import React, { Component } from "react"
import { Box } from "rebass"
import { Dialog, Button } from "@material-ui/core"
import { get } from "lodash"
import { DialogTitle, DialogActions, DialogContent } from "./styles"
import { SwitchInput } from "../switch"
import ListProduct from "./ListProduct"
import ListImages from "./ListImages"
import { dataProvider } from "../../../../../restClient"
import { removeProductObject } from "./utils"

class ListGroupProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: props.list || [],
      products: props.products || { isProduct: false, list: [] },
      open: false,
      dataItems: [],
      isLoading: false,
      productsContent: get(props, "products.list", []),
    }
    this.getCollections()
  }
  getCollections = () => {
    dataProvider
      .getMany("collections", {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then(items => {
        if (items && items.data) {
          this.setState({ dataItems: items.data })
        }
      })
      .catch(e => {
        console.error(e)
      })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }
  handleSave = () => {
    this.setState({ open: false })
    this.props.onChangeText(this.state.list, this.state.products)
  }
  onAddText = () => {
    const newList = this.state.list
    newList.push({
      imageUrl: {
        url:
          "https://s3-ap-southeast-1.amazonaws.com/oleh-prod/assets/1/product/For-testing-only-UDO.png",
        urlMobile:
          "https://s3-ap-southeast-1.amazonaws.com/oleh-prod/assets/1/product/For-testing-only-UDO.png",
      },
      title: "Loyalty",
      href: {
        url: "",
        target: "",
        isLink: false,
      },
    })
    this.setState({
      list: newList,
    })
  }
  onDelete = index => {
    const newList = this.arrayRemove(this.state.list, index)
    this.setState({
      list: newList,
    })
  }
  arrayRemove(arr, value) {
    arr.splice(value, 1)

    return arr
  }
  onUpdateText = (value, index) => {
    const { list } = this.state
    list[index].title = value
    this.setState({
      list: list,
    })
  }
  onUpdateLink = (value, index) => {
    const { list } = this.state
    list[index].href.url = value
    this.setState({
      list: list,
    })
  }
  onUpdateUrl = (value, index) => {
    const { list } = this.state
    list[index].imageUrl = value
    this.setState({
      list: list,
    })
  }

  getCollectionOne = id => {
    dataProvider
      .getOne("collections", { id: id })
      .then(items => {
        // console.log(items)
        if (items && items.data) {
          const { products } = items.data
          this.getProductList(products)
        } else {
          this.setState({ isLoading: false })
        }
      })
      .catch(e => {
        this.setState({ isLoading: false })
        console.error(e)
      })
  }
  getProductList = itemId => {
    dataProvider
      .getMany("products", { ids: itemId })
      .then(items => {
        if (items && items.data) {
          const { data } = items

          if (data.length > 0) {
            const newData = data.map(product => {
              return removeProductObject(product)
            })
            this.setState({
              productsContent: newData,
              isLoading: false,
            })
            this.onUpdateList(newData)
          } else {
            this.setState({ isLoading: false })
          }
        } else {
          this.setState({ isLoading: false })
        }
      })
      .catch(e => {
        this.setState({ isLoading: false })
      })
  }
  onChangeCollection = event => {
    this.setState({
      isLoading: true,
      productsContent: [],
    })
    this.onUpdateList([])
    this.getCollectionOne(event.target.value)
  }

  onUpdateList = list => {
    this.setState({
      products: {
        isProduct: this.state.products.isProduct,
        list: list,
      },
    })
  }

  render() {
    const {
      list,
      open,
      products,
      dataItems,
      isLoading,
      productsContent,
    } = this.state
    const { setting, data, title } = this.props
    const { type } = data.values
    return (
      <div>
        <Button variant="outlined" onClick={this.handleOpen} color={"primary"}>
          Product List
        </Button>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title-product"
          open={open}
          fullScreen={true}
          scroll={"paper"}
        >
          <DialogTitle
            id="customized-dialog-title-product"
            onClose={this.handleClose}
          >
            Product of List
          </DialogTitle>
          <DialogContent dividers>
            <Box p={[1]}>
              <SwitchInput
                id={`choice-collection`}
                title={"Choice List Collections or Custom"}
                checked={products.isProduct}
                onChange={e =>
                  this.setState({
                    products: {
                      list: products.list,
                      isProduct: e.target.checked,
                    },
                  })
                }
              />
              {products.isProduct ? (
                <ListProduct
                  dataItems={dataItems}
                  setting={setting}
                  title={title}
                  productsContent={productsContent}
                  products={products}
                  isLoading={isLoading}
                  onChangeCollection={this.onChangeCollection}
                />
              ) : (
                <ListImages
                  list={list}
                  type={type}
                  onUpdateUrl={(value, index) => this.onUpdateUrl(value, index)}
                  onUpdateText={(value, index) =>
                    this.onUpdateText(value, index)
                  }
                  onUpdateLink={(value, index) =>
                    this.onUpdateLink(value, index)
                  }
                  onDelete={index => this.onDelete(index)}
                  onAddText={() => this.onAddText()}
                />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default ListGroupProduct
