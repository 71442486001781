import React, { Component } from "react"
import PropTypes from "prop-types"
import { Flex } from "rebass"
import { get } from "lodash"
import { FooterAction, ContainerPanel } from "../actions"
import { PanelText, PanelGeneral } from "./ImageTextFields"
import { BoxContent } from "../styled"
const defaultImageTextView = {
  parent: "Content",
  type: "ImageTextView",
  containerPadding: "0px",
  backgroundColor: "rgba(255,255,255,0)",
  text: {
    containerPadding: "0px",
    backgroundColor: "rgba(255,255,255,0)",
    lineHeight: "120%",
    letterSpacing: "1px",
    value:
      "<div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>",
  },
  backgroundImage: {
    url: "",
    urlMobile: "",
    desktop: {
      width: 0,
      height: 0,
    },
    mobile: {
      width: 0,
      height: 0,
    },
  },
}
class ImageTextView extends Component {
  constructor(props) {
    super(props)
    const values = get(props, "data.values", defaultImageTextView)
    const lH = values.text.lineHeight.split("%").join("") * 1
    const lS = values.text.letterSpacing.split("px").join("") * 1
    this.state = {
      heightContent: props.heightContent,
      widthContent: props.widthContent,
      text: values.text,
      valueText: values.text.value,
      backgroundColorText: values.text.backgroundColor,
      containerPaddingText: values.text.containerPadding,
      backgroundImage: values.backgroundImage,
      backgroundColor: values.backgroundColor,
      containerPadding: values.containerPadding,
      lineHeight: lH,
      letterSpacing: lS,
    }
  }

  onClickSave = () => {
    let newData = this.props.data
    let text = this.state.text
    text.value = this.state.valueText
    text.lineHeight = this.state.lineHeight + "%"
    text.letterSpacing = this.state.letterSpacing + "px"
    text.backgroundColor = this.state.backgroundColorText
    text.containerPadding = this.state.containerPaddingText
    newData.values.containerPadding = this.state.containerPadding
    newData.values.backgroundImage = this.state.backgroundImage
    newData.values.text = text
    this.props.onUpdateData(newData)
  }

  onSetValue = (key, value) => {
    this.setState({ [key]: value })
  }

  onClickCopy = () => {
    let newData = this.props.data
    let text = this.state.text
    text.value = this.state.valueText
    text.lineHeight = this.state.lineHeight + "%"
    text.letterSpacing = this.state.letterSpacing + "px"
    text.backgroundColor = this.state.backgroundColorText
    text.containerPadding = this.state.containerPaddingText
    newData.values.containerPadding = this.state.containerPadding
    newData.values.backgroundImage = this.state.backgroundImage
    newData.values.text = text
    localStorage.setItem("ImageTextView", JSON.stringify(newData.values))
  }
  onClickPaste = () => {
    const imageTextContent = JSON.parse(localStorage.getItem("ImageTextView"))
    if (imageTextContent) {
      const {
        containerPadding,
        backgroundColor,
        text,
        backgroundImage,
      } = imageTextContent
      const lH = text.lineHeight.split("%").join("") * 1
      const lS = text.letterSpacing.split("px").join("") * 1
      this.setState({
        text: text,
        valueText: text.value,
        backgroundColorText: text.backgroundColor,
        containerPaddingText: text.containerPadding,
        backgroundImage: backgroundImage,
        backgroundColor: backgroundColor,
        containerPadding: containerPadding,
        lineHeight: lH,
        letterSpacing: lS,
      })
    }
  }

  render() {
    const { type } = this.props.data.values
    return (
      <Flex width={[1]} flexDirection={"column"}>
        <BoxContent style={{ height: this.state.heightContent - 120 }}>
          <ContainerPanel>
            <PanelText state={this.state} onSetValue={this.onSetValue} />
            <PanelGeneral
              state={this.state}
              onSetValue={this.onSetValue}
              type={type}
            />
          </ContainerPanel>
        </BoxContent>
        <FooterAction
          onClickSave={this.onClickSave}
          onClickCopy={this.onClickCopy}
          onClickPaste={this.onClickPaste}
          widthContent={this.state.widthContent}
        />
      </Flex>
    )
  }
}

ImageTextView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
}

ImageTextView.defaultProps = {
  data: {},
}
export default ImageTextView
