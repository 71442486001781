export default {
  levels: {
    name: "Level",
    fields: {
      status: "Status",
      active: "Active",
      inActive: "InActive",
      name: "Name",
      email: "Email",
      phone: "Phone Number",
      description: "Description",
      min_accumulated_points: "Minimum accumulated points",
      achievement_days: "Achievement (days)",
      reward_name: "Reward name",
      reward_points: "Reward points",
      reward_code: "Reward code",
      photo: "Photo",
      created_at: "Create On",
    },
  },
}
