import React, { Component } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { FooterAction } from "../actions";
import { Flex } from "rebass";
import { FabFields } from "./Fields";
const defaultFabData = {
  parent: "Content",
  type: "FAButtonView",
  containerPadding: "0px",
  disabled: false,
  mini: false,
  buttonAlign: "center",
  buttonColors: {
    color: "#FFF",
    backgroundColor: "#e61919",
    hoverColor: "#42d696",
    disabledColor: "#757575",
  },
  size: "medium",
  sizeIcon: 24,
  zDepth: 2,
  icon: "add",
  href: {
    url: "",
    target: "",
  },
};

class FAButtonView extends Component {
  constructor(props) {
    super(props);
    const values = get(props, "data.values", defaultFabData);
    this.state = {
      heightContent: props.heightContent,
      widthContent: props.widthContent,
      buttonColors: values.buttonColors,
      icon: values.icon,
      sizeIcon: values.sizeIcon,
      size: values.size,
      zDepth: values.zDepth,
      href: values.href,
      mini: values.mini,
      buttonAlign: values.buttonAlign,
      disabled: values.disabled,
      containerPadding: values.containerPadding,
    };
  }
  handleValue = (name, key) => (event) => {
    this.setState({ ...this.state, [name]: event.target[key] });
  };

  onUpdateUrl = (value) => {
    let newHref = this.state.href;
    newHref.url = value;

    this.setState({
      href: newHref,
    });
  };

  onChangeTarget = (event) => {
    const newValue = event.target.value === "_blank" ? event.target.value : "";
    let newHref = this.state.href;
    newHref.target = newValue;
    this.setState({
      href: newHref,
    });
  };

  onSetValue = (key, value) => {
    this.setState({ [key]: value });
  };
  onUpdateColor = (color, type) => {
    const newButtonColor = this.state.buttonColors;
    this.setState({
      buttonColors: { ...newButtonColor, [type]: color },
    });
  };

  onClickCopy = () => {
    let newData = this.props.data;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values.buttonAlign = this.state.buttonAlign;
    newData.values.buttonColors = this.state.buttonColors;
    newData.values.icon = this.state.icon;
    newData.values.disabled = this.state.disabled;
    newData.values.href = this.state.href;
    newData.values.zDepth = this.state.zDepth;
    newData.values.mini = this.state.mini;
    newData.values.sizeIcon = this.state.sizeIcon;
    newData.values.size = this.state.size;
    localStorage.setItem("FAButtonView", JSON.stringify(newData.values));
  };
  onClickPaste = () => {
    const FABContent = JSON.parse(localStorage.getItem("FAButtonView"));
    if (FABContent) {
      this.setState({
        buttonColors: FABContent.buttonColors,
        icon: FABContent.icon,
        sizeIcon: FABContent.sizeIcon,
        size: FABContent.size,
        zDepth: FABContent.zDepth,
        href: FABContent.href,
        mini: FABContent.mini,
        buttonAlign: FABContent.buttonAlign,
        disabled: FABContent.disabled,
        containerPadding: FABContent.containerPadding,
      });
    }
  };
  onClickSave = () => {
    let newData = this.props.data;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values.buttonAlign = this.state.buttonAlign;
    newData.values.buttonColors = this.state.buttonColors;
    newData.values.icon = this.state.icon;
    newData.values.disabled = this.state.disabled;
    newData.values.href = this.state.href;
    newData.values.zDepth = this.state.zDepth;
    newData.values.mini = this.state.mini;
    newData.values.sizeIcon = this.state.sizeIcon;
    newData.values.size = this.state.size;
    //  console.log(newData.values);
    this.props.onUpdateData(newData);
  };
  render() {
    return (
      <Flex width={[1]} flexDirection={"column"} pb={["80px"]}>
        <FabFields
          state={this.state}
          onSetValue={this.onSetValue}
          handleValue={this.handleValue}
          onUpdateColor={this.onUpdateColor}
          onUpdateUrl={this.onUpdateUrl}
          onChangeTarget={this.onChangeTarget}
        />

        <FooterAction
          onClickSave={this.onClickSave}
          onClickCopy={this.onClickCopy}
          onClickPaste={this.onClickPaste}
          widthContent={this.state.widthContent}
        />
      </Flex>
    );
  }
}

FAButtonView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

FAButtonView.defaultProps = {
  data: {},
};
export default FAButtonView;
