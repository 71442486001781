import React from "react"
import { Box, Flex, Text } from "rebass"
import { IconButton } from "@material-ui/core"
import ActionAdd from "@material-ui/icons/AddCircle"
import ActionDelete from "@material-ui/icons/Delete"
import TextInput from "../TextInput"
import { UploadImageListSlide } from "../../../../components"

const ListImages = props => {
  const {
    list,
    type,
    onDelete,
    onUpdateLink,
    onUpdateText,
    onUpdateUrl,
    onAddText,
  } = props
  return (
    <Box width={[1]}>
      <Flex width={[1]} direction={"row"} justify={"space-between"}>
        <Box width={[1]}>
          <Text>Items</Text>
        </Box>
        <Box>
          <IconButton
            onClick={() => onAddText()}
            style={{ color: "rgb(0, 188, 212)" }}
          >
            <ActionAdd color={"inherit"} />
          </IconButton>
        </Box>
      </Flex>
      <Box width={[1]}>
        {list.length > 0 &&
          list.map((item, index) => {
            return (
              <Flex
                key={index}
                width={[1]}
                direction={"row"}
                justify={"space-between"}
                align={"center"}
                style={{
                  paddingBottom: 4,
                  borderBottomWidth: 1,
                  borderBottomColor: "rgba(0,0,0,0.2)",
                  borderBottomStyle: "solid",
                }}
              >
                <Box width={[1]}>
                  <Flex w={[1]} direction={"row"}>
                    <UploadImageListSlide
                      type={type}
                      imageUrl={item.imageUrl}
                      onUpdateUrl={value => onUpdateUrl(value, index)}
                      title="Image"
                    />
                    <Box width={[1]}>
                      <TextInput
                        value={item.title}
                        onChange={value => onUpdateText(value, index)}
                        name={`text-${index}`}
                        title="Title"
                        fullWidth={true}
                      />
                      <TextInput
                        value={item.href.url}
                        onChange={value => onUpdateLink(value, index)}
                        name={`url-${index}`}
                        title="Url"
                        fullWidth={true}
                      />
                    </Box>
                  </Flex>
                </Box>
                <Box>
                  {list.length > 1 && (
                    <IconButton
                      onClick={() => onDelete(index)}
                      style={{ color: "rgb(0, 188, 212)" }}
                    >
                      <ActionDelete color={"inherit"} />
                    </IconButton>
                  )}
                </Box>
              </Flex>
            )
          })}
      </Box>
    </Box>
  )
}

export default ListImages
