import React from "react"
import { TextInput, SelectInput, NumberInput } from "react-admin"
import { Box, Flex } from "rebass"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "../../../components"
import { dataCountry } from "../../websites"

/**{
    
    address: {
        address_1:"",
        address_2:"",
        city:"",
        state:"",
        postal_code:"",
        country:"",
        latitude:"",
        longitude:""
    }
} */

const AddressFields = (props) => {
  const { translate } = props
  return (
    <Box width={[1]} py={[2]}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="address-sources-content"
          id="address-sources-header"
        >
          <Typography>
            {translate("resources.sources.fields.address")}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Flex width={[1]} flexDirection={"column"}>
            <TextInput
              source="address.address_1"
              fullWidth
              label={"resources.sources.fields.address1"}
            />
            <TextInput
              source="address.address_2"
              fullWidth
              label={"resources.sources.fields.address2"}
            />
            <TextInput
              source="address.city"
              style={{ width: 300 }}
              label={"resources.sources.fields.city"}
            />
            <TextInput
              source="address.state"
              style={{ width: 300 }}
              label={"resources.sources.fields.state"}
            />
            <TextInput
              source="address.postal_code"
              style={{ width: 300 }}
              label={"resources.sources.fields.postal_code"}
            />
            <SelectInput
              source="address.country"
              choices={dataCountry}
              label={"resources.sources.fields.country"}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 300 }}
            />
            <NumberInput
              source="address.latitude"
              style={{ width: 300 }}
              label={"resources.sources.fields.latitude"}
            />
            <NumberInput
              source="address.longtitude"
              style={{ width: 300 }}
              label={"resources.sources.fields.longtitude"}
            />
          </Flex>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Box>
  )
}

export default AddressFields
