export default {
  loyaltyPrograms: {
    name: "Loyalty Program",
    cashback: "Cashback",
    fields: {
      name: "Name",
      currency: "Currency",
      points_singular: "Points singular",
      points_plural: "Points plural",
      points_expiry: "Points expiry (days)",
      created_at: "Create On",
      cashback: {
        point_value: "Point value",
      },
      marketing_cloud_settings: {
        name: "Marketing cloud settings",
        subdomain: "Subdomain",
        client_id: "Client ID",
        client_secret: "Client Secret",
        loyalty_members_key: "Loyalty Members Key",
        loyalty_rewards_key: "Loyalty Rewards Key",
      },
      service_cloud_settings: {
        name: "Service cloud settings",
        username: "Username",
        password: "Password",
      },
    },
  },
}
