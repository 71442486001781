import React from "react";
import { get } from "lodash";
import {
  Datagrid,
  DateField,
  FormTab,
  // TextField,
  Pagination,
  useTranslate,
} from "react-admin";
import DocumentIcon from "@material-ui/icons/InsertDriveFileOutlined";
import {
  EditToolbar,
  BetterTabbedCustomForm,
  RaReferenceManyField,
  ButtonEditView,
  ButtonEditLayoutView,
  RaTextField,
  FormValueTab,
} from "../../../components";
import { FormFields } from "./FormFields";
import { LocaleFields } from "./locale";
// import ProductFields from "./ProductFields";
// import CheckoutFields from "./CheckoutFields";
import { PoliciesFields } from "./policies";
// import { ServiceFields } from "./service"
// import { ProductFields } from "./product"
import ListPackagings from "./shippings/ListPackagings";

export const EditForm = (props) => {
  const translate = useTranslate();
  return (
    <BetterTabbedCustomForm
      {...props}
      title={"resources.websites.back"}
      labelBack={"resources.websites.edit_title"}
      toolbar={<EditToolbar />}
      isShowBack={true}
    >
      <FormValueTab label='resources.websites.tabs.info'>
        <FormFields {...props} />
      </FormValueTab>
      <FormTab label='resources.websites.tabs.locale' path={"locale"}>
        <LocaleFields {...props} />
      </FormTab>
      <FormTab label='resources.websites.tabs.layout' path={"layout"}>
        <RaReferenceManyField
          reference='layouts'
          addLabel={false}
          target={"id"}
          pagination={<Pagination />}
          style={{ width: 500 }}
          filter={{ website_id: props.id }}
          isCustomCreateButton={true}
          subheader={translate("resources.websites.note.layout")}
          notShowSearch={true}
          labelCreate={translate("resources.buttons.create")}
        >
          <Datagrid>
            <RaTextField source='name' />
            <DateField source='created_at' />

            <ButtonEditLayoutView
              basePath={props.basePath}
              isCustomLink={true}
              taburl='layout'
            />
          </Datagrid>
        </RaReferenceManyField>
      </FormTab>
      <FormTab label='resources.websites.tabs.policies' path={"policies"}>
        <PoliciesFields {...props} translate={translate} />
      </FormTab>
      <FormTab label='resources.websites.tabs.shipping' path={"shipping"}>
        <ListPackagings
          {...props}
          subheader={translate("resources.shippings.label.packaging")}
          labelCreate={translate("resources.buttons.add_packaging")}
        />
      </FormTab>
      {/*<FormTab label="resources.websites.tabs.payment" path={"payment"}>
        <div>Payment</div>
         <RaReferenceManyField
          reference="payments"
          addLabel={false}
          target={"website_id"}
          pagination={<Pagination />}
          style={{ width: 500 }}
          isCustomCreateButton={true}
          subheader={""}
          notShowSearch={true}
          labelCreate={translate("resources.buttons.create")}
        >
          <Datagrid>
            <TextField source="name" />
            <DateField source="created_at" />
            <ButtonEditView {...props} isCustomLink={true} taburl="payment" />
          </Datagrid>
        </RaReferenceManyField> 
      </FormTab>*/}
      {/*<FormTab label="resources.websites.tabs.checkout" path={"checkout"}>
        <CheckoutFields {...props} />
         <RaReferenceManyField
          reference="payments"
          addLabel={false}
          target={"website_id"}
          pagination={<Pagination />}
          style={{ width: 500 }}
          isCustomCreateButton={true}
          subheader={""}
          notShowSearch={true}
          labelCreate={translate("resources.buttons.create")}
        >
          <Datagrid>
            <TextField source="name" />
            <DateField source="created_at" />
            <ButtonEditView {...props} isCustomLink={true} taburl="payment" />
          </Datagrid>
        </RaReferenceManyField> 
      </FormTab>*/}
      {/* <FormTab label="resources.websites.tabs.webhooks" path={"webhooks"}>
        <div>Webhooks</div>
        <RaReferenceManyField
          reference="webhooks"
          addLabel={false}
          target={"website_id"}
          pagination={<Pagination />}
          style={{ width: 500 }}
          isCustomCreateButton={true}
          subheader={""}
          notShowSearch={true}
          labelCreate={translate("resources.buttons.create")}
        >
          <Datagrid>
            <TextField source="name" />
            <TextField source="description" />
             <BooleanField source="active" /> 
            <ButtonEditView {...props} isCustomLink={true} taburl="webhooks" />
          </Datagrid>
        </RaReferenceManyField> 
      </FormTab>*/}
      <FormTab label='resources.websites.tabs.faq' path={"faq"}>
        <RaReferenceManyField
          key={get(props, "match.params.id", 0)}
          reference='topics'
          addLabel={false}
          target={"website_id"}
          filter={{ website_id: get(props, "match.params.id", 0) }}
          pagination={<Pagination />}
          style={{ width: 500 }}
          isCustomCreateButton={true}
          subheader={""}
          createIcon={<DocumentIcon />}
          notShowSearch={true}
          labelCreate={translate("resources.buttons.create_topic")}
        >
          <Datagrid>
            <RaTextField source='name' label={"resources.faq.fields.topic"} />
            <DateField
              source='created_at'
              options={{
                year: "numeric",
                month: "short",
                day: "numeric",
              }}
              label={"resources.faq.fields.created_at"}
            />
            <ButtonEditView {...props} isCustomLink={true} taburl='faq' />
          </Datagrid>
        </RaReferenceManyField>
      </FormTab>
    </BetterTabbedCustomForm>
  );
};
