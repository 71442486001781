import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Media from "react-media";

const Image = styled.img`
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`;

class ResponsiveImage extends Component {
  render() {
    let srcMobile =
      this.props.srcMobile || this.props.srcDesktop || this.props.src || "";
    let srcDesktop =
      this.props.srcDesktop || this.props.srcMobile || this.props.src || "";

    return (
      <div>
        <Media query="(max-width: 768px)">
          {matches =>
            matches ? <Image src={srcMobile} /> : <Image src={srcDesktop} />
          }
        </Media>
      </div>
    );
  }
}

ResponsiveImage.propTypes = {
  src: PropTypes.string,
  srcMobile: PropTypes.string,
  srcDesktop: PropTypes.string
};

export default ResponsiveImage;
