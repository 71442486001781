import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text } from "rebass";
import { get } from "lodash";
import { Counter, Title, BlockCounter, SelectInput } from "../components";
import { ContentDetail } from "../actions";

const imgMode = [
  { id: "contain", name: "Contain" },
  { id: "cover", name: "Cover" },
  { id: "fill", name: "Fill" },
  { id: "none", name: "None" },
  { id: "scale-down", name: "Scale Down" },
];

const ItemSetting = (props) => {
  const { values, onUpdate, keyContent } = props;
  const [state, setState] = useState(values);
  // useEffect(() => {
  //   handleChange(layoutRow);
  // }, [layoutRow]);
  // const handleChange = (v) => {
  //   setState({
  //     ...state,
  //     ...v,
  //   });
  // };

  const onChangeValue = (k, v, index) => {
    let myValue = state[k];

    if (state[k]) {
      if (myValue[index]) {
        myValue[index] = v;
      } else {
        if (myValue[index] === "") {
          myValue[index] = v;
        } else {
          if (typeof v === "number") {
            myValue = [1, 1];
            myValue[index] = v;
          } else {
            myValue = ["", ""];
            myValue[index] = v;
          }
        }
      }
    } else {
      if (typeof v === "number") {
        myValue = [1, 1];
        myValue[index] = v;
      } else {
        myValue = ["", ""];
        myValue[index] = v;
      }
    }

    const params = { ...state, [k]: myValue };
    setState(params);
    onUpdate(keyContent, params);
  };
  return (
    <Box width={[1]}>
      <Flex width={[1]} flexDirection={"column"}>
        <ContentDetail>
          <Text fontWeight={"bold"} pb={[2]}>
            Desktop View
          </Text>
          <BlockCounter
            title={"Container Padding"}
            containerPadding={get(state, "containerPadding[1]", 1)}
            onUpdatePadding={(containerPadding) =>
              onChangeValue("containerPadding", containerPadding, 1)
            }
          />
          <BlockCounter
            title={"Title Padding"}
            containerPadding={get(state, "titlePadding[1]", 1)}
            onUpdatePadding={(containerPadding) =>
              onChangeValue("titlePadding", containerPadding, 1)
            }
          />
          <BlockCounter
            title={"Sub Title Padding"}
            containerPadding={get(state, "subPadding[1]", 1)}
            onUpdatePadding={(containerPadding) =>
              onChangeValue("subPadding", containerPadding, 1)
            }
          />
          <Title title={"Limit"} />
          <Counter
            onSetValue={(value) => onChangeValue("limit", value, 1)}
            value={get(state, "limit[1]", 1)}
            interval={1}
            subtitle=''
            minValue={0}
          />
          <Title title={"Title Font Size"} />
          <Counter
            onSetValue={(value) => onChangeValue("titleFontSize", value, 1)}
            value={get(state, "titleFontSize[1]", 1)}
            interval={1}
            subtitle='pt'
            minValue={1}
          />
          <Title title={"Subtitle Font Size"} />
          <Counter
            onSetValue={(value) => onChangeValue("subFontSize", value, 1)}
            value={get(state, "subFontSize[1]", 1)}
            interval={1}
            subtitle='pt'
            minValue={1}
          />
          <SelectInput
            id={`img-object-fit-desktop`}
            title={"Object Fit Image"}
            value={get(state, "modeImg[1]", "contain")}
            list={imgMode}
            onChange={(e) => onChangeValue("modeImg", e.target.value, 1)}
          />
        </ContentDetail>
      </Flex>
      <Flex width={[1]} flexDirection={"column"} py={[2]}>
        <ContentDetail>
          <Text fontWeight={"bold"} pb={[2]}>
            Mobile View
          </Text>
          <BlockCounter
            title={"Container Padding"}
            containerPadding={get(state, "containerPadding[0]", 0)}
            onUpdatePadding={(containerPadding) =>
              onChangeValue("containerPadding", containerPadding, 0)
            }
          />
          <BlockCounter
            title={"Title Padding"}
            containerPadding={get(state, "titlePadding[0]", 0)}
            onUpdatePadding={(containerPadding) =>
              onChangeValue("titlePadding", containerPadding, 0)
            }
          />
          <BlockCounter
            title={"Sub Title Padding"}
            containerPadding={get(state, "subPadding[0]", 0)}
            onUpdatePadding={(containerPadding) =>
              onChangeValue("subPadding", containerPadding, 0)
            }
          />
          <Title title={"Limit"} />
          <Counter
            onSetValue={(value) => onChangeValue("limit", value, 0)}
            value={get(state, "limit[0]", 0)}
            interval={1}
            subtitle=''
            minValue={0}
          />
          <Title title={"Title Font Size"} />
          <Counter
            onSetValue={(value) => onChangeValue("titleFontSize", value, 0)}
            value={get(state, "titleFontSize[0]", 0)}
            interval={1}
            subtitle='pt'
            minValue={1}
          />
          <Title title={"Subtitle Font Size"} />
          <Counter
            onSetValue={(value) => onChangeValue("subFontSize", value, 0)}
            value={get(state, "subFontSize[0]", 0)}
            interval={1}
            subtitle='pt'
            minValue={1}
          />
          <SelectInput
            id={`img-object-fit-mobile`}
            title={"Object Fit Image"}
            value={get(state, "modeImg[0]", "contain")}
            list={imgMode}
            onChange={(e) => onChangeValue("modeImg", e.target.value, 0)}
          />
        </ContentDetail>
      </Flex>
    </Box>
  );
};

ItemSetting.propTypes = {
  values: PropTypes.object,
  title: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  keyContent: PropTypes.string,
};

ItemSetting.defaultProps = {
  values: {
    limit: [100, 100],
    titleFontSize: [12, 12],
    subFontSize: [12, 12],
  },
  title: "Item Setting",
  keyContent: "itemSetting",
};

export default ItemSetting;
