import React from "react";
import { Box } from "rebass";
import { get } from "lodash";
import {
  FormDataConsumer,
  useTranslate,
  SelectInput,
  required,
} from "react-admin";
import {
  RaTextInput,
  AutoCompleteTimeZone,
  RaSelectArrayInputOptions,
} from "../../../../components";
import {
  dataCurrency,
  dataDateFormat,
  dataDecimal,
  dataSeparator,
  dataLanguage,
} from "../data";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

const LocaleFields = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  // console.log(props)
  const onSetValue = (key, value) => {
    props.updateField(key, value);
  };
  return (
    <Box width={[1]} p={[3]}>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <Box width={[1]}>
              <RaSelectArrayInputOptions
                className={classes.root}
                source="locale.supported_language"
                label={translate(
                  "resources.websites.fields.supported_language"
                )}
                choices={dataLanguage}
                fullWidth={true}
                notetext="resources.websites.note.supported_language"
                validate={[required()]}
              />
              <Box>
                <SelectInput
                  source="locale.default_language"
                  choices={dataLanguage}
                  label={translate(
                    "resources.websites.fields.default_language"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: 300 }}
                />
              </Box>
              <Box>
                <SelectInput
                  source="locale.currency"
                  choices={dataCurrency}
                  label={translate("resources.websites.fields.currency")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: 300 }}
                />
              </Box>
              <RaTextInput
                label={translate("resources.websites.fields.currency_symbol")}
                source="locale.currency_symbol"
                style={{ width: 300 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Box>
                <SelectInput
                  source="locale.thousand_separator"
                  choices={dataSeparator}
                  label={translate(
                    "resources.websites.fields.thousand_separator"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: 300 }}
                />
              </Box>
              <Box>
                <SelectInput
                  source="locale.decimal_scale"
                  choices={dataDecimal}
                  label={translate("resources.websites.fields.decimal_scale")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: 300 }}
                />
              </Box>
              <Box>
                <SelectInput
                  source="locale.decimal_separator"
                  choices={dataSeparator}
                  label={translate(
                    "resources.websites.fields.decimal_separator"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: 300 }}
                />
              </Box>
              <SelectInput
                source="locale.date_format"
                choices={dataDateFormat}
                label={translate("resources.websites.fields.date_format")}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: 300 }}
              />
              <AutoCompleteTimeZone
                name={"timezone-input"}
                value={get(formData, "locale.timezone", "")}
                id="timezone-locale"
                onChange={(e, v) => onSetValue("locale.timezone", v)}
                title={translate("resources.websites.fields.time_zone")}
              />
            </Box>
          );
        }}
      </FormDataConsumer>
    </Box>
  );
};

export default LocaleFields;
