export default {
  setup: {
    title: "Setup",
    first_page: {
      title: "Welcome to BCX Studio!",
      subtitle: "The easiest and simplest loyalty program in Heroku",
    },
    approved: {
      title:
        "We have received your request and will review and contact you shortly.",
    },
    step1: {
      loyalty_program: "Loyalty Program",
      company_name: "Your Company/Brand Name",
      loyalty_name: "Your Loyalty Program Name",
      company_message: "How your customer know your brand.",
      loyalty_message: "How your customer remember your loyalty program.",
    },
    step2: {
      earning_point: "Earning Point",
      point_value: "Per Dollar Point Value",
      expired_point: "Point Can Be Expired",
      point_value_message:
        "How much each dollar will earn 1 point. For example, 1 point value means $1 will earn 1 point. 0.1 point value means $1 will earn 0.1 point.",
      expired_point_message: "Number of days the points are valid.",
    },
    step3: {
      spending_point: "Spending Point",
      cashback: "Cashback",
      voucher: "Voucher",
      dollars: "Dollars",
      point_needed_to_redeem: "Point Needed To Redeem",
      cashback_message:
        "How much point is equal to $1? Put 10 means 10 points is $1. Put 1 means 1 point is $1.",
      voucher_message: "Customer can redeem as shopping voucher.",
    },
    button: {
      next: "Next",
      back: "Back",
      complete: "Complete",
      get_started: "Get Started",
      home: "Home",
      submit: "Submit",
    },
  },
}
