import React from "react";
import { Paper as MuiPaper } from "@material-ui/core";
import { Flex } from "rebass";
import { get } from "lodash";
import { useQuery } from "react-query";
import { withStyles } from "@material-ui/core/styles";
// import { Button } from "@material-ui/core";
import { RestApi } from "../../../../restClient";
import { TextRecord } from "../../../../components";
import DialogCancelOrder from "./DialogCancelOrder";
import { getPaymentInfo } from "./utils";
import SetDeliveryFields from "./SetDeliveryFields";
const cacheTimeMinute = 1000 * 60 * 2;
const Paper = withStyles((theme) => ({
  root: {
    width: "100%",
  },
}))(MuiPaper);

const OrderFieldView = (props) => {
  const { record } = props;
  const orderId = get(record, "id", 0);

  const { data } = useQuery(
    `/orders/${orderId}/shipments`,
    () => RestApi(`/orders/${orderId}/shipments`).then((res) => res.json()),
    {
      cacheTime: cacheTimeMinute,
    }
  );
  // console.log("data", orderId, data);
  // add payment_info. when using from record
  const myPaymentInfo = getPaymentInfo(record);
  return (
    <Paper elevation={3} component={"div"}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        p={[2]}
      >
        <Flex
          width={[1]}
          flexDirection={"row"}
          px={[2]}
          sx={{
            "&> div": {
              py: [2],
              pr: [3],
            },
          }}
        >
          <TextRecord
            label={"resources.orders.labels.order_reference"}
            source='ref'
            record={record}
          />
          <TextRecord
            label={"resources.orders.fields.order_status"}
            source='status'
            record={record}
          />
          <TextRecord
            label={"resources.orders.fields.payment_status"}
            source='status'
            record={myPaymentInfo}
            defaultValue=''
          />
          <TextRecord
            label={"resources.orders.labels.order_date_time"}
            source='created_at'
            isDate={true}
            formatDate={"DD-MMM-YYYY HH:mm a"}
            record={record}
          />
        </Flex>
        <Flex
          flexDirection={"row"}
          pr={[2]}
          width={"300px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <SetDeliveryFields {...props} />
          <DialogCancelOrder {...props} shipmentList={data} />
          {/* <Button color={"primary"} onClick={() => onCancelOrder()}>
            {translate("resources.buttons.cancel_order")}
          </Button> */}
        </Flex>
      </Flex>
    </Paper>
  );
};

export default OrderFieldView;
