import React from "react"
import { withRouter } from "react-router-dom"
import { Box } from "rebass"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { showNotification } from "react-admin"
import { BetterCustomSimpleForm, FooterToolbar, CardPanel } from "./"
import FormFields from "./FormFields"
import { getSiteID } from "../../../restClient"
const initValues = {
  site_id: getSiteID(),
  type: "Cart",
}

const CreateCartRules = (props) => {
  return (
    <CardPanel>
      <Box width={[1]}>
        <BetterCustomSimpleForm
          {...props}
          toolbar={<FooterToolbar />}
          initialValues={initValues}
          sourceApi="promotions"
          title={"resources.promotions.title.new_cart"}
          typePostUrl={"CREATE"}
          pathUrl="promotions"
          customBackUrl="/promotions/cart"
        >
          <FormFields {...props} isCreate={true} />
        </BetterCustomSimpleForm>
      </Box>
    </CardPanel>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(CreateCartRules)
