import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { Box } from "rebass";
import { get, map } from "lodash";
import ItemCategory from "./ItemCategory";
import { useQueries } from "react-query";
import { getProductById } from "../../../../restClient";
import { defaultSetting, settingsList } from "./utils";
import HeaderList from "./HeaderList";
const cacheTimeMinute = 1000 * 60 * 30;
export default (props) => {
  const refContainer = useRef(null);
  const [state, setState] = useState({ isIndex: 0, isEnd: false });
  const {
    screenWidth,
    desktop,
    colorProps,
    showPrice,
    modeType,
    products,
  } = props;
  const settings = settingsList(
    get(desktop, "setting", defaultSetting),
    screenWidth,
    (index) => onChange(index)
  );
  const listProduct = map(products, function (o) {
    return {
      queryKey: ["products", o],
      queryFn: getProductById,
      queryOption: { cacheTime: cacheTimeMinute },
    };
  });
  const results = useQueries(listProduct);

  const onChange = (index) => {
    const listTags = get(refContainer, "current.props.children", []);
    const slidesToShow = get(desktop, "setting.slidesToShow", defaultSetting);
    const addIndex = slidesToShow; //screenWidth > 702 ? 6 : 3;
    const currentIndex = index + addIndex;
    let isEndCurrent = false;

    if (currentIndex >= listTags.length) {
      isEndCurrent = true;
    }
    setState({
      isIndex: index,
      isEnd: isEndCurrent,
    });
  };

  // console.log(results);
  return (
    <Box style={{ width: screenWidth }}>
      <HeaderList {...props} sliderone={refContainer.current} state={state} />

      <Slider
        ref={refContainer}
        {...settings}
        style={{ backgroundColor: "transparent" }}
      >
        {results &&
          results.map((item, index) => {
            if (item.isLoading) {
              return <div key={index} />;
            }
            return (
              <ItemCategory
                item={item.data}
                key={index}
                size={[1]}
                screenWidth={screenWidth}
                colorProps={colorProps}
                desktop={desktop}
                modeType={modeType}
                showPrice={showPrice}
              />
            );
          })}
      </Slider>
    </Box>
  );
};
