import React from "react";
import styled from "styled-components";
import { Text } from "rebass";
import { grey } from "@material-ui/core/colors";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

export const ERROR_MESSAGE_MAX_SIZE = "Please resize your image less then 1MB";
export const ERROR_MESSAGE_FAILED = "Upload image failed";

export const BoxUpload = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  border: 1px solid ${grey[300]};
  justify-content: center;
  align-ttems: center;
  borderwidth: 0;
  cursor: pointer;
  & > input {
    outline: none;

    *:focus {
      outline: none;
    }
  }
  *:focus {
    outline: none;
  }
`;

export const BoxUploadSmall = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  border: 1px solid ${grey[300]};
  justify-content: center;
  align-ttems: center;
  borderwidth: 0;
  cursor: pointer;
  & > input {
    outline: none;

    *:focus {
      outline: none;
    }
  }
  *:focus {
    outline: none;
  }
`;

const BoxContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: inherit;
  fontsize: 12px;
`;
export const BoxInside = () => {
  return (
    <BoxContent>
      <CameraAltIcon style={{ fontSize: 70, color: "#ccc" }} />
      <Text
        fontSize={"0.8rem"}
        fontWeight={"bold"}
        p={[2]}
        pt={[1]}
        color={"#008bdb"}
        textAlign={"center"}
      >
        Browser to find or drag image here
      </Text>
      {/* <Text fontSize={[0]} color={"#008bdb"}>
        or Click here
      </Text> */}
    </BoxContent>
  );
};

export const BoxInsideSmall = () => {
  return (
    <BoxContent>
      <CameraAltIcon style={{ fontSize: 24, color: "#ccc" }} />
      <Text
        fontSize={"0.6rem"}
        fontWeight={"bold"}
        p={[2]}
        pt={[1]}
        color={"#008bdb"}
        textAlign={"center"}
      >
        Browser to find or drag image here
      </Text>
      {/* <Text fontSize={[0]} color={"#008bdb"}>
        or Click here
      </Text> */}
    </BoxContent>
  );
};
