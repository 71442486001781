import React from "react"
import { Create } from "react-admin"
import { BetterCustomSimpleForm, FooterToolbar, BackTitleAction } from "./"
import { FormFields } from "./components"

import { getSiteID } from "../../restClient"
const initValues = {
  site_id: getSiteID(),
}

export const AttributeCreate = (props) => {
  return (
    <Create
      {...props}
      actions={
        <BackTitleAction
          title={"resources.attributes.new_attributes"}
          backTitle="resources.attributes.back"
          basePath="/attributes/list"
        />
      }
    >
      <BetterCustomSimpleForm
        {...props}
        initialValues={initValues}
        toolbar={<FooterToolbar />}
        sourceApi="attributes"
        typePostUrl={"CREATE"}
        pathUrl="attributes"
        customBackUrl="/attributes/list"
        title={"resources.attributes.back"}
        labelBack={"resources.attributes.new_attributes"}
        isShowBack={true}
        hideAction={true}
      >
        <FormFields {...props} isCreate={true} />
      </BetterCustomSimpleForm>
    </Create>
  )
}
