import { Layout15, Layout16 } from "../TemplateImageSonak";
export const template15 = {
  ijxiUq3c: {
    id: "ijxiUq3c",
    type: "Row2",
    props: {
      values: {
        name: "Row2",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: true,
            align: "middle",
            gutter: 0,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(35, 31, 30, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(35, 31, 30, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              width: {
                type: "%",
                contentWidth: 100,
              },
              column: 2,
              height: {
                type: "%",
                isMobile: false,
                isDesktop: false,
                contentHeight: 100,
              },
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                bgProps: {
                  show: false,
                },
                padding: "4px 10px 4px 10px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              bgProps: {
                size: "cover",
                ratio: 1.2,
                repeat: "no-repeat",
                position: "center",
              },
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 1,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              padding: "0px",
              gradient: {
                bg1: "rgb(34,193,195)",
                bg2: "rgba(253,187,45,1)",
                type: "linear-gradient",
                degre: 160,
                linear: [0, 100],
              },
              alignment: {
                vertical: "center",
                horizontal: "center",
              },
              className: "col-xs-12 col-lg-6 col-md-6",
              alignItems: "center",
              isGradient: false,
              isMobileView: true,
              flexDirection: "row",
              isDesktopView: true,
              justifyContent: "flex-start",
              backgroundColor: "rgba(255,255,255,0)",
              backgroundImage: {
                url: "",
                mobile: {
                  width: 0,
                  height: 0,
                },
                desktop: {
                  width: 0,
                  height: 0,
                },
                urlMobile: "",
              },
              isBackgroundImage: false,
              backgroundColorParent: "rgba(255,255,255,0)",
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              width: {
                type: "%",
                contentWidth: 100,
              },
              column: 2,
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 0,
                  order: 2,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              bgProps: {
                size: "cover",
                ratio: 1.2,
                repeat: "no-repeat",
                position: "center",
              },
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 2,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              padding: "0px",
              gradient: {
                bg1: "rgb(34,193,195)",
                bg2: "rgba(253,187,45,1)",
                type: "linear-gradient",
                degre: 160,
                linear: [0, 100],
              },
              alignment: {
                vertical: "center",
                horizontal: "center",
              },
              className: "col-xs-12 col-lg-6 col-md-6",
              alignItems: "center",
              isGradient: false,
              isMobileView: false,
              flexDirection: "row",
              isDesktopView: true,
              justifyContent: "flex-end",
              backgroundColor: "rgba(255,255,255,0)",
              backgroundImage: {
                url: "",
                mobile: {
                  width: 0,
                  height: 0,
                },
                desktop: {
                  width: 0,
                  height: 0,
                },
                urlMobile: "",
              },
              isBackgroundImage: false,
              backgroundColorParent: "rgba(255,255,255,0)",
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    layout: Layout15,
    childOf: "Row",
    children: ["gL++S1Pd", "6dgEQ1ts"],
    myContent: [
      {
        id: "3sJbCCtu",
        type: "MenuGroupView",
        props: {
          values: {
            list: [
              {
                url: "https://asics.com",
                icon:
                  "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/icon-image-desktop-9R6.png",
                type: "icon",
                title: "",
                target: "_blank",
                widthIcon: "100px",
              },
              {
                url: "https://asics.com",
                icon:
                  "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/icon-image-desktop-UZC.png",
                type: "icon",
                title: "",
                target: "_blank",
                widthIcon: "100px",
              },
              {
                url: "https://asics.com",
                icon:
                  "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/icon-image-desktop-8ZY.png",
                type: "icon",
                title: "",
                target: "_blank",
                widthIcon: "100px",
              },
            ],
            type: "MenuGroupView",
            parent: "Content",
            layoutRow: {
              alignItems: ["center", "center"],
              flexDirection: ["row", "row"],
              justifyContent: ["center", "center"],
            },
            containerPadding: "0px",
          },
        },

        parent: {
          id: "gL++S1Pd",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "gL++S1Pd",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "75M7d08Y",
        type: "MenuGroupView",
        props: {
          values: {
            list: [
              {
                url: "/contact_us",
                icon: "",
                type: "text",
                color: "rgba(155, 155, 155, 1)",
                title: "CONTACT US",
                target: "same",
                widthIcon: "100px",
              },
              {
                url: "/myorders",
                icon: "",
                type: "text",
                color: "rgba(155, 155, 155, 1)",
                title: "TRACK MY ORDER",
                target: "same",
                widthIcon: "100px",
              },
              {
                url: "/helps",
                icon: "",
                type: "text",
                color: "rgba(155, 155, 155, 1)",
                title: "HELP",
                target: "same",
                widthIcon: "100px",
              },
            ],
            type: "MenuGroupView",
            parent: "Content",
            layoutRow: {
              alignItems: ["center", "center"],
              flexDirection: ["row", "row"],
              justifyContent: ["center", "center"],
            },
            containerPadding: "0px",
          },
        },

        parent: {
          id: "6dgEQ1ts",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "6dgEQ1ts",
          idx: 1,
          index: 1,
        },
      },
    ],
  },
};

export const template16 = {
  AF8zGFWL: {
    id: "AF8zGFWL",
    type: "Row1",
    props: {
      values: {
        name: "Row1",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              width: {
                type: "%",
                contentWidth: 100,
              },
              column: 1,
              height: {
                type: "%",
                isMobile: false,
                isDesktop: false,
                contentHeight: 100,
              },
              parent: "Row",
              bgProps: {
                size: "cover",
                ratio: 1.2,
                repeat: "no-repeat",
                position: "center",
              },
              padding: "0px",
              gradient: {
                bg1: "rgb(34,193,195)",
                bg2: "rgba(253,187,45,1)",
                type: "linear-gradient",
                degre: 160,
                linear: [0, 100],
              },
              alignment: {
                vertical: "center",
                horizontal: "center",
              },
              className: "col-xs-12",
              isGradient: false,
              backgroundColor: "rgba(255,255,255,0)",
              backgroundImage: {
                url: "",
                mobile: {
                  width: 0,
                  height: 0,
                },
                desktop: {
                  width: 0,
                  height: 0,
                },
                urlMobile: "",
              },
              isBackgroundImage: false,
              backgroundColorParent: "rgba(255,255,255,0)",
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    layout: Layout16,
    childOf: "Row",
    children: ["TgViZu5H"],
    content: {
      uoqY6ytp: {
        id: "uoqY6ytp",
        type: "MenuView",
        props: {
          values: {
            list: [
              {
                url: "",
                slug: "",
                type: "category",
                title: "",
                target: "same",
                category_id: 1,
              },
              {
                url: "",
                slug: "",
                type: "category",
                title: "",
                target: "same",
                category_id: 35,
              },
              {
                url: "",
                slug: "",
                type: "category",
                title: "",
                target: "same",
                category_id: 67,
              },
              {
                url: "",
                slug: "aboutus",
                type: "slug",
                title: "About Us",
                target: "same",
                category_id: 0,
              },
            ],
            logo:
              "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/Logo-image-desktop-87J.png",
            type: "MenuView",
            color: "rgba(74, 74, 74, 1)",
            parent: "Content",
            modelHeader: 3,
            titleButton: "SHOP BY CATEGORY",
            containerPadding: "0px",
          },
        },
        style: {},
        parent: {
          id: "TgViZu5H",
          idx: 0,
        },
        childOf: "Content",
        children: [],
      },
    },
  },
};
