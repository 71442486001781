import React from "react";
import { SketchColorPicker } from "../../../components";
import { ContentDetail } from "../actions";

// colorProps: {
//   backgroundColor: "rgba(255,255,255,0)",
//   color: "rgba(0,0,0,1)",
//   buttonBackgroundColor: "rgba(255,255,255,0)",
//   buttonColor: "rgba(0,0,0,1)",
// },
export default (props) => {
  const { state, onSetValue } = props;
  const onStateColorsValue = (k, v) => {
    const params = {
      ...state,
      [k]: v,
    };
    onSetValue(params);
  };
  return (
    <ContentDetail>
      <SketchColorPicker
        title={"Color"}
        onUpdateColor={(color) => onStateColorsValue("color", color)}
        color={state.color}
      />
      <SketchColorPicker
        title={"Background"}
        onUpdateColor={(color) => onStateColorsValue("backgroundColor", color)}
        color={state.backgroundColor}
      />
      <SketchColorPicker
        title={"Button Color"}
        onUpdateColor={(color) => onStateColorsValue("buttonColor", color)}
        color={state.buttonColor}
      />
      <SketchColorPicker
        title={"Button Background"}
        onUpdateColor={(color) =>
          onStateColorsValue("buttonBackgroundColor", color)
        }
        color={state.buttonBackgroundColor}
      />
    </ContentDetail>
  );
};
