import React from "react";
import { get } from "lodash";
import { useQueryWithStore, Loading } from "react-admin";
import { BetterCustomSimpleFormLayout, EditToolbar } from "../../../components";
import { EditFormFields } from "./EditFormFields";

export const CreateForm = (props) => {
  // const websiteId = get(formData, "website_id", 3)
  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "categories",
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "id", order: "ASC" },
      filter: { website_id: get(props, "initialValues".website_id) },
    },
  });
  if (loading) {
    return <Loading />;
  }
  return (
    <BetterCustomSimpleFormLayout
      {...props}
      title={"resources.layouts.back"}
      toolbar={<EditToolbar />}
      isShowBack={true}
      hiddhenSideBar={true}
    >
      <EditFormFields {...props} isCreate={true} categories={data} />
    </BetterCustomSimpleFormLayout>
  );
};
