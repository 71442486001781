import React, { Component } from "react";
import { Flex, Box, Text } from "rebass";
import { IconButton } from "@material-ui/core";
import ActionAdd from "@material-ui/icons/AddCircle";
import ActionDelete from "@material-ui/icons/Delete";
import { DialogCard, DialogHrefContent } from "../components";
import { UploadImageListSlide } from "../../../components";

class CarouselGroupImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: props.list || [],
      open: false,
    };
  }

  handleSave = () => {
    this.props.onChangeText(this.state.list);
  };
  onAddText = () => {
    const newList = this.state.list;
    newList.push({
      imageUrl: {
        url:
          "https://s3-ap-southeast-1.amazonaws.com/oleh-prod/assets/1/product/For-testing-only-UDO.png",
        urlMobile:
          "https://s3-ap-southeast-1.amazonaws.com/oleh-prod/assets/1/product/For-testing-only-UDO.png",
      },
      link: {
        target: "",
        type: "URL",
        value: "",
        isLink: false,
      },
    });
    this.setState({
      list: newList,
    });
  };
  onDelete = (index) => {
    const newList = this.arrayRemove(this.state.list, index);
    this.setState({
      list: newList,
    });
    this.props.onChangeText(newList);
  };
  arrayRemove(arr, value) {
    arr.splice(value, 1);

    return arr;
  }

  onUpdateLink = (value, index) => {
    const { list } = this.state;
    list[index].href.url = value;
    this.setState({
      list: list,
    });
  };
  onUpdateUrl = (value, index) => {
    const { list } = this.state;
    list[index].imageUrl = value;
    this.setState({
      list: list,
    });
  };
  onSetItemLinkValue = (value, index) => {
    const { list } = this.state;
    list[index].link = value;
    this.setState({
      list: list,
    });
  };
  render() {
    const { list } = this.state;
    const { type } = this.props.data.values;
    return (
      <div>
        <DialogCard
          title={"Content List"}
          titleButton={"Content List"}
          onSave={() => this.handleSave()}
          id={"carouselgroup-image"}
        >
          <Box width={[1]}>
            <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Box width={[1]}>
                <Text>Items</Text>
              </Box>
              <Box>
                <IconButton
                  onClick={() => this.onAddText()}
                  style={{ color: "rgb(0, 188, 212)" }}
                >
                  <ActionAdd color={"inherit"} />
                </IconButton>
              </Box>
            </Flex>
            <Box width={[1]}>
              {list.length > 0 &&
                list.map((item, index) => {
                  return (
                    <Flex
                      key={index}
                      width={[1]}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      align={"center"}
                      style={{
                        paddingBottom: 4,
                        borderBottomWidth: 1,
                        borderBottomColor: "rgba(0,0,0,0.2)",
                        borderBottomStyle: "solid",
                      }}
                    >
                      <Box width={[1]}>
                        <Flex width={[1]} flexDirection={"row"}>
                          <UploadImageListSlide
                            type={type}
                            imageUrl={item.imageUrl}
                            onUpdateUrl={(value) =>
                              this.onUpdateUrl(value, index)
                            }
                            title='Image'
                          />
                          <Box width={[1]}>
                            <DialogHrefContent
                              link={item.link}
                              onSetValue={(v) =>
                                this.onSetItemLinkValue(v, index)
                              }
                              websiteId={this.props.websiteId}
                            />
                          </Box>
                        </Flex>
                      </Box>
                      <Box>
                        {list.length > 1 && (
                          <IconButton
                            onClick={() => this.onDelete(index)}
                            style={{ color: "rgb(0, 188, 212)" }}
                          >
                            <ActionDelete color={"inherit"} />
                          </IconButton>
                        )}
                      </Box>
                    </Flex>
                  );
                })}
            </Box>
          </Box>
        </DialogCard>
      </div>
    );
  }
}

export default CarouselGroupImage;
