import React from "react";
import { Box, Flex } from "rebass";
import { ContentDetail } from "../actions";
import {
  DialogIconTemplate,
  Counter,
  SelectInput,
  SwitchInput,
  Title,
  DialogHrefContent,
} from "../components";
import { SketchColorPicker } from "../../../components";
import { dataTypeBorder } from "../button/dataList";
import ButtonContentFields from "./ButtonContentFields";

export default (props) => {
  const { item, onSetItemValue } = props;
  const onSetValue = (k, v) => {
    const newItem = {
      ...item,
      [k]: v,
    };
    onSetItemValue(newItem);
  };

  const updateBorderContent = (k, v) => {
    const newBorder = {
      ...item.border,
      [k]: v,
    };
    onSetValue("border", newBorder);
  };
  const onSetValueDesktop = (k, v) => {
    const newDesktop = {
      ...item.desktop,
      [k]: v,
    };
    onSetValue("desktop", newDesktop);
  };

  const onSetValueMobile = (k, v) => {
    const newMobile = {
      ...item.mobile,
      [k]: v,
    };
    onSetValue("mobile", newMobile);
  };

  return (
    <Box width={[1]} pb={["150px"]}>
      <ContentDetail>
        {/* <SelectInput
          id={`type-flat-button`}
          title={"Type Button"}
          value={item.typeButton}
          list={typeButton}
          onChange={(e) => onSetValue("typeButton", e.target.value)}
        />
        <SelectInput
          id={`label-position-${item.id}`}
          title={"Label Position"}
          value={item.labelPosition}
          list={datalabelPosition}
          onChange={(e) => onSetValue("labelPosition", e.target.value)}
        />
        <TextInput
          value={item.text}
          onChange={(value) => onSetValue("text", value)}
          name='textButton'
          title='Button Text'
          fullWidth={true}
        /> */}
      </ContentDetail>
      <ContentDetail>
        <Title title={"Show Button"} />
        <Flex
          flexDirection={"row"}
          width={[1]}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <SwitchInput
            id={`showDesktop`}
            title='Show on Desktop'
            checked={item.isShowDesktop}
            onChange={(e) => onSetValue("isShowDesktop", e.target.checked)}
          />
          <SwitchInput
            id={`showMobile`}
            title='Show on Mobile'
            checked={item.isShowMobile}
            onChange={(e) => onSetValue("isShowMobile", e.target.checked)}
          />
          <DialogIconTemplate
            iconItem={item.icon}
            onItemSelected={(icon) => {
              onSetValue("icon", icon);
            }}
          />
        </Flex>
      </ContentDetail>
      <ContentDetail>
        <DialogHrefContent
          link={item.link}
          onSetValue={(v) => onSetValue("link", v)}
          websiteId={props.websiteId}
        />
      </ContentDetail>
      <ContentDetail>
        <Title title={"Border"} />
        <Flex
          flexDirection={"row"}
          width={[1]}
          justifyContent={"flex-start"}
          alignItems={"center"}
          flexWrap={"wrap"}
        >
          <Flex width={["320px"]}>
            <SketchColorPicker
              title={"Color"}
              onUpdateColor={(color) => updateBorderContent("color", color)}
              color={item.border.color}
            />
          </Flex>
          <Counter
            isDisabled={false}
            title='Size'
            onSetValue={(value) => updateBorderContent("size", value)}
            value={item.border.size}
            interval={1}
            subtitle='px'
            minValue={0}
          />
          <SelectInput
            id={`border-button`}
            title={"Type Border"}
            value={item.border.type}
            list={dataTypeBorder}
            onChange={(e) => updateBorderContent("type", e.target.value)}
          />
          <Counter
            isDisabled={false}
            title='Border Radius'
            onSetValue={(value) => updateBorderContent("radius", value)}
            value={item.border.radius}
            interval={1}
            subtitle='px'
            minValue={0}
          />
        </Flex>
      </ContentDetail>
      <Box width={[1]} py={[2]}>
        <Title title={"Desktop Settings"} />
        <ButtonContentFields
          typeButton={item.typeButton}
          state={item.desktop}
          title={"Desktop"}
          onSetValue={(k, v) => onSetValueDesktop(k, v)}
        />
      </Box>
      <Box width={[1]} py={[2]}>
        <Title title={"Mobile Settings"} />
        <ButtonContentFields
          typeButton={item.typeButton}
          state={item.mobile}
          title={"Mobile"}
          onSetValue={(k, v) => onSetValueMobile(k, v)}
        />
      </Box>
    </Box>
  );
};
