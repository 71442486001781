import React from "react";
import { get } from "lodash";
import { Flex } from "rebass";
import { ImagePedro } from "./Items";
import { HrefContainer } from "../../../libs/hrefUrl";
/**
 * text: {
                  fontSize: [14, 14],
                  textAlign: ["center", "center"],
                },
 */

const BoxText = (props) => {
  const { title, att, screenWidth } = props;
  const indexItem = screenWidth > 480 ? 1 : 0;
  return (
    <span
      style={{
        width: "100%",
        textAlign: att.textAlign[indexItem],
        fontSize: `${att.fontSize[indexItem]}pt`,
      }}
    >
      {title}
    </span>
  );
};

export default (props) => {
  const { item, size = [1], screenWidth, desktop, colorProps } = props;
  const { imageUrl, link } = item;
  const textAttributes = get(item, "text");
  const title = get(item, "title", "");
  const colorTitle = get(colorProps, "color", "#000000");
  const colorHover = get(colorProps, "hoverColor", "#FFFFFF");
  const titlePadding = get(desktop, "listProps.titlePadding", "0px");
  const padding = get(desktop, "listProps.padding", "0px");
  const hoverBackgroundColor = get(
    colorProps,
    "hoverBackgroundColor",
    "transparent"
  );
  const backgroundColorContainer = get(
    colorProps,
    "backgroundColor",
    "transparent"
  );
  return (
    <Flex
      width={size}
      style={{ padding: padding }}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <HrefContainer link={link}>
        <ImagePedro
          size={[1]}
          images={imageUrl}
          test={"contentitem"}
          screenWidth={screenWidth}
        />
        {title !== "" && (
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"center"}
            style={{ padding: titlePadding }}
            backgroundColor={backgroundColorContainer}
            sx={{
              color: colorTitle,
              fontWeight: "bold",
              "&:hover": {
                cursor: "pointer",
                color: colorHover,
                backgroundColor: hoverBackgroundColor,
              },
            }}
          >
            {textAttributes ? (
              <BoxText
                title={title}
                att={textAttributes}
                screenWidth={screenWidth}
              />
            ) : (
              <span>{title}</span>
            )}
          </Flex>
        )}
      </HrefContainer>
    </Flex>
  );
};
