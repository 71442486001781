import React from "react"
import styled from "styled-components"
import { has, get } from "lodash"
import { Flex, Box, Text } from "rebass"
import { getPhoto } from "./utils"
import Currency from "./Currency"
import { useTranslate } from "react-admin"

const Discount = styled(Box)(props => ({
  width: props.size || "30px",
  height: props.size || "30px",
  fontSize: props.fontSize || "0.8em",
  borderRadius: "50%",
  textAlign: "center",
  verticalAlign: "middle",
  lineHeight: props.size || "30px",
}))

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0.8em 0.8em 0 0;
`

const BoxOverlay = styled.div`
  align-self: flex-end;
  z-index: 1;
  margin-top: -20px;
  padding-right: 10px;
`

const Container = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  height: 100%;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.08);
  border-radius: 0.8em;
`

const ProductInfo = styled(Flex)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0.4em;
  padding-top: 8px;
  min-height: 3rem;
  line-height: 1.3rem;
  background: #fff;
  justify-content: space-between;
  border-bottom-left-radius: 0.8em;
  border-bottom-right-radius: 0.8em;
`

const ProductImage = styled.div`
  display: flex;
  flex;1;
  align-items:center;
  justify-content:center;
`

export const ProductCard = ({
  product,
  width,
  locale,
  itemIndex,
  paddingMiddle,
}) => {
  const isLeft = itemIndex % 2

  if (!has(product, "id")) {
    return <div />
  }

  const name = get(product, `locale[${locale}].name`, product.name)
  const discountedPrice =
    product.discount < 1
      ? product.price * (1 - product.discount)
      : product.price - product.discount

  const discountText = product.discount < 1 ? product.discount * 100 : 0
  const src = product && product.photos ? getPhoto(product.photos) : ""

  const advanced_price = get(product, `advanced_price.prices`, [])
  return (
    <Container
      style={
        isLeft === 0
          ? {
              margin: paddingMiddle,
              marginTop: paddingMiddle,
              marginRight: paddingMiddle / 2,
            }
          : {
              margin: paddingMiddle,
              marginTop: paddingMiddle,
              marginLeft: paddingMiddle / 2,
            }
      }
    >
      <Flex flexDirection="column">
        <ProductImage style={{ height: width }}>
          <Image src={src} alt={name} />
        </ProductImage>
        {discountText > 0 && (
          <BoxOverlay>
            <Discount size="40px" fontSize="1em" bg="pink" color="white">
              {discountText}%
            </Discount>
          </BoxOverlay>
        )}
      </Flex>
      <ProductInfo p={2}>
        <Box pb={2}>{name}</Box>
        <Flex
          width={[1]}
          flexDirection={["row"]}
          justifyContent={"space-between"}
        >
          <Box>
            <Box
              pt={[1]}
              pb={[1]}
              style={
                product.discount > 0
                  ? {
                      textDecorationLine: "line-through",
                      textDecorationStyle: "solid",
                    }
                  : {}
              }
            >
              {product.discount > 0 ? (
                <Currency value={product.price} />
              ) : (
                <div> &nbsp;</div>
              )}
            </Box>
            <Text fontWeight="bold" color="pink">
              <Currency value={discountedPrice} />
            </Text>
          </Box>
          {advanced_price && advanced_price.length > 0 && (
            <PriceTier product={product} />
          )}
        </Flex>
      </ProductInfo>
    </Container>
  )
}

const PriceTier = ({ product }) => {
  const translate = useTranslate()
  const advanced_price = get(product, `advanced_price.prices`, [])
  const discount = product.price * advanced_price[0].discount
  const priceDiscout = product.price - discount
  return (
    <Box>
      <Box pt={[1]} pb={[1]}>
        {translate("resources.products.fields.buy")} &nbsp;
        {advanced_price[0].qty}
      </Box>
      <Text fontWeight="bold" color="pink">
        <Currency value={priceDiscout} />
      </Text>
    </Box>
  )
}
