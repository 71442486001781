import React from "react"
import { Edit } from "react-admin"
import { BetterCustomSimpleForm, EditToolbar, DetailAction } from "./"
import FormFields from "./FormFields"

const EditCartRules = (props) => {
  return (
    <Edit
      {...props}
      actions={
        <DetailAction
          title={"resources.promotions.title.edit_cart"}
          basePath="promotions/cart"
        />
      }
    >
      <BetterCustomSimpleForm
        {...props}
        toolbar={<EditToolbar />}
        sourceApi="promotions"
        title={"resources.promotions.title.edit_cart"}
        typePostUrl={"UPDATE"}
        pathUrl="promotions"
        customBackUrl="/promotions/cart"
      >
        <FormFields {...props} isCreate={false} />
      </BetterCustomSimpleForm>
    </Edit>
  )
}

export default EditCartRules
