import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { translate, WithPermissions } from "react-admin";
import { ROLE_USER } from "../../utils";

import {
  SuperAdminMenu,
  AccountAdminMenu,
  AccountClientMenu,
  OperationMenu,
} from "./RoleMenu";

class Menu extends Component {
  state = {
    menuSettings: false,
  };

  static propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
  };

  handleToggle = (menu) => {
    this.setState((state) => ({ [menu]: !state[menu] }));
  };

  render() {
    const { onMenuClick, open, logout, translate } = this.props;
    const modules = get(this.props, "datauser.modules", []);
    return (
      <Fragment>
        <WithPermissions
          render={({ permissions }) =>
            permissions === ROLE_USER.super_admin && (
              <SuperAdminMenu
                onMenuClick={onMenuClick}
                handleToggle={() => this.handleToggle("menuSettings")}
                menuSettings={this.state.menuSettings}
                open={open}
                translate={translate}
                modules={modules}
              />
            )
          }
        />
        <WithPermissions
          render={({ permissions }) =>
            permissions === ROLE_USER.operation && (
              <OperationMenu
                onMenuClick={onMenuClick}
                handleToggle={() => this.handleToggle("menuSettings")}
                menuSettings={this.state.menuSettings}
                open={open}
                translate={translate}
                modules={modules}
              />
            )
          }
        />
        <WithPermissions
          render={({ permissions }) =>
            permissions === ROLE_USER.account_admin && (
              <AccountAdminMenu
                onMenuClick={onMenuClick}
                handleToggle={() => this.handleToggle("menuSettings")}
                menuSettings={this.state.menuSettings}
                open={open}
                translate={translate}
              />
            )
          }
        />
        <WithPermissions
          render={({ permissions }) =>
            permissions === ROLE_USER.client_admin && (
              <AccountClientMenu
                onMenuClick={onMenuClick}
                translate={translate}
              />
            )
          }
        />

        {logout}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme,
  // locale: state.i18n.locale,
});

const enhance = compose(withRouter, connect(mapStateToProps, {}), translate);

export default enhance(Menu);
