import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

import {
  Editor,
  LayoutState,
  Row1,
  Row2,
  Row3,
  Body,
  MenuView,
  Content,
  PropsPlugin,
  DnD,
  Select,
  MenuGroupView,
  MenuContentView,
} from "../../editor";

const comps = {
  Row1,
  Row2,
  Row3,
  Body,
  Content,
  MenuView,
  MenuGroupView,
  MenuContentView,
};

class HeaderEditorLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: props.categories,
      layoutState: props.data
        ? LayoutState.fromRaw(props.data)
        : new LayoutState(),
      dataEditor: props.data,
      locale: props.locale,
      myref: props.myref,
    };
  }
  // componentDidMount() {
  //   dataProvider
  //     .getList("categories", {
  //       pagination: { page: 1, perPage: 1000 },
  //       sort: { field: "name", order: "ASC" },
  //       filter: { website_id: this.props.websiteId },
  //     })
  //     .then((response) => {
  //       // console.log(response)
  //       if (response && response.data) {
  //         this.setState({ categories: response })
  //       }
  //     })
  // }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, categories } = nextProps;

    if (!isEqual(data, this.state.dataEditor) && data) {
      if (data && data.root) {
        this.setState({
          layoutState: LayoutState.fromRaw(data),
          dataEditor: data,
          categories: categories,
        });
      } else {
        // const newLayout = new LayoutState()
        this.setState({
          layoutState:
            data && data.root ? LayoutState.fromRaw(nextProps.data) : data,
          dataEditor: data,
          categories: categories,
        });
      }
    }
  }
  onResetPage = () => {
    this.setState({
      layoutState: new LayoutState(),
    });
  };
  handleLayoutChange = (layout) => {
    // console.log(this.state.layoutState);
    let newLayout = layout.toRaw();
    // console.log("raw", newLayout)
    // console.log("layout", layout)

    // this.setState({ layoutState: layout })
    this.props.onChangeData(layout, newLayout);
  };

  render() {
    const {
      statusForm,
      isShow,
      typePage,
      marketerId,
      locale,
      websiteId,
    } = this.props;
    return (
      <Editor
        // ref={this.props.myref}
        statusForm={statusForm}
        layoutState={this.state.layoutState}
        onChange={this.handleLayoutChange.bind(this)}
        addons={[PropsPlugin, DnD, Select]}
        components={comps}
        isShow={isShow}
        typePage={typePage}
        marketerId={marketerId}
        onResetPage={() => this.props.onResetPage()}
        widthScreen={this.props.widthScreen}
        heightScreen={this.props.heightScreen}
        locale={locale}
        websiteId={websiteId}
        myTop={80}
        myContainerheight={"80vh"}
        categories={this.state.categories}
        isHeader={true}
      />
    );
  }
}

HeaderEditorLayout.propTypes = {
  data: PropTypes.object,
  onChangeData: PropTypes.func,
  isShow: PropTypes.bool,
  statusForm: PropTypes.string,
  typePage: PropTypes.string,
  marketerId: PropTypes.string,
  locale: PropTypes.string,
};

HeaderEditorLayout.defaultProps = {
  isShow: true,
  statusForm: "edit",
  typePage: "",
  marketerId: "0",
};

export default HeaderEditorLayout;
