import { Layout10 } from "../../TemplateImageSonak";
export const template10A = {
  pa15SsmE: {
    id: "pa15SsmE",
    type: "Row1",
    props: {
      values: {
        name: "Row1",
        type: "Row",
        parent: "Body",
        desktop: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
            contentAlign: "center",
            contentJustify: "center",
          },
          padding: "0px",
          backgroundColor: "rgba(255,255,255,1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              parent: "Row",
              desktop: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                padding: "0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    layout: Layout10,
    childOf: "Row",
    children: ["+Sfg4+xF"],
    content: {
      "43gLLyWF": {
        id: "43gLLyWF",
        type: "CarouselAnimationView",
        props: {
          values: {
            list: [
              {
                bg: {
                  imageUrl: {
                    url: "",
                    mobile: {
                      width: 0,
                      height: 0,
                    },
                    desktop: {
                      width: 0,
                      height: 0,
                    },
                    urlMobile: "",
                  },
                  isBgImage: true,
                  backgroundColor: "#e6e6e6",
                },
                link: {
                  target: "_blank",
                  type: "URL",
                  value: "https://asics.com",
                  isLink: true,
                },
                name: "QueueAnim",
                image: {
                  visible: false,
                  animation: {
                    ease: "easeInQuart",
                    type: "from",
                    delay: 40,
                    opacity: 0,
                    duration: 1500,
                  },
                  isAnimation: false,
                },
                containerTitle: {
                  parent: {
                    alignItems: ["center", "center"],
                    direction: ["column", "row"],
                    justifyContent: ["center", "flex-end"],
                  },
                  title: {
                    width: ["100%", "80%"],
                    justifyContent: ["center", "center"],
                    direction: ["column", "column"],
                    alignItems: ["center", "flex-start"],
                  },
                },
                title: {
                  ease: "",
                  mode: "smooth",
                  size: "40pt",
                  type: "left",
                  align: "left",
                  color: "rgba(255, 255, 255, 1)",
                  delay: 200,
                  value: "Fall/Winter Collection",
                  family: "Rage Italic",
                  isEase: false,
                  italic: true,
                  weight: "bold",
                  visible: true,
                  duration: 1600,
                  sizeSmall: "16pt",
                  lineHeight: "100%",
                  sizeMedium: "30pt",
                  isAnimation: true,
                  letterSpacing: "0px",
                  paddingMobile: "0px 0px 10px 0px",
                  textTransform: "normal",
                  paddingDesktop: "0px",
                },
                url_bg:
                  "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/CarouselAnimationView-url_bg-0-image-desktop-S9Y.jpeg",
                imageUrl: {
                  url: "",
                  mobile: {
                    width: 0,
                    height: 0,
                  },
                  desktop: {
                    width: 0,
                    height: 0,
                  },
                  urlMobile: "",
                },
                position: {
                  boxImage: "500px",
                  marginLeftText: "-100px",
                  marginLeftImage: "50px",
                  marginImageMobile: "0px",
                  marginTitleMobile: "0px",
                  marginImageDesktop: "0px 0px 0px 0px",
                  marginTitleDesktop: "0px 0px 0px 0px",
                  paddingImageMobile: "0px",
                  paddingTitleMobile: "0px",
                  paddingImageDesktop: "0px",
                  paddingTitleDesktop: "0px",
                },
                subtitle: {
                  ease: "easeOutQuart",
                  mode: "sync",
                  size: "36pt",
                  type: "alpha",
                  align: "center",
                  color: "rgba(255, 255, 255, 1)",
                  delay: 100,
                  value: "Shop Now",
                  family: "Montserrat",
                  isEase: true,
                  italic: false,
                  weight: "bold",
                  visible: true,
                  duration: 800,
                  sizeSmall: "14pt",
                  lineHeight: "100%",
                  sizeMedium: "24pt",
                  isAnimation: true,
                  letterSpacing: "0px",
                  paddingMobile: "4px 0px 0px 0px",
                  textTransform: "uppercase",
                  paddingDesktop: "0px",
                },
                url_image: "",
                url_bg_mobile:
                  "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/CarouselAnimationView-url_bg-0-image-desktop-S9Y.jpeg",
                position_image: "left",
                url_image_mobile: "",
              },
              {
                bg: {
                  imageUrl: {
                    url: "",
                    mobile: {
                      width: 0,
                      height: 0,
                    },
                    desktop: {
                      width: 0,
                      height: 0,
                    },
                    urlMobile: "",
                  },
                  isBgImage: true,
                  backgroundColor: "#e6e6e6",
                },
                id: "4ujk5",
                link: {
                  target: "_blank",
                  type: "URL",
                  value: "https://asics.com",
                  isLink: true,
                },
                name: "QueueAnim",
                image: {
                  visible: false,
                  animation: {
                    ease: "easeInQuart",
                    type: "from",
                    delay: 40,
                    opacity: 0,
                    duration: 1500,
                  },
                  isAnimation: false,
                },
                containerTitle: {
                  parent: {
                    alignItems: ["center", "center"],
                    direction: ["column", "row"],
                    justifyContent: ["center", "center"],
                  },
                  title: {
                    width: ["100%", "100%"],
                    justifyContent: ["center", "center"],
                    direction: ["column", "column"],
                    alignItems: ["center", "center"],
                  },
                },
                title: {
                  ease: "",
                  mode: "smooth",
                  size: "40pt",
                  type: "right",
                  align: "center",
                  color: "rgba(255, 255, 255, 1)",
                  delay: 200,
                  value: "Sale New Year",
                  family: "Rage Italic",
                  isEase: false,
                  italic: true,
                  weight: "bold",
                  visible: true,
                  duration: 1600,
                  sizeSmall: "16pt",
                  lineHeight: "100%",
                  sizeMedium: "30pt",
                  isAnimation: true,
                  letterSpacing: "0px",
                  paddingMobile: "0px 0px 10px 0px",
                  textTransform: "normal",
                  paddingDesktop: "0px",
                },
                url_bg:
                  "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/CarouselAnimationView-url_bg-1-image-desktop-WMG.jpeg",
                imageUrl: {
                  url: "",
                  mobile: {
                    width: 0,
                    height: 0,
                  },
                  desktop: {
                    width: 0,
                    height: 0,
                  },
                  urlMobile: "",
                },
                position: {
                  boxImage: "500px",
                  marginLeftText: "-100px",
                  marginLeftImage: "50px",
                  marginImageMobile: "0px",
                  marginTitleMobile: "0px",
                  marginImageDesktop: "0px 0px 0px 0px",
                  marginTitleDesktop: "0px 0px 0px 0px",
                  paddingImageMobile: "0px",
                  paddingTitleMobile: "0px",
                  paddingImageDesktop: "0px",
                  paddingTitleDesktop: "0px",
                },
                subtitle: {
                  ease: "easeOutQuart",
                  mode: "sync",
                  size: "30pt",
                  type: "alpha",
                  align: "center",
                  color: "rgba(255, 255, 255, 1)",
                  delay: 100,
                  value: "Shop Now",
                  family: "Montserrat",
                  isEase: true,
                  italic: false,
                  weight: "600",
                  visible: true,
                  duration: 800,
                  sizeSmall: "14pt",
                  lineHeight: "100%",
                  sizeMedium: "24pt",
                  isAnimation: true,
                  letterSpacing: "0px",
                  paddingMobile: "4px 0px 0px 0px",
                  textTransform: "uppercase",
                  paddingDesktop: "0px",
                },
                url_image: "",
                url_bg_mobile:
                  "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/CarouselAnimationView-url_bg-1-image-desktop-WMG.jpeg",
                position_image: "right",
                url_image_mobile: "",
              },
            ],
            type: "CarouselAnimationView",
            parent: "Content",
            container: {
              auto: true,
              type: "across",
              height: "500px",
              vertical: "center",
              fullHeight: false,
              horizontal: "center",
              heightMobile: "200px",
              autoPlaySpeed: 5000,
              backgroundColor: "rgba(255, 255, 255, 1)",
              paddingTopQuery: ["0px", "0px"],
            },
          },
        },
        parent: {
          id: "+Sfg4+xF",
          idx: 0,
        },
        childOf: "Content",
        children: [],
      },
    },
  },
};
