import React from "react"
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd"
import styled from "styled-components"
import { connect } from "../../core"
import ResponsiveImage from "./ResponsiveImage"
import { target } from "./utils"
// const target = {
//   drop(props, monitor, component) {
//     if (!monitor.didDrop()) {
//       props.layoutState.insertOrMoveItem(
//         props.id,
//         props.children.length,
//         monitor.getItem()
//       );
//     }
//   }
// };

const renderText = value => {
  return <div dangerouslySetInnerHTML={{ __html: value }} />
}

const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
}) => {
  const { containerPadding, backgroundImage, text } = layoutState.getItemJS(
    id
  ).props.values
  return connectDropTarget(
    <div style={{ padding: containerPadding }}>
      <BoxImage>
        <ResponsiveImage
          srcDesktop={backgroundImage.url}
          srcMobile={backgroundImage.urlMobile}
          alt="Image Template"
        />
        <MiddleView>
          <MiddleText
            style={{
              padding: text.containerPadding,
              lineHeight: text.lineHeight,
              letterSpacing: text.letterSpacing,
              backgroundColor: text.backgroundColor,
            }}
          >
            {renderText(text.value)}
          </MiddleText>
        </MiddleView>
      </BoxImage>
    </div>
  )
}

const BoxImage = styled.div`
  position: relative;
  width: 100%;
`
const MiddleView = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const MiddleText = styled.div`
  align-items: center;
  justify-content: center;
`

const LayoutContainer = DropTarget(
  "ImageTextView",
  target,
  (conn, monitor) => ({
    connectDropTarget: conn.dropTarget(),
    isOverCurrent: monitor.isOver({ shallow: true }),
  })
)(EditLayout)

export default connect("layoutState", "readOnly")(LayoutContainer)
