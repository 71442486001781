import React from "react";
import { Flex, Box, Image } from "rebass";
import SearchBox from "./SearchBox";
import Menu3 from "./Menu3";
import Account3 from "./Account3";
const Desktop3 = ({ settings, cart, categories }) => {
  return (
    <Box width={[1]} py={[2]}>
      <Flex
        width={[1, 1]}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        p={[2]}
      >
        <Flex width={[1]} flexDirection={"row"} alignItems={"center"}>
          <Image
            sx={{ width: ["60px", "120px"], height: "auto", mr: [2] }}
            src={settings.header.image}
            alt='logo'
          />

          <Menu3
            isMobile={false}
            settings={settings}
            categories={categories}
            style={{ marginBottom: 0 }}
            marginLeft={"-80px"}
          />
        </Flex>
        <Flex
          style={{ zIndex: 2 }}
          flexDirection={"row"}
          justifyContent={"flex-end"}
        >
          <SearchBox width={"300px"} styleBox={{ borderRadius: 16 }} />
          <Account3 cart={cart} settings={settings} />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Desktop3;
