import React, { Fragment } from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import {
  Alignment,
  BlockCounter,
  Counter,
  SwitchInput,
  Title,
} from "../components";
import { ContentDetail } from "../actions";
import { SketchColorPicker } from "../../../components";
export default (props) => {
  const { title, state, onSetValue, typeButton } = props;
  const onUpdateColor = (k, v) => {
    const newButtonColors = {
      ...state.buttonColors,
      [k]: v,
    };
    onSetValue("buttonColors", newButtonColors);
  };
  const myOrder = get(state, "order", 1);
  return (
    <Fragment>
      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        sx={{
          "&>div": {
            width: ["320px"],
            p: [2],
            mr: [3],
          },
        }}
      >
        <ContentDetail>
          <SketchColorPicker
            title={"Button Color"}
            onUpdateColor={(color) => onUpdateColor("color", color)}
            color={state.buttonColors.color}
          />
          <SketchColorPicker
            title={"Button Background"}
            onUpdateColor={(color) => onUpdateColor("backgroundColor", color)}
            color={state.buttonColors.backgroundColor}
          />
        </ContentDetail>
        {typeButton !== "raised" && (
          <ContentDetail>
            <SketchColorPicker
              title={"Button Hover"}
              onUpdateColor={(color) => onUpdateColor("hoverColor", color)}
              color={state.buttonColors.hoverColor}
            />
          </ContentDetail>
        )}
        {typeButton !== "raised" && (
          <ContentDetail>
            <SketchColorPicker
              title={"Button Ripple"}
              onUpdateColor={(color) => onUpdateColor("rippleColor", color)}
              color={state.buttonColors.rippleColor}
            />
          </ContentDetail>
        )}
        <ContentDetail>
          <Alignment
            value={state.buttonAlign}
            onChange={(value) => onSetValue("buttonAlign", value)}
            title='Button Align'
          />
          <Flex
            flexDirection={"row"}
            width={[1]}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Title title={`Font size ${title}`} />
            <Counter
              onSetValue={(value) => onSetValue("fontSize", value)}
              value={state.fontSize}
              interval={1}
              subtitle='pt'
              minValue={5}
            />
          </Flex>
          <SwitchInput
            id={`full-width-${title}`}
            title={"Full Width"}
            checked={state.fullWidth}
            onChange={(e) => onSetValue("fullWidth", e.target.checked)}
          />
        </ContentDetail>
        <ContentDetail>
          <Flex
            flexDirection={"row"}
            width={[1]}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Title title='Order' />
            <Counter
              onSetValue={(value) => onSetValue("order", value)}
              value={myOrder}
              interval={1}
              subtitle=''
              minValue={1}
            />
          </Flex>
        </ContentDetail>
        <BlockCounter
          containerPadding={state.containerPadding}
          onUpdatePadding={(containerPadding) =>
            onSetValue("containerPadding", containerPadding)
          }
          title={"Container Padding"}
        />
        <BlockCounter
          containerPadding={state.buttonPadding}
          onUpdatePadding={(bp) => onSetValue("buttonPadding", bp)}
          title={"Button Padding"}
        />
      </Flex>
    </Fragment>
  );
};
//ButtonContentFields
