import React from "react";
import { get, find } from "lodash";
import { Box } from "rebass";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
export default (props) => {
  const { updateField, contents, values, translate } = props;
  const onChangeValue = (v) => {
    const getItem = find(contents, function (o) {
      return o.id === v;
    });
    // console.log(getItem);
    updateField("href.value", v);
    updateField("href.slug", get(getItem, "slug", ""));
    updateField("title", get(getItem, "title", ""));
  };
  return (
    <Box width={[1]} py={[3]}>
      <FormControl>
        <InputLabel id='type-content-select-label' shrink={true}>
          {translate("resources.layouts.menu.content")}
        </InputLabel>
        <Select
          labelId='type-content-select-label'
          id='type-content-select'
          name={`href.value-content`}
          value={get(values, "href.value", "")}
          onChange={(e) => onChangeValue(e.target.value)}
          style={{ width: 200 }}
        >
          {contents &&
            contents.length > 0 &&
            contents.map((item, index) => {
              return (
                <MenuItem value={item.id} key={index}>
                  {item.title}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
};
