import React from "react";
import { DropTarget } from "react-dnd";
import { DivTitleDragContent } from "./styled";
import { isShow } from "./utils";
const target = {
  drop(props, monitor) {
    // console.log("content", monitor.getItem());
    if (!monitor.didDrop()) {
      const content = monitor.getItem();
      if (content && content.childOf === "Content") {
        // console.log("content", monitor.getItem());
        props.onDrop(monitor.getItem());
      }
    }
  },
};

const SeparatorContent = ({
  direction,
  connectDropTarget,
  isOverCurrent,
  canDrop,
}) => (
  <div
    style={{
      position: "relative",
      zIndex: 2009,
      width: "100%",
      padding: canDrop ? 4 : 0,
    }}
  >
    {connectDropTarget(
      <div
        style={{
          display: canDrop ? "flex" : "none",
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          width: canDrop ? "100%" : 0,
          height: canDrop ? 30 : 0,
          top: 0,
          left: direction === "vertical" ? 0 : 0,
        }}
      >
        {isOverCurrent ? (
          <div
            style={{
              width: direction === "vertical" ? 4 : "100%",
              height: direction === "vertical" ? "100%" : 4,
              borderRadius: 2,
              marginTop: "-5px",
              backgroundColor: "#4cb9ea",
            }}
          />
        ) : null}
        {isOverCurrent ? <DivTitleDragContent /> : null}
      </div>
    )}
  </div>
);
export default DropTarget("Content", target, (connect, monitor) => {
  const item = monitor.getItem();
  if (item && item !== null) {
    if (isShow(item.type)) {
      return {
        isOverCurrent: monitor.isOver({ shallow: true }),
        connectDropTarget: connect.dropTarget(),
        canDrop: monitor.canDrop(),
      };
    }
  }
  return {
    isOverCurrent: monitor.isOver({ shallow: true }),
    connectDropTarget: connect.dropTarget(),
  };
})(SeparatorContent);
