import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

class TextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
    this.onChange = this.onChange.bind(this);
  }
  onChange = (event) => {
    this.setState({ value: event.target.value });
    this.props.onChange(event.target.value);
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    if (value !== this.props.value) {
      this.setState({ value: value });
    }
  }
  render() {
    const {
      title,
      name,
      fullWidth,
      paddingTop,
      style,
      placeholder,
    } = this.props;
    return (
      <div style={{ width: "100%", paddingTop: paddingTop }}>
        <TextField
          name={name}
          id={name}
          value={this.state.value}
          label={title}
          placeholder={placeholder}
          fullWidth={fullWidth}
          onChange={this.onChange}
          InputLabelProps={{
            shrink: true,
            value: this.state.value,
          }}
          variant='filled'
          style={style}
        />
      </div>
    );
  }
}
TextInput.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  paddingTop: PropTypes.number,
};

TextInput.defaultProps = {
  title: "Alternate Text",
  value: "alternatif text",
  name: "textinput",
  placeholder: "",
  fullWidth: false,
  paddingTop: 16,
  style: {},
};
export default TextInput;
