import React, { Component } from "react"
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd"
import { Flex } from "rebass"
import ContainerDimensions from "react-container-dimensions"
import { DivEmptyContent } from "./styled"
import SeparatorContent from "./SeparatorContent"
import { connect } from "../../core"

const target = {
  drop(props, monitor, component) {
    if (!monitor.didDrop()) {
      const dragType = monitor.getItem().type
      const { values } = monitor.getItem().props
      const hoverType = props.type
      if (dragType !== hoverType && values.parent === "Content") {
        props.layoutState.insertOrMoveItem(
          props.id,
          props.children.length,
          monitor.getItem()
        )
      }
    }
  },
}

const defaultBackground = {
  backgroundColorParent: "rgba(255,255,255,0)",
  backgroundColor: "rgba(255,255,255,0)",
  backgroundImage: {
    url: "",
    urlMobile: "",
  },
  bgProps: {
    size: "cover",
    position: "center",
    repeat: "no-repeat",
    ratio: 1.2,
  },
  isBackgroundImage: false,
  padding: "0px",
  width: {
    type: "%",
    contentWidth: 100,
  },
  alignment: {
    vertical: "center",
    horizontal: "center",
  },
}

const renderWithBackgroundImage = (
  values,
  children,
  contentPadding,
  bgColor,
  alignment,
  width,
  bgColorParent
) => {
  const bgProps = values.bgProps ? values.bgProps : defaultBackground.bgProps
  const srcMobile =
    values.backgroundImage.urlMobile || values.backgroundImage.url || ""
  const srcDesktop =
    values.backgroundImage.url || values.backgroundImage.urlMobile || ""
  const important = {
    backgroundImage: `url("${srcDesktop}")`,
    backgroundSize: bgProps.size,
    backgroundPosition: bgProps.position,
    backgroundRepeat: bgProps.repeat,
  }
  const widthContent = `${width.contentWidth}${width.type}`
  return (
    <ContainerDimensions>
      {({ width, height }) => {
        const minHeight = width / bgProps.ratio
        if (width < 768) {
          important.backgroundImage = `url("${srcMobile}")`
        }
        return (
          <div
            style={{
              display: "flex",
              flex: 1,
              backgroundColor: bgColorParent,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: alignment.vertical,
                justifyContent: alignment.horizontal,
                ...important,
                minHeight: minHeight,
                padding: contentPadding,
              }}
            >
              <Flex
                width={[1, widthContent]}
                flexDirection={"column"}
                style={{ backgroundColor: bgColor }}
              >
                {children.length < 1 && (
                  <DivEmptyContent>
                    No content Carousel yet. Drag a content from the left to
                    here
                  </DivEmptyContent>
                )}
                {children}
              </Flex>
            </div>
          </div>
        )
      }}
    </ContainerDimensions>
  )
}

const renderWithBackgroundColor = (
  values,
  children,
  contentPadding,
  bgColor,
  alignment,
  width,
  bgColorParent
) => {
  const widthContent = `${width.contentWidth}${width.type}`
  let important = { backgroundColor: bgColorParent }
  if (values.isGradient) {
    if (values.gradient && values.gradient.type) {
      const { gradient } = values
      const mydegre =
        gradient.type === "radial-gradient" ? "circle" : `${gradient.degre}deg`
      const newBg = {
        background: `${gradient.type}(${mydegre}, ${gradient.bg1} ${gradient.linear[0]}%,${gradient.bg2} ${gradient.linear[1]}%)`,
      }
      important = { background: gradient.bg1, height: "100%", ...newBg }
    }
  }
  return (
    <ContainerDimensions>
      {({ width, height }) => {
        return (
          <div
            style={{
              padding: contentPadding,
              display: "flex",
              flex: 1,
              ...important,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: alignment.vertical,
                justifyContent: alignment.horizontal,
              }}
            >
              <Flex
                width={[1, widthContent]}
                flexDirection={"column"}
                style={{ backgroundColor: bgColor }}
              >
                {children.length < 1 && (
                  <DivEmptyContent>
                    No content Carousel yet. Drag a content from the left to
                    here
                  </DivEmptyContent>
                )}
                {children}
              </Flex>
            </div>
          </div>
        )
      }}
    </ContainerDimensions>
  )
}

class EditLayout extends Component {
  // constructor(props) {
  //   super(props);
  //   // console.log(props);
  // }
  render() {
    const {
      id,
      children,
      connectDropTarget,
      layoutState,
      readOnly,
    } = this.props

    let parsedChildren = []
    if (!readOnly && children.length) {
      for (let i = 0; i < children.length * 2 + 1; i++) {
        if (i % 2 === 0) {
          parsedChildren.push(
            <SeparatorContent
              key={`${id}-seperator-${i}`}
              onDrop={(item) => layoutState.insertOrMoveItem(id, i / 2, item)}
              direction={"horizontal"}
            />
          )
        } else {
          parsedChildren.push(children[(i - 1) / 2])
        }
      }
    } else {
      parsedChildren = children
    }

    const { parent, props } = layoutState.getItemJS(id)

    const { values } = props

    const bgColor = values.backgroundColor
      ? values.backgroundColor
      : defaultBackground.backgroundColor
    const bgColorParent = values.backgroundColorParent
      ? values.backgroundColorParent
      : defaultBackground.backgroundColorParent
    const contentPadding = values.padding
      ? values.padding
      : defaultBackground.padding
    const contentWidth = values.width ? values.width : defaultBackground.width
    const alignment = values.alignment
      ? values.alignment
      : defaultBackground.alignment

    const isBackgroundImage = values.isBackgroundImage
      ? renderWithBackgroundImage(
          values,
          parsedChildren,
          contentPadding,
          bgColor,
          alignment,
          contentWidth,
          bgColorParent
        )
      : renderWithBackgroundColor(
          values,
          parsedChildren,
          contentPadding,
          bgColor,
          alignment,
          contentWidth,
          bgColorParent
        )

    const { carousel } = layoutState.getItemJS(parent.id).props.values
    const childrenParent = layoutState.getItemJS(parent.id).children

    if (
      carousel &&
      carousel.keyActive &&
      carousel.keyActive === "" &&
      childrenParent[0] === id
    ) {
      return connectDropTarget(
        <div className={values.className} style={{ display: "flex" }}>
          {isBackgroundImage}
        </div>
      )
    } else if (carousel && carousel.keyActive && carousel.keyActive === id) {
      return connectDropTarget(
        <div className={values.className} style={{ display: "flex" }}>
          {isBackgroundImage}
        </div>
      )
    } else {
      return null
    }
  }
}

const LayoutContainer = DropTarget("Content", target, (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(EditLayout)

export default connect("layoutState", "readOnly")(LayoutContainer)

// export default LayoutContainer;s
