import React, { Component } from "react";
import PropsTypes from "prop-types";
import { get } from "lodash";
import { Box } from "rebass";
import ChipInput from "material-ui-chip-input";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";

const StyledChipInput = withStyles({
  root: {
    minWidth: 500,
    marginTop: 0,
  },
  labeled: {
    marginTop: 0,
  },
  inputRoot: { marginTop: 0 },
  input: { marginTop: 0 },
  helperText: { marginTop: 0 },
  label: {
    textTransform: "capitalize",
  },
  chip: { marginTop: 0 },
  standard: {
    marginTop: 0,
  },
  chipContainer: {
    marginTop: 0,
    "&$labeled&$standard": {
      marginTop: 0,
    },
  },
})(ChipInput);

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

class EditSearchChipInput extends Component {
  constructor(props) {
    super(props);
    const values = get(props, "dataOptions", []);
    this.state = {
      chip: values,
      defaultChip: values,
      autoHideDuration: 2000,
      message: "This Item Can't delete",
      open: false,
    };
  }
  handleAddChip = (valueChip) => {
    const { chip } = this.state;
    const values = [...chip, valueChip];
    this.setState({ chip: values });
    this.props.updateField(this.props.input.name, values);
  };
  handleDeleteChip = (valueChip, index) => {
    const { chip } = this.state;
    //defaultChip,
    const values = chip.filter((v) => v !== valueChip);
    this.setState({ chip: values });
    this.props.updateField(this.props.input.name, values);
    // const isHaveValue = defaultChip.filter((v) => v === valueChip);
    // if (isHaveValue.length === 0) {
    //   const values = chip.filter((v) => v !== valueChip);
    //   this.setState({ chip: values });
    //   this.props.updateField(this.props.input.name, values);
    // } else {
    //   const message = "Item " + isHaveValue[0] + " Can't delete";
    //   this.setState({ open: true, message });
    // }
  };
  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { label } = this.props;
    const { chip, open, message } = this.state;
    return (
      <Box width={[1]} pb={[2]}>
        <StyledChipInput
          label={label ? label : ""}
          value={chip}
          onAdd={(chip) => this.handleAddChip(chip)}
          onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
          InputLabelProps={{ shrink: true }}
        />
        <Snackbar
          open={open}
          autoHideDuration={4000}
          onClose={this.handleRequestClose}
        >
          <Alert onClose={this.handleRequestClose} severity='error'>
            {message}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

EditSearchChipInput.propTpes = {
  onUpdate: PropsTypes.func,
};

export default EditSearchChipInput;
