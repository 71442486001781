import React from "react";
import { Counter, SwitchInput, Title } from "../components";
import { ContentDetail } from "../actions";

export default (props) => {
  const { state, onSetValue, contentId } = props;
  const onStateSettingValue = (k, v) => {
    const params = {
      ...state,
      [k]: v,
    };
    onSetValue(params);
  };
  return (
    <ContentDetail>
      <SwitchInput
        id={`arrows-setting-${contentId}`}
        title={"Arrows"}
        name={"arrows"}
        checked={state.arrows}
        onChange={(e) => onStateSettingValue("arrows", e.target.checked)}
      />
      <SwitchInput
        id={`autoplay-setting-${contentId}`}
        title={"Autoplay"}
        name={"autoplay"}
        checked={state.autoplay}
        onChange={(e) => onStateSettingValue("autoplay", e.target.checked)}
      />
      <Title title={"Speed"} fontWeight={"bold"} pt={[1]} />
      <Counter
        onSetValue={(value) => onStateSettingValue("speed", value)}
        value={state.speed}
        interval={10}
        subtitle=''
        minValue={1}
      />
      <Title title={"SlidesToShow"} fontWeight={"bold"} pt={[1]} />
      <Counter
        onSetValue={(value) => onStateSettingValue("slidesToShow", value)}
        value={state.slidesToShow}
        interval={1}
        subtitle=''
        minValue={1}
      />
      <Title title={"SlidesToScroll"} fontWeight={"bold"} pt={[1]} />
      <Counter
        onSetValue={(value) => onStateSettingValue("slidesToScroll", value)}
        value={state.slidesToScroll}
        interval={1}
        subtitle=''
        minValue={1}
      />
      <Title title={"SlidesPerRow"} fontWeight={"bold"} pt={[1]} />
      <Counter
        onSetValue={(value) => onStateSettingValue("slidesPerRow", value)}
        value={state.slidesPerRow}
        interval={1}
        subtitle=''
        minValue={0}
      />
    </ContentDetail>
  );
};
