import React, { useState } from "react";
import { get, find } from "lodash";
import { Popover, Button } from "antd";
import { useWindowSize } from "../../../../../components/hooks";
import { MenuItem, MenusList } from "./MenuDesktop";
const BOX_SHADOW_COLOR = `rgba(66, 66, 66, 0.2) 0px 3px 3px -2px, rgba(66, 66, 66, 0.14) 0px 3px 4px 0px, rgba(66, 66, 66, 0.12) 0px 1px 8px 0px`;

const ListMenu = (props) => {
  const { settings, categories } = props;
  const screens = useWindowSize();
  const widthScreen = get(screens, "width", 400) - 14;
  const list = get(settings, "header.list", []);
  const titleButton = get(settings, "header.titleButton", "");
  const newList = list.map((item, index) => {
    let myLabel = item.title;
    let myItem = { item: item };
    if (item.type === "category" && categories) {
      const category = find(categories, function (o) {
        return o.category_id === item.category_id;
      });
      myLabel = get(category, "title", "");
      myItem = { item: item, category: category };
    }

    return { key: index, label: myLabel, items: <MenuItem {...myItem} /> };
  });

  return (
    <div
      className={"menu-container"}
      style={{ position: "absolute", marginLeft: 60 }}
    >
      <Popover
        placement='bottomLeft'
        autoAdjustOverflow={false}
        overlayStyle={{
          height: "auto",
          boxShadow: BOX_SHADOW_COLOR,
        }}
        color={"#FFFFFF"}
        title={""}
        content={
          <div
            style={{
              position: "relative",
              marginLeft: "-14px",
              width: widthScreen,
            }}
          >
            <MenusList newList={newList} />
          </div>
        }
        // trigger='click'
      >
        <Button type={"text"}>
          <span style={{ color: "#FFFFFF" }}>{titleButton}</span>
        </Button>
      </Popover>
      {/* <Menus
        mode="horizontal"
        onClick={onClickItem}
        onClose={onClose}
        style={{ backgroundColor: "tranparent" }}
      >
        {list.length > 0 &&
          list.map((menuParent, index) => {
            if (menuParent.type === "category" && categories) {
              const category = find(categories, function (o) {
                return o.category_id === menuParent.category_id
              })
              if (category && category.title) {
                return (
                  <SubMenu
                    key={`${category.title}-${index}`}
                    title={category.title}
                  >
                    {category.children.map((childMenu, indexChild) => {
                      const { children } = childMenu
                      if (children && children.length > 0) {
                        return (
                          <SubMenu
                            key={`${childMenu.title}-${indexChild}`}
                            title={childMenu.title}
                          >
                            {children.map((grandchild, indexGrandchild) => {
                              const { title } = grandchild
                              return (
                                <Menus.Item key={`${title}-${indexGrandchild}`}>
                                  {title}
                                </Menus.Item>
                              )
                            })}
                          </SubMenu>
                        )
                      }
                      return (
                        <Menus.Item key={`${childMenu.title}-${indexChild}`}>
                          {childMenu.title}
                        </Menus.Item>
                      )
                    })}
                  </SubMenu>
                )
              }
            }
            return <Menus.Item key={index}>{menuParent.title}</Menus.Item>
          })}
      </Menus>
     */}
    </div>
  );
};

const Menu = (props) => {
  const [show, setShow] = useState(false);
  return (
    <ListMenu
      show={show}
      onClose={() => setShow(false)}
      categories={props.categories}
      settings={props.settings}
      style={props.style}
    />
  );
};
export default Menu;
