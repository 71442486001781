import React from "react"
import { Flex, Box } from "rebass"
import { FieldArray } from "react-final-form-arrays"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import IconButton from "@material-ui/core/IconButton"
import AddIcon from "@material-ui/icons/AddCircleOutline"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import { makeStyles } from "@material-ui/core/styles"
import { dataCriteriaType } from "./utils"
import CriteriaFields from "./CriteriaFields"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "./ExpandPanel"
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: { marginBottom: 16 },
}))

const CriteriaItems = ({ fields, meta: { error, submitFailed }, ...rest }) => {
  const classes = useStyles()
  const { translate } = rest
  const [criteriaType, setCriteriaType] = React.useState("Product")
  const handleChange = event => {
    setCriteriaType(event.target.value)
  }
  return (
    <Box width={[1]} mb={[2]}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        mt={[2]}
      >
        <FormControl className={classes.formControl}>
          <InputLabel id="criteria-type-select-label">
            {translate("resources.promotions.fields.criteria_type")}
          </InputLabel>
          <Select
            labelId="criteria-type-select-label"
            id="criteria-type-select"
            value={criteriaType}
            onChange={handleChange}
            style={{ width: 256 }}
          >
            {dataCriteriaType &&
              dataCriteriaType.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Box ml={[2]}>
          <IconButton
            onClick={() => {
              return fields.push({
                type: criteriaType,
                operator: "contains",
                collections: [],
                products: [],
                categories: [],
                cart: {
                  attribute: "Subtotal",
                },
              })
            }}
          >
            <AddIcon color={"primary"} />
          </IconButton>
        </Box>
      </Flex>

      <Box width={[1]} mt={[3]}>
        {fields.map((items, index) => (
          <CriteriaFields
            key={index}
            items={items}
            index={index}
            fields={fields}
            classes={classes}
            {...rest}
          />
        ))}
      </Box>
    </Box>
  )
}

const CriteriaList = props => {
  const { translate } = props
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="criteria-content"
        id="criteria-header"
      >
        <Typography>
          {translate("resources.promotions.panel.criteria")}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Flex width={[1]}>
          <FieldArray name="criteria" component={CriteriaItems} {...props} />
        </Flex>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
export default CriteriaList
