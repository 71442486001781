export const dataCriteriaType = [
  {
    id: "Product",
    name: "Product",
  },
  {
    id: "Collection",
    name: "Collection",
  },
  {
    id: "Category",
    name: "Category",
  },
  {
    id: "Cart",
    name: "Cart",
  },
]
export const dataDiscountType = [
  {
    id: "Percent",
    name: "Percent",
  },
  {
    id: "Amount",
    name: "Amount",
  },
]

export const dataOperator = [
  {
    id: "contains",
    name: "Contains",
  },
  {
    id: "ncontains",
    name: "Doesn’t contain",
  },
]

export const dataPromotionTypeCart = [
  {
    id: "Discount on Product",
    name: "Discount on Product",
  },
  {
    id: "Discount on Cart",
    name: "Discount on Cart",
  },
  {
    id: "Buy X Get Y",
    name: "Buy X Get Y",
  },
  {
    id: "Shipping Cost",
    name: "Shipping Cost",
  },
  {
    id: "Next Item Of Same Category",
    name: "Next Item Of Same Category",
  },
]

export const dataAttributes = [
  {
    id: "Subtotal",
    name: "Subtotal",
  },
  {
    id: "Total Item Quantity",
    name: "Total Item Quantity",
  },
  {
    id: "Payment Method",
    name: "Payment Method",
  },
  {
    id: "Shipping Weight",
    name: "Shipping Weight",
  },
  {
    id: "Shipping Postal Code",
    name: "Shipping Postal Code",
  },
  {
    id: "Shipping Region",
    name: "Shipping Region",
  },
  {
    id: "Shipping State",
    name: "Shipping State",
  },
]
