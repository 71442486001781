import React, { useState, Fragment } from "react";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useRefresh, useTranslate, useNotify } from "react-admin";
import { RestApiCRUDString } from "../../restClient";
export default (props) => {
  const { record } = props;
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const onSubmit = () => {
    if (state.name !== "" && record && record.id) {
      setLoading(true);
      RestApiCRUDString(`/websiteLayouts/${record.id}/duplicate`, "POST", {
        name: state.name,
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          console.log("create shipment", res);
          if (res) {
            notify("ra.action.create", "success");
            setOpen(false);
            refresh();
          } else {
            notify("resources.errors.please_try_again", "warning", {
              smart_count: 1,
            });
          }
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          notify("resources.errors.please_try_again", "warning", {
            smart_count: 1,
          });
        });
    } else {
      notify("resources.errors.please_try_again", "warning", {
        smart_count: 1,
      });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSetValue = (k, v) => {
    setState({
      ...state,
      [k]: v,
    });
  };
  return (
    <Fragment>
      <IconButton
        style={{ textAlign: "right", fontSize: 14, padding: 7 }}
        onClick={() => setOpen(true)}
      >
        <FileCopyIcon color={"primary"} style={{ fontSize: 14 }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title-duplicate-form'
      >
        <DialogTitle id='form-dialog-title-duplicate-form'>
          {translate("resources.layouts.messages.header_message_duplicate")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate("resources.layouts.messages.content_message_duplicate")}
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Name'
            value={state.name}
            type='text'
            onChange={(e) => onSetValue("name", e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {translate("resources.buttons.cancel")}
          </Button>
          <Button
            onClick={onSubmit}
            color='primary'
            startIcon={loading ? <CircularProgress size={22} /> : null}
          >
            {translate("resources.buttons.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
