import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { includes } from "lodash"
import { Flex } from "rebass"
import { BoxContent } from "../styled"
import {
  BlockCounter,
  TextInput,
  Alignment,
  SwitchInput,
  PixelPercent,
  Counter,
} from "../components"
import {
  FooterAction,
  ContainerPanel,
  ContentDetail,
  PanelCard,
} from "../actions"

class YoutubeView extends Component {
  constructor(props) {
    super(props)
    const {
      containerPadding,
      alignValue,
      fullwidth,
      videoId,
      width,
    } = props.data.values
    let widthType = "%"
    let widthValue = width ? width.split("%").join("") * 1 : 100
    if (includes(width, "px")) {
      widthValue = width ? width.split("px").join("") * 1 : 100
      widthType = "px"
    }

    this.state = {
      heightContent: props.heightContent,
      widthContent: props.widthContent,
      fullwidth: fullwidth,
      alignValue: alignValue,
      containerPadding: containerPadding,
      videoId,
      width: widthValue,
      type: widthType,
      maxValueWidth: widthType === "px" ? 0 : 100,
      interval: widthType === "px" ? 20 : 1,
    }
  }

  onClickSave = () => {
    let newData = this.props.data
    newData.values.videoId = this.state.videoId
    newData.values.alignValue = this.state.alignValue
    newData.values.containerPadding = this.state.containerPadding
    newData.values.fullwidth = this.state.fullwidth
    newData.values.width = this.state.width + this.state.type
    this.props.onUpdateData(newData)
  }
  onSetValue = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value,
    })
  }
  onClickCopy = () => {
    let newData = this.props.data
    newData.values.videoId = this.state.videoId
    newData.values.alignValue = this.state.alignValue
    newData.values.containerPadding = this.state.containerPadding
    newData.values.fullwidth = this.state.fullwidth
    newData.values.width = this.state.width + this.state.type
    localStorage.setItem("YoutubeView", JSON.stringify(newData.values))
  }
  onClickPaste = () => {
    const dividerContent = JSON.parse(localStorage.getItem("YoutubeView"))
    if (dividerContent) {
      const {
        containerPadding,
        fullwidth,
        alignValue,
        videoId,
        width,
      } = dividerContent
      let widthType = "%"
      let widthValue = width ? width.split("%").join("") * 1 : 100
      if (includes(width, "px")) {
        widthValue = width ? width.split("px").join("") * 1 : 100
        widthType = "px"
      }
      this.setState({
        videoId,
        fullwidth,
        alignValue,
        containerPadding,
        width: widthValue,
        type: widthType,
      })
    }
  }

  onChangeType = event => {
    let width = 0
    let content = this.state.width
    let interval = 20
    if (event.target.value === "%") {
      width = 100
      content = 100
      interval = 1
    }
    this.setState({
      type: event.target.value,
      maxValueWidth: width,
      width: content,
      interval: interval,
    })
  }

  render() {
    return (
      <Flex width={[1]} flexDirection={"column"}>
        <BoxContent style={{ height: this.state.heightContent - 120 }}>
          <ContainerPanel>
            <PanelCard title="Content Youtube" id="content-youtube-view">
              <ContentDetail>
                <TextInput
                  value={this.state.videoId}
                  onChange={value => this.onSetValue("videoId", value)}
                  name="videoId"
                  title="Video Id"
                  fullWidth={true}
                />
              </ContentDetail>
              <ContentDetail>
                <SwitchInput
                  id={`full-width`}
                  title={"Full Width"}
                  checked={this.state.fullwidth}
                  onChange={e => this.onSetValue("fullwidth", e.target.checked)}
                />
                {!this.state.fullwidth && (
                  <Fragment>
                    <PixelPercent
                      name="widthview"
                      value={this.state.type}
                      onChange={this.onChangeType}
                      title="Width"
                    />
                    <Flex
                      flexDirection={"row"}
                      width={[1]}
                      justifyContent={"flex-end"}
                    >
                      <Counter
                        isDisabled={false}
                        onSetValue={value => this.onSetValue("width", value)}
                        value={this.state.width}
                        interval={1}
                        maxValue={this.state.maxValueWidth}
                      />
                    </Flex>
                  </Fragment>
                )}
              </ContentDetail>
              <ContentDetail>
                <Alignment
                  value={this.state.align}
                  onChange={value => this.onSetValue("alignValue", value)}
                  title="Alignment"
                />
              </ContentDetail>
              <BlockCounter
                title={"Container Padding"}
                containerPadding={this.state.containerPadding}
                onUpdatePadding={cp => this.onSetValue("containerPadding", cp)}
              />
            </PanelCard>
          </ContainerPanel>
        </BoxContent>
        <FooterAction
          onClickSave={this.onClickSave}
          onClickCopy={this.onClickCopy}
          onClickPaste={this.onClickPaste}
          widthContent={this.state.widthContent}
        />
      </Flex>
    )
  }
}

YoutubeView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
}

YoutubeView.defaultProps = {
  data: {},
}
export default YoutubeView
