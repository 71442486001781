import React from "react"
import { Flex, Box, Text } from "rebass"

import { IconButton } from "@material-ui/core"
import ActionAdd from "@material-ui/icons/AddCircle"
import ActionDelete from "@material-ui/icons/Delete"

import { TextInput, DialogCard, SelectInput } from "../components"
import { SketchColorPicker } from "../../../components"
const dataHref = [
  { text: "Same Page", value: "same" },
  { text: "New Page", value: "_blank" },
]

const dataType = [
  { text: "Facebook", value: "facebook" },
  { text: "Instagram", value: "instagram" },
  { text: "Twitter", value: "twitter" },
  { text: "Youtube", value: "youtube" },
  { text: "Whatsapp", value: "whatsapp" },
]

const arrayRemove = (arr, value) => {
  arr.splice(value, 1)

  return arr
}

const DialogList = (props) => {
  const { list, onSetValue } = props
  const onAddItem = () => {
    let newMenu = list
    newMenu.push({
      url: "",
      target: "",
      type: "facebook",
    })

    onSetValue("list", newMenu)
  }
  const handleItem = (key, value, index) => {
    let mylist = list
    let newList = list[index]
    newList[key] = value
    mylist[index] = newList
    onSetValue("list", mylist)
  }
  const onDelete = (index) => {
    const newList = arrayRemove(list, index)
    onSetValue("list", newList)
  }

  return (
    <Box width={[1]} p={[2]}>
      <DialogCard
        title={"Social Media"}
        titleButton={"Social Media"}
        id={"Social-media-view"}
        hiddenAction={true}
      >
        <Box width={[1]}>
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Box width={[1]}>
              <Text>Items</Text>
            </Box>
            <Box>
              <IconButton
                onClick={onAddItem}
                style={{ color: "rgb(0, 188, 212)" }}
              >
                <ActionAdd color={"inherit"} />
              </IconButton>
            </Box>
          </Flex>
          <Box width={[1]}>
            {list.map((item, index) => {
              return (
                <Flex
                  key={index}
                  width={[1]}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  align={"center"}
                  style={{
                    paddingBottom: 4,
                    borderBottomWidth: 1,
                    borderBottomColor: "rgba(0,0,0,0.2)",
                    borderBottomStyle: "solid",
                  }}
                >
                  <Box>
                    <Flex width={[1]} flexDirection={"row"}>
                      <Flex
                        width={[1]}
                        flexDirection={"row"}
                        sx={{
                          "&> *": {
                            p: [2],
                          },
                        }}
                      >
                        <SelectInput
                          id={`type-${index}`}
                          title={"Type"}
                          value={item.type}
                          list={dataType}
                          onChange={(e) =>
                            handleItem("type", e.target.value, index)
                          }
                        />

                        <SelectInput
                          id={`target-${index}`}
                          title={"Target"}
                          value={item.target}
                          list={dataHref}
                          onChange={(e) =>
                            handleItem("target", e.target.value, index)
                          }
                        />
                        <Box style={{ position: "relative" }} pr={["100px"]}>
                          <Box py={[2]} style={{ position: "absolute" }}>
                            <SketchColorPicker
                              title={"Color"}
                              onUpdateColor={(color) =>
                                handleItem("color", color, index)
                              }
                              color={item.color}
                            />
                          </Box>
                        </Box>
                        <TextInput
                          value={item.url}
                          style={{ minWidth: 300 }}
                          onChange={(value) => handleItem("url", value, index)}
                          name={`url-${index}`}
                          id={`url-${index}`}
                          title="Url"
                        />
                      </Flex>
                    </Flex>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => onDelete(index)}
                      style={{ color: "rgb(0, 188, 212)" }}
                    >
                      <ActionDelete color={"inherit"} />
                    </IconButton>
                  </Box>
                </Flex>
              )
            })}
          </Box>
          <Box style={{ height: 300 }} />
        </Box>
      </DialogCard>
    </Box>
  )
}

export default DialogList
