import React, { useState } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { Flex } from "rebass";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import { RemoveCircle } from "@material-ui/icons";
import { UploadClient } from "../../restClient";
import { styles } from "./styles";
import {
  ERROR_MESSAGE_MAX_SIZE,
  ERROR_MESSAGE_FAILED,
  BoxUploadSmall,
  BoxInsideSmall,
} from "./utils";
import "./index.css";

const ImageItem = (props) => {
  const { state, onRemove } = props;
  return (
    <div style={styles.gridImage}>
      <div style={styles.container}>
        <Button
          style={styles.removeButton}
          onClick={() => onRemove(state.photo)}
        >
          <RemoveCircle color={"secondary"} />
        </Button>
        <img
          src={state.photo}
          style={styles.imageContainer}
          alt='delete'
          title='photos'
        />
      </div>
    </div>
  );
};
//
const ImageField = (props) => {
  const {
    accept,
    disableClick,
    elStyle,
    maxSize,
    minSize,
    multiple,
    labelImage,
    source,
    toValue,
  } = props;
  const [state, setState] = useState({
    isShow: props.photo && props.photo !== "",
    photo: props.photo,
    isLoading: false,
    openSnackBar: false,
    message: "Please try again",
    variant: "success",
  });
  const onDrop = (accepted, rejected) => {
    if (accepted.length > 0 && rejected.length === 0) {
      setState({
        ...state,
        isLoading: true,
      });
      const name = toValue;
      //   const flag = labelImage;
      let url = "/products/upload";

      if (name !== undefined && name !== "") {
        let brand = name.trim();
        brand = brand.replace(/\s+/g, "-");
        brand = brand.replace(/[&/\\#,+[/\]()$~%.@'"^:*!`?<>{}]/g, "");

        let joinData = brand;
        let formData = new FormData();
        // caption as brand-product name
        formData.append("caption", joinData);
        accepted.forEach((file) => {
          formData.append("photos", file, file.name);
        });

        UploadClient(formData, url)
          .then((response) => response.json())
          .then((data) => {
            if (data && data.url) {
              onAdd(data.url);
            } else {
              setState({
                ...state,
                isLoading: false,
                openSnackBar: true,
                variant: "error",
                message: ERROR_MESSAGE_FAILED,
              });
            }
          })
          .catch((e) => {
            setState({
              ...state,
              isLoading: false,
              openSnackBar: true,
              variant: "error",
              message: ERROR_MESSAGE_FAILED,
            });
          });
      }
    } else {
      setState({
        ...state,
        isLoading: false,
        openSnackBar: true,
        variant: "error",
        message: ERROR_MESSAGE_MAX_SIZE,
      });
    }
  };
  const onAdd = (url) => {
    setState({
      ...state,
      photo: url,
      isShow: true,
      isLoading: false,
      openSnackBar: false,
      message: "",
    });
    props.updateField(source, url);
  };
  const handleRequestClose = () => {
    setState({
      ...state,
      openSnackBar: false,
    });
  };
  const onRemove = () => {
    setState({ ...state, photo: "", isShow: false });
    //props.input.onChange("")
    props.updateField(source, "");
  };
  return (
    <div style={elStyle}>
      <div className='labelImage'>{labelImage}</div>
      <Flex width={[1]} flexDirection={"row"}>
        <Dropzone
          onDrop={onDrop}
          accept={accept}
          disableClick={disableClick}
          maxSize={maxSize}
          minSize={minSize}
          multiple={multiple}
        >
          {({ getRootProps, getInputProps }) => (
            <BoxUploadSmall>
              <div {...getRootProps()} style={{ display: "flex" }}>
                <input {...getInputProps()} />
                {state.isLoading && (
                  <div style={styles.insideBox}>
                    <CircularProgress />
                  </div>
                )}
                {!state.isLoading && <BoxInsideSmall />}
              </div>
            </BoxUploadSmall>
          )}
        </Dropzone>
        {state.isShow && (
          <div className='previews'>
            <ImageItem onRemove={() => onRemove()} state={state} />
          </div>
        )}
      </Flex>
      <Snackbar
        variant={state.variant}
        open={state.openSnackBar}
        message={state.message}
        autoHideDuration={4000}
        onClose={() => handleRequestClose()}
      />
    </div>
  );
};

ImageField.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  disableClick: PropTypes.bool,
  elStyle: PropTypes.object,
  input: PropTypes.object,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  style: PropTypes.object,
  placeholder: PropTypes.node,
  toValue: PropTypes.string,
  updateField: PropTypes.func,
};

ImageField.defaultProps = {
  addLabel: true,
  addField: true,
  multiple: false,
  maxSize: 1000000,
  onUpload: () => {},
  toValue: "",
};

export default ImageField;
