export const dataTypeBorder = [
  { value: "dotted", text: "Dotted" },
  { value: "dashed", text: "Dashed" },
  { value: "solid", text: "Solid" },
  { value: "double", text: "Double" },
  { value: "groove", text: "Groove" },
  { value: "ridge", text: "Ridge" },
  { value: "inset", text: "Inset" },
  { value: "outset", text: "Outset" },
  { value: "none", text: "None" },
  { value: "hidden", text: "Hidden" },
]

export const typeButton = [
  { value: "flat", text: "Flat" },
  { value: "raised", text: "Raised" },
]

export const datalabelPosition = [
  { value: "before", text: "Before" },
  { value: "after", text: "After" },
]

export const dataHref = [
  { text: "Same Page", value: "same" },
  { text: "New Page", value: "_blank" },
]
export const dataTooltipList = [
  { text: "Top start", value: "top-start" },
  { text: "Top End", value: "top-end" },
  { text: "Top", value: "top" },
  { text: "Bottom Start", value: "bottom-start" },
  { text: "Bottom End", value: "bottom-end" },
  { text: "Bottom", value: "bottom" },
  { text: "Left start", value: "left-start" },
  { text: "Left End", value: "left-end" },
  { text: "Left", value: "left" },
  { text: "Right Start", value: "right-start" },
  { text: "Right End", value: "right-end" },
  { text: "Right", value: "right" },
]

export const dataSizeIconButton = [
  { text: "X-Small", value: "xsmall" },
  { text: "Small", value: "small" },
  { text: "Medium", value: "medium" },
  { text: "Large", value: "large" },
]
