import React from "react"
import { Flex } from "rebass"
import { SwitchInput, BlockCounter } from "../components"
import { PanelCard, ContentDetail } from "../actions"

const PanelGeneral = (props) => {
  const { state, onSetValue } = props
  return (
    <PanelCard title={"General"} id={"general"}>
      <Flex width={[1]} flexDirection={"column"}>
        <BlockCounter
          title="Container Padding"
          containerPadding={state.containerPadding}
          onUpdatePadding={(containerPadding) =>
            onSetValue("containerPadding", containerPadding)
          }
        />
        <ContentDetail>
          <SwitchInput
            id={`showPrice`}
            title="Show Price Product"
            checked={state.showPrice}
            onChange={(e) => onSetValue("showPrice", e.target.checked)}
          />

          <Flex
            flexDirection={"row"}
            width={[1]}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
          ></Flex>
        </ContentDetail>
      </Flex>
    </PanelCard>
  )
}

export default PanelGeneral
