import React from "react"
import PropTypes from "prop-types"
import Layout from "../Layout"

const ImageView = (props) => {
  return <Layout {...props} type="ImageView" />
}

ImageView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
}

export default ImageView
