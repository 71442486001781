import React, { Component } from "react";
import PropTypes from "prop-types";
import Layout from "./Layout";

class Body extends Component {
  render() {
    return <Layout {...this.props} type="Body" />;
  }
}

Body.propTypes = {
  childOf: PropTypes.string
};

Body.defaultProps = {
  childOf: "root",
  values: {
    backgroundColor: "#e7e7e7",
    backgroundImage: {
      url: "",
      fullWidth: true,
      repeat: false,
      center: true,
      cover: false
    },
    fullheight: true,
    contentWidth: "500px",
    fontFamily: {
      label: "Arial",
      value: "arial,helvetica,sans-serif"
    }
  }
};

export default Body;
