import React from "react";
import PropTypes from "prop-types";
import Layout from "../Layout";

const SubcribeView = (props) => {
  return <Layout {...props} type='SubcribeView' />;
};

SubcribeView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};

export default SubcribeView;
