export default {
  channels: {
    name: "Channel",
    title_create: "Create Channel",
    title_edit: "Edit Channel",
    fields: {
      active: "Active",
      name: "Name",
      type: "Type",
      merchant: "Merchant",
      created_at: "Create On",
    },
  },
}
