import React, { Children, useCallback, isValidElement, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Form, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Route } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import { includes } from "lodash";
import {
  useTranslate,
  useInitializeFormWithRecord,
  sanitizeEmptyValues,
  getFormInitialValues,
  Toolbar,
  useDataProvider,
  useNotify,
} from "react-admin";
import get from "lodash/get";
import { IsDebugForm } from "./ValuesForm";
import { API_SERVER, API_DEBUG } from "../../restClient";
import { sanitizeRestProps } from "./utils";
import TabbedFormTabs, { getTabFullPath } from "./TabbedFormTabs";
import { useRouteMatch, useLocation } from "react-router-dom";
import { Box } from "rebass";

import { HeaderCustomForm } from "./utils";

const TabbedCustomForm = ({
  initialValues,
  defaultValue,
  saving,
  typePostUrl,
  pathUrl,
  title,
  isShowBack,
  labelBack,
  customBackUrl,
  location,
  sourceApi,
  history,
  staticContext,
  showNotification,
  ...props
}) => {
  const notify = useNotify();
  let redirect = useRef(props.redirect);
  // We don't use state here for two reasons:
  // 1. There no way to execute code only after the state has been updated
  // 2. We don't want the form to rerender when redirect is changed
  const setRedirect = (newRedirect) => {
    redirect.current = newRedirect;
  };

  const translate = useTranslate();
  const dataProvider = useDataProvider();
  // const classes = useStyles()

  const finalInitialValues = getFormInitialValues(
    initialValues,
    defaultValue,
    props.record
  );

  const { pathname } = location;
  let paramsUrl = pathname;

  if (typePostUrl === "CREATE") {
    const check = `/create`;
    paramsUrl = pathname.substring(0, pathname.length - check.length);
    if (includes(paramsUrl, "attributeSets")) {
      paramsUrl = "/attributes";
    }
  } else if (typePostUrl === "UPDATE") {
    const check = `/${pathUrl}/`;
    const n = pathname.indexOf(check);
    const record_id = pathname.substring(n + check.length, pathname.length);
    paramsUrl = pathname.replace(`/${pathUrl}/${record_id}`, `/${pathUrl}`);
  }
  if (customBackUrl && customBackUrl !== "") {
    paramsUrl = customBackUrl;
  }

  const submit = (values) => {
    // const finalRedirect =
    //   typeof redirect.current === undefined ? props.redirect : redirect.current
    const finalValues = sanitizeEmptyValues(finalInitialValues, values);

    // props.save(finalValues, finalRedirect)
    // const finalValues = getFormInitialValues(finalInitialValues, values)
    //
    if (typePostUrl === "CREATE") {
      const params = {
        data: finalValues,
      };

      dataProvider
        .create(sourceApi, params)
        .then(({ data }) => {
          // console.log(data)
          notify("ra.notification.created", "success", {
            smart_count: 1,
          });
          history.push(paramsUrl);
        })
        .catch((error) => {
          notify("resources.notification.please_try_again", "warning", {
            smart_count: 1,
          });
        });
    } else if (typePostUrl === "UPDATE") {
      // console.log(finalValues)
      const params = {
        id: finalValues.id,
        data: values,
        previousData: finalValues,
      };
      dataProvider
        .update(sourceApi, params)
        .then(({ data }) => {
          // console.log(data)
          notify("resources.notification.updated", "success", {
            smart_count: 1,
          });
          history.push(paramsUrl);
        })
        .catch((error) => {
          notify("resources.notification.please_try_again", "warning", {
            smart_count: 1,
          });
        });
    }
  };

  return (
    <Box width={[1]}>
      <HeaderCustomForm
        translate={translate}
        title={title}
        finalInitialValues={finalInitialValues}
        paramsUrl={paramsUrl}
        isShowBack={isShowBack}
        labelBack={labelBack}
      />
      <Form
        key={props.version}
        initialValues={finalInitialValues}
        onSubmit={submit}
        mutators={{
          ...arrayMutators,
          updateField: ([field, value], state, utils) => {
            utils.changeValue(state, field, () => value);
          },
        }}
        setRedirect={setRedirect}
        keepDirtyOnReinitialize
        subscription={defaultSubscription}
        {...props}
        render={(formProps) => {
          // console.log(formProps)
          // console.log(formProps)
          return (
            <TabbedFormView
              saving={formProps.submitting || saving}
              translate={translate}
              {...props}
              {...formProps}
            />
          );
        }}
      />
    </Box>
  );
};

const defaultSubscription = {
  submitting: true,
  pristine: false,
  valid: true,
  invalid: true,
};

export default TabbedCustomForm;

export const TabbedFormView = ({
  basePath,
  children,
  className,
  classes = {},
  form,
  handleSubmit,
  invalid,
  pristine,
  record,
  redirect: defaultRedirect,
  resource,
  saving,
  setRedirect,
  submitOnEnter,
  tabs,
  toolbar,
  translate,
  undoable,
  value,
  version,
  variant,
  margin,
  dirtyFieldsSinceLastSubmit,
  ...rest
}) => {
  useInitializeFormWithRecord(record);

  const handleSubmitWithRedirect = useCallback(
    (redirect = defaultRedirect) => {
      setRedirect(redirect);
      handleSubmit();
    },
    [setRedirect, defaultRedirect, handleSubmit]
  );

  const tabsWithErrors = findTabsWithErrors(children, form.getState().errors);

  const match = useRouteMatch();
  const location = useLocation();

  const url = match ? match.url : location.pathname;

  const { updateField } = form.mutators;

  return (
    <form
      className={classnames("tabbed-form", className)}
      key={version}
      {...sanitizeRestProps(rest)}
    >
      {React.cloneElement(
        tabs,
        {
          classes,
          url,
          tabsWithErrors,
          updateField,
        },
        children
      )}
      <Divider />
      <div className={classes.content}>
        {/* All tabs are rendered (not only the one in focus), to allow validation
                on tabs not in focus. The tabs receive a `hidden` property, which they'll
                use to hide the tab using CSS if it's not the one in focus.
                See https://github.com/marmelab/react-admin/issues/1866 */}
        {Children.map(children, (tab, index) => {
          const newChild = tab.props.children;
          const newTab = React.cloneElement(tab, {
            children: React.cloneElement(newChild, {
              updateField: updateField,
            }),
          });

          return (
            newTab && (
              <Route exact path={getTabFullPath(newTab, index, url)}>
                {(routeProps) =>
                  isValidElement(newTab)
                    ? React.cloneElement(newTab, {
                        intent: "content",
                        resource,
                        record,
                        basePath,
                        hidden: !routeProps.match,
                        variant,
                        margin,
                        classes,
                      })
                    : null
                }
              </Route>
            )
          );
        })}
      </div>
      {toolbar &&
        React.cloneElement(toolbar, {
          basePath,
          className: "toolbar",
          handleSubmitWithRedirect,
          handleSubmit,
          invalid,
          pristine,
          record,
          redirect: defaultRedirect,
          resource,
          saving,
          submitOnEnter,
          undoable,
        })}
      {API_SERVER === API_DEBUG && IsDebugForm && (
        <FormSpy subscription={{ values: true }}>
          {(props) => {
            return (
              <code style={{ fontSize: 10, padding: 10, margin: 16 }}>
                {JSON.stringify(props.values, undefined, 2)}
              </code>
            );
          }}
        </FormSpy>
      )}
    </form>
  );
};

TabbedFormView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // @deprecated
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  handleSubmit: PropTypes.func, // passed by react-final-form
  invalid: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  pristine: PropTypes.bool,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  tabs: PropTypes.element.isRequired,
  tabsWithErrors: PropTypes.arrayOf(PropTypes.string),
  toolbar: PropTypes.element,
  translate: PropTypes.func,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  value: PropTypes.number,
  version: PropTypes.number,
};

TabbedFormView.defaultProps = {
  submitOnEnter: true,
  tabs: <TabbedFormTabs />,
  toolbar: <Toolbar />,
};

export const findTabsWithErrors = (children, errors) => {
  return Children.toArray(children).reduce((acc, child) => {
    if (!isValidElement(child)) {
      return acc;
    }

    const inputs = Children.toArray(child.props.children);

    if (
      inputs.some(
        (input) => isValidElement(input) && get(errors, input.props.source)
      )
    ) {
      return [...acc, child.props.label];
    }

    return acc;
  }, []);
};
