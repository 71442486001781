import React, { useState } from "react";
import { get } from "lodash";
import { Flex, Box, Text } from "rebass";
import { SelectInput } from "../select";
import { BlockCounterWithType } from "../counter";

const listDirection = [
  { id: "column", name: "Column" },
  { id: "column-reverse", name: "Column-Reverse" },
  { id: "row", name: "Row" },
  { id: "row-reverse", name: "Row Reverse" },
];
const listAlignment = [
  { id: "baseline", name: "Baseline" },
  { id: "flex-start", name: "Flex Start" },
  { id: "flex-end", name: "Flex End" },
  { id: "center", name: "Center" },
  { id: "stretch", name: "Stretch" },
  { id: "space-between", name: "Space Between" },
  { id: "space-around", name: "Space Around" },
  { id: "space-evenly", name: "Space Evenly" },
];
const defaultContainer = {
  parent: {
    alignItems: ["center", "center"],
    direction: ["column", "row"],
    justifyContent: ["center", "flex-end"],
  },
  title: {
    width: ["100%", "100%"],
    justifyContent: ["center", "center"],
    direction: ["column", "column"],
    alignItems: ["center", "center"],
  },
};

const LayoutContent = (props) => {
  const { layout, onUpdateItemValue, keyContent } = props;
  const [state, setState] = useState(layout);
  const onChangeValue = (k, v, index) => {
    let myValue = state[k];

    if (state[k]) {
      if (myValue[index]) {
        myValue[index] = v;
      } else {
        if (myValue[index] === "") {
          myValue[index] = v;
        } else {
          if (typeof v === "number") {
            myValue = [1, 1];
            myValue[index] = v;
          } else {
            myValue = ["", ""];
            myValue[index] = v;
          }
        }
      }
    }

    const params = { ...state, [k]: myValue };
    setState(params);
    onUpdateItemValue(keyContent, params);
  };

  return (
    <Flex width={[1]} flexDirection={"row"}>
      <Flex width={[1 / 2]} flexDirection={"column"}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontWeight={"bold"} pb={[2]}>
            Desktop View
          </Text>
        </Flex>
        {state.width && (
          <Box width={["200px"]}>
            <BlockCounterWithType
              name={`width-image-desktop-content-${keyContent}`}
              value={state.width[1]}
              onSetValue={(v) => onChangeValue("width", v, 1)}
              title={"Width"}
            />
          </Box>
        )}
        <SelectInput
          id={`direction`}
          title={"Direction"}
          value={state.direction[1]}
          list={listDirection}
          onChange={(e) => onChangeValue("direction", e.target.value, 1)}
        />
        <SelectInput
          id={`justifyContent`}
          title={"Jusity Content"}
          value={state.justifyContent[1]}
          list={listAlignment}
          onChange={(e) => onChangeValue("justifyContent", e.target.value, 1)}
        />
        <SelectInput
          id={`alignItems`}
          title={"Align Items"}
          value={state.alignItems[1]}
          list={listAlignment}
          onChange={(e) => onChangeValue("alignItems", e.target.value, 1)}
        />
      </Flex>
      <Flex width={[1 / 2]} flexDirection={"column"} py={[2]}>
        <Text fontWeight={"bold"} pb={[2]}>
          Mobile View
        </Text>
        {state.width && (
          <Box width={["200px"]}>
            <BlockCounterWithType
              name={`width-image-mobile-content-${keyContent}`}
              value={state.width[0]}
              onSetValue={(v) => onChangeValue("width", v, 0)}
              title={"Width"}
            />
          </Box>
        )}
        <SelectInput
          id={`direction-mobile`}
          title={"Direction"}
          value={state.direction[0]}
          list={listDirection}
          onChange={(e) => onChangeValue("direction", e.target.value, 0)}
        />
        <SelectInput
          id={`justifyContent-mobile`}
          title={"Jusity Content"}
          value={state.justifyContent[0]}
          list={listAlignment}
          onChange={(e) => onChangeValue("justifyContent", e.target.value, 0)}
        />
        <SelectInput
          id={`alignItems-mobile`}
          title={"Align Items"}
          value={state.alignItems[0]}
          list={listAlignment}
          onChange={(e) => onChangeValue("alignItems", e.target.value, 0)}
        />
      </Flex>
    </Flex>
  );
};

export default (props) => {
  const { data, onSetItemValue, indexParent } = props;
  const containerTitle = get(data, "containerTitle", defaultContainer);
  const containerParent = get(
    containerTitle,
    "parent",
    defaultContainer.parent
  );
  const boxTitle = get(containerTitle, "title", defaultContainer.title);

  const onSetValue = (k, v) => {
    const params = {
      ...containerTitle,
      [k]: v,
    };
    onSetItemValue("containerTitle", params, indexParent);
  };

  return (
    <Box width={[1]}>
      <Box width={[1]} style={{ border: "1px solid #d8d8d8" }} p={[2]}>
        <Text fontWeight={"bold"} pb={[2]}>
          Container Parent
        </Text>
        <LayoutContent
          onUpdateItemValue={(k, v) => onSetValue(k, v)}
          keyContent={"parent"}
          layout={containerParent}
        />
      </Box>
      <Box width={[1]} style={{ border: "1px solid #d8d8d8" }} p={[2]}>
        <Text fontWeight={"bold"} pb={[2]}>
          Box Title
        </Text>
        <LayoutContent
          onUpdateItemValue={(k, v) => onSetValue(k, v)}
          keyContent={"title"}
          layout={boxTitle}
        />
      </Box>
    </Box>
  );
};
