import React from "react";
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd";
import { target } from "./utils";
// import { get } from "lodash";
//import Separator from './Separator';
import { connect } from "../../core";
import { SubcribeFields } from "./components/subcribe";

// const target = {
//   drop(props, monitor, component) {
//     if (!monitor.didDrop()) {
//       props.layoutState.insertOrMoveItem(props.id, props.children.length, monitor.getItem());
//     }
//   }
// };

const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
}) => {
  const {
    desktop,
    placeholder,
    labelButton,
    urlSubcribe,
  } = layoutState.getItemJS(id).props.values;

  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        display: "flex",
        flex: 1,
      }}
    >
      <SubcribeFields
        state={desktop}
        placeholder={placeholder}
        labelButton={labelButton}
        urlSubcribe={urlSubcribe}
      />
    </div>
  );
};

const LayoutContainer = DropTarget("SubcribeView", target, (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(EditLayout);

export default connect("layoutState", "readOnly")(LayoutContainer);

// export default LayoutContainer;s
