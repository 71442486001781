import React, { Fragment } from "react";
import { required, useTranslate, FormDataConsumer } from "react-admin";
import { get } from "lodash";
import { Box, Text, Flex } from "rebass";
import { DatePicker } from "antd";
import moment from "moment";
import {
  RaBooleanInput,
  RaTextInput,
  useWindowSize,
} from "../../../components";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HeaderContent from "./HeaderContent";
import HomepageContent from "./HomepageContent";
import FooterContent from "./FooterContent";
import ThemesContent from "./ThemesContent";
import MenuContent from "./MenuContent";
import ShowContentDialog from "./ShowContentDialog";
// const localesList = [
//   { id: "header", name: "resources.layouts.tabs.header" },
//   { id: "homepage", name: "resources.layouts.tabs.homepage_content" },
//   { id: "footer", name: "resources.layouts.tabs.footer" },
//   { id: "theme", name: "resources.layouts.tabs.theme" },
// ];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
const { RangePicker } = DatePicker;

const dateFormat = "DD-MM-YYYY HH:mm";

export const EditFormFields = (props) => {
  const styleWindow = useWindowSize();
  const translate = useTranslate();
  const classes = useStyles();
  const { isCreate, showcontent } = props;

  const onChange = (value) => {
    if (value[0]) {
      props.updateField("start_date", value[0].toISOString());
    }
    if (value[1]) {
      props.updateField("end_date", value[1].toISOString());
    }
  };

  const { width, height } = styleWindow;
  return (
    <div className={classes.root}>
      <FormDataConsumer>
        {({ formData }) => {
          const websiteId = get(formData, "website_id", 0);
          const startDate = get(formData, "start_date", moment());
          const endDate = get(formData, "end_date", moment());
          return (
            <Fragment>
              {!showcontent && (
                <Box width={[1]} px={[0]}>
                  <Typography className={classes.heading}>
                    {translate("resources.layouts.title")}
                  </Typography>
                  <Box width={[1]} px={[isCreate ? 2 : 3]}>
                    <RaTextInput
                      source='name'
                      validate={required()}
                      style={{ width: 256 }}
                    />
                    <RaBooleanInput
                      source='default'
                      label={"resources.layouts.fields.default_layout"}
                    />
                    {/* <RaTextInput source="domain" style={{ width: 256 }} /> */}
                    <Box width={[1]} py={[2]}>
                      <Text py={[1]} fontSize={[0]} fontWeight={"bold"}>
                        {translate("resources.layouts.fields.range_date")}
                      </Text>
                      <RangePicker
                        showTime={{ format: "HH:mm" }}
                        format='YYYY-MM-DD HH:mm'
                        onChange={onChange}
                        defaultValue={[
                          moment(startDate, dateFormat),
                          moment(endDate, dateFormat),
                        ]}
                        // onOk={onOk}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
              {showcontent && showcontent === "header" && (
                <HeaderContent
                  {...props}
                  formData={formData}
                  widthScreen={width}
                  heightScreen={height}
                />
              )}
              {showcontent && showcontent === "home" && (
                <HomepageContent
                  {...props}
                  formData={formData}
                  widthScreen={width}
                  heightScreen={height}
                />
              )}
              {showcontent && showcontent === "footer" && (
                <FooterContent
                  {...props}
                  formData={formData}
                  widthScreen={width}
                  heightScreen={height}
                />
              )}
              {showcontent && showcontent === "theme" && (
                <ThemesContent {...props} formData={formData} />
              )}
              {showcontent && showcontent === "menu" && (
                <MenuContent {...props} formData={formData} />
              )}
              <Flex flexDirection={"row"} p={[2]} alignItems='center'>
                {showcontent && showcontent === "home" && (
                  <ShowContentDialog
                    localeId={"en"}
                    content={[
                      {
                        id: "en",
                        data: formData.homepage_content,
                        layoutSelected: formData.homepage_content,
                      },
                    ]}
                    translate={translate}
                    widthScreen={width}
                    heightScreen={height}
                    websiteId={websiteId}
                  />
                )}
                {showcontent && showcontent === "footer" && (
                  <ShowContentDialog
                    localeId={"en"}
                    content={[
                      {
                        id: "en",
                        data: formData.footer,
                        layoutSelected: formData.footer,
                      },
                    ]}
                    translate={translate}
                    widthScreen={width}
                    heightScreen={height}
                    websiteId={websiteId}
                  />
                )}
              </Flex>
            </Fragment>
          );
        }}
      </FormDataConsumer>
    </div>
  );
};
