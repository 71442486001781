export default {
  websites: {
    name: "Website",
    back: "Back to Websites",
    new_title: "New Website",
    edit_title: "Edit Website",
    title: "Home Page Design Schedule",
    subtitle: "Configure which Webpage to use as home page in different dates",
    tabs: {
      info: "Info",
      locale: "Locale",
      product: "Product",
      service: "Service",
      layout: "Layout",
      header: "Header",
      homepage_content: "Homepage Content",
      footer: "Footer",
      shipping: "Shipping",
      payment: "Payment",
      webhooks: "Webhooks",
      faq: "FAQ",
      checkout: "Checkout",
      policies: "Policies",
    },
    fields: {
      name: "Name",
      domain: "Domain",
      default_homepage: "Default Homepage",
      layout: "Layout",
      active: "Active",
      webpage: "Webpage",
      from: "From",
      to: "To",
      country: "Country",
      default_language: "Default Language",
      supported_language: "Supported Language",
      currency: "Currency",
      currency_symbol: "Currency Symbol",
      thousand_separator: "Thousand Separator",
      decimal_scale: "Decimal Scale",
      decimal_separator: "Decimal Separator",
      date_format: "Date Format",
      time_zone: "Timezone",
      default_layout: "Default Layout",
      start_date: "Start Date",
      end_date: "End Date",
      auto_detect_country: "Auto detect country",
      inventory_source_group: "Inventory Source Group",
      review: "Product Review",
      comparison: "Product Comparison",
      login_google: "Google",
      login_guest: "Guest",
      login_instagram: "Instagram",
      login_facebook: "Facebook",
      billing_information: "Billing information",
      delivery_date_time: "Delivery Datetime",
      order_status: "Order Status",
      page_id: "Cancellation Policy Page",
      message: "Cancellation Message",
      allow_cancel: "Allow Cancel Order",
      allow_return: "Allow Return Order",
      return_page_id: "Return & Refund Policy Page",
      return_message: "Return Message",
      number_of_day_after_delivered: "Number of Days After Delivered ",
      order: "Order",
      return_refund: "Return & Refund",
    },
    labels: {
      login_options: "Login Options",
      checkout_information: "Checkout Information",
      cancelation: "Cancellation",
      return: "Return",
      notification: "Notification",
    },
    note: {
      layout: "",
      supported_language: "Supported languages for the site",
      order_status: "Allow cancellation when order in these status",
    },
    messages: {
      toDate: "Must be after from ",
      checkYourDate: "Please check yout 'TO' Date",
    },
  },
};
