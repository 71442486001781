import React from "react"
// import { Field } from "react-final-form"
import { Box, Text } from "rebass"
import { get } from "lodash"
import InputLabel from "@material-ui/core/InputLabel"
import { addField, useTranslate, useGetOne } from "react-admin"
import TextMessage from "./utils"
import { Loading } from "../../components"
const ReferanceTextForm = props => {
  const translate = useTranslate()
  const { input, label, notetext, source, optionText, reference } = props
  const { data, loading, error } = useGetOne(reference, input.value)

  return (
    <Box width={180} pb={[2]}>
      <InputLabel>{label ? translate(label) : source}</InputLabel>
      {loading ? (
        <Loading />
      ) : error ? (
        <div />
      ) : (
        <Text fontSize={12} pt={[1]}>
          {get(data, optionText, "")}
        </Text>
      )}
      {notetext && <TextMessage notetext={notetext} />}
    </Box>
  )
}
export default addField(ReferanceTextForm)
