import moment from "moment";
export const dataLayout = [
  { id: "Eraspace", name: "Eraspace" },
  { id: "Erafone", name: "Erafone" },
  { id: "Urban Republic", name: "Urban Republic" },
];

export const dataHoliday = [
  { id: "Erapsace - Valentine Day", name: "Erapsace - Valentine Day" },
  { id: "Erafone - Valentine Day", name: "Erafone - Valentine Day" },
  {
    id: "Urban Republic - Valentine Day",
    name: "Urban Republic - Valentine Day",
  },
];

export const dataCountry = [
  { id: "ID", name: "Indonesia" },
  { id: "MY", name: "Malaysia" },
  { id: "PH", name: "Philippines" },
  { id: "SG", name: "Singapore" },
  { id: "TH", name: "Thailand" },
  { id: "VN", name: "Vietnam" },
];
export const dataTypeStore = [
  { id: "DC", name: "DC" },
  { id: "Store", name: "Store" },
  { id: "Warehouse", name: "Warehouse" },
];
export const dataCurrency = [
  { id: "IDR", name: "IDR - Indonesian rupiah" },
  { id: "MYR", name: "MYR - Malaysian ringgit" },
  { id: "PHP", name: "PHP - Philippine peso" },
  { id: "SGD", name: "SGD - Singapore dollar" },
  { id: "THB", name: "THB - Thai baht" },
  { id: "VND", name: "VND - Vietnamese đồng" },
];
export const dataCurrencySymbol = [
  { id: "IDR", name: "Rp - Indonesia" },
  { id: "MYR", name: "RM - Malaysia" },
  { id: "PHP", name: "₱  - Philippines" },
  { id: "SGD", name: "$  - Singapore" },
  { id: "THB", name: "฿  - Thailand" },
  { id: "VND", name: "₫  - Vietnam" },
];

export const dataLocale = [
  { id: "ID", name: "Indonesia" },
  { id: "MY", name: "Malaysia" },
  { id: "PH", name: "Philipine" },
];

export const dataLanguage = [
  { id: "id", name: "Bahasa" },
  { id: "en", name: "English" },
];

export const dataOrder = [
  { id: "Pending Payment", name: "Pending Payment" },
  { id: "Processing", name: "Processing" },
  { id: "Ready To Ship", name: "Ready To Ship" },
  { id: "Cancelled", name: "Cancelled" },
  { id: "Shipped", name: "Shipped" },
  { id: "Out For Delivery", name: "Out For Delivery" },
  { id: "Delivered", name: "Delivered" },
  // { id: "partial shipped", name: "partial shipped" },
  // { id: "partial shipped", name: "partial shipped" },
];
export const dataOrderStatus = [
  { id: "Pending Payment", name: "Pending Payment" },
  { id: "Processing", name: "Processing" },
  // { id: "Ready To Ship", name: "Ready To Ship" },
  // { id: "Cancelled", name: "Cancelled" },
  // { id: "Shipped", name: "Shipped" },
  // { id: "Out For Delivery", name: "Out For Delivery" },
  // { id: "Delivered", name: "Delivered" },
  // { id: "partial shipped", name: "partial shipped" },
  // { id: "partial shipped", name: "partial shipped" },
];
export const dataSeparator = [
  { id: "comma", name: "Comma" },
  { id: "dot", name: "Dot" },
];
export const dataDecimal = [
  { id: 0, name: "0" },
  { id: 1, name: "1" },
  { id: 2, name: "2" },
];

export const dataDateFormat = [
  { id: "DD/MM/YYYY", name: moment().format("DD/MM/YYYY") },
  { id: "DD-MM-YYYY", name: moment().format("DD-MM-YYYY") },
  { id: "MM/DD/YYYY", name: moment().format("MM/DD/YYYY") },
  { id: "MM-DD-YYYY", name: moment().format("MM-DD-YYYY") },
  { id: "YYYY/MM/DD", name: moment().format("YYYY/MM/DD") },
  { id: "YYYY-MM-DD", name: moment().format("YYYY-MM-DD") },
  { id: "MMMM DD,YYYY", name: moment().format("MMMM DD,YYYY") },
];
