import { Layout22 } from "../../TemplateImageSonak";
export const template22A = {
  YBnXQSXd: {
    id: "YBnXQSXd",
    type: "Row3",
    props: {
      values: {
        name: "Row3",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: true,
            align: "middle",
            gutter: 10,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "8px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: true,
            align: "top",
            gutter: 24,
            justify: "center",
            contentAlign: "center",
            contentJustify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "16px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              parent: "Row",
              desktop: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 8,
                  order: 1,
                },
                padding: "0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              parent: "Row",
              desktop: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 8,
                  order: 2,
                },
                padding: "0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              parent: "Row",
              desktop: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 8,
                  order: 3,
                },
                padding: "0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    layout: Layout22,
    childOf: "Row",
    children: ["75oGWvfI", "Rfy48ylv", "lSMtx06z"],
    myContent: [
      {
        id: "AwB6/cpT",
        type: "ImageView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            type: "ImageView",
            align: "center",
            mobile: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            parent: "Content",
            desktop: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            imageUrl: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-7D3.jpeg",
              mobile: {
                width: 0,
                height: 0,
              },
              desktop: {
                width: 0,
                height: 0,
              },
              urlMobile:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-7D3.jpeg",
            },
          },
        },

        parent: {
          id: "Rfy48ylv",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "Rfy48ylv",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "FrLLal2w",
        type: "ImageView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            type: "ImageView",
            align: "center",
            mobile: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            parent: "Content",
            desktop: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            imageUrl: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-8RY.jpeg",
              mobile: {
                width: 0,
                height: 0,
              },
              desktop: {
                width: 0,
                height: 0,
              },
              urlMobile:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-8RY.jpeg",
            },
          },
        },
        parent: {
          id: "lSMtx06z",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "lSMtx06z",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "TcYkF63K",
        type: "ImageView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            type: "ImageView",
            align: "center",
            mobile: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            parent: "Content",
            desktop: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            imageUrl: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-0KW.jpeg",
              mobile: {
                width: 0,
                height: 0,
              },
              desktop: {
                width: 0,
                height: 0,
              },
              urlMobile:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-0KW.jpeg",
            },
          },
        },
        parent: {
          id: "75oGWvfI",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "75oGWvfI",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "Ngfc4ZjQ",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>ORDER PROCESSING</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 12,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px 0px 0px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 14,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "16px 0px 0px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "Rfy48ylv",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "Rfy48ylv",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "mHrOJBzO",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text:
              '<p><span style="color: rgb(0, 0, 0);">All products available on PlayitRight go through stringent quality checks before being shipped</span>.</p>',
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px 0px 8px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "16px 0px 16px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        style: {},
        parent: {
          id: "lSMtx06z",
          idx: 2,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "lSMtx06z",
          idx: 2,
          index: 1,
        },
      },
      {
        id: "v2oBlDLH",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>ORDER PROCESSING</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 12,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px 0px 0px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 14,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "16px 0px 0px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "75oGWvfI",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "75oGWvfI",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "xDXhJW8L",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text:
              '<p><span style="color: rgb(0, 0, 0);">All products available on PlayitRight go through stringent quality checks before being shipped</span>.</p>',
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px 0px 8px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "16px 0px 16px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        style: {},
        parent: {
          id: "75oGWvfI",
          idx: 2,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "75oGWvfI",
          idx: 2,
          index: 1,
        },
      },
      {
        id: "xUSmUlcs",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>ORDER PROCESSING</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 12,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px 0px 0px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 14,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "16px 0px 0px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "lSMtx06z",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "lSMtx06z",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "xkRAjX4c",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text:
              '<p><span style="color: rgb(0, 0, 0);">All products available on PlayitRight go through stringent quality checks before being shipped</span>.</p>',
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px 0px 8px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "16px 0px 16px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        style: {},
        parent: {
          id: "Rfy48ylv",
          idx: 2,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "Rfy48ylv",
          idx: 2,
          index: 1,
        },
      },
    ],
  },
};
