import React from "react";
import { Create, useTranslate } from "react-admin";
import moment from "moment";
import {
  BetterCustomForm,
  TopBarTitleAction,
  FooterToolbar,
  Loading,
} from "../../components";
import { FormFields } from "./components";
import { getSiteID, getWebsites } from "../../restClient";
import { useQuery } from "react-query";
const cacheTimeMinute = 1000 * 60 * 30;
const initValues = {
  site_id: getSiteID(),
  start_date: moment(),
  end_date: moment().add(7, "days"),
  type: "Percentage",
  promotion_rule: "Product-based",
};
const CreatePromotion = (props) => {
  const translate = useTranslate();
  const { data, isLoading } = useQuery("websites", () => getWebsites(), {
    cacheTime: cacheTimeMinute,
  });
  if (isLoading) {
    return <Loading />;
  }

  return (
    <Create
      {...props}
      actions={
        <TopBarTitleAction
          title={translate("resources.promotions.title.new_promotion")}
        />
      }
    >
      <BetterCustomForm
        {...props}
        toolbar={<FooterToolbar />}
        initialValues={initValues}
      >
        <FormFields {...props} isCreate={true} websites={data} />
      </BetterCustomForm>
    </Create>
  );
};

export default CreatePromotion;
