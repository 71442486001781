import React, { useState } from "react";
import { get } from "lodash";
import { Flex, Box, Text } from "rebass";
import { useTheme } from "@material-ui/core/styles";
import { Currency } from "../../../../components";
import { H3, Small } from "../../../../components/Text";
import { Quantity } from "../Quantity";
import { useStyles, Price, Image, Container } from "./utils";

const ReceivedQty = (props) => {
  const { item, listQty, setListQty, aqty, setQty } = props;
  // console.log(listQty, item);

  const onQtyChange = (qty) => {
    setQty(qty);
    const newList = listQty.map((i) => {
      let newI = i;
      if (i.id === item.id) {
        newI.qtyTemp = qty;
      }
      return newI;
    });
    setListQty(newList);
  };
  // const myShippedQty = get(item, "shipped_qty", 0) * 1;
  const quantity = get(item, "qty", 0) * 1;
  // const myTotalMax = myShippedQty <= 0 ? quantity : quantity - myShippedQty;
  return (
    <Quantity
      size='small'
      qty={aqty}
      min={0}
      max={quantity}
      onQtyChange={(qty) => onQtyChange(qty)}
    />
  );
};

const CartItem = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const primaryColor = theme.palette.primary[500];
  const {
    item,
    translate,
    currency_symbol,
    setListQty,
    listQty,
    typeAmendment,
  } = props;
  const { qty, price, discount_price, tier_price, name, variant } = item;

  const [aqty, setQty] = useState(
    typeAmendment === "Refund"
      ? get(item, "refunded_qty", 0)
      : get(item, "returned_qty", 0)
  );
  const tierPrice = tier_price * 1;
  const discountPrice = discount_price * 1;
  const myPrice = price * 1;
  // const mySubtotal = Number(subtotal * 1).toFixed(2);
  // const onQtyChange = (qty) => {
  //   setQty(qty);
  //   const newList = listQty.map((i) => {
  //     let newI = i;
  //     if (i.id === item.id) {
  //       newI.qty = qty;
  //     }
  //     return newI;
  //   });
  //   setListQty(newList);
  // };

  const myVariant = get(variant, "variant", {});
  var variants = [];
  for (var key in myVariant) {
    // console.log(key);
    variants.push(
      <Small fontSize='0.9em' key={key} pr={1} pt={1}>
        <span className={classes.text}>{key}</span>
        <span>{myVariant[key]}</span>
      </Small>
    );
  }
  let titleList = "resources.orders.labels.return";
  if (typeAmendment === "Refund") {
    titleList = "resources.orders.labels.refund";
  }
  // console.log(typeAmendment, titleList);
  return (
    <Container width={[1, 1, 1]} py={2}>
      <Box width={["160px", "200px", "256px"]} p={[1]}>
        <Image src={item.photo} />
      </Box>
      <Flex width={[1]} p={[1]} pr={[2]}>
        <Box width={1 / 2}>
          <Box>
            <H3>{name}</H3>
            <Flex
              flexDirection={"row"}
              justifyContent={"space-between"}
              pt={[2]}
            >
              <Box>{variants}</Box>
            </Flex>
          </Box>
        </Box>
        <Box width={1 / 2}>
          <Flex
            width={[1]}
            flexDirection='row'
            justifyContent='flex-end'
            alignItems={"flex-start"}
          >
            <Flex
              flexDirection={"row"}
              alignItems={"center"}
              sx={{
                "&>div": {
                  pr: [2],
                },
              }}
            >
              <Price striked={myPrice > discountPrice} pr='0.8em'>
                <Currency
                  value={myPrice}
                  unit={currency_symbol}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Price>
              <Price
                hide={myPrice <= discountPrice}
                striked={discountPrice > tierPrice}
                pr='0.8em'
              >
                <Currency
                  value={discountPrice}
                  unit={currency_symbol}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Price>
              <Price hide={tierPrice >= discountPrice}>
                <Currency
                  value={tierPrice}
                  unit={currency_symbol}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Price>
              <Text py={1}>
                x <span>{qty}</span> =
              </Text>
              <Price striked={myPrice > discountPrice} pr='0.8em'>
                <Currency
                  value={myPrice * qty}
                  unit={currency_symbol}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Price>
              <Price
                hide={myPrice <= discountPrice}
                striked={discountPrice > tierPrice}
                pr='0.8em'
              >
                <Currency
                  value={discountPrice * qty}
                  unit={currency_symbol}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Price>
              <Price hide={tierPrice >= discountPrice}>
                <Currency
                  value={tierPrice * qty}
                  unit={currency_symbol}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Price>
            </Flex>
          </Flex>
          <Flex
            width={[1]}
            flexDirection='row'
            justifyContent='flex-end'
            alignItems={"flex-start"}
            pt={[2]}
            pr={[2]}
          >
            <Flex
              flexDirection='row'
              justifyContent='space-between'
              width={[1]}
              alignItems='center'
            >
              <Text pr={[2]} color={primaryColor}>
                {translate("resources.orders.labels.shipped")} {qty}
              </Text>
              <Text pr={[2]} color={primaryColor}>
                {translate(titleList)}
              </Text>
            </Flex>

            {/* <Quantity
              size="small"
              qty={aqty}
              min={0}
              max={qty}
              onQtyChange={(qty) => onQtyChange(qty)}
            /> */}
            <ReceivedQty
              item={item}
              listQty={listQty}
              setListQty={setListQty}
              aqty={aqty}
              setQty={setQty}
            />
          </Flex>
        </Box>
      </Flex>
    </Container>
  );
};

export default CartItem;
