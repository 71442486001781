import React from "react"
import { BooleanInput } from "react-admin"
import { Box } from "rebass"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "../../../components"
/**
 * 
 
    capabilities: {
        pickup_from_this_source:true/false,
        transfer_from_this_source:true/false,
        ship_from_this_source:true/false,
        accepts_returns:true/false,
        accepts_exchange:true/false,
    },
 */

const CapabilitiesFields = (props) => {
  const { translate } = props
  return (
    <Box width={[1]} py={[2]}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="capabilities-content"
          id="capabilities-header"
        >
          <Typography>
            {translate("resources.sources.fields.capabilities")}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box width={[1]}>
            <BooleanInput
              source="capabilities.pickup_from_this_source"
              label={"resources.sources.fields.pick_from_source"}
            />
            <BooleanInput
              source="capabilities.transfer_from_this_source"
              label={"resources.sources.fields.transfer_from_source"}
            />
            <BooleanInput
              source="capabilities.ship_from_this_source"
              label={"resources.sources.fields.ship_from_source"}
            />
            <BooleanInput
              source="capabilities.accepts_returns"
              label={"resources.sources.fields.accepts_return"}
            />
            <BooleanInput
              source="capabilities.accepts_exchange"
              label={"resources.sources.fields.accepts_exchange"}
            />
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Box>
  )
}

export default CapabilitiesFields
