import React from "react";
import { Flex, Text, Box, Image } from "rebass";
import { get } from "lodash";
// const LinkHref = ({ url, children, target }) => {
// if (url && url.includes("http")) {
//   return (
//     <a href={url} target={target ? target : ""}>
//       {children}
//     </a>
//   )
// }
//   return <div>{children}</div>;
// };

const defaultLayout = {
  flexDirection: ["column", "row"],
  justifyContent: ["flex-start", "flex-start"],
  alignItems: ["flex-start", "flex-start"],
};

const RowItem = (props) => {
  const { item } = props;
  if (item.type === "icon") {
    const icon = get(item, "icon");
    return icon && icon !== "" ? (
      <Box px={[1]}>
        <Image
          src={icon}
          style={{ width: get(item, "widthIcon", "100px"), height: "auto" }}
        />
      </Box>
    ) : (
      <span />
    );
  }
  return (
    <Box px={[1]}>
      <Text color={get(item, "color", "#000000")}>
        <div dangerouslySetInnerHTML={{ __html: get(item, "title", "") }} />
      </Text>
      {/* <Text color={get(item, "color", "#000000")}>
        {get(item, "title", "")}
      </Text> */}
    </Box>
  );
};

export default (props) => {
  const { list } = props.values;
  const layoutRow = get(props, "values.layoutRow", defaultLayout);
  if (list && list.length === 0) {
    return (
      <Flex
        width={[1]}
        justifyContent={"center"}
        flexDirection={"row"}
        alignItems={"center"}
        p={[1]}
        style={{ color: "#40a9ff" }}
      >
        Click here to update content
      </Flex>
    );
  }
  return (
    <Flex width={[1]} {...layoutRow}>
      {list &&
        list.length > 0 &&
        list.map((item, index) => <RowItem key={index} item={item} />)}
    </Flex>
  );
};
