export const transitionsAnimation = [
  {
    animation: "ease both",
    value: "fade",
    text: "Fade",
  },
  {
    animation: "ease both",
    value: "moveFromRight",
    text: "Move From Right",
  },
  {
    animation: "ease both",
    value: "moveFromLeft",
    text: "Move From Left",
  },
  {
    animation: "ease both",
    value: "moveFromTop",
    text: "Move From Top",
  },
  {
    animation: "ease both",
    value: "moveFromBottom",
    text: "Move From Bottom",
  },
  { animation: "ease both", value: "zoom", text: "Zoom" },
  { animation: "ease both", value: "scaleUp", text: "Scale Up" },
  {
    animation: "ease both",
    value: "scaleUpDown",
    text: "Scale Up Down",
  },
  {
    animation: "ease-out both",
    value: "scaleUpCenter",
    text: "Scale Up Center",
  },
  {
    animation: "both ease-out",
    value: "flipInRight",
    text: "Flip Horizontal",
  },
  {
    animation: "both ease-in",
    value: "flipInTop",
    text: "Flip In Vertical",
  },
  {
    animation: "both ease-out",
    value: "rotateInNewspaper",
    text: "Rotate In Newspaper",
  },
  {
    animation: "both ease",
    value: "rotatePullLeft",
    text: "Rotate Pull Horizontal",
  },
  {
    animation: "both ease",
    value: "rotatePullTop",
    text: "Rotate Pull Vertical",
  },
  {
    animation: "both ease-out",
    value: "rotateSidesIn",
    text: "Rotate Side",
  },
  {
    animation: "both ease",
    value: "rotateUnfoldLeft",
    text: "Rotate Unfold Left",
  },
  {
    animation: "both ease",
    value: "rotateUnfoldRight",
    text: "Rotate Unfold Right",
  },
  {
    animation: "both ease",
    value: "rotateUnfoldTop",
    text: "Rotate Unfold Top",
  },
  {
    animation: "both ease",
    value: "rotateUnfoldBottom",
    text: "Rotate Unfold Bottom",
  },
  {
    animation: "both ease",
    value: "rotateRoomLeftIn",
    text: "Rotate Room Horizontal",
  },
  {
    animation: "both ease",
    value: "rotateRoomTopIn",
    text: "Rotate Room Vertical",
  },
  {
    animation: "both ease-in",
    value: "rotateCubeLeftIn",
    text: "Rotate Cube Left",
  },
  {
    animation: "both ease-in",
    value: "rotateCubeRightIn",
    text: "Rotate Cube Right",
  },
  {
    animation: "both ease-in",
    value: "rotateCubeTopIn",
    text: "Rotate Cube Top",
  },
  {
    animation: "both ease-in",
    value: "rotateCubeBottomIn",
    text: "Rotate Cube Bottom",
  },
  {
    animation: "both ease",
    value: "rotateCarouselLeftIn",
    text: "Rotate Carousel Left",
  },
  {
    animation: "both ease",
    value: "rotateCarouselRightIn",
    text: "Rotate Carousel Right",
  },
  {
    animation: "both ease",
    value: "rotateCarouselTopIn",
    text: "Rotate Carousel Top",
  },
  {
    animation: "both ease",
    value: "rotateCarouselBottomIn",
    text: "Rotate Carousel Bottom",
  },
]
