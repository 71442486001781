import { Layout3 } from "../../TemplateImageSonak";
export const template3A = {
  LMZTwptw: {
    id: "LMZTwptw",
    type: "Row6",
    props: {
      values: {
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 2,
          height: "100%",
          layout: {
            wrap: true,
            align: "top",
            gutter: 10,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "10px 20px 10px 20px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "1080px",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "top",
            gutter: 20,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "10px 10px 10px 10px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 1,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                show: null,
                width: "100%",
                height: "100%",
                layout: {
                  span: 6,
                  order: 1,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 2,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                show: null,
                width: "100%",
                height: "100%",
                layout: {
                  span: 6,
                  order: 2,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 3,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                show: null,
                width: "100%",
                height: "100%",
                layout: {
                  span: 6,
                  order: 3,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 4,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                show: null,
                width: "100%",
                height: "100%",
                layout: {
                  span: 6,
                  order: 4,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    childOf: "Row",
    layout: Layout3,
    children: ["tKAqJbjk", "sPHRDRPc", "UJQWYzLk", "3+rZNbOO"],
    myContent: [
      {
        id: "1NzVhv0r",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>LEARN MORE</strong></p>",
            type: "TextView",
            isLink: true,
            mobile: {
              color: "rgba(75,75,75,1)",
              fontSize: 8,
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(75,75,75,1)",
              fontSize: 8,
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            isShowMobile: false,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "3+rZNbOO",
          idx: 2,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "3+rZNbOO",
          idx: 2,
          index: 1,
        },
      },
      {
        id: "99vcQX4i",
        type: "ImageView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            type: "ImageView",
            align: "center",
            mobile: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            parent: "Content",
            desktop: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            imageUrl: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-desktop-Z48.jpeg",
              mobile: {
                width: 0,
                height: 0,
              },
              desktop: {
                width: 0,
                height: 0,
              },
              urlMobile:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-desktop-Z48.jpeg",
            },
          },
        },
        parent: {
          id: "3+rZNbOO",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "3+rZNbOO",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "FBqWF8XO",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>MUELLER BRAND VIDEO</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 11,
              textAlign: "center",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "14px 0px 20px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 13,
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "20px 0px 10px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        style: {},
        parent: {
          id: "sPHRDRPc",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "sPHRDRPc",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "P9PFtB/V",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>PLAYITRIGHT TV</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 11,
              textAlign: "center",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "14px 0px 20px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 13,
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "20px 0px 10px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "tKAqJbjk",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "tKAqJbjk",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "GnniGNG5",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>LEARN MORE</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(75,75,75,1)",
              fontSize: 8,
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(75,75,75,1)",
              fontSize: 8,
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px 0px 0px 0px",
            },
            isShowMobile: false,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "tKAqJbjk",
          idx: 2,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "tKAqJbjk",
          idx: 2,
          index: 1,
        },
      },
      {
        id: "JXP6SHIN",
        type: "ImageView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            type: "ImageView",
            align: "center",
            mobile: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            parent: "Content",
            desktop: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            imageUrl: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-desktop-8MI.jpeg",
              mobile: {
                width: 0,
                height: 0,
              },
              desktop: {
                width: 0,
                height: 0,
              },
              urlMobile:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-desktop-8MI.jpeg",
            },
          },
        },
        parent: {
          id: "sPHRDRPc",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "sPHRDRPc",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "LM2ltU4k",
        type: "ImageView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            type: "ImageView",
            align: "center",
            mobile: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            parent: "Content",
            desktop: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            imageUrl: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-desktop-FK7.jpeg",
              mobile: {
                width: 0,
                height: 0,
              },
              desktop: {
                width: 0,
                height: 0,
              },
              urlMobile:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-desktop-FK7.jpeg",
            },
          },
        },
        style: {},
        parent: {
          id: "UJQWYzLk",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "UJQWYzLk",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "M/zJ0jKN",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>QUALITY ASSURANCE</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 11,
              textAlign: "center",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "14px 0px 20px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 13,
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "20px 0px 10px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        style: {},
        parent: {
          id: "UJQWYzLk",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "UJQWYzLk",
          idx: 1,
          index: 1,
        },
      },

      {
        id: "RlFk1YPn",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>PLAYRIGHT CLUB</strong></p>",
            type: "TextView",
            isLink: false,
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 11,
              textAlign: "center",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "14px 0px 20px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 13,
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "20px 0px 10px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "3+rZNbOO",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "3+rZNbOO",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "fF6VigrM",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>LEARN MORE</strong></p>",
            type: "TextView",
            isLink: true,
            mobile: {
              color: "rgba(75,75,75,1)",
              fontSize: 8,
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(75,75,75,1)",
              fontSize: 8,
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            isShowMobile: false,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "sPHRDRPc",
          idx: 2,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "sPHRDRPc",
          idx: 2,
          index: 1,
        },
      },
      {
        id: "g8YM/fEK",
        type: "ImageView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            type: "ImageView",
            align: "center",
            mobile: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            parent: "Content",
            desktop: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            imageUrl: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-desktop-ZW8.jpeg",
              mobile: {
                width: 0,
                height: 0,
              },
              desktop: {
                width: 0,
                height: 0,
              },
              urlMobile:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-desktop-ZW8.jpeg",
            },
          },
        },
        parent: {
          id: "tKAqJbjk",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "tKAqJbjk",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "x/AcKrw2",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>LEARN MORE</strong></p>",
            type: "TextView",
            isLink: true,
            mobile: {
              color: "rgba(75,75,75,1)",
              fontSize: 8,
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(75,75,75,1)",
              fontSize: 8,
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            isShowMobile: false,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "UJQWYzLk",
          idx: 2,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "UJQWYzLk",
          idx: 2,
          index: 1,
        },
      },
    ],
  },
};
