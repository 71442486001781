import React, { useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Flex, Box, Text } from "rebass";
import { Currency } from "../../../../components";
import { H3, Small } from "../../../../components/Text";
import { Quantity } from "../Quantity";
import { getPhoto } from "../utils";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary[500],
  },
}));

const Container = styled(Flex)`
  border-top: 1px solid rgba(0, 0, 0, 0.06);
`;

const Image = styled.img`
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  padding: 8px;
  margin: 0 auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border: 2px solid;
  border-color: rgba(0, 0, 0, 0.1);
`;

const Price = styled(Text)`
  font-size: ${(props) => (props.striked ? "1em" : "1em")};
  display: ${(props) => (props.hide ? "none" : "block")};
  text-decoration: ${(props) => (props.striked ? "line-through" : "")};
  background-color: ${(props) => (props.striked ? "#FF9900" : "transparent")};
`;

const ProductItem = (props) => {
  const classes = useStyles();

  const { item, data, onDelete } = props;
  const { qty, price, discount_price, tier_price, name } = item;
  const { variant } = data;
  const [aqty, setQty] = useState(0);
  const tierPrice = tier_price * 1;
  const discountPrice = discount_price * 1;
  const myPrice = price * 1;
  const onQtyChange = (qty) => {
    setQty(qty);
  };
  let variants = [];

  for (var key in variant) {
    variants.push(
      <Small fontSize="0.9em" key={key} pr={1} pt={1}>
        <span className={classes.text}>{key}</span> {variant[key]}
      </Small>
    );
  }

  return (
    <Container width={[1, 1, 1]} py={2}>
      <Box width={["160px", "200px", "256px"]} p={[1]}>
        <Image src={getPhoto(item.photos)} />
      </Box>
      <Flex width={[1]} p={[1]} pr={[2]}>
        <Box width={1}>
          <Box>
            <H3>{name}</H3>
            <Flex
              flexDirection={"row"}
              width={[1]}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
            >
              <Flex
                flexDirection={"row"}
                justifyContent={"space-between"}
                pt={[2]}
              >
                {variants && variants.length > 0 && <Box>{variants}</Box>}
              </Flex>

              <IconButton onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Flex>
          </Box>
          <Box width={1} py={[2]}>
            <Flex
              width={[1]}
              flexDirection="row"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Flex
                flexDirection={"row"}
                alignItems={"center"}
                sx={{
                  "&>div": {
                    pr: [2],
                  },
                }}
              >
                <Price striked={myPrice > discountPrice} pr="0.8em">
                  <Currency value={myPrice} unit="₱" decimalScale={2} />
                </Price>
                <Price
                  hide={myPrice <= discountPrice}
                  striked={discountPrice > tierPrice}
                  pr="0.8em"
                >
                  <Currency value={discountPrice} unit="₱" decimalScale={2} />
                </Price>
                <Price hide={tierPrice >= discountPrice}>
                  <Currency value={tierPrice} unit="₱" decimalScale={2} />
                </Price>
              </Flex>
              <Quantity
                size="small"
                qty={aqty}
                min={0}
                max={qty}
                onQtyChange={(qty) => onQtyChange(qty)}
              />
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default ProductItem;
