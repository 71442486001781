export default {
  provisions: {
    name: "Provisions",
    tabs: {
      main: "Main",
      earn_point: "Earn Point",
      spend: "Spend",
    },
    fields: {
      company_name: "Company Name",
      loyalty_program_name: "Loyalty Program Name",
      email: "Email",
      point_value: "Point Value",
      expiry_days: "Expiry Days",
      sign_up_point: "Signup Point",
      key_voucher: " Dollar $",
      refer_a_friend_point: "Refer a friend point",
      cashback: "CashBack",
      status: "status",
      uuid: "Uuid",
      plan: "Plan",
      region: "Region",
      active: "Active",
      inactive: "Inactive",
      vouchers: "Vouchers",
    },
  },
}
