import React from "react";
import { FormDataConsumer } from "react-admin";
import { BetterCustomSimpleForm, EditToolbar } from "../../../components";
import { FormFields } from "./FormFields";

const MainField = (props) => {
  return (
    <FormDataConsumer>
      {({ formData }) => (
        <FormFields {...props} isCreate={true} formValues={formData} />
      )}
    </FormDataConsumer>
  );
};

export const CreateForm = (props) => {
  return (
    <BetterCustomSimpleForm
      {...props}
      title={"resources.websites.back"}
      labelBack={"resources.websites.new_title"}
      toolbar={<EditToolbar />}
      isShowBack={true}
    >
      <MainField {...props} />
    </BetterCustomSimpleForm>
  );
};
