import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { showNotification as showNotificationAction } from "react-admin";
import { userLogin as userLoginAction, translate } from "react-admin";
import GoogleLogin from "react-google-login";
import Button from "@material-ui/core/Button";
import { stylesLogin } from "./styles";

const myStyles = {
  width: "100%",
  backgroundColor: "#4fc3f7",
  border: "none",
  outline: "none",
  borderRadius: "3px",
  color: "#FFFFFF",
  padding: "1em",
  textAlign: "center",
  textDecoration: "none",
  cursor: "pointer",
  display: "block",
  fontSize: "14px",
};

class LoginButton extends Component {
  responseGoogle = (response) => {
    // console.log('responseGoogle');
    // console.log(response);
    var token = {
      tokenId: response.tokenId,
      accessToken: response.accessToken,
    };
    this.props.userLogin(token);
  };

  render() {
    const { classes, translate } = this.props;
    // console.log(process.env.REACT_APP_GOOGLE_CLIENT_ID);
    return (
      <GoogleLogin
        // clientId="18881713926-dp8f2ujaftvqsq34kij4giq7musqeaho.apps.googleusercontent.com"
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        render={(renderProps) => (
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            size='medium'
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            {translate("resources.login.titleGoogleButtinon")}
          </Button>
        )}
        onSuccess={this.responseGoogle.bind(this)}
        onFailure={this.responseGoogle.bind(this)}
        prompt='select_account'
        style={{ ...myStyles }}
      />
    );
  }
}

LoginButton.propTypes = {
  push: PropTypes.func,
  showNotification: PropTypes.func,
};

export default connect(null, {
  showNotification: showNotificationAction,
  userLogin: userLoginAction,
})(withStyles(stylesLogin)(translate(LoginButton)));
