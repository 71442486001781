import React, { Component } from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { RestApi } from "../../../../restClient";
import { BoxContent } from "../styled";
import {
  FooterAction,
  PanelCard,
  ContainerPanel,
  ContentDetail,
} from "../actions";
import { get } from "lodash";
import { CategoryList, ProductContent } from "./product";
import { SelectInput, SelectInputCollection } from "../components";
import { getSiteID } from "../../../../restClient";
import ColorsContent from "./ColorsContent";
import ItemSetting from "./ItemSetting";
// containerPadding: ["0px", "0px"],
//     isShow: [true, true],
//     showPrice: [true, true],
//     productLaunchDate: "",
//     contentList: {
//       type: "Category",
//       value: "",
//     },
/**
 * itemSetting: {
      limit: [100, 100],
      titleFontSize: [12, 12],
      subFontSize: [12, 12],
    },
    colorProps: {
      backgroundColor: "rgba(255,255,255,0)",
      color: "rgba(0,0,0,1)",
      viewallColor: "rgba(0,0,0,1)",
      hoverColor: "rgba(0,0,0,1)",
      hoverBackgroundColor: "rgba(255,255,255,0)",
    },
 */
const defaultItemSetting = {
  limit: [100, 100],
  titleFontSize: [12, 12],
  subFontSize: [12, 12],
  titlePadding: ["0px", "0px"],
  containerPadding: ["0px", "0px"],
  subPadding: ["0px", "0px"],
};
const defaultColor = {
  backgroundColor: "rgba(255,255,255,0)",
  color: "rgba(0,0,0,1)",
  viewallColor: "rgba(0,0,0,1)",
  hoverColor: "rgba(0,0,0,1)",
  hoverBackgroundColor: "rgba(255,255,255,0)",
};

const dataTypeProduct = [
  { value: "Category", text: "Category" },
  { value: "Collection", text: "Collection" },
];

class ProductListView extends Component {
  constructor(props) {
    super(props);
    const {
      showPrice,
      containerPadding,
      contentList,
      productLaunchDate,
      isShow,
      showPagination,
      columnProduct,
      colorProps,
      itemSetting,
      gutter,
      showSearch,
    } = props.data.values;
    this.state = {
      heightContent: props.heightContent,
      widthContent: props.widthContent,
      containerPadding: containerPadding,
      showPrice: showPrice,
      contentList: contentList,
      productLaunchDate: productLaunchDate,
      isShow: isShow,
      categories: [],
      collections: [],
      columnProduct: columnProduct || [12, 4],
      showPagination: showPagination || [false, false],
      colorProps: colorProps || defaultColor,
      itemSetting: itemSetting || defaultItemSetting,
      gutter: gutter || [0, 0],
      showSearch: showSearch || [true, true],
    };
  }
  componentDidMount() {
    this.getCategories();
    this.getCollections();
  }

  getCollections = () => {
    // const { websiteId } = this.props;
    RestApi(`/collections?site_id=${getSiteID()}`)
      .then((res) => res.json())
      .then((items) => {
        // console.log(items);
        if (items && items) {
          this.setState({ collections: items });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  getCategories = () => {
    const { websiteId } = this.props;
    RestApi(`/categories?website_id=${websiteId}`)
      .then((res) => res.json())
      .then((items) => {
        if (items && items) {
          this.setState({ categories: items });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };
  onClickCopy = () => {
    let newData = this.props.data;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values.contentList = this.state.contentList;
    newData.values.showPrice = this.state.showPrice;
    newData.values.isShow = this.state.isShow;
    newData.values.productLaunchDate = this.state.productLaunchDate;
    newData.values.showPagination = this.state.showPagination;
    newData.values.columnProduct = this.state.columnProduct;
    newData.values.colorProps = this.state.colorProps;
    newData.values.itemSetting = this.state.itemSetting;
    newData.values.gutter = this.state.gutter;
    newData.values.showSearch = this.state.showSearch;
    localStorage.setItem("ProductListView", JSON.stringify(newData.values));
  };
  onClickPaste = () => {
    const IconContent = JSON.parse(localStorage.getItem("ProductListView"));
    if (IconContent) {
      const {
        showPrice,
        containerPadding,
        contentList,
        productLaunchDate,
        isShow,
        showPagination,
        columnProduct,
        colorProps,
        itemSetting,
        gutter,
        showSearch,
      } = IconContent;
      this.setState({
        containerPadding: containerPadding,
        showPrice: showPrice,
        contentList: contentList,
        productLaunchDate: productLaunchDate,
        isShow: isShow,
        showPagination: showPagination,
        columnProduct: columnProduct,
        colorProps: colorProps || defaultColor,
        itemSetting: itemSetting || defaultItemSetting,
        gutter: gutter || [0, 0],
        showSearch: showSearch || [true, true],
      });
    }
  };
  handleExpandChange = (expanded) => {
    this.setState({ expanded: expanded });
  };
  onClickSave = () => {
    let newData = this.props.data;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values.contentList = this.state.contentList;
    newData.values.showPrice = this.state.showPrice;
    newData.values.isShow = this.state.isShow;
    newData.values.productLaunchDate = this.state.productLaunchDate;
    newData.values.showPagination = this.state.showPagination;
    newData.values.columnProduct = this.state.columnProduct;
    newData.values.colorProps = this.state.colorProps;
    newData.values.itemSetting = this.state.itemSetting;
    newData.values.gutter = this.state.gutter;
    newData.values.showSearch = this.state.showSearch;
    // this.getProductList()
    this.props.onUpdateData(newData);
  };
  onSetValue = (k, v) => {
    this.setState({
      ...this.state,
      [k]: v,
    });
  };
  onSetItemValue = (k, v, parent) => {
    const newParam = {
      ...this.state[parent],
      [k]: v,
    };
    this.onSetValue(parent, newParam);
  };
  render() {
    const { categories, contentList } = this.state;
    const typeContent = get(contentList, "type", "");
    const valueContent = get(contentList, "value", "");
    return (
      <Flex width={[1]} flexDirection={"column"}>
        <BoxContent style={{ height: this.state.heightContent - 120 }}>
          <ContainerPanel>
            <PanelCard
              title={"Desktop Setting"}
              id={"content-desktop-list-view"}
            >
              <ProductContent
                state={this.state}
                onSetValue={this.onSetValue}
                indexValue={1}
              />
            </PanelCard>
            <PanelCard title={"Mobile Setting"} id={"content-mobile-list-view"}>
              <ProductContent
                state={this.state}
                onSetValue={this.onSetValue}
                indexValue={0}
              />
            </PanelCard>
            <PanelCard title={"Item Setting"} id={`item-setting`}>
              <ItemSetting
                onUpdate={(k, v) => this.onSetValue(k, v)}
                keyContent={"itemSetting"}
                values={get(this.state, "itemSetting", defaultItemSetting)}
              />
            </PanelCard>
            <PanelCard title={"Colors Setting"} id={`setting-colors`}>
              <ColorsContent
                state={get(this.state, "colorProps", {
                  backgroundColor: "rgba(255,255,255,0)",
                  color: "rgba(0,0,0,1)",
                  viewallColor: "rgba(0,0,0,1)",
                  hoverColor: "rgba(0,0,0,1)",
                  hoverBackgroundColor: "rgba(255,255,255,0)",
                })}
                contentId={"list-slide-color"}
                onSetValue={(v) => this.onSetValue("colorProps", v)}
              />
            </PanelCard>
            <PanelCard
              title={"Content Categoy Value"}
              id={"content-category-list-view"}
            >
              <SelectInput
                id={`Content-type`}
                title={"Content Type"}
                value={typeContent}
                list={dataTypeProduct}
                onChange={(e) =>
                  this.onSetItemValue("type", e.target.value, "contentList")
                }
              />
              {typeContent === "Category" && (
                <ContentDetail>
                  <CategoryList
                    list={categories}
                    onSetValue={this.onSetValue}
                    contentList={contentList}
                  />
                </ContentDetail>
              )}
              {typeContent === "Collection" && (
                <ContentDetail>
                  <SelectInputCollection
                    id={`Content-value`}
                    title={"Collection"}
                    value={valueContent}
                    list={this.state.collections}
                    onChange={(e) =>
                      this.onSetItemValue(
                        "value",
                        e.target.value,
                        "contentList"
                      )
                    }
                  />
                </ContentDetail>
              )}
            </PanelCard>
          </ContainerPanel>
        </BoxContent>
        <FooterAction
          onClickSave={this.onClickSave}
          onClickCopy={this.onClickCopy}
          onClickPaste={this.onClickPaste}
          widthContent={this.state.widthContent}
        />
      </Flex>
    );
  }
}

ProductListView.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  onUpdateData: PropTypes.func.isRequired,
  typePage: PropTypes.string,
  marketerId: PropTypes.string,
};

ProductListView.defaultProps = {
  data: {},
  id: "",
  typePage: "",
  marketerId: "0",
};
export default ProductListView;
