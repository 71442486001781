import * as React from "react";
// import { Children, cloneElement } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, makeStyles } from "@material-ui/core";
//useMediaQuery
// import lodashGet from "lodash/get";
import { setSidebarVisibility } from "react-admin";

export const DRAWER_WIDTH = 235;
export const CLOSED_DRAWER_WIDTH = 55;

const useStyles = makeStyles(
  (theme) => ({
    drawerPaper: {
      position: "relative",
      height: "auto",
      overflowX: "hidden",
      width: DRAWER_WIDTH,
      //   width: (props) =>
      //     props.open
      //       ? lodashGet(theme, "sidebar.width", DRAWER_WIDTH)
      //       : lodashGet(theme, "sidebar.closedWidth", CLOSED_DRAWER_WIDTH),
      //   transition: theme.transitions.create("width", {
      //     easing: theme.transitions.easing.sharp,
      //     duration: theme.transitions.duration.leavingScreen,
      //   }),
      backgroundColor: "#424242",
      marginTop: "0.5em",
      borderRight: "none",
      [theme.breakpoints.only("xs")]: {
        marginTop: 0,
        height: "100vh",
        position: "inherit",
        backgroundColor: "#424242",
      },
      [theme.breakpoints.up("md")]: {
        border: "none",
        marginTop: "1.5em",
        backgroundColor: "#424242",
      },
      zIndex: "inherit",
    },
  }),
  { name: "RaSidebar" }
);

const Sidebar = (props) => {
  const {
    children,
    closedSize,
    size,
    classes: classesOverride,
    ...rest
  } = props;
  const dispatch = useDispatch();
  //   const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  //   const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.locale); // force redraw on locale change
  //   const handleClose = () => dispatch(setSidebarVisibility(false));
  const toggleSidebar = () => dispatch(setSidebarVisibility(!open));
  const classes = useStyles({ ...props, open });
  //   console.log("masuk sidebar", isSmall);
  //   console.log("masuk sidebar", isXSmall);
  return (
    <Drawer
      variant='permanent'
      open={open}
      PaperProps={{
        className: classes.drawerPaper,
      }}
      onClose={toggleSidebar}
      {...rest}
    >
      {children}
    </Drawer>
  );
  //   return isXSmall ? (
  //     <Drawer
  //       variant="temporary"
  //       open={open}
  //       PaperProps={{
  //         className: classes.drawerPaper,
  //       }}
  //       onClose={toggleSidebar}
  //       {...rest}
  //     >
  //       {cloneElement(Children.only(children), {
  //         onMenuClick: handleClose,
  //       })}
  //     </Drawer>
  //   ) : isSmall ? (
  //     <Drawer
  //       variant="permanent"
  //       open={open}
  //       PaperProps={{
  //         className: classes.drawerPaper,
  //       }}
  //       onClose={toggleSidebar}
  //       {...rest}
  //     >
  //       {cloneElement(Children.only(children), {
  //         onMenuClick: handleClose,
  //       })}
  //     </Drawer>
  //   ) : (
  //     <Drawer
  //       variant="permanent"
  //       open={open}
  //       PaperProps={{
  //         className: classes.drawerPaper,
  //       }}
  //       onClose={toggleSidebar}
  //       {...rest}
  //     >
  //       {children}
  //     </Drawer>
  //   );
};

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Sidebar;
