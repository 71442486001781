import React from "react";
import { Row } from "./rows";
import {
  CarouselView,
  CarouselLinkView,
  CarouselAnimationView,
} from "./carousel";
import { AvatarView } from "./avatar";
import { TextView, TextGroupView } from "./text";
import { DividerView } from "./divider";
import { ImageView, ImageTextView } from "./images";
import { ListSlideView, ProductListView } from "./list";
import { FAButtonView } from "./fab";
import { FlatButtonView, IconButtonView } from "./button";
import { YoutubeView } from "./youtube";
import { ProductGroupView } from "./product";
import { MenuView } from "./menu";
import { GridListView } from "./gridlist";
import { SocialMediaView } from "./social_media";
import { AccordionListView } from "./accordian";
import { MenuGroupView } from "./menugroup";
import { MultiContentGroupView } from "./multicontentgroup";
import { SubcribeView } from "./subcribe";
import { RowIconText } from "./rowicontext";
import { MenuContentView } from "./menucontent";

export const dataPropreties = (item, id, onUpdateData, props) => {
  return {
    Row: (
      <Row
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    TextView: (
      <TextView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    TextGroupView: (
      <TextGroupView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    DividerView: (
      <DividerView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    AvatarView: (
      <AvatarView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    ImageView: (
      <ImageView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    ImageTextView: (
      <ImageTextView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    FlatButtonView: (
      <FlatButtonView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    // ButtonView: (
    //   <FlatButtonView
    //     data={item}
    //     id={id}
    //     onUpdateData={(data) => onUpdateData(data)}
    //     {...props}
    //   />
    // ),
    IconButtonView: (
      <IconButtonView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    FAButtonView: (
      <FAButtonView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    ProductListView: (
      <ProductListView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    YoutubeView: (
      <YoutubeView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    SocialMediaView: (
      <SocialMediaView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    CarouselView: (
      <CarouselView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    CarouselLinkView: (
      <CarouselLinkView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    ListSlideView: (
      <ListSlideView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    CarouselAnimationView: (
      <CarouselAnimationView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),

    ProductGroupView: (
      <ProductGroupView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    MenuView: (
      <MenuView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),

    GridListView: (
      <GridListView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    AccordionListView: (
      <AccordionListView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    MenuGroupView: (
      <MenuGroupView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    MultiContentGroupView: (
      <MultiContentGroupView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    SubcribeView: (
      <SubcribeView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    RowIconText: (
      <RowIconText
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
    MenuContentView: (
      <MenuContentView
        data={item}
        id={id}
        onUpdateData={(data) => onUpdateData(data)}
        {...props}
      />
    ),
  };
};
