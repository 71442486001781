import React, { useState } from "react";
import { get } from "lodash";
import { Flex } from "rebass";
import { ItemDesktop } from "./desktop";
import { ItemMobile } from "./mobile";
//ItemContents

const defaultItemContent = {
  desktop: {
    padding: "0px",
    backgroundColor: "rgba(255,255,255,0)",
    width: "100%",
    height: "100%",
    bgProps: {
      show: false,
      content: {
        url: "",
        size: "cover",
        position: "center",
        repeat: "no-repeat",
        ratio: 1.2,
      },
    },
    gradient: {
      show: false,
      content: {
        bg1: "rgb(34,193,195)",
        bg2: "rgba(253,187,45,1)",
        type: "linear-gradient",
        degre: 160,
        linear: [0, 100],
      },
    },
  },
  mobile: {
    padding: "0px",
    backgroundColor: "rgba(255,255,255,0)",
    width: "100%",
    height: "100%",
    bgProps: {
      show: false,
      content: {
        url: "",
        size: "cover",
        position: "center",
        repeat: "no-repeat",
        ratio: 1.2,
      },
    },
    gradient: {
      show: false,
      content: {
        bg1: "rgb(34,193,195)",
        bg2: "rgba(253,187,45,1)",
        type: "linear-gradient",
        degre: 160,
        linear: [0, 100],
      },
    },
  },
};

export default (props) => {
  const { itemContent, onUpdateItemContent } = props;
  const { desktop, mobile } = get(itemContent, "props.values", {
    desktop: {},
    mobile: {},
  });
  const [state, setState] = useState({
    desktop: {
      padding: get(desktop, "padding", "0px"),
      backgroundColor: get(desktop, "backgroundColor", "transparent"),
      width: get(desktop, "width", "100%"),
      maxWidth: get(desktop, "maxWidth", "100%"),
      height: get(desktop, "height", "100%"),
      layout: get(desktop, "layout", { span: 0, order: 0 }),
      bgProps: get(desktop, "bgProps.show", false)
        ? get(desktop, "bgProps", defaultItemContent.desktop.bgProps)
        : defaultItemContent.desktop.bgProps,
      gradient: get(desktop, "gradient.show", false)
        ? get(desktop, "gradient", defaultItemContent.desktop.gradient)
        : defaultItemContent.desktop.gradient,
    },
    mobile: {
      padding: get(mobile, "padding", "0px"),
      backgroundColor: get(mobile, "backgroundColor", "transparent"),
      width: get(mobile, "width", "100%"),
      maxWidth: get(mobile, "maxWidth", "100%"),
      height: get(mobile, "height", "100%"),
      layout: get(
        mobile,
        "layout",
        get(desktop, "layout", { span: 0, order: 0 })
      ),
      bgProps: get(mobile, "bgProps.show", false)
        ? get(mobile, "bgProps", defaultItemContent.mobile.bgProps)
        : defaultItemContent.mobile.bgProps,
      gradient: get(mobile, "gradient.show", false)
        ? get(mobile, "gradient", defaultItemContent.mobile.gradient)
        : defaultItemContent.mobile.gradient,
    },
  });

  const onUpdateContentMobile = (k, v) => {
    const { mobile } = state;
    const newMobile = {
      ...mobile,
      [k]: v,
    };
    setState({
      ...state,
      mobile: newMobile,
    });
    onSaveContent(state.desktop, newMobile);
  };
  const onUpdateContentDekstop = (k, v) => {
    const { desktop } = state;
    const newDekstop = {
      ...desktop,
      [k]: v,
    };
    setState({
      ...state,
      desktop: newDekstop,
    });
    onSaveContent(newDekstop, state.mobile);
  };

  const onSaveContent = (desktop, mobile) => {
    let newBgPropsDesktop = { show: false };
    let newGradientDesktop = { show: false };
    if (get(desktop, "bgProps.show", false)) {
      newBgPropsDesktop = { ...get(desktop, "bgProps", { show: false }) };
    }
    if (get(desktop, "gradient.show", false)) {
      newGradientDesktop = { ...get(desktop, "gradient", { show: false }) };
    }
    let newBgPropsMobile = { show: false };
    let newGradientMobile = { show: false };
    if (get(mobile, "bgProps.show", false)) {
      newBgPropsMobile = { ...get(mobile, "bgProps", { show: false }) };
    }
    if (get(mobile, "gradient.show", false)) {
      newGradientMobile = { ...get(mobile, "gradient", { show: false }) };
    }
    const newContent = {
      desktop: {
        padding: get(desktop, "padding", "0px"),
        backgroundColor: get(desktop, "backgroundColor", "transparent"),
        width: get(desktop, "width", "100%"),
        maxWidth: get(desktop, "maxWidth", "100%"),
        height: get(desktop, "height", "100%"),
        layout: get(desktop, "layout", { span: 0, order: 0 }),
        bgProps: { ...newBgPropsDesktop },
        gradient: { ...newGradientDesktop },
      },
      mobile: {
        padding: get(mobile, "padding", "0px"),
        backgroundColor: get(mobile, "backgroundColor", "transparent"),
        width: get(mobile, "width", "100%"),
        maxWidth: get(mobile, "maxWidth", "100%"),
        height: get(mobile, "height", "100%"),
        layout: get(mobile, "layout", { span: 0, order: 0 }),
        bgProps: { ...newBgPropsMobile },
        gradient: { ...newGradientMobile },
      },
    };

    onUpdateItemContent(newContent);
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <ItemDesktop
        state={state.desktop}
        onSetValue={(k, v) => onUpdateContentDekstop(k, v)}
      />
      <ItemMobile
        state={state.mobile}
        onSetValue={(k, v) => onUpdateContentMobile(k, v)}
      />
    </Flex>
  );
};
