import React, { Fragment } from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import { Row, Col } from "antd";
import ItemButtons from "./ItemButtons";
import ItemText from "./ItemText";
import ItemImages from "./ItemImages";
import ItemSocial from "./ItemSocial";
// import { useClientRect } from "../../../../../components";
// const LinkHref = ({ url, children, target }) => {
// if (url && url.includes("http")) {
//   return (
//     <a href={url} target={target ? target : ""}>
//       {children}
//     </a>
//   )
// }
//   return <div>{children}</div>;
// };

const defaultLayout = {
  flexDirection: ["column", "row"],
  justifyContent: ["flex-start", "flex-start"],
  alignItems: ["flex-start", "flex-start"],
  flexWrap: ["nowrap", "nowrap"],
  column: [1, 1],
};

const defaultBorder = {
  type: ["solid", "solid"],
  color: ["#e61919", "#e61919"],
  radius: ["0px", "0px"],
  size: [0, 0],
  backgroundColor: ["#FFFFFF", "#FFFFFF"],
};

const RowItem = (props) => {
  const { item, layoutRow, border, indexView } = props;
  // console.log("item", item);
  if (item.typeContent === "button") {
    return (
      <Col span={get(layoutRow, `column[${indexView}]`, 1)}>
        <ItemButtons
          item={item}
          border={border}
          layoutRow={layoutRow}
          indexView={indexView}
        />
      </Col>
    );
  } else if (item.typeContent === "image") {
    return (
      <Col span={get(layoutRow, `column[${indexView}]`, 1)}>
        <ItemImages
          item={item}
          border={border}
          layoutRow={layoutRow}
          indexView={indexView}
        />
      </Col>
    );
  } else if (item.typeContent === "text") {
    return (
      <Col span={get(layoutRow, `column[${indexView}]`, 1)}>
        <ItemText
          item={item}
          border={border}
          layoutRow={layoutRow}
          indexView={indexView}
        />
      </Col>
    );
  } else if (item.typeContent === "social") {
    return (
      <Col span={get(layoutRow, `column[${indexView}]`, 1)}>
        <ItemSocial
          item={item}
          border={border}
          layoutRow={layoutRow}
          indexView={indexView}
        />
      </Col>
    );
  }
  return <Fragment />;
};

export default (props) => {
  const { list } = props.values;
  // const [rect, myRef] = useClientRect();
  const layoutRow = get(props, "values.layoutRow", defaultLayout);
  if (list && list.length === 0) {
    return (
      <Flex width={[1]} p={[3]} flexDirection={"row"} justifyContent={"center"}>
        {" "}
        Add Item Click Here{" "}
      </Flex>
    );
  }

  const indexView = 1; // rect.width > 640 ? 1 : 0;
  const gutter = get(layoutRow, `gutter[${indexView}]`, 8);
  return (
    <Flex width={[1]}>
      <Row
        style={{ alignItems: layoutRow.alignItems[indexView] }}
        gutter={[gutter, gutter]}
      >
        {list &&
          list.length > 0 &&
          list.map((item, index) => (
            <RowItem
              key={index}
              item={item}
              layoutRow={layoutRow}
              border={get(props, "values.border", defaultBorder)}
              indexView={indexView}
            />
          ))}
      </Row>
    </Flex>
  );
};
