import React, { Component } from "react"
import Slider from "react-slick"
import { Box } from "rebass"
import { ContentItemCarousel } from "./Items"

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        fontSize: 48,
      }}
      onClick={onClick}
    />
  )
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        fontSize: 48,
      }}
      onClick={onClick}
    />
  )
}

class ListCarousel extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.state = {
      isIndex: 0,
      isEnd: false,
    }
  }
  next() {
    if (!this.state.isEnd) {
      this.sliderone.slickNext()
    }
  }
  previous() {
    if (this.state.isIndex > 0) {
      this.sliderone.slickPrev()
    }
  }
  onChange = index => {
    const { newMan, screenWidth } = this.props
    const addIndex = screenWidth > 702 ? 6 : 3
    const currentIndex = index + addIndex
    let isEndCurrent = false
    if (currentIndex >= newMan.length) {
      isEndCurrent = true
    }
    this.setState({
      isIndex: index,
      isEnd: isEndCurrent,
    })
  }
  render() {
    const { newMan, screenWidth, settingsCarousel } = this.props
    let settings = settingsCarousel
      ? {
          dots: settingsCarousel.dots,
          infinite: settingsCarousel.infinite,
          speed: settingsCarousel.speed,
          slidesToShow: settingsCarousel.slidesToShow,
          slidesToScroll: settingsCarousel.slidesToScroll,
          autoplay: settingsCarousel.autoplay,
          autoplaySpeed: settingsCarousel.autoplaySpeed,
          nextArrow: <SamplePrevArrow />,
          prevArrow: <SampleNextArrow />,
          appendDots: dots => (
            <div
              style={{
                backgroundColor: "transparent",
                borderRadius: "10px",
                zIndex: 1,
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
        }
      : {
          dots: true,
          infinite: true,
          arrows: true,
          speed: 200,
          slidesToShow: 1,
          slidesToScroll: 1,
          afterChange: index => this.onChange(index),
        }
    return (
      <Box
        style={{
          width: screenWidth,
          backgroundColor: "rbga(0,0,0,0.9",
        }}
      >
        <Slider {...settings}>
          {newMan.map((item, index) => {
            return (
              <ContentItemCarousel
                item={item}
                key={index}
                size={[1]}
                screenWidth={screenWidth}
                pContainer={[0]}
              />
            )
          })}
        </Slider>
      </Box>
    )
  }
}

export default ListCarousel
