import React from "react";
import { DropTarget } from "react-dnd";
import { target } from "./utils";
import { connect } from "../../core";
import { RowIconTextContent } from "./components/rowicontext";

const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
}) => {
  /*isShowDesktop: true,
  isShowMobile: true, */
  const { backgroundColor } = layoutState.getItemJS(id).props.values;
  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        backgroundColor: backgroundColor,
        display: "flex",
        flex: 1,
        padding: 0,
      }}
    >
      <RowIconTextContent values={layoutState.getItemJS(id).props.values} />
    </div>
  );
};

const LayoutContainer = DropTarget("RowIconText", target, (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(EditLayout);

export default connect("layoutState", "readOnly")(LayoutContainer);

// export default LayoutContainer;s
