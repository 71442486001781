import React, { Fragment } from "react";
import {
  DialogIconTemplate,
  TextInput,
  Alignment,
  BlockCounter,
  SwitchInput,
  SelectInput,
  Title,
  Counter,
} from "../components";
import { Flex } from "rebass";
import { SketchColorPicker } from "../../../components";
import { ContainerPanel, ContentDetail, PanelCard } from "../actions";
import { dataTooltipList } from "./dataList";

export const IconButtonFields = (props) => {
  const { state, onSetValue } = props;
  const onUpdateColor = (k, v) => {
    const params = {
      ...state.buttonColors,
      [k]: v,
    };
    onSetValue("buttonColors", params);
  };
  return (
    <ContainerPanel>
      <PanelCard title={"General"} id={"icon-button-general"}>
        <ContentDetail>
          <Title title={"Tooltip"} />
          <SwitchInput
            id={`disable-icon-button-view`}
            title={"Disabled Icon Button"}
            checked={state.disabled}
            onChange={(e) => onSetValue("disabled", e.target.checked)}
          />
          <SwitchInput
            id={`disable-tooltip-view`}
            title={"Disabled Tooltip"}
            checked={state.disabledTooltip}
            onChange={(e) => onSetValue("disabledTooltip", e.target.checked)}
          />
          {!state.disabledTooltip && (
            <Fragment>
              <TextInput
                value={state.tooltip}
                onChange={(value) => onSetValue("tooltip", value)}
                name='textButton'
                title='Tooltip Text'
                fullWidth={true}
              />
              <SelectInput
                id={`tooltip-position-icon-button`}
                title={"Tooltip Position"}
                value={state.tooltipPosition}
                list={dataTooltipList}
                onChange={(e) => onSetValue("tooltipPosition", e.target.value)}
              />
            </Fragment>
          )}
        </ContentDetail>
        <ContentDetail>
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Title title={"Padding Icon"} />
            <Counter
              isDisabled={false}
              onSetValue={(value) => onSetValue("paddingIcon", value)}
              value={state.paddingIcon}
              interval={1}
              maxValue={50}
            />
          </Flex>
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Title title={"Size Icon"} />
            <Counter
              isDisabled={false}
              onSetValue={(value) => onSetValue("sizeIcon", value)}
              value={state.sizeIcon}
              interval={1}
              maxValue={100}
            />
          </Flex>
        </ContentDetail>
        <ContentDetail>
          <Title title='Color Button' />
          <SketchColorPicker
            title={"Color"}
            onUpdateColor={(color) => onUpdateColor("color", color)}
            color={state.buttonColors.color}
          />
          <SketchColorPicker
            title={"Background"}
            onUpdateColor={(color) => onUpdateColor("backgroundColor", color)}
            color={state.buttonColors.backgroundColor}
          />
          <SketchColorPicker
            title={"Hover"}
            onUpdateColor={(color) => onUpdateColor("hoverColor", color)}
            color={state.buttonColors.hoverColor}
          />
        </ContentDetail>
        <ContentDetail>
          <Alignment
            value={state.buttonAlign}
            onChange={(value) => onSetValue("buttonAlign", value)}
            title='Button Alignment'
          />
        </ContentDetail>

        <ContentDetail>
          <DialogIconTemplate
            iconItem={state.icon}
            onItemSelected={(icon) => {
              onSetValue("icon", icon);
            }}
            isRequired={true}
          />
        </ContentDetail>

        <BlockCounter
          containerPadding={state.containerPadding}
          onUpdatePadding={(containerPadding) =>
            onSetValue("containerPadding", containerPadding)
          }
          title={"Container Padding"}
        />
      </PanelCard>
    </ContainerPanel>
  );
};
