import React from "react";
import { Image } from "rebass";
import styled from "styled-components";
const DivContainer = styled.div`
  padding: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition-duration: 0.15s;
  transition-property: box-shadow;
  &:hover {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.35);
  }
`;

const myimage = require("../../assets/images/template13.jpg");

export const LayoutContent = (myIndex) => (
  <DivContainer>
    <Image src={myimage} />
  </DivContainer>
);
const Layout1 = () => (
  <DivContainer>
    <Image src={require("../../assets/images/template1.jpg")} />
  </DivContainer>
);
const Layout2 = () => (
  <DivContainer>
    <Image src={require("../../assets/images/template2.jpg")} />
  </DivContainer>
);
const Layout3 = () => (
  <DivContainer>
    <Image src={require("../../assets/images/template3.jpg")} />
  </DivContainer>
);
const Layout4 = () => (
  <DivContainer>
    <Image src={require("../../assets/images/template4.jpg")} />
  </DivContainer>
);
const Layout5 = () => (
  <DivContainer>
    <Image src={require("../../assets/images/template5.jpg")} />
  </DivContainer>
);
const Layout6 = () => (
  <DivContainer>
    <Image src={require("../../assets/images/template6.jpg")} />
  </DivContainer>
);
const Layout7 = () => (
  <DivContainer>
    <Image src={require("../../assets/images/template7.jpg")} />
  </DivContainer>
);
const Layout8 = () => (
  <DivContainer>
    <Image src={require("../../assets/images/template8.jpg")} />
  </DivContainer>
);
const Layout9 = () => (
  <DivContainer>
    <Image src={require("../../assets/images/template9.jpg")} />
  </DivContainer>
);
const Layout10 = () => (
  <DivContainer>
    <Image src={require("../../assets/images/template10.jpg")} />
  </DivContainer>
);

const Layout11 = () => (
  <DivContainer>
    <Image src={require("../../assets/images/template11.jpg")} />
  </DivContainer>
);
const Layout12 = () => (
  <DivContainer>
    <Image src={require("../../assets/images/template12.jpg")} />
  </DivContainer>
);
const Layout13 = () => (
  <DivContainer>
    <Image src={myimage} />
  </DivContainer>
);

const Layout14 = () => (
  <DivContainer>
    <Image src={require("../../assets/images/templateheader.png")} />
  </DivContainer>
);
const Layout15 = () => (
  <DivContainer>
    <Image src={require("../../assets/images/templateheader1.png")} />
  </DivContainer>
);
const Layout16 = () => (
  <DivContainer>
    <Image src={require("../../assets/images/templateheaderatmos.png")} />
  </DivContainer>
);
//
export {
  Layout1,
  Layout2,
  Layout3,
  Layout4,
  Layout5,
  Layout6,
  Layout7,
  Layout8,
  Layout9,
  Layout10,
  Layout11,
  Layout12,
  Layout13,
  DivContainer,
  Layout14,
  Layout15,
  Layout16,
};
