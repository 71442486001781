import React from "react";
import { Tree } from "antd";
import { Box, Text } from "rebass";
import { sortBy, filter, transform, get, find, uniq } from "lodash";
// const elasticSearchServer = "https://api.staging.commerce.bcxstudio.com/search";
const locale = "en";
export default (props) => {
  const { onSetValue, list, contentList } = props;
  const onSetItemValue = (k, v) => {
    let result = get(contentList, "tags", []);
    if (v && v.length > 0) {
      v.map((category) => {
        const myCat = find(list, function (o) {
          return o.id === category * 1;
        });
        const myTags = get(myCat, "tags", []);
        const oldResult = [...result, ...myTags];
        result = oldResult;
        return category;
      });
    }
    const uniqTags = uniq(result);
    // console.log(uniqTags);
    const params = {
      ...contentList,
      [k]: v,
      tags: uniqTags,
    };
    // console.log(v);
    onSetValue("contentList", params);
  };

  const treeDatas = [];
  if (list) {
    treeDatas.push(
      ...transform(
        sortBy(
          filter(list, (o) => o.parent_id === null),
          (o) => o.sort_order
        ),
        (result, o) => {
          result.push({
            title: get(o, "locale." + locale + ".name", o.name),
            key: "" + o.id,
          });
        },
        []
      )
    );
    for (let i = 0; i < treeDatas.length; i++) {
      const children = [];
      children.push(
        ...transform(
          sortBy(
            filter(list, (o) => "" + o.parent_id === treeDatas[i].key),
            (o) => o.sort_order
          ),
          (result, o) => {
            result.push({
              title: get(o, "locale." + locale + ".name", o.name),
              key: "" + o.id,
            });
          },
          []
        )
      );
      for (let j = 0; j < children.length; j++) {
        const grandChildren = [];
        grandChildren.push(
          ...transform(
            sortBy(
              filter(list, (o) => "" + o.parent_id === children[j].key),
              (o) => o.sort_order
            ),
            (result, o) => {
              result.push({
                title: get(o, "locale." + locale + ".name", o.name),
                key: "" + o.id,
              });
            },
            []
          )
        );
        children[j].children = grandChildren;
      }
      treeDatas[i].children = children;
    }
  }
  // console.log(list);
  return (
    <Box width={[1]}>
      <Box pb={1}>
        <Text>Filters</Text>
      </Box>
      <Tree
        treeData={treeDatas}
        checkedKeys={contentList.value}
        checkable={true}
        onCheck={(checkedKeys) => {
          // console.log(checkedKeys);
          onSetItemValue("value", checkedKeys);
        }}
      />
    </Box>
  );
};
