import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import { red } from "@material-ui/core/colors"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Text } from "rebass"

const useStyles = makeStyles(theme => ({
  card: {
    width: "100%",
  },
  content: { overflowY: "scroll", height: 300 },
  contentDefault: { overflowY: "scroll" },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}))

export const CardCollapse = props => {
  const { children, title, subheader, isDefault, subtitle } = props
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)
  const contentClass = isDefault ? classes.contentDefault : classes.content
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
        title={
          <Text fontSize={"0.85rem"} fontWeight={"bold"}>
            {title}
          </Text>
        }
        subheader={
          subtitle ? (
            <Text fontSize={"0.85rem"}>{subtitle}</Text>
          ) : (
            <Text fontSize={"0.85rem"}>{subheader}</Text>
          )
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={contentClass}>{children}</CardContent>
      </Collapse>
    </Card>
  )
}
