import React from "react"
import { BetterCustomSimpleForm, EditToolbar } from "../../../components"
import FormFields from "./FormFields"

export const CreateForm = (props) => {
  return (
    <BetterCustomSimpleForm
      {...props}
      title={"resources.sources.new_title"}
      toolbar={<EditToolbar />}
      isShowBack={false}
    >
      <FormFields {...props} isCreate={true} />
    </BetterCustomSimpleForm>
  )
}
