import React, { Component } from "react";
import PropTypes from "prop-types";
// import DestopIcon from 'material-ui/svg-icons/hardware/desktop-mac';
// import MobileIcon from 'material-ui/svg-icons/communication/stay-primary-portrait';
import Dropzone from "react-dropzone";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { CircularProgress, Snackbar, IconButton } from "@material-ui/core";
import { pink, red } from "@material-ui/core/colors";
import { Text } from "rebass";
import { UploadClient } from "../../../restClient";
import {
  DivContainer,
  SpanImage,
  BoxImageView,
  InsideBox,
  Image,
  BoxUpload,
} from "./styled";
import {
  URL_UPLOAD,
  ERROR_MESSAGE_FAILED,
  ERROR_MESSAGE_MAX_SIZE,
} from "./constants";

class UploadItemArray extends Component {
  constructor(props) {
    super(props);
    const { imageUrl } = props;
    this.state = {
      isEditMode: false,
      imageUrl: imageUrl,
      isLoading: false,
      openSnackBar: false,
      message: "Please try again",
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { imageUrl } = nextProps;
    if (imageUrl !== this.props.imageUrl) {
      this.setState({ imageUrl: imageUrl });
    }
  }
  onDropDesktop = (accepted, rejected) => {
    const paramsCaption = this.props.type + "-image-desktop";
    if (accepted.length > 0 && rejected.length === 0) {
      this.setState({
        isLoading: true,
      });
      let formData = new FormData();
      formData.append("caption", paramsCaption);
      accepted.forEach((file) => {
        formData.append("photos", file, file.name);
      });

      UploadClient(formData, URL_UPLOAD)
        .then((response) => response.json())
        .then((data) => {
          if (data.url) {
            this.setState({
              imageUrl: data.url,
              isLoading: false,
            });
            this.props.onUpdateUrl(data.url);
          } else {
            this.setState({
              isLoading: false,
              openSnackBar: true,
              message: ERROR_MESSAGE_FAILED,
            });
          }
        })
        .catch((e) => {
          this.setState({
            isLoading: false,
            openSnackBar: true,
            message: ERROR_MESSAGE_FAILED,
          });
        });
    } else {
      this.setState({
        openSnackBar: true,
        message: ERROR_MESSAGE_MAX_SIZE,
      });
    }
  };
  handleRequestClose = () => {
    this.setState({
      openSnackBar: false,
    });
  };
  onRemove = () => {
    this.setState({
      imageUrl: "",
    });
    this.props.onUpdateUrl("");
  };
  renderItem() {
    return (
      <div style={styles.gridImage}>
        <div style={styles.container}>
          {this.state.imageUrl !== "" && (
            // <Button
            //   variant="contained"
            //   style={styles.removeButton}
            //   icon={<RemoveCircle color={pink["A200"]} />}
            //   onClick={() => this.onRemove()}
            // />
            <IconButton
              style={styles.removeButton}
              onClick={() => this.onRemove()}
            >
              <RemoveCircle color={"inherit"} />
            </IconButton>
          )}
          <Image
            color='#FFFFFF'
            src={
              this.state.imageUrl ||
              "https://via.placeholder.com/400x400?text=Image"
            }
            style={{ backgroundColor: "#CFD8DC", width: 100, height: "auto" }}
          />
        </div>
      </div>
    );
  }
  render() {
    const { disableClick, maxSize, minSize, multiple, title } = this.props;
    return (
      <div>
        <Text fontSize={[0]} fontWeight={"bold"}>
          {title}
        </Text>
        <DivContainer>
          <Dropzone
            onDrop={this.onDropDesktop}
            accept='image/*'
            disableClick={disableClick}
            maxSize={maxSize}
            minSize={minSize}
            multiple={multiple}
            style={styles.boxImage}
          >
            {({ getRootProps, getInputProps }) => (
              <BoxUpload style={{ width: 150, height: 150 }}>
                <div {...getRootProps()} style={{ display: "flex" }}>
                  <input {...getInputProps()} />
                  {this.state.isLoading && (
                    <InsideBox>
                      <CircularProgress />
                    </InsideBox>
                  )}
                  {!this.state.isLoading && (
                    <InsideBox>
                      <SpanImage>Drop your Image file here</SpanImage>
                      <SpanImage>or Click here</SpanImage>
                    </InsideBox>
                  )}
                </div>
              </BoxUpload>
            )}
          </Dropzone>
          <BoxImageView>{this.renderItem()}</BoxImageView>
        </DivContainer>
        <Snackbar
          open={this.state.openSnackBar}
          message={this.state.message}
          autoHideDuration={4000}
          bodyStyle={{ backgroundColor: red[500] }}
          onRequestClose={this.handleRequestClose}
        />
      </div>
    );
  }
}
const styles = {
  insideBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "inherit",
  },
  boxImage: {
    width: 120,
    height: 120,
    borderWidth: 2,
    padding: "0 6px 0 6px",
    borderColor: "#666",
    borderStyle: "dashed",
    borderRadius: 5,
  },
  gridImage: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: 10,
    paddingBottom: 10,
  },
  image: {
    maxHeight: "15rem",
    margin: "0.2rem",
    maxWidth: "100%",
  },
  container: {
    display: "inline-block",
    position: "relative",
    padding: 5,
  },
  removeButton: {
    position: "absolute",
    top: "0.1rem",
    right: "0.1rem",
    minWidth: "2rem",
    opacity: 1,
    zIndex: 5,
    color: pink["A200"],
  },
};

UploadItemArray.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  disableClick: PropTypes.bool,
  onUpdateUrl: PropTypes.func,
  imageUrl: PropTypes.string,
};

UploadItemArray.defaultProps = {
  type: "default",
  multiple: false,
  maxSize: 1000000,
  title: "Image",
  imageUrl: "https://via.placeholder.com/400x400?text=Image",
};

export default UploadItemArray;
