import React, { useState } from "react"
import { Flex, Box } from "rebass"
import { isFinite } from "lodash"
import TextField from "@material-ui/core/TextField"
import {
  TypeGradient,
  AngleDegre,
  GradientSlider,
  BoxListColor,
  SelectColors,
} from "./components"

const GradientBackground = (props) => {
  const { gradient, setGradient } = props
  // const [gradient, setGradient] = useState(data)
  const [indexBG, setIndexBG] = useState(0)
  const onSetValue = (key, value) => {
    setGradient({
      ...gradient,
      [key]: value,
    })
  }

  const handleChangeLinear = (key, index) => (event) => {
    const value = event.target.value * 1
    if (isFinite(value) && value >= 0 && value <= 100) {
      let newLinear = gradient.linear
      newLinear[index] = event.target.value * 1

      const newGradiant = {
        ...gradient,
        [key]: newLinear,
      }
      setGradient(newGradiant)
    }
  }

  const handleChangeDegre = (key) => (event) => {
    const value = event.target.value * 1
    if (isFinite(value) && value >= 0 && value <= 360) {
      setGradient({
        ...gradient,
        [key]: value,
      })
    }
  }

  const { bg1, bg2, type, degre, linear } = gradient
  const mydegre = type === "radial-gradient" ? "circle" : `${degre}deg`
  const newBg = {
    background: `${type}(${mydegre}, ${bg1} ${linear[0]}%,${bg2} ${linear[1]}%)`,
  }
  return (
    <Flex width={[1]} flexDirection={"column"} p={[3]}>
      <Flex
        width={[1]}
        p={[2]}
        style={{
          height: 150,
          background: bg1,
          ...newBg,
        }}
      />
      <Flex width={[1]} p={[2]} px={[3]}>
        <GradientSlider gradient={gradient} onSetValue={onSetValue} />
      </Flex>
      <Flex width={[1]} flexDirection="row" p={[2]}>
        <SelectColors
          gradient={gradient}
          onSetValue={onSetValue}
          indexBG={indexBG}
        />
        <Box px={[3]}>
          <Flex flexDirection={["row"]}>
            <Box
              width={["80px"]}
              p={[2]}
              mx={[3]}
              mb={[3]}
              style={{
                backgroundColor: bg2,
                height: 60,
                cursor: "pointer",
                border: "2px solid #C4c4c4",
              }}
              onClick={() => setIndexBG(1)}
            />
            <Box px={[2]}>
              <TextField
                id="position-gradient-0"
                type="number"
                label="Position"
                value={linear[0]}
                placeholder="Position"
                onChange={handleChangeLinear("linear", 0)}
              />
            </Box>
          </Flex>
          <Flex flexDirection={["row"]}>
            <Box
              width={["80px"]}
              p={[2]}
              mx={[3]}
              style={{
                backgroundColor: bg1,
                height: 60,
                cursor: "pointer",
                border: "2px solid #C4c4c4",
              }}
              onClick={() => setIndexBG(0)}
            />
            <Box px={[2]}>
              <TextField
                id="position-gradient-1"
                type="number"
                label="Position"
                value={linear[1]}
                placeholder="Position"
                onChange={handleChangeLinear("linear", 1)}
              />
            </Box>
          </Flex>
        </Box>
        <Box px={[3]}>
          <TypeGradient onSetValue={onSetValue} type={type} />
          {type === "linear-gradient" && (
            <Flex flexDirection={["row"]} pt={[3]} alignItems="center">
              <AngleDegre degre={degre} onSetValue={onSetValue} />
              <Box pl={[3]}>
                <TextField
                  style={{ width: 80 }}
                  id="Degre-gradient"
                  type="number"
                  label="Degre"
                  value={degre}
                  placeholder="Degre"
                  onChange={handleChangeDegre("degre")}
                />
              </Box>
            </Flex>
          )}
        </Box>
        <Box px={[3]}>
          <BoxListColor setGradient={setGradient} />
        </Box>
      </Flex>
    </Flex>
  )
}

export default GradientBackground
