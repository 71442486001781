import React, { Fragment } from "react";
import { get } from "lodash";
import styled from "styled-components";
import { Image } from "antd";
import { Flex } from "rebass";
const MyDivUL = styled.div`
  a {
    color: ${(props) => (props.menuColor ? props.menuColor : "#000000")};
  }
  ul.nav:first-child li > ul:before {
    background-color: ${(props) =>
      props.colorPrimary ? props.colorPrimary : "#000000"};
  }
  ul.nav:first-child li > ul:after {
    background-color: ${(props) =>
      props.colorPrimary ? props.colorPrimary : "#000000"};
  }
  ul.nav:first-child > li:hover > a:not(:last-child):after {
    border-bottom: 15px solid
      ${(props) => (props.colorPrimary ? props.colorPrimary : "#000000")};
  }
`;

export default (props) => {
  const { settings, categories, isDarkBG, values, styleLogo } = props;

  const myMenuColor = get(values, "color", isDarkBG ? "#FFFFFF" : "#000000");
  const myBackgroundColor = isDarkBG ? "#000000" : "#FFFFFF";
  let listParentLeft = [];
  let listParentRight = [];

  const sizeParent = categories.length / 2;
  categories.map((item, index) => {
    if (index < sizeParent) {
      listParentLeft.push(item);
    } else {
      listParentRight.push(item);
    }
    return item;
  });
  return (
    <Fragment>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        style={{ backgroundColor: "transparent", height: "100%" }}
      >
        <MyDivUL
          colorPrimary={myBackgroundColor}
          menuColor={myMenuColor}
          backgroundColor={myBackgroundColor}
        >
          <ul className='nav'>
            {listParentLeft.map((il, index) => {
              return (
                <li key={index}>
                  <a href={"/"} style={{ color: myMenuColor }}>
                    {il.title}
                  </a>
                </li>
              );
            })}
            <li>
              <a href={"/"}>
                <Flex
                  flexDirection={"row"}
                  alignItems={"center"}
                  width={["60px", "140px"]}
                  style={{ height: 62 }}
                  px={[2]}
                >
                  <Image
                    style={styleLogo}
                    src={settings.header.image}
                    alt='logo'
                    preview={false}
                  />
                </Flex>
              </a>
            </li>
            {listParentRight.map((il, index) => {
              return (
                <li key={index}>
                  <a href={"/"} style={{ color: myMenuColor }}>
                    {il.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </MyDivUL>
      </Flex>
    </Fragment>
  );
};
