import React from "react"
import { get } from "lodash"
import { Flex } from "rebass"
import { SelectInput } from "react-admin"
import { RaNumberInput, RaBooleanInput } from "./"
import { dataPromotionTypeCart } from "../components"

const ActionList = props => {
  const { formData } = props
  const isShowBuyX = get(formData, "actions.promotion_type", "")

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <SelectInput
        source="actions.promotion_type"
        label={"resources.promotions.fields.promotion_type"}
        choices={dataPromotionTypeCart}
        style={{ width: 256 }}
      />
      {isShowBuyX === "Buy X Get Y" && (
        <RaNumberInput
          source={"actions.buy_x"}
          label={"resources.promotions.fields.buy_x"}
          notetext={"resources.promotions.note_text.buy_x"}
        />
      )}
      <RaNumberInput
        source={"actions.get_y"}
        label={"resources.promotions.fields.get_y"}
        notetext={"resources.promotions.note_text.get_y"}
      />
      <RaNumberInput
        source={"actions.max_free_qty"}
        label={"resources.promotions.fields.max_qty_y"}
        notetext={"resources.promotions.note_text.max_qty_y"}
      />
      <RaBooleanInput
        source={"actions.apply_other_promotions"}
        label={"resources.promotions.fields.apply_lower_priority"}
      />
    </Flex>
  )
}

export default ActionList
