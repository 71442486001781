import React from "react"
import { Box, Flex } from "rebass"
import ContainerDimensions from "react-container-dimensions"
import CircularProgress from "@material-ui/core/CircularProgress"
import { ListNewMan } from "../../../../layout/dnd/components/listside"
import { SelectInput } from "./utils"
class ListProduct extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collection_id: "",
    }
  }

  onChangeCollection = event => {
    this.setState({
      collection_id: event.target.value,
    })
    this.props.onChangeCollection(event)
  }
  render() {
    const { collection_id } = this.state
    const { title, setting, dataItems, productsContent, isLoading } = this.props
    return (
      <Box width={[1]}>
        <Box width={[1]}>
          <SelectInput
            id={`tooltip-position-collection-list`}
            title={"Select Collection"}
            value={collection_id}
            list={dataItems}
            onChange={this.onChangeCollection}
          />
        </Box>
        <ContainerDimensions>
          {({ width }) => {
            return (
              <Box width={[width]}>
                {width > 0 && (
                  <div style={{ backgroundColor: "transparent" }}>
                    <ListNewMan
                      newMan={productsContent}
                      title={title}
                      newSetting={setting}
                      screenWidth={width > 1280 ? 1280 : width}
                    />
                  </div>
                )}
              </Box>
            )
          }}
        </ContainerDimensions>
        {isLoading && (
          <Flex
            flexDirection={"row"}
            width={[1]}
            justifyContent={"center"}
            p={[3]}
          >
            <CircularProgress color="primary" />
          </Flex>
        )}
      </Box>
    )
  }
}

export default ListProduct
