import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout23 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const SubcribeView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='SubcribeView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

SubcribeView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};
//type layout combine , split
SubcribeView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "SubcribeView",
    placeholder: "enter your email address",
    labelButton: "Subscribe",
    urlSubcribe: "",
    desktop: {
      show: true,
      colorProps: {
        backgroundColor: "rgba(255,255,255,0)",
        color: "rgba(0,0,0,1)",
        buttonBackgroundColor: "rgba(255,255,255,0)",
        buttonColor: "rgba(0,0,0,1)",
      },
      button: {
        icon: "",
        iconPosition: "",
        fontSize: 10,
        borderRadius: 0,
      },
      layout: {
        type: "combine",
        direction: "row",
        justify: "center",
        align: "center",
      },
      inputFontSize: 10,
      borderRadius: 0,
      containerPadding: "0px",
    },
  },
  layout: Layout23,
};
export default SubcribeView;
