import React from "react";
import { FontelloSvg } from "../../../libs/icons";
import { connect } from "../../../core";
import { HrefContainer } from "../../../libs/hrefUrl";
const FAButtonView = (props) => {
  const { id, layoutState } = props;
  /**tooltipPosition,
    tooltip, */
  const {
    buttonColors,
    mini,
    icon,
    link,
    buttonAlign,
    disabled,
    containerPadding,
  } = layoutState.getItemJS(id).props.values;
  const Size = mini ? "24px" : "24px";
  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "auto",
        display: "flex",
        width: "100%",
        justifyContent: buttonAlign,
        flex: 1,
        padding: containerPadding,
      }}
    >
      <div
        style={{
          borderRadius: "50%",
          padding: 10,
          backgroundColor: buttonColors.backgroundColor,
          boxShadow: `rgba(0,0,0,0.5) 0px 0px 5px 1px`,
        }}
      >
        {disabled ? (
          <FontelloSvg fill={buttonColors.color} name={icon} size={Size} />
        ) : (
          <HrefContainer link={link}>
            <FontelloSvg fill={buttonColors.color} name={icon} size={Size} />
          </HrefContainer>
        )}
      </div>
    </div>
  );
};

export default connect("layoutState")(FAButtonView);
