import React from "react"
import styled from "styled-components"
import { has, get } from "lodash"
import { Flex, Box } from "rebass"
import { getPhoto } from "./utils"
import { Link } from "../../layout/config"
import { Discount } from "./Discount"
import Currency from "./Currency"

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0.6em 0.6em 0 0;
`
const BoxOverlay = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  right: 20px;
  margin-top: -20px;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: inherit;
  border: 1px solid;
  background: #fff;
  border-color: rgba(0, 0, 0, 0.08);
  border-radius: 0.6em;
`

const ProductInfo = styled.div`
  padding: 0.4em;
  padding-top: 8px;
  min-height: 3rem;
`

const ProductImage = styled.div`
  display: flex;
  flex;1;
  align-items:center;
  justify-content:center;
`

export const ProductCard = ({ product, width, locale, showPrice }) => {
  if (!has(product, "id")) {
    return <div />
  }
  const name = get(product, `locale[${locale}].name`, product.name)
  const discountedPrice =
    product.discount < 1
      ? product.price * (1 - product.discount)
      : product.price - product.discount

  const discountText = product.discount < 1 ? product.discount * 100 : 0
  const src = product && product.photos ? getPhoto(product.photos) : ""
  return (
    <Flex
      style={{ width: width, paddingBottom: 8, height: "inherit" }}
      flexDirection={"column"}
      alignItems={"stretch"}
    >
      <Container>
        <Link to={`/products/${product.id}`}>
          <ProductImage style={{ height: width }}>
            <Image src={src} borderRadius="0.6em 0.6em 0 0" />
          </ProductImage>
          {discountText > 0 && (
            <BoxOverlay style={{ width: width }}>
              <div style={{ paddingRight: 8 }}>
                <Discount size="40px" fontSize="1em" bg="pink" color="white">
                  {discountText}%
                </Discount>
              </div>
            </BoxOverlay>
          )}
          <ProductInfo style={discountText === 0 ? { marginTop: 20 } : {}}>
            <div> {name}</div>

            <Box
              pt={[1]}
              pb={[1]}
              style={
                product.discount > 0
                  ? {
                      textDecorationLine: "line-through",
                      textDecorationStyle: "solid",
                    }
                  : {}
              }
            >
              {product.discount > 0 && showPrice ? (
                <Currency value={product.price} />
              ) : (
                <div> &nbsp;</div>
              )}
            </Box>
            <Currency value={discountedPrice} />
          </ProductInfo>
        </Link>
      </Container>
    </Flex>
  )
}
