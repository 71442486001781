import React, { useState } from "react";
import { Flex } from "rebass";
import { BoxContent } from "../styled";
import { FooterAction, PanelCard, ContainerPanel } from "../actions";
import { get } from "lodash";
import DialogList from "./DialogList";
import SocialContent from "./SocialContent";

const defaultSocial = {
  desktop: {
    show: true,
    containerPadding: "0px",
    size: 80,
    align: "center",
  },
  mobile: {
    show: false,
    containerPadding: "0px",
    size: 80,
    align: "center",
  },
};

const SocialMediaView = (props) => {
  const { list, desktop, mobile } = props.data.values;
  const [state, setState] = useState({
    list: list,
    desktop: desktop || defaultSocial.desktop,
    mobile: mobile || defaultSocial.mobile,
  });

  const onSetValue = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const onSetDesktopValue = (k, v) => {
    const params = {
      ...state.desktop,
      [k]: v,
    };
    onSetValue("desktop", params);
  };

  const onSetMobileValue = (k, v) => {
    const params = {
      ...state.mobile,
      [k]: v,
    };
    onSetValue("mobile", params);
  };

  const onClickSave = () => {
    let newData = props.data;
    newData.values.list = state.list;
    newData.values.desktop = get(state, "desktop.show", false)
      ? state.desktop
      : { show: false };
    newData.values.mobile = get(state, "mobile.show", false)
      ? state.mobile
      : { show: false };
    props.onUpdateData(newData);
  };

  const onClickCopy = () => {
    let newData = props.data;
    newData.values.list = state.list;
    newData.values.desktop = state.desktop;
    newData.values.mobile = state.mobile;
    localStorage.setItem("SocialMediaView", JSON.stringify(newData.values));
  };
  const onClickPaste = () => {
    const socialMedia = JSON.parse(localStorage.getItem("SocialMediaView"));
    if (socialMedia) {
      const { desktop, mobile, list } = socialMedia;
      setState({
        list: list,
        mobile: mobile,
        desktop: desktop,
      });
    }
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <BoxContent style={{ height: props.heightContent - 120 }}>
        <ContainerPanel>
          <PanelCard title='Desktop Setting' id='social-media-content-desktop'>
            <SocialContent
              state={state.desktop}
              onSetValue={(k, v) => onSetDesktopValue(k, v)}
              contentId={"desktop"}
            />
          </PanelCard>
          <PanelCard title='Mobile Setting' id='social-media-content-mobile'>
            <SocialContent
              state={state.mobile}
              onSetValue={(k, v) => onSetMobileValue(k, v)}
              contentId={"mobile"}
            />
          </PanelCard>
          <DialogList list={state.list} onSetValue={onSetValue} />
        </ContainerPanel>
      </BoxContent>
      <FooterAction
        onClickSave={onClickSave}
        onClickCopy={onClickCopy}
        onClickPaste={onClickPaste}
        widthContent={props.widthContent}
      />
    </Flex>
  );
};

export default SocialMediaView;
