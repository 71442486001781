import React from "react"
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd"
import { connect } from "../../core"
import Media from "./Media"
import { CarouselList } from "./components"
import { target } from "./utils"
// const target = {
//   drop(props, monitor, component) {
//     if (!monitor.didDrop()) {
//       props.layoutState.insertOrMoveItem(
//         props.id,
//         props.children.length,
//         monitor.getItem()
//       )
//     }
//   },
// }
const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
}) => {
  const {
    imageUrl,
    altText,
    auto,
    interval,
    direction,
  } = layoutState.getItemJS(id).props.values
  let srcMobile = []
  let srcDesktop = []
  if (imageUrl) {
    srcMobile =
      imageUrl.urlMobile.length > 0 ? imageUrl.urlMobile : imageUrl.url
    if (srcMobile.length === 0) {
      srcMobile = ["https://via.placeholder.com/960x400?text=960x400=CMS"]
    }
    srcDesktop = imageUrl.url.length > 0 ? imageUrl.url : imageUrl.urlMobile
    if (srcDesktop.length === 0) {
      srcDesktop = ["https://via.placeholder.com/960x400?text=960x400=CMS"]
    }
  }
  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "auto",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        flex: 1,
      }}
    >
      <Media query="(max-width: 768px)">
        {matches =>
          matches ? (
            <CarouselList
              photos={srcMobile}
              alt={altText}
              auto={auto}
              interval={interval}
              direction={direction}
            />
          ) : (
            <CarouselList
              photos={srcDesktop}
              alt={altText}
              auto={auto}
              interval={interval}
              direction={direction}
            />
          )
        }
      </Media>
    </div>
  )
}

const LayoutContainer = DropTarget("CarouselView", target, (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(EditLayout)

export default connect("layoutState", "readOnly")(LayoutContainer)
