import React from "react"
import {
  required,
  BooleanInput,
  TextInput,
  FormDataConsumer,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin"
import { Box } from "rebass"

const MainFields = (props) => {
  const { formData } = props
  return (
    <Box width={[1]}>
      <TextInput
        source="name"
        validate={required()}
        style={{ width: 300 }}
        label={"resources.sourceGroups.fields.name"}
      />
      <BooleanInput
        source="active"
        label="resources.sourceGroups.fields.active"
      />

      <ReferenceArrayInput
        source="sources"
        resource="sources"
        reference="sources"
        label="resources.sourceGroups.fields.sources"
        allowEmpty
        filter={{ site_id: formData.site_id }}
        fullWidth
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </Box>
  )
}

const FormFields = (props) => {
  const { hasList, hasCreate, ...rest } = props
  return (
    <Box width={[1]}>
      <FormDataConsumer>
        {({ formData }) => {
          return <MainFields {...rest} formData={formData} />
        }}
      </FormDataConsumer>
    </Box>
  )
}

export default FormFields
