import React, { useState } from "react"
import { Flex } from "rebass"
import { filter, get } from "lodash"
import AutoCompleteProduct from "./AutoCompleteProduct"
import ActionDelete from "@material-ui/icons/Delete"
import { IconButton } from "@material-ui/core"
import { getPhoto } from "./utils"
import { ProductGroupCard } from "../../../components"
export const ProductList = (props) => {
  const { state, onSetValue } = props

  const [value, setValue] = useState("")
  const [suggestions, setSuggestions] = useState([])

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }
  const getItem = (product) => {
    let products = get(state, "products", [])
    const isAllow = filter(products, function (o) {
      return o.id === product.id
    })
    // console.log(isAllow.length)
    if (isAllow.length === 0) {
      const params = {
        id: product.id,
        name: product.name,
        photos: getPhoto(product.photos),
        slug: product.slug,
        price: product.price,
        discount: product.discount,
        unit_price: product.unit_price,
        site_id: product.site_id,
      }
      products.push(params)
      onSetValue("products", products)
    }
  }
  const onDelete = (product) => {
    let products = get(state, "products", [])
    const newProduct = filter(products, function (o) {
      return o.id !== product.id
    })
    onSetValue("products", newProduct)
  }
  // console.log(state.products)
  return (
    <Flex width={[1]} flexDirection="column">
      <AutoCompleteProduct
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        value={value}
        setSuggestions={setSuggestions}
        suggestions={suggestions}
        setValue={setValue}
        getItem={getItem}
      />
      <Flex width={[1]}>
        {state.products &&
          state.products.length > 0 &&
          state.products.map((item, index) => {
            return (
              <Flex
                key={index}
                p={[2]}
                width={["200px"]}
                flexDirection={"column"}
                justifyContent={"space-between"}
              >
                <Flex
                  width={[1]}
                  justifyContent={"flex-end"}
                  flexDirection={"row"}
                >
                  <IconButton
                    onClick={() => onDelete(item)}
                    style={{
                      color: "#FF0000",
                      position: "fixed",
                      zIndex: 20,
                    }}
                  >
                    <ActionDelete color={"inherit"} />
                  </IconButton>
                </Flex>
                <ProductGroupCard product={item} />
              </Flex>
            )
          })}
      </Flex>
    </Flex>
  )
}
