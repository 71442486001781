import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { get } from "lodash";
import { assignIn } from "lodash";
import { FooterAction, ContainerPanel, PanelCard } from "../actions";
import { BoxContent } from "../styled";
import ItemContents from "./ItemContents";
import { RowDesktop } from "./desktop";
import { RowMobile } from "./mobile";
// const defaultMobileRow = {
//   padding: "0px",
//   backgroundColor: "rgba(255,255,255,0)",
//   isBackgroundImage: false,
//   bgProps: {
//     size: "cover",
//     position: "center",
//     repeat: "no-repeat",
//     ratio: 1.2,
//   },
// };

const defaultRowContent = {
  desktop: {
    column: 1,
    show: true,
    padding: "0px",
    backgroundColor: "rgba(255,255,255,0)",
    width: "100%",
    height: "100%",
    layout: {
      align: "top",
      gutter: 0,
      justify: "start",
      wrap: false,
    },
    bgProps: {
      show: false,
      content: {
        url: "",
        size: "cover",
        position: "center",
        repeat: "no-repeat",
        ratio: 1.2,
      },
    },
    gradient: {
      show: false,
      content: {
        bg1: "rgb(34,193,195)",
        bg2: "rgba(253,187,45,1)",
        type: "linear-gradient",
        degre: 160,
        linear: [0, 100],
      },
    },
  },
  mobile: {
    column: 1,
    show: true,
    padding: "0px",
    backgroundColor: "rgba(255,255,255,0)",
    width: "100%",
    height: "100%",
    layout: {
      align: "top",
      gutter: 0,
      justify: "start",
      wrap: false,
    },
    bgProps: {
      show: false,
      content: {
        url: "",
        size: "cover",
        position: "center",
        repeat: "no-repeat",
        ratio: 1.2,
      },
    },
    gradient: {
      show: false,
      content: {
        bg1: "rgb(34,193,195)",
        bg2: "rgba(253,187,45,1)",
        type: "linear-gradient",
        degre: 160,
        linear: [0, 100],
      },
    },
  },
};

const Row = (props) => {
  const { desktop, mobile } = props.data.values;
  const [state, setState] = useState({
    content: props.data.content,
    desktop: {
      column: get(desktop, "column", 1),
      show: get(desktop, "show", true),
      padding: get(desktop, "padding", "0px"),
      backgroundColor: get(desktop, "backgroundColor", "transparent"),
      width: get(desktop, "width", "100%"),
      maxWidth: get(desktop, "maxWidth", "100%"),
      height: get(desktop, "height", "100%"),
      layout: get(desktop, "layout", {
        align: "middle",
        gutter: 0,
        justify: "center",
        wrap: false,
      }),
      bgProps: get(desktop, "bgProps.show", false)
        ? get(desktop, "bgProps", defaultRowContent.desktop.bgProps)
        : defaultRowContent.desktop.bgProps,
      gradient: get(desktop, "gradient.show", false)
        ? get(desktop, "gradient", defaultRowContent.desktop.gradient)
        : defaultRowContent.desktop.gradient,
    },
    mobile: {
      column: get(mobile, "column", 1),
      show: get(mobile, "show", true),
      padding: get(mobile, "padding", "0px"),
      backgroundColor: get(mobile, "backgroundColor", "transparent"),
      width: get(mobile, "width", "100%"),
      maxWidth: get(mobile, "maxWidth", "100%"),
      height: get(mobile, "height", "100%"),
      layout: get(mobile, "layout", {
        align: "middle",
        gutter: 0,
        justify: "center",
        wrap: false,
      }),
      bgProps: get(mobile, "bgProps.show", false)
        ? get(mobile, "bgProps", defaultRowContent.mobile.bgProps)
        : defaultRowContent.mobile.bgProps,
      gradient: get(mobile, "gradient.show", false)
        ? get(mobile, "gradient", defaultRowContent.mobile.gradient)
        : defaultRowContent.mobile.gradient,
    },
  });

  const onClickCopy = () => {
    let newData = props.data;
    if (state.desktop.show) {
      let newBgProps = { show: false };
      let newGradient = { show: false };
      if (get(state, "desktop.bgProps.show", false)) {
        newBgProps = { ...get(state, "desktop.bgProps", { show: false }) };
      }
      if (get(state, "desktop.gradient.show", false)) {
        newGradient = { ...get(state, "desktop.gradient", { show: false }) };
      }
      newData.values.desktop = {
        column: get(state, "desktop.column", 1),
        show: get(state, "desktop.show", true),
        padding: get(state, "desktop.padding", "0px"),
        backgroundColor: get(state, "desktop.backgroundColor", "transparent"),
        width: get(state, "desktop.width", "100%"),
        maxWidth: get(state, "desktop.maxWidth", "100%"),
        height: get(state, "desktop.height", "100%"),
        layout: get(state, "desktop.layout", {
          align: "middle",
          gutter: 0,
          justify: "center",
          wrap: false,
        }),
        bgProps: { ...newBgProps },
        gradient: { ...newGradient },
      };
    }

    if (state.mobile.show) {
      let newBgProps = { show: false };
      let newGradient = { show: false };
      if (get(state, "mobile.bgProps.show", false)) {
        newBgProps = { ...get(state, "mobile.bgProps", { show: false }) };
      }
      if (get(state, "mobile.gradient.show", false)) {
        newGradient = { ...get(state, "mobile.gradient", { show: false }) };
      }
      newData.values.mobile = {
        column: get(state, "mobile.column", 1),
        show: get(state, "mobile.show", true),
        padding: get(state, "mobile.padding", "0px"),
        backgroundColor: get(state, "mobile.backgroundColor", "transparent"),
        width: get(state, "mobile.width", "100%"),
        maxWidth: get(state, "mobile.maxWidth", "100%"),
        height: get(state, "mobile.height", "100%"),
        layout: get(state, "mobile.layout", {
          align: "middle",
          gutter: 0,
          justify: "center",
          wrap: false,
        }),
        bgProps: { ...newBgProps },
        gradient: { ...newGradient },
      };
    }
    newData.content = state.content;
    localStorage.setItem("Row", JSON.stringify(newData));
  };
  const onClickPaste = () => {
    const row = JSON.parse(localStorage.getItem("Row"));
    if (row && row !== null) {
      const { values, content } = row;
      const { desktop, mobile } = values;
      setState({
        content: content,
        desktop: {
          column: get(desktop, "column", 1),
          show: get(desktop, "show", true),
          padding: get(desktop, "padding", "0px"),
          backgroundColor: get(desktop, "backgroundColor", "transparent"),
          width: get(desktop, "width", "100%"),
          maxWidth: get(desktop, "maxWidth", "100%"),
          height: get(desktop, "height", "100%"),
          layout: get(desktop, "layout", {
            align: "middle",
            gutter: 0,
            justify: "center",
            wrap: false,
          }),
          bgProps: get(desktop, "bgProps.show", false)
            ? get(desktop, "bgProps", defaultRowContent.desktop.bgProps)
            : defaultRowContent.desktop.bgProps,
          gradient: get(desktop, "gradient.show", false)
            ? get(desktop, "gradient", defaultRowContent.desktop.gradient)
            : defaultRowContent.desktop.gradient,
        },
        mobile: {
          column: get(mobile, "column", 1),
          show: get(mobile, "show", true),
          padding: get(mobile, "padding", "0px"),
          backgroundColor: get(mobile, "backgroundColor", "transparent"),
          width: get(mobile, "width", "100%"),
          maxWidth: get(mobile, "maxWidth", "100%"),
          height: get(mobile, "height", "100%"),
          layout: get(mobile, "layout", {
            align: "middle",
            gutter: 0,
            justify: "center",
            wrap: false,
          }),
          bgProps: get(mobile, "bgProps.show", false)
            ? get(mobile, "bgProps", defaultRowContent.mobile.bgProps)
            : defaultRowContent.mobile.bgProps,
          gradient: get(mobile, "gradient.show", false)
            ? get(mobile, "gradient", defaultRowContent.mobile.gradient)
            : defaultRowContent.mobile.gradient,
        },
      });
    }
    //     if (row) {
    //       const { values, content } = row;
    //       const height = values.height
    //         ? values.height
    //         : {
    //             type: "%",
    //             isMobile: false,
    //             isDesktop: false,
    //             contentHeight: 100,
    //           };
    //       this.setState({
    //         type: values.width.type,
    //         contentWidth: values.width.contentWidth,
    //         backgroundColor: values.backgroundColor,
    //         containerPadding: values.padding,
    //         backgroundImage: values.backgroundImage,
    //         align: values.align,
    //         isBackgroundImage: values.isBackgroundImage,
    //         bgProps: values.bgProps,
    //         content: content,
    //         typeHeight: height.type,
    //         isMobileHeight: height.isMobile,
    //         isDesktopHeight: height.isDesktop,
    //         contentHeight: height.contentHeight,
    //         isGradient: values.isGradient,
    //         gradient: values.gradient,
    //         mobileColumn: values.mobileColumn,
    //         mobile: values.mobile,
    //       });
    //     }
  };
  const onClickSave = () => {
    let newData = props.data;
    let newBgProps1 = { show: false };
    let newGradient1 = { show: false };
    if (get(state, "desktop.bgProps.show", false)) {
      newBgProps1 = { ...get(state, "desktop.bgProps", { show: false }) };
    }
    if (get(state, "desktop.gradient.show", false)) {
      newGradient1 = { ...get(state, "desktop.gradient", { show: false }) };
    }
    newData.values.desktop = {
      column: get(state, "desktop.column", 1),
      show: get(state, "desktop.show", true),
      padding: get(state, "desktop.padding", "0px"),
      backgroundColor: get(state, "desktop.backgroundColor", "transparent"),
      width: get(state, "desktop.width", "100%"),
      maxWidth: get(state, "desktop.maxWidth", "100%"),
      height: get(state, "desktop.height", "100%"),
      layout: get(state, "desktop.layout", {
        align: "middle",
        gutter: 0,
        justify: "center",
        wrap: false,
      }),
      bgProps: { ...newBgProps1 },
      gradient: { ...newGradient1 },
    };
    // if (state.desktop.show) {
    //   let newBgProps = { show: false };
    //   let newGradient = { show: false };
    //   if (get(state, "desktop.bgProps.show", false)) {
    //     newBgProps = { ...get(state, "desktop.bgProps", { show: false }) };
    //   }
    //   if (get(state, "desktop.gradient.show", false)) {
    //     newGradient = { ...get(state, "desktop.gradient", { show: false }) };
    //   }
    //   newData.values.desktop = {
    //     column: get(state, "desktop.column", 1),
    //     show: get(state, "desktop.show", true),
    //     padding: get(state, "desktop.padding", "0px"),
    //     backgroundColor: get(state, "desktop.backgroundColor", "transparent"),
    //     width: get(state, "desktop.width", "100%"),
    //     height: get(state, "desktop.height", "100%"),
    //     layout: get(state, "desktop.layout", {
    //       align: "middle",
    //       gutter: 0,
    //       justify: "center",
    //       wrap: false,
    //     }),
    //     bgProps: { ...newBgProps },
    //     gradient: { ...newGradient },
    //   };
    // }

    if (state.mobile.show) {
      let newBgProps = { show: false };
      let newGradient = { show: false };
      if (get(state, "mobile.bgProps.show", false)) {
        newBgProps = { ...get(state, "mobile.bgProps", { show: false }) };
      }
      if (get(state, "mobile.gradient.show", false)) {
        newGradient = { ...get(state, "mobile.gradient", { show: false }) };
      }
      newData.values.mobile = {
        column: get(state, "mobile.column", 1),
        show: get(state, "mobile.show", true),
        padding: get(state, "mobile.padding", "0px"),
        backgroundColor: get(state, "mobile.backgroundColor", "transparent"),
        width: get(state, "mobile.width", "100%"),
        maxWidth: get(state, "mobile.maxWidth", "100%"),
        height: get(state, "mobile.height", "100%"),
        layout: get(state, "mobile.layout", {
          align: "middle",
          gutter: 0,
          justify: "center",
          wrap: false,
        }),
        bgProps: { ...newBgProps },
        gradient: { ...newGradient },
      };
    } else {
      newData.values.mobile = {
        show: false,
      };
    }
    newData.content = state.content;
    props.onUpdateData(newData);
  };

  const onUpdateItemContent = (values, index) => {
    const { content } = state;
    //     // const {backgroundColor, backgroundImage, isBackgroundImage, bgProps} = values;
    content[index].props.values = assignIn(content[index].props.values, values);
    setState({
      ...state,
      content: content,
    });
  };

  const onSetValue = (k, v) => {
    setState({
      ...state,
      [k]: v,
    });
  };
  const onSetDesktopValue = (k, v) => {
    const newDesktop = {
      ...state.desktop,
      [k]: v,
    };
    console.log(k, v);
    console.log("newDesktop", newDesktop);
    onSetValue("desktop", newDesktop);
  };

  const onSetMobileValue = (k, v) => {
    const newMobile = {
      ...state.mobile,
      [k]: v,
    };
    onSetValue("mobile", newMobile);
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <BoxContent style={{ height: props.heightContent - 120 }}>
        <ContainerPanel>
          <PanelCard title={`Desktop Setting`} id={`desktop-row-setting`}>
            <RowDesktop
              state={state.desktop}
              onSetValue={(k, v) => onSetDesktopValue(k, v)}
            />
          </PanelCard>
        </ContainerPanel>
        <ContainerPanel>
          <PanelCard title={`Mobile Setting`} id={`mobile-row-setting`}>
            <RowMobile
              state={state.mobile}
              onSetValue={(k, v) => onSetMobileValue(k, v)}
            />
          </PanelCard>
        </ContainerPanel>

        <ContainerPanel>
          {state.content.map((item, index) => {
            return (
              <PanelCard
                key={index}
                title={`Properties of Content ${index + 1}`}
                id={`properties-content-${index + 1}`}
              >
                <ItemContents
                  itemContent={item}
                  indexContent={index + 1}
                  onUpdateItemContent={(values) =>
                    onUpdateItemContent(values, index)
                  }
                  title={`Content ${index + 1}`}
                />
              </PanelCard>
            );
          })}
        </ContainerPanel>
      </BoxContent>
      <FooterAction
        onClickSave={() => onClickSave()}
        onClickCopy={() => onClickCopy()}
        onClickPaste={() => onClickPaste()}
        widthContent={props.widthContent}
      />
    </Flex>
  );
};

// class Row extends Component {
//   constructor(props) {
//     super(props);
//     const {
//       backgroundColor,
//       padding,
//       width,
//       backgroundImage,
//       isBackgroundImage,
//       align,
//       bgProps,
//       height,
//       isGradient,
//       gradient,
//       mobileColumn,
//       mobile,
//     } = props.data.values;
//     // console.log(props.data.values)
//     let newHeight = height;
//     if (height === undefined) {
//       newHeight = {
//         type: "%",
//         isMobile: false,
//         isDesktop: false,
//         contentHeight: 100,
//       };
//     }
//     const bi = backgroundImage ? backgroundImage : { url: "", urlMobile: "" };
//     const alignContent = align ? align : "center";
//     const isBGI = isBackgroundImage ? isBackgroundImage : false;
//     const bgProperties = bgProps ? bgProps : bgPropertiesDefault;
//     this.state = {
//       heightContent: props.heightContent,
//       widthContent: props.widthContent,
//       content: props.data.content,
//       backgroundColor: backgroundColor,
//       containerPadding: padding,
//       contentWidth: get(width, "contentWidth", 100),
//       type: get(width, "type", "%"),
//       maxValueWidth: get(width, "type", "%") === "px" ? 2000 : 100,
//       interval: get(width, "type", "%") === "px" ? 20 : 1,
//       backgroundImage: bi,
//       align: alignContent,
//       isBackgroundImage: isBGI,
//       bgProps: bgProperties,
//       typeHeight: newHeight.type || "%",
//       isMobileHeight: newHeight.isMobile || false,
//       isDesktopHeight: newHeight.isDesktop || false,
//       contentHeight: newHeight.contentHeight,
//       maxValueHeight: newHeight.type === "px" ? 2000 : 100,
//       intervalHeight: 1,
//       isGradient: isGradient || false,
//       gradient: gradient || defaultGradient,
//       mobileColumn: mobileColumn || 1,
//       mobile: mobile || defaultMobileRow,
//     };
//   }

//   onClickCopy = () => {
//     let newData = this.props.data;
//     let width = {
//       type: this.state.type,
//       contentWidth: this.state.contentWidth,
//     };
//     let height = {
//       type: this.state.typeHeight,
//       isMobile: this.state.isMobileHeight,
//       isDesktop: this.state.isDesktopHeight,
//       contentHeight: this.state.contentHeight,
//     };
//     newData.values.backgroundColor = this.state.backgroundColor;
//     newData.values.padding = this.state.containerPadding;
//     newData.values.align = this.state.align;
//     newData.values.backgroundImage = this.state.backgroundImage;
//     newData.values.isBackgroundImage = this.state.isBackgroundImage;
//     newData.values.bgProps = this.state.bgProps;
//     newData.values.width = width;
//     newData.content = this.state.content;
//     newData.values.height = height;
//     newData.values.isGradient = this.state.isGradient;
//     newData.values.gradient = this.state.gradient;
//     newData.values.mobileColumn = this.state.mobileColumn;
//     newData.values.mobile = this.state.mobile;

//     localStorage.setItem("Row", JSON.stringify(newData));
//   };
//   onClickPaste = () => {
//     const row = JSON.parse(localStorage.getItem("Row"));
//     if (row) {
//       const { values, content } = row;
//       const height = values.height
//         ? values.height
//         : {
//             type: "%",
//             isMobile: false,
//             isDesktop: false,
//             contentHeight: 100,
//           };
//       this.setState({
//         type: values.width.type,
//         contentWidth: values.width.contentWidth,
//         backgroundColor: values.backgroundColor,
//         containerPadding: values.padding,
//         backgroundImage: values.backgroundImage,
//         align: values.align,
//         isBackgroundImage: values.isBackgroundImage,
//         bgProps: values.bgProps,
//         content: content,
//         typeHeight: height.type,
//         isMobileHeight: height.isMobile,
//         isDesktopHeight: height.isDesktop,
//         contentHeight: height.contentHeight,
//         isGradient: values.isGradient,
//         gradient: values.gradient,
//         mobileColumn: values.mobileColumn,
//         mobile: values.mobile,
//       });
//     }
//   };
//   onClickSave = () => {
//     let newData = this.props.data;
//     let width = {
//       type: this.state.type,
//       contentWidth: this.state.contentWidth,
//     };
//     let height = {
//       type: this.state.typeHeight,
//       isMobile: this.state.isMobileHeight,
//       isDesktop: this.state.isDesktopHeight,
//       contentHeight: this.state.contentHeight,
//     };
//     newData.values.backgroundColor = this.state.backgroundColor;
//     newData.values.padding = this.state.containerPadding;
//     newData.values.align = this.state.align;
//     newData.values.backgroundImage = this.state.backgroundImage;
//     newData.values.isBackgroundImage = this.state.isBackgroundImage;
//     newData.values.bgProps = this.state.bgProps;
//     newData.values.width = width;
//     newData.content = this.state.content;
//     newData.values.height = height;
//     newData.values.isGradient = this.state.isGradient;
//     newData.values.gradient = this.state.gradient;
//     newData.values.mobileColumn = this.state.mobileColumn;
//     newData.values.mobile = this.state.mobile;
//     this.props.onUpdateData(newData);
//   };

//   onChangeType = (event) => {
//     let width = 2000;
//     let content = this.state.contentWidth;
//     let interval = 20;
//     if (event.target.value === "%") {
//       width = 100;
//       content = 100;
//       interval = 1;
//     }
//     this.setState({
//       type: event.target.value,
//       maxValueWidth: width,
//       contentWidth: content,
//       interval: interval,
//     });
//   };
//   onChangeTypeHeight = (event) => {
//     // console.log(event.target.value)
//     let height = 2000;
//     let content = this.state.contentHeight;
//     let interval = 20;
//     if (event.target.value === "%") {
//       height = 100;
//       content = 100;
//       interval = 1;
//     }
//     this.setState({
//       typeHeight: event.target.value,
//       maxValueHeight: height,
//       contentHeight: content,
//       intervalHeight: interval,
//     });
//   };

//   updateBackground = (values) => {
//     const {
//       backgroundColor,
//       backgroundImage,
//       isBackgroundImage,
//       bgProps,
//     } = values;
//     this.setState({
//       backgroundColor,
//       backgroundImage,
//       isBackgroundImage,
//       bgProps,
//     });
//   };
//   updateBackgroundContent = (values, index) => {
//     const { content } = this.state;
//     // const {backgroundColor, backgroundImage, isBackgroundImage, bgProps} = values;
//     content[index].props.values = assignIn(content[index].props.values, values);
//     this.setState({
//       content: content,
//     });
//   };

//   onToggle = (e, v, type) => {
//     if (type === "mobile") {
//       this.setState({ isMobileHeight: e.target.checked });
//     } else if (type === "desktop") {
//       this.setState({ isDesktopHeight: e.target.checked });
//     }
//   };
//   onSetValue = (key, value) => {
//     this.setState({
//       ...this.state,
//       [key]: value,
//     });
//   };

//   render() {
//     return (
//       <Flex width={[1]} flexDirection={"column"}>
//         <BoxContent style={{ height: this.state.heightContent - 120 }}>
//           <ContainerPanel>
//             <RowFields
//               state={this.state}
//               onChangeType={this.onChangeType}
//               onSetValue={this.onSetValue}
//               onToggle={this.onToggle}
//               updateBackground={this.updateBackground}
//               onChangeTypeHeight={this.onChangeTypeHeight}
//             />
//             {this.state.content.map((item, index) => {
//               return (
//                 <PanelCard
//                   key={index}
//                   title={`Properties of Content ${index + 1}`}
//                   id={`properties-content-${index + 1}`}
//                 >
//                   <ItemBackgroundContent
//                     itemContent={item}
//                     indexContent={index + 1}
//                     defaultItemProps={defaultItemProps}
//                     updateBackground={(values) =>
//                       this.updateBackgroundContent(values, index)
//                     }
//                     title={`Content ${index + 1}`}
//                   />
//                 </PanelCard>
//               );
//             })}
//           </ContainerPanel>
//         </BoxContent>
//         <FooterAction
//           onClickSave={this.onClickSave}
//           onClickCopy={this.onClickCopy}
//           onClickPaste={this.onClickPaste}
//           widthContent={this.state.widthContent}
//         />
//       </Flex>
//     );
//   }
// }

Row.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

Row.defaultProps = {
  data: {},
};
export default Row;
