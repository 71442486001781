import React from "react";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { replace } from "lodash";
export function NumberFormatCustom(props) {
  const { inputRef, onChange, id, name, ...other } = props;
  const myPrefix = replace(id, `input_${name}-`, "");
  console.log(myPrefix);
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={","}
      decimalSeparator={"."}
      isNumericString
      prefix={`${myPrefix} `}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
export const dataReason = [
  { id: "Cancel", name: "Cancel" },
  { id: "Return", name: "Return" },
  { id: "Not Delivered", name: "Not Delivered" },
];

export const dataRefundMethod = [
  { id: "Payment Gateway", name: "Payment Gateway" },
  { id: "Bank Transfer", name: "Bank Transfer" },
  { id: "Cash", name: "Cash" },
];

export const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    paddingTop: 0,
  },
}));
