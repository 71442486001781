import React from "react"
import clsx from "clsx"
import Collapse from "@material-ui/core/Collapse"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Flex } from "rebass"
import { Title } from "../Text"

const useStyles = makeStyles(theme => ({
  expand: {
    padding: 0,
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}))

export const CollapseMui = props => {
  const { label, children } = props
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (
    <Box width={[1]}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        pb={[2]}
        pt={[2]}
      >
        <Box pr={[2]}>
          <Title fontWeight={"bold"} fontSize={12}>
            {label}
          </Title>
        </Box>
        <Box>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Show all"
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      </Flex>
      <Collapse in={expanded}>{children}</Collapse>
    </Box>
  )
}
