import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import {
  BlockCounter,
  ItemBgProps,
  SketchColorPicker,
  LayoutRowContent,
  BlockCounterWithType,
  SwitchInput,
  DialogGradient,
  Counter,
  Title,
} from "../../components";
import { ContentDetail } from "../../actions";

export default (props) => {
  const { state, onSetValue } = props;
  const onSetGradient = (k, v) => {
    const newGradient = {
      ...state.gradient,
      [k]: v,
    };
    onSetValue("gradient", newGradient);
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <ContentDetail>
        <SwitchInput
          id={`use-show-row-mobile`}
          title={"Show on Mobile"}
          checked={get(state, "show", false)}
          onChange={(e) => onSetValue("show", e.target.checked)}
        />
      </ContentDetail>
      <SketchColorPicker
        title={"Background Color"}
        onUpdateColor={(color) => onSetValue("backgroundColor", color)}
        color={state.backgroundColor}
      />
      <BlockCounter
        containerPadding={state.padding}
        onUpdatePadding={(value) => onSetValue("padding", value)}
        title={"Padding"}
      />

      <BlockCounterWithType
        name='width-row-content'
        value={state.width}
        onSetValue={(v) => onSetValue("width", v)}
        title={"Width"}
      />
      <BlockCounterWithType
        name='max-width-content'
        value={state.maxWidth}
        onSetValue={(v) => onSetValue("maxWidth", v)}
        title={"MaxWidth"}
      />
      <BlockCounterWithType
        name='height-row-content'
        value={state.height}
        onSetValue={(v) => onSetValue("height", v)}
        title={"Height"}
      />
      <ContentDetail>
        <Title title={"column"} />
        <Counter
          isDisabled={false}
          onSetValue={(v) => onSetValue("column", v)}
          value={state.column}
          interval={1}
          maxValue={24}
        />
      </ContentDetail>

      <LayoutRowContent
        state={state.layout}
        onSetValue={(v) => onSetValue("layout", v)}
        contentId={"bg-row-layout-mobile"}
      />
      <ContentDetail>
        <ItemBgProps
          state={state.bgProps}
          onSetValue={(v) => onSetValue("bgProps", v)}
          contentId={"bg-row-props-mobile"}
        />
      </ContentDetail>
      <ContentDetail>
        <SwitchInput
          id={`use-bg-gradient-body-row-mobile`}
          title={"Use Background Gradient"}
          checked={get(state, "gradient.show", false)}
          onChange={(e) => onSetGradient("show", e.target.checked)}
        />
        {get(state, "gradient.show", false) && (
          <DialogGradient
            onSetValue={(gradient) => onSetGradient("content", gradient)}
            gradient={get(state, "gradient.content", {
              bg1: "rgb(34,193,195)",
              bg2: "rgba(253,187,45,1)",
              type: "linear-gradient",
              degre: 160,
              linear: [0, 100],
            })}
          />
        )}
      </ContentDetail>
    </Flex>
  );
};
