import React from "react";
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd";
//find
import { get } from "lodash";
import Separator from "./Separator";
import { connect } from "../../core";
import { DivEmpty } from "./styled";
import "./index.css";
// import { data } from "../../addons/props/components/bgTheme"
import { target } from "./utils";

const defaultGradient = {
  bg1: "rgb(34,193,195)",
  bg2: "rgba(253,187,45,1)",
  type: "linear-gradient",
  degre: 160,
  linear: [0, 100],
};

/**const bgImage = {
    backgroundImage: `url(${checkImage(values.backgroundImage.url)})`,
    backgroundRepeat: "repeat",
    padding: "2px 2px 20px 2px",
  } */
// const target = {
//   drop(props, monitor, component) {
//     if (!monitor.didDrop()) {
//       props.layoutState.insertOrMoveItem(
//         props.id,
//         props.children.length,
//         monitor.getItem()
//       )
//     }
//   },
// }
// const checkImage = (id) => {
//   let image = ""
//   let result = find(data, function (item) {
//     return item.id === id
//   })
//   if (result) {
//     return result.bg
//   }

//   return image
// }
const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
  widthScreen,
  mon,
}) => {
  // console.log(mon.targetId)
  // const widthContent = widthScreen ? widthScreen - widthScreen / 4 : "100%"
  const { values } = layoutState.getItemJS(id).props;

  let parsedChildren = [];
  if (!readOnly && children.length) {
    for (let i = 0; i < children.length * 2 + 1; i++) {
      if (i % 2 === 0) {
        parsedChildren.push(
          <Separator
            key={`${id}-seperator-${i}`}
            onDrop={(item) => layoutState.insertOrMoveItem(id, i / 2, item)}
            direction={"horizontal"}
          />
        );
      } else {
        parsedChildren.push(children[(i - 1) / 2]);
      }
    }
  } else {
    parsedChildren = children;
  }
  const gradient =
    get(values, "gradient", defaultGradient) !== null
      ? get(values, "gradient", defaultGradient)
      : defaultGradient;
  const mydegre =
    gradient.type === "radial-gradient" ? "circle" : `${gradient.degre}deg`;
  const newBg = {
    background: `${gradient.type}(${mydegre}, ${gradient.bg1} ${gradient.linear[0]}%,${gradient.bg2} ${gradient.linear[1]}%)`,
  };
  const dataImg = get(values, "backgroundImage.url", "");
  const newGradient = values.isGradient
    ? { background: gradient.bg1, ...newBg }
    : dataImg !== ""
    ? { backgroundImage: `url(${dataImg})` }
    : { backgroundColor: values.backgroundColor };

  return connectDropTarget(
    <div
      className='fullheight'
      style={{
        ...newGradient,
        width: "100%",
      }}
    >
      {children.length < 1 && (
        <DivEmpty>No row yet. Drag a row from the right to here</DivEmpty>
      )}
      {parsedChildren}
    </div>
  );
};

const LayoutContainer = DropTarget("Body", target, (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true }),
  mon: monitor,
}))(EditLayout);

export default connect(
  "layoutState",
  "readOnly",
  "widthScreen"
)(LayoutContainer);
