import { Layout8 } from "../../TemplateImageSonak";
export const template8A = {
  GTfTssDb: {
    id: "GTfTssDb",
    type: "Row1",
    props: {
      values: {
        name: "Row1",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 20,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "10px 0px 10px 0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "1080px",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 20,
            justify: "center",
            contentAlign: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              parent: "Row",
              desktop: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                padding: "0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    childOf: "Row",
    layout: Layout8,
    children: ["GXTT8iHZ"],
    content: {
      J13YJumi: {
        id: "J13YJumi",
        type: "ListSlideView",
        props: {
          values: {
            type: "ListSlideView",
            title: "Shop by Sports",
            mobile: {
              show: true,
              header: {
                show: false,
                title: "Shop by Sports",
                fontSize: 14,
                showViewAll: false,
              },
              setting: {
                dots: false,
                rows: 0,
                speed: 200,
                arrows: true,
                autoplay: false,
                infinite: false,
                slidesPerRow: 0,
                slidesToShow: 2,
                autoplaySpeed: false,
                adaptiveHeight: true,
                slidesToScroll: 2,
              },
              listProps: {
                padding: "10px",
                fontSize: 14,
                urlViewAll: "",
                titlePadding: "8px",
                showViewAllBottom: true,
              },
              colorProps: {
                color: "rgba(0,0,0,1)",
                hoverColor: "rgba(0,0,0,1)",
                backgroundColor: "rgba(255,255,255,0)",
                hoverBackgroundColor: "rgba(255,255,255,0)",
              },
            },
            parent: "Content",
            desktop: {
              show: true,
              header: {
                show: true,
                title: "Shop by Sports",
                fontSize: 18,
                showViewAll: true,
              },
              setting: {
                dots: false,
                rows: 0,
                speed: 200,
                arrows: false,
                autoplay: false,
                infinite: false,
                slidesPerRow: 0,
                slidesToShow: 3,
                autoplaySpeed: false,
                adaptiveHeight: true,
                slidesToScroll: 3,
              },
              listProps: {
                padding: "10px",
                fontSize: 18,
                urlViewAll: "",
                titlePadding: "10px",
                showViewAllBottom: false,
              },
              colorProps: {
                color: "rgba(0,0,0,1)",
                hoverColor: "rgba(0,0,0,1)",
                backgroundColor: "rgba(255,255,255,0)",
                hoverBackgroundColor: "rgba(255,255,255,0)",
              },
            },
            modeType: "list",
            valueText: [
              {
                link: {
                  type: "URL",
                  value: "",
                  isLink: false,
                  target: "",
                },
                title: "VOLLEYBALL",
                text: {
                  fontSize: [10, 14],
                  textAlign: ["center", "center"],
                },
                imageUrl: {
                  url:
                    "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-615.jpeg",
                  urlMobile:
                    "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-615.jpeg",
                },
              },
              {
                link: {
                  type: "URL",
                  value: "",
                  isLink: false,
                  target: "",
                },
                title: "BASKETBALL",
                text: {
                  fontSize: [10, 14],
                  textAlign: ["center", "center"],
                },
                imageUrl: {
                  url:
                    "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-0KW.jpeg",
                  urlMobile:
                    "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-0KW.jpeg",
                },
              },
              {
                link: {
                  type: "URL",
                  value: "",
                  isLink: false,
                  target: "",
                },
                title: "BADMINTON",
                text: {
                  fontSize: [10, 14],
                  textAlign: ["center", "center"],
                },
                imageUrl: {
                  url:
                    "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-HEM.jpeg",
                  urlMobile:
                    "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-HEM.jpeg",
                },
              },
              {
                link: {
                  type: "URL",
                  value: "",
                  isLink: false,
                  target: "",
                },
                title: "SOCCER",
                text: {
                  fontSize: [10, 14],
                  textAlign: ["center", "center"],
                },
                imageUrl: {
                  url:
                    "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-8RY.jpeg",
                  urlMobile:
                    "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-8RY.jpeg",
                },
              },
              {
                link: {
                  type: "URL",
                  value: "",
                  isLink: false,
                  target: "",
                },
                title: "FITNESS",
                text: {
                  fontSize: [10, 14],
                  textAlign: ["center", "center"],
                },
                imageUrl: {
                  url:
                    "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-7D3.jpeg",
                  urlMobile:
                    "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-7D3.jpeg",
                },
              },
              {
                link: {
                  type: "URL",
                  value: "",
                  isLink: false,
                  target: "",
                },
                title: "TENIS",
                text: {
                  fontSize: [10, 14],
                  textAlign: ["center", "center"],
                },
                imageUrl: {
                  url:
                    "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-3LF.jpeg",
                  urlMobile:
                    "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/list-slide-image-desktop-3LF.jpeg",
                },
              },
            ],
            colorProps: {
              color: "rgba(0,0,0,1)",
              hoverColor: "rgba(255, 255, 255, 1)",
              viewallColor: "rgba(74, 74, 74, 1)",
              backgroundColor: "rgba(255, 255, 255, 1)",
              hoverBackgroundColor: "rgba(208, 2, 27, 1)",
            },
            urlViewAll: "https://asics.com",
          },
        },
        parent: {
          id: "GXTT8iHZ",
          idx: 0,
        },
        childOf: "Content",
        children: [],
      },
    },
  },
};
