import React, { Component } from "react"
import { Box } from "rebass"
import { Dialog, Button } from "@material-ui/core"
import { DialogTitle, DialogActions, DialogContent } from "../listslide/styles"
import GradientBackground from "./GradientBackground"

// const customContentStyle = {
//   width: "100%",
//   maxWidth: "none",
//   padding: 20,
// }

class DialogGradient extends Component {
  constructor(props) {
    super(props)

    this.state = {
      gradient: props.gradient,
      open: false,
    }
  }
  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }
  handleSave = () => {
    this.setState({ open: false })
    this.props.onSetValue(this.state.gradient)
  }
  onSetGradient = (gradient) => {
    this.setState({ gradient: gradient })
  }

  render() {
    const { open } = this.state
    return (
      <div>
        <Box p={[2]}>
          <Button
            variant="outlined"
            onClick={this.handleOpen}
            color={"primary"}
            style={{ textTransform: "capitalize" }}
          >
            Background Gradient
          </Button>
        </Box>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullScreen={true}
          scroll={"paper"}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Background Gradient
          </DialogTitle>
          <DialogContent dividers>
            <Box width={[1]}>
              <GradientBackground
                gradient={this.state.gradient}
                setGradient={(gradient) => this.onSetGradient(gradient)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default DialogGradient
