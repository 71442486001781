import { useState, useRef, useEffect, useCallback } from 'react';

export function useSafeSetState(initialState) {
  const [state, setState] = useState(initialState);

  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);
  const safeSetState = useCallback(args => mountedRef.current && setState(args), [
    mountedRef,
    setState,
  ]);

  return [state, safeSetState];
}
