import React from "react"
import { DragSource } from "react-dnd"

const source = {
  beginDrag(props, monitor, component) {
    // console.log(props)
    return {
      type: props.type,
      props: {
        values: props.defaultProps.values,
        content: props.defaultProps.content,
      },
      children: [],
      style: {},
      childOf: "Body",
    }
  },
}

const RowItem = ({ type, component, connectDragSource, defaultProps }) =>
  connectDragSource(
    <div style={{ backgroundColor: "#efefef", color: "#eee" }}>
      <div
        style={{
          backgroundColor: "transparent",
          color: "#eee",
          padding: 4,
        }}
      >
        {React.createElement(defaultProps.layout, defaultProps)}
      </div>
    </div>
  )

export default DragSource("Body", source, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
}))(RowItem)
