import React from "react";
import { Box, Flex } from "rebass";
export const DEFAULT_SIDEBAR = 235;
export const DEFAULT_BG_SIDEBAR = "#424242";
export const HOVER_BG_MENU = "rgba(0, 175, 239,0.1)"; //"rgba(0, 175, 239, 0.2)"
export const ACTIVE_BG_MENU = "rgba(0, 175, 239, 0.4)";
export const BOX_SHADOW_COLOR = `rgba(66, 66, 66, 0.2) 0px 3px 3px -2px, rgba(66, 66, 66, 0.14) 0px 3px 4px 0px, rgba(66, 66, 66, 0.12) 0px 1px 8px 0px`;

const HeaderBox = (props) => (
  <Flex
    width={[1]}
    justifyContent={"flex-end"}
    flexDirection={"row"}
    px={[3]}
    py={[2]}
    bg={"white"}
    {...props}
  />
);

const ContentBox = (props) => (
  <Box width={[1]} p={[3]} bg={"white"} py={[4]} {...props} />
);
const ContentBoxCard = (props) => (
  <Box width={[1]} p={[1]} bg={"white"} {...props} />
);
const DividerBox = (props) => (
  <Box width={[1]} style={{ height: 24 }} {...props} />
);
export { HeaderBox, ContentBox, DividerBox, ContentBoxCard };
