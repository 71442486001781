export default {
  promotions: {
    name: "Promotions",
    title: {
      new_product: "New Product Promotions",
      edit_product: "Edit Product Promotions",
      new_cart: "New Cart Promotions",
      edit_cart: "Edit Cart Promotions",
      new_promotion: "New Promotion",
      edit_promotion: "Edit Promotion",
    },
    tabs: {
      product_rules: "Product Rules",
      cart_rules: "Cart Rules",
    },
    panel: {
      criteria: "Criteria",
      action: "Action",
      coupon_settings: "Coupon Settings",
    },
    labels: {
      promotion_type: "Promotion Type",
      applies_to: "Applies To",
      requirements: "Requirements",
      coupon_settings: "Coupon Settings",
      customer_buys: "Customer buys",
      customer_gets: "Customer gets",
      select_applicable_products: "Select Applicable Products",
      at_discounted_value: "At A Discounted Value",
      select_applicable_categories: "Select Applicable Categories",
      of: "Of",
      variants_selected: "variants selected",
      edit_variants: "Edit variants",
      description_terms: "Description & Terms",
      customer_type: "Customer Type",
      promotion_rule: "Promotion Rule",
      additional_discount: "Additional Discount",
      ad_applies_to: "Applies To (must be subset of the above)",
    },
    fields: {
      website_id: "Website",
      name: "Name",
      site_id: "Site",
      active: "Active",
      start: "Start",
      end: "End",
      start_date: "Start Date",
      end_date: "End Date",
      criteria: "Criteria",
      criteria_type: "Criteria Type",
      coupon: "Coupon",
      warehouse: "Warehouse",
      collection: "Collection",
      collections: "Collections",
      apply_lower_priority: "Apply Other Promotions With Lower Priority",
      promotion_amount: "Promotion Amount",
      promotion_type: "Promotion Type",
      need_coupon: "Need Coupon Code To Use Promotion",
      priority: "Priority",
      buy_x: "Buy X",
      get_y: "Get Y",
      max_qty_y: "Maximum Quantity of Free Y",
      auto_generate: "Auto Generate",
      qty: "Quantity",
      coupon_prefix: "Coupon Prefix",
      coupon_suffix: "Coupon Suffix",
      coupon_code_length: "Coupon Code Length",
      coupon_code: "Coupon Code",
      limit_number_use: "Limit Number of Use",
      limit_per_user: "Limit Per User",
      limit_per_customer: "Limit Per Customer",
      subtotal: "Subtotal",
      weight: "Weight",
      payment_method: "Payment Method",
      postal_code: "Postal Code",
      shipping_region: "Shipping Region",
      shipping_state: "Shipping State",
      products: "Products",
      cart: "Cart",
      categories: "Categories",
      attribute: "Attribute",
      operator: "Operator",
      discount_type: "Discount Type",
      percent: "Percent",
      amount: "Amount",
      min_order_amount: "Minimum Order Amount",
      any_items_from: "Any items from",
      max_number_uses_per_order: "Maximum number of uses per order",
      type: "Type",
      effective_date: "Effective Date",
      available: "available",
      description: "Description",
      terms: "Terms",
      discount_type_buyx_gety: "At A Discounted Value",
      discounted_percentage: "Discounted Percentage",
      minimum_order_qty: "Minimum Order Quantity",
      price_per_product: "Price Per Product",
      product_based: "Product-based",
      cart_based: "Cart-based",
      min_order_amount_ad: "Minimum Order Amount $",
      additional_discount_amount: "Additional Discount Amount",
      images: "Images",
      period: "Period",
    },
    note_text: {
      priority:
        "Which promotion to apply when there are other promotions match the same products. Higher number has higher priority",
      percent: "Enter 20 for 20% for Percent Type or 20 for $20 for Amount",
      amount: "Enter 20 for 20% for Percent Type or 20 for $20 for Amount",
      get_y:
        "Enter 20 for 20% for Percent or 20 for $20 for Amount, 1 for 1 free quantity for Buy X Get Y",
      buy_x:
        "Discount quantity step. For example, enter 3 for Buy 3 Get Y also means Buy 6 Get 2xY",
      max_qty_y: "For example, enter 5 means maximum of 5xY can be given",
      limit_number_use: "Maximum number of redemption. Put 0 if no limit",
      limit_per_user: "Put 0 if no limit",
      limit_per_customer: "Put 0 if no limit",
      promotion_amount:
        "Enter 20 for 20% for Percent Type or 20 for $20 for Amount",
      min_order_amount: "Minimum order amount must be more than 0",
      website_id_not_found:
        "Please select Website to select specific categories",
      minimum_order_qty: "Minimum order quantity must be more than 0",
      blank: "",
      additional_discount_amount:
        "Enter 20 for 20% for Percent Type or 20 for $20 for Amount",
    },
  },
};
