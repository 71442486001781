//
import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout22 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const MultiContentGroupView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='MultiContentGroupView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

MultiContentGroupView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};

MultiContentGroupView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "MultiContentGroupView",
    containerPadding: "0px",
    layoutRow: {
      flexDirection: ["column", "row"],
      justifyContent: ["flex-start", "flex-start"],
      alignItems: ["flex-start", "flex-start"],
      flexWrap: ["nowrap", "nowrap"],
      column: [1, 2],
      gutter: [8, 8],
    },
    border: {
      type: ["solid", "solid"],
      color: ["#e61919", "#e61919"],
      radius: ["0px", "0px"],
      size: [0, 0],
      backgroundColor: ["#FFFFFF", "#FFFFFF"],
    },
    mobile: {
      containerPadding: "0px",
    },
    list: [],
  },
  layout: Layout22,
};
export default MultiContentGroupView;
