import React from "react";
import { get } from "lodash";

import { ButtonView } from "./FlatButtonUtils";
const defaultBorder = {
  type: "solid",
  size: 0,
  color: "#e61919",
};

/*
{
  
  desktop: {
    fullWidth: false,
    fontSize: 14,
    containerPadding: "0px",
    buttonPadding: "8px 12px 8px 12px",
    buttonAlign: "center",
    buttonColors: {
      color: "#FFF",
      backgroundColor: "#e61919",
      hoverColor: "#42d696",
      rippleColor: "#FF9900",
    },
  },
  mobile: {
    fullWidth: false,
    fontSize: 10,
    containerPadding: "0px",
    buttonPadding: "8px 12px 8px 12px",
    buttonAlign: "center",
    buttonColors: {
      color: "#FFF",
      backgroundColor: "#e61919",
      hoverColor: "#42d696",
      rippleColor: "#FF9900",
    },
  },
};
*/
export default (props) => {
  const { item, indexView } = props;
  //   const linkUrl = "";
  const {
    icon,
    text,
    // href,
    // isShowDesktop,
    // isShowMobile,
    labelPosition,
    typeButton,
    border,
    desktop,
    // mobile,
  } = item.content;

  const isBorder = border
    ? `${border.size}px ${border.type} ${border.color}`
    : `${defaultBorder.size}px ${defaultBorder.type} ${defaultBorder.color}`;
  const borderRadius = get(border, "radius", "0")
    ? `${get(border, "radius", 0)}px`
    : "0px";
  return (
    <div
      style={{
        padding: desktop.containerPadding,
        border: `${get(props, `border.size[${indexView}]`, 0)}px 
          ${get(props, `border.type[${indexView}]`)}
          ${get(props, `border.color[${indexView}]`)}
         `,
        backgroundColor: get(
          props,
          `border.backgroundColor[${indexView}]`,
          "transparent"
        ),
      }}
    >
      <ButtonView
        newChildIcon={icon}
        typeButton={typeButton}
        isBorder={isBorder}
        buttonColors={desktop.buttonColors}
        text={text}
        labelPosition={labelPosition}
        fullWidth={desktop.fullWidth}
        defaultBorderSize={defaultBorder.size}
        borderRadius={borderRadius}
        buttonPadding={desktop.buttonPadding}
      />
    </div>
  );
};
