import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  rootList: {
    display: "flex",
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 0,
  },
  drawerPaper: {
    zIndex: 1220,
    padding: 0,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 256,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export const useStylesEdit = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
    position: "fixed",
    zIndex: 10,
    backgroundColor: "#FFFFFF",
  },
  form: {
    paddingTop: 62,
    [theme.breakpoints.up("xs")]: {
      width: 600,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
}))
