import React, { useState, useEffect } from "react";
//, { PureComponent, useState }
import PropTypes from "prop-types";
import { filter, get } from "lodash";
import { IconButton } from "@material-ui/core";
import ActionClose from "@material-ui/icons/HighlightOff";
import ActionDelete from "@material-ui/icons/Delete";
import { connect } from "../utils";
// import Drawer from "@material-ui/core/Drawer";
import { Text, Flex } from "rebass";

import { DivHeaderActions, DivHeader } from "./styled";
import "./index.css";
const currentPlugin = 0;
const PropsEditor = (props) => {
  const { layoutState, addons } = props;
  const Plugin = addons[currentPlugin].Interface || (() => null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(layoutState.getSelectedItem());
  const handleClose = () => {
    setSelected(undefined);
    setOpen(false);
    layoutState.setSelectedItem(null);
  };
  useEffect(() => {
    if (layoutState.getSelectedItem()) {
      setOpen(true);
      setSelected(layoutState.getSelectedItem());
    }
  }, [layoutState]);
  const onDelete = () => {
    if (
      layoutState.getItemJS(layoutState.selectedItem).type === "ContentCarousel"
    ) {
      const item = layoutState.getItemJS(layoutState.selectedItem);
      const parent = layoutState.getItemJS(item.parent.id);
      let newProps = parent.props;
      if (parent.children.length > 1) {
        layoutState.removeItem(layoutState.selectedItem);
        setOpen(false);
        if (item.id === newProps.values.carousel.keyActive) {
          let newParent = layoutState.getItemJS(item.parent.id).props;
          let newChildren = filter(
            layoutState.getItemJS(item.parent.id).children,
            function (o) {
              return o !== item.id;
            }
          );
          newParent.values.carousel.keyActive = newChildren[0];
        }
      }
    } else {
      setOpen(false);
      layoutState.removeItem(layoutState.selectedItem);
    }
  };

  // const selectedItem = layoutState.getSelectedItem();
  // const isRooted = true; // selectedItem && selectedItem.id === "root" ? false : true;
  // console.log(selected);
  // const isOpen = selectedItem ? true : false;
  return (
    <div
      style={{ height: "100vh", top: 0, overflowY: "hidden", zIndex: 88975 }}
    >
      {open && (
        <div
          style={{
            width: 320,
            zIndex: 88990,
            position: "fixed",
            backgroundColor: "#FFFFFF",
            height: props.myContainerheight ? props.myContainerheight : "68vh",
            overflowY: "scroll",
            boxSizing: "content-box",
          }}
        >
          <Flex width={[1]} flexDirection={"column"} justifyContent={"center"}>
            <DivHeader
              style={{
                position: "fixed",
                top: props.myTop ? props.myTop : "80px",
                width: 320,
                zIndex: 988999,
              }}
            >
              <Text fontWeight={"bold"} width={[1]} pl={[2]} color={"#FFFFFF"}>
                {get(selected, "type", "")}
              </Text>
              <DivHeaderActions>
                <IconButton
                  onClick={() => handleClose()}
                  style={{ color: "#FFFFFF" }}
                >
                  <ActionClose color={"inherit"} />
                </IconButton>
                {selected && selected.id !== "root" && (
                  <IconButton
                    onClick={() => onDelete()}
                    style={{ color: "#FFFFFF" }}
                  >
                    <ActionDelete color={"inherit"} />
                  </IconButton>
                )}
              </DivHeaderActions>
            </DivHeader>
            <Plugin
              layoutState={layoutState}
              typePage={props.typePage}
              locale={props.locale}
              marketerId={props.marketerId}
              websiteId={props.websiteId}
              onClose={() => handleClose()}
              heightContent={props.heightScreen}
              widthContent={"320px"}
              myContainerheight={props.myContainerheight}
              myTop={props.myTop}
            />
          </Flex>
        </div>
      )}
    </div>
  );
};

// class PropsEditor extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       currentPlugin: 0,
//     };
//   }
//   handleClose = (layoutState) => {
//     console.log("masuk sini broo");
//     layoutState.setSelectedItem(null);
//   };

//   onDelete = (layoutState) => {
//     if (
//       layoutState.getItemJS(layoutState.selectedItem).type === "ContentCarousel"
//     ) {
//       const item = layoutState.getItemJS(layoutState.selectedItem);
//       const parent = layoutState.getItemJS(item.parent.id);
//       let newProps = parent.props;
//       if (parent.children.length > 1) {
//         layoutState.removeItem(layoutState.selectedItem);

//         if (item.id === newProps.values.carousel.keyActive) {
//           let newParent = layoutState.getItemJS(item.parent.id).props;
//           let newChildren = filter(
//             layoutState.getItemJS(item.parent.id).children,
//             function (o) {
//               return o !== item.id;
//             }
//           );
//           newParent.values.carousel.keyActive = newChildren[0];
//         }
//       }
//     } else {
//       layoutState.removeItem(layoutState.selectedItem);
//     }
//   };

//   render() {
//     const { layoutState, addons } = this.props;
//     const selectedItem = layoutState.getSelectedItem();
//     console.log(selectedItem);
//     let isRooted = selectedItem && selectedItem.id === "root" ? false : true;

//     let Plugin = addons[this.state.currentPlugin].Interface || (() => null);
//     const isOpen = selectedItem ? true : false;
//     return (
//       <div style={{ height: "100vh" }}>
//         <ContainerDimensions>
//           {({ width, height }) => {
//             return (
//               <Drawer anchor='right' open={isOpen}>
//                 <div style={{ width: width }}>
//                   {selectedItem && (
//                     <Flex
//                       width={[1]}
//                       flexDirection={"column"}
//                       justifyContent={"center"}
//                     >
//                       <DivHeader>
//                         <Text
//                           fontWeight={"bold"}
//                           width={[1]}
//                           pl={[2]}
//                           color={"#FFFFFF"}
//                         >
//                           {get(selectedItem, "type", "")}
//                         </Text>
//                         <DivHeaderActions>
//                           <IconButton
//                             onClick={() => this.handleClose(layoutState)}
//                             style={{ color: "#FFFFFF" }}
//                           >
//                             <ActionClose color={"inherit"} />
//                           </IconButton>
//                           {isRooted && (
//                             <IconButton
//                               onClick={() => this.onDelete(layoutState)}
//                               style={{ color: "#FFFFFF" }}
//                             >
//                               <ActionDelete color={"inherit"} />
//                             </IconButton>
//                           )}
//                         </DivHeaderActions>
//                       </DivHeader>
//                       <Plugin
//                         layoutState={layoutState}
//                         typePage={this.props.typePage}
//                         locale={this.props.locale}
//                         marketerId={this.props.marketerId}
//                         websiteId={this.props.websiteId}
//                         onClose={() => this.handleClose(layoutState)}
//                         heightContent={height}
//                         widthContent={width}
//                       />
//                     </Flex>
//                   )}
//                 </div>
//               </Drawer>
//             );
//           }}
//         </ContainerDimensions>
//       </div>
//     );
//   }
// }

PropsEditor.propTypes = {
  addons: PropTypes.array,
  typePage: PropTypes.string,
  marketerId: PropTypes.string,
};

export default connect("layoutState", "addons", "widthScreen")(PropsEditor);
