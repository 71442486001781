import { createMuiTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { themeMui, inputStyles } from "../../themes";
export const themeRaTA1 = createMuiTheme({
  ...themeMui,
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginTop: 16,
      },
    },
    MuiFormLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        color: inputStyles.colorLabel,
        fontWeight: "900",
        transform: "none",
        "&$focused": {
          color: inputStyles.colorLabel,
          backgroundColor: "transparent",
          transform: "none",
        },
      },
    },
    MuiInputLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        fontSize: 14,
        color: inputStyles.colorLabel,
        minWidth: 180,
        fontWeight: "900",
      },
      filled: {
        letterSpacing: inputStyles.letterSpacing,
        minWidth: 180,
        fontWeight: "900",
        "&$shrink": {
          fontSize: 14,
          "&$marginDense": {
            transform: "translate(0px, 6px) scale(0.75)",
          },
        },
        "&$marginDense": {
          fontSize: 14,
          transform: "translate(0px, 17px) scale(1)",
        },
      },
    },
    MuiInput: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        minWidth: 180,
      },
      underline: {
        borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
        "&$focused": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          "&::before": {
            borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          },
        },
        "&:hover": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          "&::before": {
            borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          },
        },
        "&::before": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
        },
        "&::after": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
        },
      },
    },
    MuiInputBase: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        backgroundColor: "Transparent",
        "&$focused": {
          backgroundColor: "Transparent",
        },
        "&:hover": {
          backgroundColor: "Transparent",
        },
      },
      inputMarginDense: {
        letterSpacing: 5,
        paddingTop: 4,
        paddingBottom: 2,
      },
      inputMultiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: 4,
        paddingBottom: 2,
      },
      input: {
        letterSpacing: inputStyles.letterSpacing,
        padding: "0px",
        paddingBottom: 2,
        fontSize: 14,
        backgroundColor: grey[300],
        borderRadius: 6,
      },
      marginDense: {
        padding: 0,
      },
      multiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: "0px",
        fontSize: 14,
      },
      underline: {
        borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
        "&$focused": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          "&::before": {
            borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          },
        },
        "&:hover": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          "&::before": {
            borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          },
        },
        "&::before": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
        },
        "&::after": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
        },
      },
    },
    MuiFilledInput: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        backgroundColor: "transparent",
        "&$focused": {
          backgroundColor: "transparent",
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      input: {
        letterSpacing: inputStyles.letterSpacing,
        padding: 0,
        fontSize: 14,
        backgroundColor: grey[300],
        borderRadius: 6,
      },
      multiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: 0,
      },
      inputMarginDense: {
        letterSpacing: inputStyles.letterSpacing,
        padding: 4,
      },
      inputMultiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: 4,
        fontSize: 14,
      },
      underline: {
        borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
        "&$focused": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          "&::before": {
            borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          },
        },
        "&:hover": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          "&::before": {
            borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          },
        },
        "&::before": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
        },
        "&::after": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
        },
      },
    },
  },
});
export const themeRaTA = createMuiTheme({
  ...themeMui,
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginTop: 16,
      },
    },
    MuiFormLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        color: inputStyles.colorLabel,
        fontWeight: "900",
        transform: "none",
        "&$focused": {
          color: inputStyles.colorLabel,
          backgroundColor: "transparent",
          transform: "none",
        },
      },
    },
    MuiInputLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        fontSize: 16,
        color: inputStyles.colorLabel,
        minWidth: 180,
        fontWeight: "900",
      },
      filled: {
        letterSpacing: inputStyles.letterSpacing,
        minWidth: 180,
        fontWeight: "900",
        "&$shrink": {
          fontSize: 16,
          "&$marginDense": {
            transform: "translate(0px, 6px) scale(0.75)",
          },
        },
        "&$marginDense": {
          fontSize: 16,
          transform: "translate(0px, 17px) scale(1)",
        },
      },
    },
    MuiInput: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        minWidth: 180,
      },
    },
    MuiFilledInput: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        backgroundColor: "transparent",
        "&$focused": {
          backgroundColor: "transparent",
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      input: {
        letterSpacing: inputStyles.letterSpacing,
        padding: 0,
        fontSize: 14,
        backgroundColor: grey[300],
        borderRadius: 6,
      },
      multiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: 0,
      },
      inputMarginDense: {
        letterSpacing: inputStyles.letterSpacing,
        padding: 4,
      },
      inputMultiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: 4,
        fontSize: 14,
      },
      underline: {
        borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
        "&$focused": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          "&::before": {
            borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          },
        },
        "&:hover": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          "&::before": {
            borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          },
        },
        "&::before": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
        },
        "&::after": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
        },
      },
    },
  },
});

export const themeRaTANoLabel = createMuiTheme({
  ...themeMui,
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginTop: 0,
      },
    },
    MuiFormLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        color: inputStyles.colorLabel,
        fontWeight: "900",
        transform: "none",
        "&$focused": {
          color: inputStyles.colorLabel,
          backgroundColor: "transparent",
          transform: "none",
        },
      },
    },
    MuiInputLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        fontSize: 16,
        color: inputStyles.colorLabel,
        minWidth: 180,
        fontWeight: "900",
      },
      filled: {
        letterSpacing: inputStyles.letterSpacing,
        minWidth: 180,
        fontWeight: "900",
        "&$shrink": {
          fontSize: 16,
          "&$marginDense": {
            transform: "translate(0px, 6px) scale(0.75)",
          },
        },
        "&$marginDense": {
          fontSize: 16,
          transform: "translate(0px, 17px) scale(1)",
        },
      },
    },
    MuiInput: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        minWidth: 180,
      },
    },
    MuiInputBase: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        backgroundColor: "Transparent",
        "&$focused": {
          backgroundColor: "Transparent",
        },
        "&:hover": {
          backgroundColor: "Transparent",
        },
      },
      inputMarginDense: {
        letterSpacing: 5,
        paddingTop: 4,
        paddingBottom: 2,
      },
      inputMultiline: {
        letterSpacing: inputStyles.letterSpacing,
        paddingTop: 4,
        paddingBottom: 2,
      },
      input: {
        letterSpacing: inputStyles.letterSpacing,
        padding: "6px 2px 10px",
        paddingBottom: 2,
        fontSize: 14,
      },
      marginDense: {
        padding: 0,
      },
      multiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: "0px 2px 10px",
        fontSize: 14,
      },
    },
    MuiFilledInput: {
      root: {
        padding: 0,
        letterSpacing: inputStyles.letterSpacing,
        backgroundColor: "transparent",
        "&$focused": {
          backgroundColor: "transparent",
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      inputMarginDense: {
        letterSpacing: 5,
        paddingTop: 4,
        paddingBottom: 2,
      },
      marginDense: {
        padding: 0,
      },
      input: {
        letterSpacing: inputStyles.letterSpacing,
        padding: 0,
        fontSize: 14,
        backgroundColor: grey[300],
        borderRadius: 6,
      },
      multiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: 0,
      },
      inputMultiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: 2,
        fontSize: 14,
      },
      underline: {
        borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
        "&$focused": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          "&::before": {
            borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          },
        },
        "&:hover": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          "&::before": {
            borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
          },
        },
        "&::before": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
        },
        "&::after": {
          borderBottom: `0px solid ${inputStyles.borderBottomColor}`,
        },
      },
    },
  },
});
