export default {
  sites: {
    name: "Sites",
    new_title: "New Site",
    edit_title: "Edit Site",
    tabs: {
      site: "Site Setting",
      websites: "Websites",
      sources: "Sources",
      channels: "Channels",
    },
    fields: {
      name: "Name",
      active: "Active",
      currency: "Currency",
      language: "Language",
      default_language: "Default Language",
    },
    note: {
      language: "Supported languages for the site",
      subheader:
        "Website shares the same products, pricing, categories, collections but with unique webpage design",
    },
  },
}
