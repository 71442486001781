import React from "react";
import { Flex } from "rebass";
import { getStylesContent } from "./styles";
import ThemeItem from "./ThemeItem";
import ThemeMulti from "./ThemeMulti";

import {
  template1A,
  template2A,
  template3A,
  template4A,
  template5A,
  template6A,
  template7A,
  template8A,
  template11A,
  template12A,
  template13A,
  template9A,
  template10A,
  template14A,
  template15,
  template15A,
  template16,
  template16A,
  template17A,
  template18A,
  template21A,
  template22A,
  template23A,
  template24A,
  template25A,
  templateHeader3,
} from "./templateContent";
// templateHeader,
// templateHeader1,
const listTemplate2 = [
  template23A,
  template21A,
  template22A,
  template2A,
  template3A,
  template5A,
  template6A,
  template7A,
  template11A,
  template14A,
  template15A,
  template16A,
  template17A,
  // template4A,
  // template5A,
  // template6A,
  // template8A,
  // template10A,
  template13A,
];
//, template9A, template11A, template12A
const listSingleRow = [
  template1A,
  template4A,
  template8A,
  template9A,
  template10A,
  template12A,
  template18A,
  template24A,
  template25A,
];
const listHeader = [template16, templateHeader3];
const listHeaderMulti = [template15];
const renderTemplate = (props, width, height) => {
  const { components } = props;
  const styles = getStylesContent();
  return (
    <Flex width={[1]} flexDirection={"column"} alignItems={"center"} px={[1]}>
      {listSingleRow.map((template, index) => {
        return (
          <div style={styles.sidebarOpenRow} key={index}>
            {Object.keys(template).map((key) => {
              let value = template[key];

              if (value.childOf === "Row" && value.type !== "Content") {
                return (
                  <ThemeItem
                    key={key}
                    type={value.type}
                    component={components[value.type]}
                    defaultProps={value.props}
                    layout={value.layout}
                    width={width}
                    content={value.content}
                    heightContent={height}
                    children={value.children}
                    {...props}
                    id={value.id}
                  />
                );
              } else {
                return <div key={key} />;
              }
            })}
          </div>
        );
      })}
    </Flex>
  );
};
const renderTemplateRow2 = (props, width, height) => {
  const { components } = props;
  const styles = getStylesContent();

  return (
    <Flex width={[1]} flexDirection={"column"} alignItems={"center"} px={[1]}>
      {listTemplate2.map((template, index) => {
        return (
          <div style={styles.sidebarOpenRow} key={index}>
            {Object.keys(template).map((key) => {
              // console.log(key)
              let value = template[key];

              if (value.childOf === "Row" && value.type !== "Content") {
                return (
                  <ThemeMulti
                    key={key}
                    type={value.type}
                    component={components[value.type]}
                    defaultProps={value.props}
                    layout={value.layout}
                    width={width}
                    content={template}
                    myContent={value.myContent}
                    heightContent={height}
                    children={value.children}
                    {...props}
                    id={value.id}
                  />
                );
              } else {
                return <div key={key} />;
              }
            })}
          </div>
        );
      })}
    </Flex>
  );
};

const renderTemplateMulti = (props, width, height) => {
  // const { components } = props;
  // const styles = getStylesContent();
  // // console.log(props.layoutState)
  return <Flex />;
  // return (
  //   <Flex width={[1]} flexDirection={"column"} alignItems={"center"} px={[2]}>
  //     <div style={styles.sidebarOpenRow}>
  //       {Object.keys(template2A).map((key) => {
  //         // console.log(key)
  //         let value = template2A[key];

  //         if (value.childOf === "Row" && value.type !== "Content") {
  //           return (
  //             <ThemeMulti
  //               key={key}
  //               type={value.type}
  //               component={components[value.type]}
  //               defaultProps={value.props}
  //               layout={value.layout}
  //               width={width}
  //               content={template2A}
  //               myContent={value.myContent}
  //               heightContent={height}
  //               children={value.children}
  //               {...props}
  //               id={value.id}
  //             />
  //           );
  //         } else {
  //           return <div key={key} />;
  //         }
  //       })}
  //     </div>
  //   </Flex>
  // );
};

const renderTemplateHeader = (props, width, height) => {
  const { components } = props;
  const styles = getStylesContent();

  return (
    <Flex width={[1]} flexDirection={"column"} alignItems={"center"} px={[1]}>
      {listHeader.map((template, index) => {
        return (
          <div style={styles.sidebarOpenRow} key={index}>
            {Object.keys(template).map((key) => {
              let value = template[key];

              if (value.childOf === "Row" && value.type !== "Content") {
                return (
                  <ThemeItem
                    key={key}
                    type={value.type}
                    component={components[value.type]}
                    defaultProps={value.props}
                    layout={value.layout}
                    width={width}
                    content={value.content}
                    heightContent={height}
                    children={value.children}
                    {...props}
                    id={value.id}
                  />
                );
              } else {
                return <div key={key} />;
              }
            })}
          </div>
        );
      })}
    </Flex>
  );
};

const renderTemplateHeader2 = (props, width, height) => {
  const { components } = props;
  const styles = getStylesContent();

  return (
    <Flex width={[1]} flexDirection={"column"} alignItems={"center"} px={[1]}>
      {listHeaderMulti.map((template, index) => {
        return (
          <div style={styles.sidebarOpenRow} key={index}>
            {Object.keys(template).map((key) => {
              // console.log(key)
              let value = template[key];

              if (value.childOf === "Row" && value.type !== "Content") {
                return (
                  <ThemeMulti
                    key={key}
                    type={value.type}
                    component={components[value.type]}
                    defaultProps={value.props}
                    layout={value.layout}
                    width={width}
                    content={template}
                    myContent={value.myContent}
                    heightContent={height}
                    children={value.children}
                    {...props}
                    id={value.id}
                  />
                );
              } else {
                return <div key={key} />;
              }
            })}
          </div>
        );
      })}
    </Flex>
  );
};
export {
  renderTemplate,
  renderTemplateMulti,
  renderTemplateRow2,
  renderTemplateHeader,
  renderTemplateHeader2,
};
