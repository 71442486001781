import React, { Fragment } from "react";
import { Flex, Box } from "rebass";
import { withDataProvider } from "react-admin";
import { RaTextArea } from "../../../components";
import styled from "styled-components";
import { Paper } from "@material-ui/core";
export const DivTitle = styled.div`
  font-size: 1em;
  padding-bottom: 10px;
  font-weight: bold;
`;

const styles = {
  paperText: {
    padding: 10,
    marginBottom: 10,
  },
};
export const PaperCard = (props) => {
  const { children, title, elevation = 1 } = props;
  return (
    <Paper style={styles.paperText} elevation={elevation}>
      <DivTitle>{title}</DivTitle>
      <Fragment>{children}</Fragment>
    </Paper>
  );
};

const LocaleDescriptions = (props) => {
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <RaTextArea
        source={`description`}
        label={"resources.promotions.fields.description"}
        rows='6'
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
      <RaTextArea
        source={`terms`}
        label={"resources.promotions.fields.terms"}
        rows='6'
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
    </Flex>
  );
};

const DescriptionTerms = (props) => {
  const { translate } = props;

  return (
    <Box width={[1]} py={[2]}>
      <PaperCard
        title={translate("resources.promotions.labels.description_terms")}
        elevation={3}
      >
        <LocaleDescriptions {...props} />
      </PaperCard>
    </Box>
  );
};

export default withDataProvider(DescriptionTerms);
