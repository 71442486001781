import React, { Component } from "react"
import PropTypes from "prop-types"
import { Flex } from "rebass"
import { get } from "lodash"
import { FooterAction, ContainerPanel } from "../actions"
import { BoxContent } from "../styled"
import PanelGeneral from "./PanelGeneral"
import DialogProductGroup from "./DialogProductGroup"

const defaultSetting = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 200,
  centerPadding: "16px",
  slidesToShow: 2,
  slidesToScroll: 4,
  adaptiveHeight: true,
  rows: 2,
  slidesPerRow: 2,
  autoplay: false,
  autoplaySpeed: false,
}

class ProductGroupView extends Component {
  constructor(props) {
    super(props)
    // console.log(props)
    // const { products, setting } = props.data.values
    const { values } = props.data
    this.state = {
      products: get(values, "products", ""),
      type_list: get(values, "type_list", "product"),
      containerPadding: get(values, "containerPadding", "0px"),
      categoryId: get(values, "categoryId", 0),
      collectionId: get(values, "collectionId", 0),
      showPrice: get(values, "showPrice", false),
      limit: get(values, "limit", 4),
      setting: get(values, "setting", defaultSetting),
      heightContent: props.heightContent,
      widthContent: props.widthContent,
    }
  }
  onClickSave = () => {
    let newData = this.props.data
    newData.values.products = this.state.products
    newData.values.type_list = this.state.type_list
    newData.values.containerPadding = this.state.containerPadding
    newData.values.categoryId = this.state.categoryId
    newData.values.collectionId = this.state.collectionId
    newData.values.showPrice = this.state.showPrice
    newData.values.limit = this.state.limit
    newData.values.setting = this.state.setting
    // console.log(newData)
    this.props.onUpdateData(newData)
  }
  onClickCopy = () => {
    let newData = this.props.data
    newData.values.products = this.state.products
    newData.values.type_list = this.state.type_list
    newData.values.containerPadding = this.state.containerPadding
    newData.values.categoryId = this.state.categoryId
    newData.values.collectionId = this.state.collectionId
    newData.values.showPrice = this.state.showPrice
    newData.values.limit = this.state.limit
    newData.values.setting = this.state.setting
    localStorage.setItem("ProductGroupView", JSON.stringify(newData.values))
  }
  onClickPaste = () => {
    const productGroupContent = JSON.parse(
      localStorage.getItem("ProductGroupView")
    )
    if (productGroupContent && productGroupContent !== null) {
      this.setState({
        products: get(productGroupContent, "products", ""),
        type_list: get(productGroupContent, "type_list", "product"),
        containerPadding: get(productGroupContent, "containerPadding", "0px"),
        categoryId: get(productGroupContent, "categoryId", 0),
        collectionId: get(productGroupContent, "collectionId", 0),
        showPrice: get(productGroupContent, "showPrice", false),
        limit: get(productGroupContent, "limit", 4),
        setting: get(productGroupContent, "setting", defaultSetting),
      })
    }
  }
  onSetValue = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value,
    })
  }
  render() {
    return (
      <Flex width={[1]} flexDirection={"column"}>
        <BoxContent style={{ height: this.state.heightContent - 120 }}>
          <ContainerPanel>
            <PanelGeneral state={this.state} onSetValue={this.onSetValue} />
            <DialogProductGroup
              state={this.state}
              onSetValue={this.onSetValue}
              websiteId={this.props.websiteId}
            />
          </ContainerPanel>
          <FooterAction
            onClickSave={this.onClickSave}
            onClickCopy={this.onClickCopy}
            onClickPaste={this.onClickPaste}
            widthContent={this.state.widthContent}
          />
        </BoxContent>
      </Flex>
    )
  }
}

ProductGroupView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
}

ProductGroupView.defaultProps = {
  data: {},
}

export default ProductGroupView
