import { lightBlue } from "@material-ui/core/colors"

export const stylesLogin = theme => ({
  root: {
    width: 550,
    borderRadius: 16,
    [theme.breakpoints.down("xs")]: {
      width: 310,
      borderRadius: 8,
    },
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(8) * 2,
    paddingBottom: theme.spacing(8) * 2,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  button: {
    backgroundColor: lightBlue[300],
    textTransform: "capitalize",
    fontSize: 14,
    fontWeight: "900",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: lightBlue[400],
    },
  },
})
