import React from "react";
import { get, find } from "lodash";
import { Box } from "rebass";
import CategoryList from "./CategoryList";
export default (props) => {
  const { updateField, categories, values, translate } = props;
  const onChangeValue = (v) => {
    // console.log(v);
    const getItem = find(categories, function (o) {
      return o.id === v;
    });
    updateField("href.value", v);
    updateField("href.slug", get(getItem, "slug", ""));
    updateField("title", get(getItem, "name", ""));
  };
  // console.log(values);
  return (
    <Box width={[1]} py={[3]}>
      <Box>{translate("resources.layouts.menu.category")}</Box>
      <CategoryList
        list={categories}
        onSetValue={(v) => onChangeValue(v)}
        value={get(values, "href.value", "") + ""}
      />
    </Box>
  );
};
