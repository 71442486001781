import { Layout7 } from "../../TemplateImageSonak";
export const template7A = {
  mBOjHyFS: {
    id: "mBOjHyFS",
    type: "Row6",
    props: {
      values: {
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 2,
          height: "100%",
          layout: {
            wrap: true,
            align: "middle",
            gutter: 10,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "10px 20px 10px 20px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "1080px",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 20,
            justify: "center",
            contentAlign: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "20px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 1,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-PST.jpeg",
                    size: "cover",
                    ratio: 1,
                    layout: {
                      justify: "flex-end",
                    },
                    margin: "8px 0px 8px 0px",
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                show: null,
                width: "100%",
                height: "100%",
                layout: {
                  span: 6,
                  order: 1,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-PST.jpeg",
                    size: "cover",
                    ratio: 1,
                    layout: {
                      justify: "flex-end",
                    },
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 2,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-K2H.jpeg",
                    size: "cover",
                    ratio: 1,
                    layout: {
                      justify: "flex-end",
                    },
                    margin: "8px 0px 8px 0px",
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                show: null,
                width: "100%",
                height: "100%",
                layout: {
                  span: 6,
                  order: 2,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-K2H.jpeg",
                    size: "cover",
                    ratio: 1,
                    layout: {
                      justify: "flex-end",
                    },
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 3,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-CPB.jpeg",
                    size: "cover",
                    ratio: 1,
                    layout: {
                      justify: "flex-end",
                    },
                    margin: "8px 0px 8px 0px",
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                show: null,
                width: "100%",
                height: "100%",
                layout: {
                  span: 6,
                  order: 3,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-CPB.jpeg",
                    size: "cover",
                    ratio: 1,
                    layout: {
                      justify: "flex-end",
                    },
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 4,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-9O7.jpeg",
                    size: "cover",
                    ratio: 1,
                    layout: {
                      justify: "flex-end",
                    },
                    margin: "8px 0px 8px 0px",
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                show: null,
                width: "100%",
                height: "100%",
                layout: {
                  span: 6,
                  order: 4,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-9O7.jpeg",
                    size: "cover",
                    ratio: 1,
                    layout: {
                      justify: "flex-end",
                    },
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    childOf: "Row",
    layout: Layout7,
    children: ["weC4s14Q", "kswt1ri7", "16VBo+Ra", "f0eFQDFp"],
    myContent: [
      {
        id: "bZaHpWMa",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>FOOTWEAR</strong></p>",
            type: "TextView",
            isLink: true,
            mobile: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 8,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "10px 0px 10px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 14,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "20px 0px 20px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "kswt1ri7",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "kswt1ri7",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "eRo6M5c0",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>FOOTWEAR</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 8,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "10px 0px 10px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 14,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "20px 0px 20px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "weC4s14Q",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "weC4s14Q",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "f0HXD4f0",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>ACCESSORIES</strong></p>",
            type: "TextView",
            isLink: true,
            mobile: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 8,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "10px 0px 10px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 14,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "20px 0px 20px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "16VBo+Ra",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "16VBo+Ra",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "i9xdTEhX",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>STYLE</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 8,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "10px 0px 10px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 14,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "20px 0px 20px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "f0eFQDFp",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "f0eFQDFp",
          idx: 0,
          index: 1,
        },
      },
    ],
  },
};
