import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Text } from "rebass"
import { withStyles } from "@material-ui/core/styles"
import { grey } from "@material-ui/core/colors"
import { translate, useTranslate } from "react-admin"
import { inputStyles } from "../../themes"

// A function you can extract and put into its own module.
// Yes, 15 lines of code, it's all you need.
export function styled(Component) {
  return (style, options) => {
    function StyledComponent(props) {
      const { classes, className, ...other } = props
      return (
        <Component className={classNames(classes.root, className)} {...other} />
      )
    }
    StyledComponent.propTypes = {
      classes: PropTypes.object.isRequired,
      className: PropTypes.string,
    }
    const styles =
      typeof style === "function"
        ? theme => ({ root: style(theme) })
        : { root: style }
    return withStyles(styles, options)(StyledComponent)
  }
}

const TextMessage = ({ notetext }) => {
  const translate = useTranslate()
  return (
    <Text
      fontSize={12}
      color={grey[700]}
      pt={"1px"}
      style={{ letterSpacing: inputStyles.letterSpacing }}
    >
      {notetext ? translate(notetext) : ""}
    </Text>
  )
}

export default translate(TextMessage)
