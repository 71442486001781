import React, { useRef } from "react";
// /useState
// import { elasticSearchServer } from "../../../../restClient";
// import Slider from "react-slick";
import { Box, Flex, Text } from "rebass";
// import { get } from "lodash";
// import { ReactiveBase, ReactiveList } from "@appbaseio/reactivesearch";
// import ItemCategory from "./ItemCategory";
import HeaderList from "./HeaderList";
// import { defaultSetting } from "./utils";
//settingsList

export default (props) => {
  const refContainer = useRef(null);
  // const [state, setState] = useState({ isIndex: 0, isEnd: false });
  const state = { isIndex: 0, isEnd: false };
  // const [listLength, setListLength] = useState(100);
  const { screenWidth } = props;
  //desktop
  //, colorProps, tags, modeType, showPrice
  // const settings = settingsList(
  //   get(desktop, "setting", defaultSetting),
  //   screenWidth,
  //   (index) => onChange(index)
  // );
  // const slidesToShow = get(
  //   desktop,
  //   "setting.slidesToShow",
  //   defaultSetting.slidesToShow
  // );
  // const onChange = (index) => {
  //   const listTags = get(refContainer, "current.props.children", []);
  //   // const slidesToShow = get(desktop, "setting.slidesToShow", defaultSetting);
  //   const addIndex = slidesToShow; //screenWidth > 702 ? 6 : 3;
  //   const currentIndex = index + addIndex;
  //   let isEndCurrent = false;

  //   if (currentIndex >= listTags.length) {
  //     isEndCurrent = true;
  //   }
  //   setState({
  //     isIndex: index,
  //     isEnd: isEndCurrent,
  //   });
  // };
  // const onGetData = (props) => {
  //   const { resultStats } = props;
  //   setListLength(get(resultStats, "numberOfResults", 100));
  // };
  return (
    <Box style={{ width: screenWidth }}>
      <HeaderList {...props} sliderone={refContainer.current} state={state} />
      <div style={{ backgroundColor: "transparent" }}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"center"}
          p={[2]}
        >
          <Text textAlign={"center"}>Items Content</Text>
        </Flex>

        {/* <ReactiveBase app='product' url={elasticSearchServer}>
          <ReactiveList
            componentId='results'
            dataField='name'
            stream={true}
            size={listLength}
            infiniteScroll={false}
            className='product-list-slide-categories-reactactive'
            showResultStats={false}
            defaultQuery={() => {
              return {
                query: {
                  bool: {
                    should: [
                      {
                        terms: {
                          "tags.keyword": tags,
                        },
                      },
                    ],
                    minimum_should_match: "1",
                  },
                },
              };
            }}
            render={({ data }) => (
              <Slider
                ref={refContainer}
                {...settings}
                style={{ backgroundColor: "transparent" }}
              >
                {data &&
                  data.map((item, index) => {
                    return (
                      <ItemCategory
                        item={item}
                        key={index}
                        size={[1]}
                        screenWidth={screenWidth}
                        colorProps={colorProps}
                        desktop={desktop}
                        modeType={modeType}
                        showPrice={showPrice}
                      />
                    );
                  })}
              </Slider>
            )}
            onData={(propsData) => onGetData(propsData)}
            pagination={false}
            renderError={(error) => <div />}
          />
        </ReactiveBase>
         */}
      </div>
    </Box>
  );
};
