import React, { useState } from "react";
import { Flex, Box, Text } from "rebass";
import { lightBlue } from "@material-ui/core/colors";
import { Dialog } from "@material-ui/core";
import { DialogTitle, DialogContent } from "../listslide/styles";
import ContentItemCarousel from "./ContentItemCarousel";
import DialogDelete from "./DialogDelete";
const DialogItemCarousel = (props) => {
  const [show, setShow] = useState(false);
  const { data, indexParent } = props;
  return (
    <Box width={[1]}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        p={[2]}
        my={[2]}
        style={{ backgroundColor: lightBlue[600] }}
      >
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          style={{ backgroundColor: lightBlue[600], cursor: "pointer" }}
          onClick={() => setShow(true)}
        >
          <Text pl={[2]} color={"white"}>{`${data.name} #${
            indexParent + 1
          }`}</Text>
        </Flex>
        <DialogDelete {...props} />
      </Flex>
      <Dialog
        onClose={() => setShow(false)}
        aria-labelledby={`customized-dialog-title-${indexParent}`}
        open={show}
        fullScreen={true}
        scroll={"paper"}
      >
        <DialogTitle
          id={`customized-dialog-title-${indexParent}`}
          onClose={() => setShow(false)}
        >
          Content of {`${data.name} #${indexParent + 1}`}
        </DialogTitle>
        <DialogContent dividers>
          <ContentItemCarousel {...props} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DialogItemCarousel;
