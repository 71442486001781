import React from "react";
import { get, sumBy } from "lodash";
import { Flex, Box, Text, Card } from "rebass";
import Currency from "./Currency";
import ProductColor from "./ProductColor";
import { Discount } from "./Discount";
import {
  BoxOverlay,
  ProductInfo,
  ContainerCard,
  BoxOverlayOutOfStock,
  // BoxOverlayFav,
} from "./styled";
import { useClientRect, getPhoto } from "./utils";
import { ImageWithFB } from "../images";

// const BoxText = (props) => {
//   const { title, att, screenWidth } = props;
//   const indexItem = screenWidth > 480 ? 1 : 0;
//   return (
//     <span
//       style={{
//         width: "100%",
//         textAlign: att.textAlign[indexItem],
//         fontSize: `${att.fontSize[indexItem]}pt`,
//       }}
//     >
//       {title}
//     </span>
//   );
// };

export default (props) => {
  const {
    item,
    // size = [1],
    // screenWidth,
    desktop,
    colorProps,
    modeType,
    showPrice,
  } = props;
  const [rect, myRef] = useClientRect();
  // const { url } = href;
  //   const title = get(item, "title", "");
  //   const textAttributes = get(item, "text");
  const colorTitle = get(colorProps, "color", "#000000");
  const colorHover = get(colorProps, "hoverColor", "#FFFFFF");
  const titlePadding = get(desktop, "listProps.titlePadding", "0px");
  // const padding = get(desktop, "listProps.padding", "0px");
  const fontSize = get(desktop, "listProps.fontSize", "12");
  const hoverBackgroundColor = get(
    colorProps,
    "hoverBackgroundColor",
    "transparent"
  );
  const backgroundColorContainer = get(
    colorProps,
    "backgroundColor",
    "transparent"
  );
  const name = get(item, `locale.en.name`, item.name);
  const discount = get(item, "discount", 0);
  const price = get(item, "price", 0);
  const discountedPrice =
    discount < 1 ? price * (1 - discount) : price - discount;

  const discountText = discount < 1 ? discount * 100 : 0;
  const myPhoto = getPhoto(item.photos);
  const listVariantsQty = get(item, "variant.variants", []);
  const productQty = sumBy(listVariantsQty, "qty");
  const bodyFontFamily = "Roboto";
  // console.log("itemCategory", productQty, listVariantsQty);
  return (
    <Box
      width={[1]}
      ref={myRef}
      p={[1]}
      sx={{ height: "100%", position: "relative" }}
    >
      <ContainerCard
        key={item.id}
        width={[1]}
        flexDirection={"column"}
        sx={{
          height: "100%",
          backgroundColor: backgroundColorContainer,
          color: colorTitle,
          fontWeight: "bold",
          fontFamily: bodyFontFamily,
          "&:hover": {
            cursor: "pointer",
            color: colorHover,
            backgroundColor: hoverBackgroundColor,
          },
        }}
      >
        <Card
          width={[1]}
          mx='auto'
          style={{
            backgroundColor: backgroundColorContainer,
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box width={[1]}>
            <ImageWithFB
              src={myPhoto}
              style={{
                width: "100%",
                height: rect.width <= 0 ? "auto" : rect.width - 50,
                objectFit: "contain",
              }}
            />
          </Box>
          {discountText > 0 && (
            <BoxOverlay>
              <div style={{ paddingRight: 0 }}>
                <Discount
                  size='40px'
                  fontSize='1em'
                  bg='red'
                  color='white'
                  style={{ fontFamily: bodyFontFamily }}
                >
                  {discountText}%
                </Discount>
              </div>
            </BoxOverlay>
          )}
          {/* {discountText >= 0 && (
          <BoxOverlay>
            <div>
              <BoxPromotion size='40px' fontSize='10pt' bg='red' color='white'>
                Sale
              </BoxPromotion>
            </div>
          </BoxOverlay>
        )}
        {discountText >= 200 && (
          <BoxOverlay>
            <div>
              <BoxPromotion
                size='40px'
                fontSize='10pt'
                bg='#16aded'
                color='white'
              >
                New
              </BoxPromotion>
            </div>
          </BoxOverlay>
        )} */}
          {/* <BoxOverlayFav>
          <div style={{ paddingRight: 0 }}>
            <ButtonFav />
          </div>
        </BoxOverlayFav> */}
          <Flex
            flexDirection={"row"}
            flexWrap={"wrap"}
            width={[1]}
            sx={{ padding: titlePadding }}
          >
            <Text
              fontSize={[`${fontSize}pt`]}
              sx={{
                fontFamily: bodyFontFamily,
                color: colorTitle,
                fontWeight: "bold",
                "&:hover": {
                  cursor: "pointer",
                  color: colorHover,
                },
              }}
            >
              {name}
            </Text>
          </Flex>
          <Flex
            width={[1]}
            flexDirection='column'
            sx={{
              flex: 1,
              color: colorTitle,
              textAlign: "left",
              padding: titlePadding,
              "&:hover": {
                color: colorHover,
              },
            }}
          >
            <ProductInfo flexDirection='column' justifyContent='flex-end'>
              <ProductColor
                product={item}
                typeContent={modeType}
                colorTitle={colorTitle}
                fontSize={fontSize}
              />
              {discount > 0 && showPrice && (
                <Box
                  pt={[1]}
                  pb={[1]}
                  style={
                    discount > 0
                      ? {
                          textDecorationLine: "line-through",
                          textDecorationStyle: "solid",
                        }
                      : {}
                  }
                >
                  {discount > 0 && (
                    <Text fontSize={["10pt"]}>
                      <Currency value={item.price} />
                    </Text>
                  )}
                </Box>
              )}
              {showPrice && (
                <Text
                  fontWeight='normal'
                  // fontSize={[`${fontSize}pt`]}
                  color={discount > 0 ? "red" : "text"}
                  sx={{
                    fontFamily: bodyFontFamily,
                    "&:hover": {
                      color: ["#000000"],
                    },
                  }}
                >
                  <Currency value={discountedPrice} />
                </Text>
              )}
            </ProductInfo>
          </Flex>
        </Card>
      </ContainerCard>
      {productQty === 0 && (
        <BoxOverlayOutOfStock>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255,255,255,0.8)",
              borderRadius: "0.25rem",
              border: "1px solid #d8d8d8",
            }}
          >
            <Text
              color={"red"}
              fontWeight={"bold"}
              style={{
                textTransform: "uppercase",
                fontFamily: bodyFontFamily,
              }}
            >
              Out of Stock
            </Text>
          </Flex>
        </BoxOverlayOutOfStock>
      )}
    </Box>
  );
};
