import React, { Fragment, useCallback } from "react";
import { useTranslate, TextField, SearchInput, Filter } from "react-admin";
import Button from "@material-ui/core/Button";
import StoresEdit from "./Edit";
import StoresCreate from "./Create";
import { Route, useHistory } from "react-router-dom";
import { Drawer } from "@material-ui/core";
import { Flex } from "rebass";
import { get } from "lodash";
import { useStyles } from "./styles";
import blue from "@material-ui/core/colors/blue";
import { BetterList, ButtonEditView, HeaderTitle } from "../../components";
import moment from "moment";
import Chip from "@material-ui/core/Chip";

const StoreFilter = (props) => (
  <Flex
    width={[1]}
    flexDirection={"row"}
    justifyContent={"flex-end"}
    style={{ backgroundColor: "#FFFFFF" }}
  >
    <Filter {...props}>
      <SearchInput source='search' alwaysOn />
    </Filter>
  </Flex>
);

const DateField = ({ record, source }) => {
  const myDate = get(record, source, "");
  if (myDate === null || myDate === "") {
    return <span />;
  }
  return <span>{moment(myDate).format("DD-MMM-YYYY")}</span>;
};

const TagsField = ({ source, record }) => {
  const myTags = get(record, source, []);
  return myTags && myTags.length > 0
    ? myTags.map((item, key) => {
        return (
          <Chip
            key={key}
            style={{
              backgroundColor: blue[300],
              textTransform: "none",
              marginRight: 4,
              marginLeft: 4,
            }}
            label={<span style={{ textTransform: "none" }}>{item}</span>}
          />
        );
      })
    : null;
};

export const StoresList = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  // const theme = useTheme()
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push("/stores");
  }, [history]);

  const openCreate = useCallback(
    (id) => {
      // let pathUrl = "/stores/create";
      // if (id !== null) {
      //   pathUrl = `/stores/create?pid=${id}`;
      // }
      let pathUrl = "/stores/create";
      history.push(pathUrl);
    },
    [history]
  );

  // const openEdit = useCallback(
  //   (id) => {
  //     history.push(`/stores/${id}`);
  //   },
  //   [history]
  // );

  return (
    <div className={classes.root}>
      <BetterList
        {...props}
        actions={
          <HeaderTitle title='resources.stores.name'>
            <Button
              color='primary'
              // onClick={() => this.onSetOpenByShow(true, 1)}
              onClick={() => openCreate(null)}
              size='small'
              style={{ textTransform: "capitalize" }}
            >
              {translate("resources.buttons.create")}
            </Button>
          </HeaderTitle>
        }
        filters={<StoreFilter />}
      >
        <TextField source='name' />
        <TagsField source='tags' />
        <DateField
          source='created_at'
          label={"resources.stores.fields.created_at"}
        />
        <ButtonEditView {...props} />
      </BetterList>
      <div className={classes.rootList}>
        <Route path='/stores/:id'>
          {({ match }) => {
            const isMatch = !!(
              match &&
              match.url &&
              match.url !== "/stores/create"
            );
            if (match && match.url && match.url === "/stores/create") {
              return (
                <Fragment>
                  <Drawer
                    variant='persistent'
                    open={match && match.url && match.url === "/stores/create"}
                    anchor='right'
                    onClose={handleClose}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                  >
                    {match && match.url && match.url === "/stores/create" ? (
                      <StoresCreate onCancel={handleClose} {...props} />
                    ) : null}
                  </Drawer>
                </Fragment>
              );
            }
            return (
              <Fragment>
                <Drawer
                  variant='persistent'
                  open={isMatch}
                  anchor='right'
                  onClose={handleClose}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  {isMatch &&
                  match &&
                  match.url &&
                  match.url !== "/stores/create" ? (
                    <StoresEdit
                      id={match.params.id}
                      onCancel={handleClose}
                      {...props}
                    />
                  ) : null}
                </Drawer>
              </Fragment>
            );
          }}
        </Route>
      </div>
    </div>
  );
};
