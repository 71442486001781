import React from "react";
import { useTranslate } from "react-admin";
import ChipInput from "material-ui-chip-input";
import { get } from "lodash";
import { Box } from "rebass";
import TextMessage from "./utils";
import { Chip } from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";
const defaultChipRenderer = (
  {
    text,
    isFocused,
    isDisabled,
    isReadOnly,
    handleClick,
    handleDelete,
    className,
  },
  key
) => (
  <Chip
    key={key}
    className={className}
    style={{
      pointerEvents: isDisabled || isReadOnly ? "none" : undefined,
      backgroundColor: isFocused ? blue[300] : undefined,
      textTransform: "none",
    }}
    onClick={handleClick}
    onDelete={handleDelete}
    label={<span style={{ textTransform: "none" }}>{text}</span>}
  />
);

const RaSelectArrayChipInput = ({
  updateField,
  record,
  source,
  label,
  notetext,
  formData,
  ...rest
}) => {
  const dataValue = formData
    ? get(formData, source, [])
    : get(record, source, []);
  const [chip, setChip] = React.useState(dataValue);
  const translate = useTranslate();
  const handleAddChip = (valueChip) => {
    const values = [...chip, valueChip];
    setChip(values);
    updateField(source, values);
  };
  const handleDeleteChip = (valueChip, index) => {
    const values = chip.filter((v) => v !== valueChip);
    // console.log(values)
    setChip(values);
    updateField(source, values);
  };
  return (
    <Box width={[1]}>
      <ChipInput
        label={label ? translate(label) : source}
        value={chip}
        onAdd={(chip) => handleAddChip(chip)}
        onDelete={(chip, index) => handleDeleteChip(chip, index)}
        {...rest}
        InputLabelProps={{ shrink: true }}
        chipRenderer={defaultChipRenderer}
      />
      <TextMessage notetext={notetext} />
    </Box>
  );
};
export default RaSelectArrayChipInput;
