import React from "react"
import { Radio, RadioGroup } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { Flex } from "rebass"
import { Title } from "../text"

export const Direction = ({ onChange, name, value, title }) => {
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      flexWrap={"wrap"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      {title && <Title title={title} />}
      <FormControl component="fieldset">
        <RadioGroup
          aria-label={name}
          name={name}
          value={value}
          onChange={onChange}
          row
        >
          <FormControlLabel
            value="incremental"
            label="Incremental"
            control={<Radio />}
          />
          <FormControlLabel
            value="decremental"
            label="Decremental"
            control={<Radio />}
          />
        </RadioGroup>
      </FormControl>
    </Flex>
  )
}
