import React from "react";
import { Box, Flex } from "rebass";
import {
  SwitchInput,
  TextInput,
  Title,
  SketchColorPicker,
} from "../../components";
import { get } from "lodash";
import { ContentDetail } from "../../actions";
import BoxPosition from "./BoxPosition";
import BoxText from "./BoxText";
import { BoxImageItem } from "./BoxImageItem";
import { ContainerImage, ContainerTitle } from "./ContainerPositionImageTitle";
import { UploadItemArray } from "../../../../components";
import ContainerLayout from "./ContainerLayout";
import { HrefContent } from "../../components";
// import BoxHref from "./BoxHref";
const ContentItemCarousel = (props) => {
  const {
    data,
    onUpdateBg,
    onTitle,
    onSubTitle,
    indexParent,
    updateImage,
    onSetItemValue,
    list,
    websiteId,
  } = props;
  return (
    <Box width={[1]}>
      <Box width={[1]} pb={[1]}>
        <TextInput
          value={data.name}
          onChange={(v) => onSetItemValue("name", v, indexParent)}
          name={`name-${indexParent}`}
          title='Name'
          style={{ width: 300 }}
        />
      </Box>
      <ContentDetail>
        <Title title={"Background"} />
        <SwitchInput
          id={`carousel-item-use-use-background-image-${indexParent}`}
          title={"use Background Image"}
          checked={data.bg.isBgImage}
          style={{ width: 100 }}
          onChange={(e) =>
            onUpdateBg("isBgImage", e.target.checked, indexParent)
          }
        />
        {data.bg.isBgImage ? (
          <Flex
            width={[1]}
            flexDirection={"row"}
            flexWrap={"wrap"}
            className='item-list'
          >
            <UploadItemArray
              type={`CarouselAnimationView-url_bg-${indexParent}`}
              imageUrl={list[indexParent].url_bg}
              onUpdateUrl={(value) => updateImage(value, "url_bg")}
              title='Background Desktop'
            />
            <UploadItemArray
              type={`CarouselAnimationView-url_bg_mobile-${indexParent}`}
              imageUrl={list[indexParent].url_bg_mobile}
              onUpdateUrl={(value) => updateImage(value, "url_bg_mobile")}
              title='Background Mobile'
            />
          </Flex>
        ) : (
          <Box width={"320px"}>
            <SketchColorPicker
              title={"Background Color"}
              onUpdateColor={(color) =>
                onUpdateBg("backgroundColor", color, indexParent)
              }
              color={data.bg.backgroundColor}
            />
          </Box>
        )}
      </ContentDetail>
      <ContentDetail>
        <Title title={"Position"} />
        <BoxPosition {...props} />
        <Title title={"Container Layout"} />
        <ContainerLayout {...props} />
        <Flex
          width={[1]}
          flexDirection={"row"}
          flexWrap={"wrap"}
          className='item-list'
        >
          <Box>
            <Title title={"Container Image"} pl={[2]} />
            <ContainerImage {...props} />
          </Box>
          <Box>
            <Title title={"Container Title"} pl={[2]} />
            <ContainerTitle {...props} />
          </Box>
        </Flex>
      </ContentDetail>
      <Flex width={[1]} flexDirection={"row"} flexWrap={"wrap"}>
        <ContentDetail style={{ marginRight: 8 }}>
          <Title title={"Title"} />
          <BoxText
            onUpdateValue={onTitle}
            data={data.title}
            index={indexParent}
            type={"Title"}
          />
        </ContentDetail>
        <ContentDetail>
          <Title title={"Subtitle"} />
          <BoxText
            onUpdateValue={onSubTitle}
            data={data.subtitle}
            index={indexParent}
            type={"Subtitle"}
          />
        </ContentDetail>
      </Flex>
      <ContentDetail>
        <Title title='Image' />
        <BoxImageItem {...props} />
      </ContentDetail>
      <ContentDetail>
        <HrefContent
          link={get(data, "link", {})}
          onSetValue={(k, v) => onSetItemValue(k, v, indexParent)}
          websiteId={websiteId}
        />
      </ContentDetail>
    </Box>
  );
};

export default ContentItemCarousel;
