import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { Box } from "rebass";
import { get, map } from "lodash";
import ItemCategory from "./ItemCategory";
import { useQueries, useQuery } from "react-query";
import { getProductById, RestApi } from "../../../../restClient";
import { defaultSetting, settingsList } from "./utils";
import HeaderList from "./HeaderList";
const cacheTimeMinute = 1000 * 60 * 30;
const MainField = (props) => {
  const refContainer = useRef(null);
  const [state, setState] = useState({ isIndex: 0, isEnd: false });
  const { screenWidth, desktop, colorProps, modeType, products, showPrice } =
    props;

  const settings = settingsList(
    get(desktop, "setting", defaultSetting),
    screenWidth,
    (index) => onChange(index)
  );
  const listProduct = map(products, function (o) {
    return {
      queryKey: ["products", o],
      queryFn: getProductById,
      queryOption: { cacheTime: cacheTimeMinute },
    };
  });
  const results = useQueries(listProduct);

  const onChange = (index) => {
    const listTags = get(refContainer, "current.props.children", []);
    const slidesToShow = get(desktop, "setting.slidesToShow", defaultSetting);
    const addIndex = slidesToShow; //screenWidth > 702 ? 6 : 3;
    const currentIndex = index + addIndex;
    let isEndCurrent = false;

    if (currentIndex >= listTags.length) {
      isEndCurrent = true;
    }
    setState({
      isIndex: index,
      isEnd: isEndCurrent,
    });
  };
  return (
    <Box width={[1]}>
      <HeaderList {...props} sliderone={refContainer.current} state={state} />
      <div style={{ backgroundColor: "transparent" }}>
        <Slider
          ref={refContainer}
          {...settings}
          style={{ backgroundColor: "transparent" }}
        >
          {results &&
            results.map((item, index) => {
              if (item.isLoading) {
                return <div key={index} />;
              }
              return (
                <ItemCategory
                  item={item.data}
                  key={index}
                  size={[1]}
                  screenWidth={screenWidth}
                  colorProps={colorProps}
                  desktop={desktop}
                  modeType={modeType}
                  showPrice={showPrice}
                />
              );
            })}
        </Slider>
      </div>
    </Box>
  );
};

export default (props) => {
  // const { data, isLoading } = useQuery(
  //   "collections",
  //   () => RestApi(`/collections/${props.collection}`).then((res) => res.json()),
  //   {
  //     cacheTime: cacheTimeMinute,
  //   }
  // );
  const { data, isLoading } = useQuery(
    "collections",
    () =>
      RestApi(`/products?collection=${props.collection}`).then((res) =>
        res.json()
      ),
    {
      cacheTime: cacheTimeMinute,
    }
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }
  const products = get(data, "products", []);
  return <MainField {...props} products={products} />;
};
