import React, { useState } from "react";
import PropTypes from "prop-types";
// import { has } from "lodash";
import { Flex } from "rebass";
import { FooterAction } from "../actions";
import { BoxContent } from "../styled";
// import { TextViewFields } from "./Fields";
import { defaultText } from "./utils";
import TextFields from "./TextFields";
import DialogEditContent from "./DialogEditContent";
import { get } from "immutable";
const TextView = (props) => {
  const { websiteId, data } = props;
  const {
    text,
    isLink,
    href,
    mobile,
    desktop,
    isShowDesktop,
    isShowMobile,
    link,
  } = data.values;

  const [state, setState] = useState({
    isLink: isLink,
    href: href,
    text: text,
    mobile: mobile || defaultText.mobile,
    desktop: desktop || defaultText.desktop,
    isShowDesktop: isShowDesktop,
    isShowMobile: isShowMobile,
    link: isLink
      ? {
          target:
            get(href, "target", "") === "same" ? "" : get(href, "target", ""),
          type: "URL",
          value: get(href, "url", ""),
          isLink: isLink,
        }
      : link,
  });

  const onSetValue = (k, v) => {
    setState({
      ...state,
      [k]: v,
    });
  };

  const onClickCopy = () => {
    let newData = props.data;
    newData.values.text = state.text;
    newData.values.link = state.link;
    newData.values.mobile = state.mobile;
    newData.values.desktop = state.desktop;
    newData.values.isShowDesktop = state.isShowDesktop;
    newData.values.isShowMobile = state.isShowMobile;
    localStorage.setItem("TextView", JSON.stringify(newData.values));
  };
  const onClickPaste = () => {
    const textViewF = JSON.parse(localStorage.getItem("TextView"));
    if (textViewF) {
      const {
        text,
        isLink,
        mobile,
        desktop,
        isShowDesktop,
        isShowMobile,
        link,
      } = textViewF;
      setState({
        ...state,
        text: text,
        isLink: isLink,
        link: link,
        mobile: mobile,
        desktop: desktop,
        isShowDesktop: isShowDesktop,
        isShowMobile: isShowMobile,
      });
    }
  };
  const onClickSave = () => {
    let newData = props.data;
    newData.values.text = state.text;
    newData.values.isShowDesktop = state.isShowDesktop;
    newData.values.isShowMobile = state.isShowMobile;
    newData.values.link = state.link;
    newData.values.mobile = state.mobile;
    newData.values.desktop = state.desktop;
    props.onUpdateData(newData);
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <BoxContent style={{ height: props.heightContent - 80 }}>
        <DialogEditContent
          item={state}
          onSetValue={(k, v) => onSetValue(k, v)}
        />
        <TextFields
          state={state}
          onSetValue={(k, v) => onSetValue(k, v)}
          heightContent={props.heightContent}
          websiteId={websiteId}
        />
      </BoxContent>
      <FooterAction
        onClickSave={onClickSave}
        onClickCopy={onClickCopy}
        onClickPaste={onClickPaste}
        widthContent={props.widthContent}
      />
    </Flex>
  );
};

TextView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

TextView.defaultProps = {
  data: {},
};
export default TextView;
