import React from "react";
import Slider from "react-slick";
import { Box, Flex } from "rebass";
import ContainerDimensions from "react-container-dimensions";
import { connect } from "../../../core";

var settings = {
  dots: false,
  arrows: false,
  infinite: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 3000,
  cssEase: "linear",
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  adaptiveHeight: true,
};

const TextGroupView = (props) => {
  const { id, layoutState, widthScreen } = props;
  const {
    containerPadding,
    valueText,
    backgroundColor,
    align,
    setting,
    fontSize,
    lineHeight,
    letterSpacing,
    color,
  } = layoutState.getItemJS(id).props.values;
  const newSetting = setting ? setting : settings;
  let newalign = align === "center" ? align : "center";
  if (newalign === "flex-end") {
    newalign = "right";
  } else if (newalign === "flex-start") {
    newalign = "left";
  }

  return (
    <div style={{ width: "100%" }}>
      <ContainerDimensions>
        {({ width }) => {
          const myWidth =
            widthScreen > 480 ? (width > 0 ? width : widthScreen - 30) : 316;
          return (
            <Box
              width={[myWidth]}
              style={{
                backgroundColor: backgroundColor,
                padding: containerPadding,
              }}
              mt={[widthScreen > 480 ? 0 : "-8px"]}
            >
              <div style={{ textAlign: newalign }}>
                <Slider {...newSetting}>
                  {valueText &&
                    valueText.length > 0 &&
                    valueText.map((item, index) => {
                      // const valueDefault =

                      return (
                        <div
                          key={index}
                          style={{
                            lineHeight,
                            letterSpacing,
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            flex: 1,
                            justifyContent: align,
                          }}
                        >
                          <Flex
                            width={[1]}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDirection={"row"}
                          >
                            {item.icon &&
                            item.icon !== "" &&
                            item.iconPosition === "flex-start" ? (
                              <img
                                src={item.icon}
                                width='16px'
                                alt='text group'
                              />
                            ) : (
                              ""
                            )}

                            <span
                              style={{
                                color: color,
                                paddingLeft: 8,
                                paddingRight: 8,
                                fontSize:
                                  widthScreen > 480
                                    ? `${fontSize.desktop}pt`
                                    : `${fontSize.mobile}pt`,
                              }}
                            >
                              {item.title}
                            </span>
                            {item.icon &&
                            item.icon !== "" &&
                            item.iconPosition === "flex-end" ? (
                              <img
                                src={item.icon}
                                width='16px'
                                alt='text group'
                              />
                            ) : (
                              ""
                            )}
                          </Flex>
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </Box>
          );
        }}
      </ContainerDimensions>
    </div>
  );
};
/**
 *  <Text fontSize={[fontSize.mobile, fontSize.desktop]}>
                          </Text> 
                        //   <Box
                        //   width={[1]}
                         
                        //   style={{ padding: containerPadding }}
                        // >
                        // </Box>
 */

export default connect("layoutState", "widthScreen")(TextGroupView);
