import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "@material-ui/core/styles"
import {
  useReferenceArrayInputController,
  useInput,
  useTranslate,
  LinearProgress,
  Labeled,
} from "react-admin"

import { Box } from "rebass"
import TextMessage from "./utils"
import ReferenceError from "./ReferenceError"
import { sanitizeRestPropsReferenceArray } from "./defaultProps"
import { themeMui } from "../../themes"

const RaReferenceArrayInput = ({
  children,
  id: idOverride,
  onBlur,
  onChange,
  onFocus,
  validate,
  ...props
}) => {
  if (props.resource === undefined) {
    props.resource = props.resource1
  }
  if (React.Children.count(children) !== 1) {
    throw new Error(
      "<ReferenceArrayInput> only accepts a single child (like <Datagrid>)"
    )
  }

  const { id, input, isRequired, meta } = useInput({
    id: idOverride,
    onBlur,
    onChange,
    onFocus,
    source: props.source,
    validate,
  })

  const controllerProps = useReferenceArrayInputController({
    ...props,
    input,
  })

  const translate = useTranslate()

  return (
    <ThemeProvider theme={themeMui}>
      <ReferenceArrayInputView
        id={id}
        input={input}
        isRequired={isRequired}
        meta={meta}
        translate={translate}
        children={children}
        {...props}
        {...controllerProps}
      />
    </ThemeProvider>
  )
}

RaReferenceArrayInput.propTypes = {
  allowEmpty: PropTypes.bool.isRequired,
  basePath: PropTypes.string,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  filter: PropTypes.object,
  filterToQuery: PropTypes.func.isRequired,
  label: PropTypes.string,
  perPage: PropTypes.number,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.oneOf(["ASC", "DESC"]),
  }),
  source: PropTypes.string,
  notetext: PropTypes.string,
}

RaReferenceArrayInput.defaultProps = {
  allowEmpty: false,
  filter: {},
  filterToQuery: searchText => ({ q: searchText }),
  perPage: 25,
  sort: { field: "id", order: "DESC" },
  notetext: "",
}

export const ReferenceArrayInputView = ({
  allowEmpty,
  basePath,
  children,
  choices,
  className,
  error,
  input,
  loading,
  isRequired,
  label,
  meta,
  onChange,
  options,
  resource,
  setFilter,
  setPagination,
  setSort,
  source,
  translate,
  warning,
  notetext,
  ...rest
}) => {
  const translatedLabel = translate(
    label || `resources.${resource}.fields.${source}`,
    { _: label }
  )

  if (loading) {
    return (
      <Labeled
        label={translatedLabel}
        source={source}
        resource={resource}
        className={className}
        isRequired={isRequired}
      >
        <LinearProgress />
      </Labeled>
    )
  }

  if (error) {
    return <ReferenceError label={translatedLabel} error={error} />
  }

  return (
    <div>
      {React.cloneElement(children, {
        allowEmpty,
        basePath,
        choices,
        className,
        error,
        input,
        isRequired,
        label: translatedLabel,
        meta: {
          ...meta,
          helperText: warning || false,
        },
        onChange,
        options,
        resource,
        setFilter,
        setPagination,
        setSort,
        source,
        translateChoice: false,
        limitChoicesToValue: true,
        ...sanitizeRestPropsReferenceArray(rest),
        ...children.props,
      })}
      <Box>
        <TextMessage notetext={notetext} />
      </Box>
    </div>
  )
}

ReferenceArrayInputView.propTypes = {
  allowEmpty: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.element,
  choices: PropTypes.array,
  className: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.object,
  resource: PropTypes.string.isRequired,
  setFilter: PropTypes.func,
  setPagination: PropTypes.func,
  setSort: PropTypes.func,
  source: PropTypes.string,
  translate: PropTypes.func.isRequired,
  warning: PropTypes.string,
}

export default RaReferenceArrayInput
