import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { Flex } from "rebass";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default (props) => {
  const { children, open, setOpen } = props;
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      style={{ zIndex: 1000 }}
      TransitionComponent={Transition}
    >
      <Flex
        width={[1]}
        flexDirection={"column"}
        px={[2]}
        style={{ position: "absolute" }}
      >
        {children}
      </Flex>
    </Dialog>
  );
};
