import React from "react";
import ReactDOM from "react-dom";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "antd/dist/antd.css";
import "./index.css";
import "./spinkit.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

function AppMain() {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <App />
    </MuiPickersUtilsProvider>
  );
}

ReactDOM.render(<AppMain />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
