import React from "react";
import { showNotification, useQueryWithStore } from "react-admin";
import { withRouter } from "react-router-dom";
import { Box } from "rebass";
import { get } from "lodash";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { FormFields } from "./components";
import { FullLoadingScreen, CardPanel } from "../../components";

const ShippingsEdit = (props) => {
  const { match } = props;
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "packagings",
    payload: { id: get(match, "params.id", 0) },
  });

  if (loading) {
    return <FullLoadingScreen />;
  }

  return (
    <CardPanel>
      <Box width={[1]}>
        <FormFields
          {...props}
          title={"resources.shippings.edit_title"}
          sourceApi="packagings"
          typePostUrl={"UPDATE"}
          pathUrl="shipping"
          initialValues={data}
        />
      </Box>
    </CardPanel>
  );
};

const enhance = compose(withRouter, connect(undefined, { showNotification }));

export default enhance(ShippingsEdit);
