export function cssToJS(str) {
  // const lines = str.split("px").filter((line) => line.trim());
  const lines =
    str && str !== "" ? str.split("px").filter((line) => line.trim()) : "";
  const myKey = ["top", "right", "bottom", "left"];
  const result = {};

  if (lines.length === 1) {
    for (let i = 0; i < myKey.length; i++) {
      const key = myKey[i];
      result[key] = !isNaN(lines[0]) ? lines[0] * 1 : 0;
    }
  } else {
    for (let i = 0; i < myKey.length; i++) {
      const key = myKey[i];
      if (i < lines.length) {
        result[key] = !isNaN(lines[i]) ? lines[i] * 1 : 0;
      } else {
        result[key] = 0;
      }
    }
  }
  return result;
}

export function getSize(str) {
  const lines =
    str && str !== "" ? str.split("px").filter((line) => line.trim()) : "";
  return lines.length > 1 ? true : false;
}

export function setValue(top, right, bottom, left) {
  let padding = top + "px " + right + "px " + bottom + "px " + left + "px";
  return padding;
}
