import React, { useRef } from "react"
import { get } from "lodash"
import Slider from "react-slick"
import { Flex, Image, Text } from "rebass"
import { Button } from "@material-ui/core"
import "./slick.css"

const ProductGroupCarousel = (props) => {
  const ref = useRef(null)
  const { data, widthContainer } = props

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 200,
    centerPadding: "16px",
    slidesToShow: 2,
    slidesToScroll: 4,
    adaptiveHeight: true,
    rows: 2,
    slidesPerRow: 2,
    autoplay: false,
    autoplaySpeed: false,
  }

  const products = get(data, "products", [])
  return (
    <div style={{ backgroundColor: "transparent", width: widthContainer }}>
      <Slider
        ref={ref}
        {...settings}
        style={{ backgroundColor: "tranparent", width: widthContainer }}
      >
        {products.map((item, index) => {
          return (
            <Flex
              key={index}
              px={[2]}
              py={[1]}
              width={[1]}
              flexDirection={"column"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Image src={item.photos} p={[1]} />
              <Text
                fontSize={[1, 2]}
                fontWeight={"bold"}
                textAlign={"center"}
                p={[2]}
              >
                {item.name}
              </Text>
              <Flex width={[1]} flexDirection={"row"} justifyContent={"center"}>
                <Button variant="outlined" color={"primary"}>
                  Shop now
                </Button>
              </Flex>
            </Flex>
          )
        })}
      </Slider>
    </div>
  )
}
export default ProductGroupCarousel
