import React from "react"
import { Text } from "rebass"
export { default as TextGroup } from "./TextGroup"

export const Title = ({ title, fontSize, fontWeight, ...rest }) => {
  return (
    <Text
      fontSize={fontSize || "0.85rem"}
      {...rest}
      fontWeight={fontWeight || "bold"}
    >
      {title}
    </Text>
  )
}
