import React, { Component } from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import { UploadAvatar } from "../../../components";
import {
  BlockCounter,
  Title,
  SelectInput,
  TextInput,
  SketchColorPicker,
  ContainerContent,
  SwitchInputContent,
} from "../components";
import { dataProvider } from "../../../../restClient";
import { BoxContent } from "../styled";
import {
  FooterAction,
  PanelCard,
  ContentDetail,
  ContainerPanel,
} from "../actions";
// import DialogListMenu from "./DialogListMenu";

const dataModelHeader = [
  { value: 0, text: "Model #1" },
  { value: 1, text: "Model #2" },
  { value: 2, text: "Model #3" },
  { value: 3, text: "Model #4" },
  { value: 4, text: "Model #Atmos" },
  { value: 5, text: "Model No Menu" },
];

const defaultContainerLogo = {
  isAutoWidth: [false, false],
  isAutoHeight: [false, false],
  width: ["100%", "100%"],
  height: ["100%", "100%"],
};
const defaultIsShowMenu = { isShow: [true, true] };

class MenuView extends Component {
  constructor(props) {
    super(props);
    // console.log(props)
    const {
      containerPadding,
      list,
      logo,
      modelHeader,
      color,
      isShowMenu,
      containerLogo,
    } = props.data.values;
    this.state = {
      containerPadding: containerPadding,
      list: list,
      logo: logo,
      modelHeader: modelHeader,
      categories: [],
      heightContent: props.heightContent,
      widthContent: props.widthContent,
      color: color || "#000000",
      titleButton: get(props, "data.values.titleButton", ""),
      containerLogo: containerLogo || defaultContainerLogo,
      isShowMenu: isShowMenu || defaultIsShowMenu,
    };
    this.getCategory();
  }

  getCategory = () => {
    dataProvider
      .getList("categories", {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: "name", order: "ASC" },
        filter: {
          website_id: this.props.websiteId === 0 ? 3 : this.props.websiteId,
        },
      })
      .then((items) => {
        if (items && items.data) {
          this.setState({ categories: items.data });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };
  onSetValue = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value,
    });
  };
  onClickSave = () => {
    let newData = this.props.data;
    newData.values.list = this.state.list;
    newData.values.logo = this.state.logo;
    newData.values.color = this.state.color;
    newData.values.containerLogo = this.state.containerLogo;
    newData.values.isShowMenu = this.state.isShowMenu;
    newData.values.modelHeader = this.state.modelHeader;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values["titleButton"] = this.state.titleButton;
    // console.log(newData)
    this.props.onUpdateData(newData);
  };
  onClickCopy = () => {
    let newData = this.props.data;
    newData.values.list = this.state.list;
    newData.values.logo = this.state.logo;
    newData.values.color = this.state.color;
    newData.values.containerLogo = this.state.containerLogo;
    newData.values.isShowMenu = this.state.isShowMenu;
    newData.values.modelHeader = this.state.modelHeader;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values["titleButton"] = this.state.titleButton;
    localStorage.setItem("MenuView", JSON.stringify(newData.values));
  };
  onClickPaste = () => {
    const imageContent = JSON.parse(localStorage.getItem("MenuView"));
    if (imageContent) {
      const {
        containerPadding,
        list,
        logo,
        modelHeader,
        titleButton,
        color,
        containerLogo,
        isShowMenu,
      } = imageContent;
      this.setState({
        containerPadding: containerPadding,
        list: list,
        logo: logo,
        color: color || "#000000",
        modelHeader: modelHeader,
        titleButton: titleButton,
        containerLogo: containerLogo || defaultContainerLogo,
        isShowMenu: isShowMenu || defaultIsShowMenu,
      });
    }
  };

  render() {
    const { logo, heightContent, modelHeader } = this.state;
    return (
      <Flex width={[1]} flexDirection={"column"}>
        <BoxContent style={{ height: heightContent - 120 }}>
          <ContainerPanel>
            <PanelCard title='General' id='menu-content'>
              <ContentDetail>
                <SwitchInputContent
                  value={this.state.isShowMenu}
                  onUpdate={(key, value) => this.onSetValue(key, value)}
                  keyContent={"isShowMenu"}
                  title={"Setting Show Menu"}
                />
              </ContentDetail>
              <ContentDetail>
                <Title title='Upload Logo' />
                <UploadAvatar
                  type={"Logo"}
                  imageUrl={logo}
                  onUpdateUrl={(value) => this.onSetValue("logo", value)}
                  title='Logo'
                  isSquare={true}
                />
                <ContainerContent
                  keyContent={"containerLogo"}
                  container={this.state.containerLogo}
                  onUpdate={(key, value) => this.onSetValue(key, value)}
                />
              </ContentDetail>
              <ContentDetail>
                <SelectInput
                  id={`type-flat-button`}
                  title={"Model Header Menu"}
                  value={modelHeader}
                  list={dataModelHeader}
                  onChange={(e) =>
                    this.onSetValue("modelHeader", e.target.value)
                  }
                />
                <TextInput
                  value={this.state.titleButton}
                  onChange={(value) => this.onSetValue("titleButton", value)}
                  name='titleButton'
                  title='Title Button'
                  fullWidth={true}
                />
                <SketchColorPicker
                  title={"Color"}
                  onUpdateColor={(color) => this.onSetValue("color", color)}
                  color={this.state.color}
                />
              </ContentDetail>
              <BlockCounter
                title='Container Padding'
                containerPadding={this.state.containerPadding}
                onUpdatePadding={(cp) =>
                  this.onSetValue("containerPadding", cp)
                }
              />
            </PanelCard>
            {/* <DialogListMenu
              list={this.state.list}
              onSetValue={this.onSetValue}
              categories={this.state.categories}
            /> */}
          </ContainerPanel>
        </BoxContent>
        <FooterAction
          onClickSave={this.onClickSave}
          onClickCopy={this.onClickCopy}
          onClickPaste={this.onClickPaste}
          widthContent={this.state.widthContent}
        />
      </Flex>
    );
  }
}

export default MenuView;
