import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text } from "rebass";
import { get } from "lodash";
import SwitchInput from "./SwitchInput";

const SwitchInputView = (props) => {
  const { value, keyContent, onUpdate, title, keyValue } = props;
  const [state, setState] = useState(value);
  const onChangeValue = (k, v, index) => {
    let myValue = state[k];

    if (state[k]) {
      if (myValue[index]) {
        myValue[index] = v;
      } else {
        if (myValue[index] === "") {
          myValue[index] = v;
        } else {
          if (typeof v === "number") {
            myValue = [1, 1];
            myValue[index] = v;
          } else if (typeof v === "boolean") {
            myValue = [true, true];
            myValue[index] = v;
          } else {
            myValue = ["", ""];
            myValue[index] = v;
          }
        }
      }
    } else {
      if (typeof v === "number") {
        myValue = [1, 1];
        myValue[index] = v;
      } else if (typeof v === "boolean") {
        myValue = [true, true];
        myValue[index] = v;
      } else {
        myValue = ["", ""];
        myValue[index] = v;
      }
    }

    const params = { ...state, [k]: myValue };
    setState(params);
    onUpdate(keyContent, params);
  };

  return (
    <Box width={[1]}>
      <Text fontWeight={"bold"} pb={[2]}>
        {title}
      </Text>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"space-between"}>
        <SwitchInput
          id={`show-width-desktop-${keyContent}`}
          title={"Desktop"}
          checked={get(state, `${keyValue}[1]`, true)}
          onChange={(e) => onChangeValue(keyValue, e.target.checked, 1)}
        />
        <SwitchInput
          id={`isShow-width-mobile-${keyContent}`}
          title={"Mobile"}
          checked={get(state, `${keyValue}[0]`, true)}
          onChange={(e) => onChangeValue(keyValue, e.target.checked, 0)}
        />
      </Flex>
    </Box>
  );
};

SwitchInputView.propTypes = {
  value: PropTypes.object,
  title: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  keyContent: PropTypes.string.isRequired,
  keyValue: PropTypes.string.isRequired,
};

SwitchInputView.defaultProps = {
  title: "Setting Show",
  keyContent: "show",
  keyValue: "isShow",
};

export default SwitchInputView;
