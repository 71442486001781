import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Media from "./Media"

const Image = styled.img`
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`

class ResponsiveImage extends Component {
  render() {
    let srcMobile =
      this.props.srcMobile ||
      this.props.srcDesktop ||
      this.props.src ||
      "https://via.placeholder.com/940x400?text=CMS"
    let srcDesktop =
      this.props.srcDesktop ||
      this.props.srcMobile ||
      this.props.src ||
      "https://via.placeholder.com/940x400?text=CMS"

    return (
      <div>
        <Media query="(max-width: 768px)">
          {matches =>
            matches ? (
              <Image src={srcMobile} alt={this.props.alt} />
            ) : (
              <Image src={srcDesktop} alt={this.props.alt} />
            )
          }
        </Media>
      </div>
    )
  }
}

ResponsiveImage.propTypes = {
  src: PropTypes.string,
  srcMobile: PropTypes.string,
  srcDesktop: PropTypes.string,
  alt: PropTypes.string,
}
ResponsiveImage.defaultProps = {
  alt: "Image",
}

export default ResponsiveImage
