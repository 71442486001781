import React, { Children, cloneElement, useRef, isValidElement } from "react"
import PropTypes from "prop-types"
import Box from "@material-ui/core/Box"
import { get } from "lodash"

import Button from "@material-ui/core/Button"
import FormHelperText from "@material-ui/core/FormHelperText"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Delete"
import AddIcon from "@material-ui/icons/AddCircleOutline"

import classNames from "classnames"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import IconButton from "@material-ui/core/IconButton"

import { FormInput, useTranslate } from "react-admin"

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
      marginBottom: 0,
      "& > li:last-child": {
        borderBottom: "none",
      },
    },
    line: {
      display: "flex",
      listStyleType: "none",
      borderBottom: `solid 1px ${theme.palette.divider}`,
      [theme.breakpoints.down("xs")]: { display: "block" },
      "&.fade-enter": {
        opacity: 0.01,
        transform: "translateX(100vw)",
      },
      "&.fade-enter-active": {
        opacity: 1,
        transform: "translateX(0)",
        transition: "all 500ms ease-in",
      },
      "&.fade-exit": {
        opacity: 1,
        transform: "translateX(0)",
      },
      "&.fade-exit-active": {
        opacity: 0.01,
        transform: "translateX(100vw)",
        transition: "all 500ms ease-in",
      },
    },
    index: {
      width: "3em",
      paddingTop: "1em",
      [theme.breakpoints.down("sm")]: { display: "none" },
    },
    form: { flex: 2 },
    action: {
      paddingTop: "0.5em",
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: "TableFormIterator" }
)

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#cecece",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)
const StyledTableAction = withStyles((theme) => ({
  head: {
    backgroundColor: "#cecece",
    color: theme.palette.common.black,
    width: 40,
    padding: 0,
  },
  body: {
    fontSize: 14,
    width: 40,
    padding: 0,
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow)

const TableFormIterator = (props) => {
  const {
    basePath,
    children,
    fields,
    meta: { error, submitFailed },
    record,
    resource,
    source,
    disableAdd,
    disableRemove,
    variant,
    margin,
    titleAdd,
    defaultValue,
  } = props
  const translate = useTranslate()
  const classes = useStyles(props)

  // We need a unique id for each field for a proper enter/exit animation
  // so we keep an internal map between the field position and an auto-increment id
  const nextId = useRef(
    fields && fields.length
      ? fields.length
      : defaultValue
      ? defaultValue.length
      : 0
  )

  // We check whether we have a defaultValue (which must be an array) before checking
  // the fields prop which will always be empty for a new record.
  // Without it, our ids wouldn't match the default value and we would get key warnings
  // on the CssTransition element inside our render method
  const ids = useRef(
    nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []
  )

  const removeField = (index) => () => {
    ids.current.splice(index, 1)
    fields.remove(index)
  }

  // Returns a boolean to indicate whether to disable the remove button for certain fields.
  // If disableRemove is a function, then call the function with the current record to
  // determining if the button should be disabled. Otherwise, use a boolean property that
  // enables or disables the button for all of the fields.
  const disableRemoveField = (record, disableRemove) => {
    if (typeof disableRemove === "boolean") {
      return disableRemove
    }
    return disableRemove && disableRemove(record)
  }

  const addField = () => {
    ids.current.push(nextId.current++)
    fields.push(undefined)
  }

  const records = get(record, source)
  return fields ? (
    <Box>
      {submitFailed && typeof error !== "object" && error && (
        <FormHelperText error>{error}</FormHelperText>
      )}
      <TableContainer component={"div"}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {fields.map((member, index) => {
                return (
                  index === 0 &&
                  Children.map(children, (input, index2) =>
                    isValidElement(input) ? (
                      <StyledTableCell key={index}>
                        {typeof input.props.label === "undefined"
                          ? input.props.source
                            ? translate(
                                `resources.${resource}.fields.${input.props.source}`
                              )
                            : undefined
                          : translate(input.props.label)}
                      </StyledTableCell>
                    ) : null
                  )
                )
              })}
              {!disableRemove && <StyledTableAction />}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((member, index) => (
              <StyledTableRow key={index}>
                {Children.map(children, (input, index2) =>
                  isValidElement(input) ? (
                    <StyledTableCell component="td" scope="row">
                      <FormInput
                        basePath={input.props.basePath || basePath}
                        input={cloneElement(input, {
                          source: input.props.source
                            ? `${member}.${input.props.source}`
                            : member,
                          index: input.props.source ? undefined : index2,
                          label: "",
                        })}
                        record={(records && records[index]) || {}}
                        resource={resource}
                        variant={variant}
                        margin={margin}
                      />
                    </StyledTableCell>
                  ) : null
                )}

                {!disableRemoveField(
                  (records && records[index]) || {},
                  disableRemove
                ) && (
                  <StyledTableAction>
                    <IconButton
                      onClick={removeField(index)}
                      className={classes.leftIcon}
                    >
                      <CloseIcon />
                    </IconButton>
                  </StyledTableAction>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!disableAdd && (
        <Box>
          <span className={classes.action}>
            <Button
              className={classNames("button-add", `button-add-${source}`)}
              size="small"
              color={"primary"}
              onClick={() => addField()}
            >
              <AddIcon className={classes.leftIcon} />
              {translate(titleAdd)}
            </Button>
          </span>
        </Box>
      )}
    </Box>
  ) : null
}

TableFormIterator.defaultProps = {
  disableAdd: false,
  disableRemove: false,
}

TableFormIterator.propTypes = {
  defaultValue: PropTypes.any,
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  meta: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  titleAdd: PropTypes.string,
  disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
}
export default TableFormIterator
// export default compose(translate, withStyles(styles))(TableFormIterator)
