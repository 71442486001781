import React from "react"
import { Flex, Image, Text } from "rebass"
import { Button } from "@material-ui/core"
const ProductGroupCard = (props) => {
  const { product } = props
  return (
    <Flex flexDirection={"column"}>
      <Image src={product.photos} p={[1]} />
      <Text fontSize={[1, 2]} fontWeight={"bold"} textAlign={"center"} p={[2]}>
        {product.name}
      </Text>

      <Button variant="outlined" color={"primary"}>
        Shop now
      </Button>
    </Flex>
  )
}
export default ProductGroupCard
