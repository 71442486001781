import React from "react";
import { Box } from "rebass";
import { ContentDetail } from "../../actions";
import { UploadImage } from "../../../../components";
import { Title, SelectInput } from "../";
export const BackgroundImage = (props) => {
  const {
    state,
    onChangeBackgroundImageProperties,
    onSetValue,
    contentId,
  } = props;
  return (
    <Box width={[1]}>
      <ContentDetail>
        <SelectInput
          id={`size${contentId}`}
          title={"Size"}
          value={state.bgProps.size}
          list={datasize}
          onChange={(e, value) =>
            onChangeBackgroundImageProperties(e, value, "size")
          }
        />
      </ContentDetail>
      <ContentDetail>
        <SelectInput
          id={`position${contentId}`}
          title={"Position"}
          value={state.bgProps.position}
          list={dataImagePosition}
          onChange={(e, value) =>
            onChangeBackgroundImageProperties(e, value, "position")
          }
        />
      </ContentDetail>
      <ContentDetail>
        <SelectInput
          id={`repeat${contentId}`}
          title={"Repeat"}
          value={state.bgProps.repeat}
          list={datarepeat}
          onChange={(e, value) =>
            onChangeBackgroundImageProperties(e, value, "repeat")
          }
        />
      </ContentDetail>
      <ContentDetail>
        <SelectInput
          id={`ratio${contentId}`}
          title={"Ratio"}
          value={state.bgProps.ratio}
          list={dataRatio}
          onChange={(e, value) =>
            onChangeBackgroundImageProperties(e, value, "ratio")
          }
        />
      </ContentDetail>
      <ContentDetail>
        <Title title={"Background Image"} />
        <UploadImage
          type={"ease"}
          imageUrl={state.backgroundImage}
          onUpdateUrl={(value) => onSetValue("backgroundImage", value)}
          title='Image'
        />
      </ContentDetail>
    </Box>
  );
};

export const dataRatio = [
  { value: 1.2, text: "1.2" },
  { value: 1.3, text: "1.3" },
  { value: 1.4, text: "1.4" },
  { value: 1.5, text: "1.5" },
  { value: 1.6, text: "1.6" },
  { value: 1.7, text: "1.7" },
  { value: 1.8, text: "1.8" },
  { value: 1.9, text: "1.9" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
];
export const datasize = [
  { text: "Contain", value: "contain" },
  { text: "Cover", value: "cover" },
];
export const datarepeat = [
  { value: "no-repeat", text: "No Repeat" },
  { value: "repeat", text: "Repeat" },
];
export const dataImagePosition = [
  { value: "left", text: "Left" },
  { value: "center", text: "Center" },
  { value: "right", text: "Right" },
];
