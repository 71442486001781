import React from "react"
import { useTranslate } from "react-admin"
import { Box, Flex } from "rebass"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { RaTextInput, RaNumberInput } from "../../../components"
import { useStyles } from "./utils"

const Conditions = (props) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="conditions-payment-fields-content"
          id="conditions-payment-fields-header"
        >
          <Typography className={classes.heading}>
            {translate("resources.payments.label.conditions")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Flex width={[1]} flexDirection={"column"}>
            <Box width={[1]}>
              <RaTextInput
                source="conditions.supported_countries"
                label={"resources.payments.fields.supported_countries"}
                style={{ width: 500 }}
                notetext={"resources.payments.note.supported_countries"}
              />
            </Box>
            <Box width={[1]} py={[2]}>
              <RaNumberInput
                source="conditions.minimum_subtotal"
                label={"resources.payments.fields.minimum_subtotal"}
                notetext={"resources.payments.note.minimum_subtotal"}
                style={{ width: 100 }}
              />
            </Box>
            <Box width={[1]}>
              <RaNumberInput
                source="conditions.maximum_subtotal"
                label={"resources.payments.fields.maximum_subtotal"}
                notetext={"resources.payments.note.maximum_subtotal"}
                style={{ width: 100 }}
              />
            </Box>
          </Flex>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default Conditions
