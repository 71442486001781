import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout6 } from "../RowBox";
import { DivHover, DivHelp } from "./styled";

const Row6 = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='Row' />
      {hover && <DivHelp>Row</DivHelp>}
    </DivHover>
  );
};

Row6.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};

Row6.defaultProps = {
  childOf: "Row",
  values: {
    parent: "Body",
    type: "Row",
    desktop: {
      column: 1,
      show: true,
      padding: "0px",
      backgroundColor: "rgba(255,255,255,0)",
      width: "100%",
      maxWidth: "100%",
      height: "100%",
      layout: {
        align: "middle",
        gutter: 0,
        justify: "center",
        wrap: false,
        contentAlign: "center",
        contentJustify: "center",
      },
    },
    mobile: {
      show: false,
    },
  },
  content: [
    {
      type: "Content",
      props: {
        values: {
          parent: "Row",
          type: "Content",
          desktop: {
            show: true,
            padding: "0px",
            backgroundColor: "rgba(255,255,255,0)",
            width: "100%",
            height: "100%",
            layout: {
              span: 6,
              order: 1,
            },
          },
          mobile: {
            show: false,
          },
        },
      },
      children: [],
      childOf: "Row",
    },
    {
      type: "Content",
      props: {
        values: {
          parent: "Row",
          type: "Content",
          desktop: {
            show: true,
            padding: "0px",
            backgroundColor: "rgba(255,255,255,0)",
            width: "100%",
            height: "100%",
            layout: {
              span: 6,
              order: 2,
            },
          },
          mobile: {
            show: false,
          },
        },
      },
      children: [],
      childOf: "Row",
    },
    {
      type: "Content",
      props: {
        values: {
          parent: "Row",
          type: "Content",
          desktop: {
            show: true,
            padding: "0px",
            backgroundColor: "rgba(255,255,255,0)",
            width: "100%",
            height: "100%",
            layout: {
              span: 6,
              order: 3,
            },
          },
          mobile: {
            show: false,
          },
        },
      },
      children: [],
      childOf: "Row",
    },
    {
      type: "Content",
      props: {
        values: {
          parent: "Row",
          type: "Content",
          desktop: {
            show: true,
            padding: "0px",
            backgroundColor: "rgba(255,255,255,0)",
            width: "100%",
            height: "100%",
            layout: {
              span: 6,
              order: 4,
            },
          },
          mobile: {
            show: false,
          },
        },
      },
      children: [],
      childOf: "Row",
    },
  ],
  layout: Layout6,
};
export default Row6;
