const attributes = {
  attributes: {
    name: "Attributes",
    back: "Back to Attributes",
    new_attributes: "New Attributes",
    edit_attributes: "Edit Attributes",
    fields: {
      site_id: "Site",
      name: "Name",
      type: "Data type",
      data_type: "Data type",
      code: "Code",
      unit: "Unit",
      value: "Value",
      description: "Description",
    },
    note: {
      code: "This is used for system internally. Don’t use spaces",
      value:
        "The numeric value to represent this attribute. For example, put 18 for 18 MP camera resolution. If product has multiple of same attribute, use the most significant value",
      unit: "For example cm, mm, inch, mAh, GB, MP",
    },
  },
}

export default attributes
