import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import {
  useTranslate,
  withDataProvider,
  Loading,
  useNotify,
} from "react-admin";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { useLocation } from "react-router-dom";
import { MainFields } from "./components/amendment_item";
import { useQuery } from "../../components";
const CreateAmendment = (props) => {
  const { match } = props;

  // console.log(props);
  const notify = useNotify();
  const orderId = get(match, "params.id", 0);
  const translate = useTranslate();
  const location = useLocation();
  // const [loading, setLoading] = useState(true);
  // const [record, setRecord] = useState({});

  const { loading, data } = useQuery(`/orders/${orderId}`);

  if (loading) {
    return (
      <Flex
        width={[1]}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Loading />
      </Flex>
    );
  }

  const list = get(data, "items", []);
  return (
    <MainFields
      {...props}
      record={data}
      translate={translate}
      location={location}
      notify={notify}
      listItem={list}
      orderId={orderId}
    />
  );
};
const enhance = compose(withRouter, connect(undefined, {}));

export default enhance(withDataProvider(CreateAmendment));
