import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout1 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const TextView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='TextView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

TextView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};

TextView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "TextView",
    isShowDesktop: true,
    isShowMobile: true,
    mobile: {
      backgroundColor: "rgba(255,255,255,0)",
      color: "rgba(0,0,0,1)",
      lineHeight: "120%",
      letterSpacing: "1px",
      fontSize: 10,
      containerPadding: "0px",
      textAlign: "left",
      isFullHeight: false,
    },
    desktop: {
      backgroundColor: "rgba(255,255,255,0)",
      color: "rgba(0,0,0,1)",
      lineHeight: "120%",
      letterSpacing: "1px",
      fontSize: 10,
      containerPadding: "0px",
      textAlign: "left",
      isFullHeight: false,
    },
    text:
      "<div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>",
    link: {
      target: "",
      type: "URL",
      value: "",
      isLink: false,
    },
  },
  layout: Layout1,
};
export default TextView;
