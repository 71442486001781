export default {
  members: {
    name: "Members",
    fields: {
      active: "Active",
      email: "Email",
      phone: "Phone number",
      first_name: "First name",
      last_name: "Last name",
      tags: "Tags",
      created_at: "Create On",
      reference_id: "Reference ID",
    },
  },
}
