import React, { Fragment } from "react";
//, useState, useEffect
import { get } from "lodash";
import { Loading } from "react-admin";
import { Flex } from "rebass";
import CancelShipmentDetail from "./CancelShipmentDetail";
import ListOrderItems from "./ListOrderItems";
import { useQuery } from "../../../../components";
const MainFields = (props) => {
  const { order } = props;
  // /dataProvider
  const websiteId = get(order, "website_id", 0);
  // const [loading, setLoading] = useState(true);
  // const [website, setWebsite] = useState({});
  const { loading, data } = useQuery(`/websites/${websiteId}`);
  // useEffect(() => {
  //   setLoading(true);
  //   const timer = setTimeout(() => {
  //     dataProvider
  //       .getOne(`websites`, { id: websiteId })
  //       .then(({ data }) => {
  //         // console.log(data);
  //         if (data) {
  //           setWebsite(data);
  //         }
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //       });
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // }, [websiteId, dataProvider]);
  if (loading) {
    return (
      <Flex
        width={[1]}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Loading />
      </Flex>
    );
  }
  return (
    <Fragment>
      <CancelShipmentDetail {...props} website={data} />
      <ListOrderItems {...props} website={data} />
    </Fragment>
  );
};

const CancelFormFields = (props) => {
  const { match } = props;
  //dataProvider,
  const orderId = get(match, "params.order_id", 0);
  // const [loading, setLoading] = useState(true);
  // const [order, setOrder] = useState({});
  const { loading, data } = useQuery(`/orders/${orderId}`);
  // console.log(props);
  // useEffect(() => {
  //   setLoading(true);
  //   const timer = setTimeout(() => {
  //     dataProvider
  //       .getOne(`orders`, { id: orderId })
  //       .then(({ data }) => {
  //         // console.log(data);
  //         if (data) {
  //           setOrder(data);
  //         }
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //       });
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // }, [orderId, dataProvider]);

  if (loading) {
    return (
      <Flex
        width={[1]}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Loading />
      </Flex>
    );
  }
  return <MainFields {...props} order={data} />;
};

export default CancelFormFields;
