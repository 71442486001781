import React from "react";
import { Flex } from "rebass";
// import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import {
  dataTypeAmendment,
  dataReasonAmendment,
  dataReasonAmendmentRefund,
} from "../utils";
import { PaperCard } from "../../../../components";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DetailFields = (props) => {
  const { translate, state, onSetValue } = props;
  const classes = useStyles();

  return (
    <Flex width={[1]} flexDirection={"column"} py={[3]}>
      <PaperCard
        title={translate("resources.orders.labels.details")}
        showButton={false}
        elevation={3}
        bgHeader={"#eeeeee"}
        propsHeader={{ px: [3], py: [2] }}
      >
        <Flex width={[1]} flexDirection={"column"} p={[3]}>
          <Flex
            width={[1]}
            flexDirection={"row"}
            sx={{
              "&>div": {
                pr: [4],
              },
            }}
          >
            <FormControl className={classes.formControl}>
              <InputLabel id="provider-select-type-label">
                {translate("resources.orders.fields.type")}
              </InputLabel>
              <Select
                labelId="provider-select-type-label"
                id="provider-select-type"
                value={state.type}
                onChange={(e) => onSetValue("type", e.target.value)}
              >
                {dataTypeAmendment.map((p, index) => {
                  return (
                    <MenuItem value={p.id} key={index}>
                      {p.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="provider-select-label">
                {translate("resources.orders.fields.reason")}
              </InputLabel>
              {state.type === "Return" && (
                <Select
                  labelId="provider-select-label"
                  id="provider-select"
                  value={state.reason}
                  onChange={(e) => onSetValue("reason", e.target.value)}
                >
                  {dataReasonAmendment.map((p, index) => {
                    return (
                      <MenuItem value={p.id} key={index}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              {state.type === "Refund" && (
                <Select
                  labelId="provider-select-label"
                  id="provider-select"
                  value={state.reason}
                  onChange={(e) => onSetValue("reason", e.target.value)}
                >
                  {dataReasonAmendmentRefund.map((p, index) => {
                    return (
                      <MenuItem value={p.id} key={index}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </FormControl>
          </Flex>
          <Flex px={[2]}>
            <TextField
              label={translate("resources.orders.fields.remarks")}
              fullWidth={true}
              value={state.remarks}
              onChange={(e) => onSetValue("remarks", e.target.value)}
            />
          </Flex>
        </Flex>
      </PaperCard>
    </Flex>
  );
};

export default DetailFields;
