import React from "react";
import DnDBody from "./DnDBody";
import DnDRow from "./DnDRow";
import Content from "./Content";
import DnDText from "./DnDText";
import DnDDivider from "./DnDDivider";
import DnDAvatar from "./DnDAvatar";
import DnDImage from "./DnDImage";
import DnDImageText from "./DnDImageText";
import DnDCarousel from "./DnDCarousel";
import DnDFlatButton from "./DnDFlatButton";
import DnDIconButton from "./DnDIconButton";
import DnDFAButton from "./DnDFAButton";
import DnDProductList from "./DnDProductList";
import DnDYoutube from "./DnDYoutube";
import DnDRowCarousel from "./DnDRowCarousel";
import ContentCarousel from "./ContentCarousel";
import DnDTextGroup from "./DnDTextGroup";
import DnDListSlide from "./DndListSlide";
import DnDCarouselLinkView from "./DnDCarouselLinkView";
import DnDCarouselAnimation from "./DnDCarouselAnimation";
import DnDProductGroupView from "./DnDProductGroupView";
import DnDMenuView from "./DnDMenuView";
import DnDSocialMedia from "./DnDSocialMedia";
import DnDGridListView from "./DnDGridListView";
import DnDAccordionListView from "./DnDAccordionListView";
import DnDMenuGroupView from "./DnDMenuGroupView";
import DnDMultiContentGroupView from "./DnDMultiContentGroupView";
import DnDSubcribeView from "./DnDSubcribeView";
import DnDRowIconText from "./DnDRowIconText";
import DnDMenuContentView from "./DnDMenuContentView";
import { connect } from "../../core";
import "./index.css";

const LayoutHandler = ({ readOnly, ...props }) => {
  if (readOnly) {
  } else {
    const component = dataDnD({ ...props });
    if (component[props.type]) {
      return component[props.type];
    } else {
      return <div />;
    }
  }
};

const dataDnD = ({ ...props }) => {
  return {
    Body: <DnDBody {...props} />,
    RowCarousel: <DnDRowCarousel {...props} />,
    Row: <DnDRow {...props} />,
    ContentCarousel: <ContentCarousel {...props} />,
    Content: <Content {...props} />,
    TextView: <DnDText {...props} />,
    DividerView: <DnDDivider {...props} />,
    AvatarView: <DnDAvatar {...props} />,
    ImageView: <DnDImage {...props} />,
    ImageTextView: <DnDImageText {...props} />,
    CarouselView: <DnDCarousel {...props} />,
    FlatButtonView: <DnDFlatButton {...props} />,
    IconButtonView: <DnDIconButton {...props} />,
    FAButtonView: <DnDFAButton {...props} />,
    ProductListView: <DnDProductList {...props} />,
    YoutubeView: <DnDYoutube {...props} />,
    TextGroupView: <DnDTextGroup {...props} />,
    ListSlideView: <DnDListSlide {...props} />,
    CarouselLinkView: <DnDCarouselLinkView {...props} />,
    CarouselAnimationView: <DnDCarouselAnimation {...props} />,
    ProductGroupView: <DnDProductGroupView {...props} />,
    MenuView: <DnDMenuView {...props} />,
    SocialMediaView: <DnDSocialMedia {...props} />,
    GridListView: <DnDGridListView {...props} />,
    AccordionListView: <DnDAccordionListView {...props} />,
    MenuGroupView: <DnDMenuGroupView {...props} />,
    MultiContentGroupView: <DnDMultiContentGroupView {...props} />,
    SubcribeView: <DnDSubcribeView {...props} />,
    RowIconText: <DnDRowIconText {...props} />,
    MenuContentView: <DnDMenuContentView {...props} />,
  };
};

export default connect("readOnly")(LayoutHandler);
