import React, { cloneElement } from "react";
// useState, useEffect,
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { Divider, Tabs, Tab } from "@material-ui/core";
import { useGetOne, Loading } from "react-admin";
import { choicesLanguages } from "../../utils";
//languages,
import { TabPanel, a11yProps } from "../mui";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

// let LocaleSiteId = 0;

const RaLocaleTabs = ({
  siteId,
  children,
  nameTab,
  isHideLoading,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  // const [localesList, setLocalesList] = useState({
  //   default_locale: "en",
  //   locale: languages,
  // });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const { data, loading } = useGetOne("sites", siteId);
  // useEffect(() => {
  //   let timeout = setTimeout(() => {
  //     if (data && LocaleSiteId !== siteId) {
  //       let newLanguage = data.locales.map(function (e) {
  //         let f = choicesLanguages.find((a) => a.id === e);
  //         return f ? f : undefined;
  //       });
  //       console.log(newLanguage);
  //       setLocalesList({
  //         default_locale: data.default_locale,
  //         locale: newLanguage,
  //       });
  //       LocaleSiteId = siteId;
  //     }
  //   }, 300);
  //   return () => clearTimeout(timeout);
  // });
  if (loading) {
    return isHideLoading ? <div>Loading...</div> : <Loading />;
  }
  const newLanguage = data.locales.map(function (e) {
    let f = choicesLanguages.find((a) => a.id === e);
    return f ? f : undefined;
  });

  if (newLanguage && newLanguage.length === 1) {
    const item = newLanguage[0];
    return (
      <div className={classes.root}>
        {cloneElement(children, { locale: item.id })}
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Tabs value={value} indicatorColor='primary' onChange={handleChange}>
        {newLanguage &&
          newLanguage.map((choice, index) => (
            <Tab
              key={choice.id}
              label={choice.name}
              {...a11yProps(nameTab, index)}
            />
          ))}
      </Tabs>
      <Divider />
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {newLanguage &&
          newLanguage.map((item, index) => (
            <TabPanel
              key={index}
              value={value}
              index={index}
              dir={theme.direction}
              name={nameTab}
            >
              <div key={index}>
                {cloneElement(children, { locale: item.id })}
              </div>
            </TabPanel>
          ))}
      </SwipeableViews>
    </div>
  );
};

export default RaLocaleTabs;
