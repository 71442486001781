import React, { useState } from "react"
import { Flex, Box } from "rebass"
import { Counter, SelectInput } from "../components"
import { useQueryWithStore } from "react-admin"
import { typeListContainer } from "./utils"
import { ContentDetail } from "../actions"
// import { lightBlue } from "@material-ui/core/colors"
import { Dialog, Button } from "@material-ui/core"
import { DialogTitle, DialogContent } from "../components/listslide/styles"
import CategoriesList from "./CategoriesList"
import { ProductList } from "./ProductList"

const DialogProductGroup = (props) => {
  const { state, onSetValue, websiteId } = props
  const [open, setOpen] = useState(false)
  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "categories",
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "name", order: "ASC" },
      filter: { website_id: websiteId },
    },
  })
  if (loading) {
    return <Flex p={[2]}>Loading</Flex>
  }
  return (
    <Flex width={[1]} flexDirection={"column"} p={[2]}>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        color={"primary"}
        style={{ textTransform: "capitalize" }}
      >
        Edit Product Group
      </Button>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="product-group-dialog-title"
        open={open}
        fullScreen={true}
        scroll={"paper"}
      >
        <DialogTitle
          id="product-group-dialog-title"
          onClose={() => setOpen(false)}
        >
          Content of Product Group
        </DialogTitle>
        <DialogContent dividers>
          <Box width={[1]}>
            <ContentDetail>
              <Flex
                width={[1]}
                flexDirection={"row"}
                alignItems={"center"}
                sx={{
                  "& >*": {
                    p: [2],
                  },
                }}
              >
                <SelectInput
                  id={`type-flat-button`}
                  title={"Type List"}
                  value={state.type_list}
                  list={typeListContainer}
                  onChange={(e) => onSetValue("type_list", e.target.value)}
                />
                <Counter
                  onSetValue={(value) => onSetValue("limit", value)}
                  value={state.limit}
                  interval={1}
                  subtitle=""
                  title="Limit"
                  minValue={4}
                />
              </Flex>
            </ContentDetail>
            {state && state.type_list === "product" && (
              <ContentDetail>
                <ProductList {...props} />
              </ContentDetail>
            )}
            {state && state.type_list === "category" && (
              <ContentDetail>
                <CategoriesList {...props} categories={data} />
              </ContentDetail>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Flex>
  )
}

export default DialogProductGroup
