import React from "react";
import { isEmpty, get } from "lodash";
// import { useMedia } from "react-media";
import { HrefContainer } from "../hrefUrl";

import { ButtonView, defaultBorder } from "./ButtonView";
export default (props) => {
  const { item, widthScreen, column } = props;
  const {
    text,
    typeButton,
    labelPosition,
    icon,
    isShowDesktop,
    isShowMobile,
    link,
    border,
    desktop,
    mobile,
  } = item.content;
  const isBorder = border
    ? `${border.size}px ${border.type} ${border.color}`
    : `${defaultBorder.size}px ${defaultBorder.type} ${defaultBorder.color}`;
  const childIcon = !isEmpty(icon) ? null : null;

  const borderRadius = get(border, "radius", "0")
    ? `${get(border, "radius", 0)}px`
    : "0px";

  // const isSmallScreen = useMedia({
  //   query: "(max-width: 768px)",
  // });
  const isShow = widthScreen <= 480 ? isShowMobile : isShowDesktop;
  if (isShow === false) {
    return <div />;
  }

  const newChildIcon = childIcon && childIcon !== null ? childIcon : "";
  let newButtonColors = desktop.buttonColors;
  let newFullWidth = desktop.fullWidth;
  let newButtonAlign = desktop.buttonAlign;
  let newFontSize = get(desktop, "fontSize", 10);
  let newPadding = desktop.containerPadding;
  let newButtonPadding = desktop.buttonPadding
    ? desktop.buttonPadding
    : "8px 12px 8px 12px";
  if (widthScreen <= 480) {
    if (mobile) {
      newButtonColors = mobile.buttonColors;
      newFullWidth = mobile.fullWidth;
      newFontSize = get(mobile, "fontSize", 10);
      newPadding = mobile.containerPadding;
      newButtonAlign = mobile.buttonAlign;
      newButtonPadding = get(mobile, "buttonPadding", "8px 12px 8px 12px");
    }
  }
  /**boxSizing: 'border-box',
        position: 'relative',
        height: 'auto',
        display: 'flex',
        width: '100%',
        justifyContent: newButtonAlign,
        flex: 1,
        padding: newPadding, */
  const myWidth = column && column > 1 ? { width: 100 / column + "%" } : {};
  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "auto",
        display: "flex",
        justifyContent: newButtonAlign,
        padding: newPadding,
        ...myWidth,
      }}
    >
      {link && link.value !== "" ? (
        <HrefContainer link={link}>
          <ButtonView
            newChildIcon={newChildIcon}
            typeButton={typeButton}
            isBorder={isBorder}
            buttonColors={newButtonColors}
            text={text}
            labelPosition={labelPosition}
            fullWidth={newFullWidth}
            defaultBorderSize={defaultBorder.size}
            borderRadius={borderRadius}
            fontSize={`${newFontSize}pt`}
            buttonPadding={newButtonPadding}
          />
        </HrefContainer>
      ) : (
        <ButtonView
          newChildIcon={newChildIcon}
          typeButton={typeButton}
          isBorder={isBorder}
          buttonColors={newButtonColors}
          text={text}
          labelPosition={labelPosition}
          fullWidth={newFullWidth}
          defaultBorderSize={defaultBorder.size}
          borderRadius={borderRadius}
          fontSize={`${newFontSize}pt`}
          buttonPadding={newButtonPadding}
        />
      )}
    </div>
  );
};
