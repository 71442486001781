import React from "react";
import { get } from "lodash";
import { Flex } from "rebass";
import Desktop from "./Desktop";
import Desktop1 from "./Desktop1";
import Desktop2 from "./Desktop2";
import Desktop3 from "./Desktop3";
import Desktop4 from "./Desktop4";
import Desktop5 from "./Desktop5";
import { getTreeFromFlatData } from "./utils";
//listCategories,
import { useStoreTemp } from "../../../../../screens/layouts/components/utils";
const MenuView = (props) => {
  const { settingTemp } = useStoreTemp();
  const {
    logo,
    list,
    modelHeader,
    titleButton,
    color,
    containerLogo,
    isShowMenu,
  } = props.values;
  const settings = {
    header: {
      image: logo,
      list: list,
      titleButton: titleButton,
      color: color,
      isShowMenu: isShowMenu,
      containerLogo: containerLogo,
    },
  };
  const cart = {
    items: [],
    amount: {
      subtotal: 0,
      total: 0,
    },
  };
  const listMenu = get(settingTemp, "menu", []);
  const categories = getTreeFromFlatData({
    flatData: listMenu.map((node) => ({ ...node, title: node.title })),
    getKey: (node) => node.id,
    getParentKey: (node) => node.parent_id,
    rootKey: "root",
  });
  // const categories = listCategories(myMenu);
  return (
    <Flex width={[1]} justifyContent={"center"} alignItems={"center"}>
      {modelHeader === 0 ? (
        <Desktop settings={settings} cart={cart} categories={categories} />
      ) : modelHeader === 1 ? (
        <Desktop1 settings={settings} cart={cart} categories={categories} />
      ) : modelHeader === 2 ? (
        <Desktop2 settings={settings} cart={cart} categories={categories} />
      ) : modelHeader === 3 ? (
        <Desktop3 settings={settings} cart={cart} categories={categories} />
      ) : modelHeader === 4 ? (
        <Desktop4
          settings={settings}
          cart={cart}
          categories={categories}
          websiteId={props.websiteId}
          values={props.values}
        />
      ) : (
        <Desktop5
          settings={settings}
          cart={cart}
          categories={categories}
          websiteId={props.websiteId}
          values={props.values}
        />
      )}
    </Flex>
  );
};

export default MenuView;
