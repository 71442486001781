import React from "react"
import PropTypes from "prop-types"
import { Box } from "rebass"
import { addField, FieldTitle, ResettableTextField } from "react-admin"
import sanitizeRestProps from "./sanitizeRestProps"
import TextMessage from "./utils"

export const RaLongTextInput = ({
  className,
  input,
  meta,
  isRequired,
  label,
  options,
  source,
  resource,
  notetext,
  ...rest
}) => {
  if (typeof meta === "undefined") {
    throw new Error(
      "The RaLongTextInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
    )
  }
  const { touched, error } = meta
  return (
    <Box>
      <ResettableTextField
        {...input}
        className={className}
        multiline
        margin="normal"
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
        error={!!(touched && error)}
        helperText={touched && error}
        {...sanitizeRestProps(rest)}
        {...options}
      />
      {notetext && <TextMessage notetext={notetext} />}
    </Box>
  )
}

RaLongTextInput.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  meta: PropTypes.object,
  name: PropTypes.string,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  notetext: PropTypes.string,
}

const EnhancedLongTextInput = addField(RaLongTextInput)
EnhancedLongTextInput.defaultProps = {
  options: {},
  fullWidth: true,
}

export default EnhancedLongTextInput
