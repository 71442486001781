import React from "react"
import { DropTarget } from "react-dnd"
import { Box } from "rebass"
import ContainerDimensions from "react-container-dimensions"
import { ListCarousel } from "./components"
import { connect } from "../../core"
import { target } from "./utils"
// const target = {
//   drop(props, monitor) {
//     if (!monitor.didDrop()) {
//       props.layoutState.insertOrMoveItem(
//         props.id,
//         props.children.length,
//         monitor.getItem()
//       )
//     }
//   },
// }

const EditLayout = ({ id, connectDropTarget, layoutState }) => {
  const {
    containerPadding,
    backgroundColor,
    align,
    title,
    valueText,
    setting,
  } = layoutState.getItemJS(id).props.values
  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        backgroundColor,
        display: "flex",
        flex: 1,
      }}
    >
      <div
        style={{
          padding: containerPadding,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flex: 1,
          justifyContent: align,
        }}
      >
        <ContainerDimensions>
          {({ width }) => {
            return (
              <Box width={[width]} style={{ backgroundColor: backgroundColor }}>
                {width > 0 && (
                  <div style={{ backgroundColor: backgroundColor }}>
                    <ListCarousel
                      newMan={valueText}
                      title={title}
                      settingsCarousel={setting}
                      screenWidth={width > 1280 ? 1280 : width}
                    />
                  </div>
                )}
              </Box>
            )
          }}
        </ContainerDimensions>
      </div>
    </div>
  )
}

const LayoutContainer = DropTarget(
  "CarouselLinkView",
  target,
  (conn, monitor) => ({
    connectDropTarget: conn.dropTarget(),
    isOverCurrent: monitor.isOver({ shallow: true }),
  })
)(EditLayout)

export default connect("layoutState", "readOnly")(LayoutContainer)

// export default LayoutContainer;s
