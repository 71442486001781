import React, { useState } from "react"
import PropTypes from "prop-types"
import Layout from "../dnd"
import { Layout11 } from "../ContentBox"
import { DivHover, DivHelp } from "./styled"

const YoutubeView = props => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(true)
  }
  const onMouseOver = () => {
    setHover(false)
  }

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type="YoutubeView" />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  )
}

YoutubeView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
}
YoutubeView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "YoutubeView",
    containerPadding: "0px",
    videoId: "R0h9qvX2Wy0",
    fullwidth: true,
    alignValue: "center",
    width: "100%",
  },
  layout: Layout11,
}
export default YoutubeView
