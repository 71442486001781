import React, { useState } from "react";
import { Box } from "rebass";
import { get } from "lodash";
import { DialogCardView, SelectInput } from "../components";
import ContentItemButton from "./ContentItemButton";
import ContentItemImage from "./ContentItemImage";
import ContentItemText from "./ContentItemText";
import ContentItemSocial from "./ContentItemSocial";
import {
  defaultText,
  defaultImage,
  defaultButton,
  defaultSocial,
} from "./utils";
const dataTypeContent = [
  { text: "Text", value: "text" },
  { text: "Image", value: "image" },
  { text: "Button", value: "button" },
  { text: "Social Media", value: "social" },
];

const getContent = (contentType, id) => {
  if (contentType === "button") {
    return defaultButton;
  } else if (contentType === "social") {
    return defaultSocial;
  } else if (contentType === "image") {
    return {
      ...defaultImage,
      imageUrl: {
        url: "",
        urlMobile: "",
      },
    };
  }
  return defaultText;
};

const DialogSelectedItem = (props) => {
  const { item, onEditItem, open, setOpen, onCloseDialog } = props;
  // console.log("item", item);
  const [state, setState] = useState(item);

  const handleSave = () => {
    setOpen(false);
    onEditItem(state);
  };

  const onEditType = (v) => {
    if (state.typeContent !== v) {
      const newContent = getContent(v, item.id);
      const newParams = {
        content: newContent,
        id: item.id,
        typeContent: v,
      };
      setState(newParams);
    } else {
      const newParams = {
        ...state,
        typeContent: v,
      };
      setState(newParams);
    }
  };
  const onSetItemValue = (v) => {
    const newParams = {
      ...state,
      content: v,
    };
    setState(newParams);
  };
  return (
    <DialogCardView
      title={`Item # ${get(item, "id", 0)}`}
      onSave={() => handleSave()}
      open={open}
      setOpen={() => onCloseDialog()}
      onCancel={() => onCloseDialog()}
      id={`item-list-multi-${get(item, "id", 0)}`}
      hiddenAction={false}
    >
      <Box width={[1]}>
        <SelectInput
          id={`type-content-${get(item, "id", 0)}`}
          title={"Type Content"}
          value={state.typeContent}
          list={dataTypeContent}
          onChange={(e) => onEditType(e.target.value)}
        />
        {state.typeContent === "button" && (
          <ContentItemButton
            item={state.content}
            onSetItemValue={(v) => onSetItemValue(v)}
            websiteId={props.websiteId}
          />
        )}
        {state.typeContent === "image" && (
          <ContentItemImage
            item={state.content}
            onSetItemValue={(v) => onSetItemValue(v)}
            websiteId={props.websiteId}
          />
        )}
        {state.typeContent === "text" && (
          <ContentItemText
            item={state.content}
            onSetItemValue={(v) => onSetItemValue(v)}
            websiteId={props.websiteId}
          />
        )}
        {state.typeContent === "social" && (
          <ContentItemSocial
            item={state.content}
            onSetItemValue={(v) => onSetItemValue(v)}
            websiteId={props.websiteId}
          />
        )}
      </Box>
    </DialogCardView>
  );
};

export default DialogSelectedItem;
