import React from "react";
import { Flex } from "rebass";
import { get, find } from "lodash";
import {
  useTranslate,
  withDataProvider,
  Loading,
  useNotify,
} from "react-admin";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { useLocation } from "react-router-dom";
import { EditMainFields } from "./components/amendment_item";
import { useQuery } from "../../components";
const EditAmendment = (props) => {
  const { match } = props;
  const notify = useNotify();
  const orderId = get(match, "params.id", 0);
  const amendmentId = get(match, "params.amendment_id", 0);
  const translate = useTranslate();
  const location = useLocation();
  // const [loading, setLoading] = useState(true);
  // const [record, setRecord] = useState({});

  const { loading, data } = useQuery(`/orders/${orderId}`);
  const amandment = useQuery(`/orders/${orderId}/amendments`);
  ///${amendmentId}
  if (loading || amandment.loading) {
    return (
      <Flex
        width={[1]}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Loading />
      </Flex>
    );
  }

  const list = get(data, "items", []);
  const myAmendment = get(amandment, "data", []);
  const dataAmendment = find(myAmendment, function (o) {
    return o.id === amendmentId * 1;
  });

  return (
    <EditMainFields
      {...props}
      record={data}
      translate={translate}
      location={location}
      notify={notify}
      listItem={list}
      orderId={orderId}
      dataAmendment={dataAmendment}
      amendmentId={amendmentId}
    />
  );
};
const enhance = compose(withRouter, connect(undefined, {}));

export default enhance(withDataProvider(EditAmendment));
