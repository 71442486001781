import React, { Component } from "react";
import { Flex, Box, Text } from "rebass";
import { IconButton } from "@material-ui/core";
import ActionAdd from "@material-ui/icons/AddCircle";
import ActionDelete from "@material-ui/icons/Delete";
import TextInput from "../TextInput";
import { SelectInput } from "../select";
import { UploadAvatar } from "../../../../components";
const dataPosition = [
  { id: "flex-start", text: "Start" },
  { id: "flex-end", text: "End" },
];
class TextGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: props.list || [],
    };
  }
  onAddText = () => {
    const newList = this.state.list;
    const item = { title: "", icon: "", iconPosition: "" };
    newList.push(item);
    this.setState({
      list: newList,
    });
    this.props.onChangeText(newList);
  };
  onDelete = (index) => {
    const newList = this.arrayRemove(this.state.list, index);

    this.setState({
      list: newList,
    });
    this.props.onChangeText(newList);
  };
  arrayRemove(arr, value) {
    arr.splice(value, 1);
    return arr;
  }
  onUpdateText = (value, index) => {
    const { list } = this.state;
    let item = list[index];
    item["title"] = value;
    list[index] = item;
    this.setState({
      list: list,
    });
    this.props.onChangeText(list);
  };
  onUpdateField = (k, value, index) => {
    const { list } = this.state;
    let item = list[index];
    item[k] = value;
    list[index] = item;
    this.setState({
      list: list,
    });
    this.props.onChangeText(list);
  };
  render() {
    const { list } = this.state;
    return (
      <Box width={[1]}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box width={[1]}>
            <Text>Items</Text>
          </Box>
          <Box>
            <IconButton
              onClick={() => this.onAddText()}
              // iconStyle={{ color: "rgb(0, 188, 212)" }}
            >
              <ActionAdd />
            </IconButton>
          </Box>
        </Flex>
        <Box width={[1]}>
          {list.length > 0 &&
            list.map((item, index) => {
              return (
                <Flex
                  key={index}
                  width={[1]}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                >
                  <Box width={[1]}>
                    <TextInput
                      value={item.title}
                      onChange={(value) => this.onUpdateText(value, index)}
                      name={`text-${index}`}
                      title=''
                      fullWidth={true}
                      paddingTop={0}
                    />
                    <UploadAvatar
                      type={"img"}
                      imageUrl={item.icon}
                      onUpdateUrl={(value) =>
                        this.onUpdateField("icon", value, index)
                      }
                      title='Icon'
                    />
                    <SelectInput
                      list={dataPosition}
                      title={"Icon Position"}
                      id={"icon-position"}
                      value={item.iconPosition}
                      onChange={(e) =>
                        this.onUpdateField(
                          "iconPosition",
                          e.target.value,
                          index
                        )
                      }
                    />
                  </Box>
                  <Box>
                    {list.length > 1 && (
                      <IconButton
                        onClick={() => this.onDelete(index)}
                        // iconStyle={{ color: "rgb(0, 188, 212)" }}
                      >
                        <ActionDelete />
                      </IconButton>
                    )}
                  </Box>
                </Flex>
              );
            })}
        </Box>
      </Box>
    );
  }
}

export default TextGroup;
