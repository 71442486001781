import React from "react"
import { DragSource } from "react-dnd"
import styled from "styled-components"

const source = {
  beginDrag(props, monitor) {
    // console.log(props)
    return {
      type: props.type,
      props: { values: props.defaultProps.values },
      children: [],
      style: {},
      childOf: "Content",
    }
  },
}

const ContentItem = ({
  type,
  component,
  connectDragSource,
  defaultProps,
  width,
}) =>
  connectDragSource(
    <div
      style={{
        padding: 4,
      }}
    >
      <DivItem>
        {React.createElement(defaultProps.layout, defaultProps)}
      </DivItem>
    </div>
  )

const DivItem = styled.div`
  display: flex;
  flex: 1;
`

export default DragSource("Content", source, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
}))(ContentItem)
