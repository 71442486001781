import { Layout6 } from "../../TemplateImageSonak";
export const template6A = {
  "1ANqLwJ+": {
    id: "1ANqLwJ+",
    type: "Row5",
    props: {
      values: {
        name: "Row5",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 2,
          height: "100%",
          layout: {
            wrap: false,
            align: "bottom",
            gutter: 10,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "10px 20px 10px 20px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "1080px",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "bottom",
            gutter: 20,
            justify: "center",
            contentAlign: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "10px 10px 10px 10px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 1,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-NXD.jpeg",
                    size: "cover",
                    ratio: 1,
                    layout: {
                      justify: "flex-end",
                    },
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 16,
                  order: 1,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-15Y.jpeg",
                    size: "cover",
                    ratio: 1,
                    height: "280px",
                    layout: {
                      justify: "flex-end",
                    },
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 2,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-M7T.jpeg",
                    size: "cover",
                    ratio: 1,
                    layout: {
                      justify: "flex-end",
                    },
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 8,
                  order: 2,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-E18.jpeg",
                    size: "cover",
                    ratio: 1,
                    height: "280px",
                    layout: {
                      justify: "flex-end",
                    },
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    childOf: "Row",
    layout: Layout6,
    children: ["T+zlZwRB", "yhVQEaXO"],
    myContent: [
      {
        id: "DIZCEiyf",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p>SHOP NOW</p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "8px 0px 16px 20px",
            },
            isShowMobile: false,
            isShowDesktop: true,
          },
        },

        parent: {
          id: "yhVQEaXO",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "yhVQEaXO",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "mUflKM0+",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>RECOMMENDATIONS</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 7,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "10px 0px 10px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 18,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "10px 0px 0px 20px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },

        parent: {
          id: "yhVQEaXO",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "yhVQEaXO",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "tzn6+G4+",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>TRENDING</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 7,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "10px 0px 10px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 18,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "10px 0px 0px 20px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },

        parent: {
          id: "T+zlZwRB",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "T+zlZwRB",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "v7aOvReK",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p>SHOP NOW</p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "8px 0px 16px 20px",
            },
            isShowMobile: false,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "T+zlZwRB",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "T+zlZwRB",
          idx: 1,
          index: 1,
        },
      },
    ],
  },
};
