import React from "react";
import { Link } from "react-router-dom";
import { get } from "lodash";
export default (props) => {
  const isLink = get(props, "link.isLink", false);
  let value = get(props, "link.value", "");
  const typeLink = get(props, "link.type", "URL");
  const target = get(props, "link.target", "");
  if (isLink) {
    if (typeLink === "Category") {
      value = `/categories/${value}`;
    } else if (typeLink === "Content") {
      value = `/contents/${value}`;
    }
    return (
      <Link to={value} target={target} style={{ width: "100%" }}>
        {props.children}
      </Link>
    );
  }
  return props.children;
};
