import React, { Fragment } from "react";
// import { Flex } from "rebass";
import { get, sortBy } from "lodash";
import ItemText from "./ItemText";
import ItemImages from "./ItemImages";
import ItemButtons from "./ItemButtons";
import ItemSocial from "./ItemSocial";
import { Row, Col } from "antd";
import { useClientRect } from "../../../components";

// const LinkHref = ({ url, children, target }) => {
//   if (url && url !== '') {
//     const myTarget =
//       target && target !== '' ? (target !== 'same' ? target : '') : '';
//     return (
//       <Link href={url} target={myTarget}>
//         {children}
//       </Link>
//     );
//     // if (url.includes('http')) {
//     //   return (
//     //     <a href={url} target={target ? target : ''}>
//     //       {children}
//     //     </a>
//     //   );
//     // }else{

//     // }
//   }

//   return <div>{children}</div>;
// };

const defaultLayout = {
  flexDirection: ["column", "row"],
  justifyContent: ["flex-start", "flex-start"],
  alignItems: ["flex-start", "flex-start"],
};
const defaultBorder = {
  type: ["solid", "solid"],
  color: ["#e61919", "#e61919"],
  radius: ["0px", "0px"],
  size: [0, 0],
};

const RowItem = (props) => {
  const { item, widthScreen, column, layoutRow, border, indexView } = props;
  if (item.typeContent === "text") {
    return (
      <ItemText
        item={item}
        widthScreen={widthScreen}
        column={column}
        border={border}
        layoutRow={layoutRow}
        indexView={indexView}
      />
    );
  } else if (item.typeContent === "button") {
    return (
      <ItemButtons
        item={item}
        widthScreen={widthScreen}
        column={column}
        border={border}
        layoutRow={layoutRow}
        indexView={indexView}
      />
    );
  } else if (item.typeContent === "image") {
    return (
      <ItemImages
        item={item}
        widthScreen={widthScreen}
        column={column}
        border={border}
        layoutRow={layoutRow}
        indexView={indexView}
      />
    );
  } else if (item.typeContent === "social") {
    return (
      <ItemSocial
        item={item}
        widthScreen={widthScreen}
        column={column}
        border={border}
        layoutRow={layoutRow}
        indexView={indexView}
      />
    );
  }
  return <Fragment />;
};

export default (props) => {
  const [rect, myRef] = useClientRect();
  const { values, widthScreen } = props;
  const { list } = values;

  const layoutRow = get(values, "layoutRow", defaultLayout);

  const showContent = widthScreen > 640 ? "desktop" : "mobile";

  // const wrap = get(layoutRow, `flexWrap[${valueIndex}]`, "nowrap");
  const newList = sortBy(list, function (o) {
    return o.content[showContent].order;
  });

  const indexView = rect.width > 480 ? 1 : 0;

  const border = get(props, "values.border", defaultBorder);
  const column = get(layoutRow, `column[${indexView}]`, 1);
  const gutter = get(layoutRow, `gutter[${indexView}]`, 8);
  return (
    <div
      ref={myRef}
      style={{
        display: "flex",
        width: "100%",
        // flexDirection: layoutRow.flexDirection[valueIndex],
        // justifyContent: layoutRow.justifyContent[valueIndex],
        // alignItems: layoutRow.alignItems[valueIndex],
        // flexWrap: wrap,
      }}
    >
      <Row
        style={{ alignItems: layoutRow.alignItems[indexView] }}
        gutter={[gutter, gutter]}
      >
        {newList &&
          newList.length > 0 &&
          newList.map((item, index) => (
            <Col key={index} span={get(layoutRow, `column[${indexView}]`, 1)}>
              <RowItem
                item={item}
                widthScreen={widthScreen}
                column={column}
                border={border}
                layoutRow={layoutRow}
                indexView={indexView}
              />
            </Col>
          ))}
      </Row>
    </div>
  );
};
