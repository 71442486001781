import { Layout24 } from "../../TemplateImageSonak";
export const template24A = {
  "7pXGG5xk": {
    id: "7pXGG5xk",
    type: "Row1",
    props: {
      values: {
        name: "Row1",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: true,
            align: "middle",
            gutter: 16,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "8px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: true,
            align: "top",
            gutter: 24,
            justify: "center",
            contentAlign: "center",
            contentJustify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "16px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              parent: "Row",
              desktop: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                padding: "0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    layout: Layout24,
    childOf: "Row",
    children: ["wxaeBrkD"],
    content: {
      "05tv8qFV": {
        id: "05tv8qFV",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>ORDER PROCESSING</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 12,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 14,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "wxaeBrkD",
          idx: 0,
        },
        childOf: "Content",
        children: [],
      },
      FfwKStG5: {
        id: "FfwKStG5",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text:
              '<p><span style="color: rgb(0, 0, 0);">PlayitRight does not ship on weekends and public holidays</span>.</p><p>Orders received after 3pm between Mondays and Fridays will processed on the next working day.</p><p>All orders received after 3pm on Friday, Saturday and Sunday will begin processing the following Monday.</p>',
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "justify",
              lineHeight: "140%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px 0px 8px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "justify",
              lineHeight: "140%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "16px 0px 16px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "wxaeBrkD",
          idx: 1,
        },
        childOf: "Content",
        children: [],
      },
    },
  },
};
