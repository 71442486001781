import React from "react"
import { SwitchInput } from "../switch"
import { Counter } from "../counter"
import { Box, Flex } from "rebass"
/*
autoplay: true
autoplaySpeed: 3000
speed: 500
slidesToScroll: 1
dots: true
arrows: true
infinite: true
rows: 0
slidesToShow: 1
adaptiveHeight: true
slidesPerRow: 0

*/
const SettingSlide = props => {
  const { setting, onChange } = props
  const handleChange = name => event => {
    let newSetting = { ...setting, [name]: event.target.checked }
    onChange(newSetting)
  }
  const handleChangeCounter = name => value => {
    let newSetting = { ...setting, [name]: value }
    onChange(newSetting)
  }
  return (
    <Box>
      <Flex p={[2]} flexDirection={"row"} flexWrap={"wrap"}>
        <SwitchInput
          id={`autoplay`}
          title={"AutoPlay"}
          checked={setting.autoplay}
          onChange={handleChange("autoplay")}
        />
        <SwitchInput
          id={`dots`}
          title={"Dots"}
          checked={setting.dots}
          onChange={handleChange("dots")}
        />
        <SwitchInput
          id={`arrows`}
          title={"Arrows"}
          checked={setting.arrows}
          onChange={handleChange("arrows")}
        />
        <SwitchInput
          id={`infinite`}
          title={"Infinite"}
          checked={setting.infinite}
          onChange={handleChange("infinite")}
        />
        <SwitchInput
          id={`adaptiveHeight`}
          title={"Adaptive Height"}
          checked={setting.adaptiveHeight}
          onChange={handleChange("adaptiveHeight")}
        />
      </Flex>
      <Flex
        p={[2]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
      >
        <Counter
          title={"Speed"}
          onSetValue={handleChangeCounter("speed")}
          value={setting.speed}
          interval={10}
          subtitle="/ms"
          minValue={500}
        />
        <Counter
          title={"AutoplaySpeed"}
          onSetValue={handleChangeCounter("autoplaySpeed")}
          value={setting.autoplaySpeed}
          interval={10}
          subtitle="/ms"
          minValue={500}
        />
        <Counter
          title={"SlidesToScroll"}
          onSetValue={handleChangeCounter("slidesToScroll")}
          value={setting.slidesToScroll}
          interval={1}
          subtitle="/item"
          minValue={0}
        />
        <Counter
          title={"SlidesToShow"}
          onSetValue={handleChangeCounter("slidesToShow")}
          value={setting.slidesToShow}
          interval={1}
          subtitle="/show"
          minValue={0}
        />
        <Counter
          title={"rows"}
          onSetValue={handleChangeCounter("rows")}
          value={setting.rows}
          interval={1}
          subtitle="/row"
          minValue={0}
        />
        <Counter
          title={"slidesPerRow"}
          onSetValue={handleChangeCounter("slidesPerRow")}
          value={setting.slidesPerRow}
          interval={1}
          subtitle="/row"
          minValue={0}
        />
      </Flex>
    </Box>
  )
}

export default SettingSlide
