import React, { Component } from "react"
import PropTypes from "prop-types"
import { LayoutState, Store } from "../core"

class LayoutProvider extends Component {
  constructor(props) {
    super(props)
    this.store = new Store({
      layoutState: props.layoutState,
      components: props.components,
      addons: props.addons,
      readOnly: props.readOnly,
      token: props.token,
      locale: props.locale,
      widthScreen: props.widthScreen,
      websiteId: props.websiteId,
    })
  }

  getChildContext() {
    return {
      store: this.store,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const watched = [
      "components",
      "addons",
      "readOnly",
      "token",
      "locale",
      "widthScreen",
      "websiteId",
    ]
    if (nextProps.layoutState !== this.props.layoutState) {
      this.store.update("layoutState", nextProps.layoutState)
    }
    watched.forEach((key) => {
      if (nextProps[key] !== this.props[key])
        this.store.update(key, nextProps[key])
    })
  }

  render() {
    return React.Children.only(this.props.children)
  }
}

LayoutProvider.childContextTypes = {
  store: PropTypes.instanceOf(Store),
}

LayoutProvider.propTypes = {
  layoutState: PropTypes.instanceOf(LayoutState).isRequired,
  components: PropTypes.object.isRequired,
  addons: PropTypes.array,
  readOnly: PropTypes.bool,
  token: PropTypes.string,
  locale: PropTypes.string,
}

LayoutProvider.defaultProps = {
  addons: [],
}

export default LayoutProvider
