import React, { Fragment, useState } from "react";
import { Flex } from "rebass";
import { get, find } from "lodash";
import {
  useTranslate,
  withDataProvider,
  Loading,
  useNotify,
  Confirm,
} from "react-admin";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { Button } from "@material-ui/core";
import { HeaderTitle } from "../../components";
import { CancelFormFields } from "./components/shipment_item";
import { RestApi } from "../../restClient";
import { useQuery } from "../../components";

const CancelShipment = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const { match, history } = props;
  const [open, setOpen] = useState(false);
  const shipmentId = get(match, "params.id", 0);
  const orderId = get(match, "params.order_id", 0);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  // const [record, setRecord] = useState({});
  const url = `/orders/${orderId}/show/shipment`;
  const { loading, data } = useQuery(`/orders/${orderId}/shipments`);
  // console.log(props);

  const onCancelShipment = () => {
    setLoadingSubmit(true);
    RestApi(`/orders/${orderId}/shipments/${shipmentId}/cancel`)
      .then((res) => {
        if (res && res.message && res.message !== "") {
          notify(res.message, "warning", {
            smart_count: 1,
          });
        } else {
          setOpen(false);
          notify("resources.shippings.messages.cancel_success", "success", {
            smart_count: 1,
          });
          history.replace(url);
        }
        setLoadingSubmit(false);
      })
      .catch((e) => {
        setOpen(false);
        notify("resources.errors.please_try_again", "warning", {
          smart_count: 1,
        });
        setLoadingSubmit(false);
        // console.log(e);
      });
  };

  // useEffect(() => {
  //   setLoading(true);
  //   const timer = setTimeout(() => {
  //     RestApi(`/orders/${orderId}/shipments`)
  //       .then((response) => {
  //         return response.json();
  //       })
  //       .then((res) => {
  //         // console.log(res);
  //         if (res) {
  //           // console.log(res);
  //           const shipment = find(res, function (o) {
  //             return shipmentId === o.id + "";
  //           });
  //           // console.log(shipment);
  //           setRecord(shipment);
  //         }
  //         setLoading(false);
  //       })
  //       .catch((e) => {
  //         setLoading(false);
  //         console.log(e);
  //       });
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // }, [orderId, shipmentId]);
  // console.log(record);
  if (loading) {
    return (
      <Flex
        width={[1]}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Loading />
      </Flex>
    );
  }
  const handleDialogClose = () => {
    setOpen(false);
    setLoadingSubmit(false);
  };
  const shipment = find(data, function (o) {
    return shipmentId === o.id + "";
  });
  const isShowingCancel = get(shipment, "status", "");
  return (
    <Fragment>
      <Flex width={[1]} flexDirection={"column"}>
        <HeaderTitle title={"resources.orders.title.shipment"}>
          <Fragment>
            <Button component={Link} color={"primary"} to={url}>
              {translate("resources.buttons.list")}
            </Button>
            {isShowingCancel === "Pending" ? (
              <Button onClick={() => setOpen(true)} color={"primary"}>
                {translate("resources.buttons.cancel_shipment")}
              </Button>
            ) : (
              <div />
            )}
          </Fragment>
        </HeaderTitle>
        <Flex width={[1]} flexDirection={"column"} px={[2]}>
          <CancelFormFields
            {...props}
            record={shipment}
            translate={translate}
          />
        </Flex>
      </Flex>
      <Confirm
        isOpen={open}
        loading={loadingSubmit}
        title='resources.orders.messages.cancel_title'
        content='resources.orders.messages.cancel_content'
        onConfirm={() => onCancelShipment()}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

const enhance = compose(withRouter, connect(undefined, {}));

export default enhance(withDataProvider(CancelShipment));
