import React, { useState } from "react";
import Counter from "./Counter";
import { includes } from "lodash";
import { PixelPercent } from "../radioGroup";
import { Flex } from "rebass";
import { Content } from "./styled";
export default (props) => {
  const { value, onSetValue, name = "imagewidth", title = "Title" } = props;
  const newValue = value && value !== "" ? value : "100%";
  let widthType = includes(newValue, "px") ? "px" : "%";

  let widthValue =
    widthType === "%"
      ? newValue.split("%").join("") * 1
      : newValue.split("px").join("") * 1;

  const [type, setType] = useState(widthType);
  return (
    <Content>
      <PixelPercent
        name={name}
        value={type}
        onChange={(e) => setType(e.target.value)}
        title={title}
      />
      <Flex flexDirection={"row"} width={[1]} justifyContent={"flex-end"}>
        <Counter
          isDisabled={false}
          onSetValue={(v) => onSetValue(v + type + "")}
          value={widthValue}
          interval={1}
          maxValue={type === "%" ? 100 : 2000}
        />
      </Flex>
    </Content>
  );
};
