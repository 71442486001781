import React from "react";
import { get } from "lodash";
import { HrefContainer } from "../hrefUrl";
export default (props) => {
  const { item, widthScreen, column } = props;
  const { desktop, mobile } = item.content;
  const neContent = widthScreen > 640 ? desktop : mobile;
  const myWidth = column && column > 1 ? 100 / column : 100;
  const fontSize = get(neContent, "fontSize", 10);
  const mytext =
    widthScreen > 640
      ? get(item, "content.text", "")
      : get(mobile, "text", get(item, "content.text", ""));
  const parentBackground = get(
    props,
    `border.backgroundColor[${props.indexView}]`,
    "transparent"
  );
  return (
    <HrefContainer link={get(item, "link", { isLink: false })}>
      <div
        style={{
          padding: neContent.containerPadding,
          backgroundColor: get(neContent, "backgroundColor", parentBackground),
          fontSize: `${fontSize}pt`,
          color: get(neContent, "color", "#000000"),
          letterSpacing: get(neContent, "letterSpacing", "1px"),
          lineHeight: get(neContent, "lineHeight", "100%"),
          width: `${myWidth}%`,
        }}
        dangerouslySetInnerHTML={{ __html: mytext }}
      />
    </HrefContainer>
  );
};
