import React from "react";
import { get } from "lodash";
import { Flex, Box, Text } from "rebass";
import { useTheme } from "@material-ui/core/styles";
import { Currency } from "../../../../components";
import { H3, Small } from "../../../../components/Text";
import { useStyles, Price, Image, Container } from "./utils";

const ViewItem = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const accentColor = theme.palette.primary[500];
  const primaryColor = theme.palette.primary[500];
  const { item, translate, currency_symbol } = props;
  const {
    qty,
    price,
    discount_price,
    tier_price,
    name,
    variant,
    shipped_qty,
    refunded_qty,
    returned_qty,
  } = item;

  const tierPrice = tier_price * 1;
  const discountPrice = discount_price * 1;
  const myPrice = price * 1;

  const myVariant = get(variant, "variant", {});
  var variants = [];
  for (var key in myVariant) {
    // console.log(key);
    variants.push(
      <Small fontSize='0.9em' key={key} pr={1} pt={1}>
        <span className={classes.text}>{key}</span>
        <span>{myVariant[key]}</span>
      </Small>
    );
  }

  return (
    <Container width={[1, 1, 1]} py={2}>
      <Box width={["160px", "200px", "256px"]} p={[1]}>
        <Image src={item.photo} />
      </Box>
      <Flex width={[1]} p={[1]} pr={[2]}>
        <Box width={1 / 2}>
          <Box>
            <H3>{name}</H3>
            <Flex
              flexDirection={"row"}
              justifyContent={"space-between"}
              pt={[2]}
            >
              <Box>{variants}</Box>
            </Flex>
          </Box>
        </Box>
        <Box width={1 / 2}>
          <Flex
            width={[1]}
            flexDirection='row'
            justifyContent='flex-end'
            alignItems={"flex-start"}
          >
            <Flex
              flexDirection={"row"}
              alignItems={"center"}
              sx={{
                "&>div": {
                  pr: [2],
                },
              }}
            >
              <Price striked={myPrice > discountPrice} pr='0.8em'>
                <Currency
                  value={myPrice}
                  unit={currency_symbol}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Price>
              <Price
                hide={myPrice <= discountPrice}
                striked={discountPrice > tierPrice}
                pr='0.8em'
              >
                <Currency
                  value={discountPrice}
                  unit={currency_symbol}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Price>
              <Price hide={tierPrice >= discountPrice}>
                <Currency
                  value={tierPrice}
                  unit={currency_symbol}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Price>
              <Text py={1}>
                x <span>{qty}</span> =
              </Text>
              <Price striked={myPrice > discountPrice} pr='0.8em'>
                <Currency
                  value={myPrice * qty}
                  unit={currency_symbol}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Price>
              <Price
                hide={myPrice <= discountPrice}
                striked={discountPrice > tierPrice}
                pr='0.8em'
              >
                <Currency
                  value={discountPrice * qty}
                  unit={currency_symbol}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Price>
              <Price hide={tierPrice >= discountPrice}>
                <Currency
                  value={tierPrice * qty}
                  unit={currency_symbol}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Price>
            </Flex>
          </Flex>
          <Flex
            width={[1]}
            flexDirection='row'
            justifyContent='flex-end'
            alignItems={"flex-start"}
            pt={[2]}
            pr={[2]}
          >
            <Flex
              flexDirection='row'
              justifyContent='space-between'
              width={[1 / 4]}
              alignItems='center'
            >
              <Text pr={[2]} color={primaryColor}>
                {translate("resources.orders.labels.shipped")}{" "}
                {shipped_qty || 0}
              </Text>
            </Flex>
            <Flex flexDirection={"row"} width={[1]} justifyContent={"flex-end"}>
              <Text color={accentColor} px={[1]}>
                {translate("resources.orders.labels.returned_qty")}{" "}
                {returned_qty || 0}
              </Text>
              <Text color={accentColor} pl={[1]}>
                {translate("resources.orders.labels.refunded_qty")}{" "}
                {refunded_qty || 0}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Container>
  );
};

export default ViewItem;
