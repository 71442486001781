import React, { Fragment, useEffect, useState } from "react";
import { Flex } from "rebass";
import { replace, get } from "lodash";
import { useTranslate, withDataProvider, Loading } from "react-admin";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { Button } from "@material-ui/core";
import { useLocation, Link, withRouter } from "react-router-dom";
import { HeaderTitle } from "../../components";
import { FormFields } from "./components/shipment_item";
const CreateShipment = (props) => {
  const { match, dataProvider } = props;
  const orderId = get(match, "params.id", 0);
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState({});
  const translate = useTranslate();
  const location = useLocation();
  const url = replace(location.pathname, "/create", "");

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      dataProvider
        .getOne(`orders`, { id: orderId })
        .then(({ data }) => {
          // console.log(data);
          if (data) {
            setRecord(data);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }, 1000);

    return () => clearTimeout(timer);
  }, [orderId, dataProvider]);

  if (loading) {
    return (
      <Flex
        width={[1]}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Loading />
      </Flex>
    );
  }

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <HeaderTitle title={"resources.orders.title.new_shipment"}>
        <Fragment>
          <Button component={Link} color={"primary"} to={url}>
            {translate("resources.buttons.list")}
          </Button>
        </Fragment>
      </HeaderTitle>
      <Flex width={[1]} flexDirection={"column"} px={[2]}>
        <FormFields {...props} record={record} translate={translate} />
      </Flex>
    </Flex>
  );
};

const enhance = compose(withRouter, connect(undefined, {}));

export default enhance(withDataProvider(CreateShipment));
