import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import RowIcon from "@material-ui/icons/ViewModuleTwoTone";
import ContentIcon from "@material-ui/icons/CategoryTwoTone";
import BodyIcon from "@material-ui/icons/PaletteTwoTone";

import { connect } from "../core";
import BodyItem from "./BodyItem";
import RowItem from "./RowItem";
import ContentItem from "./ContentItem";
import { Flex, Box, Text } from "rebass";
import { getStylesContent, ContainerCatalog, Tab, Tabs } from "./styles";
import {
  renderTemplate,
  renderTemplateMulti,
  renderTemplateRow2,
  renderTemplateHeader,
  renderTemplateHeader2,
} from "./Template";

const arrayTabs = [
  { id: 1, title: "Blocks", name: "Content", icon: <ContentIcon /> },
  { id: 2, title: "Templates", name: "Row", icon: <RowIcon /> },
  { id: 3, title: "Styles", name: "Body", icon: <BodyIcon /> },
];
const arrayTabsHeader = [
  { id: 1, title: "Blocks", name: "Content", icon: <ContentIcon /> },
  { id: 2, title: "Templates", name: "Row", icon: <RowIcon /> },
];
const arrayTabsFooter = [
  { id: 1, title: "Blocks", name: "Content", icon: <ContentIcon /> },
];

const renderComponent = (tab, props, width, height) => {
  const { components } = props;
  const styles = getStylesContent();
  const stylesContent = styles.sidebarOpenRow;
  // tab === "Content" ? styles.sidebarOpen : styles.sidebarOpenRow

  return (
    <Flex
      width={[1]}
      flexDirection={"column"}
      alignItems={"center"}
      pl={[1]}
      pr={[1]}
    >
      <div style={stylesContent}>
        {Object.keys(components).map((key) => {
          let obj = Object.hasOwnProperty.call(components[key], "defaultProps");
          if (obj) {
            let { value } = Object.getOwnPropertyDescriptor(
              components[key],
              "defaultProps"
            );
            if (tab === "Row") {
              if (value.childOf === tab) {
                return (
                  <RowItem
                    key={key}
                    type={key}
                    component={components[key]}
                    defaultProps={value}
                    width={width / 4}
                    heightContent={height}
                  />
                );
              }
            } else if (tab === "eCommerce") {
              if (value.childOf === "Content") {
                if (key === "ProductListView") {
                  return (
                    <Fragment key={key}>
                      <Text p={[2]} fontSize={[0]} fontWeight={"bold"}>
                        eCommerce
                      </Text>
                      <ContentItem
                        key={key}
                        type={key}
                        component={components[key]}
                        defaultProps={value}
                        width={width / 4}
                        heightContent={height}
                      />
                    </Fragment>
                  );
                } else {
                  return "";
                }
                // else {
                //   return <div key={key} />
                // }
              }
            } else {
              if (value.childOf === tab) {
                if (key !== "ProductListView") {
                  return (
                    <ContentItem
                      key={key}
                      type={key}
                      component={components[key]}
                      defaultProps={value}
                      width={width / 4 - 50}
                      heightContent={height}
                    />
                  );
                } else {
                  return "";
                }
                // else {
                //   return <div key={key} />
                // }
              }
            }
          }
          return "";

          // return <div key={key} />
        })}
      </div>
    </Flex>
  );
};

const CatalogDrag = (props) => {
  const [value, setValue] = useState("Content");
  // console.log(props.layoutState.getItemJS("root"))
  const dataBody = props.layoutState.getItemJS("root").props
    ? props.layoutState.getItemJS("root").props
    : null;
  const handleChange = (event, value) => {
    setValue(value);
  };
  const onUpdateData = (data) => {
    try {
      props.layoutState.updateItem("root")(["props"], () => data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const myHeight = props.heightCatalog > 600 ? 480 : props.heightCatalog - 100;
  const myTop = props.myTop >= 0 ? { top: props.myTop } : {};
  const myArray = props.isHeader
    ? arrayTabsHeader
    : props.isFooter
    ? arrayTabsFooter
    : arrayTabs;

  const isShowFull = false;
  //isShowFull ? "100%" : "70vh",
  return (
    <ContainerCatalog style={{ ...myTop, backgroundColor: "#FFFFFF" }}>
      <Flex width={[1]} flexDirection={"column"}>
        <Tabs value={value} onChange={handleChange}>
          {myArray.map((choice) => {
            return (
              <Tab key={choice.id} label={choice.title} value={choice.name} />
            );
          })}
        </Tabs>

        <Flex
          width={[1]}
          flexDirection={"column"}
          style={{ overflowY: "hidden", height: "100vh" }}
        >
          {value === "Content" && (
            <Box
              width={[1]}
              pb={[3]}
              style={{
                height: props.myContainerheight
                  ? props.myContainerheight
                  : "70vh",
                overflowY: "scroll",
                boxSizing: "content-box",
              }}
            >
              <Text p={[2]} fontSize={[0]} fontWeight={"bold"}>
                Row Layouts
              </Text>
              {renderComponent("Row", props, props.widthScreen)}
              <Text p={[2]} fontSize={[0]} fontWeight={"bold"}>
                Blocks
              </Text>
              {renderComponent("Content", props, props.widthScreen)}
              {renderComponent("eCommerce", props, props.widthScreen)}
            </Box>
          )}

          {value === "Row" && (
            <Box
              width={[1]}
              py={[2]}
              style={{
                height: isShowFull ? "100%" : "70vh",
                overflowY: "auto",
              }}
            >
              {props.isHeader &&
                renderTemplateHeader2(props, props.widthScreen)}
              {props.isHeader && renderTemplateHeader(props, props.widthScreen)}
              {!props.isHeader && renderTemplate(props, props.widthScreen)}
              {!props.isHeader && renderTemplateMulti(props, props.widthScreen)}
              {!props.isHeader && renderTemplateRow2(props, props.widthScreen)}
            </Box>
          )}
          {value === "Body" && dataBody !== null && (
            <BodyItem
              data={dataBody}
              onUpdateData={(data) => onUpdateData(data)}
              heightContent={myHeight}
              widthContent={props.widthScreen}
            />
          )}
        </Flex>
      </Flex>
    </ContainerCatalog>
  );
};
CatalogDrag.propTypes = {
  components: PropTypes.object.isRequired,
  layoutState: PropTypes.object,
};

export default connect("components")(CatalogDrag);
