import React from "react";
import { Flex } from "rebass";
import { ContentDetail } from "../actions";
import { get } from "lodash";
import {
  BlockCounter,
  Counter,
  LayoutSubcribe,
  SwitchInput,
} from "../components";
import ButtonContent from "./ButtonContent";
import ColorsContent from "./ColorsContent";

export default (props) => {
  const { onSetValue, state, contentId } = props;
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <ContentDetail>
        <SwitchInput
          id={`show-${contentId}`}
          title='Show on Screen'
          checked={state.show}
          onChange={(e) => onSetValue("show", e.target.checked)}
        />
        <Counter
          isDisabled={false}
          title='Input Font Size'
          onSetValue={(value) => onSetValue("inputFontSize", value)}
          value={state.inputFontSize}
          interval={1}
          subtitle='pt'
          minValue={0}
        />
        <Counter
          isDisabled={false}
          title='Border Radius Container'
          onSetValue={(value) => onSetValue("borderRadius", value)}
          value={state.borderRadius}
          interval={1}
          subtitle='px'
          minValue={0}
        />
      </ContentDetail>
      <BlockCounter
        containerPadding={get(state, "containerPadding", "0px")}
        onUpdatePadding={(containerPadding) =>
          onSetValue("onSetValue", containerPadding)
        }
        title={"Container Padding"}
      />
      <ColorsContent
        state={state.colorProps}
        onSetValue={(v) => onSetValue("colorProps", v)}
      />
      <LayoutSubcribe
        state={state.layout}
        onSetValue={(v) => onSetValue("layout", v)}
        contentId={`layout-${contentId}`}
      />
      <ButtonContent
        state={state.button}
        onSetValue={(v) => onSetValue("button", v)}
        contentId={`button-${contentId}`}
      />
    </Flex>
  );
};
