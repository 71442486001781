export const defaultText = {
  contentType: "text",
  mobile: {
    backgroundColor: "rgba(255,255,255,0)",
    color: "rgba(0,0,0,1)",
    fontSize: 10,
    lineHeight: "120%",
    letterSpacing: "1px",
    containerPadding: "0px",
    order: 1,
    text: "<div>Lorem ipsum</div>",
  },
  desktop: {
    backgroundColor: "rgba(255,255,255,0)",
    color: "rgba(0,0,0,1)",
    fontSize: 12,
    lineHeight: "120%",
    letterSpacing: "1px",
    containerPadding: "0px",
    order: 1,
  },
  text: "<div>Lorem ipsum dolor sit amet</div>",
  link: {
    target: "",
    type: "URL",
    value: "",
    isLink: false,
  },
};

export const defaultImage = {
  contentType: "image",
  imageUrl: {
    url: "",
    urlMobile: "",
  },
  isShowMobile: true,
  isShowDesktop: true,
  altText: "Image one",
  desktop: {
    width: "100%",
    height: "100%",
    containerMargin: "0px",
    containerPadding: "0px",
    order: 1,
    mode: "contain",
  },
  mobile: {
    width: "100%",
    height: "100%",
    containerMargin: "0px",
    containerPadding: "0px",
    order: 1,
    mode: "contain",
  },
  link: {
    target: "",
    type: "URL",
    value: "",
    isLink: false,
  },
};

export const defaultSocial = {
  contentType: "social",
  type: "facebook",
  color: "#F61C0D",
  showLabel: true,
  isLink: false,
  desktop: {
    show: true,
    containerPadding: "0px",
    size: 24,
    align: "center",
  },
  mobile: {
    show: false,
    containerPadding: "0px",
    size: 24,
    align: "center",
  },
  link: {
    target: "",
    type: "URL",
    value: "",
    isLink: false,
  },
};
export const defaultButton = {
  contentType: "button",
  text: "FlatButton",
  typeButton: "flat",
  labelPosition: "before",
  icon: "",
  isShowDesktop: true,
  isShowMobile: true,
  link: {
    target: "",
    type: "URL",
    value: "",
    isLink: false,
  },
  border: {
    type: "solid",
    size: 0,
    color: "#e61919",
    radius: 0,
  },
  desktop: {
    fullWidth: false,
    fontSize: 14,
    containerPadding: "0px",
    buttonPadding: "8px 12px 8px 12px",
    buttonAlign: "center",
    buttonColors: {
      color: "#FFF",
      backgroundColor: "#e61919",
      hoverColor: "#42d696",
      rippleColor: "#FF9900",
    },
    order: 1,
  },
  mobile: {
    fullWidth: false,
    fontSize: 10,
    containerPadding: "0px",
    buttonPadding: "8px 12px 8px 12px",
    buttonAlign: "center",
    buttonColors: {
      color: "#FFF",
      backgroundColor: "#e61919",
      hoverColor: "#42d696",
      rippleColor: "#FF9900",
    },
    order: 1,
  },
};
