import React from "react";
import { IconButton } from "@material-ui/core";
import ActionSave from "@material-ui/icons/Save";
import ActionCopy from "@material-ui/icons/FileCopy";
import ActionPaste from "@material-ui/icons/FilterNone";
import { DivFooterActions } from "../styled";

export const FooterAction = ({
  onClickCopy,
  onClickPaste,
  onClickSave,
  widthContent,
}) => {
  return (
    <DivFooterActions style={{ width: widthContent }}>
      <IconButton onClick={onClickCopy} style={{ color: "#FFFFFF" }}>
        <ActionCopy color={"inherit"} />
      </IconButton>
      <IconButton onClick={onClickPaste} style={{ color: "#FFFFFF" }}>
        <ActionPaste color={"inherit"} />
      </IconButton>
      <IconButton onClick={onClickSave} style={{ color: "#FFFFFF" }}>
        <ActionSave color={"inherit"} />
      </IconButton>
    </DivFooterActions>
  );
};
