import React from "react";
// import styled from "styled-components";
// import { DndProvider } from "react-dnd"
// import { HTML5Backend } from "react-dnd-html5-backend"
import { LayoutProvider, RootWrapper, PropsEditor } from "./core";
import { CatalogDrag } from "./catalog";
import { Flex } from "rebass";
import { DragAndDrop } from "../components";
const widthBox2 = 320;
const EditorTemplate = (props) => {
  return (
    <DragAndDrop>
      <LayoutProvider {...props}>
        <Flex
          flexDirection={"row"}
          width={[1]}
          justifyContent={"space-between"}
        >
          <div
            style={{
              width: `${props.widthScreen - 310}px`,
              minHeight: props.heightScreen,
              overflowY: "hidden",
              backgroundColor: "#FFFFFF",
            }}
          >
            <div style={{ overflowY: "scroll", height: "100%" }}>
              <RootWrapper id='root' />
            </div>
          </div>
          <div style={{ width: `${widthBox2}px`, backgroundColor: "#FFFFFF" }}>
            <CatalogDrag
              layoutState={props.layoutState}
              statusForm={props.statusForm}
              widthScreen={props.widthScreen}
              heightScreen={props.heightScreen}
              heightCatalog={props.heightScreen - 135}
              locale={props.locale}
              websiteId={props.websiteId}
              myTop={props.myTop}
              isHeader={props.isHeader}
              isFooter={props.isFooter}
              siteId={props.siteId}
              myContainerheight={props.myContainerheight}
            />
            {props.layoutState.getSelectedItem() && (
              <PropsEditor
                style={styles.contentProps}
                typePage={props.typePage}
                marketerId={props.marketerId}
                widthScreen={props.widthScreen}
                heightScreen={props.heightScreen}
                locale={props.locale}
                websiteId={props.websiteId}
                siteId={props.siteId}
                myTop={props.myTop}
                myContainerheight={props.myContainerheight}
              />
            )}
          </div>
        </Flex>
      </LayoutProvider>
    </DragAndDrop>
  );
};

const styles = {
  contentProps: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    zIndex: 1001,
  },
};

// const DivContainer = styled.div`
//   display: flex;
//   width: 100%;
//   height: 100%;
//   flex-direction: column;
//   overflow-y: hidden;
//   overflow-x: hidden;
//   flex: 1;
//   padding-bottom: 60px;
//   @media only screen and (min-width: 768px) {
//     padding-bottom: 0px;
//   }
// `;
// const LayoutEditor = styled.div`
//   width: 100%;
//   flex: 1;
//   background-color: #ffffff;
//   padding: 0px;
// `;
// const BoxEditor = styled.div`
//   overflow-y: scroll;
//   height: 100%;
// `;

EditorTemplate.propTypes = LayoutProvider.propTypes;

export default EditorTemplate;
