import { Layout16 } from "../TemplateImage";

export const templateHeader3 = {
  vYNuvJRh: {
    id: "vYNuvJRh",
    type: "Row1",
    props: {
      values: {
        name: "Row1",
        type: "Row",
        mobile: {
          width: "100%",
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          gradient: {
            show: false,
          },
          padding: "0px",
          backgroundColor: "rgba(0, 51, 160, 1)",
          column: 1,
          show: true,
        },
        parent: "Body",
        desktop: {
          width: "100%",
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          gradient: {
            show: false,
          },
          padding: "0px",
          backgroundColor: "rgba(0, 51, 160, 1)",
          column: 1,
          show: true,
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              className: "col-xs-12",
              alignment: {
                vertical: "center",
                horizontal: "center",
              },
              isBackgroundImage: false,
              width: {
                type: "%",
                contentWidth: 100,
              },
              height: {
                type: "%",
                isMobile: false,
                isDesktop: false,
                contentHeight: 100,
              },
              parent: "Row",
              bgProps: {
                size: "cover",
                ratio: 1.2,
                repeat: "no-repeat",
                position: "center",
              },
              backgroundImage: {
                url: "",
                mobile: {
                  width: 0,
                  height: 0,
                },
                desktop: {
                  width: 0,
                  height: 0,
                },
                urlMobile: "",
              },
              gradient: {
                bg1: "rgb(34,193,195)",
                bg2: "rgba(253,187,45,1)",
                type: "linear-gradient",
                degre: 160,
                linear: [0, 100],
              },
              padding: "0px",
              backgroundColor: "rgba(255,255,255,0)",
              column: 1,
              isGradient: false,
              type: "Content",
              backgroundColorParent: "rgba(255,255,255,0)",
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    layout: Layout16,
    childOf: "Row",
    children: ["Pp/tsGLl"],
    content: {
      FxXG4Zc1: {
        id: "FxXG4Zc1",
        type: "MenuView",
        props: {
          values: {
            list: [
              {
                url: "",
                slug: "",
                type: "category",
                title: "",
                target: "same",
                category_id: 1,
              },
              {
                url: "",
                slug: "",
                type: "category",
                title: "",
                target: "same",
                category_id: 35,
              },
              {
                url: "",
                slug: "",
                type: "category",
                title: "",
                target: "same",
                category_id: 67,
              },
              {
                url: "",
                slug: "aboutus",
                type: "slug",
                title: "About Us",
                target: "same",
                category_id: 0,
              },
            ],
            logo:
              "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/Logo-image-desktop-K46.png",
            type: "MenuView",
            color: "rgba(255, 255, 255, 1)",
            parent: "Content",
            modelHeader: 4,
            titleButton: "SHOP BY CATEGORY",
            containerPadding: "0px",
          },
        },
        parent: {
          id: "Pp/tsGLl",
          idx: 0,
        },
        childOf: "Content",
        children: [],
      },
    },
  },
};
