export default {
  register: {
    name: "Registrations",
    fields: {
      uuid: "Uuid",
      status: "Status",
      plan: "Plan",
      region: "Region",
    },
  },
}
