import React, { useState } from "react";
import PropTypes from "prop-types";
import { FooterAction } from "../actions";
import { get } from "lodash";
import { Flex, Box } from "rebass";
import DialogEditContent from "./DialogEditContent";
import { defaultButton } from "./utils";
import ButtonFields from "./ButtonFields";
import { HrefContent } from "../components";
import { BoxContent } from "../styled";
/** note: change collections with content (slug) 090818*/
const FlatButtonView = (props) => {
  const {
    labelPosition,
    icon,
    href,
    containerPadding,
    text,
    typeButton,
    link,
    border = defaultButton.border,
    mobile = defaultButton.mobile,
    desktop = defaultButton.desktop,
    isShowDesktop = defaultButton.isShowDesktop,
    isShowMobile = defaultButton.isShowMobile,
  } = props.data.values;
  const [state, setState] = useState({
    icon: icon,
    labelPosition: labelPosition,
    containerPadding: containerPadding,
    href: href,
    typeButton: typeButton,
    text: text,
    border: border,
    mobile: mobile,
    desktop: desktop,
    isShowDesktop: isShowDesktop,
    isShowMobile: isShowMobile,
    link: link,
  });

  const onSetValue = (k, v) => {
    setState({
      ...state,
      [k]: v,
    });
  };

  const onClickSave = () => {
    let newData = props.data;
    newData.values.text = state.text;
    newData.values.containerPadding = state.containerPadding;
    newData.values.labelPosition = state.labelPosition;
    newData.values.icon = state.icon;
    newData.values.link = state.link;
    newData.values.typeButton = state.typeButton;
    newData.values.border = state.border;
    newData.values.mobile = state.mobile;
    newData.values.desktop = state.desktop;
    newData.values.isShowDesktop = state.isShowDesktop;
    newData.values.isShowMobile = state.isShowMobile;
    props.onUpdateData(newData);
  };
  const onClickCopy = () => {
    let newData = props.data;
    newData.values.text = state.text;
    newData.values.containerPadding = state.containerPadding;
    newData.values.labelPosition = state.labelPosition;
    newData.values.icon = state.icon;
    newData.values.link = state.link;
    newData.values.typeButton = state.typeButton;
    newData.values.border = state.border;
    newData.values.mobile = state.mobile;
    newData.values.desktop = state.desktop;
    newData.values.isShowDesktop = state.isShowDesktop;
    newData.values.isShowMobile = state.isShowMobile;
    localStorage.setItem("FlatButtonView", JSON.stringify(newData.values));
  };
  const onClickPaste = () => {
    const ButtonViewContent = JSON.parse(
      localStorage.getItem("FlatButtonView")
    );
    if (ButtonViewContent) {
      const {
        labelPosition,
        icon,
        containerPadding,
        text,
        typeButton,
        border,
        mobile,
        desktop,
        isShowDesktop,
        isShowMobile,
        link,
      } = ButtonViewContent;
      setState({
        ...state,
        icon: icon,
        labelPosition: labelPosition,
        containerPadding: containerPadding,
        link: link,
        typeButton: typeButton,
        text: text,
        border: border,
        mobile: mobile,
        dekstop: desktop,
        isShowDesktop: isShowDesktop,
        isShowMobile: isShowMobile,
      });
    }
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <BoxContent style={{ height: props.heightContent - 120 }}>
        <DialogEditContent
          item={state}
          onSetValue={(k, v) => onSetValue(k, v)}
        />
        <ButtonFields
          state={state}
          onSetValue={(k, v) => onSetValue(k, v)}
          heightContent={props.heightContent}
        />
        <Box width={[1]} py={[2]}>
          <HrefContent
            link={get(state, "link", {})}
            onSetValue={(k, v) => onSetValue(k, v)}
            websiteId={props.websiteId}
          />
        </Box>
      </BoxContent>
      <FooterAction
        onClickSave={onClickSave}
        onClickCopy={onClickCopy}
        onClickPaste={onClickPaste}
        widthContent={props.widthContent}
      />
    </Flex>
  );
};

FlatButtonView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

FlatButtonView.defaultProps = {
  data: {
    values: {
      urlSlug: {},
      border: {
        type: "solid",
        size: "0",
        color: "#e61919",
        radius: "0",
      },
    },
  },
};

export default FlatButtonView;
