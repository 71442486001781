import React from "react"
import PropTypes from "prop-types"
import { union, uniqBy, map } from "lodash"
import Chip from "@material-ui/core/Chip"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import { useQueryWithStore } from "react-admin"

const payload = {
  pagination: { page: 1, perPage: 1000 },
  sort: { field: "name", order: "ASC" },
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
    chip: {
      textTransform: "capitalize",
    },
  },
}))

const ArrayChipInput = props => {
  const classes = useStyles()
  const { dataOptions, resource, input, optionText } = props
  const { data } = useQueryWithStore({
    type: "getList",
    resource: resource,
    payload: payload,
  })
  const dataList = data ? data : []

  const valueOptions = dataList.filter(function(item) {
    for (var key in dataOptions) {
      if (item.id === undefined || item.id === dataOptions[key]) return true
    }
    return false
  })

  const newData = union(valueOptions, dataList)
  const listData = map(newData, item => {
    return item
  })
  const onChangeChip = (e, v) => {
    const valueOptions1 = map(v, item => {
      return item.id
    })
    props.updateField(input.name, valueOptions1)
  }
  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        disableClearable
        id={`${resource}-${input.name}`}
        options={uniqBy(listData, "id")}
        value={valueOptions}
        onChange={onChangeChip}
        filterSelectedOptions={true}
        getOptionLabel={option => option[optionText]}
        style={{ width: "100%" }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            return (
              <Chip
                label={option[optionText]}
                {...getTagProps({ index })}
                color="primary"
                style={{ color: "#FFFFFF" }}
              />
            )
          })
        }
        renderInput={params => (
          <TextField
            fullWidth
            {...params}
            variant="standard"
            label=""
            placeholder=""
          />
        )}
      />
    </div>
  )
}

ArrayChipInput.propTypes = {
  updateField: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
  dataOptions: PropTypes.array,
  optionText: PropTypes.string.isRequired,
}

ArrayChipInput.defaultProps = {
  optionText: "name",
}
export default ArrayChipInput
