import React from "react"

const ChaseLoader = (props) => {
  return (
    <div className="sk-chase">
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
    </div>
  )
}
const Plane = (props) => {
  return <div className="sk-plane"></div>
}
const BounceLoader = (props) => {
  return (
    <div className="sk-bounce">
      <div className="sk-bounce-dot"></div>
      <div className="sk-bounce-dot"></div>
    </div>
  )
}
const PulseLoader = (props) => {
  return <div className="sk-pulse"></div>
}
const FlowLoader = (props) => {
  return (
    <div className="sk-flow">
      <div className="sk-flow-dot"></div>
      <div className="sk-flow-dot"></div>
      <div className="sk-flow-dot"></div>
    </div>
  )
}

const SwingDot = (props) => {
  return (
    <div className="sk-swing">
      <div className="sk-swing-dot"></div>
      <div className="sk-swing-dot"></div>
    </div>
  )
}
const CircleLoader = (props) => {
  return (
    <div className="sk-circle">
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
    </div>
  )
}
const CircleFade = (props) => {
  return (
    <div className="sk-circle-fade">
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
    </div>
  )
}
const GridLoader = (props) => {
  return (
    <div className="sk-grid">
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
    </div>
  )
}
const Fold = (props) => {
  return (
    <div className="sk-fold">
      <div className="sk-fold-cube"></div>
      <div className="sk-fold-cube"></div>
      <div className="sk-fold-cube"></div>
      <div className="sk-fold-cube"></div>
    </div>
  )
}
const Wander = (props) => {
  return (
    <div className="sk-wander">
      <div className="sk-wander-cube"></div>
      <div className="sk-wander-cube"></div>
      <div className="sk-wander-cube"></div>
    </div>
  )
}
const Wave = (props) => {
  return (
    <div className="sk-wave" style={{ color: "inherit" }}>
      <div className="sk-wave-rect" style={{ color: "inherit" }}></div>
      <div className="sk-wave-rect" style={{ color: "inherit" }}></div>
      <div className="sk-wave-rect" style={{ color: "inherit" }}></div>
      <div className="sk-wave-rect"></div>
      <div className="sk-wave-rect"></div>
    </div>
  )
}

export {
  Wave,
  Wander,
  ChaseLoader,
  Fold,
  FlowLoader,
  CircleFade,
  CircleLoader,
  GridLoader,
  SwingDot,
  Plane,
  PulseLoader,
  BounceLoader,
}
