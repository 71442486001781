import React, { cloneElement, Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { NavLink } from "react-router-dom"
import MenuItem from "@material-ui/core/MenuItem"
import { withStyles, createStyles } from "@material-ui/core/styles"
import { HOVER_BG_MENU, ACTIVE_BG_MENU } from "../../utils"
// import { inputStyles } from "../../themes"

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      flexDirection: "row",
      color: "#FFFFFF",
      paddingTop: 15,
      paddingBottom: 15,
      fontSize: 12,
      backgroundColor: "transparent",
      letterSpacing: 3,
      "&:hover": {
        backgroundColor: HOVER_BG_MENU,
      },
      paddingLeft: 60,
    },

    active: {
      fontSize: 12,
      fontWeight: "bold",
      alignItems: "center",
      backgroundColor: ACTIVE_BG_MENU,
      color: "#FFFFFF",
      "&:hover": {
        color: "#FFFFFF",
      },
    },

    active1: {
      fontSize: 12,
      fontWeight: "bold",
      alignItems: "center",
      backgroundColor: "transparent",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: HOVER_BG_MENU,
      },
    },
    icon: { paddingRight: "1.2em", display: "flex", size: 24 },
  })

export class MenuItemLink extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    leftIcon: PropTypes.node,
    onClick: PropTypes.func,
    primaryText: PropTypes.node,
    staticContext: PropTypes.object,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  }

  handleMenuTap = (e) => {
    this.props.onClick && this.props.onClick(e)
  }

  render() {
    const {
      classes,
      className,
      primaryText,
      leftIcon,
      staticContext,
      ...props
    } = this.props
    return (
      <MenuItem
        className={classnames(classes.root, className)}
        activeClassName={
          primaryText === "Dashboard" ? classes.active1 : classes.active
        }
        component={NavLink}
        {...props}
        onClick={this.handleMenuTap}
      >
        {leftIcon && (
          <span className={classes.icon}>
            {cloneElement(leftIcon, { titleAccess: primaryText })}
          </span>
        )}
        {primaryText}
      </MenuItem>
    )
  }
}

export default withStyles(styles)(MenuItemLink)
