import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout18 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const SocialMediaView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='SocialMediaView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

SocialMediaView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};

SocialMediaView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "SocialMediaView",
    desktop: {
      show: true,
      containerPadding: "0px",
      size: 80,
      align: "center",
    },
    mobile: {
      show: false,
      containerPadding: "0px",
      size: 80,
      align: "center",
    },
    list: [
      {
        url: "",
        target: "same",
        type: "facebook",
        color: "#475993",
      },
      {
        url: "",
        target: "same",
        type: "youtube",
        color: "#F61C0D",
      },
      {
        url: "",
        target: "same",
        type: "instagram",
        color: "#E09B3D",
      },
      {
        url: "",
        target: "same",
        type: "twitter",
        color: "#76A9EA",
      },
    ],
  },
  layout: Layout18,
};
//wacolor:#7AD06D
export default SocialMediaView;
