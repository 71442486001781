import React, { useState } from "react";
// import { withStyles } from "@material-ui/core/styles"
import { useTranslate } from "react-admin";
// import { styles } from "./styles"
import SubMenuItem from "./SubMenuItem";
import MenuItemLink from "../MenuItemLink";
import { SettingsIcon } from "../../icon_svg";

const MenuAdmin = (props) => {
  const { classes, onMenuClick, isHide } = props;
  const [openMenu, setOpenMenu] = useState(false);
  const translate = useTranslate();
  return (
    <SubMenuItem
      handleToggle={() => setOpenMenu(!openMenu)}
      isOpen={openMenu}
      classes={classes}
      sidebarIsOpen={openMenu}
      name='resources.menus.admin'
      icon={<SettingsIcon />}
    >
      {/* <MenuItemLink
        to={`/sites`}
        primaryText={translate(`resources.menus.sites`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      /> */}
      {!isHide && (
        <MenuItemLink
          to={`/users`}
          primaryText={translate(`resources.menus.users`, {
            smart_count: 2,
          })}
          onClick={onMenuClick}
        />
      )}
      {/* <MenuItemLink
        to={`/settings`}
        primaryText={translate(`resources.menus.settings`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      /> */}
    </SubMenuItem>
  );
};

export default MenuAdmin;
