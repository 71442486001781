import React from "react"
import FormatIconLeft from "@material-ui/icons/FormatAlignLeft"
import FormatIconCenter from "@material-ui/icons/FormatAlignCenter"
import FormatIconRight from "@material-ui/icons/FormatAlignRight"
import { Radio, RadioGroup } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { Flex } from "rebass"
import { Title } from "../text"

export const AlignMentContent = ({ onChange, name, value, title }) => {
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      flexWrap={"wrap"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      {title && <Title title={title} />}
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="alignment"
          name={name}
          value={value}
          onChange={onChange}
          row
        >
          <FormControlLabel
            value="left"
            control={<Radio color="primary" />}
            label={
              value === "left" ? (
                <FormatIconLeft color={"primary"} />
              ) : (
                <FormatIconLeft color={"secondary"} />
              )
            }
            labelPlacement="end"
          />
          <FormControlLabel
            value="center"
            label={
              value === "center" ? (
                <FormatIconCenter color={"primary"} />
              ) : (
                <FormatIconCenter color={"secondary"} />
              )
            }
            control={<Radio color="primary" />}
            labelPlacement="end"
          />
          <FormControlLabel
            value="right"
            label={
              value === "right" ? (
                <FormatIconRight color={"primary"} />
              ) : (
                <FormatIconRight color={"secondary"} />
              )
            }
            control={<Radio color="primary" />}
            labelPlacement="end"
          />
        </RadioGroup>
      </FormControl>
    </Flex>
  )
  // <FormControl component="fieldset" classes={classes.formControl}>
  //   <FormLabel component="legend">Alignment</FormLabel>
  //   <RadioGroup
  //     aria-label={name}
  //     name={name}
  //     value={value}
  //     onChange={onChange}
  //   >
  //     <FormControlLabel
  //       value="left"
  //       label=""
  //       control={<Radio />}
  //       checkedIcon={<FormatIconLeft style={{ color: blue[500] }} />}
  //       icon={<FormatIconLeft style={{ color: green["A200"] }} />}
  //     />
  //     <FormControlLabel
  //       value="center"
  //       label=""
  //       checkedIcon={<FormatIconCenter style={{ color: blue[500] }} />}
  //       icon={<FormatIconCenter style={{ color: green["A200"] }} />}
  //       control={<Radio />}
  //     />
  //     <FormControlLabel
  //       value="right"
  //       label=""
  //       checkedIcon={<FormatIconRight style={{ color: blue[500] }} />}
  //       icon={<FormatIconRight style={{ color: green["A200"] }} />}
  //       control={<Radio />}
  //     />
  //   </RadioGroup>
  // </FormControl>
}
