import React from "react";
import { TextField, DateField, Filter, SearchInput } from "react-admin";
import { Box, Flex } from "rebass";
// import { lightBlue } from "@material-ui/core/colors";
import {
  BetterList,
  BetterShowButton,
  BetterEditButton,
  ListAction,
} from "../../components";
import { CheckIcon, CloseIcon } from "../../components/icon_svg";
//AddIcon,
// import { ROLE_USER } from "../../utils";
export { create as UserCreate, edit as UserEdit } from "./Create";
export { UserShow } from "./Show";

const ButtonShowEditPermissionView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    <BetterShowButton {...props} />
    <BetterEditButton {...props} />
    {/* {props.permissions === ROLE_USER.account_admin && (
      <BetterEditButton {...props} />
    )} */}
  </Flex>
);

// const UsersAction = ({ basePath, permissions }) => {
//   return (
//     <Flex
//       flexDirection={"row"}
//       justifyContent={"space-between"}
//       alignItems={"flex-end"}
//       style={{ minHeight: 80 }}
//       pt={[4]}
//     >
//       <Box></Box>
//       <Box>
//         {/* permissions === ROLE_USER.super_admin || */}
//         {permissions === ROLE_USER.account_admin && (
//           <CreateButton
//             basePath={basePath}
//             icon={<AddIcon />}
//             sizeIcon={"xsmall"}
//             style={{ color: lightBlue[700] }}
//           />
//         )}
//       </Box>
//     </Flex>
//   );
// };

const UsersFilter = ({ permissions, ...props }) => {
  return (
    <Box width={[1]} bg={"#FFFFFF"}>
      <Filter
        {...props}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          paddingBottom: 16,
          paddingRight: 16,
        }}
      >
        {/* {permissions === ROLE_USER.super_admin && (
          <ReferenceInput
            label='Loyalty Program'
            source='loyalty_program_id'
            reference='loyaltyPrograms'
            alwaysOn
            allowEmpty
            style={{ width: 300 }}
          >
            <SelectInput optionText='name' />
          </ReferenceInput>
        )} */}
        <SearchInput source='search' alwaysOn style={{ minWidth: 180 }} />
      </Filter>
    </Box>
  );
};

export const StatusField = ({ record }) => {
  return (
    <div style={{ textAlign: "center" }}>
      {record.status === 1 ? (
        <CheckIcon style={{ fontSize: 12 }} />
      ) : (
        <CloseIcon style={{ fontSize: 12 }} />
      )}
    </div>
  );
};
export const UserList = (props) => (
  <BetterList
    {...props}
    actions={<ListAction title={`resources.users.name`} />}
    filters={<UsersFilter permissions={props.permissions} />}
  >
    {/* {props.permissions === ROLE_USER.super_admin && (
      <ReferenceField
        source="loyalty_program_id"
        reference="loyaltyPrograms"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
    )} */}
    <TextField source='first_name' />
    <TextField source='last_name' />
    <TextField source='email' />
    <TextField source='mobile' />
    <TextField source='role' />
    <DateField source='created_at' />
    <StatusField source='status' {...props} />
    <ButtonShowEditPermissionView {...props} />
  </BetterList>
);
