import React from "react"
import { Flex } from "rebass"
import { get } from "lodash"
import { BlockCounter } from "../../components"

const ContainerImage = (props) => {
  const { data, onPositionMPValue, indexParent } = props

  const paddingImageMobile = get(data, "position.paddingImageMobile", "0px")
  const paddingImageDesktop = get(data, "position.paddingImageDesktop", "0px")
  const marginImageMobile = get(data, "position.marginImageMobile", "0px")
  const marginImageDesktop = get(
    data,
    "position.marginImageDesktop",
    "0px 0px 0px 50px"
  )

  return (
    <Flex
      flexDirection={"row"}
      flexWrap={"wrap"}
      p={[1]}
      className={"item-list"}
    >
      <BlockCounter
        idSwitch={`padding-image-mobile-${indexParent}`}
        containerPadding={paddingImageMobile}
        onUpdatePadding={(v) =>
          onPositionMPValue("paddingImageMobile", v, indexParent)
        }
        title={"Padding Mobile"}
      />
      <BlockCounter
        idSwitch={`padding-image-desktop-${indexParent}`}
        containerPadding={paddingImageDesktop}
        onUpdatePadding={(v) =>
          onPositionMPValue("paddingImageDesktop", v, indexParent)
        }
        title={"Padding Desktop"}
      />

      <BlockCounter
        idSwitch={`margin-image-mobile-${indexParent}`}
        containerPadding={marginImageMobile}
        onUpdatePadding={(v) =>
          onPositionMPValue("marginImageMobile", v, indexParent)
        }
        title={"Margin Mobile"}
      />
      <BlockCounter
        idSwitch={`margin-image-desktop-${indexParent}`}
        containerPadding={marginImageDesktop}
        onUpdatePadding={(v) =>
          onPositionMPValue("marginImageDesktop", v, indexParent)
        }
        title={"Margin Desktop"}
      />
    </Flex>
  )
}
const ContainerTitle = (props) => {
  const { data, onPositionMPValue, indexParent } = props

  const paddingTitleMobile = get(data, "position.paddingTitleMobile", "0px")
  const paddingTitleDesktop = get(data, "position.paddingTitleDesktop", "0px")
  const marginTitleMobile = get(data, "position.marginTitleMobile", "0px")
  const marginTitleDesktop = get(
    data,
    "position.marginTitleDesktop",
    "0px 0px 0px 100px"
  )
  return (
    <Flex
      flexDirection={"row"}
      flexWrap={"wrap"}
      p={[1]}
      className={"item-list"}
    >
      <BlockCounter
        idSwitch={`padding-title-mobile-${indexParent}`}
        containerPadding={paddingTitleMobile}
        onUpdatePadding={(v) =>
          onPositionMPValue("paddingTitleMobile", v, indexParent)
        }
        title={"Padding Mobile"}
      />
      <BlockCounter
        idSwitch={`padding-title-desktop-${indexParent}`}
        containerPadding={paddingTitleDesktop}
        onUpdatePadding={(v) =>
          onPositionMPValue("paddingTitleDesktop", v, indexParent)
        }
        title={"Padding Desktop"}
      />
      <BlockCounter
        idSwitch={`margin-title-mobile-${indexParent}`}
        containerPadding={marginTitleMobile}
        onUpdatePadding={(v) =>
          onPositionMPValue("marginTitleMobile", v, indexParent)
        }
        title={"Margin Mobile"}
      />
      <BlockCounter
        idSwitch={`margin-title-desktop-${indexParent}`}
        containerPadding={marginTitleDesktop}
        onUpdatePadding={(v) =>
          onPositionMPValue("marginTitleDesktop", v, indexParent)
        }
        title={"Margin Desktop"}
      />
    </Flex>
  )
}

export { ContainerImage, ContainerTitle }
