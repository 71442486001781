import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Slider from "@material-ui/core/Slider"
import { Title } from "../text"
import { Box } from "rebass"

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider)

const MySlider = props => {
  const { value, title, onChange, min = 0, max = 100, step = 1 } = props
  const handleChange = (event, newValue) => {
    onChange(newValue)
  }
  return (
    <Box width={[1]} p={[2]}>
      {title && <Title title={title} />}
      <PrettoSlider
        min={min}
        valueLabelDisplay="auto"
        aria-label={`${title}-slider`}
        value={value}
        step={step}
        max={max}
        onChange={handleChange}
      />
    </Box>
  )
}

export default MySlider
