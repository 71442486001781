import { Layout1 } from "../../TemplateImageSonak";
export const template1A = {
  kDjuUOFv: {
    id: "kDjuUOFv",
    type: "Row1",
    props: {
      values: {
        name: "Row1",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
            contentAlign: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "4px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(74, 74, 74, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
            contentAlign: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "4px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(74, 74, 74, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              parent: "Row",
              desktop: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                padding: "0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    childOf: "Row",
    children: ["zY3ClsI0"],
    layout: Layout1,
    content: {
      Rsy4A62z: {
        id: "Rsy4A62z",
        type: "TextGroupView",
        props: {
          values: {
            type: "TextGroupView",
            align: "center",
            color: "rgba(255, 255, 255, 1)",
            parent: "Content",
            setting: {
              dots: false,
              speed: 1000,
              arrows: false,
              cssEase: "linear",
              autoplay: true,
              infinite: true,
              vertical: true,
              slidesToShow: 1,
              autoplaySpeed: 3000,
              adaptiveHeight: true,
              slidesToScroll: 1,
            },
            fontSize: {
              mobile: 6,
              desktop: 10,
            },
            valueText: [
              {
                icon:
                  "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/img-image-desktop-X1V.png",
                title:
                  "Free shipping for all products with purchase above Php1.000",
                iconPosition: "flex-start",
              },
              {
                icon: "",
                title: "Sale 10 % discount for all products",
                iconPosition: "",
              },
            ],
            lineHeight: "120%",
            letterSpacing: "1px",
            backgroundColor: "rgba(255,255,255,0)",
            containerPadding: "4px",
          },
        },
        parent: {
          id: "zY3ClsI0",
          idx: 0,
        },
        childOf: "Content",
        children: [],
      },
    },
  },
};
