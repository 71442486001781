import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import {
  BlockCounter,
  BlockCounterWithType,
  Counter,
  Title,
  SelectInput,
} from "../components";
import { ContentDetail } from "../actions";
const datasize = [
  { text: "Contain", value: "contain" },
  { text: "Cover", value: "cover" },
];

export default (props) => {
  const { state, onSetValue, title } = props;
  const myOrder = get(state, "order", 1);
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      flexWrap={"wrap"}
      sx={{
        "&>div": {
          width: ["320px"],
          p: [2],
          mr: [3],
        },
      }}
    >
      <ContentDetail>
        <BlockCounterWithType
          name='imagewidth'
          value={state.width}
          onSetValue={(v) => onSetValue("width", v)}
          title={"Width"}
        />
      </ContentDetail>
      <ContentDetail>
        <BlockCounterWithType
          name='imageheight'
          value={state.height}
          onSetValue={(v) => onSetValue("height", v)}
          title={"Height"}
        />
      </ContentDetail>
      <ContentDetail>
        <Flex
          flexDirection={"row"}
          width={[1]}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Title title='Order' />
          <Counter
            onSetValue={(value) => onSetValue("order", value)}
            value={myOrder}
            interval={1}
            subtitle=''
            minValue={1}
          />
        </Flex>
      </ContentDetail>
      <BlockCounter
        title='Container Padding'
        containerPadding={state.containerPadding}
        onUpdatePadding={(cp) => onSetValue("containerPadding", cp)}
      />
      <BlockCounter
        title='Container Margin'
        containerPadding={state.containerMargin}
        onUpdatePadding={(cp) => onSetValue("containerMargin", cp)}
      />
      <ContentDetail>
        <SelectInput
          id={`mode${title}`}
          title={"Mode"}
          value={get(state, "mode", "contain")}
          list={datasize}
          onChange={(e) => onSetValue("mode", e.target.value)}
        />
      </ContentDetail>
    </Flex>
  );
};
