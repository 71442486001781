import React from "react"
import YouTube from "react-youtube"
import { connect } from "../../../core"

const YoutubeView = (props) => {
  const { id, layoutState } = props
  const { containerPadding, alignValue, videoId } = layoutState.getItemJS(
    id
  ).props.values

  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        display: "flex",
        padding: containerPadding,
        flex: 1,
        justifyContent: alignValue,
      }}
    >
      <YouTube videoId={videoId} />
    </div>
  )
}

export default connect("layoutState")(YoutubeView)
