import { makeStyles } from "@material-ui/core/styles"
import { HOVER_BG_MENU } from "../../../utils"
export const styles = {
  listItem: {
    paddingTop: 10,
    paddingBottom: 10,
    color: "rgba(0, 0, 0, 0.54)",
    "&:hover": {
      backgroundColor: HOVER_BG_MENU,
    },
  },
  listItemText: {
    textAlign: "left",
    paddingLeft: 0,
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 12,
    letterSpacing: 3,
  },
  listItemIcon: {
    padding: 0,
    minWidth: 24,
    color: "rgba(0, 0, 0, 0.54)",
    paddingRight: 16,
  },
  text: { color: "rgba(0, 0, 0, 0.54)" },
  sidebarIsOpen: {
    paddingLeft: 0,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
}

export const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingTop: 10,
    paddingBottom: 10,
    color: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: HOVER_BG_MENU,
    },
  },
  listItemText: {
    textAlign: "left",
    paddingLeft: 0,
    color: theme.palette.primary.dark,
    fontSize: 12,
    letterSpacing: 3,
  },
  listItemIcon: {
    padding: 0,
    minWidth: 24,
    color: theme.palette.primary.dark,
    paddingRight: 16,
  },
  text: { color: theme.palette.primary.dark },
  sidebarIsOpen: {
    paddingLeft: 0,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
}))
