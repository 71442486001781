import React from "react";
import { Flex } from "rebass";
import { get, filter } from "lodash";
import Desktop from "./Desktop";
import Desktop1 from "./Desktop1";
import Desktop2 from "./Desktop2";
import Desktop3 from "./Desktop3";
import MobileView from "./MobileView";
// import { useQueryWithStore } from "react-admin";
import { getTreeFromFlatData } from "./utils";

const MenuNavBar = (props) => {
  const { widthScreen, values, listMenu } = props;

  // console.log(widthScreen)
  // const { data, loading } = useQueryWithStore({
  //   type: "getList",
  //   resource: "categories",
  //   payload: {
  //     pagination: { page: 1, perPage: 1000 },
  //     sort: { field: "name", order: "ASC" },
  //     filter: { website_id: get(props, "websiteId", 0) },
  //   },
  // });
  // console.log(get(props, "websiteId", 0));
  // const { data, loading } = useQueryWithStore({
  //   type: "getList",
  //   resource: "websiteLayouts",
  //   payload: {
  //     pagination: { page: 1, perPage: 10 },
  //     sort: { field: "id", order: "ASC" },
  //     filter: { website_id: get(props, "websiteId", 0) },
  //   },
  // });

  // if (loading) {
  //   return <div />;
  // }

  const settings = {
    header: {
      image: values.logo,
      list: values.list,
      titleButton: values.titleButton,
    },
  };
  const cart = {
    items: [],
    amount: {
      subtotal: 0,
      total: 0,
    },
  };
  const menuOne = filter(listMenu, function (o) {
    return o.default === true;
  });
  // console.log(data);
  // console.log(listMenu);

  const dataSource = menuOne.length > 0 ? get(menuOne[0], "menu", []) : [];
  const flatToTreeData = getTreeFromFlatData({
    flatData: dataSource.map((node) => ({ ...node, title: node.title })),
    getKey: (node) => node.id,
    getParentKey: (node) => node.parent_id,
    rootKey: "root",
  });
  // console.log(flatToTreeData);
  return (
    <Flex width={[1]} justifyContent={"center"} alignItems={"center"}>
      {widthScreen > 768 ? (
        values.modelHeader === 0 ? (
          <Desktop
            settings={settings}
            cart={cart}
            categories={flatToTreeData}
          />
        ) : values.modelHeader === 1 ? (
          <Desktop1
            settings={settings}
            cart={cart}
            categories={flatToTreeData}
          />
        ) : values.modelHeader === 2 ? (
          <Desktop2
            settings={settings}
            cart={cart}
            categories={flatToTreeData}
          />
        ) : (
          <Desktop3
            settings={settings}
            cart={cart}
            categories={flatToTreeData}
          />
        )
      ) : (
        <MobileView
          settings={settings}
          cart={cart}
          categories={flatToTreeData}
        />
      )}
    </Flex>
  );
  // return <div>hello world Menu bar</div>
};

export default MenuNavBar;
