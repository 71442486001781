import React from "react";
import { connect } from "../../../core";
import { MenuGroupView } from "../../../libs/menugroup";
const ContainerMenuGroupView = (props) => {
  const { id, layoutState, widthScreen } = props;
  const { containerPadding } = layoutState.getItemJS(id).props.values;
  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        width: "100%",
        display: "flex",
        flex: 1,
      }}
    >
      <div style={{ padding: containerPadding, width: "100%" }}>
        <MenuGroupView
          values={layoutState.getItemJS(id).props.values}
          widthScreen={widthScreen}
        />
      </div>
    </div>
  );
};

export default connect(
  "layoutState",
  "widthScreen",
  "websiteId"
)(ContainerMenuGroupView);
