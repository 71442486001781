import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Flex, Box } from "rebass";
import { filter, find } from "lodash";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { withDataProvider, Loading } from "react-admin";
import { DialogContent, DialogTitle, styles } from "./DialogFields";
import { EmptyList } from "../../../components";
import TableProduct from "./TableProduct";
import ProductItem from "./amendment_item/ProductItem";

const DialogProducts = (props) => {
  const { translate, classes, dataProvider, setItems, items } = props;

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      dataProvider
        .getList("products", {
          pagination: { page: 1, perPage: 10 },
          sort: { field: "name", order: "ASC" },
          filter: { search: search },
        })
        .then(({ data }) => {
          // console.log(data);
          if (data) {
            setProducts(data);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }, 1000);

    return () => clearTimeout(timer);
  }, [search, dataProvider]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSelected = (product) => {
    // console.log(props.items, product);
    const myItems = props.items;
    myItems.push(product);
    const isHave = find(myItems, function (o) {
      return o.sku === product.sku;
    });
    if (myItems.length > 0) {
      if (!isHave) {
        myItems.push(product);
      }
    } else {
      myItems.push(product);
    }
    setItems(myItems);
    setOpen(false);
  };
  const onDelete = (product) => {
    let myItems = filter(items, function (oi) {
      return oi.sku !== product.sku;
    });

    setItems(myItems);

    console.log(product, myItems);
  };
  return (
    <div>
      <Button
        color='primary'
        onClick={handleClickOpen}
        className={classes.button}
        startIcon={<AddCircleOutlineIcon />}
      >
        {translate("resources.buttons.add_product")}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {translate("resources.buttons.add_product")}
        </DialogTitle>
        <DialogContent dividers>
          <Flex width={[1]} flexDirection={"column"}>
            <Flex
              width={[1]}
              style={{
                backgroundColor: "#FFFFFF",
              }}
            >
              <Box
                width={[1]}
                p={[2]}
                style={{
                  backgroundColor: "#FFFFFF",
                  position: "absolute",
                  zIndex: 2,
                }}
              >
                <FormControl
                  fullWidth
                  variant='outlined'
                  style={{ backgroundColor: "#FFFFFF" }}
                >
                  <TextField
                    id='standard-search'
                    label=''
                    variant={"outlined"}
                    style={{ width: "100%", backgroundColor: "#FFFFFF" }}
                    // onChange={onSearch}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type='search'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Box>
            </Flex>
            {loading ? (
              <Box width={[1]} p={[2]}>
                <Loading />
              </Box>
            ) : (
              <Box width={[1]} p={[2]} mt={["70px"]}>
                {products.length > 0 ? (
                  products.map((product) => {
                    let newValues = [];
                    return (
                      <TableProduct
                        key={product.id}
                        {...props}
                        product={product}
                        selectedProduct={[]}
                        setSelectedProduct={onSelected}
                        values={newValues}
                      />
                    );
                  })
                ) : (
                  <Box width={[1]}>
                    <EmptyList message={"resources.errors.no_have_product"} />
                  </Box>
                )}
              </Box>
            )}
          </Flex>
        </DialogContent>
      </Dialog>
      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        sx={{
          "&>div": {
            p: [1],
          },
        }}
      >
        {items &&
          items.length > 0 &&
          items.map((o, index) => {
            return (
              <Flex key={index} width={[1 / 2]}>
                <ProductItem
                  item={o.product}
                  data={o}
                  translate={translate}
                  onDelete={() => onDelete(o)}
                />
              </Flex>
            );
          })}
      </Flex>
    </div>
  );
};

export default withStyles(styles)(withDataProvider(DialogProducts));
