import { Record, fromJS } from "immutable"

const defaultItems = fromJS({
  root: {
    id: "root",
    type: "Body",
    props: {
      values: {
        backgroundColor: "#e7e7e7",
        backgroundImage: {
          url: "",
          fullWidth: true,
          repeat: false,
          center: true,
          cover: false,
        },
        fullheight: true,
        contentWidth: "500px",
        fontFamily: {
          label: "Arial",
          value: "arial,helvetica,sans-serif",
        },
      },
    },
    children: [],
  },
})

class LayoutState extends Record({ items: defaultItems, selectedItem: null }) {
  getItemJS(id) {
    const item = this.items.get(id)
    return item && item.toJS()
  }

  toRaw() {
    return this.items.toJS()
  }
}

LayoutState.fromRaw = raw => {
  let layoutState = new LayoutState()
  return layoutState.set("items", fromJS(raw))
}

export default LayoutState
