import React, { Component } from "react"
import PropTypes from "prop-types"
import { Text, Box } from "rebass"
import styled from "styled-components"
import "./index.css"
class Counter extends Component {
  // constructor(props){
  //   super(props);
  // }
  onIncrement = (value) => {
    const { interval, maxValue } = this.props
    if (maxValue > 0) {
      if (value <= maxValue) {
        this.props.onSetValue(value + interval)
      }
    } else {
      this.props.onSetValue(value + interval)
    }
  }
  onDecrement = (value) => {
    if (value > this.props.minValue) {
      const { interval } = this.props
      this.props.onSetValue(value - interval)
    }
  }
  OnChangeText = (event, value) => {
    if (!isNaN(event.target.value)) {
      const { maxValue } = this.props
      if (maxValue > 0) {
        if (event.target.value <= maxValue) {
          this.props.onSetValue(event.target.value * 1)
        }
      } else {
        this.props.onSetValue(event.target.value * 1)
      }
    }
  }
  render() {
    let { value, title, subtitle, isDisabled } = this.props
    return (
      <Box p={[1]}>
        <Text fontSize={[0]} fontWeight={"bold"} pb={[1]}>
          {title}
        </Text>
        <DivContainer>
          <div className="blockbuilder-counter-control">
            <span
              className="blockbuilder-counter-control-btn"
              onClick={() => this.onDecrement(value)}
            >
              -
            </span>
            <input
              className="blockbuilder-counter-control-value"
              onChange={this.OnChangeText.bind(this)}
              name="value"
              value={value}
              disabled={isDisabled}
            />
            <span
              className="blockbuilder-counter-control-btn"
              onClick={() => this.onIncrement(value)}
            >
              +
            </span>
          </div>
          <div style={{ paddingLeft: 10 }}>{subtitle}</div>
        </DivContainer>
      </Box>
    )
  }
}
const DivContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
Counter.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  interval: PropTypes.number,
  onSetValue: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  minValue: PropTypes.number,
  isDisabled: PropTypes.bool,
}

Counter.defaultProps = {
  value: 0,
  title: "",
  interval: 1,
  subtitle: "",
  isDisabled: false,
  minValue: 0,
  maxValue: 0,
}

export default Counter
