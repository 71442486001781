import React, { Component } from "react";
import { Flex, Box, Text } from "rebass";
import { IconButton, Dialog, Button } from "@material-ui/core";
import {
  DialogTitle,
  DialogActions,
  DialogContent,
} from "../components/listslide/styles";
import ActionAdd from "@material-ui/icons/AddCircle";
import ActionDelete from "@material-ui/icons/Delete";
import { DialogHrefContent, TextInput } from "../components";

class ListCustom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: props.list || [],
      open: false,
    };
  }
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleSave = () => {
    this.setState({ open: false });
    this.props.onSetValue("list", this.state.list);
  };
  onAddText = () => {
    const newList = this.state.list;
    newList.push({
      title: "Title",
      url: "",
      isLink: false,
      target: "same",
    });
    this.setState({
      list: newList,
    });
  };
  onDelete = (index) => {
    const newList = this.arrayRemove(this.state.list, index);
    this.setState({
      list: newList,
    });
    this.props.onChangeText(newList);
  };
  arrayRemove(arr, value) {
    arr.splice(value, 1);

    return arr;
  }
  onUpdateText = (value, index) => {
    const { list } = this.state;
    list[index].title = value;
    this.setState({
      list: list,
    });
  };
  onUpdateSlug = (value, index) => {
    const { list } = this.state;
    list[index].slug = value;
    this.setState({
      list: list,
    });
  };
  onUpdateTarget = (value, index) => {
    const { list } = this.state;
    list[index].target = value;
    this.setState({
      list: list,
    });
    // this.props.onChangeText(list)
  };
  onUpdateLink = (value, index) => {
    const { list } = this.state;
    list[index].url = value;
    this.setState({
      list: list,
    });
  };
  onToggleActionIsLink = (value, index) => {
    const { list } = this.state;
    list[index].isLink = value;
    this.setState({
      list: list,
    });
  };
  onSetItemLinkValue = (value, index) => {
    const { list } = this.state;
    list[index].link = value;
    this.setState({
      list: list,
    });
  };
  render() {
    const { list, open } = this.state;

    return (
      <div>
        <Button variant='outlined' onClick={this.handleOpen} color={"primary"}>
          List
        </Button>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          fullScreen={true}
          scroll={"paper"}
        >
          <DialogTitle id='customized-dialog-title' onClose={this.handleClose}>
            Content of List
          </DialogTitle>
          <DialogContent dividers>
            <Box width={[1]}>
              <Flex
                width={[1]}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Box width={[1]}>
                  <Text>Items</Text>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => this.onAddText()}
                    style={{ color: "rgb(0, 188, 212)" }}
                  >
                    <ActionAdd color={"inherit"} />
                  </IconButton>
                </Box>
              </Flex>
              <Box width={[1]}>
                {list.length > 0 &&
                  list.map((item, index) => {
                    return (
                      <Flex
                        key={index}
                        width={[1]}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        align={"center"}
                        style={{
                          paddingBottom: 4,
                          borderBottomWidth: 1,
                          borderBottomColor: "rgba(0,0,0,0.2)",
                          borderBottomStyle: "solid",
                        }}
                      >
                        <Box width={[1]}>
                          <Flex width={[1]} flexDirection={"row"}>
                            <Box width={[1]}>
                              <TextInput
                                value={item.title}
                                onChange={(value) =>
                                  this.onUpdateText(value, index)
                                }
                                name={`text-${index}`}
                                title='Title'
                                fullWidth={true}
                              />
                              <Box width={[1]}>
                                <DialogHrefContent
                                  link={item.link}
                                  onSetValue={(v) =>
                                    this.onSetItemLinkValue(v, index)
                                  }
                                  websiteId={this.props.websiteId}
                                />
                              </Box>
                              {/* <TextInput
                                value={item.url}
                                onChange={(value) =>
                                  this.onUpdateLink(value, index)
                                }
                                name={`url-${index}`}
                                title="Url"
                                fullWidth={true}
                              /> */}
                              <Box pt={[2]}>
                                {/* <SwitchInput
                                  id={`isLink-${index}`}
                                  title={"IsLink"}
                                  checked={item.isLink}
                                  message={
                                    "Please checked when Using 'Https://' or outside url"
                                  }
                                  onChange={(e) =>
                                    this.onToggleActionIsLink(
                                      e.target.checked,
                                      index
                                    )
                                  }
                                /> */}
                              </Box>
                              {/* <SelectInput
                                id={`target`}
                                title={"Target"}
                                value={item.target}
                                list={dataHref}
                                onChange={(e) =>
                                  this.onUpdateTarget(e.target.value, index)
                                }
                              /> */}
                            </Box>
                          </Flex>
                        </Box>
                        <Box>
                          {list.length > 1 && (
                            <IconButton
                              onClick={() => this.onDelete(index)}
                              style={{ color: "rgb(0, 188, 212)" }}
                            >
                              <ActionDelete color={"inherit"} />
                            </IconButton>
                          )}
                        </Box>
                      </Flex>
                    );
                  })}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Cancel
            </Button>
            <Button onClick={this.handleSave} color='primary'>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ListCustom;
