import React from "react";
import { SwitchInput, Title, SelectInput, TextInput, BlockCounter } from "../";
import { ContentDetail } from "../../actions";
import { UploadAvatar } from "../../../../components";
import { Flex, Box } from "rebass";
import { get } from "lodash";
import { LayoutBGContent } from "../layout";
const dataRatio = [
  { value: 1, text: "1" },
  { value: 1.2, text: "1.2" },
  { value: 1.3, text: "1.3" },
  { value: 1.4, text: "1.4" },
  { value: 1.5, text: "1.5" },
  { value: 1.6, text: "1.6" },
  { value: 1.7, text: "1.7" },
  { value: 1.8, text: "1.8" },
  { value: 1.9, text: "1.9" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
];
const datasize = [
  { text: "Contain", value: "contain" },
  { text: "Cover", value: "cover" },
];
const datarepeat = [
  { value: "no-repeat", text: "No Repeat" },
  { value: "repeat", text: "Repeat" },
];
const dataImagePosition = [
  { value: "left", text: "Left" },
  { value: "right", text: "Right" },
  { value: "top center", text: "Top Center" },
  { value: "center", text: "Center" },
  { value: "bottom center", text: "Bottom Center" },
  { value: "left top", text: "Left Top" },
  { value: "left center", text: "Left Center" },
  { value: "left bottom", text: "Left Bottom" },
  { value: "right top", text: "Right Top" },
  { value: "right center", text: "Right Center" },
  { value: "right bottom", text: "Right Bottom" },
];

/**
 * left top
left center
left bottom
right top
right center
right bottom
center top
center center
center bottom
 */

/**
 * bgProps: {
      show: false,
      content: {
        url: "",
        size: "cover",
        position: "center",
        repeat: "no-repeat",
        ratio: 1.2,
      },
    },
 */

export default (props) => {
  const { onSetValue, state, contentId } = props;

  const onSetItemValue = (k, v) => {
    const newbgProps = {
      ...state,
      [k]: v,
    };
    onSetValue(newbgProps);
  };

  const onSetContentValue = (k, v) => {
    const newContent = {
      ...state.content,
      [k]: v,
    };
    onSetItemValue("content", newContent);
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <SwitchInput
        id={`${contentId}-bg-content-show`}
        title={"Using background image"}
        checked={state.show}
        onChange={(e) => onSetItemValue("show", e.target.checked)}
      />
      {state.show && (
        <Box width={[1]}>
          <ContentDetail>
            <SelectInput
              id={`size${contentId}`}
              title={"Size"}
              value={get(state, "content.size", "")}
              list={datasize}
              onChange={(e) => onSetContentValue("size", e.target.value)}
            />
          </ContentDetail>
          <ContentDetail>
            <SelectInput
              id={`position${contentId}`}
              title={"Position"}
              value={get(state, "content.position", "")}
              list={dataImagePosition}
              onChange={(e) => onSetContentValue("position", e.target.value)}
            />
          </ContentDetail>
          <ContentDetail>
            <SelectInput
              id={`repeat${contentId}`}
              title={"Repeat"}
              value={get(state, "content.repeat", "")}
              list={datarepeat}
              onChange={(e) => onSetContentValue("repeat", e.target.value)}
            />
          </ContentDetail>
          <ContentDetail>
            <SelectInput
              id={`ratio${contentId}`}
              title={"Ratio"}
              value={get(state, "content.ratio", "")}
              list={dataRatio}
              onChange={(e) => onSetContentValue("ratio", e.target.value)}
            />
          </ContentDetail>
          <ContentDetail>
            <Title title={"Background Image"} />
            <UploadAvatar
              type={"ease"}
              imageUrl={get(state, "content.url", "")}
              onUpdateUrl={(v) => onSetContentValue("url", v)}
              title='Image'
            />
          </ContentDetail>
          <ContentDetail>
            <TextInput
              title={"Height Background"}
              value={get(state, "content.height", "100%")}
              name={`height-background${contentId}`}
              onChange={(v) => onSetContentValue("height", v)}
              fullWidth={true}
            />
          </ContentDetail>
          <LayoutBGContent
            onSetValue={(v) => onSetContentValue("layout", v)}
            state={get(state, "content.layout", {})}
            contentId={`bg-layout${contentId}`}
          />
          <BlockCounter
            containerPadding={get(state, "content.margin", "0px")}
            onUpdatePadding={(value) => onSetContentValue("margin", value)}
            title={"Background Margin"}
          />
        </Box>
      )}
    </Flex>
  );
};
