import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout9 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const FAButtonView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='FAButtonView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};
FAButtonView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  layout: PropTypes.func,
};

FAButtonView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "FAButtonView",
    containerPadding: "0px",
    disabled: false,
    mini: false,
    buttonAlign: "center",
    buttonColors: {
      color: "#FFF",
      backgroundColor: "#e61919",
      hoverColor: "#42d696",
      disabledColor: "#757575",
    },
    size: "medium",
    sizeIcon: 24,
    zDepth: 2,
    icon: "add",
    link: {
      target: "",
      type: "URL",
      value: "",
      isLink: false,
    },
  },
  layout: Layout9,
};
export default FAButtonView;
