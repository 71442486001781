import React, { Component } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { shallowEqual } from "recompose";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import { RemoveCircle } from "@material-ui/icons";
import { red } from "@material-ui/core/colors";
import { get } from "lodash";
import { UploadClient } from "../../restClient";
import {
  ERROR_MESSAGE_MAX_SIZE,
  ERROR_MESSAGE_FAILED,
  BoxUpload,
  BoxInside,
} from "./utils";
import "./index.css";

class ImageVariantByAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      openSnackBar: false,
      dataImage: props.dataImage,
      message: "Please try again",
      variant: "success",
    };
  }

  handleRequestClose = () => {
    this.setState({
      openSnackBar: false,
    });
  };
  convertTagsName = (name, tags) => {
    let brand = name.trim();
    let newTags = "";
    brand = brand.replace(/\s+/g, "-");
    brand = brand.replace(/[&/\\#,+[/\]()$~%.@'":*!`^?<>{}]/g, "");
    if (tags) {
      const tagsTrim = tags.map((tag) => {
        return tag.trim();
      });
      newTags = tagsTrim.join("-");
      newTags = newTags.replace(/\s+/g, "-");
      //remove special character
      newTags = newTags.replace(/[&/\\#,+[/\]()$~%.@'"^:*!`?<>{}]/g, "");
    }

    let joinData = newTags === "" ? brand : brand + "-" + newTags;
    // console.log(joinData);
    return joinData;
  };

  onDrop = (accepted, rejected) => {
    if (accepted.length > 0 && rejected.length === 0) {
      this.setState({
        isLoading: true,
      });

      const { nameProduct, tagsProduct } = this.props;
      let paramsCaption = this.convertTagsName(nameProduct, tagsProduct);
      const url = "/products/upload";
      let formData = new FormData();
      // caption as brand-product name
      formData.append("caption", paramsCaption);
      accepted.forEach((file) => {
        formData.append("photos", file, file.name);
      });
      UploadClient(formData, url)
        .then((response) => response.json())
        .then((data) => {
          // this.onAdd(data.url);
          if (data && data.url) {
            this.onAdd(data.url);
          } else {
            this.setState({
              isLoading: false,
              openSnackBar: true,
              variant: "error",
              message: get(data, "message", ERROR_MESSAGE_FAILED),
            });
          }
        })
        .catch((e) => {
          this.setState({
            isLoading: false,
            openSnackBar: true,
            variant: "error",
            message: get(e, "message", ERROR_MESSAGE_FAILED),
          });
        });
    } else {
      this.setState({
        isLoading: false,
        openSnackBar: true,
        variant: "error",
        message: ERROR_MESSAGE_MAX_SIZE,
      });
    }
  };

  onAdd(url) {
    // const updatedFiles = [...this.state.dataImage.photos, url];
    let newImage =
      get(this.state, "dataImage.photos", []) !== null
        ? get(this.state, "dataImage.photos", [])
        : [];
    const updatedFiles = [...newImage, url];
    const newDataImage = this.state.dataImage;
    newDataImage.photos = updatedFiles;
    this.setState({ dataImage: newDataImage, isLoading: false });
    this.props.onUpdatePhotos(newDataImage);
  }

  onRemove = (file) => () => {
    const filteredFiles = this.state.dataImage.photos.filter(
      (stateFile) => !shallowEqual(stateFile, file)
    );
    let newDataImage = this.state.dataImage;
    newDataImage.photos = filteredFiles;
    this.setState({ dataImage: newDataImage });
    this.props.onUpdatePhotos(newDataImage);
  };
  render() {
    const { photos } = this.state.dataImage;
    const { accept, maxSize, minSize, multiple, disableClick } = this.props;
    return (
      <div>
        <DivImageBox>
          <Dropzone
            onDrop={this.onDrop}
            accept={accept}
            disableClick={disableClick}
            maxSize={maxSize}
            minSize={minSize}
            multiple={multiple}
          >
            {({ getRootProps, getInputProps }) => (
              <BoxUpload>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {this.state.isLoading && (
                    <div style={styles.insideBox}>
                      <CircularProgress />
                    </div>
                  )}
                  {!this.state.isLoading && <BoxInside />}
                </div>
              </BoxUpload>
            )}
          </Dropzone>

          {photos &&
            photos.map((item, index) => {
              return (
                <DivImages key={index} style={styles.container}>
                  <IconButton
                    style={styles.removeButton}
                    onClick={this.onRemove(item)}
                  >
                    <RemoveCircle style={{ color: red[900] }} />
                  </IconButton>
                  <img
                    src={item}
                    style={{
                      width: "160px",
                      height: "160px",
                      objectFit: "contain",
                    }}
                    alt='delete'
                    title='photos'
                  />
                </DivImages>
              );
            })}
        </DivImageBox>
        <Snackbar
          variant={this.state.variant}
          open={this.state.openSnackBar}
          message={this.state.message}
          autoHideDuration={4000}
          onClose={this.handleRequestClose}
        />
      </div>
    );
  }
}

const DivImageBox = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direaction: row;
  min-height: 200px;
  padding: 16px 16px 16px 16px;
  border: 4px dashed #d6d6d6;
  border-radius: 5px;
  background-color: rgba(249, 247, 244, 0.45);
`;

const DivImages = styled.div`
  width: 180px;
  height: 180px;
  padding: 8px;
`;

const styles = {
  dropzone: {
    width: 180,
    height: 180,
    marginRight: 8,
    display: "flex",
    borderWidth: 2,
    borderColor: "#D6D6D6",
    borderStyle: "dashed",
    cursor: "pointer",
    borderRadius: 5,
  },
  insideBox: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "inherit",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    padding: 5,
  },
  removeButton: {
    position: "absolute",
    top: "0.5rem",
    right: "0.5rem",
    minWidth: "2rem",
    opacity: 1,
  },
  image: {
    maxHeight: "14rem",
    margin: "0.2rem",
    maxWidth: "100%",
  },
};

ImageVariantByAll.propTypes = {
  accept: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  disableClick: PropTypes.bool,
  style: PropTypes.object,
  placeholder: PropTypes.node,
  onUpdatePhotos: PropTypes.func.isRequired,
  dataImage: PropTypes.object,
  tagsProduct: PropTypes.array,
  nameProduct: PropTypes.string.isRequired,
};

ImageVariantByAll.defaultProps = {
  multiple: false,
  maxSize: 1000000,
  minSize: 10,
  tagsProduct: [],
  dataImage: {
    photos: [],
  },
};

export default ImageVariantByAll;
