import React, { Component } from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { get } from "lodash";
import { FooterAction } from "../actions";
import { DividerFields } from "./Fields";

const defaultDivider = {
  parent: "Content",
  type: "DividerView",
  containerPadding: "0px",
  line: "solid",
  lineColor: "rgba(255,255,255,1)",
  alignValue: "center",
  width: "100%",
  size: "1px",
};

class DividerView extends Component {
  constructor(props) {
    super(props);
    const values = get(props, "data.values", defaultDivider);
    const widthValue = values.width.split("%").join("") * 1;
    const sizeValue = values.size.split("px").join("") * 1;
    this.state = {
      heightContent: props.heightContent,
      widthContent: props.widthContent,
      lineColor: values.lineColor,
      width: widthValue,
      line: values.line,
      alignValue: values.alignValue,
      containerPadding: values.containerPadding,
      size: sizeValue,
    };
  }
  handleExpandChange = (expanded) => {
    this.setState({ expanded: expanded });
  };
  onClickSave = () => {
    let newData = this.props.data;
    newData.values.lineColor = this.state.lineColor;
    newData.values.line = this.state.line;
    newData.values.alignValue = this.state.alignValue;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values.width = this.state.width + "%";
    newData.values.size = this.state.size + "px";
    this.props.onUpdateData(newData);
  };

  handleValue = (name, key) => (event) => {
    this.setState({ ...this.state, [name]: event.target[key] });
  };
  onSetValue = (key, value) => {
    this.setState({ [key]: value });
  };

  onClickCopy = () => {
    let newData = this.props.data;
    newData.values.lineColor = this.state.lineColor;
    newData.values.line = this.state.line;
    newData.values.alignValue = this.state.alignValue;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values.width = this.state.width + "%";
    newData.values.size = this.state.size + "px";
    localStorage.setItem("DividerView", JSON.stringify(newData.values));
  };
  onClickPaste = () => {
    const dividerContent = JSON.parse(localStorage.getItem("DividerView"));
    if (dividerContent) {
      const {
        line,
        lineColor,
        containerPadding,
        width,
        alignValue,
        size,
      } = dividerContent;
      const widthValue = width.split("%").join("") * 1;
      const sizeValue = size.split("px").join("") * 1;
      this.setState({
        lineColor: lineColor,
        width: widthValue,
        line: line,
        alignValue: alignValue,
        containerPadding: containerPadding,
        size: sizeValue,
      });
    }
  };

  render() {
    return (
      <Flex width={[1]} flexDirection={"column"} pb={["80px"]}>
        <DividerFields
          state={this.state}
          handleValue={this.handleValue}
          onSetValue={this.onSetValue}
        />
        <FooterAction
          onClickSave={this.onClickSave}
          onClickCopy={this.onClickCopy}
          onClickPaste={this.onClickPaste}
          widthContent={this.state.widthContent}
        />
      </Flex>
    );
  }
}

DividerView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

DividerView.defaultProps = {
  data: {},
};
export default DividerView;
