import React, { useState } from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import { BlockCounter, BorderContent, LayoutContainer } from "../components";
import { BoxContent } from "../styled";
import { FooterAction, PanelCard, ContainerPanel } from "../actions";
import ListContent from "./ListContent";

const defaultLayout = {
  flexDirection: ["column", "row"],
  justifyContent: ["flex-start", "flex-start"],
  alignItems: ["flex-start", "flex-start"],
  wrap: ["nowrap", "nowrap"],
  flexWrap: ["nowrap", "nowrap"],
};

const defaultBorder = {
  color: ["#e61919", "#e61919"],
  radius: ["0px", "0px"],
  size: [0, 0],
  type: ["solid", "solid"],
  backgroundColor: ["#FFFFFF", "#FFFFFF"],
};

export default (props) => {
  const { containerPadding, list, mobile } = props.data.values;
  console.log(props.data.values);
  const [state, setState] = useState({
    containerPadding: containerPadding,
    list: list,
    layoutRow: get(props, "data.values.layoutRow", defaultLayout),
    heightContent: props.heightContent,
    widthContent: props.widthContent,
    mobile: mobile,
    border:
      typeof get(props, "data.values.border", defaultBorder) === "string"
        ? defaultBorder
        : get(props, "data.values.border", defaultBorder),
  });

  const onSetValue = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
    console.log(key, value);
  };
  const onClickSave = () => {
    let newData = props.data;
    newData.values.list = state.list;
    newData.values.containerPadding = state.containerPadding;
    newData.values.layoutRow = state.layoutRow;
    newData.values.mobile = state.mobile;
    newData.values.border = state.border;
    props.onUpdateData(newData);
  };
  const onClickCopy = () => {
    let newData = props.data;
    newData.values.list = state.list;
    newData.values.containerPadding = state.containerPadding;
    newData.values.layoutRow = state.layoutRow;
    newData.values.mobile = state.mobile;
    newData.values.border = state.border;
    localStorage.setItem(
      "MultiContentGroupView",
      JSON.stringify(newData.values)
    );
  };
  const onClickPaste = () => {
    const imageContent = JSON.parse(
      localStorage.getItem("MultiContentGroupView")
    );
    if (imageContent) {
      const {
        containerPadding,
        list,
        mobile,
        layoutRow,
        border,
      } = imageContent;
      setState({
        ...state,
        containerPadding: containerPadding,
        list: list,
        layoutRow: layoutRow,
        mobile: mobile,
        border: border,
      });
    }
  };
  const onUpdateMobile = (k, v) => {
    const newMobile = {
      ...state.mobile,
      [k]: v,
    };
    onSetValue("mobile", newMobile);
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <BoxContent style={{ height: state.heightContent - 120 }}>
        <ListContent
          list={state.list}
          onSetValue={(k, v) => onSetValue(k, v)}
          websiteId={props.websiteId}
        />
        <ContainerPanel>
          <PanelCard title={`Setting Layout`} id={`properties-setting-layout`}>
            <LayoutContainer
              layoutRow={state.layoutRow}
              onUpdateLayoutRow={(k, v) => onSetValue(k, v)}
            />
          </PanelCard>
          <PanelCard title='General' id='menu-content'>
            <BlockCounter
              title='Container Padding'
              containerPadding={state.containerPadding}
              onUpdatePadding={(cp) => onSetValue("containerPadding", cp)}
            />
            <BlockCounter
              title='Mobile Padding'
              containerPadding={state.mobile.containerPadding}
              onUpdatePadding={(cp) => onUpdateMobile("containerPadding", cp)}
            />
          </PanelCard>
          <PanelCard title='Border Setting' id='menu-content'>
            <BorderContent
              border={state.border}
              onUpdateBorder={(bdr) => onSetValue("border", bdr)}
            />
          </PanelCard>
        </ContainerPanel>
      </BoxContent>
      <FooterAction
        onClickSave={onClickSave}
        onClickCopy={onClickCopy}
        onClickPaste={onClickPaste}
        widthContent={state.widthContent}
      />
    </Flex>
  );
};
