import React from "react";
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd";
import { get } from "lodash";
import { connect } from "../../core";
import { target } from "./utils";
import { ButtonView } from "../content/FlatButtonUtils";
const defaultBorder = {
  type: "solid",
  size: 0,
  color: "#e61919",
};

const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
}) => {
  const { values } = layoutState.getItemJS(id).props;
  const linkUrl = "";
  // const {
  //   icon,
  //   text,
  //   buttonAlign,
  //   containerPadding,
  //   href,
  //   buttonColors,
  //   fullWidth,
  //   labelPosition,
  //   typeButton,
  //   border,
  // } = values
  const {
    icon,
    text,
    // href,
    // isShowDesktop,
    // isShowMobile,
    labelPosition,
    typeButton,
    border,
    desktop,
    // mobile,
  } = values;

  const isBorder = border
    ? `${border.size}px ${border.type} ${border.color}`
    : `${defaultBorder.size}px ${defaultBorder.type} ${defaultBorder.color}`;
  const borderRadius = get(border, "radius", "0")
    ? `${get(border, "radius", 0)}px`
    : "0px";
  const childButton =
    linkUrl !== "" ? (
      <a href={linkUrl}>
        <ButtonView
          newChildIcon={icon}
          typeButton={typeButton}
          isBorder={isBorder}
          buttonColors={get(desktop, "buttonColors", "transparent")}
          text={text}
          labelPosition={labelPosition}
          fullWidth={get(desktop, "fullWidth", false)}
          defaultBorderSize={defaultBorder.size}
          borderRadius={borderRadius}
          buttonPadding={get(desktop, "buttonPadding", "0px")}
        />
      </a>
    ) : (
      <ButtonView
        newChildIcon={icon}
        typeButton={typeButton}
        isBorder={isBorder}
        buttonColors={get(desktop, "buttonColors", "transparent")}
        text={text}
        labelPosition={labelPosition}
        fullWidth={get(desktop, "fullWidth", false)}
        defaultBorderSize={defaultBorder.size}
        borderRadius={borderRadius}
        buttonPadding={get(desktop, "buttonPadding", "0px")}
      />
      // <ButtonView
      //   newChildIcon={icon}
      //   typeButton={typeButton}
      //   isBorder={isBorder}
      //   buttonColors={buttonColors}
      //   text={text}
      //   labelPosition={labelPosition}
      //   fullWidth={fullWidth}
      //   defaultBorderSize={defaultBorder.size}
      //   borderRadius={borderRadius}
      // />
    );
  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "auto",
        display: "flex",
        width: "100%",
        justifyContent: get(desktop, "buttonAlign", "center"),
        flex: 1,
        padding: get(desktop, "containerPadding", "0px"),
      }}
    >
      {childButton}
    </div>
  );
};
/**
 * note : in here for edit not show the target url for button
 * href={values.href.url}
   target={values.href.target}
 */
const LayoutContainer = DropTarget(
  "FlatButtonView",
  target,
  (conn, monitor) => ({
    connectDropTarget: conn.dropTarget(),
    isOverCurrent: monitor.isOver({ shallow: true }),
  })
)(EditLayout);

export default connect("layoutState", "readOnly")(LayoutContainer);
