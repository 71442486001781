import React, { Component } from "react";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  getFlatDataFromTree,
  getTreeFromFlatData,
  changeNodeAtPath,
} from "react-sortable-tree";
import { get } from "lodash";
import { Drawer } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/EditOutlined";
import { Flex, Box } from "rebass";
import "react-sortable-tree/style.css";
import "./shortable-tree.css";
import { generateRandomKey } from "./utils";
import DialogRemoveItem from "./DialogRemoveItem";
import { pink } from "@material-ui/core/colors";
import SampleCreateMenu from "./SampleCreateMenu";
import SampleEditMenu from "./SampleEditMenu";

class SampleListMenu extends Component {
  constructor(props) {
    super(props);
    const { dataSource } = props;
    const flatToTreeData = getTreeFromFlatData({
      flatData: dataSource.map((node) => ({ ...node, title: node.title })),
      getKey: (node) => node.id,
      getParentKey: (node) => node.parent_id,
      rootKey: "root",
    });

    this.state = {
      treeData: flatToTreeData,
      open: false,
      openEdit: false,
      openRemove: false,
      showCategory: 0,
      nodeChild: null,
      getNodeKey: null,
      getPathChild: null,
      nodeRemove: null,
      getNodeRemove: null,
      pathRemove: null,
      itemSelected: null,
      nodeSelected: null,
      pathSelected: null,
      iscreate: null,
      isedit: null,
    };
  }
  onCreateRootMenu = () => {
    this.setState({
      open: true,
      iscreate: "root",
      itemSelected: {
        title: "",
        href: { type: "", value: "" },
        icon: "",
        parent_id: "root",
        newColumn: false,
        cols: 6,
        mobileCols: 6,
      },
    });
  };
  onCreateSubMenu = (node, path, getKey) => {
    // console.log("createsubmenu", node);
    // console.log(path);
    this.setState({
      open: true,
      iscreate: "sub",
      nodeSelected: node,
      pathSelected: path,
      getNodeKey: getKey,
      itemSelected: {
        title: "",
        href: { type: "", value: "" },
        icon: "",
        parent_id: get(node, "content.content_id", node.id),
        newColumn: false,
        cols: 6,
        mobileCols: 6,
      },
    });
  };

  onSaveRootMenu = (values) => {
    const newId = generateRandomKey();
    const newTreeData = this.state.treeData.concat({
      id: newId,
      content_id: newId,
      title: values.title,
      parent_id: "root",
      content: {
        ...values,
        id: newId,
        content_id: newId,
      },
      children: [],
    });
    this.setState({
      open: false,
      itemSelected: null,
      iscreate: null,
      treeData: newTreeData,
    });
    const flatData = getFlatDataFromTree({
      treeData: newTreeData,
      getNodeKey: ({ node }) => node.id,
      ignoreCollapsed: false,
    }).map(({ node, path }) => {
      // console.log(path);
      return {
        id: node.id,
        title: node.title,
        content: node.content,
        parent_id: node.parent_id,
      };
    });

    this.props.updateField("menu", flatData);
  };

  onSaveSubMenu = (values) => {
    const newId = generateRandomKey();
    const newTreeData = addNodeUnderParent({
      treeData: this.state.treeData,
      parentKey: this.state.pathSelected[this.state.pathSelected.length - 1],
      expandParent: true,
      getNodeKey: this.state.getNodeKey,
      newNode: {
        id: newId,
        content_id: newId,
        title: values.title,
        parent_id: this.state.nodeSelected.content_id,
        content: {
          ...values,
          id: newId,
          content_id: newId,
        },
        children: [],
      },
    }).treeData;

    const flatData = getFlatDataFromTree({
      treeData: newTreeData,
      getNodeKey: ({ node }) => node.id,
      ignoreCollapsed: false,
    }).map(({ node, path }) => {
      // console.log(node);
      // console.log(path);
      return {
        id: node.id,
        title: node.title,
        content: node.content,
        parent_id: get(node, "content.parent_id"),
      };
    });
    // console.log(flatData);
    this.props.updateField("menu", flatData);
    this.setState({
      treeData: newTreeData,
      open: false,
      itemSelected: null,
      nodeSelected: null,
      iscreate: null,
      pathSelected: null,
    });
  };

  onCancel = () => {
    this.setState({
      ...this.state,
      open: false,
      itemSelected: null,
      openEdit: false,
      nodeSelected: null,
      iscreate: null,
      pathSelected: null,
    });
  };
  onSetValue = (k, v) => {
    // console.log(k, v);
    this.setState({
      ...this.state,
      [k]: v,
    });
  };

  onEditMenu = (values, path, getNodeKey) => {
    this.setState({
      openEdit: true,
      itemSelected: values.content,
      nodeSelected: values,
      pathSelected: path,
      getNodeKey: getNodeKey,
    });
  };

  onRemoveItem = (path, getNodeKey) => {
    this.setState({
      pathSelected: path,
      getNodeKey: getNodeKey,
      openRemove: true,
    });
  };

  onRemoveItemDialog = () => {
    const { pathSelected, getNodeKey } = this.state;
    const currentItem = removeNodeAtPath({
      treeData: this.state.treeData,
      path: pathSelected,
      getNodeKey,
    });
    this.setState({
      treeData: currentItem,
      openRemove: false,
      pathSelected: null,
      getNodeKey: null,
    });

    const flatData = getFlatDataFromTree({
      treeData: currentItem,
      getNodeKey: ({ node }) => node.id,
      ignoreCollapsed: false,
    }).map(({ node, path }) => ({
      id: node.id,
      title: node.title,
      content: node.content,
      parent_id: node.parent_id,
    }));
    this.props.updateField("menu", flatData);
  };

  onSaveEditMenu = (values) => {
    const newTreeData = changeNodeAtPath({
      treeData: this.state.treeData,
      path: this.state.pathSelected,
      getNodeKey: this.state.getNodeKey,
      newNode: ({ node, treeIndex }) => {
        // console.log(treeIndex, node);
        return {
          ...node,
          content: values,
          title: values.title,
        };
      },
    });
    this.setState({
      treeData: newTreeData,
      open: false,
      itemSelected: null,
      openEdit: false,
      nodeSelected: null,
      iscreate: null,
      pathSelected: null,
    });
    const flatData = getFlatDataFromTree({
      treeData: newTreeData,
      getNodeKey: ({ node }) => node.id,
      ignoreCollapsed: false,
    }).map(({ node, path }) => ({
      id: node.id,
      title: node.title,
      content: node.content,
      parent_id: node.parent_id,
    }));
    this.props.updateField("menu", flatData);
  };

  render() {
    const { translate, classes } = this.props;
    const getNodeKey = ({ treeIndex }) => treeIndex;
    const canDrop = ({ prevPath, nextPath }) => {
      // node, nextParent,
      //can drop if same level
      if (prevPath.length === nextPath.length) {
        return true;
      }
      return false;
    };
    return (
      <Flex width={[1]} flexDirection={"column"}>
        <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-start"}>
          <Box px={[3]}>
            <Button
              color='primary'
              onClick={() => this.onCreateRootMenu()}
              size='small'
              style={{ textTransform: "capitalize" }}
            >
              {translate("resources.layouts.menu.addRootMenu")}
            </Button>
          </Box>
        </Flex>
        <div style={{ height: "49vh" }}>
          <SortableTree
            treeData={this.state.treeData}
            canDrop={canDrop}
            onChange={(treeData) => this.setState({ treeData })}
            generateNodeProps={({ node, path }) => {
              return {
                buttons: [
                  path.length < 5 ? (
                    <Button
                      size='small'
                      color='primary'
                      style={{ textTransform: "capitalize", margin: 8 }}
                      onClick={() =>
                        this.onCreateSubMenu(node, path, getNodeKey)
                      }
                    >
                      {translate("resources.layouts.menu.addSubMenu")}
                    </Button>
                  ) : null,
                  <IconButton
                    style={{ padding: 8 }}
                    onClick={() => this.onEditMenu(node, path, getNodeKey)}
                  >
                    <EditIcon fontSize='small' color={"primary"} />
                  </IconButton>,
                  <IconButton
                    style={{ padding: 8 }}
                    onClick={() => this.onRemoveItem(path, getNodeKey)}
                  >
                    <DeleteIcon fontSize='small' style={{ color: pink[500] }} />
                  </IconButton>,
                ],
              };
            }}
          />
        </div>

        <DialogRemoveItem
          state={this.state}
          onSetOpen={(v) => this.onSetValue("openRemove", v)}
          onRemoveItem={() => this.onRemoveItemDialog()}
          {...this.props}
        />
        {this.state.open && (
          <Drawer
            variant='persistent'
            open={this.state.open}
            anchor='right'
            onClose={() => this.onSetValue("open", false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <SampleCreateMenu
              {...this.props}
              initialValues={this.state.itemSelected}
              state={this.state}
              onSaveRootMenu={(v) => this.onSaveRootMenu(v)}
              onSaveSubMenu={(v) => this.onSaveSubMenu(v)}
              setState={this.setState}
              onCancel={() => this.onCancel()}
            />
          </Drawer>
        )}
        {this.state.openEdit && (
          <Drawer
            variant='persistent'
            open={this.state.openEdit}
            anchor='right'
            onClose={() => this.onSetValue("openEdit", false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <SampleEditMenu
              {...this.props}
              initialValues={this.state.itemSelected}
              state={this.state}
              setState={this.setState}
              onSaveEditMenu={(v) => this.onSaveEditMenu(v)}
              onCancel={() => this.onCancel()}
            />
          </Drawer>
        )}
      </Flex>
    );
  }
}

export default SampleListMenu;
