import React from "react";
import styled from "styled-components";
import TextIcon from "@material-ui/icons/TextFormat";
import DividerIcon from "@material-ui/icons/Remove";
import ImageIcon from "@material-ui/icons/Photo";
import AvatarIcon from "@material-ui/icons/AccountCircle";
import ImageTextIcon from "@material-ui/icons/ArtTrack";
import CarouselIcon from "@material-ui/icons/ViewCarousel";
import FlatButtonIcon from "@material-ui/icons/Crop75";
import ButtonIcon from "@material-ui/icons/AddCircle";
import FAButtonIcon from "@material-ui/icons/Add";
import ProductListIcon from "@material-ui/icons/ViewList";
import YoutubeIcon from "@material-ui/icons/OndemandVideo";
import ProductGroupIcon from "@material-ui/icons/BorderAllRounded";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import { Text } from "rebass";
const colors = "#505659";

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
`;

const DivContainer = styled.div`
  padding: 4px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition-duration: 0.15s;
  transition-property: box-shadow;
  &:hover {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.35);
  }
`;
export const Layout1 = () => (
  <DivContainer>
    <Content>
      <TextIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Text
      </Text>
    </Content>
  </DivContainer>
);

export const Layout2 = () => (
  <DivContainer>
    <Content>
      <ImageIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Image
      </Text>
    </Content>
  </DivContainer>
);

export const Layout3 = () => (
  <DivContainer>
    <Content>
      <ImageTextIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Image Text
      </Text>
    </Content>
  </DivContainer>
);
export const Layout4 = () => (
  <DivContainer>
    <Content>
      <AvatarIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Avatar
      </Text>
    </Content>
  </DivContainer>
);

export const Layout5 = () => (
  <DivContainer>
    <Content>
      <DividerIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Divider
      </Text>
    </Content>
  </DivContainer>
);

export const Layout6 = () => (
  <DivContainer>
    <Content>
      <CarouselIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Carousel
      </Text>
    </Content>
  </DivContainer>
);

export const Layout7 = () => (
  <DivContainer>
    <Content>
      <FlatButtonIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Button
      </Text>
    </Content>
  </DivContainer>
);
export const Layout8 = () => (
  <DivContainer>
    <Content>
      <ButtonIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Icon Button
      </Text>
    </Content>
  </DivContainer>
);

export const Layout9 = () => (
  <DivContainer>
    <Content>
      <FAButtonIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        FAB Button
      </Text>
    </Content>
  </DivContainer>
);
export const Layout10 = () => (
  <DivContainer>
    <Content>
      <ProductListIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Product List
      </Text>
    </Content>
  </DivContainer>
);
export const Layout11 = () => (
  <DivContainer>
    <Content>
      <YoutubeIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Youtube
      </Text>
    </Content>
  </DivContainer>
);

export const Layout12 = () => (
  <DivContainer>
    <Content>
      <TextIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        TextGroup
      </Text>
    </Content>
  </DivContainer>
);
export const Layout13 = () => (
  <DivContainer>
    <Content>
      <CarouselIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        List Slide
      </Text>
    </Content>
  </DivContainer>
);

export const Layout14 = () => (
  <DivContainer>
    <Content>
      <CarouselIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Carousel Link Sllide
      </Text>
    </Content>
  </DivContainer>
);
export const Layout15 = () => (
  <DivContainer>
    <Content>
      <CarouselIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Carousel Animation
      </Text>
    </Content>
  </DivContainer>
);
export const Layout16 = () => (
  <DivContainer>
    <Content>
      <ProductGroupIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Product Group
      </Text>
    </Content>
  </DivContainer>
);
export const Layout17 = () => (
  <DivContainer>
    <Content>
      <ProductGroupIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Menu
      </Text>
    </Content>
  </DivContainer>
);
export const Layout18 = () => (
  <DivContainer>
    <Content>
      <ViewModuleIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Social Media
      </Text>
    </Content>
  </DivContainer>
);

export const Layout19 = () => (
  <DivContainer>
    <Content>
      <CarouselIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Grid List
      </Text>
    </Content>
  </DivContainer>
);
export const Layout20 = () => (
  <DivContainer>
    <Content>
      <CarouselIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Accordion List
      </Text>
    </Content>
  </DivContainer>
);

export const Layout21 = () => (
  <DivContainer>
    <Content>
      <ProductGroupIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Menu Group
      </Text>
    </Content>
  </DivContainer>
);
export const Layout22 = () => (
  <DivContainer>
    <Content>
      <ProductGroupIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Multi Content Group
      </Text>
    </Content>
  </DivContainer>
);

export const Layout23 = () => (
  <DivContainer>
    <Content>
      <ProductGroupIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Subcribe Form
      </Text>
    </Content>
  </DivContainer>
);
export const Layout24 = () => (
  <DivContainer>
    <Content>
      <ImageTextIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Row Icon Text
      </Text>
    </Content>
  </DivContainer>
);

export const Layout25 = () => (
  <DivContainer>
    <Content>
      <ProductGroupIcon style={styles.icon} color={"primary"} />
      <Text fontSize={[1]} pl={[2]} style={{ color: colors }}>
        Menu Content
      </Text>
    </Content>
  </DivContainer>
);

export const layoutsleft = {
  layout1: Layout1,
  layout2: Layout2,
  layout3: Layout3,
  layout4: Layout4,
  layout5: Layout5,
  layout6: Layout6,
  layout7: Layout7,
  layout8: Layout8,
  layout9: Layout9,
  layout10: Layout10,
  layout11: Layout11,
  Layout12: Layout12,
  Layout13: Layout13,
  Layout14: Layout14,
  Layout15: Layout15,
  Layout16: Layout16,
  Layout17: Layout17,
  Layout18: Layout18,
  Layout19: Layout19,
  Layout20: Layout20,
  Layout21: Layout21,
  Layout22: Layout22,
  Layout23: Layout23,
  Layout24: Layout24,
  Layout25: Layout25,
};

const styles = {
  icon: {
    width: 40,
    height: 40,
  },
};
