import React, { useState } from "react"
import { IconButton, Dialog, Button } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const DialogCard = (props) => {
  const { title, titleButton, onSave, onCancel, id, children } = props
  const [open, setOpen] = useState(false)

  const onHandleCancel = () => {
    setOpen(false)
    if (onCancel) {
      onCancel()
    }
  }
  const onHandleSave = () => {
    setOpen(false)
    if (onSave) {
      onSave()
    }
  }
  return (
    <div>
      <Button onClick={() => setOpen(true)} color={"primary"}>
        {titleButton}
      </Button>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby={`customized-dialog-title${id}`}
        open={open}
        fullScreen={true}
        scroll={"paper"}
      >
        <DialogTitle
          id={`customized-dialog-title${id}`}
          onClose={() => setOpen(false)}
        >
          {title}
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        {!props.hiddenAction && (
          <DialogActions>
            <Button onClick={() => onHandleCancel()} color="primary">
              Cancel
            </Button>
            <Button onClick={() => onHandleSave()} color="primary">
              Save
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}

export default DialogCard
