import React from "react";
import { Route } from "react-router-dom";
import { WebsiteEdit, WebsiteCreate } from "./screens/websites";
import {
  LayoutEdit,
  LayoutCreate,
  LayoutEditHomePage,
  LayoutEditThemePage,
  LayoutEditHeaderPage,
  LayoutEditFooterPage,
  LayoutEditMenuPage,
} from "./screens/layouts";
import { WebhooksEdit, WebhooksCreate } from "./screens/webhooks";
import { PaymentCreate, PaymentEdit } from "./screens/payments";
import { ShippingsCreate, ShippingsEdit } from "./screens/shippings";
import { AttributesList } from "./screens/attributes";
import { SourceCreate } from "./screens/sources";
import { SourceGroupsCreate } from "./screens/sourceGroups";
import {
  PromotionsList,
  CreateCartRules,
  EditCartRules,
} from "./screens/promotions";
import {
  CreateShipment,
  CancelShipment,
  CreateAmendment,
  EditAmendment,
} from "./screens/orders";
import { CreateFAQ, EditFAQ } from "./screens/faq";
import { WrapperPermissions } from "./components";
export default [
  <Route
    exact
    path='/sites/:site_id/websites/create'
    render={() => (
      <WrapperPermissions>
        <WebsiteCreate />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/sites/:site_id/sources/create'
    render={() => (
      <WrapperPermissions>
        <SourceCreate />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/sites/:site_id/sources/createGroup'
    render={() => (
      <WrapperPermissions>
        <SourceGroupsCreate />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/websites/:website_id/layout/create'
    render={() => (
      <WrapperPermissions>
        <LayoutCreate />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/websites/:website_id/shipping/create'
    render={() => (
      <WrapperPermissions>
        <ShippingsCreate />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/websites/:website_id/payment/create'
    render={() => (
      <WrapperPermissions>
        <PaymentCreate />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/websites/:website_id/webhooks/create'
    render={() => (
      <WrapperPermissions>
        <WebhooksCreate />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/websites/:website_id/faq/create'
    render={() => (
      <WrapperPermissions>
        <CreateFAQ />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/attributes/list'
    render={() => (
      <WrapperPermissions>
        <AttributesList />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/promotions/cart'
    render={() => (
      <WrapperPermissions>
        <PromotionsList />
      </WrapperPermissions>
    )}
  />,

  <Route
    exact
    path='/promotions/cart/create'
    render={() => (
      <WrapperPermissions>
        <CreateCartRules />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/promotions/cart/:id'
    render={() => (
      <WrapperPermissions>
        <EditCartRules />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/sites/:site_id/websites/:id'
    render={() => (
      <WrapperPermissions>
        <WebsiteEdit />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/websites/:website_id/layout/:id'
    render={() => (
      <WrapperPermissions>
        <LayoutEdit />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/websites/:website_id/layout/:id/header'
    render={() => (
      <WrapperPermissions>
        <LayoutEditHeaderPage />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/websites/:website_id/layout/:id/page'
    render={() => (
      <WrapperPermissions>
        <LayoutEditHomePage />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/websites/:website_id/layout/:id/footer'
    render={() => (
      <WrapperPermissions>
        <LayoutEditFooterPage />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/websites/:website_id/layout/:id/theme'
    render={() => (
      <WrapperPermissions>
        <LayoutEditThemePage />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/websites/:website_id/layout/:id/menu'
    render={() => (
      <WrapperPermissions>
        <LayoutEditMenuPage />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/websites/:website_id/webhooks/:id'
    render={() => (
      <WrapperPermissions>
        <WebhooksEdit />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/websites/:website_id/shipping/:id'
    render={() => (
      <WrapperPermissions>
        <ShippingsEdit />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/websites/:website_id/payment/:id'
    render={() => (
      <WrapperPermissions>
        <PaymentEdit />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/websites/:website_id/faq/:id'
    render={() => (
      <WrapperPermissions>
        <EditFAQ />
      </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/orders/:id/show/shipment/create'
    render={() => (
      // <WrapperPermissions>
      <CreateShipment />
      // </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/orders/:order_id/show/shipment/:id'
    render={() => (
      // <WrapperPermissions>
      <CancelShipment />
      // </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/orders/:id/show/amendment/create'
    render={() => (
      // <WrapperPermissions>
      <CreateAmendment />
      // </WrapperPermissions>
    )}
  />,
  <Route
    exact
    path='/orders/:id/show/amendment/:amendment_id'
    render={() => (
      // <WrapperPermissions>
      <EditAmendment />
      // </WrapperPermissions>
    )}
  />,

  // <Route exact path="/orders/:id/show/shipment" render={() => <OrderShow />} />,
  // <Route
  //   exact
  //   path="/orders/:id/show/amendment"
  //   render={() => <OrderShow resource={"orders"} />}
  // />,
  // <Route exact path="/orders/:id/show/timeline" render={() => <OrderShow />} />,
  // <Route
  //   exact
  //   path="/sites/:site_id/websites/:id/locale"
  //   render={() => <WebsiteEdit />}
  // />,
  // <Route
  //   exact
  //   path="/sites/:site_id/websites/:id/product"
  //   render={() => <WebsiteEdit />}
  // />,
  // <Route
  //   exact
  //   path="/sites/:site_id/websites/:id/service"
  //   render={() => <WebsiteEdit />}
  // />,
  // <Route
  //   exact
  //   path="/sites/:site_id/websites/:id/locale"
  //   render={() => <WebsiteEdit />}
  // />,
];
