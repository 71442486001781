import React from "react";
import { Box } from "rebass";
import { Title, DialogHrefContent, SelectInput } from "../components";
import SocialContentFields from "./SocialContentFields";
import { ContentDetail } from "../actions";
import { SketchColorPicker } from "../../../components";
/***type: "facebook",
  color: "#F61C0D",
  showLabel: true, */

const dataType = [
  { text: "Facebook", value: "facebook" },
  { text: "Instagram", value: "instagram" },
  { text: "Twitter", value: "twitter" },
  // { text: "Youtube", value: "youtube" },
  // { text: "Whatsapp", value: "whatsapp" },
];

export default (props) => {
  const { item, onSetItemValue } = props;
  const onSetValue = (k, v) => {
    const newItem = {
      ...item,
      [k]: v,
    };
    onSetItemValue(newItem);
  };

  const onSetValueDesktop = (k, v) => {
    const newDesktop = {
      ...item.desktop,
      [k]: v,
    };
    onSetValue("desktop", newDesktop);
  };

  const onSetValueMobile = (k, v) => {
    const newMobile = {
      ...item.mobile,
      [k]: v,
    };
    onSetValue("mobile", newMobile);
  };
  return (
    <Box width={[1]}>
      <SelectInput
        id={`type-social-Media`}
        title={"Social Media"}
        value={item.type}
        list={dataType}
        onChange={(e) => onSetItemValue("type", e.target.value)}
      />
      <Box width={[1]} py={[2]}>
        <Title title={"Desktop Settings"} />
        <SocialContentFields
          state={item.desktop}
          title={"Desktop"}
          onSetValue={(k, v) => onSetValueDesktop(k, v)}
        />
      </Box>
      <Box width={[1]} py={[2]}>
        <Title title={"Mobile Settings"} />
        <SocialContentFields
          state={item.mobile}
          title={"Mobile"}
          onSetValue={(k, v) => onSetValueMobile(k, v)}
        />
      </Box>
      <ContentDetail>
        <SketchColorPicker
          title={"Color"}
          onUpdateColor={(color) => onSetValue("color", color)}
          color={item.color}
        />
      </ContentDetail>
      <ContentDetail>
        <DialogHrefContent
          link={item.link}
          onSetValue={(v) => onSetValue("link", v)}
          websiteId={props.websiteId}
        />
      </ContentDetail>
    </Box>
  );
};
