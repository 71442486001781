import React from "react";
import { get } from "lodash";
import { PanelCard } from "../actions";
import { SwitchInput } from "../components";
import HeaderContent from "./HeaderContent";
import ListPropsContent from "./ListPropsContent";
import SettingsContent from "./SettingsContent";
export default (props) => {
  const { title, contentId, state, onSetValue } = props;
  return (
    <PanelCard title={title} id={`setting-${contentId}`}>
      <SwitchInput
        id={`use-show-onScreen-${contentId}`}
        title={"Show on Screen"}
        checked={get(state, "show", false)}
        onChange={(e) => onSetValue("show", e.target.checked)}
      />
      <HeaderContent
        state={get(state, "header", {
          show: true,
          title: "Shop by Sports",
          fontSize: 14,
          showViewAll: true,
        })}
        contentId={contentId}
        onSetValue={(v) => onSetValue("header", v)}
      />
      <ListPropsContent
        state={get(state, "listProps", {
          fontSize: 14,
          titlePadding: "0px",
          padding: "0px",
          urlViewAll: "",
          showViewAllBottom: false,
        })}
        contentId={contentId}
        onSetValue={(v) => onSetValue("listProps", v)}
      />
      <SettingsContent
        state={get(state, "setting", {
          dots: false,
          infinite: false,
          arrows: false,
          speed: 200,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          rows: 0,
          slidesPerRow: 0,
          autoplay: false,
          autoplaySpeed: false,
        })}
        contentId={contentId}
        onSetValue={(v) => onSetValue("setting", v)}
      />
    </PanelCard>
  );
};
