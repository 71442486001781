import React, { useRef } from "react";
import Slider from "react-slick";
import { Box, Flex, Text } from "rebass";
import { get, map } from "lodash";
import { ShowAllContent } from "./Items";
import ItemCategory from "./ItemCategory";
import { useQueries, useQuery } from "react-query";
import { getProductById, RestApi } from "../../../../../restClient";
import { defaultSetting, settingsList } from "./utils";
const cacheTimeMinute = 1000 * 60 * 30;
const MainField = (props) => {
  const refContainer = useRef(null);
  // const [state, setState] = useState({ isIndex: 0, isEnd: false });
  const {
    screenWidth,
    title,
    desktop,
    colorProps,
    titleViewAll,
    modeType,
    products,
  } = props;
  const fontSizeHeader = get(desktop, "header.fontSize", 16);
  const showHeader = get(desktop, "header.show", false);
  const headerPadding = get(desktop, "header.padding", "0px");
  const showViewAllHeader = get(desktop, "header.showViewAll", false);
  const settings = settingsList(
    get(desktop, "setting", defaultSetting),
    screenWidth,
    (index) => onChange(index)
  );
  const listProduct = map(products, function (o) {
    return {
      queryKey: ["products", o],
      queryFn: getProductById,
      queryOption: { cacheTime: cacheTimeMinute },
    };
  });
  const results = useQueries(listProduct);

  const onChange = (index) => {};

  // console.log(results);
  return (
    <Box width={[1]}>
      {showHeader && (
        <Flex
          width={[1]}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexDirection={"row"}
          style={{ padding: headerPadding }}
        >
          <Text style={{ fontSize: `${fontSizeHeader}pt` }} fontWeight={"bold"}>
            {title}
          </Text>
          {showViewAllHeader && (
            <ShowAllContent
              url={""}
              title={titleViewAll}
              viewallColor={get(colorProps, "viewallColor", "#000000")}
            />
          )}
        </Flex>
      )}

      <Slider
        ref={refContainer}
        {...settings}
        style={{ backgroundColor: "transparent" }}
      >
        {results &&
          results.map((item, index) => {
            if (item.isLoading) {
              return <div key={index} />;
            }
            return (
              <ItemCategory
                item={item.data}
                key={index}
                size={[1]}
                screenWidth={screenWidth}
                colorProps={colorProps}
                desktop={desktop}
                modeType={modeType}
              />
            );
          })}
      </Slider>
    </Box>
  );
};

export default (props) => {
  const { data, isLoading } = useQuery(
    "collections",
    () => RestApi(`/collections/${props.collection}`).then((res) => res.json()),
    {
      cacheTime: cacheTimeMinute,
    }
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }
  const products = get(data, "products", []);
  return <MainField {...props} products={products} />;
};
