import React from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"
import MuiTab from "@material-ui/core/Tab"
import classnames from "classnames"
import { FormSpy } from "react-final-form"
import { useTranslate, FormInput } from "react-admin"

const sanitizeRestProps = ({
  contentClassName,
  label,
  icon,
  value,
  translate,
  ...rest
}) => rest

const hiddenStyle = { display: "none" }

const FormValueTab = ({
  basePath,
  className,
  contentClassName,
  children,
  hidden,
  icon,
  intent,
  label,
  margin,
  record,
  resource,
  variant,
  value,
  ...rest
}) => {
  const translate = useTranslate()
  const location = useLocation()

  const renderHeader = () => (
    <MuiTab
      key={label}
      label={translate(label, { _: label })}
      value={value}
      icon={icon}
      className={classnames("form-tab", className)}
      component={Link}
      to={{ ...location, pathname: value }}
      {...sanitizeRestProps(rest)}
    />
  )

  const renderContent = () => (
    <FormSpy subscription={{ values: true }}>
      {(propsValue) => {
        return (
          <span
            style={hidden ? hiddenStyle : null}
            className={contentClassName}
          >
            {React.Children.map(
              children,
              (input) =>
                input && (
                  <FormInput
                    basePath={basePath}
                    input={input}
                    record={record}
                    resource={resource}
                    variant={input.props.variant || variant}
                    margin={input.props.margin || margin}
                    formValues={propsValue.values}
                  />
                )
            )}
          </span>
        )
      }}
    </FormSpy>
  )

  return intent === "header" ? renderHeader() : renderContent()
}

FormValueTab.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node,
  intent: PropTypes.oneOf(["header", "content"]),
  hidden: PropTypes.bool,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  value: PropTypes.string,
}

FormValueTab.displayName = "FormValueTab"

export default FormValueTab
