import { get, has } from "lodash";

export const dataPromotionRule = [
  {
    id: "Product-based",
    name: "Product-based",
  },
  {
    id: "Cart-based",
    name: "Cart-based",
  },
];

export const dataPromoType = [
  { id: "Percentage", name: "Percentage" },
  { id: "Amount", name: "Amount" },
  { id: "Buy X Get Y", name: "Buy X Get Y" },
  { id: "Fixed Price", name: "Fixed Price" },
  { id: "Free Shipping", name: "Free Shipping" },
];

export const dataPromoTypeProduct = [
  { id: "Percentage", name: "Percentage" },
  { id: "Amount", name: "Amount" },
  { id: "Free Shipping", name: "Free Shipping" },
];

export const dataCustomerType = [
  { id: "All", name: "All" },
  { id: "Guest", name: "Guest" },
  { id: "Registered Customer", name: "Registered Customer" },
];

export const dataApplyTo = [
  { id: "Category", name: "Specific Categories" },
  { id: "Product", name: "Specific Products" },
];
export const dataCustomerFrom = [
  { id: "Category", name: "Specific Categories" },
  { id: "Product", name: "Specific Products" },
];

export const dataCustomerBuy = [
  { id: "Quantity", name: "Minimum quantity of items" },
  { id: "Amount", name: "Mininum order amount" },
];

export const dataDiscountType = [
  { id: "Free", name: "Free" },
  { id: "Percentage", name: "Percentage" },
];
//Active/Scheduled/Expired
export const dataStatus = [
  { id: "Active", name: "Active" },
  { id: "Scheduled", name: "Scheduled" },
  { id: "Expired", name: "Expired" },
];

export const getPhoto = function (photos) {
  var src = "";

  if (has(photos, "photo_set")) {
    var photoSet = get(photos, "photo_set");
    var key = Object.keys(photoSet);
    var path = `photo_set.${key[0]}.photos[0]`;
    src = get(photos, path, "");
  } else if (has(photos, "*")) {
    src = get(photos, "*.photos[0]", "");
  }
  return src;
};
