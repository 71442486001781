import React from "react"
import { connect } from "react-redux"
import { withStyles, createStyles } from "@material-ui/core/styles"
import classnames from "classnames"
import { userLogout } from "react-admin"
import MenuItem from "@material-ui/core/MenuItem"
import { TurnOffIcon } from "../icon_svg"
import { clearLocalStorage } from "../../authClient"
import { DEFAULT_SIDEBAR, DEFAULT_BG_SIDEBAR, HOVER_BG_MENU } from "../../utils"

const styles = (theme) =>
  createStyles({
    root: {
      paddingTop: 15,
      paddingBottom: 15,
      fontSize: 12,
      alignItems: "center",
      letterSpacing: 3,
      backgroundColor: DEFAULT_BG_SIDEBAR,
      "&:hover": {
        backgroundColor: HOVER_BG_MENU,
      },
      color: theme.palette.primary.dark,
      fill: theme.palette.primary.dark,
    },
  })

const AppLogoutButton = ({ classes, className, userLogout, ...rest }) => {
  const onLogout = () => {
    clearLocalStorage()
    userLogout()
  }
  return (
    <MenuItem
      onClick={onLogout}
      {...rest}
      className={classnames(classes.root, className)}
      style={{
        width: DEFAULT_SIDEBAR,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        textTransform: "capitalize",
      }}
    >
      <TurnOffIcon
        style={{
          marginRight: "16px",
        }}
      />
      Logout
    </MenuItem>
  )
}
export default connect(undefined, { userLogout })(
  withStyles(styles)(AppLogoutButton)
)
