import * as React from "react"
import PropTypes from "prop-types"
import Toolbar from "@material-ui/core/Toolbar"
import { makeStyles } from "@material-ui/core/styles"
import { Flex, Box } from "rebass"
const useStyles = makeStyles(
  (theme) => ({
    toolbar: {
      flexDirection: "column",
      // justifyContent: "space-between",
      // alignItems: "flex-end",
      paddingRight: 0,
      [theme.breakpoints.up("xs")]: {
        paddingLeft: 0,
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
      },
    },
    actions: {
      paddingTop: theme.spacing(1),
      minHeight: theme.spacing(5),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
      },
    },
  }),
  { name: "BCX-RaListToolbar" }
)

const ListToolbar = (props) => {
  const {
    classes: classesOverride,
    filters,
    filterValues, // dynamically set via the UI by the user
    permanentFilter, // set in the List component by the developer
    actions,
    exporter,
    ...rest
  } = props
  const classes = useStyles(props)
  return (
    <Toolbar className={classes.toolbar}>
      <Box width={[1]}>
        {actions &&
          React.cloneElement(actions, {
            ...rest,
            className: classes.actions,
            exporter, // deprecated, use ExporterContext instead
            filters,
            filterValues,
            permanentFilter,
            ...actions.props,
          })}
        <Flex alignItems={"flex-end"} justifyContent={"flex-end"}>
          {filters &&
            React.cloneElement(filters, {
              ...rest,
              filterValues,
              context: "form",
            })}
        </Flex>
      </Box>
    </Toolbar>
  )
}

ListToolbar.propTypes = {
  classes: PropTypes.object,
  filters: PropTypes.element,
  permanentFilter: PropTypes.object,
  actions: PropTypes.element,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
}

export default React.memo(ListToolbar)
