import React from "react"
import { required, SelectInput } from "react-admin"
import { Box } from "rebass"
import {
  BetterCustomSimpleForm,
  RaBooleanInput,
  RaTextInput,
  EditToolbar,
} from "../../../components"
import Settings from "./Settings"
import Conditions from "./Conditions"

const FormFields = (props) => {
  return (
    <BetterCustomSimpleForm
      {...props}
      title={"resources.payments.create_title"}
      toolbar={<EditToolbar />}
      isShowBack={false}
    >
      <Box width={[1]}>
        <RaTextInput
          source="name"
          label={"resources.payments.fields.name"}
          validate={required()}
          style={{ width: 320 }}
        />
        <Box width={[1]} pt={[2]}>
          <RaBooleanInput
            source="active"
            label={"resources.payments.fields.active"}
          />
        </Box>
        <Box width={[1]} pt={[2]}>
          <SelectInput
            source="payment_gateway"
            label={"resources.payments.fields.payment_gateway"}
            choices={[
              { id: "pesoPay", name: "PesoPay" },
              { id: "creditcard", name: "Credit Card" },
            ]}
            style={{ width: 256 }}
          />
        </Box>

        <Box width={[1]} pt={[2]}>
          <Settings {...props} />
        </Box>
        <Box width={[1]} pt={[2]}>
          <Conditions {...props} />
        </Box>
      </Box>
    </BetterCustomSimpleForm>
  )
}
export default FormFields
