import React from "react";
import { get } from "lodash";
import { Flex, Box } from "rebass";
import styled from "styled-components";
const Input = styled.input`
  padding: ${(props) => props.inputPadding || "0.5em"};
  margin: ${(props) => props.inputMargin || "0.5em"};
  color: ${(props) => props.inputColor || "palevioletred"};
  background: ${(props) => props.bgColor || "transparent"};
  border: none;
  border-radius: ${(props) => props.borderRadius || "0px"};
`;

const Button = styled.button`
  height: ${(props) => props.buttonHeight || "36px"};
`;
export default (props) => {
  const { state } = props;
  const layout = get(state, "layout");
  const buttonFontSize = `${get(state, "button.fontSize", 10)}pt`;
  const buttonColor = get(state, "colorProps.buttonColor", "transparent");

  if (get(layout, "type", "split") === "split") {
    return (
      <div
        style={{
          padding: get(state, "containerPadding", "0px"),
          width: "100%",
        }}
      >
        <Flex
          width={[1]}
          flexDirection={get(layout, "direction", "column")}
          alignItems={get(layout, "align", "center")}
          justifyContent={get(layout, "jutify", "center")}
          px={[2]}
        >
          <Box
            style={{
              borderRadius: get(state, "borderRadius", "0px"),
              backgroundColor: get(
                state,
                "colorProps.backgroundColor",
                "transparent"
              ),
            }}
          >
            <Input
              id='email'
              name='email'
              type='email'
              placeholder={get(props, "placeholder", "")}
              inputColor={get(state, "colorProps.color", "transparent")}
              bgColor={get(state, "colorProps.backgroundColor", "transparent")}
              inputPadding={"2px 16px"}
              inputMargin={"5px 16px"}
            />
          </Box>
          <Box pt={[3]}>
            <Button
              style={{
                fontSize: buttonFontSize,
                borderRadius: get(state, "button.borderRadius", 0),
                padding: get(state, "button.padding", "0px"),
                backgroundColor: get(
                  state,
                  "colorProps.buttonBackgroundColor",
                  "transparent"
                ),
                color: buttonColor,
                fontWeight: "bold",
                width: 200,
              }}
              buttonHeight={"40px"}
            >
              {get(state, "button.iconPosition", "") === "before" &&
              get(state, "button.icon", "") !== "" ? (
                <span
                  className={get(state, "button.icon", "")}
                  style={{
                    fontSize: buttonFontSize,
                    color: buttonColor,
                    paddingRight: 4,
                  }}
                />
              ) : (
                ""
              )}

              {get(props, "labelButton", "")}

              {get(state, "button.iconPosition", "") === "after" &&
              get(state, "button.icon", "") !== "" ? (
                <span
                  className={get(state, "button.icon", "")}
                  style={{
                    fontSize: buttonFontSize,
                    color: buttonColor,
                    paddingLeft: 4,
                  }}
                />
              ) : (
                ""
              )}
            </Button>
          </Box>
        </Flex>
      </div>
    );
  }
  /**backgroundColor: get(
            state,
            "colorProps.backgroundColor",
            "transparent"
          ),
          borderRadius: get(state, "borderRadius", "0px"), */
  return (
    <div style={{ padding: get(state, "containerPadding", "0px") }}>
      <Flex
        flexDirection={get(layout, "direction", "row")}
        alignItems={get(layout, "align", "center")}
        justifyContent={get(layout, "jutify", "center")}
        px={[2]}
        style={{
          backgroundColor: get(
            state,
            "colorProps.backgroundColor",
            "transparent"
          ),
          borderRadius: get(state, "borderRadius", "0px"),
        }}
      >
        <Input
          id='email'
          name='email'
          type='email'
          placeholder={get(props, "placeholder", "")}
          inputColor={get(state, "colorProps.color", "transparent")}
          bgColor={get(state, "colorProps.backgroundColor", "transparent")}
        />
        <Button
          style={{
            fontSize: buttonFontSize,
            borderRadius: get(state, "button.borderRadius", 0),
            padding: get(state, "button.padding", "0px"),
            backgroundColor: get(
              state,
              "colorProps.buttonBackgroundColor",
              "transparent"
            ),
            color: buttonColor,
            fontWeight: "bold",
          }}
        >
          {get(state, "button.iconPosition", "") === "before" &&
          get(state, "button.icon", "") !== "" ? (
            <span
              className={get(state, "button.icon", "")}
              style={{
                fontSize: buttonFontSize,
                color: buttonColor,
                paddingRight: 4,
              }}
            />
          ) : (
            ""
          )}

          {get(props, "labelButton", "")}

          {get(state, "button.iconPosition", "") === "after" &&
          get(state, "button.icon", "") !== "" ? (
            <span
              className={get(state, "button.icon", "")}
              style={{
                fontSize: buttonFontSize,
                color: buttonColor,
                paddingLeft: 4,
              }}
            />
          ) : (
            ""
          )}
        </Button>
      </Flex>
    </div>
  );
};
