export default {
  categories: {
    title: "Categories",
    edit_title: "Edit Categories",
    create_title: "Create Categories",
    create_filter: "Create Filter",
    edit_filter: "Edit Filter",
    fields: {
      active: "Active",
      name: "Name",
      category_name: "Category Name",
      site_id: "Site",
      sort_order: "Sort Order",
      create_at: "Create On",
      property: "Properties",
      icon: "Icon 600x600",
      banner: "Banner 960x400",
      type: "Type",
      data_type: "Data Type",
      promotions: "Promotions",
      popular: "Populars",
      attribute: "Attribute",
      filters: "Search Filters",
      website: "Website",
      code: "Code",
      variant: "Variant",
      slug: "Slug",
    },
    label: {
      add_root_category: "Add Root Category",
      add_sub_category: "Add Sub Category",
      select_category: "Select Category",
      metadata: "Metadata",
      metadata_title: "Title",
      metadata_description: "Description",
      metadata_keywords: "Keyword",
      metadata_custom_canonical_page: "Canonical Page",
    },
    note_text: {
      code: "This is used for system internally. Don’t use spaces",
    },
    error: {
      variant_not_selected: "Variant not selected",
      variant_selected: "Variant Already Selected",
    },
    messages: {
      loading: "Loading...",
      element_updated: "Element updated",
      empty_list:
        "List empty, Please choices Categories first for this website ",
      remove_item: "Do you want to remove category?",
    },
  },
};
