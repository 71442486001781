import React from "react";
import { Flex } from "rebass";
// import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { PaperCard } from "../../../../components";
/**Processing
Cancelled
Awaiting Return Shipment
Received
Refunded */
const listStatus = [
  { id: "Processing", text: "Processing" },
  { id: "Cancelled", text: "Cancelled" },
  { id: "Awaiting Return Shipment", text: "Awaiting Return Shipment" },
  { id: "Received", text: "Received" },
  { id: "Refunded", text: "Refunded" },
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const EditDetailFields = (props) => {
  const { translate, state, onSetValue } = props;
  const classes = useStyles();

  return (
    <Flex width={[1]} flexDirection={"column"} py={[3]}>
      <PaperCard
        title={translate("resources.orders.labels.details")}
        showButton={false}
        elevation={3}
        bgHeader={"#eeeeee"}
        propsHeader={{ px: [3], py: [2] }}
      >
        <Flex width={[1]} flexDirection={"column"} p={[3]}>
          <Flex
            width={[1]}
            flexDirection={"row"}
            sx={{
              "&>div": {
                pr: [4],
              },
            }}
          >
            <FormControl className={classes.formControl}>
              <InputLabel id='provider-select-status-label'>
                {translate("resources.orders.fields.status")}
              </InputLabel>
              <Select
                labelId='provider-select-status-label'
                id='provider-select-status'
                value={state.status}
                onChange={(e) => onSetValue("status", e.target.value)}
              >
                {listStatus.map((p, index) => {
                  return (
                    <MenuItem value={p.id} key={index}>
                      {p.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Flex>
          <Flex px={[2]}>
            <TextField
              label={translate("resources.orders.fields.remarks")}
              fullWidth={true}
              value={state.remarks}
              onChange={(e) => onSetValue("remarks", e.target.value)}
            />
          </Flex>
        </Flex>
      </PaperCard>
    </Flex>
  );
};

export default EditDetailFields;
