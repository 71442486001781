import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout19 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const GridListView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='GridListView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};
GridListView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};

GridListView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "GridListView",
    containerPadding: "0px",
    backgroundColor: "rgba(255,255,255,0)",
    color: "rgba(0,0,0,1)",
    fontSize: {
      desktop: 14,
      mobile: 10,
    },
    align: "center",
    title: "",
    modeType: "list",
    showAll: false,
    urlShowAll: "",
    valueText: [
      {
        imageUrl: {
          url:
            "https://s3-ap-southeast-1.amazonaws.com/oleh-prod/assets/1/product/For-testing-only-UDO.png",
          urlMobile:
            "https://s3-ap-southeast-1.amazonaws.com/oleh-prod/assets/1/product/For-testing-only-UDO.png",
        },
        title: "Loyalty",
        subtitle: "",
        link: {
          target: "",
          type: "URL",
          value: "",
          isLink: false,
        },
      },
    ],
    appointment: {
      isAppointment: false,
      id: 0,
      list: [],
    },
    package: {
      isPackage: false,
      id: 0,
      list: [],
    },
    products: {
      isProduct: false,
      id: 0,
      list: [],
    },
  },

  layout: Layout19,
};

export default GridListView;
