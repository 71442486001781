import React from "react"
import { useTranslate, BooleanInput } from "react-admin"
import { Box, Flex } from "rebass"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { RaTextInput } from "../../../components"
import { useStyles } from "./utils"

const Settings = (props) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="settings-fields-content"
          id="settings-fields-header"
        >
          <Typography className={classes.heading}>
            {translate("resources.payments.label.settings")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Flex width={[1]} flexDirection={"column"}>
            <Box width={[1]}>
              <RaTextInput
                source="settings.app_id"
                label={"resources.payments.fields.app_id"}
                style={{ width: 320 }}
              />
            </Box>
            <Box width={[1]} py={[2]}>
              <RaTextInput
                source="settings.app_secret"
                label={"resources.payments.fields.app_secret"}
                style={{ width: 320 }}
              />
            </Box>
            <Box width={[1]}>
              <BooleanInput
                source="settings.sandbox"
                label={"resources.payments.fields.sandbox"}
              />
            </Box>
          </Flex>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default Settings
