import React, { Component } from "react"
import PropTypes from "prop-types"
import { Box } from "rebass"
import { SwitchInput } from "../"
import { ContentDetail } from "../../actions"
import { BackgroundImage } from "./dataList"
import { ContentBG } from "./Fields"

class ItemBackgroundCarousel extends Component {
  constructor(props) {
    super(props)
    const {
      backgroundColor,
      backgroundImage,
      isBackgroundImage,
      bgProps,
      padding,
      width,
      alignment,
      backgroundColorParent,
    } = props

    this.state = {
      backgroundColorParent: backgroundColorParent,
      backgroundColor: backgroundColor,
      backgroundImage: backgroundImage,
      isBackgroundImage: isBackgroundImage,
      bgProps: bgProps,
      padding: padding,
      type: width.type || "%",
      contentWidth: width.contentWidth || 100,
      maxValueWidth: width.type === "px" ? 0 : 100,
      interval: width.type === "px" ? 20 : 1,
      valign: alignment.vertical,
      align: alignment.horizontal,
    }
  }
  onUpdateUrl = value => {
    this.setState({ backgroundImage: value })
    this.updateContent("backgroundImage", value)
  }
  onToggle = e => {
    this.setState({ isBackgroundImage: e.target.checked })
    this.updateContent("isBackgroundImage", e.target.checked)
  }

  onChangeBackgroundImageProperties = (event, value, key) => {
    let { bgProps } = this.state
    bgProps[key] = event.target.value
    this.setState({
      bgProps: bgProps,
    })
    this.updateContent("bgProps", bgProps)
  }

  onSetValue = (key, value) => {
    this.setState({ [key]: value })
    this.updateContent(key, value)
  }

  onChangeType = event => {
    let width = 0
    let content = this.state.contentWidth
    let interval = 20
    if (event.target.value === "%") {
      width = 100
      content = 100
      interval = 1
    }
    this.setState({
      type: event.target.value,
      maxValueWidth: width,
      contentWidth: content,
      interval: interval,
    })
    this.updateContent("type", event.target.value)
  }

  updateContent = (key, value) => {
    const {
      backgroundColor,
      backgroundImage,
      isBackgroundImage,
      bgProps,
      padding,
      type,
      contentWidth,
      valign,
      align,
      backgroundColorParent,
    } = this.state
    const values = {
      backgroundColorParent:
        key === "backgroundColorParent" ? value : backgroundColorParent,
      backgroundColor: key === "backgroundColor" ? value : backgroundColor,
      backgroundImage: key === "backgroundImage" ? value : backgroundImage,
      isBackgroundImage:
        key === "isBackgroundImage" ? value : isBackgroundImage,
      bgProps: key === "bgProps" ? value : bgProps,
      padding: key === "padding" ? value : padding,
      width: {
        type: key === "type" ? value : type,
        contentWidth: key === "contentWidth" ? value : contentWidth,
      },
      alignment: {
        vertical: key === "vertical" ? value : valign,
        horizontal: key === "horizontal" ? value : align,
      },
    }
    this.props.updateBackground(values)
  }

  onUpdateDataBackgroundCarousel(value) {
    this.setState({
      backgroundCarousel: value,
    })
    this.updateContent("backgroundCarousel", value)
  }

  render() {
    return (
      <Box width={[1]}>
        <ContentBG
          state={this.state}
          onChangeType={this.onChangeType}
          onSetValue={this.onSetValue}
        />
        <ContentDetail>
          <SwitchInput
            id={`bg-image`}
            title={"Using background image"}
            checked={this.state.isBackgroundImage}
            onChange={e => this.onToggle(e)}
          />
          {this.state.isBackgroundImage && (
            <BackgroundImage
              contentId={"itembackgroundCarousel"}
              state={this.state}
              onChangeBackgroundImageProperties={
                this.onChangeBackgroundImageProperties
              }
              onSetValue={this.onSetValue}
            />
          )}
        </ContentDetail>
      </Box>
    )
  }
}

ItemBackgroundCarousel.propTypes = {
  updateBackground: PropTypes.func,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.object,
  bgProps: PropTypes.object,
  isBackgroundImage: PropTypes.bool,
  title: PropTypes.string,
  typeBackground: PropTypes.number,
  backgroundCarousel: PropTypes.object,
  isShowDelete: PropTypes.bool,
}

ItemBackgroundCarousel.defaultProps = {
  isShowDelete: true,
  title: "Background Row",
  backgroundImage: { url: "", urlMobile: "" },
  backgroundColor: "#FFFFFF",
  isBackgroundImage: false,
  bgProps: {
    size: "cover",
    position: "center",
    repeat: "no-repeat",
    ratio: 1.2,
  },
  padding: "0px",
  width: { type: "%", contentWidth: 100 },
  alignment: {
    vertical: "center",
    horizontal: "center",
  },
}

export default ItemBackgroundCarousel
