import React from "react";
import {
  BlockCounter,
  Alignment,
  TextInput,
  Title,
  //   SelectInput,
  SwitchInput,
} from "../components";
import { Box } from "rebass";
import { UploadAvatar } from "../../../components";
import { SketchColorPicker } from "../../../components";
// import { SelectInputCollection } from "../components";
import { BoxContent } from "../styled";
import { PanelCard, ContentDetail } from "../actions";
import ListCustom from "./ListCustom";
// const dataTypeCustom = [
//   { text: "Custom", value: "custom" },
//   { text: "Collections", value: "collection" },
// ];

const FormFields = (props) => {
  const { state, onSetValue, onSetHeaderValue } = props;
  return (
    <BoxContent style={{ height: state.heightContent - 120 }}>
      <Box width={[1]} p={[2]}>
        <ListCustom list={state.list} onSetValue={onSetValue} />
        {/* {collections && collections.length > 0 && (
          <ContentDetail>
            <SelectInputCollection
              id={`type-flat-button`}
              title={"Collections"}
              value={state.collections}
              list={collections}
              onChange={(e) => onSetValue("collections", e.target.value)}
            />
          </ContentDetail>
        )} */}
      </Box>
      <PanelCard title={"General"} id={"content-general-accordion"}>
        <Box width={[1]} pb={[2]} style={{ height: 420 }}>
          <ContentDetail>
            <BlockCounter
              title={"Container Padding"}
              containerPadding={state.containerPadding}
              onUpdatePadding={(containerPadding) =>
                onSetValue("containerPadding", containerPadding)
              }
            />
            <Alignment
              value={state.align}
              onChange={(value) => onSetValue("align", value)}
              title="Alignment"
            />
            {/* <SelectInput
            id={`type-content`}
            title={"Type Content"}
            value={state.typeContent}
            list={dataTypeCustom}
            onChange={(e) => onSetValue("typeContent", e.target.value)}
          /> */}
          </ContentDetail>
        </Box>
      </PanelCard>

      <PanelCard title={"Header"} id={"header-accordian"}>
        <ContentDetail>
          <Box width={[1]} pb={[2]}>
            <TextInput
              value={state.header.title}
              onChange={(value) => onSetHeaderValue("title", value)}
              name={`header-title`}
              title="Title"
              fullWidth={true}
            />
          </Box>
          <BlockCounter
            title={"Container Padding"}
            containerPadding={state.header.containerPadding}
            onUpdatePadding={(containerPadding) =>
              onSetHeaderValue("containerPadding", containerPadding)
            }
          />
          <SwitchInput
            id={`show-desktop-state`}
            title={"Show Desktop"}
            name={"show-desktop"}
            checked={state.header.isShowDesktop}
            onChange={(e) =>
              onSetHeaderValue("isShowDesktop", e.target.checked)
            }
          />
          <SwitchInput
            id={`show-mobile-state`}
            title={"Show Mobile"}
            name={"show-mobile"}
            checked={state.header.isShowMobile}
            onChange={(e) => onSetHeaderValue("isShowMobile", e.target.checked)}
          />
        </ContentDetail>
        <ContentDetail>
          <SketchColorPicker
            title={"Background"}
            onUpdateColor={(color) =>
              onSetHeaderValue("backgroundColor", color)
            }
            color={state.header.backgroundColor}
          />
        </ContentDetail>
        <ContentDetail>
          <Title title="Upload Thumbnail" />
          <UploadAvatar
            type={"default"}
            imageUrl={state.header.thumbnail}
            onUpdateUrl={(value) => onSetHeaderValue("thumbnail", value)}
            title="Thumbnail-Image"
          />
        </ContentDetail>
      </PanelCard>
    </BoxContent>
  );
};

export default FormFields;
