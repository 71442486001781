/* eslint-disable no-use-before-define */
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 4px",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function ChipInput(props) {
  const classes = useStyles();
  const { title, id, placeholder, dataSource, onChange, value } = props;
  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id={id}
        value={value}
        options={dataSource}
        getOptionLabel={(option) => option.text}
        filterSelectedOptions
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            label={title}
            placeholder={placeholder}
            fullWidth
          />
        )}
      />
    </div>
  );
}
