import * as React from "react";
import { Children, cloneElement, memo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import {
  useReference,
  //   UseReferenceProps,
  getResourceLinkPath,
  //   LinkToType,
  Link,
} from "react-admin";

import { LinearProgress } from "@material-ui/core";

import sanitizeRestProps from "./sanitizeRestProps";

const RaReferenceField = ({ children, record, source, ...props }) => {
  if (React.Children.count(children) !== 1) {
    throw new Error("<ReferenceField> only accepts a single child");
  }
  const { basePath, resource } = props;
  const resourceLinkPath = getResourceLinkPath({
    ...props,
    resource,
    record,
    source,
    basePath,
  });

  return (
    <PureReferenceFieldView
      {...props}
      {...useReference({
        reference: props.reference,
        id: get(record, source),
      })}
      record_parent={record}
      resourceLinkPath={resourceLinkPath}
    >
      {children}
    </PureReferenceFieldView>
  );
};

RaReferenceField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.element.isRequired,
  classes: PropTypes.any,
  className: PropTypes.string,
  record_parent: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.any,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string,
  sortBy: PropTypes.string,
  sortByOrder: PropTypes.oneOf(["ASC", "DESC"]),
  source: PropTypes.string.isRequired,
  translateChoice: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  linkType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func])
    .isRequired,
};

RaReferenceField.defaultProps = {
  addLabel: true,
  classes: {},
  link: "edit",
};

const useStyles = makeStyles(
  (theme) => ({
    link: {
      color: theme.palette.primary.main,
    },
  }),
  { name: "RaReferenceField" }
);

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e) => e.stopPropagation();

export const ReferenceFieldView = (props) => {
  const {
    basePath,
    children,
    className,
    error,
    loaded,
    loading,
    record,
    reference,
    referenceRecord,
    resource,
    resourceLinkPath,
    source,
    translateChoice = false,
    ...rest
  } = props;
  const classes = useStyles(props);
  if (!loaded) {
    return <LinearProgress />;
  }
  if (error) {
    return (
      <ErrorIcon
        aria-errormessage={error.message ? error.message : error}
        color="error"
        fontSize="small"
        role="presentation"
      />
    );
  }
  if (!referenceRecord) {
    return null;
  }

  if (resourceLinkPath) {
    return (
      <Link
        to={resourceLinkPath}
        className={className}
        onClick={stopPropagation}
      >
        {cloneElement(Children.only(children), {
          className: classnames(
            children.props.className,
            classes.link // force color override for Typography components
          ),
          record: referenceRecord,
          resource: reference,
          basePath,
          translateChoice,
          ...sanitizeRestProps(rest),
        })}
      </Link>
    );
  }

  return cloneElement(Children.only(children), {
    record: referenceRecord,
    resource: reference,
    basePath,
    translateChoice,
    ...sanitizeRestProps(rest),
  });
};

ReferenceFieldView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
  classes: PropTypes.any,
  loading: PropTypes.bool,
  record: PropTypes.any,
  reference: PropTypes.string,
  referenceRecord: PropTypes.any,
  resource: PropTypes.string,
  resourceLinkPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([false]),
  ]),
  source: PropTypes.string,
  translateChoice: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

const PureReferenceFieldView = memo(ReferenceFieldView);

export default RaReferenceField;
