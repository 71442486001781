import React, { Component } from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { get } from "lodash";
import { FooterAction } from "../actions";
import { defaultDataList } from "./datalist";
import { GridListFields } from "./Fields";
class GridListView extends Component {
  constructor(props) {
    super(props);
    const values = get(props, "data.values", defaultDataList);
    this.state = {
      heightContent: props.heightContent,
      widthContent: props.widthContent,
      value: values.valueText,
      backgroundColor: values.backgroundColor,
      oldValue: props.data,
      containerPadding: values.containerPadding,
      align: values.align,
      fontSize: values.fontSize,
      modeType: values.modeType,
      color: values.color,
      title: values.title,
      products: values.products || { isProduct: false, id: 0, list: [] },
      package: values.package || { isPackage: false, id: 0, list: [] },
      appointment: values.appointment || {
        isAppointment: false,
        id: 0,
        list: [],
      },
    };
    this.onChangeText = this.onChangeText.bind(this);
  }
  onChangeText = (valueHtml) => {
    this.setState({ value: valueHtml });
  };
  onClickSave = () => {
    let newData = this.props.data;
    newData.values.valueText = this.state.value;
    newData.values.modeType = this.state.modeType;
    newData.values.backgroundColor = this.state.backgroundColor;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values.align = this.state.align;
    newData.values.fontSize = this.state.fontSize;
    newData.values.color = this.state.color;
    newData.values.title = this.state.title;
    newData.values.products = this.state.products;
    newData.values.package = this.state.package;
    newData.values.appointment = this.state.appointment;
    this.props.onUpdateData(newData);
  };

  onUpdateFontSize = (value, type) => {
    const fs = this.state.fontSize;
    fs[type] = value;
    this.setState({ fontSize: fs });
  };

  onClickCopy = () => {
    let newData = this.props.data;
    newData.values.valueText = this.state.value;
    newData.values.modeType = this.state.modeType;
    newData.values.backgroundColor = this.state.backgroundColor;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values.align = this.state.align;
    newData.values.fontSize = this.state.fontSize;
    newData.values.color = this.state.color;
    newData.values.title = this.state.title;
    newData.values.products = this.state.products;
    newData.values.package = this.state.package;
    newData.values.appointment = this.state.appointment;
    localStorage.setItem("GridListView", JSON.stringify(newData.values));
  };
  onClickPaste = () => {
    const textContent = JSON.parse(localStorage.getItem("GridListView"));
    if (textContent) {
      const {
        valueText,
        modeType,
        backgroundColor,
        containerPadding,
        align,
        fontSize,
        color,
        title,
        products,
        appointment,
      } = textContent;

      this.setState({
        value: valueText,
        modeType: modeType,
        backgroundColor: backgroundColor,
        oldValue: this.props.data,
        containerPadding: containerPadding,
        align: align,
        fontSize: fontSize,
        color: color,
        title: title,
        products: products,
        package: textContent.package,
        appointment: appointment,
      });
      this.onChangeText(valueText);
    }
  };

  onSetValue = (key, value) => {
    this.setState({ [key]: value });
  };
  onChangeProducts = (valueText, products) => {
    this.setState({
      value: valueText,
      products: products,
    });
  };
  render() {
    return (
      <Flex width={[1]} flexDirection={"column"} pb={["80px"]}>
        <GridListFields
          state={this.state}
          onSetValue={this.onSetValue}
          onUpdateFontSize={this.onUpdateFontSize}
          data={this.props.data}
          onChangeProducts={this.onChangeProducts}
        />
        <FooterAction
          onClickSave={this.onClickSave}
          onClickCopy={this.onClickCopy}
          onClickPaste={this.onClickPaste}
          widthContent={this.state.widthContent}
        />
      </Flex>
    );
  }
}

GridListView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

GridListView.defaultProps = {
  data: {},
};
export default GridListView;
