export const typeContainer = [
  { value: "across", text: "across" },
  { value: "acrossOverlay", text: "acrossOverlay" },
  { value: "vertical", text: "vertical" },
  { value: "verticalOverlay", text: "verticalOverlay" },
  { value: "grid", text: "grid" },
  { value: "gridBar", text: "gridBar" },
];

export const typeImageAnimation = [
  { value: "to", text: "to" },
  { value: "from", text: "from" },
];

export const easeAnimation = [
  { value: "easeInSine", text: "easeInSine" },
  { value: "easeOutSine", text: "easeOutSine" },
  { value: "easeInOutSine", text: "easeInOutSine" },
  { value: "easeInQuad", text: "easeInQuad" },
  { value: "easeOutQuad", text: "easeOutQuad" },
  { value: "easeInOutQuad", text: "easeInOutQuad" },
  { value: "easeInCubic", text: "easeInCubic" },
  { value: "easeOutCubic", text: "easeOutCubic" },
  { value: "easeInOutCubic", text: "easeInOutCubic" },
  { value: "easeInQuart", text: "easeInQuart" },
  { value: "easeOutQuart", text: "easeOutQuart" },
  { value: "easeInOutQuart", text: "easeInOutQuart" },
  { value: "easeInQuint", text: "easeInQuint" },
  { value: "easeOutQuint", text: "easeOutQuint" },
  { value: "easeInOutQuint", text: "easeInOutQuint" },
  { value: "easeInExpo", text: "easeInExpo" },
  { value: "easeOutExpo", text: "easeOutExpo" },
  { value: "easeInOutExpo", text: "easeInOutExpo" },
  { value: "easeInCirc", text: "easeInCirc" },
  { value: "easeOutCirc", text: "easeOutCirc" },
  { value: "easeInOutCirc", text: "easeInOutCirc" },
  { value: "easeInBack", text: "easeInBack" },
  { value: "easeOutBack", text: "easeOutBack" },
  { value: "easeInOutBack", text: "easeInOutBack" },
  { value: "easeInElastic", text: "easeInElastic" },
  { value: "easeOutElastic", text: "easeOutElastic" },
  { value: "easeInOutElastic", text: "easeInOutElastic" },
  { value: "easeInBounce", text: "easeInBounce" },
  { value: "easeOutBounce", text: "easeOutBounce" },
  { value: "easeInOutBounce", text: "easeInOutBounce" },
];

export const typeTexty = [
  { value: "left", text: "left" },
  { value: "right", text: "right" },
  { value: "top", text: "top" },
  { value: "bottom", text: "bottom" },
  { value: "alpha", text: "alpha" },
  { value: "scale", text: "scale" },
  { value: "scaleX", text: "scaleX" },
  { value: "scaleBig", text: "scaleBig" },
  { value: "scaleY", text: "scaleY" },
  { value: "mask-bottom", text: "mask-bottom" },
  { value: "mask-top", text: "mask-top" },
  { value: "flash", text: "flash" },
  { value: "bounce", text: "bounce" },
  { value: "swing", text: "swing" },
  { value: "swing-y", text: "swing-y" },
  { value: "swing-rotate", text: "swing-rotate" },
];

export const modeTexty = [
  { value: "smooth", text: "smooth" },
  { value: "reverse", text: "reverse" },
  { value: "random", text: "random" },
  { value: "sync", text: "sync" },
];
export const textTransform = [
  { value: "uppercase", text: "uppercase" },
  { value: "lowercase", text: "lowercase" },
  { value: "normal", text: "normal" },
  { value: "capitalize", text: "capitalize" },
];

export const fontWeight = [
  { value: "normal", text: "normal" },
  { value: "bold", text: "bold" },
  { value: "100", text: "100" },
  { value: "200", text: "200" },
  { value: "300", text: "300" },
  { value: "400", text: "400" },
  { value: "500", text: "500" },
  { value: "600", text: "600" },
  { value: "700", text: "700" },
  { value: "800", text: "800" },
  { value: "900", text: "900" },
];
/*
{ value: "Rage Italic", text: "Rage Italic" },
  { value: "Montserrat", text: "Montserrat" },
  { value: "Ubuntu", text: "Ubuntu" },
  { value: "Roboto", text: "Roboto" },
*/
export const fontFamily = [
  { value: "Manrope", text: "Manrope" },
  { value: "Rajdhani", text: "Rajdhani" },
  { value: "sans-serif", text: "sans-serif" },
];

export const defaultItemList = {
  position_image: "left",
  name: "QueueAnim",
  bg: {
    backgroundColor: "#e6e6e6",
    imageUrl: {
      url: "",
      urlMobile: "",
      desktop: {
        width: 0,
        height: 0,
      },
      mobile: {
        width: 0,
        height: 0,
      },
    },
    isBgImage: false,
  },
  position: {
    boxImage: "486px",
    marginLeftImage: "50px",
    marginLeftText: "-100px",
    paddingImageMobile: "0px",
    paddingImageDesktop: "0px",
    marginImageMobile: "0px",
    marginImageDesktop: "0px 0px 0px 0px",
    paddingTitleMobile: "0px",
    paddingTitleDesktop: "0px",
    marginTitleMobile: "0px",
    marginTitleDesktop: "0px 0px 0px 0px",
  },
  title: {
    italic: true,
    weight: "normal",
    lineHeight: "100%",
    letterSpacing: "0px",
    color: "#83b828",
    size: "48pt",
    sizeMedium: "30pt",
    sizeSmall: "20pt",
    align: "center",
    family: "Rage Italic",
    textTransform: "normal",
    type: "right",
    mode: "smooth",
    delay: 200,
    duration: 1600,
    isAnimation: true,
    isEase: false,
    visible: true,
    ease: "",
    value: "find your perfect shoes",
    paddingMobile: "0px",
    paddingDesktop: "0px",
  },
  subtitle: {
    italic: false,
    weight: "normal",
    lineHeight: "100%",
    letterSpacing: "0px",
    color: "#3e3e3e",
    size: "48pt",
    sizeMedium: "30pt",
    sizeSmall: "20pt",
    align: "center",
    family: "Montserrat",
    textTransform: "uppercase",
    type: "alpha",
    mode: "sync",
    delay: 100,
    duration: 800,
    isAnimation: true,
    isEase: true,
    visible: true,
    ease: "easeOutQuart",
    value: "Sale for a week",
    paddingMobile: "0px",
    paddingDesktop: "0px",
  },
  imageUrl: {
    url: "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/CarouselAnimationView-url_image-0-image-desktop-BOY.png",
    urlMobile: "",
    desktop: {
      width: 0,
      height: 0,
    },
    mobile: {
      width: 0,
      height: 0,
    },
  },
  url_image:
    "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/CarouselAnimationView-url_image-0-image-desktop-BOY.png",
  url_image_mobile: "",
  url_bg: "",
  url_bg_mobile: "",
  image: {
    isAnimation: true,
    visible: true,
    animation: {
      type: "from",
      ease: "easeInQuart",
      opacity: 0,
      delay: 40,
      duration: 1500,
    },
  },
  href: {
    url: "",
    target: "",
    isCollection: false,
    collection: 0,
    type: "",
  },
  isLink: false,
};
