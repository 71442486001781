import styled from "styled-components"

export const ContentHorizontal = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
`
export const ContentVertical = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
`
export const ContentDetail = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 4px;
`
