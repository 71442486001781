import React from "react";
//, { useState }
// import {
//   ReactiveBase,
//   ReactiveList,
//   // ReactiveComponent,
//   // MultiList,
//   // DynamicRangeSlider,
// } from "@appbaseio/reactivesearch";
// import ResultProductCard from "./ResultProductCard";
// import { get } from "lodash";
// import { Pagination } from "antd";
//Row, Col, Box,
// import { Flex } from "rebass";
// import ColorPickerWrapper from "./ColorPickerWrapper";
// import ButtonClearAll from "./ButtonClearAll";
// import { elasticSearchServer } from "../../../restClient";
// import { sizePage } from "./utils";
// import ListColor from "./ListColor";
// import ItemProduct from "./ItemProduct";
// import { ProductShowSearch, ProductCategory } from "./ProductCategory";
import { Row, Col } from "antd";
export default (props) => {
  const { indexValue } = props;
  //state,typeContent
  // const [myColor, setMyColor] = useState([]);
  // const [myBrand, setMyBrand] = useState([]);
  // const listValue = get(state, "contentList.tags", []);
  // const columnProduct =
  //   state && state.columnProduct && state.columnProduct[indexValue]
  //     ? state.columnProduct[indexValue]
  //     : indexValue === 1
  //     ? 4
  //     : 12;
  // const showPagination = state.showPagination[indexValue];
  // // const myWidth = indexValue === 1 ? widthScreen / 5 : widthScreen / 2;
  // // console.log(listValue);
  // const gutter = get(state, "gutter", 0);
  // // const limit = get(state, `itemSetting.limit[${indexValue}]`, 100);
  // const showSearch = get(state, `showSearch[${indexValue}]`, false);
  // const limit = get(state, `itemSetting.limit[${indexValue}]`, 50);
  return (
    <Row gutter={0}>
      <Col span={indexValue === 1 ? 8 : 12}>Content Product</Col>
    </Row>
    // <ReactiveBase
    //   app='product'
    //   url={elasticSearchServer}
    //   theme={{
    //     typography: {
    //       fontFamily: "Roboto, Helvetica, sans-serif",
    //     },
    //     colors: {
    //       primaryColor: "#008000",
    //       titleColor: "white",
    //     },
    //     component: {
    //       padding: 10,
    //     },
    //   }}
    // >
    //   <ReactiveList
    //     componentId='results'
    //     dataField='name'
    //     size={showSearch ? sizePage : limit}
    //     className='product-list-reactactive'
    //     showResultStats={false}
    //     pagination={showSearch ? showPagination : showSearch}
    //     react={{
    //       and: ["Price", "Color", "Brand"],
    //     }}
    //     renderPagination={({
    //       // pages,
    //       totalPages,
    //       currentPage,
    //       setPage,
    //       // fragmentName,
    //     }) => {
    //       if (totalPages > 1 && showSearch) {
    //         return (
    //           <Flex width={[1]} flexDirection={"row"} justifyContent={"center"}>
    //             <Pagination
    //               current={currentPage}
    //               onChange={(page) => setPage(page)}
    //               total={totalPages}
    //             />
    //           </Flex>
    //         );
    //       }
    //       return <div />;
    //     }}
    //     defaultQuery={() => {
    //       return {
    //         query: {
    //           bool: {
    //             should: [
    //               {
    //                 terms: {
    //                   "tags.keyword": listValue,
    //                 },
    //               },
    //             ],
    //             minimum_should_match: "1",
    //           },
    //         },
    //       };
    //     }}
    //     render={({ data }) => (
    //       <Flex width={[1]}>
    //         {showSearch ? (
    //           <ProductShowSearch
    //             data={data}
    //             typeContent={typeContent}
    //             myBrand={myBrand}
    //             setMyBrand={setMyBrand}
    //             myColor={myColor}
    //             setMyColor={setMyColor}
    //             columnProduct={columnProduct}
    //             indexValue={indexValue}
    //             state={state}
    //             gutter={gutter}
    //           />
    //         ) : (
    //           <ProductCategory
    //             data={data}
    //             typeContent={typeContent}
    //             myBrand={myBrand}
    //             setMyBrand={setMyBrand}
    //             myColor={myColor}
    //             setMyColor={setMyColor}
    //             columnProduct={columnProduct}
    //             indexValue={indexValue}
    //             state={state}
    //             gutter={gutter}
    //           />
    //         )}
    //       </Flex>
    //     )}
    //     renderError={(error) => <div />}
    //     renderNoResults={(e) => <div />}
    //   />
    // </ReactiveBase>
  );
};
