import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
// import { sortBy } from "lodash"
// import rawTimeZone from "./raw-time-zones"
import timeZoneList from "./time-zones-names"

// const getDataSource = () => {
//   return sortBy(rawTimeZone, function (o) {
//     return o.name
//   })
// }

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}))

const AutoCompleteTimeZone = (props) => {
  const classes = useStyles()
  const { title, id, onChange, value, style } = props
  return (
    <div className={classes.root}>
      <Autocomplete
        id={id}
        value={value}
        options={timeZoneList}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={title}
            style={{ width: 300, ...style }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </div>
  )
}

export default AutoCompleteTimeZone
