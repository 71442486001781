import React from "react"
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import { makeStyles } from "@material-ui/core/styles"
import { grey } from "@material-ui/core/colors"
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}))

const TypeGradient = (props) => {
  const { type, onSetValue } = props
  const classes = useStyles()
  const bgColorLG = type === "linear-gradient" ? "pink" : grey[200]
  const bgColorRG = type === "radial-gradient" ? "pink" : grey[200]
  return (
    <div className={classes.root}>
      <ButtonGroup color="primary" aria-label=" primary button group">
        <Button
          style={{ backgroundColor: bgColorLG }}
          onClick={() => onSetValue("type", "linear-gradient")}
        >
          Linear
        </Button>
        <Button
          style={{ backgroundColor: bgColorRG }}
          onClick={() => onSetValue("type", "radial-gradient")}
        >
          Radiant
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default TypeGradient
