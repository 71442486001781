export const dataAccordian = {
  parent: "Content",
  type: "AccordionListView",
  containerPadding: "0px",
  align: "flex-start",
  typeContent: "custom",
  header: {
    title: "",
    containerPadding: "0px",
    thumbnail: "",
    backgroundColor: "transparent",
    isShowDesktop: false,
    isShowMobile: true,
  },
  list: [
    {
      title: "",
      url: "",
      isLink: false,
      target: "same",
    },
  ],
  collections: [],
};
