export const defaultSubcribe = {
  placeholder: "",
  labelBUtton: "",
  urlSubcribe: "",
  desktop: {
    show: true,
    colorProps: {
      backgroundColor: "rgba(255,255,255,0)",
      color: "rgba(0,0,0,1)",
      buttonBackgroundColor: "rgba(255,255,255,0)",
      buttonColor: "rgba(0,0,0,1)",
    },
    button: {
      icon: "",
      iconPosition: "",
      fontSize: 10,
      borderRadius: 0,
    },
    layout: {
      type: "combine",
      direction: "row",
      justify: "center",
      align: "center",
    },
    inputFontSize: 10,
    borderRadius: 0,
    containerPadding: "0px",
  },
  mobile: {
    show: true,
    colorProps: {
      backgroundColor: "rgba(255,255,255,0)",
      color: "rgba(0,0,0,1)",
      buttonBackgroundColor: "rgba(255,255,255,0)",
      buttonColor: "rgba(0,0,0,1)",
    },
    button: {
      icon: "",
      iconPosition: "",
      fontSize: 10,
      borderRadius: 0,
    },
    layout: {
      type: "combine",
      direction: "row",
      justify: "center",
      align: "center",
    },
    inputFontSize: 10,
    borderRadius: 0,
    containerPadding: "0px",
  },
};
