import React from "react";
//, { useState }
import { Flex } from "rebass";
//Text
// import { get } from "lodash";
import { Button } from "@material-ui/core";
// import TextField from "@material-ui/core/TextField";
// import { PaperCard, Currency } from "../../../../components";

const RefundItemFields = (props) => {
  /**
    title,
    labelButton,
    onPrintCreditMemo, */
  const { translate, onCreateMemo, state, loading } = props;
  //website,record,
  // const [values, setValues] = useState({
  //   adjustment: 0,
  //   refund_shipping: 0,
  //   admin_fee: 0,
  //   // refund_tax: 0,
  //   total: get(record, "amounts.total"),
  // });
  // const currency_symbol = get(website, "locale.currency_symbol", "");
  // const [subtotal, setSubtotal] = useState(get(record, "amounts.total"));
  // const subtotal = get(record, "amounts.total");
  // const onHandleChange = (e, name) => {
  //   const value = e.target.value * 1;
  //   let total = 0;
  //   if (name === "adjustment") {
  //     total = subtotal + value - values.admin_fee - values.refund_shipping;
  //   } else if (name === "refund_shipping") {
  //     total = subtotal + values.adjustment - value - values.admin_fee;
  //   } else if (name === "admin_fee") {
  //     total = subtotal + values.adjustment - value - values.refund_shipping;
  //   }

  //   setValues({
  //     ...values,
  //     total: total,
  //     [name]: e.target.value,
  //   });
  // };
  // useEffect(() => {
  //   let timeout = setTimeout(() => {
  //     let st = 0;
  //     items.map((t) => {
  //       st = st + t.subtotal * 1;
  //       return "";
  //     });
  //     setSubtotal(st);
  //   }, 500);
  //   return () => clearTimeout(timeout);
  // }, [subtotal, items]);

  // let total =
  //   subtotal * 1 +
  //   values.adjustment * 1 +
  //   values.refund_shipping * 1 +
  //   values.admin_fee * 1 +
  //   values.refund_tax * 1;
  // console.log(currency_symbol);
  return (
    <Flex width={[1]} flexDirection={"column"} py={[3]}>
      {state.reason === "Cancel Order" && (
        <Flex width={[1]} flexDirection={"row"} p={[3]}>
          <Button
            variant={"contained"}
            disabled={loading}
            style={{ borderRadius: 24 }}
            color={"primary"}
            onClick={onCreateMemo}
          >
            {/* {translate("resources.orders.labels.create_credit_memo")} */}
            {translate("resources.buttons.save")}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
/***
 * <PaperCard
        title={translate(title)}
        showButton={true}
        elevation={3}
        bgHeader={"#eeeeee"}
        propsHeader={{ px: [3], py: [2] }}
        onClick={onPrintCreditMemo}
        labelButton={translate(labelButton)}
      >
        <Flex width={[1]} flexDirection={"column"}>
          <Flex
            width={[1]}
            flexDirection={"row"}
            p={[3]}
            justifyContent={"space-between"}
          >
            <Text>{translate("resources.orders.fields.refund_subtotal")}</Text>
            <Text>
              <Currency
                value={subtotal}
                unit={currency_symbol}
                decimalScale={2}
              />
            </Text>
          </Flex>
          <Flex
            width={[1]}
            flexDirection={"row"}
            p={[3]}
            justifyContent={"space-between"}
          >
            <Text>{translate("resources.orders.fields.adjustment")}</Text>
            <Flex>
              <Text pt={[1]} pr={[1]}>
                {currency_symbol}
              </Text>
              <TextField
                label=''
                value={values.adjustment}
                type='number'
                onChange={(e) => onHandleChange(e, "adjustment")}
                name='adjustment-amendment'
                id='input_adjustment-amendment-'
                style={{ width: 80 }}
              />
            </Flex>
          </Flex>
          <Flex
            width={[1]}
            flexDirection={"row"}
            p={[3]}
            justifyContent={"space-between"}
          >
            <Text>{translate("resources.orders.fields.refund_shipping")}</Text>

            <Flex>
              <Text pt={[1]} pr={[1]}>
                -{currency_symbol}
              </Text>
              <TextField
                label=''
                value={values.refund_shipping}
                type='number'
                onChange={(e) => onHandleChange(e, "refund_shipping")}
                name='refund_shipping_amendment'
                id={`input_refund_shipping_amendment-`}
                style={{ width: 80 }}
              />
            </Flex>
          </Flex>
          <Flex
            width={[1]}
            flexDirection={"row"}
            p={[3]}
            justifyContent={"space-between"}
          >
            <Text>{translate("resources.orders.fields.admin_fee")}</Text>
            <Flex>
              <Text pt={[1]} pr={[1]}>
                -{currency_symbol}
              </Text>
              <TextField
                label=''
                value={values.admin_fee}
                type='number'
                onChange={(e) => onHandleChange(e, "admin_fee")}
                name='admin_fee-amendment'
                id='input_admin_fee-amendment-'
                style={{ width: 80 }}
              />
            </Flex>
          </Flex>
        </Flex>
        {/* <Flex
          width={[1]}
          flexDirection={"row"}
          p={[3]}
          justifyContent={"space-between"}
        >
          <Text>{translate("resources.orders.fields.refund_tax")}</Text>
          <Flex>
            <Text pt={[1]} pr={[1]}>
              {currency_symbol}
            </Text>
            <TextField
              label=""
              value={values.refund_tax}
              type="number"
              onChange={(e) => onHandleChange(e, "refund_tax")}
              name="refund_tax-amendment"
              id="input_refund_tax-amendment-"
              style={{ width: 80 }}
            />
          </Flex>
        </Flex> 
      //   <Flex
      //     width={[1]}
      //     flexDirection={"row"}
      //     p={[3]}
      //     justifyContent={"space-between"}
      //     bg={"#eeeeee"}
      //   >
      //     <Text>{translate("resources.orders.fields.total_refund")}</Text>
      //     <Text>
      //       <Currency
      //         value={values.total}
      //         unit={currency_symbol}
      //         decimalScale={2}
      //       />
      //     </Text>
      //   </Flex>
        
      // </PaperCard>
      
 */

export default RefundItemFields;
