import React, { Component } from "react";
import PropTypes from "prop-types";
import hoistNonReactStatic from "hoist-non-react-statics";

const withContext = (...items) => WrappedComponent => {
  class Connect extends Component {
    constructor(props, context) {
      super(props, context);
      this.state = context.store.subscribe(items, this.updateState);
    }

    UNSAFE_componentWillMount() {
      this.context.store.unsubscribe(items, this.updateState);
    }

    updateState = (key, value) => {
      this.setState({ [key]: value });
    };

    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
  }

  Connect.displayName = `Connect(${getDisplayName(WrappedComponent)})`;
  hoistNonReactStatic(Connect, WrappedComponent);

  Connect.contextTypes = {
    store: PropTypes.object
  };

  return Connect;
};

const getDisplayName = WrappedComponent => {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
};

export default withContext;
