import React, { useState } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { FooterAction } from "../actions";
import { Flex, Box } from "rebass";
import { IconButtonFields } from "./IconButtonFields";
import { BoxContent } from "../styled";
import { HrefContent } from "../components";
const defaultValues = {
  parent: "Content",
  type: "IconButtonView",
  containerPadding: "0px",
  disabled: false,
  buttonAlign: "center",
  buttonColors: {
    color: "#FFF",
    backgroundColor: "#e61919",
    hoverColor: "#42d696",
  },
  size: "small",
  sizeIcon: 24,
  paddingIcon: 16,
  tooltip: "tooltip",
  tooltipPosition: "top-right",
  icon: "home",
  link: {
    target: "",
    type: "URL",
    value: "",
    isLink: false,
  },
};

const IconButtonView = (props) => {
  const values = get(props, "data.values", defaultValues);
  const [state, setState] = useState({
    heightContent: props.heightContent,
    widthContent: props.widthContent,
    buttonColors: values.buttonColors,
    tooltipPosition: values.tooltipPosition,
    tooltip: values.tooltip,
    icon: values.icon,
    size: values.size,
    sizeIcon: values.sizeIcon,
    buttonAlign: values.buttonAlign,
    disabled: values.disabled,
    containerPadding: values.containerPadding,
    paddingIcon: values.paddingIcon,
    disabledTooltip: values.tooltip.length > 0 ? false : true,
    link: values.link,
  });

  const onClickCopy = () => {
    let newData = props.data;
    newData.values.tooltip = state.tooltip;
    newData.values.containerPadding = state.containerPadding;
    newData.values.buttonAlign = state.buttonAlign;
    newData.values.buttonColors = state.buttonColors;
    newData.values.tooltipPosition = state.tooltipPosition;
    newData.values.icon = state.icon;
    newData.values.disabled = state.disabled;
    newData.values.link = state.link;
    newData.values.size = state.size;
    newData.values.sizeIcon = state.sizeIcon;
    newData.values.paddingIcon = state.paddingIcon;
    localStorage.setItem("IconButtonView", JSON.stringify(newData.values));
  };
  const onClickPaste = () => {
    const IconContent = JSON.parse(localStorage.getItem("IconButtonView"));
    if (IconContent) {
      setState({
        ...state,
        buttonColors: IconContent.buttonColors,
        tooltipPosition: IconContent.tooltipPosition,
        tooltip: IconContent.tooltip,
        icon: IconContent.icon,
        size: IconContent.size,
        link: IconContent.link,
        buttonAlign: IconContent.buttonAlign,
        disabled: IconContent.disabled,
        containerPadding: IconContent.containerPadding,
        sizeIcon: IconContent.sizeIcon,
        paddingIcon: IconContent.paddingIcon,
      });
    }
  };
  const onClickSave = () => {
    let newData = props.data;
    newData.values.tooltip = state.tooltip;
    newData.values.containerPadding = state.containerPadding;
    newData.values.buttonAlign = state.buttonAlign;
    newData.values.buttonColors = state.buttonColors;
    newData.values.tooltipPosition = state.tooltipPosition;
    newData.values.icon = state.icon;
    newData.values.disabled = state.disabled;
    newData.values.link = state.link;
    newData.values.size = state.size;
    newData.values.sizeIcon = state.sizeIcon;
    newData.values.paddingIcon = state.paddingIcon;
    //  console.log(newData.values);
    props.onUpdateData(newData);
  };
  const onSetValue = (k, v) => {
    setState({
      ...state,
      [k]: v,
    });
  };
  return (
    <Flex width={[1]} flexDirection={"column"} pb={["80px"]}>
      <BoxContent style={{ height: props.heightContent - 120 }}>
        <IconButtonFields
          state={state}
          onSetValue={(k, v) => onSetValue(k, v)}
        />
        <Box width={[1]} py={[2]}>
          <HrefContent
            link={get(state, "link", {})}
            onSetValue={(k, v) => onSetValue(k, v)}
            websiteId={props.websiteId}
          />
        </Box>
      </BoxContent>
      <FooterAction
        onClickSave={onClickSave}
        onClickCopy={onClickCopy}
        onClickPaste={onClickPaste}
        widthContent={props.widthContent}
      />
    </Flex>
  );
};

IconButtonView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

IconButtonView.defaultProps = {
  data: {},
};
export default IconButtonView;
