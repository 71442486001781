import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import CartItem from "./CartItem";
import { PaperCard } from "../../../../components";

const ListReturnItem = (props) => {
  const {
    translate,
    record,
    title,
    labelButton,
    onPrintSlip,
    listQty,
    setListQty,
    type,
    website,
  } = props;
  // console.log(props);
  const list = get(record, "items", []);
  const currency_symbol = get(website, "locale.currency_symbol", "");
  console.log(list);
  return (
    <Flex width={[1]} flexDirection={"column"} py={[3]}>
      <PaperCard
        title={translate(title)}
        showButton={false}
        elevation={3}
        bgHeader={"#eeeeee"}
        propsHeader={{ px: [3], py: [2] }}
        onClick={onPrintSlip}
        labelButton={translate(labelButton)}
      >
        <Flex width={[1]} flexDirection={"column"}>
          {list.length > 0 &&
            list.map((item, index) => {
              return (
                <CartItem
                  item={item}
                  key={index}
                  translate={translate}
                  listQty={listQty}
                  setListQty={setListQty}
                  website={website}
                  typeAmendment={type}
                  currency_symbol={currency_symbol}
                />
              );
            })}
        </Flex>
      </PaperCard>
    </Flex>
  );
};

export default ListReturnItem;
