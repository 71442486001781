import React from "react";
import List from "./List";
import { get } from "lodash";
class FullWidthContainer extends React.Component {
  constructor(props) {
    super(props);

    const defaultRect = {};

    this.state = {
      containerRect: defaultRect,
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      containerRect: this.containerRef.current.getBoundingClientRect(),
    });
  }

  render() {
    const {
      categories,
      settings,
      colorText,
      colorPrimary,
      isDarkBG,
      values,
      backgroundColor,
      styleLogo,
    } = this.props;
    // console.log(this.containerRef.current.getBoundingClientRect());
    return (
      <div
        ref={this.containerRef}
        style={{
          display: "flex",
          position: "relative",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <List
          categories={categories}
          settings={settings}
          colorText={colorText}
          colorPrimary={colorPrimary}
          isDarkBG={isDarkBG}
          values={values}
          backgroundColor={backgroundColor}
          containerRect={this.state.containerRect}
          styleLogo={styleLogo}
        />
      </div>
    );
  }
}

const NavBar = (props) => {
  const {
    settings,
    isDarkBG,
    categories,
    values,
    backgroundColor,
    styleLogo,
  } = props;

  const colorPrimary = get(
    settings,
    "theme.colors.primary",
    "rgba(98, 211, 181, 1)"
  );
  const colorTextTheme = get(settings, "theme.colors.text", "#626163");
  const colorText = isDarkBG ? colorTextTheme : "#626163";
  return (
    <FullWidthContainer
      categories={categories}
      settings={settings}
      colorText={colorText}
      values={values}
      isDarkBG={isDarkBG}
      backgroundColor={backgroundColor}
      colorPrimary={colorPrimary}
      styleLogo={styleLogo}
    />
  );
};

export default NavBar;
