export const target = {
  drop(props, monitor) {
    if (!monitor.didDrop()) {
      props.layoutState.insertOrMoveItem(
        props.id,
        props.children.length,
        monitor.getItem()
      )
    }
  },
}

/**MultiRow,
  Body,
  Content,
   */

export const isShow = (type) => {
  let show = false

  switch (type) {
    case "ContentCarousel":
    case "TextView":
    case "TextGroupView":
    case "ImageView":
    case "ImageTextView":
    case "AvatarView":
    case "DividerView":
    case "CarouselView":
    case "FlatButtonView":
    case "IconButtonView":
    case "FAButtonView":
    case "ProductListView":
    case "YoutubeView":
    case "ListSlideView":
    case "CarouselLinkView":
    case "CarouselAnimationView":
      show = true
      break
    default:
      show = false
  }

  return show
}
