import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout8 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const IconButtonView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='IconButtonView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

IconButtonView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  layout: PropTypes.func,
};

IconButtonView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "IconButtonView",
    containerPadding: "0px",
    disabled: false,
    buttonAlign: "center",
    buttonColors: {
      color: "#FFF",
      backgroundColor: "#e61919",
      hoverColor: "#42d696",
    },
    size: "small",
    sizeIcon: 24,
    paddingIcon: 16,
    tooltip: "tooltip",
    tooltipPosition: "top-end",
    icon: "home",
    link: {
      target: "",
      type: "URL",
      value: "",
      isLink: false,
    },
  },
  layout: Layout8,
};
export default IconButtonView;
