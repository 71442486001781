import React from "react";
import { Box } from "rebass";
import { BlockCounter, SwitchInput, Counter } from "../../components";
import { ContentDetail } from "../../actions";
export default (props) => {
  const { state, indexValue, onSetValue } = props;
  const onSetItemValue = (k, v) => {
    const valueKey = state[k];
    valueKey[indexValue] = v;
    onSetValue(k, valueKey);
  };
  return (
    <Box width={[1]}>
      <ContentDetail>
        <SwitchInput
          id={`show-${indexValue}-view`}
          title={"Show"}
          checked={state.isShow[indexValue]}
          onChange={(e) => onSetItemValue("isShow", e.target.checked)}
        />
        <SwitchInput
          id={`show-price-${indexValue}-view`}
          title={"Show Price"}
          checked={state.showPrice[indexValue]}
          onChange={(e) => onSetItemValue("showPrice", e.target.checked)}
        />
        <SwitchInput
          id={`show-showPagination-${indexValue}-view`}
          title={"Show Pagination"}
          checked={state.showPagination[indexValue]}
          onChange={(e) => onSetItemValue("showPagination", e.target.checked)}
        />
        <SwitchInput
          id={`showSearch-${indexValue}-view`}
          title={"Show Search Category"}
          checked={state.showSearch[indexValue]}
          onChange={(e) => onSetItemValue("showSearch", e.target.checked)}
        />
        <Counter
          title={"Column"}
          value={state.columnProduct[indexValue]}
          onSetValue={(value) => onSetItemValue("columnProduct", value)}
          interval={1}
          subtitle=''
          minValue={0}
        />
        <Counter
          title={"Gutter"}
          value={state.gutter[indexValue]}
          onSetValue={(value) => onSetItemValue("gutter", value)}
          interval={1}
          subtitle=''
          minValue={0}
        />
      </ContentDetail>
      <BlockCounter
        title='Container Padding'
        containerPadding={state.containerPadding[indexValue]}
        onUpdatePadding={(containerPadding) =>
          onSetItemValue("containerPadding", containerPadding)
        }
      />
    </Box>
  );
};
