import React from "react"
import { Flex } from "rebass"
import ProductGroupCard from "./ProductGroupCard"

const ProductGroup = (props) => {
  const { products, widthContainer } = props

  return (
    <Flex width={[1]} flexWrap={"wrap"}>
      {products.length > 0 &&
        products.map((item, index) => {
          return (
            <Flex
              key={index}
              p={[2]}
              width={[1 / 2]}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <ProductGroupCard
                product={item}
                widthContainer={widthContainer}
              />
            </Flex>
          )
        })}
    </Flex>
  )
}

export default ProductGroup
