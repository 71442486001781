import React, { Fragment } from "react";
import { DropTarget } from "react-dnd";
import { Box, Flex } from "rebass";
import { get, lowerCase } from "lodash";
import ContainerDimensions from "react-container-dimensions";
import { connect } from "../../core";
import {
  ListSlideMan,
  ListCategories,
  ListProducts,
  ListCollections,
} from "./components";
import { target } from "./utils";

const EditLayout = ({ id, connectDropTarget, layoutState }) => {
  const {
    desktop,
    title,
    colorProps,
    valueText,
    modeType,
    contentList,
    linkViewAll,
    titleViewAll,
  } = layoutState.getItemJS(id).props.values;
  const myBackground = get(
    colorProps,
    "backgroundColor",
    "rgba(255,255,255,0)"
  );
  // console.log(layoutState.getItemJS(id).props.values);
  const tags = get(contentList, "tags", []);
  const products = get(contentList, "products", []);
  const collection = get(contentList, "value", "");
  return connectDropTarget(
    <div
      className='list-slide-view'
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        backgroundColor: myBackground,
        display: "flex",
        flex: 1,
      }}
    >
      <ContainerDimensions>
        {({ width }) => {
          // console.log(width);
          return (
            <Box width={[width]}>
              {
                // width > 0 &&
                //   ((valueText && valueText.length === 0) ||
                //     tags.length === 0) && (
                //     <Flex
                //       width={[1]}
                //       flexDirection={"row"}
                //       justifyContent={"center"}
                //       p={[2]}
                //     >
                //       Click here to add items
                //     </Flex>
                //   )
                // <div style={{ backgroundColor: "transparent" }}>
                //   {setting.modeType === "multiRow" ? (
                //     <ListMultiRow
                //       newMan={valueText}
                //       title={title}
                //       newSetting={setting}
                //       screenWidth={width > 1280 ? 1280 : width}
                //     />
                //   ) : (
                //     <ListNewMan
                //       newMan={valueText}
                //       title={title}
                //       products={products}
                //       newSetting={setting}
                //       screenWidth={width > 1280 ? 1280 : width}
                //     />
                //   )}
                // </div>
              }
              {lowerCase(modeType) === "list" &&
              valueText &&
              valueText.length > 0 ? (
                <ListSlideMan
                  screenWidth={width}
                  title={title}
                  colorProps={colorProps}
                  desktop={desktop}
                  valueText={valueText}
                  titleViewAll={titleViewAll}
                  linkViewAll={linkViewAll}
                />
              ) : lowerCase(modeType) === "list" ? (
                <Flex
                  width={[1]}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  p={[2]}
                >
                  Click here to add items
                </Flex>
              ) : (
                <Fragment />
              )}
              {lowerCase(modeType) === "category" && tags.length > 0 ? (
                <ListCategories
                  screenWidth={width}
                  title={title}
                  colorProps={colorProps}
                  desktop={desktop}
                  valueText={valueText}
                  titleViewAll={titleViewAll}
                  linkViewAll={linkViewAll}
                  tags={tags}
                  modeType={modeType}
                />
              ) : lowerCase(modeType) === "category" ? (
                <Flex
                  width={[1]}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  p={[2]}
                >
                  Click here to add items category
                </Flex>
              ) : (
                <Fragment />
              )}
              {lowerCase(modeType) === "product" && products.length > 0 ? (
                <ListProducts
                  screenWidth={width}
                  title={title}
                  colorProps={colorProps}
                  desktop={desktop}
                  valueText={valueText}
                  titleViewAll={titleViewAll}
                  linkViewAll={linkViewAll}
                  products={products}
                  modeType={modeType}
                />
              ) : lowerCase(modeType) === "product" ? (
                <Flex
                  width={[1]}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  p={[2]}
                >
                  Click here to add items Products
                </Flex>
              ) : (
                <Fragment />
              )}
              {lowerCase(modeType) === "collection" &&
              collection !== "" &&
              collection !== null ? (
                <ListCollections
                  screenWidth={width}
                  title={title}
                  colorProps={colorProps}
                  desktop={desktop}
                  valueText={valueText}
                  titleViewAll={titleViewAll}
                  linkViewAll={linkViewAll}
                  modeType={modeType}
                  collection={collection}
                />
              ) : lowerCase(modeType) === "collection" ? (
                <Flex
                  width={[1]}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  p={[2]}
                >
                  Click here to add items Products
                </Flex>
              ) : (
                <Fragment />
              )}
            </Box>
          );
        }}
      </ContainerDimensions>
    </div>
  );
};

const LayoutContainer = DropTarget(
  "ListSlideView",
  target,
  (conn, monitor) => ({
    connectDropTarget: conn.dropTarget(),
    isOverCurrent: monitor.isOver({ shallow: true }),
  })
)(EditLayout);

export default connect("layoutState", "readOnly")(LayoutContainer);

// export default LayoutContainer;s
