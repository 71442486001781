import React from "react";
import { Box, Flex } from "rebass";
import { UploadImage } from "../../../components";
import { ContentDetail } from "../actions";
import { Title, SwitchInput, DialogHrefContent } from "../components";
import ImageContentFields from "./ImageContentFields";

export default (props) => {
  const { item, onSetItemValue } = props;
  const onSetValue = (k, v) => {
    const newItem = {
      ...item,
      [k]: v,
    };
    onSetItemValue(newItem);
  };

  const onSetValueDesktop = (k, v) => {
    const newDesktop = {
      ...item.desktop,
      [k]: v,
    };
    onSetValue("desktop", newDesktop);
  };

  const onSetValueMobile = (k, v) => {
    const newMobile = {
      ...item.mobile,
      [k]: v,
    };
    onSetValue("mobile", newMobile);
  };
  return (
    <Box width={[1]}>
      <ContentDetail>
        <Title title='Upload Image' />
        <UploadImage
          type={"imagegroup"}
          imageUrl={item.imageUrl}
          onUpdateUrl={(value) => onSetValue("imageUrl", value)}
          title='Image'
        />
      </ContentDetail>
      <ContentDetail>
        <Title title={"Show Image"} />
        <Flex flexDirection={"row"} width={[1]} justifyContent={"flex-start"}>
          <SwitchInput
            id={`showDesktop`}
            title='Show on Desktop'
            checked={item.isShowDesktop}
            onChange={(e) => onSetValue("isShowDesktop", e.target.checked)}
          />
          <SwitchInput
            id={`showMobile`}
            title='Show on Mobile'
            checked={item.isShowMobile}
            onChange={(e) => onSetValue("isShowMobile", e.target.checked)}
          />
        </Flex>
      </ContentDetail>
      <ContentDetail>
        <DialogHrefContent
          link={item.link}
          onSetValue={(v) => onSetValue("link", v)}
          websiteId={props.websiteId}
        />
      </ContentDetail>
      <Box width={[1]} py={[2]}>
        <Title title={"Desktop Settings"} />
        <ImageContentFields
          state={item.desktop}
          title={"Desktop"}
          onSetValue={(k, v) => onSetValueDesktop(k, v)}
        />
      </Box>
      <Box width={[1]} py={[2]}>
        <Title title={"Mobile Settings"} />
        <ImageContentFields
          state={item.mobile}
          title={"Mobile"}
          onSetValue={(k, v) => onSetValueMobile(k, v)}
        />
      </Box>
    </Box>
  );
};
