export { default as SelectInput } from "./SelectInput";
export { default as SelectInputCollection } from "./SelectInputCollection";
export { default as SelectInputFields } from "./SelectInputFields";
export { default as SelectTargetInput } from "./SelectTargetInput";
export { default as SelectInputProducts } from "./SelectInputProducts";
export const dataHref = [
  { text: "Same Page", value: "same" },
  { text: "New Page", value: "_blank" },
];

export const dataHrefEmpty = [
  { text: "Same Page", value: "" },
  { text: "New Page", value: "_blank" },
];

export const dataTypeLink = [
  { text: "Content", value: "Content" },
  { text: "Category", value: "Category" },
  { text: "Collection", value: "Collection" },
  { text: "URL", value: "URL" },
  { text: "SLUG", value: "Slug" },
];
