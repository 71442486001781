import React from "react";
import { Flex, Box, Image, Text } from "rebass";
import SearchBox from "./SearchBox";
import Menu3 from "./Menu3";
import { Badge } from "antd";
import { FontelloSvg } from "../../../components/Icons";

const Desktop2 = ({ settings, cart, categories }) => {
  const { items } = cart;
  const colorPrimary = "#CACACA";

  return (
    <Box width={[1]} bg={"secondary"}>
      <Flex width={[1]}>
        <Box width={[1]} bg={"secondary"}>
          <Flex
            width={[1, 1]}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={[2]}
          >
            <Flex width={[1]} flexDirection={"row"} alignItems={"center"}>
              <Image
                sx={{ width: ["60px"], height: "auto", mr: [2] }}
                src={settings.header.image}
                alt='logo'
              />

              {/* <Menu
                isMobile={false}
                settings={settings}
                categories={categories}
                style={{ marginBottom: 0 }}
              /> */}
              <Menu3
                isMobile={false}
                settings={settings}
                categories={categories}
                style={{ marginBottom: 0 }}
                marginLeft={"-80px"}
              />
            </Flex>
            <Flex style={{ zIndex: 2 }} flexDirection={"row"}>
              <SearchBox width={"300px"} />
              <Flex flexDirection={"column"} alignItems={"center"} px={[0, 3]}>
                <Badge count={items.length + 3} style={{ display: "flex" }}>
                  <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    pb={["1px"]}
                  >
                    <FontelloSvg
                      name={"shoppingCart"}
                      fill={colorPrimary}
                      size={"22px"}
                    />

                    <Text fontSize={[0]} pt={[1]}>
                      Cart
                    </Text>
                  </Flex>
                </Badge>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Desktop2;
