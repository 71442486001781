import React from "react";
// import ContainerDimensions from "react-container-dimensions";
// import { Link } from "react-router-dom";
import { get } from "lodash";
import { connect } from "../../../core";
import { SocialMediaList } from "../../../libs/socialmedia";
const SocialMediaView = (props) => {
  const { id, layoutState, widthScreen } = props;
  const { desktop, mobile, list } = layoutState.getItemJS(id).props.values;
  //   const isShowing = widthScreen > 480 ? isShowDesktop : isShowMobile;
  const newcontent = widthScreen > 640 ? desktop : mobile;
  //   if (!isShowing) {
  //     return <div />;
  //   }
  if (get(newcontent, "show", false) === false) {
    return <div />;
  }
  const containerPadding = get(newcontent, "containerPadding", "0px");
  const size = get(newcontent, "size", 16);
  const align = get(newcontent, "align", "center");
  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        display: "flex",
        flex: 1,
        width: "100%",
        padding: containerPadding,
      }}
    >
      <SocialMediaList list={list} size={size} align={align} />
    </div>
  );
};

export default connect("layoutState", "widthScreen")(SocialMediaView);
