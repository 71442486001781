import React from "react"
import { Box } from "rebass"
import { FormDataConsumer, useQueryWithStore } from "react-admin"
import {
  BetterCustomSimpleForm,
  EditToolbar,
  FullLoadingScreen,
} from "../../../components"
import EditFormFields from "./EditFormFields"

const EditTopic = (props) => {
  const { match } = props
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "websites",
    payload: { id: match.params.website_id },
  })

  if (loading) {
    return <FullLoadingScreen />
  }
  return (
    <Box>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <EditFormFields {...props} website={data} formData={formData} />
          )
        }}
      </FormDataConsumer>
    </Box>
  )
}

export const EditForm = (props) => {
  return (
    <BetterCustomSimpleForm
      {...props}
      title={"resources.faq.edit_title"}
      labelBack={"resources.faq.edit_title"}
      toolbar={<EditToolbar />}
      isShowBack={false}
    >
      <EditTopic {...props} isCreate={false} />
    </BetterCustomSimpleForm>
  )
}
