import { Layout19 } from "../../TemplateImageSonak";
export const template16A = {
  DiPpydI1: {
    id: "DiPpydI1",
    type: "Row6",
    props: {
      values: {
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: true,
            align: "middle",
            gutter: 20,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "1080px",
          column: 1,
          height: "100%",
          layout: {
            wrap: true,
            align: "top",
            gutter: 20,
            justify: "center",
            contentAlign: "center",
            contentJustify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 1,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 1,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 4,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 2,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 2,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 3,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 3,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 4,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    childOf: "Row",
    layout: Layout19,
    children: ["lhjAQn7C", "3n7/4URL", "umcApXcl", "xfackGLq"],
    myContent: [
      {
        id: "FWaIcynU",
        type: "MultiContentGroupView",
        props: {
          values: {
            list: [
              {
                id: "bh43v",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p><strong>Lorem ipsum dolor sit amet</strong></p>",
                  mobile: {
                    color: "rgba(0,0,0,1)",
                    order: 1,
                    width: null,
                    height: null,
                    fontSize: 10,
                    lineHeight: "120%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerMargin: null,
                    containerPadding: "16px 0px 16px 0px",
                  },
                  altText: null,
                  desktop: {
                    color: "rgba(0,0,0,1)",
                    order: 1,
                    width: null,
                    height: null,
                    fontSize: 12,
                    lineHeight: "120%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerMargin: null,
                    containerPadding: "20px 0px 20px 0px",
                  },
                  imageUrl: null,
                  isShowMobile: null,
                  isShowDesktop: null,
                },
                typeContent: "text",
              },
              {
                id: "5uiat",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text:
                    '<p><span style="color: rgb(0, 0, 0);">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p>',
                  mobile: {
                    text:
                      '<p><span style="color: rgb(0, 0, 0);">Consectetur adipiscing elit, sed do eiusmod tempor incididunt.</span></p>',
                    color: "rgba(0,0,0,1)",
                    order: 2,
                    fontSize: 10,
                    lineHeight: "120%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "0px",
                  },
                  desktop: {
                    color: "rgba(0,0,0,1)",
                    order: 2,
                    fontSize: 12,
                    lineHeight: "120%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "0px",
                  },
                },
                typeContent: "text",
              },
              {
                id: "1vfkj",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p><strong>shop now</strong></p>",
                  mobile: {
                    color: "rgba(0,0,0,1)",
                    order: 3,
                    fontSize: 10,
                    lineHeight: "120%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "20px 0px 20px 0px",
                  },
                  desktop: {
                    color: "rgba(0,0,0,1)",
                    order: 3,
                    fontSize: 12,
                    lineHeight: "120%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "20px 0px 20px 0px",
                  },
                },
                typeContent: "text",
              },
            ],
            type: "MultiContentGroupView",
            mobile: {
              containerPadding: "0px",
            },
            parent: "Content",
            layoutRow: {
              column: [1, 1],
              flexWrap: ["nowrap", "nowrap"],
              alignItems: ["flex-start", "flex-start"],
              flexDirection: ["column", "column"],
              justifyContent: ["flex-start", "flex-start"],
            },
            containerPadding: "0px",
          },
        },
        parent: {
          id: "xfackGLq",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "xfackGLq",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "IQPJ4GwH",
        type: "ImageView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            type: "ImageView",
            align: "center",
            mobile: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            parent: "Content",
            desktop: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            imageUrl: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-I3D.jpeg",
              mobile: {
                width: 0,
                height: 0,
              },
              desktop: {
                width: 0,
                height: 0,
              },
              urlMobile:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-mobile-EDZ.jpeg",
            },
          },
        },
        parent: {
          id: "lhjAQn7C",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "lhjAQn7C",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "YkoG+apq",
        type: "MultiContentGroupView",
        props: {
          values: {
            list: [
              {
                id: "bh43v",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p><strong>Lorem ipsum dolor sit amet</strong></p>",
                  mobile: {
                    color: "rgba(0,0,0,1)",
                    order: 1,
                    width: null,
                    height: null,
                    fontSize: 10,
                    lineHeight: "120%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerMargin: null,
                    containerPadding: "16px 0px 16px 0px",
                  },
                  altText: null,
                  desktop: {
                    color: "rgba(0,0,0,1)",
                    order: 1,
                    width: null,
                    height: null,
                    fontSize: 12,
                    lineHeight: "120%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerMargin: null,
                    containerPadding: "20px 0px 20px 0px",
                  },
                  imageUrl: null,
                  isShowMobile: null,
                  isShowDesktop: null,
                },
                typeContent: "text",
              },
              {
                id: "5uiat",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text:
                    '<p><span style="color: rgb(0, 0, 0);">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p>',
                  mobile: {
                    text:
                      '<p><span style="color: rgb(0, 0, 0);">Consectetur adipiscing elit, sed do eiusmod tempor incididun.</span></p>',
                    color: "rgba(0,0,0,1)",
                    order: 2,
                    fontSize: 10,
                    lineHeight: "120%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "0px",
                  },
                  desktop: {
                    color: "rgba(0,0,0,1)",
                    order: 2,
                    fontSize: 12,
                    lineHeight: "120%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "0px",
                  },
                },
                typeContent: "text",
              },
              {
                id: "1vfkj",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p><strong>shop now</strong></p>",
                  mobile: {
                    color: "rgba(0,0,0,1)",
                    order: 3,
                    fontSize: 10,
                    lineHeight: "120%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "20px 0px 20px 0px",
                  },
                  desktop: {
                    color: "rgba(0,0,0,1)",
                    order: 3,
                    fontSize: 12,
                    lineHeight: "120%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "20px 0px 20px 0px",
                  },
                },
                typeContent: "text",
              },
            ],
            type: "MultiContentGroupView",
            mobile: {
              containerPadding: "0px",
            },
            parent: "Content",
            layoutRow: {
              column: [1, 1],
              flexWrap: ["nowrap", "nowrap"],
              alignItems: ["flex-start", "flex-start"],
              flexDirection: ["column", "column"],
              justifyContent: ["flex-start", "flex-start"],
            },
            containerPadding: "0px",
          },
        },
        parent: {
          id: "umcApXcl",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "umcApXcl",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "xstn8gih",
        type: "ImageView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            type: "ImageView",
            align: "center",
            mobile: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            parent: "Content",
            desktop: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            imageUrl: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-O11.jpeg",
              mobile: {
                width: 0,
                height: 0,
              },
              desktop: {
                width: 0,
                height: 0,
              },
              urlMobile:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-mobile-N0V.jpeg",
            },
          },
        },
        parent: {
          id: "3n7/4URL",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "3n7/4URL",
          idx: 0,
          index: 1,
        },
      },
    ],
  },
};
