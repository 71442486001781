import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text } from "rebass";
import { get } from "lodash";
import { BlockCounter, Counter } from "../counter";
import { SelectInput } from "../select";
import { SketchColorPicker } from "../../../../components";

const listStyle = [
  { id: "none", name: "none" },
  { id: "hidden", name: "hidden" },
  { id: "dotted", name: "dotted" },
  { id: "dashed", name: "dashed" },
  { id: "solid", name: "solid" },
  { id: "double", name: "double" },
  { id: "groove", name: "groove" },
  { id: "ridge", name: "ridge" },
  { id: "inset", name: "inset" },
  { id: "outset", name: "outset" },
  { id: "initial", name: "initial" },
  { id: "inherit", name: "inherit" },
];

const BorderContent = (props) => {
  const { border, onUpdateBorder } = props;
  const [state, setState] = useState(border);
  const onChangeValue = (k, v, index) => {
    let myValue = state[k];

    if (state[k]) {
      if (myValue[index]) {
        myValue[index] = v;
      } else {
        if (myValue[index] === "") {
          myValue[index] = v;
        } else {
          if (typeof v === "number") {
            myValue = [1, 1];
            myValue[index] = v;
          } else {
            myValue = ["", ""];
            myValue[index] = v;
          }
        }
      }
    } else {
      if (typeof v === "number") {
        myValue = [1, 1];
        myValue[index] = v;
      } else {
        myValue = ["", ""];
        myValue[index] = v;
      }
    }

    const params = { ...state, [k]: myValue };
    setState(params);
    onUpdateBorder(params);
  };

  return (
    <Box width={[1]}>
      <Flex width={[1]} flexDirection={"column"}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontWeight={"bold"} pb={[2]}>
            Desktop View
          </Text>
        </Flex>
        <SketchColorPicker
          title={"Color"}
          onUpdateColor={(color) => onChangeValue("color", color, 1)}
          color={get(state, "color[1]", "#FFFFFF")}
        />
        <SketchColorPicker
          title={"Background"}
          onUpdateColor={(color) => onChangeValue("backgroundColor", color, 1)}
          color={get(state, "backgroundColor[1]", "#FFFFFF")}
        />
        <SelectInput
          id={`border-type`}
          title={"Type Border"}
          value={get(state, "type[1]", "solid")}
          list={listStyle}
          onChange={(e) => onChangeValue("type", e.target.value, 1)}
        />
        <BlockCounter
          containerPadding={get(state, "radius[1]", "0px")}
          onUpdatePadding={(radius) => onChangeValue("radius", radius, 1)}
          title={"Radius"}
        />
        <Counter
          isDisabled={false}
          title='Size'
          onSetValue={(value) => onChangeValue("size", value, 1)}
          value={get(state, "size[1]", 0)}
          interval={1}
          subtitle='px'
          minValue={0}
        />
      </Flex>
      <Flex
        width={[1]}
        flexDirection={"row"}
        style={{ height: 1, backgroundColor: "#d8d8d8" }}
      />
      <Flex width={[1]} flexDirection={"column"} py={[2]}>
        <Text fontWeight={"bold"} pb={[2]}>
          Mobile View
        </Text>
        <SketchColorPicker
          title={"Color"}
          onUpdateColor={(color) => onChangeValue("color", color, 0)}
          color={get(state, "color[0]", "#FFFFFF")}
        />
        <SketchColorPicker
          title={"Background"}
          onUpdateColor={(color) => onChangeValue("backgroundColor", color, 0)}
          color={get(state, "backgroundColor[0]", "#FFFFFF")}
        />
        <SelectInput
          id={`border-type`}
          title={"Type Border"}
          value={get(state, "type[0]", "solid")}
          list={listStyle}
          onChange={(e) => onChangeValue("type", e.target.value, 0)}
        />
        <BlockCounter
          containerPadding={get(state, "radius[0]", "0px")}
          onUpdatePadding={(radius) => onChangeValue("radius", radius, 0)}
          title={"Radius"}
        />
        <Counter
          isDisabled={false}
          title='Size Mobile'
          onSetValue={(value) => onChangeValue("size", value, 0)}
          value={get(state, "size[0]", 0)}
          interval={1}
          subtitle='px'
          minValue={0}
        />
      </Flex>
    </Box>
  );
};

BorderContent.propTypes = {
  border: PropTypes.object,
  title: PropTypes.string,
  onUpdateBorder: PropTypes.func.isRequired,
};

BorderContent.defaultProps = {
  border: {
    color: ["#e61919", "#e61919"],
    radius: ["0px", "0px"],
    size: [0, 0],
    type: ["solid", "solid"],
    backgroundColor: ["#FFFFFF", "#FFFFFF"],
  },
  title: "Setting Border",
};

export default BorderContent;
