import React from "react"
import { Field } from "react-final-form"
import { Box } from "rebass"
import TextField from "@material-ui/core/TextField"
import { addField, FieldTitle } from "react-admin"
import TextMessage from "./utils"

const renderTextField = ({
  input,
  label,
  notetext,
  source,
  resource,
  isRequired,
  meta: { touched, error },
  ...custom
}) => (
  <Box>
    <TextField
      label={
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      }
      error={!!(touched && error)}
      helperText={touched && error}
      {...input}
      {...custom}
    />
    {notetext && <TextMessage notetext={notetext} />}
  </Box>
)

const TextInputField = props => (
  <span>
    <Field {...props} component={renderTextField} required={props.validate} />
  </span>
)
export default addField(TextInputField)
