import React, { useState } from "react";
import { Text, Box, Flex } from "rebass";
import { get, has } from "lodash";
import { useTranslate } from "react-admin";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Autosuggest from "react-autosuggest";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { dataProvider, getSiteID } from "../../restClient";
import "./autosuggest.css";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export const getPhoto = function (photos) {
  var src = "";

  if (has(photos, "photo_set")) {
    var photoSet = get(photos, "photo_set");
    var key = Object.keys(photoSet);
    var path = `photo_set.${key[0]}.photos[0]`;
    src = get(photos, path, "");
  } else if (has(photos, "*")) {
    src = get(photos, "*.photos[0]", "");
  }
  return src;
};

const renderSuggestionsContainer = ({ containerProps, children, query }) => {
  return (
    <div {...containerProps} style={{ overflowY: "scroll", zIndex: 90000 }}>
      {children}
    </div>
  );
};

const renderSuggestion = (suggestion, classes) => {
  const myPhoto = getPhoto(get(suggestion, "photos"));
  return (
    <Flex flexDirection={"row"} flexWrap={"wrap"} py={[1]}>
      <span style={{ width: 24 }}>
        <Avatar src={myPhoto} className={classes.small} />
      </span>
      <Text fontSize={[0]} pl={["10px"]}>
        {suggestion.name}{" "}
        {get(suggestion, "slug", "") !== ""
          ? " - " + get(suggestion, "slug", "")
          : ""}
      </Text>
    </Flex>
  );
};

const AutoCompleteProduct = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const {
    value,
    setValue,
    suggestions,
    setSuggestions,
    onSuggestionsClearRequested,
  } = props;
  const [loading, setLoading] = useState(false);

  const getSuggestionValue = (suggestion) => {
    props.getItem(suggestion);
    setValue("");
    return "";
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };
  const onSuggestionsFetchRequested = ({ value }) => {
    if (value.length > 1) {
      setLoading(true);
      dataProvider
        .getList("products", {
          pagination: { page: 1, perPage: 50 },
          sort: { field: "name", order: "ASC" },
          filter: { site_id: getSiteID(), search: value },
        })
        .then((response) => {
          // console.log(response)
          if (response && response.data) {
            setSuggestions(response.data);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }
  };
  const inputProps = {
    value,
    onChange: onChange,
  };
  return (
    <Box width={[1]} className={"auto-complete-collection-product"}>
      <Autosuggest
        renderInputComponent={(inputProps) => (
          <FormControl fullWidth>
            <InputLabel htmlFor='auto-complete-collection-product-wrap'>
              {translate("resources.collections.fields.find_product")}
            </InputLabel>
            <Input
              fullWidth
              id={"auto-complete-collection-product-wrap"}
              style={{ width: 400 }}
              {...inputProps}
              endAdornment={
                <InputAdornment position='end'>
                  {loading && <CircularProgress size={20} />}
                </InputAdornment>
              }
            />
          </FormControl>
        )}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={(s) => renderSuggestion(s, classes)}
        renderSuggestionsContainer={renderSuggestionsContainer}
        inputProps={inputProps}
      />
    </Box>
  );
};

export default AutoCompleteProduct;
