import React from "react";
import { includes, get } from "lodash";
import { Flex, Box } from "rebass";
// import { lightBlue } from "@material-ui/core/colors"
import BackIcon from "@material-ui/icons/ChevronLeft";
import { grey } from "@material-ui/core/colors";
import { ListButton } from "../buttons";
import { Title } from "../Text";
import { BOX_SHADOW_COLOR } from "../../utils";
export const HeaderCustomForm = ({
  translate,
  paramsUrl,
  title,
  finalInitialValues,
  isShowBack,
  labelBack,
}) => {
  let newParamsUrl = paramsUrl;
  if (includes(paramsUrl, "attributeSet")) {
    newParamsUrl = "/attributes";
  } else if (
    includes(paramsUrl, "websites") &&
    get(finalInitialValues, "site_id")
  ) {
    newParamsUrl = `/sites/${get(finalInitialValues, "site_id")}/websites`;
  }
  if (includes(paramsUrl, "layout") && get(finalInitialValues, "website_id")) {
    newParamsUrl = `/websites/${get(finalInitialValues, "website_id")}/layout`;
  } else if (
    includes(paramsUrl, "faq") &&
    get(finalInitialValues, "website_id")
  ) {
    newParamsUrl = `/websites/${get(finalInitialValues, "website_id")}/faq`;
  } else if (
    includes(paramsUrl, "shipping") &&
    get(finalInitialValues, "website_id")
  ) {
    newParamsUrl = `/websites/${get(
      finalInitialValues,
      "website_id"
    )}/shipping`;
  }
  return (
    <Box width={[1]}>
      {isShowBack ? (
        <Box width={[1]} style={{ backgroundColor: grey[200] }}>
          <Box
            width={[1]}
            bg={"white"}
            style={{ boxShadow: BOX_SHADOW_COLOR }}
            p={[3]}
          >
            <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <ListButton
                label={title ? translate(title) : ""}
                icon={<BackIcon />}
                basepath={newParamsUrl}
                record={finalInitialValues}
                style={{
                  textTransform: "capitalize",
                }}
              />
              {labelBack && (
                <Box>
                  <Title fontWeight={"bold"} fontSize={[2]} px={[3]}>
                    {translate(labelBack)}
                  </Title>
                </Box>
              )}
            </Flex>
          </Box>
          <Box
            width={[1]}
            style={{ height: 32, backgroundColor: "transparent" }}
          />
        </Box>
      ) : (
        <Box width={[1]} style={{ backgroundColor: grey[200] }}>
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"space-between"}
            p={[3]}
            bg={"white"}
            style={{ boxShadow: BOX_SHADOW_COLOR }}
          >
            <Box width={[1]}>
              <Title fontWeight={"bold"} fontSize={[2]}>
                {title ? translate(title) : ""}
              </Title>
            </Box>
            <ListButton basepath={newParamsUrl} record={finalInitialValues} />
          </Flex>
          <Box
            width={[1]}
            style={{ height: 32, backgroundColor: "transparent" }}
          />
        </Box>
      )}
    </Box>
  );
};

export const sanitizeRestProps = ({
  anyTouched,
  array,
  asyncBlurFields,
  asyncValidate,
  asyncValidating,
  autofill,
  blur,
  change,
  clearAsyncError,
  clearFields,
  clearSubmit,
  clearSubmitErrors,
  destroy,
  dirty,
  dirtyFields,
  dirtyFieldsSinceLastSubmit,
  dirtySinceLastSubmit,
  dispatch,
  form,
  handleSubmit,
  hasSubmitErrors,
  hasValidationErrors,
  initialize,
  initialized,
  initialValues,
  modifiedSinceLastSubmit,
  modifiedsincelastsubmit,
  pristine,
  pure,
  redirect,
  reset,
  resetSection,
  save,
  setRedirect,
  submit,
  submitError,
  submitErrors,
  submitAsSideEffect,
  submitFailed,
  submitSucceeded,
  submitting,
  touch,
  translate,
  triggerSubmit,
  undoable,
  untouch,
  valid,
  validate,
  validating,
  __versions,
  updateField,
  ...props
}) => props;
