import React from "react";
import { Flex, Box } from "rebass";
import { SearchBox4, MenuAtmos } from "./atmos";
import { getSetLogoStyle } from "./utils";
import Account4 from "./Account4";
import { get } from "lodash";
const Desktop4 = ({
  settings,
  cart,
  categories,
  values,
  websiteId,
  widthScreen,
}) => {
  // const { items } = cart;
  // const colorPrimary = "#CACACA";
  //   console.log(values);
  const styleLogo = getSetLogoStyle(
    get(settings, "header.containerLogo"),
    widthScreen
  );
  const indexView = widthScreen > 600 ? 1 : 0;
  const isShowMenu = get(
    settings,
    `header.isShowMenu.show[${indexView}]`,
    true
  );
  return (
    <Box width={[1]}>
      <Flex
        width={[1, 1]}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        p={[0]}
      >
        {isShowMenu && (
          <MenuAtmos
            isMobile={false}
            settings={settings}
            categories={categories}
            style={{ marginBottom: 0 }}
            isDarkBG={true}
            values={values}
            styleLogo={styleLogo}
          />
        )}
        <Flex
          style={{ position: "absolute", right: 16, height: "100%" }}
          flexDirection={"row"}
          alignItems={"center"}
        >
          <SearchBox4 width={"200px"} />
          <Account4 cart={cart} settings={settings} values={values} />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Desktop4;
