import React from "react";
import { Image } from "rebass";
import { connect } from "../../../core";
import { HrefContainer } from "../../../libs/hrefUrl";
const renderLink = (url, size, link) => {
  return url !== "" ? (
    <HrefContainer link={link}>
      <Image
        style={{ width: size, height: size }}
        src={url}
        borderRadius={"100%"}
      />
    </HrefContainer>
  ) : (
    <div />
  );
};
const renderNotLink = (url, size) => {
  return url !== "" ? (
    <Image
      style={{ width: size, height: size }}
      src={url}
      borderRadius={"100%"}
    />
  ) : (
    <div />
  );
};

const AvatarView = (props) => {
  const { id, layoutState } = props;
  const { containerPadding, url, size, align, link } = layoutState.getItemJS(
    id
  ).props.values;
  const child =
    link && link.value !== ""
      ? renderLink(url, size, link)
      : renderNotLink(url, size);

  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        display: "flex",
        padding: containerPadding,
        flex: 1,
        justifyContent: align,
      }}
    >
      {child}
    </div>
  );
};

export default connect("layoutState")(AvatarView);
