import { Flex, Text } from "rebass";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary[500],
  },
}));

const Container = styled(Flex)`
  border-top: 1px solid rgba(0, 0, 0, 0.06);
`;

const Image = styled.img`
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  padding: 8px;
  margin: 0 auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border: 2px solid;
  border-color: rgba(0, 0, 0, 0.1);
`;

const Price = styled(Text)`
  font-size: ${(props) => (props.striked ? "1em" : "1em")};
  display: ${(props) => (props.hide ? "none" : "block")};
  text-decoration: ${(props) => (props.striked ? "line-through" : "")};
  background-color: ${(props) => (props.striked ? "#FF9900" : "transparent")};
`;

export { useStyles, Price, Image, Container };
