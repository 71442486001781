import React from "react"
import PropTypes from "prop-types"
import { LayoutState, RootWrapper } from "../core"
import LayoutProvider from "./LayoutProvider"

const StaticLayout = (props) => (
  <LayoutProvider {...props} readOnly={true} onChange={() => {}}>
    <RootWrapper id="root" />
  </LayoutProvider>
)

StaticLayout.propTypes = {
  layoutState: PropTypes.instanceOf(LayoutState).isRequired,
  components: PropTypes.object.isRequired,
  addons: PropTypes.array,
  token: PropTypes.string,
  locale: PropTypes.string,
}

export default StaticLayout
