import React from "react";
import { get } from "lodash";
import { Flex, Box } from "rebass";
import styled from "styled-components";
const Image = styled.img`
  opacity: 1;
  display: block;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`;

const ItemText = (props) => {
  const { title, indexValue } = props;
  const textTitle = get(title, "text", "");
  const titleAlign = get(title, `textAlign[${indexValue}]`, "left");
  const colorTitle = get(title, "color", "#000000");
  const fs = get(title, `fontSize[${indexValue}]`);
  const fontSizeTitle = fs ? { fontSize: `${fs}pt` } : {};
  const cp = get(title, `containerPadding[${indexValue}]`, "0px");
  const ls = get(title, `letterSpacing[${indexValue}]`, "1px");
  const lh = get(title, `lineHeight[${indexValue}]`, "100%");
  return (
    <div
      style={{
        padding: cp,
        lineHeight: lh,
        letterSpacing: ls,
        width: "100%",
        textAlign: titleAlign,
        color: colorTitle,
        ...fontSizeTitle,
      }}
      dangerouslySetInnerHTML={{ __html: textTitle }}
    />
  );
};

export default (props) => {
  const { values, isDesktop } = props;
  const { title, subtitle, icon, layoutRow } = values;
  const indexValue = isDesktop ? 1 : 0;
  const layoutParams = {
    flexDirection: get(layoutRow, `flexDirection[${indexValue}]`, "row"),
    justifyContent: get(
      layoutRow,
      `justifyContent[${indexValue}]`,
      "flex-start"
    ),
    alignItems: get(layoutRow, `alignItems[${indexValue}]`, "flex-start"),
    flexWrap: get(layoutRow, `flexWrap[${indexValue}]`, "nowrap"),
  };

  const textTitle = get(title, "text", "");
  const textSubtitle = get(subtitle, "text", "");

  return (
    <Flex width={[1]} style={{ ...layoutParams }}>
      {icon !== "" && (
        <Image
          src={icon}
          alt={"image"}
          style={{
            width: "60px",
            height: "auto",
          }}
        />
      )}
      <Box width={[1]}>
        {textTitle !== "" && <ItemText title={title} indexValue={indexValue} />}
        {textSubtitle !== "" && (
          <ItemText title={subtitle} indexValue={indexValue} />
        )}
      </Box>
    </Flex>
  );
};
