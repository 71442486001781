import React, { useState, useRef, useEffect } from "react";
import { Flex, Box, Image } from "rebass";

import SearchBox from "./SearchBox";
import Menu3 from "./Menu3";
import Account from "./Account";

const Desktop = ({ settings, cart, categories }) => {
  // const { items } = cart
  // const colorPrimary = "#CACACA" //get(settings, `theme.themes.colors.primary`, '#6B5B37');
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);
  return (
    <Box width={[1]} bg={"secondary"}>
      <Flex
        width={[1]}
        sx={{
          height: "112px",
        }}
        className={`${isSticky ? " sticky" : ""}`}
        ref={ref}
      >
        <Box
          width={[1]}
          bg={"secondary"}
          className='sticky-inner'
          pb={["12px"]}
        >
          <Flex
            width={[1, 1]}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={[2]}
          >
            <Box style={{ zIndex: 2 }}>
              <Image
                sx={{ width: ["60px"], height: "auto" }}
                src={settings.header.image}
                alt='logo'
              />
            </Box>
            <Flex width={[1]} flexDirection={"column"} pt={[1]}>
              <SearchBox width={"60%"} />
              <Flex
                width={[1]}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                p={[2]}
                pb={[0]}
              >
                <Flex
                  width={[1, "70%", "62%"]}
                  alignItems={"flex-Start"}
                  flexDirection={"column"}
                >
                  {/* <Menu
                    isMobile={false}
                    settings={settings}
                    categories={categories}
                    style={{ marginBottom: "-18px" }}
                  /> */}
                  <Menu3
                    isMobile={false}
                    settings={settings}
                    categories={categories}
                    style={{ marginBottom: "-18px" }}
                    marginLeft={"-80px"}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Box style={{ zIndex: 2 }}>
              <Account cart={cart} settings={settings} />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Desktop;
