import React, { Fragment } from "react"
import { Box, Flex } from "rebass"
import { SelectInput, SwitchInput, Counter } from "../../components"
import { UploadItemArray } from "../../../../components"
import { typeImageAnimation, easeAnimation } from "./utils"

const BoxImageItem = (props) => {
  const {
    data,
    onToggleAnimationChecked,
    onUpdatedImageAnimation,
    list,
    indexParent,
    updateImage,
  } = props
  // console.log(data)

  return (
    <Box width={[1]}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        alignItems={"center"}
        className="item-list"
      >
        <UploadItemArray
          type={`CarouselAnimationView-url_image-${indexParent}`}
          imageUrl={list[indexParent].url_image}
          onUpdateUrl={(value) => updateImage(value, "url_image")}
          title="Desktop"
        />
        <UploadItemArray
          type={`CarouselAnimationView-url_image_mobile-${indexParent}`}
          imageUrl={list[indexParent].url_image_mobile}
          onUpdateUrl={(value) => updateImage(value, "url_image_mobile")}
          title="Mobile"
        />
      </Flex>
      <Flex
        width={[1]}
        flexDirection={"row"}
        alignItems={"center"}
        flexWrap={"wrap"}
        className="item-list"
      >
        <SwitchInput
          id={`carousel-item-use-animation=${indexParent}`}
          title={"Use Animation"}
          checked={data.image.isAnimation}
          onChange={(e) =>
            onToggleAnimationChecked(e, "isAnimation", indexParent)
          }
        />
        <SwitchInput
          id={`carousel-item-visible-use-animation=${indexParent}`}
          title={"Use Animation"}
          checked={data.image.visible}
          onChange={(e) => onToggleAnimationChecked(e, "visible", indexParent)}
        />
        {data.image.isAnimation && (
          <Fragment>
            <SelectInput
              id={`type_type_carousel_list-${indexParent}`}
              title={"Type"}
              value={data.image.animation.type}
              list={typeImageAnimation}
              style
              onChange={(event) =>
                onUpdatedImageAnimation("type", event.target.value, indexParent)
              }
            />
            <SelectInput
              id={`type_type_carousel_ease-${indexParent}`}
              title={"Ease"}
              value={data.image.animation.ease}
              list={easeAnimation}
              style
              onChange={(event) =>
                onUpdatedImageAnimation("ease", event.target.value, indexParent)
              }
            />
          </Fragment>
        )}
      </Flex>

      {data.image.isAnimation && (
        <Box width={[1]}>
          <Flex width={[1]} flexDirection={"row"} flexWrap={"wrap"} py={[1]}>
            <Counter
              isDisabled={false}
              title="Opacity"
              onSetValue={(value) =>
                onUpdatedImageAnimation("opacity", value, indexParent)
              }
              value={data.image.animation.opacity}
              interval={1}
              subtitle=""
              minValue={0}
            />
            <Counter
              isDisabled={false}
              title="Delay"
              onSetValue={(value) =>
                onUpdatedImageAnimation("delay", value, indexParent)
              }
              value={data.image.animation.delay}
              interval={1}
              subtitle=""
              minValue={0}
            />
            <Counter
              isDisabled={false}
              title="Duration"
              onSetValue={(value) =>
                onUpdatedImageAnimation("duration", value, indexParent)
              }
              value={data.image.animation.duration}
              interval={1}
              subtitle=""
              minValue={0}
            />
          </Flex>
        </Box>
      )}
    </Box>
  )
}

export { BoxImageItem }
