import React, { Fragment } from "react"

import { Flex, Text } from "rebass"
import { Field } from "react-final-form"
import { get } from "lodash"
import { ReferenceInput, SelectInput, DateInput } from "react-admin"
import { RaTextInput, RaNumberInput, RaBooleanInput, ArrayChipInput } from "../"
import { ROLE_USER } from "../../../utils"
const PromotionFields = (props) => {
  const { translate, formData, isProduct, permissions } = props
  const warehouse = get(formData, "warehouses", [])

  return (
    <Fragment>
      {ROLE_USER.super_admin === permissions && (
        <ReferenceInput
          source="website_id"
          reference="websites"
          filter={{ site_id: formData.site_id }}
          label={"resources.promotions.fields.website_id"}
        >
          <SelectInput optionText="name" style={{ width: 256 }} />
        </ReferenceInput>
      )}
      <RaBooleanInput
        source={"active"}
        label={"resources.promotions.fields.active"}
      />
      <RaTextInput
        source={"name"}
        label={"resources.promotions.fields.name"}
        style={{ width: 400 }}
      />
      <Flex
        width={[1]}
        flexDirection={"row"}
        alignItems={"flex-end"}
        pb={[2]}
        pt={[2]}
      >
        <Text style={{ width: 100 }}>
          {translate("resources.promotions.fields.warehouse")}
        </Text>
        <Field
          label={translate("resources.promotions.fields.warehouse")}
          name="warehouses"
          resource="warehouses"
          optionText="name"
          component={ArrayChipInput}
          dataOptions={warehouse}
          updateField={props.updateField}
        />
      </Flex>
      <DateInput
        source="start_date"
        label={"resources.promotions.fields.start_date"}
        style={{ width: 256 }}
      />
      <DateInput
        source="end_date"
        label={"resources.promotions.fields.end_date"}
        style={{ width: 256 }}
      />
      {!isProduct && (
        <RaBooleanInput
          source={"require_coupon_code"}
          label={"resources.promotions.fields.need_coupon"}
        />
      )}
      <RaNumberInput
        source={"priority"}
        notetext="resources.promotions.note_text.priority"
      />
    </Fragment>
  )
}

export default PromotionFields
