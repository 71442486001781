import { Layout9 } from "../../TemplateImageSonak";
export const template9A = {
  "5ioiezu3": {
    id: "5ioiezu3",
    type: "Row1",
    props: {
      values: {
        name: "Row1",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
          },
          bgProps: {
            show: true,
            content: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-OKC.jpeg",
              size: "cover",
              ratio: 3,
              repeat: "no-repeat",
              position: "center",
            },
          },
          padding: "0px",
          gradient: {
            show: false,
          },
          backgroundColor: "transparent",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
            contentAlign: "center",
          },
          bgProps: {
            show: true,
            content: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-LYN.jpeg",
              size: "cover",
              ratio: 2,
              layout: {
                align: "center",
                justify: "center",
              },
              repeat: "no-repeat",
              position: "center",
            },
          },
          padding: "0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255,255,255,0)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                bgProps: {
                  show: true,
                  content: {
                    url: "",
                    size: "cover",
                    ratio: 2,
                    layout: {
                      align: "center",
                      justify: "center",
                    },
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                bgProps: {
                  show: true,
                  content: {
                    url: "",
                    size: "cover",
                    ratio: 3,
                    layout: {
                      align: "center",
                      justify: "center",
                    },
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    layout: Layout9,
    childOf: "Row",
    children: ["WxDB8Ei5"],
    content: {
      "yiPXhD7/": {
        id: "yiPXhD7/",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>DEALS</strong></p>",
            type: "TextView",
            isLink: false,
            mobile: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 24,
              textAlign: "center",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 60,
              textAlign: "center",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px 200px 0px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "WxDB8Ei5",
          idx: 0,
        },
        childOf: "Content",
        children: [],
      },
      "0ZnWGK/o": {
        id: "0ZnWGK/o",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>THIS WEEK</strong></p>",
            type: "TextView",
            isLink: false,
            mobile: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 18,
              textAlign: "center",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px 0px 10px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 36,
              textAlign: "center",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px 200px 16px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "WxDB8Ei5",
          idx: 1,
        },
        childOf: "Content",
        children: [],
      },
      PE0bDcaN: {
        id: "PE0bDcaN",
        type: "MultiContentGroupView",
        props: {
          values: {
            list: [
              {
                id: "5b0gm",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  icon: "",
                  text: "PROMOTION AND DEALS",
                  border: {
                    size: 0,
                    type: "solid",
                    color: "#e61919",
                    radius: 24,
                  },
                  mobile: {
                    fontSize: 8,
                    fullWidth: false,
                    buttonAlign: "center",
                    buttonColors: {
                      color: "#FFF",
                      hoverColor: "#42d696",
                      rippleColor: "#FF9900",
                      backgroundColor: "#e61919",
                    },
                    buttonPadding: "8px 12px 8px 12px",
                    containerPadding: "0px 10px 0px 10px",
                  },
                  desktop: {
                    fontSize: 12,
                    fullWidth: false,
                    buttonAlign: "center",
                    buttonColors: {
                      color: "#FFF",
                      hoverColor: "#42d696",
                      rippleColor: "#FF9900",
                      backgroundColor: "#e61919",
                    },
                    buttonPadding: "8px 16px 8px 16px",
                    containerPadding: "0px 16px 0px 0px",
                  },
                  typeButton: "raised",
                  isShowMobile: true,
                  isShowDesktop: true,
                  labelPosition: "before",
                },
                typeContent: "button",
              },
              {
                id: "10hgl",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  icon: "",
                  text: "CLEARANCE",
                  border: {
                    size: 0,
                    type: "solid",
                    color: "#e61919",
                    radius: 24,
                  },
                  mobile: {
                    fontSize: 8,
                    fullWidth: false,
                    buttonAlign: "center",
                    buttonColors: {
                      color: "#FFF",
                      hoverColor: "#42d696",
                      rippleColor: "#FF9900",
                      backgroundColor: "rgba(20, 19, 19, 1)",
                    },
                    buttonPadding: "8px 12px 8px 12px",
                    containerPadding: "0px",
                  },
                  desktop: {
                    fontSize: 12,
                    fullWidth: false,
                    buttonAlign: "center",
                    buttonColors: {
                      color: "#FFF",
                      hoverColor: "#42d696",
                      rippleColor: "#FF9900",
                      backgroundColor: "rgba(20, 19, 19, 1)",
                    },
                    buttonPadding: "8px 16px 8px 16px",
                    containerPadding: "0px",
                  },
                  typeButton: "raised",
                  isShowMobile: true,
                  isShowDesktop: true,
                  labelPosition: "before",
                },
                typeContent: "button",
              },
            ],
            type: "MultiContentGroupView",
            mobile: {
              containerPadding: "0px",
            },
            parent: "Content",
            layoutRow: {
              alignItems: ["flex-start", "center"],
              flexDirection: ["row", "row"],
              justifyContent: ["center", "center"],
            },
            containerPadding: "0px 200px 0px 0px",
          },
        },
        parent: {
          id: "WxDB8Ei5",
          idx: 2,
        },
        childOf: "Content",
        children: [],
      },
    },
  },
};
