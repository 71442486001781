import React, { Component } from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import MenuItem from "@material-ui/core/MenuItem"
import compose from "recompose/compose"
import {
  addField,
  translate,
  FieldTitle,
  ResettableTextField,
} from "react-admin"
import { Flex } from "rebass"
import TextMessage from "./utils"
import { sanitizeRestProps } from "./defaultProps"

export class RaSelectInput extends Component {
  /*
   * Using state to bypass a redux-form comparison but which prevents re-rendering
   * @see https://github.com/erikras/redux-form/issues/2456
   */
  state = {
    value: this.props.input.value,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.input.value !== this.props.input.value) {
      this.setState({ value: nextProps.input.value })
    }
  }

  handleChange = eventOrValue => {
    const value = eventOrValue.target ? eventOrValue.target.value : eventOrValue
    this.props.input.onChange(value)

    // HACK: For some reason, redux-form does not consider this input touched without calling onBlur manually
    this.props.input.onBlur(value)
    this.setState({ value })
  }

  addAllowEmpty = choices => {
    if (this.props.allowEmpty) {
      return [<MenuItem value={this.props.emptyValue} key="null" />, ...choices]
    }

    return choices
  }
  renderMenuItemOption = choice => {
    const { optionText, translate, translateChoice } = this.props
    if (React.isValidElement(optionText))
      return React.cloneElement(optionText, {
        record: choice,
      })
    const choiceName =
      typeof optionText === "function"
        ? optionText(choice)
        : get(choice, optionText)
    return translateChoice
      ? translate(choiceName, { _: choiceName })
      : choiceName
  }

  renderMenuItem = choice => {
    const { optionValue, disableValue } = this.props
    return (
      <MenuItem
        key={get(choice, optionValue)}
        value={get(choice, optionValue)}
        disabled={get(choice, disableValue)}
      >
        {this.renderMenuItemOption(choice)}
      </MenuItem>
    )
  }

  render() {
    const {
      allowEmpty,
      choices,
      classes,
      className,
      input,
      isRequired,
      label,
      meta,
      options,
      resource,
      source,
      notetext,
      ...rest
    } = this.props
    if (typeof meta === "undefined") {
      throw new Error(
        "The RaSelectInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
      )
    }
    const { touched, error, helperText = false } = meta

    return (
      <Flex flexDirection={["column"]}>
        <ResettableTextField
          select
          margin="dense"
          value={this.state.value}
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          name={input.name}
          className={`${classes.input} ${className}`}
          clearAlwaysVisible
          error={!!(touched && error)}
          helperText={(touched && error) || helperText}
          {...options}
          {...sanitizeRestProps(rest)}
          onChange={this.handleChange}
        >
          {this.addAllowEmpty(choices.map(this.renderMenuItem))}
        </ResettableTextField>
        {notetext && <TextMessage notetext={notetext} />}
      </Flex>
    )
  }
}

RaSelectInput.propTypes = {
  allowEmpty: PropTypes.bool.isRequired,
  emptyValue: PropTypes.any,
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  optionValue: PropTypes.string.isRequired,
  disableValue: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
  translate: PropTypes.func.isRequired,
  translateChoice: PropTypes.bool,
  notetext: PropTypes.string,
}

RaSelectInput.defaultProps = {
  allowEmpty: false,
  emptyValue: "",
  classes: {},
  choices: [],
  options: {},
  optionText: "name",
  optionValue: "id",
  translateChoice: true,
  disableValue: "disabled",
}

export default compose(addField, translate)(RaSelectInput)
