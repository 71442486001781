import React from "react";
import { get } from "lodash";
import { Text } from "rebass";
import TextyAnim from "rc-texty";
import TweenOne from "rc-tween-one";

const defaultTitle = {
  italic: true,
  weight: "normal",
  lineHeight: "100%",
  letterSpacing: "0px",
  color: "#ed747f",
  size: "48pt",
  sizeMedium: "30pt",
  sizeSmall: "20pt",
  align: "center",
  family: "Rage Italic",
  textTransform: "normal",
  type: "right",
  mode: "smooth",
  delay: 200,
  duration: 1600,
  isAnimation: true,
  isEase: false,
  ease: "",
  value: "",
};

const defaultSubtitle = {
  italic: false,
  weight: "normal",
  lineHeight: "100%",
  letterSpacing: "0px",
  color: "#FFFFFF",
  size: "48pt",
  sizeMedium: "30pt",
  sizeSmall: "20pt",
  align: "left",
  family: "Montserrat",
  textTransform: "uppercase",
  type: "alpha",
  mode: "sync",
  delay: 100,
  duration: 800,
  isAnimation: true,
  isEase: true,
  ease: "easeOutQuart",
  value: "",
};

/**
 * 
       italic: false,
          weight: "normal",
          lineHeight: "100%",
          letterSpacing: "0px",
          color: "#FFFFFF",
          family: "Montserrat",
          textTransform: "uppercase",
          type: "alpha",
          mode: "sync",
          delay: 100,
          duration: 800,
          isAnimation: true,
          isEase: true,
          ease: "easeOutQuart",
          value: "",
          size: "48pt",
          sizeMedium: "30pt",
          sizeSmall: "20pt",
          align: "left",
        
 */
const TextAnimation = (props) => {
  const { data, widthContainer } = props;
  const isAnimation = false; // get(data, "isAnimation", false)
  const isEase = get(data, "isEase", false);
  const value = get(data, "value", "");
  const size = get(data, "size", "48pt");
  const sizeMedium = get(data, "sizeMedium", "30pt");
  const sizeSmall = get(data, "sizeSmall", "20pt");

  let defaultSize = size;
  let padding = get(data, "paddingDesktop", "0px");
  if (widthContainer <= 480) {
    defaultSize = sizeSmall;
    padding = get(data, "paddingMobile", "0px");
  } else if (widthContainer > 600 && widthContainer <= 1024) {
    defaultSize = sizeMedium;
  }
  const styleFont = {
    fontFamily: get(data, "family", "Rage Italic"),
    textAlign: get(data, "align", "center"),
    color: get(data, "color", "#ed747f"),
    textTransform: get(data, "textTransform", "normal"),
    fontStyle: get(data, "italic", false) ? "italic" : "normal",
    fontWeight: get(data, "weight", "normal"),
    lineHeight: get(data, "lineHeight", "100%"),
    letterSpacing: get(data, "letterSpacing", 0),
    fontSize: defaultSize,
    padding: padding,
  };

  if (!isAnimation) {
    return (
      <Text fontSize={[sizeSmall, sizeMedium, size]} style={styleFont}>
        {value}
      </Text>
    );
  }

  if (isEase) {
    return (
      <div style={styleFont}>
        <TextyAnim
          type={get(data, "type", "right")}
          mode={get(data, "mode", "smooth")}
          delay={get(data, "delay", 200)}
          duration={get(data, "duration", 1600)}
          component={TweenOne}
          componentProps={{
            animation: [
              {
                letterSpacing: 0,
                scale: 0,
                ease: get(data, "ease", "easeOutQuart"),
              },
              {
                letterSpacing: get(data, "letterSpacing", 0),
                scale: 1,
                width: "100%",
              },
            ],
          }}
        >
          {value}
          {/* <Text f={[sizeSmall, sizeMedium, size]} style={styleFont}>
          
        </Text> */}
        </TextyAnim>
      </div>
    );
  }
  return (
    <div style={styleFont}>
      <TextyAnim
        type={get(data, "type", "right")}
        mode={get(data, "mode", "smooth")}
        delay={get(data, "delay", 200)}
        duration={get(data, "duration", 1600)}
      >
        {value}
        {/* <Text f={[sizeSmall, sizeMedium, size]} style={styleFont}>
        
      </Text> */}
      </TextyAnim>
    </div>
  );
};

// const SubTitle = (props) => {
//   const isAnimation = get(data, "isAnimation", false)
//   const isEase = get(data, "isEase", false)
//   const value = get(data, "value", "")
//   const size = get(data, "size", "48pt")
//   const sizeMedium = get(data, "sizeMedium", "30pt")
//   const sizeSmall = get(data, "sizeSmall", "20pt")
//   const styleFont = {
//     fontFamily: get(data, "family", "Rage Italic"),
//     textAlign: get(data, "align", "center"),
//     color: get(data, "color", "#ed747f"),
//     textTransform: get(data, "textTransform", "normal"),
//     fontStyle: get(data, "italic", false) ? "italic" : "normal",
//     fontWeight: get(data, "weight", "normal"),
//     lineHeight: get(data, "lineHeight", "100%"),
//     letterSpacing: get(data, "letterSpacing", 0),
//   }
//   return (
//     <TextyAnim
//       type="alpha"
//       mode="sync"
//       className="title"
//       delay={100}
//       duration={800}
//       component={TweenOne}
//       componentProps={{
//         animation: [
//           {
//             letterSpacing: 0,
//             scale: 0,
//             ease: "easeOutQuart",
//           },
//           {
//             scale: 1,
//             width: "100%",
//           },
//         ],
//       }}
//     >
//       {data.title}
//     </TextyAnim>
//   )
// }

export { TextAnimation, defaultSubtitle, defaultTitle };
