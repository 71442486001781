export default {
  dashboard: {
    field: {
      total_member: "Total Customer",
      total_spend: "Total Points Spend",
      total_order: "Total Orders",
      total_earn: "Total Points Earned",
      daily_member: "Daily new member",
    },
  },
}
