export const dataHref = [
  { text: "Same Page", value: "same" },
  { text: "New Page", value: "_blank" },
];
export const defaultText = {
  parent: "Content",
  type: "TextView",
  isShowDesktop: true,
  isShowMobile: true,
  text:
    "<div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>",
  href: {
    url: "",
    target: "",
    isCollection: false,
    collection: 0,
  },
  isLink: false,
  mobile: {
    backgroundColor: "rgba(255,255,255,0)",
    color: "rgba(0,0,0,1)",
    hoverColor: "transparent",
    hoverBackgroundColor: "transparent",
    lineHeight: "120%",
    letterSpacing: "1px",
    fontSize: 10,
    containerPadding: "0px",
  },
  desktop: {
    backgroundColor: "rgba(255,255,255,0)",
    color: "rgba(0,0,0,1)",
    hoverColor: "transparent",
    hoverBackgroundColor: "transparent",
    lineHeight: "120%",
    letterSpacing: "1px",
    fontSize: 10,
    containerPadding: "0px",
  },
};
//[{ size: ["small", false, "large", "huge"] }],
//[{ font: [false, "serif", "monospace", "Ubuntu", "GreatVibes"] }],
export const toolbar = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ color: [] }, { background: [] }],
  ["blockquote", "code-block"],
  ["clean"],
];
