import React from "react"
import {
  required,
  BooleanInput,
  TextInput,
  FormDataConsumer,
  useTranslate,
  SelectInput,
} from "react-admin"
import { get } from "lodash"
import { Box } from "rebass"

import { AutoCompleteTimeZone } from "../../../components"
import { dataTypeStore } from "../../websites"
import AddressFields from "./AddressFields"
import CapabilitiesFields from "./CapabilitiesFields"

/**
 * 
 site_id:0,
    name:"",
    active:true/false,
    type:"Warehouse/Store/DC",
    timezone:"",
 */

const MainFields = (props) => {
  const { formData, updateField } = props
  // console.log(props)
  const translate = useTranslate()
  const onSetValue = (key, value) => {
    updateField(key, value)
  }
  return (
    <Box width={[1]}>
      <TextInput
        source="name"
        validate={required()}
        style={{ width: 300 }}
        label={"resources.sources.fields.name"}
      />
      <BooleanInput source="active" label={"resources.sources.fields.active"} />
      <Box>
        <SelectInput
          source="type"
          choices={dataTypeStore}
          label={"resources.sources.fields.type"}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: 300 }}
        />
      </Box>
      <AutoCompleteTimeZone
        name={"timezone-input"}
        value={get(formData, "timezone", "")}
        id="timezone-locale"
        onChange={(e, v) => onSetValue("timezone", v)}
        title={translate("resources.sources.fields.timezone")}
      />
      <Box width={[1]} py={[2]}>
        <CapabilitiesFields {...props} translate={translate} />

        <AddressFields {...props} translate={translate} />
      </Box>
    </Box>
  )
}
const FormFields = (props) => {
  const { hasList, hasCreate, ...rest } = props
  return (
    <FormDataConsumer>
      {({ formData }) => {
        return <MainFields {...rest} formData={formData} />
      }}
    </FormDataConsumer>
  )
}

export default FormFields
