/* eslint-disable no-use-before-define */
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// import Avatar from "@material-ui/core/Avatar";
import get from "lodash/get";
import has from "lodash/has";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export const getPhoto = function (photos) {
  var src = "";

  if (has(photos, "photo_set")) {
    var photoSet = get(photos, "photo_set");
    var key = Object.keys(photoSet);
    var path = `photo_set.${key[0]}.photos[0]`;
    src = get(photos, path, "");
  } else if (has(photos, "*")) {
    src = get(photos, "*.photos[0]", "");
  }
  return src;
};

export default function ChipInputProducts(props) {
  const classes = useStyles();
  const { title, id, placeholder, dataSource, onChange, value } = props;
  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id={id}
        value={value}
        variant={"default"}
        options={dataSource}
        getOptionLabel={(option) => option.text}
        filterSelectedOptions
        onChange={onChange}
        renderOption={(option) => {
          // const myPhoto = getPhoto(get(option, "photos"));

          return (
            <React.Fragment>
              {/* <span style={{ width: 24, position: "absolute" }}>
                <Avatar src={myPhoto} className={classes.small} />
              </span> */}
              <span style={{ fontWeight: "normal" }}>
                {get(option, "name", "")}{" "}
                {get(option, "slug", "") !== ""
                  ? [get(option, "slug", "")]
                  : ""}
              </span>
            </React.Fragment>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            label={title}
            placeholder={placeholder}
            fullWidth
          />
        )}
      />
    </div>
  );
}
