import React, { Component } from "react";
import PropTypes from "prop-types";
import { Flex, Text } from "rebass";
import { IconButton } from "@material-ui/core";
import { lightBlue } from "@material-ui/core/colors";
import AddIcon from "@material-ui/icons/AddCircle";
import { filter } from "lodash";
// import { Title } from "../components"
import { FooterAction, ContainerPanel } from "../actions";
import { BoxContent } from "../styled";
import {
  defaultItemList,
  PanelGeneral,
  DialogItemCarousel,
} from "../components/carouselanimation";

const usedKeys = {};
const MULTIPLIER = Math.pow(2, 24);

function generateRandomKey() {
  let key;
  while (
    key === undefined ||
    usedKeys.hasOwnProperty(key) ||
    !isNaN(Number(key))
  ) {
    key = Math.floor(Math.random() * MULTIPLIER).toString(32);
  }
  return key;
}

class CarouselAnimationView extends Component {
  constructor(props) {
    super(props);
    const { container, list } = props.data.values;
    let expandListArray = [list.length];
    for (let index = 0; index < expandListArray.length; index++) {
      expandListArray[index] = false;
    }

    this.state = {
      container: container,
      list: list,
      expandListArray: expandListArray,
      heightContent: props.heightContent,
      widthContent: props.widthContent,
    };
  }
  onAddItemList = () => {
    let newList = [
      ...this.state.list,
      { ...defaultItemList, id: generateRandomKey() },
    ];
    let newExpandList = [...this.state.expandListArray, false];
    this.setState({ list: newList, expandListArray: newExpandList });
  };

  updateImage = (key, value, index) => {
    const itemList = this.state.list;
    if (key === "url_image") {
      itemList[index].url_image = value;
    } else if (key === "url_image_mobile") {
      itemList[index].url_image_mobile = value;
    } else if (key === "url_bg") {
      itemList[index].url_bg = value;
    } else if (key === "url_bg_mobile") {
      itemList[index].url_bg_mobile = value;
    }

    this.setState({ list: itemList });
  };

  onSetValue = (key, value) => {
    this.setState({ ...this.state, [key]: value });
  };

  onUpdateContainer = (key, value) => {
    const container = this.state.container;
    if (key === "height") {
      container[key] = `${value}px`;
    } else if (key === "heightMobile") {
      container[key] = `${value}px`;
    } else if (key === "paddingTopQuery0") {
      const pdtq = container.paddingTopQuery;
      pdtq[0] = `${value}px`;
      container["paddingTopQuery"] = pdtq;
    } else if (key === "paddingTopQuery1") {
      const pdtq = container.paddingTopQuery;
      pdtq[1] = `${value}px`;
      container["paddingTopQuery"] = pdtq;
    } else {
      container[key] = value;
    }

    this.setState({ container: container });
  };

  onClickCopy = () => {
    let newData = this.props.data;
    newData.values.container = this.state.container;
    newData.values.list = this.state.list;
    localStorage.setItem(
      "CarouselAnimationView",
      JSON.stringify(newData.values)
    );
  };
  onClickPaste = () => {
    const carousel = JSON.parse(localStorage.getItem("CarouselAnimationView"));
    if (carousel && carousel !== null) {
      const { container, list } = carousel;
      this.setState({
        container: container,
        list: list,
      });
    }
  };
  onClickSave = () => {
    let newData = this.props.data;
    newData.values.container = this.state.container;
    newData.values.list = this.state.list;
    this.props.onUpdateData(newData);
  };

  // onChangeText = (event, index) => {
  //   const itemList = this.state.list
  //   itemList[index].name = event.target.value
  //   this.setState({ list: itemList })
  // }
  onSetItemValue = (key, value, index) => {
    const itemList = this.state.list;
    const item = itemList[index];
    item[key] = value;
    // itemList[index] = item

    this.setState({ list: itemList });
  };

  onUpdateBg = (key, value, index) => {
    const itemList = this.state.list;
    const item = itemList[index];
    // item.bg[key] = value
    itemList[index] = {
      ...item,
      bg: { ...item.bg, [key]: value },
    };
    this.setState({ list: itemList });
  };

  onUpdatedImageAnimation = (key, value, index) => {
    const itemList = this.state.list;
    const item = itemList[index];
    // item.image.animation[key] = value
    itemList[index] = {
      ...item,
      image: {
        ...item.image,
        animation: { ...item.image.animation, [key]: value },
      },
    };
    // itemList[index] = item
    // console.log(key, value)
    this.setState({ list: itemList });
  };

  onToggleAnimationChecked = (event, key, index) => {
    const itemList = this.state.list;
    const item = itemList[index];
    // item.image[key] = event.target.checked
    // itemList[index] = item
    itemList[index] = {
      ...item,
      image: { ...item.image, [key]: event.target.checked },
    };
    this.setState({ list: itemList });
  };
  onPositionValue = (key, value, index) => {
    const itemList = this.state.list;
    const item = itemList[index];
    itemList[index] = {
      ...item,
      position: { ...item.position, [key]: `${value}px` },
    };
    this.setState({ list: itemList });
  };
  onPositionMPValue = (key, value, index) => {
    const itemList = this.state.list;
    const item = itemList[index];
    itemList[index] = {
      ...item,
      position: { ...item.position, [key]: value },
    };
    this.setState({ list: itemList });
  };

  onTitle = (key, value, index) => {
    const itemList = this.state.list;
    const item = itemList[index];

    if (item && item.title) {
      itemList[index] = {
        ...item,
        title: { ...item.title, [key]: value },
      };
    }

    // console.log(item)
    this.setState({ list: itemList });
  };
  onSubTitle = (key, value, index) => {
    const itemList = this.state.list;
    const item = itemList[index];
    if (item && item.subtitle) {
      itemList[index] = {
        ...item,
        subtitle: { ...item.subtitle, [key]: value },
      };
    }

    this.setState({ list: itemList });
  };

  onDeleteItem = (index) => {
    const itemList = this.state.list;
    itemList[index] = undefined;
    const updated = filter(itemList, function (val) {
      return val !== undefined;
    });
    // console.log("done ", updated);
    this.setState({ list: updated });
  };

  render() {
    return (
      <Flex width={[1]} flexDirection={"column"}>
        <BoxContent style={{ height: this.state.heightContent - 120 }}>
          <ContainerPanel>
            <PanelGeneral
              state={this.state}
              onUpdateContainer={this.onUpdateContainer}
            />
            <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              style={{ backgroundColor: lightBlue[600] }}
            >
              <Text pl={[2]} color={"white"}>
                Add List
              </Text>
              <IconButton
                onClick={this.onAddItemList}
                style={{ color: "#FFFFFF" }}
              >
                <AddIcon color={"inherit"} />
              </IconButton>
            </Flex>
            {this.state.list.map((item, index) => {
              return (
                <DialogItemCarousel
                  key={index}
                  data={item}
                  websiteId={this.props.websiteId}
                  list={this.state.list}
                  indexParent={index}
                  updateImage={(value, key) =>
                    this.updateImage(key, value, index)
                  }
                  onUpdateBg={(key, value, indexParent) =>
                    this.onUpdateBg(key, value, indexParent)
                  }
                  onToggleAnimationChecked={(e, key, indexParent) =>
                    this.onToggleAnimationChecked(e, key, indexParent)
                  }
                  onUpdatedImageAnimation={(key, value, indexParent) =>
                    this.onUpdatedImageAnimation(key, value, indexParent)
                  }
                  onPositionValue={(key, value, indexParent) =>
                    this.onPositionValue(key, value, indexParent)
                  }
                  onPositionMPValue={(key, value, indexParent) =>
                    this.onPositionMPValue(key, value, indexParent)
                  }
                  onTitle={(key, value) => this.onTitle(key, value, index)}
                  onSubTitle={(key, value) =>
                    this.onSubTitle(key, value, index)
                  }
                  onSetItemValue={(key, value, indexParent) =>
                    this.onSetItemValue(key, value, indexParent)
                  }
                  onDelete={() => this.onDeleteItem(index)}
                />
                // <PanelCard
                //   title={`${item.name} #${index + 1}`}
                //   id={`list-item-carousel-${index}`}
                //   key={index}
                // >
                //   {/* <Title title={item.name} /> */}
                //   <ItemCarouselAnimation
                //     data={item}
                //     list={this.state.list}
                //     indexParent={index}
                //     updateImage={(value, key) =>
                //       this.updateImage(key, value, index)
                //     }
                //     onUpdateBg={(key, value, indexParent) =>
                //       this.onUpdateBg(key, value, indexParent)
                //     }
                //     onToggleAnimationChecked={(e, key, indexParent) =>
                //       this.onToggleAnimationChecked(e, key, indexParent)
                //     }
                //     onUpdatedImageAnimation={(key, value, indexParent) =>
                //       this.onUpdatedImageAnimation(key, value, indexParent)
                //     }
                //     onPositionValue={(key, value, indexParent) =>
                //       this.onPositionValue(key, value, indexParent)
                //     }
                //     onTitle={(key, value) => this.onTitle(key, value, index)}
                //     onSubTitle={(key, value) =>
                //       this.onSubTitle(key, value, index)
                //     }
                //     onSetItemValue={(key, value, indexParent) =>
                //       this.onSetItemValue(key, value, indexParent)
                //     }
                //   />
                // </PanelCard>
              );
            })}
          </ContainerPanel>
        </BoxContent>
        <FooterAction
          onClickSave={this.onClickSave}
          onClickCopy={this.onClickCopy}
          onClickPaste={this.onClickPaste}
          widthContent={this.state.widthContent}
        />
      </Flex>
    );
  }
}

CarouselAnimationView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

CarouselAnimationView.defaultProps = {
  data: {},
};

export default CarouselAnimationView;
