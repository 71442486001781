import React from "react";
import { AutocompleteInput } from "react-admin";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { themeMui } from "../../themes";
const themeRaAuto = createMuiTheme({
  ...themeMui,
  overrides: {
    MuiFormControl: {
      marginDense: {
        minWidth: 300,
        marginTop: 16,
        paddingLeft: 0,
      },
    },
    MuiInput: {
      root: {
        minWidth: 180,
      },
    },
    MuiInputLabel: {
      root: {
        minWidth: 180,
      },
      filled: {
        minWidth: 180,
        "&$shrink": {
          fontSize: 16,
          "&$marginDense": {
            transform: "translate(0px, 6px) scale(0.75)",
          },
        },
        "&$marginDense": {
          fontSize: 16,
          transform: "translate(0px, 17px) scale(1)",
        },
      },
    },
    MuiFilledInput: {
      root: {
        paddingLeft: 0,
        backgroundColor: "transparent",
        "&$focused": {
          backgroundColor: "transparent",
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
        inputMarginDense: {
          paddingLeft: 0,
        },
      },
      input: {
        backgroundColor: "transparent",
        paddingLeft: 0,
      },
    },
  },
});
export const RaAutoCompleteInput = (props) => {
  return (
    <ThemeProvider theme={themeRaAuto}>
      <AutocompleteInput {...props} />
    </ThemeProvider>
  );
};
