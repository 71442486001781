import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout15 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const CarouselAnimationView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='CarouselAnimationView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

CarouselAnimationView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};
//let padding = top + "px " + right + "px " + bottom + "px " + left + "px"
CarouselAnimationView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "CarouselAnimationView",
    container: {
      backgroundColor: "#e6e6e6",
      auto: false,
      arrow: false,
      fullHeight: false,
      type: "across",
      height: "570px",
      heightMobile: "480px",
      paddingTopQuery: ["0px", "0px"],
      vertical: "center",
      horizontal: "center",
      autoPlaySpeed: 5000,
    },
    list: [
      {
        bg: {
          imageUrl: {
            url: "",
            mobile: {
              width: 0,
              height: 0,
            },
            desktop: {
              width: 0,
              height: 0,
            },
            urlMobile: "",
          },
          isBgImage: true,
          backgroundColor: "#e6e6e6",
        },
        name: "QueueAnim",
        image: {
          visible: false,
          animation: {
            ease: "easeInQuart",
            type: "from",
            delay: 40,
            opacity: 0,
            duration: 1500,
          },
          isAnimation: false,
        },
        containerTitle: {
          parent: {
            alignItems: ["center", "center"],
            direction: ["column", "row"],
            justifyContent: ["center", "flex-end"],
          },
          title: {
            width: ["100%", "80%"],
            justifyContent: ["center", "center"],
            direction: ["column", "column"],
            alignItems: ["center", "flex-start"],
          },
        },
        title: {
          ease: "",
          mode: "smooth",
          size: "40pt",
          type: "left",
          align: "left",
          color: "rgba(255, 255, 255, 1)",
          delay: 200,
          value: "Fall/Winter Collection",
          family: "Rage Italic",
          isEase: false,
          italic: true,
          weight: "bold",
          visible: true,
          duration: 1600,
          sizeSmall: "16pt",
          lineHeight: "100%",
          sizeMedium: "30pt",
          isAnimation: true,
          letterSpacing: "0px",
          paddingMobile: "0px 0px 10px 0px",
          textTransform: "normal",
          paddingDesktop: "0px",
        },
        url_bg:
          "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/CarouselAnimationView-url_bg-0-image-desktop-YEM.jpeg",
        imageUrl: {
          url: "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/CarouselAnimationView-url_image-0-image-desktop-BOY.png",
          mobile: {
            width: 0,
            height: 0,
          },
          desktop: {
            width: 0,
            height: 0,
          },
          urlMobile: "",
        },
        position: {
          boxImage: "500px",
          marginLeftText: "-100px",
          marginLeftImage: "50px",
          marginImageMobile: "0px",
          marginTitleMobile: "0px",
          marginImageDesktop: "0px 0px 0px 0px",
          marginTitleDesktop: "0px 0px 0px 0px",
          paddingImageMobile: "0px",
          paddingTitleMobile: "0px",
          paddingImageDesktop: "0px",
          paddingTitleDesktop: "0px",
        },
        subtitle: {
          ease: "easeOutQuart",
          mode: "sync",
          size: "36pt",
          type: "alpha",
          align: "center",
          color: "rgba(255, 255, 255, 1)",
          delay: 100,
          value: "Shop Now",
          family: "Montserrat",
          isEase: true,
          italic: false,
          weight: "bold",
          visible: true,
          duration: 800,
          sizeSmall: "14pt",
          lineHeight: "100%",
          sizeMedium: "24pt",
          isAnimation: true,
          letterSpacing: "0px",
          paddingMobile: "4px 0px 0px 0px",
          textTransform: "uppercase",
          paddingDesktop: "0px",
        },
        link: {
          target: "",
          type: "URL",
          value: "",
          isLink: false,
        },
        url_image: "",
        url_bg_mobile:
          "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/CarouselAnimationView-url_bg_mobile-0-image-desktop-LOT.jpeg",
        position_image: "left",
        url_image_mobile: "",
      },
    ],
  },
  layout: Layout15,
};

export default CarouselAnimationView;
