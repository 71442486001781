import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Box } from "rebass"
import Media from "react-media"

const Image = styled.img`
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`

class ResponsiveImage extends Component {
  render() {
    let srcMobile =
      this.props.srcMobile ||
      this.props.srcDesktop ||
      this.props.src ||
      "https://s3-ap-southeast-1.amazonaws.com/ola2-cms-staging/assets/1/content/ImageView-image-mobile-cLa.png"
    let srcDesktop =
      this.props.srcDesktop ||
      this.props.srcMobile ||
      this.props.src ||
      "https://s3-ap-southeast-1.amazonaws.com/ola2-cms-staging/assets/1/content/ImageView-image-mobile-cLa.png"
    return (
      <Box width={[1]}>
        <Media query="(max-width: 40em)">
          {matches =>
            matches ? (
              <Image src={srcMobile} />
            ) : (
              <Image src={srcDesktop} style={{ width: this.props.width }} />
            )
          }
        </Media>
      </Box>
    )
  }
}

ResponsiveImage.propTypes = {
  src: PropTypes.string,
  srcMobile: PropTypes.string,
  srcDesktop: PropTypes.string,
  width: PropTypes.number,
}
ResponsiveImage.defaultProps = {
  width: 250,
}
export default ResponsiveImage
