import React from "react";
import { Flex } from "rebass";
// import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
// import { PaperCard } from "../../../../components";
// import PackagingFields from "../shipment_item/PackagingFields";
// import SourceField from "./SourceField";

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 200,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));
// import ProviderFields from "../shipment_item/ProviderFields";
const ReturnItemFields = (props) => {
  const { translate, onRequestShipment, loading } = props;
  // website,state, setState,
  // const classes = useStyles();
  // const sourceValue = (e) => {
  //   setState({
  //     ...state,
  //     source_id: e.target.value,
  //   });
  // };
  return (
    <Flex width={[1]} flexDirection={"column"} py={[3]} px={[2]}>
      {/* <PaperCard
        title={translate("resources.orders.labels.request_return_shipment")}
        showButton={false}
        elevation={3}
        bgHeader={"#eeeeee"}
        propsHeader={{ px: [3], py: [2] }}
      >
        {website && website.id && <PackagingFields {...props} />}
        
        <Flex pl={[3]}>
          <SourceField {...props} classes={classes} onChange={sourceValue} />
        </Flex>
        <Flex width={[1]} flexDirection={"column"} px={[3]} py={[2]}>
          <Flex
            width={[1]}
            flexDirection='row'
            pb={[3]}
            sx={{ "&>button": { mr: [2] } }}
          >
            
           
          </Flex>
        </Flex>
      </PaperCard> */}
      <Button
        variant={"contained"}
        disabled={loading}
        style={{ borderRadius: 24, width: 100 }}
        color={"primary"}
        onClick={onRequestShipment}
      >
        {translate("resources.buttons.save")}
      </Button>
    </Flex>
  );
};

/* <ProviderFields {...props} /> */

/* <Button
              variant={"contained"}
              style={{ borderRadius: 24 }}
              color={"primary"}
            >
              {translate("resources.buttons.get_cost")}
            </Button> */

export default ReturnItemFields;
