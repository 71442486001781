export default {
  earningRules: {
    name: "Earning Rule",
    fields: {
      name: "Earning Rule Name",
      description: "Description",
      type: "Type",
      general_spending_rule: {
        point_value: "Per Dollar Point Value",
        excluded_skus: "Product SKUs",
        excluded_categories: "Product Categories",
        tags_inclusion_type: "Tags inclusion type",
        tags: "Tags",
        exclude_delivery_cost: "Exclude delivery cost",
        min_order_value: "Minimum Spending",
        tags_rules: "Tags Rules",
      },
      multiply_earned_points: {
        skus: "SKUs",
        categories: "Categories",
        multiplier: "Multiplier",
        min_order_value: "Minimum Spending",
      },
      member_referral: {
        event_name: "Event",
        reward: "Reward",
        points: "Points",
      },
      member_register: {
        points: "Points",
      },
      all_time_active: "Has Effective Date",
      start_at: "Start At",
      end_at: "End At",
      levels: "Restricted to levels",
      created_at: "Create On",
    },
    title: {
      advanced_options: "Advanced Options",
      subtitle: "To be exluded in the earning rules",
      effective_dates: "Effective Dates",
    },
    multi_level_option: {
      title: "Multi-Level Options",
      number_of_levels: "Number of Levels",
      level: "Level",
      percent: "%",
    },
    notetext: {
      point_value:
        "How much each dollar will earn 1 point. For example, 1 point value means $1 will earn 1 point. 0.1 point value means $1 will earn 0.1 point.",
      min_spending:
        "Minimum transaction amount to apply  this rule. Put 0 if not minimum amount is required.",
      product_sku: "Input the SKU and press <Enter> or <Return> key",
      product_categories:
        "Input the category and press <Enter> or <Return> key",
      levels: "Restricted to members in certain level",
    },
  },
}
