import React, { cloneElement } from "react";
import {
  useEditController,
  useTranslate,
  useNotify,
  useRedirect,
} from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Flex } from "rebass";
import { FormFields } from "./components";
import { BetterCustomForm, FooterToolbar } from "../../components";
import { useStylesEdit } from "./styles";

const defaultSubscription = {
  submitting: true,
  pristine: false,
  valid: true,
  invalid: true,
};

const MyEdit = (props) => {
  const { basePath, record, redirect, resource, save, saving, version } =
    useEditController(props);
  return (
    <div>
      {cloneElement(props.children, {
        basePath,
        record,
        redirect,
        resource,
        save,
        saving,
        version,
      })}
    </div>
  );
};

const StoreEdit = (props) => {
  const { onCancel } = props;
  const classes = useStylesEdit();
  // const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const controllerProps = useEditController(props);
  const translate = useTranslate();

  const onSuccess = () => {
    notify("resources.categories.messages.element_updated");
    redirect("list", "/stores");
  };
  if (!controllerProps.record) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Flex
        style={{ backgroundColor: "#FFFFFF", position: "fixed", zIndex: 20 }}
        width={[1]}
        flexDirection={"column"}
      >
        <Flex
          width={[1, "600px"]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={[2]}
        >
          <Typography variant='h6'>
            {translate("resources.stores.edit_title")}
          </Typography>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Flex>
      </Flex>
      <MyEdit {...props} undoable={false} onSuccess={onSuccess}>
        <BetterCustomForm
          className={classes.form}
          {...props}
          toolbar={<FooterToolbar />}
          redirect='list'
          resource='stores'
          defaultSubscription={defaultSubscription}
        >
          <FormFields {...props} isCreate={false} />
        </BetterCustomForm>
      </MyEdit>
    </div>
  );
};

export default StoreEdit;
