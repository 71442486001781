import React from "react";
import { Flex } from "rebass";
import { ContentDetail } from "../actions";
import { get } from "lodash";
import {
  BlockCounter,
  Counter,
  DialogIconFATemplate,
  SelectInput,
} from "../components";
export const datalabelPosition = [
  { value: "before", text: "Before" },
  { value: "after", text: "After" },
];

export default (props) => {
  const { onSetValue, state, contentId } = props;
  const onSetValueButton = (k, v) => {
    const params = {
      ...state,
      [k]: v,
    };
    onSetValue(params);
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <ContentDetail>
        <SelectInput
          id={`icon-position${contentId}`}
          title={"Icon Position"}
          value={state.iconPosition}
          list={datalabelPosition}
          onChange={(e) => onSetValueButton("iconPosition", e.target.value)}
        />
        <Counter
          isDisabled={false}
          title='Button Font Size'
          onSetValue={(value) => onSetValueButton("fontSize", value)}
          value={state.fontSize}
          interval={1}
          subtitle='pt'
          minValue={0}
        />
        <Counter
          isDisabled={false}
          title='Button Border Radiuse'
          onSetValue={(value) => onSetValueButton("borderRadius", value)}
          value={state.borderRadius}
          interval={1}
          subtitle='px'
          minValue={0}
        />
        <DialogIconFATemplate
          iconItem={state.icon}
          onItemSelected={(icon) => {
            onSetValueButton("icon", icon);
          }}
        />
      </ContentDetail>
      <BlockCounter
        containerPadding={get(state, "padding", "0px")}
        onUpdatePadding={(containerPadding) =>
          onSetValueButton("padding", containerPadding)
        }
        title={"Button Padding"}
      />
    </Flex>
  );
};
