import React, { Component } from "react";
import PropTypes from "prop-types";
import { SwitchInput, Title } from "../";
import { SketchColorPicker } from "../../../../components";
import { ContentDetail } from "../../actions";
import { BackgroundImage } from "./dataList";

class BackgroundContent extends Component {
  constructor(props) {
    super(props);
    const {
      backgroundColor,
      backgroundImage,
      isBackgroundImage,
      bgProps,
      padding,
    } = props;
    this.state = {
      backgroundColor: backgroundColor,
      backgroundImage: backgroundImage,
      isBackgroundImage: isBackgroundImage,
      bgProps: bgProps,
      padding: padding,
    };
  }
  onSetValue = (key, value) => {
    this.setState({ [key]: value });
    const {
      backgroundColor,
      isBackgroundImage,
      bgProps,
      padding,
      backgroundImage,
    } = this.state;
    const values = {
      backgroundColor: backgroundColor,
      backgroundImage: backgroundImage,
      isBackgroundImage: isBackgroundImage,
      bgProps: bgProps,
      padding: padding,
    };
    this.props.updateBackground(values);
  };

  onToggle = (e) => {
    this.setState({ isBackgroundImage: e.target.checked });
    const { backgroundColor, backgroundImage, bgProps, padding } = this.state;
    const values = {
      backgroundColor: backgroundColor,
      backgroundImage: backgroundImage,
      isBackgroundImage: e.target.checked,
      bgProps: bgProps,
      padding: padding,
    };
    this.props.updateBackground(values);
  };

  onChangeBackgroundImageProperties = (event, value, key) => {
    let {
      backgroundColor,
      backgroundImage,
      isBackgroundImage,
      bgProps,
      padding,
    } = this.state;
    bgProps[key] = event.target.value;
    this.setState({
      bgProps: bgProps,
    });
    const values = {
      backgroundColor: backgroundColor,
      backgroundImage: backgroundImage,
      isBackgroundImage: isBackgroundImage,
      bgProps: bgProps,
      padding: padding,
    };
    this.props.updateBackground(values);
  };
  onUpdateColor = (color) => {
    let { backgroundImage, isBackgroundImage, bgProps, padding } = this.state;
    this.setState({ backgroundColor: color });
    const values = {
      backgroundColor: color,
      backgroundImage: backgroundImage,
      isBackgroundImage: isBackgroundImage,
      bgProps: bgProps,
      padding: padding,
    };
    this.props.updateBackground(values);
  };
  onUpdatePadding = (padding) => {
    const {
      backgroundColor,
      backgroundImage,
      isBackgroundImage,
      bgProps,
    } = this.state;
    this.setState({ padding: padding });
    const values = {
      backgroundColor: backgroundColor,
      backgroundImage: backgroundImage,
      isBackgroundImage: isBackgroundImage,
      bgProps: bgProps,
      padding: padding,
    };
    this.props.updateBackground(values);
  };
  render() {
    return (
      <ContentDetail>
        <Title title={this.props.title} />
        <SketchColorPicker
          title={"Background Color"}
          onUpdateColor={(color) => this.onSetValue("backgroundColor", color)}
          color={this.state.backgroundColor}
        />
        <SwitchInput
          id={`bg-content-image`}
          title={"Using background image"}
          checked={this.state.isBackgroundImage}
          onChange={(e) => this.onToggle(e)}
        />
        {this.props.showbgImage && this.state.isBackgroundImage && (
          <BackgroundImage
            contentId={"bg-content"}
            state={this.state}
            onChangeBackgroundImageProperties={
              this.onChangeBackgroundImageProperties
            }
            onSetValue={this.onSetValue}
          />
        )}
      </ContentDetail>
    );
  }
}

BackgroundContent.propTypes = {
  updateBackground: PropTypes.func,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.object,
  bgProps: PropTypes.object,
  isBackgroundImage: PropTypes.bool,
  title: PropTypes.string,
  showbgImage: PropTypes.bool,
};

BackgroundContent.defaultProps = {
  title: "Background Row",
  backgroundImage: { url: "", urlMobile: "" },
  backgroundColor: "#FFFFFF",
  isBackgroundImage: false,
  showbgImage: true,
  bgProps: {
    size: "cover",
    position: "center",
    repeat: "no-repeat",
    ratio: 1.2,
  },
  padding: "0px",
};

export default BackgroundContent;
