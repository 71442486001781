import React, { Component } from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import {
  Counter,
  BlockCounter,
  TextGroup,
  Alignment,
  Title,
  SettingSlideTextGroup,
} from "../components";
import { SketchColorPicker } from "../../../components";
import {
  FooterAction,
  ContainerPanel,
  ContentDetail,
  PanelCard,
} from "../actions";
import { BoxContent } from "../styled";

const defaultHref = {
  url: "",
  target: "",
  isCollection: false,
  collection: 0,
};

//backgroundColor
class TextGroupView extends Component {
  constructor(props) {
    super(props);
    const {
      align,
      valueText,
      backgroundColor,
      containerPadding,
      lineHeight,
      letterSpacing,
      isLink,
      href,
      setting,
      fontSize,
      color,
    } = props.data.values;
    const lH = lineHeight.split("%").join("") * 1;
    const lS = letterSpacing.split("px").join("") * 1;
    const textLink = href ? href : defaultHref;
    this.state = {
      heightContent: props.heightContent,
      widthContent: props.widthContent,
      isEditMode: false,
      value: valueText,
      backgroundColor: backgroundColor,
      oldValue: props.data,
      containerPadding: containerPadding,
      lineHeight: lH,
      letterSpacing: lS,
      isLink: isLink || false,
      href: textLink,
      collections: [],
      align: align,
      setting: setting,
      fontSize: fontSize,
      color: color || "#000000",
    };
  }

  onClickSave = () => {
    let newData = this.props.data;
    newData.values.valueText = this.state.value;
    newData.values.backgroundColor = this.state.backgroundColor;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values.lineHeight = this.state.lineHeight + "%";
    newData.values.letterSpacing = this.state.letterSpacing + "px";
    // newData.values.isLink = this.state.isLink;
    // newData.values.href = this.state.href;
    newData.values.align = this.state.align;
    newData.values.setting = this.state.setting;
    newData.values.fontSize = this.state.fontSize;
    newData.values.color = this.state.color;

    this.props.onUpdateData(newData);
  };

  onSetValue = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value,
    });
  };
  onUpdateFontSize = (value, type) => {
    const fs = this.state.fontSize;
    fs[type] = value;
    this.setState({ fontSize: fs });
  };

  onClickCopy = () => {
    let newData = this.props.data;
    newData.values.valueText = this.state.value;
    newData.values.backgroundColor = this.state.backgroundColor;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values.lineHeight = this.state.lineHeight + "%";
    newData.values.letterSpacing = this.state.letterSpacing + "px";
    // newData.values.isLink = this.state.isLink;
    // newData.values.href = this.state.href;
    newData.values.align = this.state.align;
    newData.values.setting = this.state.setting;
    newData.values.fontSize = this.state.fontSize;
    newData.values.color = this.state.color;
    localStorage.setItem("TextGroupView", JSON.stringify(newData.values));
  };
  onClickPaste = () => {
    const textContent = JSON.parse(localStorage.getItem("TextGroupView"));
    if (textContent) {
      const {
        valueText,
        backgroundColor,
        containerPadding,
        lineHeight,
        letterSpacing,
        align,
        setting,
        fontSize,
        color,
      } = textContent;
      const lH = lineHeight.split("%").join("") * 1;
      const lS = letterSpacing.split("px").join("") * 1;
      // const textLink = href ? href : defaultHref;
      this.setState({
        value: valueText,
        backgroundColor: backgroundColor,
        oldValue: this.props.data,
        containerPadding: containerPadding,
        lineHeight: lH,
        letterSpacing: lS,
        align: align,
        setting: setting,
        fontSize: fontSize,
        color: color || "#000000",
      });
    }
  };

  render() {
    return (
      <Flex width={[1]} flexDirection={"column"}>
        <BoxContent style={{ height: this.state.heightContent - 120 }}>
          <ContainerPanel>
            <div style={{ padding: 8 }}>
              <ContentDetail>
                <TextGroup
                  list={this.state.value}
                  onChangeText={(value) => this.onSetValue("value", value)}
                />
              </ContentDetail>
            </div>
            <PanelCard
              title={"Content Background"}
              id={"background-text-group"}
            >
              <ContentDetail>
                <SketchColorPicker
                  title={"Background"}
                  onUpdateColor={(color) =>
                    this.onSetValue("backgroundColor", color)
                  }
                  color={this.state.backgroundColor}
                />
              </ContentDetail>
            </PanelCard>
            <PanelCard title={"Content Text"} id={"general-text-group"}>
              <ContentDetail>
                <SketchColorPicker
                  title={"Color"}
                  onUpdateColor={(color) => this.onSetValue("color", color)}
                  color={this.state.color}
                />
                <Flex
                  flexDirection={"row"}
                  width={[1]}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Title title='Font Size Desktop' />
                  <Counter
                    onSetValue={(value) =>
                      this.onUpdateFontSize(value, "desktop")
                    }
                    value={this.state.fontSize.desktop}
                    interval={1}
                    subtitle='pt'
                    minValue={5}
                  />
                </Flex>
                <Flex
                  flexDirection={"row"}
                  width={[1]}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Title title='Font Size Mobile' />
                  <Counter
                    onSetValue={(value) =>
                      this.onUpdateFontSize(value, "mobile")
                    }
                    value={this.state.fontSize.mobile}
                    interval={1}
                    subtitle='pt'
                    minValue={5}
                  />
                </Flex>
              </ContentDetail>
              <BlockCounter
                title={"Container Padding"}
                containerPadding={this.state.containerPadding}
                onUpdatePadding={(containerPadding) =>
                  this.onSetValue("containerPadding", containerPadding)
                }
              />
              <ContentDetail>
                <Alignment
                  value={this.state.align}
                  onChange={(value) => this.onSetValue("align", value)}
                  title='Alignment'
                />
              </ContentDetail>
            </PanelCard>

            <PanelCard
              title={"Setting Animation"}
              id={"setting-animation-text-group"}
            >
              <ContentDetail>
                <SettingSlideTextGroup
                  setting={this.state.setting}
                  onChange={(value) => this.onSetValue("setting", value)}
                />
              </ContentDetail>
            </PanelCard>
          </ContainerPanel>
        </BoxContent>
        <FooterAction
          onClickSave={this.onClickSave}
          onClickCopy={this.onClickCopy}
          onClickPaste={this.onClickPaste}
          widthContent={this.state.widthContent}
        />
      </Flex>
    );
  }
}

TextGroupView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

TextGroupView.defaultProps = {
  data: {},
};
export default TextGroupView;
