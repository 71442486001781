import React, { useRef, useState } from "react";
import { Box } from "rebass";
import { get } from "lodash";
import FooterEditorLayout from "../FooterEditorLayout";

const FooterContent = (props) => {
  const { formData, widthScreen, heightScreen } = props;
  // const translate = useTranslate()
  const myFooterPage = "myFooterPage";
  const myref = useRef(myFooterPage);
  const [layout, setLayout] = useState(formData.footer);
  const websiteId = get(formData, "website_id", 0);
  const siteId = get(formData, "site_id", 0);

  //get(this.props, "values.website_id", 0)
  const onChangeData = (data, dataRaw, localeId) => {
    setLayout(data);
    props.updateField(`footer`, dataRaw);
  };
  const onResetPage = (ref, locale) => {};

  return (
    <Box width={[1]} pl={[1]}>
      <FooterEditorLayout
        ref={myref}
        myref={myref}
        onChangeData={(data, dataRaw) => onChangeData(data, dataRaw, "en")}
        data={layout}
        statusForm={"edit"}
        isShow={true}
        locale={"en"}
        onResetPage={() => onResetPage(myref, "en")}
        widthScreen={widthScreen - 250}
        heightScreen={heightScreen}
        websiteId={websiteId}
        siteId={siteId}
        categories={props.categories}
      />
    </Box>
  );
};

export default FooterContent;
