export default {
  inventories: {
    name: "Inventories",
    new_title: "New Inventory",
    edit_title: "Edit Inventory",
    fields: {
      sku: "SKU",
      product_name: "Product Name",
      available: "Available",
      variant: "Variant",
      ordered: "Ordered",
      threshold: "Threshold",
    },
  },
};
