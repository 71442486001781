/* eslint-disable react/no-typos */
import React, { Component } from "react"
import PropTypes from "prop-types"
import Select from "@material-ui/core/Select"
import FormControl from "@material-ui/core/FormControl"
// import Radio from "@material-ui/core/Radio"
// import RadioGroup from "@material-ui/core/RadioGroup"
// import FormControlLabel from "@material-ui/core/FormControlLabel"
// import FormatIconLeft from "@material-ui/icons/FormatAlignLeft"
// import FormatIconCenter from "@material-ui/icons/FormatAlignCenter"
// import FormatIconRight from "@material-ui/icons/FormatAlignRight"

import MenuItem from "@material-ui/core/MenuItem"
import { Flex } from "rebass"
import { Title } from "../text"

const listAlignment = [
  "baseline",
  "flex-start",
  "flex-end",
  "center",
  "stretch",
  "space-between",
  "space-around",
  "space-evenly",
]

class Alignment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
    }
  }
  handleChange = (event) => {
    this.setState({ value: event.target.value })
    this.props.onChange(event.target.value)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = nextProps
    if (value !== this.props.value) {
      this.setState({ value: value })
    }
  }
  render() {
    const { title } = this.props
    const { value } = this.state
    return (
      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {title && <Title title={title} />}
        <FormControl component="fieldset">
          {/* <RadioGroup
            aria-label="alignment"
            name={"alignment"}
            value={value}
            onChange={this.handleChange}
            row
          >
            <FormControlLabel
              value="flex-start"
              control={<Radio color="primary" />}
              label={
                value === "flex-start" ? (
                  <FormatIconLeft color={"primary"} />
                ) : (
                  <FormatIconLeft color={"secondary"} />
                )
              }
              labelPlacement="end"
            />
            <FormControlLabel
              value="center"
              label={
                value === "center" ? (
                  <FormatIconCenter color={"primary"} />
                ) : (
                  <FormatIconCenter color={"secondary"} />
                )
              }
              control={<Radio color="primary" />}
              labelPlacement="end"
            />
            <FormControlLabel
              value="flex-end"
              label={
                value === "flex-end" ? (
                  <FormatIconRight color={"primary"} />
                ) : (
                  <FormatIconRight color={"secondary"} />
                )
              }
              control={<Radio color="primary" />}
              labelPlacement="end"
            />
          </RadioGroup>*/}
          <Select
            aria-label="alignment"
            name={"alignment"}
            value={value}
            onChange={this.handleChange}
          >
            {listAlignment.map((item, index) => {
              return (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Flex>
    )
  }
}

Alignment.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

Alignment.defaultProps = {
  title: "Alignment",
  value: "center",
}
export default Alignment
