import React from "react";
import { DivTitle } from "../styled";
import { Paper } from "@material-ui/core";

const styles = {
  paperText: {
    padding: 10,
    marginBottom: 10,
  },
};
export const PaperCard = (props) => {
  const { children, title } = props;
  return (
    <Paper style={styles.paperText} elevation={1}>
      <DivTitle>{title}</DivTitle>
      <div>{children}</div>
    </Paper>
  );
};
