import React from "react";
import { Text as RebasText } from "rebass";
import styled from "styled-components";
import { inputStyles } from "../themes";

export const Title = (props) => (
  <RebasText
    {...props}
    style={{ letterSpacing: inputStyles.letterSpacing }}
    color={inputStyles.colorLabel}
  />
);

const MyText = styled(RebasText)`
  font-weight: bold;
  letter-spacing: 1px;
  color: ${inputStyles.colorLabel};
  line-height: 1.5rem;
`;

export const H3 = (props) => {
  return (
    <MyText fontSize={["1rem", "1.3rem", "1.5rem"]} {...props}>
      {props.children}
    </MyText>
  );
};

export const Small = styled(RebasText)`
  font-weight: 300;
  font-size: 0.7rem;
  letter-spacing: 1px;
  color: ${inputStyles.colorLabel};
`;
