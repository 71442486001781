import React, { Fragment, useState } from "react";
import { Flex } from "rebass";
import { replace, get, filter } from "lodash";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { HeaderTitle } from "../../../../components";
import {
  DetailFields,
  ReturnItemFields,
  RefundItemFields,
  // ExchangeItemFields,
  RefundRequestShipment,
  ListReturnItem,
} from "./";
// import { dummyOrder } from "../utils";
import { RestApiCRUDString, RestApiAll } from "../../../../restClient";
import { useQuery } from "../../../../components";
const MainFields = (props) => {
  const { record, history, translate, orderId, location, listItem, notify } =
    props;
  const [loading, setLoading] = useState(false);
  const [listQty, setListQty] = useState(listItem);
  //by default source_id 1
  const [state, setState] = useState({
    site_id: record.site_id,
    order_id: record.id,
    source_id: 1,
    packaging_id: 0,
    type: "Return",
    reason: "",
    remarks: "",
    width: 0,
    height: 0,
    length: 0,
    weight: 0,
    cost: 0,
  });
  const websiteData = useQuery(`/websites/${record.website_id}`);
  const website = websiteData.data;

  const url = replace(location.pathname, "/create", "");
  const onSetValue = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };
  const onRequestShipment = () => {
    if (state.type === "") {
      notify("resources.errors.please_select_type", "warning", {
        smart_count: 1,
      });
    }
    //  else if (
    //   state.packaging_id === 0 &&
    //   (state.width === 0 ||
    //     state.height === 0 ||
    //     state.length === 0 ||
    //     state.weight === 0)
    // ) {
    //   notify("resources.errors.please_select_packging", "warning", {
    //     smart_count: 1,
    //   });
    // } else if (state.source_id === 0) {
    //   notify("resources.errors.please_select_ship_back_to", "warning", {
    //     smart_count: 1,
    //   });

    // }
    /**please_fill_remarks: "Please fill remarks",
    please_select_reason: "Please select reason", */
    else if (state.remarks === "") {
      notify("resources.errors.please_fill_remarks", "warning", {
        smart_count: 1,
      });
    } else if (state.reason === "") {
      notify("resources.errors.please_select_reason", "warning", {
        smart_count: 1,
      });
    } else {
      setLoading(true);
      RestApiCRUDString(`/orders/${orderId}/amendments`, "POST", state)
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          if (res && res.id) {
            onPostOrderDetail(res);
          } else {
            notify("resources.errors.please_try_again", "warning", {
              smart_count: 1,
            });
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
          notify("resources.errors.please_try_again", "warning", {
            smart_count: 1,
          });
          setLoading(false);
        });
    }
  };

  const onPostOrderDetail = (response) => {
    let newParams = [];
    listQty.map((io) => {
      const orderItem = {
        site_id: state.site_id,
        type: state.type,
        order_detail_id: io.id,
        qty: get(io, "qtyTemp", 0),
      };
      newParams.push(orderItem);
      return "";
    });
    RestApiAll(
      `orders/${record.id}/amendments/${response.id}`,
      "POST",
      newParams
    )
      .then((res) => {
        // console.log("add shipment", res);
        if (res && res.length > 0) {
          const result = filter(res, function (o) {
            return o.message;
          });
          if (result.length === res.length) {
            const message = get(
              result[0],
              "message",
              "resources.errors.please_try_again"
            );
            notify(message, "warning", {
              smart_count: 1,
            });
          } else if (result.length > 0) {
            const message = get(
              result[0],
              "message",
              "resources.errors.please_try_again"
            );
            notify(message, "warning", {
              smart_count: 1,
            });
          } else {
            notify("ra.notification.created", "success", {
              smart_count: 1,
            });
            history.replace(url);
          }
          setLoading(false);
        }
      })
      .catch((e) => {
        // console.log(JSON.stringify(e));
        notify("resources.errors.please_try_again", "warning", {
          smart_count: 1,
        });
        setLoading(false);
      });
  };
  const onPrintSlip = (p) => {};
  const onPrintCreditMemo = (p) => {};
  const onCreateMemo = () => {
    /*
    else if (
      state.packaging_id === 0 &&
      (state.width === 0 ||
        state.height === 0 ||
        state.length === 0 ||
        state.weight === 0)
    ) {
      notify("resources.errors.please_select_packging", "warning", {
        smart_count: 1,
      });
    } else if (state.source_id === 0) {
      notify("resources.errors.please_select_ship_back_to", "warning", {
        smart_count: 1,
      });
      
  } */
    if (state.type === "") {
      notify("resources.errors.please_select_type", "warning", {
        smart_count: 1,
      });
    } else if (state.remarks === "") {
      notify("resources.errors.please_fill_remarks", "warning", {
        smart_count: 1,
      });
    } else if (state.reason === "") {
      notify("resources.errors.please_select_reason", "warning", {
        smart_count: 1,
      });
    } else {
      setLoading(true);
      const paramsParent = {
        site_id: state.site_id,
        order_id: state.id,
        source_id: state.source_id,
        type: state.type,
        reason: state.reason,
        remarks: state.remarks,
        width: 0,
        height: 0,
        length: 0,
        weight: 0,
        cost: 0,
        packaging_id: 0,
      };
      RestApiCRUDString(`/orders/${orderId}/amendments`, "POST", paramsParent)
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          if (res && res.message && res.message !== "") {
            notify(res.message, "warning", {
              smart_count: 1,
            });
          } else {
            if (res && res.id) {
              onPostOrderDetail(res);
            } else {
              notify("resources.errors.please_try_again", "warning", {
                smart_count: 1,
              });
            }
          }
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          notify("resources.errors.please_try_again", "warning", {
            smart_count: 1,
          });
          setLoading(false);
        });
    }
  };
  let titleList = "resources.orders.labels.return_items";
  if (state.type === "Refund") {
    titleList = "resources.orders.labels.refund_items";
  }

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <HeaderTitle title={"resources.orders.title.new_amendment"}>
        <Fragment>
          <Button component={Link} color={"primary"} to={url}>
            {translate("resources.buttons.list")}
          </Button>
        </Fragment>
      </HeaderTitle>
      <Flex width={[1]} flexDirection={"column"} px={[2]}>
        <DetailFields
          {...props}
          translate={translate}
          state={state}
          onSetValue={onSetValue}
          website={website}
        />
        {state.type !== "" && (
          <ListReturnItem
            {...props}
            title={titleList}
            labelButton={"resources.buttons.print_return_slip"}
            onPrintSlip={onPrintSlip}
            translate={translate}
            record={record}
            type={state.type}
            listQty={listQty}
            setListQty={setListQty}
            website={website}
          />
        )}
        {state.type === "Refund" && (
          <RefundItemFields
            {...props}
            record={record}
            translate={translate}
            title={"resources.orders.labels.refund"}
            labelButton={"resources.buttons.print_creadit_memo"}
            onPrintCreditMemo={onPrintCreditMemo}
            onCreateMemo={onCreateMemo}
            state={state}
            website={website}
            loading={loading}
          />
        )}
        {state.type === "Return" && (
          <ReturnItemFields
            {...props}
            state={state}
            setState={setState}
            // record={dummyOrder}
            translate={translate}
            onRequestShipment={onRequestShipment}
            website={website}
            loading={loading}
          />
        )}
        {state.type === "Refund" &&
          (state.reason === "Missing Item" ||
            state.reason === "Lost Shipment") && (
            <RefundRequestShipment
              {...props}
              state={state}
              order={record}
              setState={setState}
              translate={translate}
              onRequestShipment={onRequestShipment}
              website={website}
              loading={loading}
            />
          )}
      </Flex>
    </Flex>
  );
};

export default MainFields;
