import React, { Fragment } from "react"
// import ExpandMore from "@material-ui/icons/ExpandMore"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import Collapse from "@material-ui/core/Collapse"
import { useTranslate } from "react-admin"
import { useStyles } from "./styles"
import clsx from "clsx"

const SubMenuItem = (props) => {
  const classes = useStyles()

  const {
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    className,
    children,
  } = props
  const translate = useTranslate()
  // console.log(classes)
  return (
    <Fragment>
      <ListItem
        dense
        button
        onClick={handleToggle}
        className={clsx(classes.listItem, className)}
      >
        <ListItemIcon className={classes.listItemIcon}>
          {isOpen ? icon : icon}
        </ListItemIcon>
        <ListItemText
          inset
          primary={
            isOpen ? (
              <Typography variant="body2" className={classes.text}>
                {translate(name)}
              </Typography>
            ) : (
              ""
            )
          }
          secondary={
            isOpen ? (
              ""
            ) : (
              <Typography variant="body2" className={classes.text}>
                {translate(name)}
              </Typography>
            )
          }
          className={classes.listItemText}
        />
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense
          component="div"
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  )
}

export default SubMenuItem
