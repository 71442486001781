import React from "react";
import { SketchColorPicker } from "../../../components";
import { ContentDetail } from "../actions";
export default (props) => {
  const { state, onSetValue } = props;
  const onStateColorsValue = (k, v) => {
    const params = {
      ...state,
      [k]: v,
    };
    onSetValue(params);
  };
  return (
    <ContentDetail>
      <SketchColorPicker
        title={"Color"}
        onUpdateColor={(color) => onStateColorsValue("color", color)}
        color={state.color}
      />
      <SketchColorPicker
        title={"ViewAll Color"}
        onUpdateColor={(color) => onStateColorsValue("viewallColor", color)}
        color={state.viewallColor}
      />
      <SketchColorPicker
        title={"Background"}
        onUpdateColor={(color) => onStateColorsValue("backgroundColor", color)}
        color={state.backgroundColor}
      />
      <SketchColorPicker
        title={"Hover Color"}
        onUpdateColor={(color) => onStateColorsValue("hoverColor", color)}
        color={state.hoverColor}
      />
      <SketchColorPicker
        title={"HoverBackground"}
        onUpdateColor={(color) =>
          onStateColorsValue("hoverBackgroundColor", color)
        }
        color={state.hoverBackgroundColor}
      />
    </ContentDetail>
  );
};
