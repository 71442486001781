import React from "react";
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd";
import { Flex, Box, Text } from "rebass";
import { get, includes } from "lodash";
import { Col } from "antd";
import ContainerDimensions from "react-container-dimensions";
import { DivEmptyContent } from "./styled";
import SeparatorContent from "./SeparatorContent";
import { connect } from "../../core";
import { getStylGradiant, getBGProps } from "./components/gradiant";
const getContentSize = (values) => {
  // const column = get(values, "column", 24);
  const classNameContent = get(values, "className");

  if (classNameContent === "col-xs-12") {
    return 24;
  } else if (includes(classNameContent, "col-md-9")) {
    return 18;
  } else if (includes(classNameContent, "col-md-3")) {
    return 6;
  } else if (includes(classNameContent, "col-md-4")) {
    return 8;
  } else {
    return 24;
  }
};

const Wave = (props) => {
  const color = props.color ? props.color : "inherit";
  return (
    <div className='sk-wave' style={{ color: "inherit" }}>
      <div
        className='sk-wave-rect'
        style={{ color: "inherit", backgroundColor: color }}
      ></div>
      <div
        className='sk-wave-rect'
        style={{ color: "inherit", backgroundColor: color }}
      ></div>
      <div
        className='sk-wave-rect'
        style={{ color: "inherit", backgroundColor: color }}
      ></div>
      <div
        className='sk-wave-rect'
        style={{ color: "inherit", backgroundColor: color }}
      ></div>
      <div
        className='sk-wave-rect'
        style={{ color: "inherit", backgroundColor: color }}
      ></div>
    </div>
  );
};

const target = {
  drop(props, monitor, component) {
    if (!monitor.didDrop()) {
      const dragType = monitor.getItem().type;
      const { values } = monitor.getItem().props;
      const hoverType = props.type;
      if (dragType !== hoverType && values.parent === "Content") {
        props.layoutState.insertOrMoveItem(
          props.id,
          props.children.length,
          monitor.getItem()
        );
      }
    }
  },
};

const ShowBGProps = (
  myBGProps,
  important,
  parsedChildren,
  padding,
  widthContent,
  height,
  candrop
) => {
  return (
    <ContainerDimensions>
      {({ width }) => {
        let myMinHeight = {};
        if (myBGProps) {
          const minHeightBG = width / myBGProps.ratio;
          // console.log(width);
          myMinHeight =
            get(myBGProps, "height", "100%") !== "100%"
              ? {}
              : { minHeight: minHeightBG };
        }
        return (
          <div
            style={{
              padding: padding,
              width: widthContent,
              display: "flex",
              height: height,
              ...important,
              ...myMinHeight,
            }}
          >
            {parsedChildren.length < 1 && (
              <DivEmptyContent>
                {candrop ? (
                  <Box p={[2]}>
                    <Wave color={"#2980b9"} />
                    <Text textAlign={"center"}>Loading...</Text>
                  </Box>
                ) : (
                  <span>
                    No content yet. Drag a content from the right to here
                  </span>
                )}
              </DivEmptyContent>
            )}
            {parsedChildren}
          </div>
        );
      }}
    </ContainerDimensions>
  );
};

const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
  candrop,
  widthScreen,
}) => {
  const { parent } = layoutState.getItemJS(id);
  const parentContent = layoutState.getItemJS(parent.id);
  const column = parentContent.children.indexOf(`${id}`);
  const { content } = parentContent.props;
  // const { align } = parentContent.props.values;
  const { values } = content[column].props;
  // console.log(values.column, parentContent);
  const layoutColSpan = get(
    values,
    "desktop.layout.span",
    getContentSize(values)
  );
  const isMobileScreen = widthScreen < 480;
  const layoutColOrder = get(
    values,
    isMobileScreen ? "mobile.layout.order" : "desktop.layout.order",
    1
  );
  const padding = get(
    values,
    isMobileScreen ? "mobile.padding" : "desktop.padding",
    1
  );
  // console.log(values);
  const backgroundColor = get(
    values,
    isMobileScreen ? "mobile.backgroundColor" : "desktop.backgroundColor",
    "transparent"
  );
  let important = { backgroundColor: backgroundColor };
  const myGradient = isMobileScreen
    ? getStylGradiant(get(values, "mobile", {}))
    : getStylGradiant(get(values, "desktop", {}));
  if (myGradient) {
    if (myGradient.type) {
      const mydegre =
        myGradient.type === "radial-gradient"
          ? "circle"
          : `${myGradient.degre}deg`;
      const newBg = {
        background: `${myGradient.type}(${mydegre}, ${myGradient.bg1} ${myGradient.linear[0]}%,${myGradient.bg2} ${myGradient.linear[1]}%)`,
      };
      important = { background: myGradient.bg1, height: "100%", ...newBg };
    }
  }
  const myBGProps = isMobileScreen
    ? getBGProps(get(values, "mobile", {}))
    : getBGProps(get(values, "desktop", {}));
  if (myBGProps) {
    // const minHeight = widthScreen / myBGProps.ratio;
    important = {
      backgroundImage: `url("${myBGProps.url}")`,
      backgroundSize: myBGProps.size,
      backgroundPosition: myBGProps.position,
      backgroundRepeat: myBGProps.repeat,
      // minHeight: minHeight,
      height: get(myBGProps, "height", "100%"),
      flexDirection: get(myBGProps, "layout.direction", "column"),
      alignItems: get(myBGProps, "layout.align", "flex-start"),
      justifyContent: get(myBGProps, "layout.justify", "flex-start"),
    };
  }

  const widthContent = get(
    values,
    isMobileScreen ? "mobile.width" : "desktop.width",
    "100%"
  );
  const height = get(
    values,
    isMobileScreen ? "mobile.height" : "desktop.height",
    "100%"
  );
  let parsedChildren = [];
  if (!readOnly && children.length) {
    for (let i = 0; i < children.length * 2 + 1; i++) {
      if (i % 2 === 0) {
        parsedChildren.push(
          <SeparatorContent
            key={`${id}-seperator-${i}`}
            onDrop={(item) => layoutState.insertOrMoveItem(id, i / 2, item)}
            direction={"horizontal"}
          />
        );
      } else {
        parsedChildren.push(children[(i - 1) / 2]);
      }
    }
  } else {
    parsedChildren = children;
  }

  return connectDropTarget(
    <div className={`ant-col-${layoutColSpan}`}>
      <Col span={24} order={layoutColOrder}>
        {myBGProps ? (
          ShowBGProps(
            myBGProps,
            important,
            parsedChildren,
            padding,
            widthContent,
            height,
            candrop
          )
        ) : (
          <div
            style={{
              padding: padding,
              display: "flex",
              flex: 1,
              ...important,
              width: widthContent,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Flex
                width={[1, "100%"]}
                flexDirection={"column"}
                alignItems={"center"}
                // justifyContent={"center"}
                // style={{
                //   backgroundColor: "#FF0000",
                // }}
              >
                {parsedChildren.length < 1 && (
                  <DivEmptyContent>
                    {candrop ? (
                      <Box p={[2]}>
                        <Wave color={"#2980b9"} />
                        <Text textAlign={"center"}>Loading...</Text>
                      </Box>
                    ) : (
                      <span>
                        No content yet. Drag a content from the right to here
                      </span>
                    )}
                  </DivEmptyContent>
                )}
                {parsedChildren}
              </Flex>
            </div>
          </div>
        )}
        {/* */}
      </Col>
    </div>
  );
};

const LayoutContainer = DropTarget("Content", target, (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true }),
  candrop: monitor.didDrop(),
}))(EditLayout);

export default connect(
  "layoutState",
  "readOnly",
  "widthScreen"
)(LayoutContainer);

// export default LayoutContainer;s
