import React from "react";
import { get, orderBy } from "lodash";
import { Box } from "rebass";
import { useTranslate, Loading } from "react-admin";
// import ListMenu from "./menu/ListMenu";
import { useQuery } from "react-query";
import { SampleListMenu } from "./menu";
import { useStyles } from "./menu/styles";
// import { useQuery } from "../../../components";
import { getContents, getCategories } from "../../../restClient";
const cacheTimeMinute = 1000 * 60 * 5;
const defaultMenu = [
  {
    id: "1",
    title: "MEN",
    parent_id: "root",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "1",
      parent_id: "root",
      content_id: "1",
      title: "MEN",
      icon: "",
      href: {
        type: "Category",
        value: 1,
      },
    },
  },
  {
    id: "2",
    title: "Apparel",
    parent_id: "1",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "2",
      parent_id: "1",
      content_id: "2",
      title: "Apparel",
      icon: "",
      href: {
        type: "Category",
        value: 2,
      },
    },
  },
  {
    id: "3",
    title: "Hoodies",
    parent_id: "2",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "3",
      parent_id: "2",
      content_id: "3",
      title: "Hoodies",
      icon: "",
      href: {
        type: "Category",
        value: 3,
      },
    },
  },
  {
    id: "4",
    title: "Jacket",
    parent_id: "2",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "4",
      parent_id: "2",
      content_id: "4",
      title: "Jacket",
      icon: "",
      href: {
        type: "Category",
        value: 4,
      },
    },
  },
  {
    id: "5",
    title: "Long Sleeves",
    parent_id: "2",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "5",
      parent_id: "2",
      content_id: "5",
      title: "Long Sleeves",
      icon: "",
      href: {
        type: "Category",
        value: 5,
      },
    },
  },
  {
    id: "6",
    title: "Outerwear",
    parent_id: "2",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "6",
      parent_id: "2",
      content_id: "6",
      title: "Outerwear",
      icon: "",
      href: {
        type: "Category",
        value: 6,
      },
    },
  },
  {
    id: "7",
    title: "Pants",
    parent_id: "2",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "7",
      parent_id: "2",
      content_id: "7",
      title: "Pants",
      icon: "",
      href: {
        type: "Category",
        value: 7,
      },
    },
  },
  {
    id: "8",
    title: "Shirts",
    parent_id: "2",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "8",
      parent_id: "2",
      content_id: "8",
      title: "Shirts",
      icon: "",
      href: {
        type: "Category",
        value: 8,
      },
    },
  },
  {
    id: "9",
    title: "Shorts",
    parent_id: "2",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "9",
      parent_id: "2",
      content_id: "9",
      title: "Shorts",
      icon: "",
      href: {
        type: "Category",
        value: 9,
      },
    },
  },
  {
    id: "10",
    title: "Tights",
    parent_id: "2",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "10",
      parent_id: "2",
      content_id: "10",
      title: "Tights",
      icon: "",
      href: {
        type: "Category",
        value: 10,
      },
    },
  },
  {
    id: "11",
    title: "Tank Top",
    parent_id: "2",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "11",
      parent_id: "2",
      content_id: "11",
      title: "Tank Top",
      icon: "",
      href: {
        type: "Category",
        value: 11,
      },
    },
  },
  {
    id: "12",
    title: "Top",
    parent_id: "2",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "12",
      parent_id: "2",
      content_id: "12",
      title: "Top",
      icon: "",
      href: {
        type: "Category",
        value: 12,
      },
    },
  },
  {
    id: "13",
    title: "Others",
    parent_id: "2",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "13",
      parent_id: "2",
      content_id: "13",
      title: "Others",
      icon: "",
      href: {
        type: "Category",
        value: 13,
      },
    },
  },
  {
    id: "14",
    title: "Footwear",
    parent_id: "1",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "14",
      parent_id: "1",
      content_id: "14",
      title: "Footwear",
      icon: "",
      href: {
        type: "Category",
        value: 14,
      },
    },
  },
  {
    id: "15",
    title: "Boots",
    parent_id: "14",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "15",
      parent_id: "14",
      content_id: "15",
      title: "Boots",
      icon: "",
      href: {
        type: "Category",
        value: 15,
      },
    },
  },
  {
    id: "16",
    title: "Sandals",
    parent_id: "14",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "16",
      parent_id: "14",
      content_id: "16",
      title: "Sandals",
      icon: "",
      href: {
        type: "Category",
        value: 16,
      },
    },
  },
  {
    id: "17",
    title: "Slides",
    parent_id: "14",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "17",
      parent_id: "14",
      content_id: "17",
      title: "Slides",
      icon: "",
      href: {
        type: "Category",
        value: 17,
      },
    },
  },
  {
    id: "18",
    title: "Sneakers",
    parent_id: "14",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "18",
      parent_id: "14",
      content_id: "18",
      title: "Sneakers",
      icon: "",
      href: {
        type: "Category",
        value: 18,
      },
    },
  },
  {
    id: "19",
    title: "Sports Shoes",
    parent_id: "14",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "19",
      parent_id: "14",
      content_id: "19",
      title: "Sports Shoes",
      icon: "",
      href: {
        type: "Category",
        value: 19,
      },
    },
  },
  {
    id: "20",
    title: "Others",
    parent_id: "14",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "20",
      parent_id: "14",
      content_id: "20",
      title: "Others",
      icon: "",
      href: {
        type: "Category",
        value: 20,
      },
    },
  },
  {
    id: "21",
    title: "Accessories",
    parent_id: "1",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "21",
      parent_id: "1",
      content_id: "21",
      title: "Accessories",
      icon: "",
      href: {
        type: "Category",
        value: 21,
      },
    },
  },
  {
    id: "22",
    title: "Key Chain",
    parent_id: "21",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "22",
      parent_id: "21",
      content_id: "22",
      title: "Key Chain",
      icon: "",
      href: {
        type: "Category",
        value: 22,
      },
    },
  },
  {
    id: "23",
    title: "Backpacks",
    parent_id: "21",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "23",
      parent_id: "21",
      content_id: "23",
      title: "Backpacks",
      icon: "",
      href: {
        type: "Category",
        value: 23,
      },
    },
  },
  {
    id: "24",
    title: "Bags",
    parent_id: "21",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "24",
      parent_id: "21",
      content_id: "24",
      title: "Bags",
      icon: "",
      href: {
        type: "Category",
        value: 24,
      },
    },
  },
  {
    id: "25",
    title: "Supporters",
    parent_id: "21",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "25",
      parent_id: "21",
      content_id: "25",
      title: "Supporters",
      icon: "",
      href: {
        type: "Category",
        value: 25,
      },
    },
  },
  {
    id: "26",
    title: "Hats",
    parent_id: "21",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "26",
      parent_id: "21",
      content_id: "26",
      title: "Hats",
      icon: "",
      href: {
        type: "Category",
        value: 26,
      },
    },
  },
  {
    id: "27",
    title: "Headwear",
    parent_id: "21",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "27",
      parent_id: "21",
      content_id: "27",
      title: "Headwear",
      icon: "",
      href: {
        type: "Category",
        value: 27,
      },
    },
  },
  {
    id: "28",
    title: "Wallet",
    parent_id: "21",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "28",
      parent_id: "21",
      content_id: "28",
      title: "Wallet",
      icon: "",
      href: {
        type: "Category",
        value: 28,
      },
    },
  },
  {
    id: "29",
    title: "Gloves",
    parent_id: "21",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "29",
      parent_id: "21",
      content_id: "29",
      title: "Gloves",
      icon: "",
      href: {
        type: "Category",
        value: 29,
      },
    },
  },
  {
    id: "30",
    title: "Arm and Leg Wear",
    parent_id: "21",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "30",
      parent_id: "21",
      content_id: "30",
      title: "Arm and Leg Wear",
      icon: "",
      href: {
        type: "Category",
        value: 30,
      },
    },
  },
  {
    id: "31",
    title: "Socks",
    parent_id: "21",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "31",
      parent_id: "21",
      content_id: "31",
      title: "Socks",
      icon: "",
      href: {
        type: "Category",
        value: 31,
      },
    },
  },
  {
    id: "32",
    title: "Towels",
    parent_id: "21",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "32",
      parent_id: "21",
      content_id: "32",
      title: "Towels",
      icon: "",
      href: {
        type: "Category",
        value: 32,
      },
    },
  },
  {
    id: "33",
    title: "Others",
    parent_id: "21",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "33",
      parent_id: "21",
      content_id: "33",
      title: "Others",
      icon: "",
      href: {
        type: "Category",
        value: 33,
      },
    },
  },
  {
    id: "34",
    title: "New Arrivals",
    parent_id: "1",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "34",
      parent_id: "1",
      content_id: "34",
      title: "New Arrivals",
      icon: "",
      href: {
        type: "Category",
        value: 34,
      },
    },
  },
  {
    id: "35",
    title: "WOMEN",
    parent_id: "root",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "35",
      parent_id: "root",
      content_id: "35",
      title: "WOMEN",
      icon: "",
      href: {
        type: "Category",
        value: 35,
      },
    },
  },
  {
    id: "36",
    title: "Apparel",
    parent_id: "35",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "36",
      parent_id: "35",
      content_id: "36",
      title: "Apparel",
      icon: "",
      href: {
        type: "Category",
        value: 36,
      },
    },
  },
  {
    id: "37",
    title: "Dresses",
    parent_id: "36",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "37",
      parent_id: "36",
      content_id: "37",
      title: "Dresses",
      icon: "",
      href: {
        type: "Category",
        value: 37,
      },
    },
  },
  {
    id: "38",
    title: "Hoodies",
    parent_id: "36",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "38",
      parent_id: "36",
      content_id: "38",
      title: "Hoodies",
      icon: "",
      href: {
        type: "Category",
        value: 38,
      },
    },
  },
  {
    id: "39",
    title: "Jacket",
    parent_id: "36",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "39",
      parent_id: "36",
      content_id: "39",
      title: "Jacket",
      icon: "",
      href: {
        type: "Category",
        value: 39,
      },
    },
  },
  {
    id: "40",
    title: "Long Sleeves",
    parent_id: "36",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "40",
      parent_id: "36",
      content_id: "40",
      title: "Long Sleeves",
      icon: "",
      href: {
        type: "Category",
        value: 40,
      },
    },
  },
  {
    id: "41",
    title: "Outerwear",
    parent_id: "36",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "41",
      parent_id: "36",
      content_id: "41",
      title: "Outerwear",
      icon: "",
      href: {
        type: "Category",
        value: 41,
      },
    },
  },
  {
    id: "42",
    title: "Pants",
    parent_id: "36",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "42",
      parent_id: "36",
      content_id: "42",
      title: "Pants",
      icon: "",
      href: {
        type: "Category",
        value: 42,
      },
    },
  },
  {
    id: "43",
    title: "Shirt",
    parent_id: "36",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "43",
      parent_id: "36",
      content_id: "43",
      title: "Shirt",
      icon: "",
      href: {
        type: "Category",
        value: 43,
      },
    },
  },
  {
    id: "44",
    title: "Short",
    parent_id: "36",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "44",
      parent_id: "36",
      content_id: "44",
      title: "Short",
      icon: "",
      href: {
        type: "Category",
        value: 44,
      },
    },
  },
  {
    id: "45",
    title: "Skirts",
    parent_id: "36",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "45",
      parent_id: "36",
      content_id: "45",
      title: "Skirts",
      icon: "",
      href: {
        type: "Category",
        value: 45,
      },
    },
  },
  {
    id: "46",
    title: "Skorts",
    parent_id: "36",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "46",
      parent_id: "36",
      content_id: "46",
      title: "Skorts",
      icon: "",
      href: {
        type: "Category",
        value: 46,
      },
    },
  },
  {
    id: "47",
    title: "Sport Bras",
    parent_id: "36",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "47",
      parent_id: "36",
      content_id: "47",
      title: "Sport Bras",
      icon: "",
      href: {
        type: "Category",
        value: 47,
      },
    },
  },
  {
    id: "48",
    title: "Tank Top",
    parent_id: "36",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "48",
      parent_id: "36",
      content_id: "48",
      title: "Tank Top",
      icon: "",
      href: {
        type: "Category",
        value: 48,
      },
    },
  },
  {
    id: "49",
    title: "Tights",
    parent_id: "36",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "49",
      parent_id: "36",
      content_id: "49",
      title: "Tights",
      icon: "",
      href: {
        type: "Category",
        value: 49,
      },
    },
  },
  {
    id: "50",
    title: "Top",
    parent_id: "36",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "50",
      parent_id: "36",
      content_id: "50",
      title: "Top",
      icon: "",
      href: {
        type: "Category",
        value: 50,
      },
    },
  },
  {
    id: "51",
    title: "Others",
    parent_id: "36",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "51",
      parent_id: "36",
      content_id: "51",
      title: "Others",
      icon: "",
      href: {
        type: "Category",
        value: 51,
      },
    },
  },
  {
    id: "52",
    title: "Footwear",
    parent_id: "35",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "52",
      parent_id: "35",
      content_id: "52",
      title: "Footwear",
      icon: "",
      href: {
        type: "Category",
        value: 52,
      },
    },
  },
  {
    id: "53",
    title: "Boots",
    parent_id: "52",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "53",
      parent_id: "52",
      content_id: "53",
      title: "Boots",
      icon: "",
      href: {
        type: "Category",
        value: 53,
      },
    },
  },
  {
    id: "54",
    title: "Flats",
    parent_id: "52",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "54",
      parent_id: "52",
      content_id: "54",
      title: "Flats",
      icon: "",
      href: {
        type: "Category",
        value: 54,
      },
    },
  },
  {
    id: "55",
    title: "Sandals",
    parent_id: "52",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "55",
      parent_id: "52",
      content_id: "55",
      title: "Sandals",
      icon: "",
      href: {
        type: "Category",
        value: 55,
      },
    },
  },
  {
    id: "56",
    title: "Slides",
    parent_id: "52",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "56",
      parent_id: "52",
      content_id: "56",
      title: "Slides",
      icon: "",
      href: {
        type: "Category",
        value: 56,
      },
    },
  },
  {
    id: "57",
    title: "Sneakers",
    parent_id: "52",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "57",
      parent_id: "52",
      content_id: "57",
      title: "Sneakers",
      icon: "",
      href: {
        type: "Category",
        value: 57,
      },
    },
  },
  {
    id: "58",
    title: "Sports Shoes",
    parent_id: "52",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "58",
      parent_id: "52",
      content_id: "58",
      title: "Sports Shoes",
      icon: "",
      href: {
        type: "Category",
        value: 58,
      },
    },
  },
  {
    id: "59",
    title: "Others",
    parent_id: "52",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "59",
      parent_id: "52",
      content_id: "59",
      title: "Others",
      icon: "",
      href: {
        type: "Category",
        value: 59,
      },
    },
  },
  {
    id: "60",
    title: "Accessories",
    parent_id: "35",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "60",
      parent_id: "35",
      content_id: "60",
      title: "Accessories",
      icon: "",
      href: {
        type: "Category",
        value: 60,
      },
    },
  },
  {
    id: "61",
    title: "Arm and Leg Wear",
    parent_id: "60",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "61",
      parent_id: "60",
      content_id: "61",
      title: "Arm and Leg Wear",
      icon: "",
      href: {
        type: "Category",
        value: 61,
      },
    },
  },
  {
    id: "62",
    title: "Backpacks",
    parent_id: "60",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "62",
      parent_id: "60",
      content_id: "62",
      title: "Backpacks",
      icon: "",
      href: {
        type: "Category",
        value: 62,
      },
    },
  },
  {
    id: "63",
    title: "Bags",
    parent_id: "60",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "63",
      parent_id: "60",
      content_id: "63",
      title: "Bags",
      icon: "",
      href: {
        type: "Category",
        value: 63,
      },
    },
  },
  {
    id: "64",
    title: "Gloves",
    parent_id: "60",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "64",
      parent_id: "60",
      content_id: "64",
      title: "Gloves",
      icon: "",
      href: {
        type: "Category",
        value: 64,
      },
    },
  },
  {
    id: "65",
    title: "Hats",
    parent_id: "60",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "65",
      parent_id: "60",
      content_id: "65",
      title: "Hats",
      icon: "",
      href: {
        type: "Category",
        value: 65,
      },
    },
  },
  {
    id: "66",
    title: "Headwear",
    parent_id: "60",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "66",
      parent_id: "60",
      content_id: "66",
      title: "Headwear",
      icon: "",
      href: {
        type: "Category",
        value: 66,
      },
    },
  },
  {
    id: "67",
    title: "Key Chain",
    parent_id: "60",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "67",
      parent_id: "60",
      content_id: "67",
      title: "Key Chain",
      icon: "",
      href: {
        type: "Category",
        value: 67,
      },
    },
  },
  {
    id: "68",
    title: "Socks",
    parent_id: "60",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "68",
      parent_id: "60",
      content_id: "68",
      title: "Socks",
      icon: "",
      href: {
        type: "Category",
        value: 68,
      },
    },
  },
  {
    id: "69",
    title: "Supporters",
    parent_id: "60",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "69",
      parent_id: "60",
      content_id: "69",
      title: "Supporters",
      icon: "",
      href: {
        type: "Category",
        value: 69,
      },
    },
  },
  {
    id: "70",
    title: "Towels",
    parent_id: "60",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "70",
      parent_id: "60",
      content_id: "70",
      title: "Towels",
      icon: "",
      href: {
        type: "Category",
        value: 70,
      },
    },
  },
  {
    id: "71",
    title: "Wallet",
    parent_id: "60",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "71",
      parent_id: "60",
      content_id: "71",
      title: "Wallet",
      icon: "",
      href: {
        type: "Category",
        value: 71,
      },
    },
  },
  {
    id: "72",
    title: "Others",
    parent_id: "60",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "72",
      parent_id: "60",
      content_id: "72",
      title: "Others",
      icon: "",
      href: {
        type: "Category",
        value: 72,
      },
    },
  },
  {
    id: "73",
    title: "New Arrivals",
    parent_id: "35",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "73",
      parent_id: "35",
      content_id: "73",
      title: "New Arrivals",
      icon: "",
      href: {
        type: "Category",
        value: 73,
      },
    },
  },
  {
    id: "74",
    title: "KIDS",
    parent_id: "root",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "74",
      parent_id: "root",
      content_id: "74",
      title: "KIDS",
      icon: "",
      href: {
        type: "Category",
        value: 74,
      },
    },
  },
  {
    id: "75",
    title: "Footwear",
    parent_id: "74",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "75",
      parent_id: "74",
      content_id: "75",
      title: "Footwear",
      icon: "",
      href: {
        type: "Category",
        value: 75,
      },
    },
  },
  {
    id: "76",
    title: "Grade School",
    parent_id: "75",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "76",
      parent_id: "75",
      content_id: "76",
      title: "Grade School",
      icon: "",
      href: {
        type: "Category",
        value: 76,
      },
    },
  },
  {
    id: "77",
    title: "Preschool",
    parent_id: "75",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "77",
      parent_id: "75",
      content_id: "77",
      title: "Preschool",
      icon: "",
      href: {
        type: "Category",
        value: 77,
      },
    },
  },
  {
    id: "78",
    title: "Toddler",
    parent_id: "75",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "78",
      parent_id: "75",
      content_id: "78",
      title: "Toddler",
      icon: "",
      href: {
        type: "Category",
        value: 78,
      },
    },
  },
  {
    id: "79",
    title: "Sneakers",
    parent_id: "75",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "79",
      parent_id: "75",
      content_id: "79",
      title: "Sneakers",
      icon: "",
      href: {
        type: "Category",
        value: 79,
      },
    },
  },
  {
    id: "80",
    title: "Sports shoes",
    parent_id: "75",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "80",
      parent_id: "75",
      content_id: "80",
      title: "Sports shoes",
      icon: "",
      href: {
        type: "Category",
        value: 80,
      },
    },
  },
  {
    id: "81",
    title: "Accessories",
    parent_id: "74",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "81",
      parent_id: "74",
      content_id: "81",
      title: "Accessories",
      icon: "",
      href: {
        type: "Category",
        value: 81,
      },
    },
  },
  {
    id: "82",
    title: "Bags",
    parent_id: "81",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "82",
      parent_id: "81",
      content_id: "82",
      title: "Bags",
      icon: "",
      href: {
        type: "Category",
        value: 82,
      },
    },
  },
  {
    id: "83",
    title: "Backpacks",
    parent_id: "81",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "83",
      parent_id: "81",
      content_id: "83",
      title: "Backpacks",
      icon: "",
      href: {
        type: "Category",
        value: 83,
      },
    },
  },
  {
    id: "84",
    title: "Hats",
    parent_id: "81",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "84",
      parent_id: "81",
      content_id: "84",
      title: "Hats",
      icon: "",
      href: {
        type: "Category",
        value: 84,
      },
    },
  },
  {
    id: "85",
    title: "Headwear",
    parent_id: "81",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "85",
      parent_id: "81",
      content_id: "85",
      title: "Headwear",
      icon: "",
      href: {
        type: "Category",
        value: 85,
      },
    },
  },
  {
    id: "86",
    title: "Socks",
    parent_id: "81",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "86",
      parent_id: "81",
      content_id: "86",
      title: "Socks",
      icon: "",
      href: {
        type: "Category",
        value: 86,
      },
    },
  },
  {
    id: "87",
    title: "New Arrivals",
    parent_id: "74",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "87",
      parent_id: "74",
      content_id: "87",
      title: "New Arrivals",
      icon: "",
      href: {
        type: "Category",
        value: 87,
      },
    },
  },
  {
    id: "88",
    title: "PLAYITRIGHT SPORTS",
    parent_id: "root",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "88",
      parent_id: "root",
      content_id: "88",
      title: "PLAYITRIGHT SPORTS",
      icon: "",
      href: {
        type: "Category",
        value: 88,
      },
    },
  },
  {
    id: "89",
    title: "Sports Men",
    parent_id: "88",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "89",
      parent_id: "88",
      content_id: "89",
      title: "Sports Men",
      icon: "",
      href: {
        type: "Category",
        value: 89,
      },
    },
  },
  {
    id: "90",
    title: "Sports Shoes",
    parent_id: "89",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "90",
      parent_id: "89",
      content_id: "90",
      title: "Sports Shoes",
      icon: "",
      href: {
        type: "Category",
        value: 90,
      },
    },
  },
  {
    id: "91",
    title: "Sportswear",
    parent_id: "89",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "91",
      parent_id: "89",
      content_id: "91",
      title: "Sportswear",
      icon: "",
      href: {
        type: "Category",
        value: 91,
      },
    },
  },
  {
    id: "92",
    title: "Sport Accessories",
    parent_id: "89",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "92",
      parent_id: "89",
      content_id: "92",
      title: "Sport Accessories",
      icon: "",
      href: {
        type: "Category",
        value: 92,
      },
    },
  },
  {
    id: "93",
    title: "Sports Women",
    parent_id: "88",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "93",
      parent_id: "88",
      content_id: "93",
      title: "Sports Women",
      icon: "",
      href: {
        type: "Category",
        value: 93,
      },
    },
  },
  {
    id: "94",
    title: "Sports Shoes",
    parent_id: "93",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "94",
      parent_id: "93",
      content_id: "94",
      title: "Sports Shoes",
      icon: "",
      href: {
        type: "Category",
        value: 94,
      },
    },
  },
  {
    id: "95",
    title: "Sportswear",
    parent_id: "93",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "95",
      parent_id: "93",
      content_id: "95",
      title: "Sportswear",
      icon: "",
      href: {
        type: "Category",
        value: 95,
      },
    },
  },
  {
    id: "96",
    title: "Sport Accessories",
    parent_id: "93",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "96",
      parent_id: "93",
      content_id: "96",
      title: "Sport Accessories",
      icon: "",
      href: {
        type: "Category",
        value: 96,
      },
    },
  },
  {
    id: "97",
    title: "Sports Kids",
    parent_id: "88",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "97",
      parent_id: "88",
      content_id: "97",
      title: "Sports Kids",
      icon: "",
      href: {
        type: "Category",
        value: 97,
      },
    },
  },
  {
    id: "98",
    title: "Kids Sports shoes",
    parent_id: "97",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "98",
      parent_id: "97",
      content_id: "98",
      title: "Kids Sports shoes",
      icon: "",
      href: {
        type: "Category",
        value: 98,
      },
    },
  },
  {
    id: "99",
    title: "Sports",
    parent_id: "88",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "99",
      parent_id: "88",
      content_id: "99",
      title: "Sports",
      icon: "",
      href: {
        type: "Category",
        value: 99,
      },
    },
  },
  {
    id: "100",
    title: "American Football",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "100",
      parent_id: "99",
      content_id: "100",
      title: "American Football",
      icon: "",
      href: {
        type: "Category",
        value: 100,
      },
    },
  },
  {
    id: "101",
    title: "Basketball",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "101",
      parent_id: "99",
      content_id: "101",
      title: "Basketball",
      icon: "",
      href: {
        type: "Category",
        value: 101,
      },
    },
  },
  {
    id: "102",
    title: "Beach Volleyball",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "102",
      parent_id: "99",
      content_id: "102",
      title: "Beach Volleyball",
      icon: "",
      href: {
        type: "Category",
        value: 102,
      },
    },
  },
  {
    id: "103",
    title: "Cheerleading",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "103",
      parent_id: "99",
      content_id: "103",
      title: "Cheerleading",
      icon: "",
      href: {
        type: "Category",
        value: 103,
      },
    },
  },
  {
    id: "104",
    title: "Cricket",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "104",
      parent_id: "99",
      content_id: "104",
      title: "Cricket",
      icon: "",
      href: {
        type: "Category",
        value: 104,
      },
    },
  },
  {
    id: "105",
    title: "Dodge Ball",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "105",
      parent_id: "99",
      content_id: "105",
      title: "Dodge Ball",
      icon: "",
      href: {
        type: "Category",
        value: 105,
      },
    },
  },
  {
    id: "106",
    title: "Football",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "106",
      parent_id: "99",
      content_id: "106",
      title: "Football",
      icon: "",
      href: {
        type: "Category",
        value: 106,
      },
    },
  },
  {
    id: "107",
    title: "Futsal",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "107",
      parent_id: "99",
      content_id: "107",
      title: "Futsal",
      icon: "",
      href: {
        type: "Category",
        value: 107,
      },
    },
  },
  {
    id: "108",
    title: "Golf",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "108",
      parent_id: "99",
      content_id: "108",
      title: "Golf",
      icon: "",
      href: {
        type: "Category",
        value: 108,
      },
    },
  },
  {
    id: "109",
    title: "Handball",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "109",
      parent_id: "99",
      content_id: "109",
      title: "Handball",
      icon: "",
      href: {
        type: "Category",
        value: 109,
      },
    },
  },
  {
    id: "110",
    title: "Hockey",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "110",
      parent_id: "99",
      content_id: "110",
      title: "Hockey",
      icon: "",
      href: {
        type: "Category",
        value: 110,
      },
    },
  },
  {
    id: "111",
    title: "Netball",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "111",
      parent_id: "99",
      content_id: "111",
      title: "Netball",
      icon: "",
      href: {
        type: "Category",
        value: 111,
      },
    },
  },
  {
    id: "112",
    title: "Rugby",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "112",
      parent_id: "99",
      content_id: "112",
      title: "Rugby",
      icon: "",
      href: {
        type: "Category",
        value: 112,
      },
    },
  },
  {
    id: "113",
    title: "Running",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "113",
      parent_id: "99",
      content_id: "113",
      title: "Running",
      icon: "",
      href: {
        type: "Category",
        value: 113,
      },
    },
  },
  {
    id: "114",
    title: "Sepak Takraw",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "114",
      parent_id: "99",
      content_id: "114",
      title: "Sepak Takraw",
      icon: "",
      href: {
        type: "Category",
        value: 114,
      },
    },
  },
  {
    id: "115",
    title: "Squash and Badminton",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "115",
      parent_id: "99",
      content_id: "115",
      title: "Squash and Badminton",
      icon: "",
      href: {
        type: "Category",
        value: 115,
      },
    },
  },
  {
    id: "116",
    title: "Table Tennis",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "116",
      parent_id: "99",
      content_id: "116",
      title: "Table Tennis",
      icon: "",
      href: {
        type: "Category",
        value: 116,
      },
    },
  },
  {
    id: "117",
    title: "Tennis",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "117",
      parent_id: "99",
      content_id: "117",
      title: "Tennis",
      icon: "",
      href: {
        type: "Category",
        value: 117,
      },
    },
  },
  {
    id: "118",
    title: "Track & Field",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "118",
      parent_id: "99",
      content_id: "118",
      title: "Track & Field",
      icon: "",
      href: {
        type: "Category",
        value: 118,
      },
    },
  },
  {
    id: "119",
    title: "Volleyball",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "119",
      parent_id: "99",
      content_id: "119",
      title: "Volleyball",
      icon: "",
      href: {
        type: "Category",
        value: 119,
      },
    },
  },
  {
    id: "120",
    title: "Water Polo",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "120",
      parent_id: "99",
      content_id: "120",
      title: "Water Polo",
      icon: "",
      href: {
        type: "Category",
        value: 120,
      },
    },
  },
  {
    id: "121",
    title: "Wrestling",
    parent_id: "99",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "121",
      parent_id: "99",
      content_id: "121",
      title: "Wrestling",
      icon: "",
      href: {
        type: "Category",
        value: 121,
      },
    },
  },
  {
    id: "122",
    title: "Sports Medicine",
    parent_id: "88",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "122",
      parent_id: "88",
      content_id: "122",
      title: "Sports Medicine",
      icon: "",
      href: {
        type: "Category",
        value: 122,
      },
    },
  },
  {
    id: "123",
    title: "Braces and Support",
    parent_id: "122",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "123",
      parent_id: "122",
      content_id: "123",
      title: "Braces and Support",
      icon: "",
      href: {
        type: "Category",
        value: 123,
      },
    },
  },
  {
    id: "124",
    title: "Compression",
    parent_id: "122",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "124",
      parent_id: "122",
      content_id: "124",
      title: "Compression",
      icon: "",
      href: {
        type: "Category",
        value: 124,
      },
    },
  },
  {
    id: "125",
    title: "Tapes and Wraps",
    parent_id: "122",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "125",
      parent_id: "122",
      content_id: "125",
      title: "Tapes and Wraps",
      icon: "",
      href: {
        type: "Category",
        value: 125,
      },
    },
  },
  {
    id: "126",
    title: "New Arrival",
    parent_id: "122",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "126",
      parent_id: "122",
      content_id: "126",
      title: "New Arrival",
      icon: "",
      href: {
        type: "Category",
        value: 126,
      },
    },
  },
  {
    id: "127",
    title: "Sports Equipment",
    parent_id: "88",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "127",
      parent_id: "88",
      content_id: "127",
      title: "Sports Equipment",
      icon: "",
      href: {
        type: "Category",
        value: 127,
      },
    },
  },
  {
    id: "128",
    title: "Beach Volleyball Equipment",
    parent_id: "127",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "128",
      parent_id: "127",
      content_id: "128",
      title: "Beach Volleyball Equipment",
      icon: "",
      href: {
        type: "Category",
        value: 128,
      },
    },
  },
  {
    id: "129",
    title: "Football Equipment",
    parent_id: "127",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "129",
      parent_id: "127",
      content_id: "129",
      title: "Football Equipment",
      icon: "",
      href: {
        type: "Category",
        value: 129,
      },
    },
  },
  {
    id: "130",
    title: "Volleyball Equipment",
    parent_id: "127",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "130",
      parent_id: "127",
      content_id: "130",
      title: "Volleyball Equipment",
      icon: "",
      href: {
        type: "Category",
        value: 130,
      },
    },
  },
  {
    id: "131",
    title: "Track and Field Equipment",
    parent_id: "127",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "131",
      parent_id: "127",
      content_id: "131",
      title: "Track and Field Equipment",
      icon: "",
      href: {
        type: "Category",
        value: 131,
      },
    },
  },
  {
    id: "132",
    title: "Training Equipment",
    parent_id: "127",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "132",
      parent_id: "127",
      content_id: "132",
      title: "Training Equipment",
      icon: "",
      href: {
        type: "Category",
        value: 132,
      },
    },
  },
  {
    id: "133",
    title: "Basketball Court Equipment",
    parent_id: "127",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "133",
      parent_id: "127",
      content_id: "133",
      title: "Basketball Court Equipment",
      icon: "",
      href: {
        type: "Category",
        value: 133,
      },
    },
  },
  {
    id: "134",
    title: "Medical Equipment",
    parent_id: "127",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "134",
      parent_id: "127",
      content_id: "134",
      title: "Medical Equipment",
      icon: "",
      href: {
        type: "Category",
        value: 134,
      },
    },
  },
  {
    id: "135",
    title: "New Arrival",
    parent_id: "127",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "135",
      parent_id: "127",
      content_id: "135",
      title: "New Arrival",
      icon: "",
      href: {
        type: "Category",
        value: 135,
      },
    },
  },
  {
    id: "136",
    title: "Sports Accessories",
    parent_id: "88",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "136",
      parent_id: "88",
      content_id: "136",
      title: "Sports Accessories",
      icon: "",
      href: {
        type: "Category",
        value: 136,
      },
    },
  },
  {
    id: "137",
    title: "Table Tennis Ball",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "137",
      parent_id: "136",
      content_id: "137",
      title: "Table Tennis Ball",
      icon: "",
      href: {
        type: "Category",
        value: 137,
      },
    },
  },
  {
    id: "138",
    title: "Table Tennis Racket",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "138",
      parent_id: "136",
      content_id: "138",
      title: "Table Tennis Racket",
      icon: "",
      href: {
        type: "Category",
        value: 138,
      },
    },
  },
  {
    id: "139",
    title: "Table Tennis Rubber Pads",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "139",
      parent_id: "136",
      content_id: "139",
      title: "Table Tennis Rubber Pads",
      icon: "",
      href: {
        type: "Category",
        value: 139,
      },
    },
  },
  {
    id: "140",
    title: "Table Tennis Blades",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "140",
      parent_id: "136",
      content_id: "140",
      title: "Table Tennis Blades",
      icon: "",
      href: {
        type: "Category",
        value: 140,
      },
    },
  },
  {
    id: "141",
    title: "Table Tennis Nets",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "141",
      parent_id: "136",
      content_id: "141",
      title: "Table Tennis Nets",
      icon: "",
      href: {
        type: "Category",
        value: 141,
      },
    },
  },
  {
    id: "142",
    title: "Scoreboard",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "142",
      parent_id: "136",
      content_id: "142",
      title: "Scoreboard",
      icon: "",
      href: {
        type: "Category",
        value: 142,
      },
    },
  },
  {
    id: "143",
    title: "Rubber Care Sponge",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "143",
      parent_id: "136",
      content_id: "143",
      title: "Rubber Care Sponge",
      icon: "",
      href: {
        type: "Category",
        value: 143,
      },
    },
  },
  {
    id: "144",
    title: "Rubber Protector",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "144",
      parent_id: "136",
      content_id: "144",
      title: "Rubber Protector",
      icon: "",
      href: {
        type: "Category",
        value: 144,
      },
    },
  },
  {
    id: "145",
    title: "Water -Based Glue",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "145",
      parent_id: "136",
      content_id: "145",
      title: "Water -Based Glue",
      icon: "",
      href: {
        type: "Category",
        value: 145,
      },
    },
  },
  {
    id: "146",
    title: "Badminton Rackets",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "146",
      parent_id: "136",
      content_id: "146",
      title: "Badminton Rackets",
      icon: "",
      href: {
        type: "Category",
        value: 146,
      },
    },
  },
  {
    id: "147",
    title: "Badminton Strings",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "147",
      parent_id: "136",
      content_id: "147",
      title: "Badminton Strings",
      icon: "",
      href: {
        type: "Category",
        value: 147,
      },
    },
  },
  {
    id: "148",
    title: "Shuttlecocks",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "148",
      parent_id: "136",
      content_id: "148",
      title: "Shuttlecocks",
      icon: "",
      href: {
        type: "Category",
        value: 148,
      },
    },
  },
  {
    id: "149",
    title: "Tennis Strings",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "149",
      parent_id: "136",
      content_id: "149",
      title: "Tennis Strings",
      icon: "",
      href: {
        type: "Category",
        value: 149,
      },
    },
  },
  {
    id: "150",
    title: "Overgrip",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "150",
      parent_id: "136",
      content_id: "150",
      title: "Overgrip",
      icon: "",
      href: {
        type: "Category",
        value: 150,
      },
    },
  },
  {
    id: "151",
    title: "Towel Grip",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "151",
      parent_id: "136",
      content_id: "151",
      title: "Towel Grip",
      icon: "",
      href: {
        type: "Category",
        value: 151,
      },
    },
  },
  {
    id: "152",
    title: "Racket Bag",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "152",
      parent_id: "136",
      content_id: "152",
      title: "Racket Bag",
      icon: "",
      href: {
        type: "Category",
        value: 152,
      },
    },
  },
  {
    id: "153",
    title: "Sports Therapy Tape",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "153",
      parent_id: "136",
      content_id: "153",
      title: "Sports Therapy Tape",
      icon: "",
      href: {
        type: "Category",
        value: 153,
      },
    },
  },
  {
    id: "154",
    title: "Ball",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "154",
      parent_id: "136",
      content_id: "154",
      title: "Ball",
      icon: "",
      href: {
        type: "Category",
        value: 154,
      },
    },
  },
  {
    id: "155",
    title: "Net",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "155",
      parent_id: "136",
      content_id: "155",
      title: "Net",
      icon: "",
      href: {
        type: "Category",
        value: 155,
      },
    },
  },
  {
    id: "156",
    title: "Ball Cart",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "156",
      parent_id: "136",
      content_id: "156",
      title: "Ball Cart",
      icon: "",
      href: {
        type: "Category",
        value: 156,
      },
    },
  },
  {
    id: "157",
    title: "Whistle",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "157",
      parent_id: "136",
      content_id: "157",
      title: "Whistle",
      icon: "",
      href: {
        type: "Category",
        value: 157,
      },
    },
  },
  {
    id: "158",
    title: "Strategy Board",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "158",
      parent_id: "136",
      content_id: "158",
      title: "Strategy Board",
      icon: "",
      href: {
        type: "Category",
        value: 158,
      },
    },
  },
  {
    id: "159",
    title: "Ball Bag",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "159",
      parent_id: "136",
      content_id: "159",
      title: "Ball Bag",
      icon: "",
      href: {
        type: "Category",
        value: 159,
      },
    },
  },
  {
    id: "160",
    title: "Pressure Gauge",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "160",
      parent_id: "136",
      content_id: "160",
      title: "Pressure Gauge",
      icon: "",
      href: {
        type: "Category",
        value: 160,
      },
    },
  },
  {
    id: "161",
    title: "Antenna",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "161",
      parent_id: "136",
      content_id: "161",
      title: "Antenna",
      icon: "",
      href: {
        type: "Category",
        value: 161,
      },
    },
  },
  {
    id: "162",
    title: "Inflating Pump",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "162",
      parent_id: "136",
      content_id: "162",
      title: "Inflating Pump",
      icon: "",
      href: {
        type: "Category",
        value: 162,
      },
    },
  },
  {
    id: "163",
    title: "Ring Net",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "163",
      parent_id: "136",
      content_id: "163",
      title: "Ring Net",
      icon: "",
      href: {
        type: "Category",
        value: 163,
      },
    },
  },
  {
    id: "164",
    title: "Manual Scoreboard",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "164",
      parent_id: "136",
      content_id: "164",
      title: "Manual Scoreboard",
      icon: "",
      href: {
        type: "Category",
        value: 164,
      },
    },
  },
  {
    id: "165",
    title: "Height And Tension Gauge",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "165",
      parent_id: "136",
      content_id: "165",
      title: "Height And Tension Gauge",
      icon: "",
      href: {
        type: "Category",
        value: 165,
      },
    },
  },
  {
    id: "166",
    title: "Net Height Measurer",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "166",
      parent_id: "136",
      content_id: "166",
      title: "Net Height Measurer",
      icon: "",
      href: {
        type: "Category",
        value: 166,
      },
    },
  },
  {
    id: "167",
    title: "Desktop Ball Stand",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "167",
      parent_id: "136",
      content_id: "167",
      title: "Desktop Ball Stand",
      icon: "",
      href: {
        type: "Category",
        value: 167,
      },
    },
  },
  {
    id: "168",
    title: "Ball Cage",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "168",
      parent_id: "136",
      content_id: "168",
      title: "Ball Cage",
      icon: "",
      href: {
        type: "Category",
        value: 168,
      },
    },
  },
  {
    id: "169",
    title: "Bar Storage Rack",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "169",
      parent_id: "136",
      content_id: "169",
      title: "Bar Storage Rack",
      icon: "",
      href: {
        type: "Category",
        value: 169,
      },
    },
  },
  {
    id: "170",
    title: "Uprights",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "170",
      parent_id: "136",
      content_id: "170",
      title: "Uprights",
      icon: "",
      href: {
        type: "Category",
        value: 170,
      },
    },
  },
  {
    id: "171",
    title: "Safety Pads",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "171",
      parent_id: "136",
      content_id: "171",
      title: "Safety Pads",
      icon: "",
      href: {
        type: "Category",
        value: 171,
      },
    },
  },
  {
    id: "172",
    title: "New Arrival",
    parent_id: "136",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "172",
      parent_id: "136",
      content_id: "172",
      title: "New Arrival",
      icon: "",
      href: {
        type: "Category",
        value: 172,
      },
    },
  },
  {
    id: "173",
    title: "BRANDS",
    parent_id: "root",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "173",
      parent_id: "root",
      content_id: "173",
      title: "BRANDS",
      icon: "",
      href: {
        type: "Category",
        value: 173,
      },
    },
  },
  {
    id: "174",
    title: "Asics",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "174",
      parent_id: "173",
      content_id: "174",
      title: "Asics",
      icon: "",
      href: {
        type: "Category",
        value: 174,
      },
    },
  },
  {
    id: "175",
    title: "Asics Sportstyle",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "175",
      parent_id: "173",
      content_id: "175",
      title: "Asics Sportstyle",
      icon: "",
      href: {
        type: "Category",
        value: 175,
      },
    },
  },
  {
    id: "176",
    title: "Butterfly",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "176",
      parent_id: "173",
      content_id: "176",
      title: "Butterfly",
      icon: "",
      href: {
        type: "Category",
        value: 176,
      },
    },
  },
  {
    id: "177",
    title: "Gosen",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "177",
      parent_id: "173",
      content_id: "177",
      title: "Gosen",
      icon: "",
      href: {
        type: "Category",
        value: 177,
      },
    },
  },
  {
    id: "178",
    title: "Kindmax",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "178",
      parent_id: "173",
      content_id: "178",
      title: "Kindmax",
      icon: "",
      href: {
        type: "Category",
        value: 178,
      },
    },
  },
  {
    id: "179",
    title: "Marathon",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "179",
      parent_id: "173",
      content_id: "179",
      title: "Marathon",
      icon: "",
      href: {
        type: "Category",
        value: 179,
      },
    },
  },
  {
    id: "180",
    title: "Mikasa",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "180",
      parent_id: "173",
      content_id: "180",
      title: "Mikasa",
      icon: "",
      href: {
        type: "Category",
        value: 180,
      },
    },
  },
  {
    id: "181",
    title: "Molten",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "181",
      parent_id: "173",
      content_id: "181",
      title: "Molten",
      icon: "",
      href: {
        type: "Category",
        value: 181,
      },
    },
  },
  {
    id: "182",
    title: "Mueller",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "182",
      parent_id: "173",
      content_id: "182",
      title: "Mueller",
      icon: "",
      href: {
        type: "Category",
        value: 182,
      },
    },
  },
  {
    id: "183",
    title: "Nelco",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "183",
      parent_id: "173",
      content_id: "183",
      title: "Nelco",
      icon: "",
      href: {
        type: "Category",
        value: 183,
      },
    },
  },
  {
    id: "184",
    title: "Nittaku",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "184",
      parent_id: "173",
      content_id: "184",
      title: "Nittaku",
      icon: "",
      href: {
        type: "Category",
        value: 184,
      },
    },
  },
  {
    id: "185",
    title: "Senoh",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "185",
      parent_id: "173",
      content_id: "185",
      title: "Senoh",
      icon: "",
      href: {
        type: "Category",
        value: 185,
      },
    },
  },
  {
    id: "186",
    title: "Donjoy",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "186",
      parent_id: "173",
      content_id: "186",
      title: "Donjoy",
      icon: "",
      href: {
        type: "Category",
        value: 186,
      },
    },
  },
  {
    id: "187",
    title: "Donjoy Advantage",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "187",
      parent_id: "173",
      content_id: "187",
      title: "Donjoy Advantage",
      icon: "",
      href: {
        type: "Category",
        value: 187,
      },
    },
  },
  {
    id: "188",
    title: "Aircast",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "188",
      parent_id: "173",
      content_id: "188",
      title: "Aircast",
      icon: "",
      href: {
        type: "Category",
        value: 188,
      },
    },
  },
  {
    id: "189",
    title: "Action",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "189",
      parent_id: "173",
      content_id: "189",
      title: "Action",
      icon: "",
      href: {
        type: "Category",
        value: 189,
      },
    },
  },
  {
    id: "190",
    title: "EcoMed",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "190",
      parent_id: "173",
      content_id: "190",
      title: "EcoMed",
      icon: "",
      href: {
        type: "Category",
        value: 190,
      },
    },
  },
  {
    id: "191",
    title: "Procare",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "191",
      parent_id: "173",
      content_id: "191",
      title: "Procare",
      icon: "",
      href: {
        type: "Category",
        value: 191,
      },
    },
  },
  {
    id: "192",
    title: "Temtex",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "192",
      parent_id: "173",
      content_id: "192",
      title: "Temtex",
      icon: "",
      href: {
        type: "Category",
        value: 192,
      },
    },
  },
  {
    id: "193",
    title: "Onitsuka Tiger",
    parent_id: "173",
    content: {
      newColumn: false,
      cols: 6,
      mobileCols: 6,
      id: "193",
      parent_id: "173",
      content_id: "193",
      title: "Onitsuka Tiger",
      icon: "",
      href: {
        type: "Category",
        value: 193,
      },
    },
  },
];

export default (props) => {
  const { formData } = props;
  const websiteid =
    get(formData, "website_id", null) !== null
      ? `?website_id=${get(formData, "website_id", null)}`
      : "";
  const { isLoading, data } = useQuery(
    ["categories", websiteid],
    () => getCategories(websiteid),
    {
      cacheTime: cacheTimeMinute,
    }
  );

  // const { loading, data } = useQuery(`/categories${websiteid}`);
  const contentsData = useQuery(
    ["contents", websiteid],
    () => getContents(websiteid),
    {
      cacheTime: cacheTimeMinute,
    }
  );
  //useQuery(`/contents${websiteid}`);
  const classes = useStyles();
  const translate = useTranslate();
  const menuList = get(formData, "menu", defaultMenu);
  if (isLoading && contentsData.isLoading) {
    return <Loading />;
  }
  const newContents = orderBy(contentsData.data, ["title"], ["asc"]);
  const newCategories = orderBy(data, ["name"], ["asc"]);
  return (
    <Box width={[1]}>
      <SampleListMenu
        {...props}
        dataSource={menuList !== null ? menuList : []}
        translate={translate}
        classes={classes}
        categories={newCategories}
        contents={newContents}
      />
    </Box>
  );
};
