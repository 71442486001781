import React from "react";
import { Flex, Box, Text } from "rebass";
import { IconButton } from "@material-ui/core";
import ActionAdd from "@material-ui/icons/AddCircle";
import ActionDelete from "@material-ui/icons/Delete";
import {
  DialogCard,
  SelectInput,
  Title,
  PixelPercent,
  Counter,
  SketchColorPicker,
  DialogHrefContent,
  RTInput,
} from "../components";
import { get, includes } from "lodash";
import { UploadAvatar } from "../../../components";

export const toolbar = [["bold", "italic", "underline", "strike"]];

const dataType = [
  { text: "Text", value: "text" },
  { text: "Icon", value: "icon" },
];
const arrayRemove = (arr, value) => {
  arr.splice(value, 1);

  return arr;
};

export default (props) => {
  const { list, onSetValue } = props;

  const onAddItem = () => {
    let newMenu = list;
    newMenu.push({
      title: "",
      icon: "",
      widthIcon: "100px",
      type: "text",
      url: "",
      target: "",
      color: "#000000",
      link: {
        value: "",
        type: "URL",
        isLink: false,
        target: "",
      },
    });

    onSetValue("list", newMenu);
  };
  const handleItem = (key, value, index) => {
    let mylist = list;
    let newList = list[index];
    newList[key] = value;
    mylist[index] = newList;
    onSetValue("list", mylist);
  };
  const onDelete = (index) => {
    const newList = arrayRemove(list, index);
    onSetValue("list", newList);
  };
  const handleSave = () => {};

  const onSetItemLinkValue = (value, index) => {
    let mylist = list;
    mylist[index].link = value;
    onSetValue("list", mylist);
  };

  return (
    <Box width={[1]}>
      <DialogCard
        title={"Menu List"}
        titleButton={"Menu List"}
        onSave={() => handleSave()}
        id={"menu-list-view"}
        hiddenAction={true}
      >
        <Box width={[1]}>
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Box width={[1]}>
              <Text>Items</Text>
            </Box>
            <Box width={["60px"]}>
              <IconButton
                onClick={onAddItem}
                style={{ color: "rgb(0, 188, 212)" }}
              >
                <ActionAdd color={"inherit"} />
              </IconButton>
            </Box>
          </Flex>
          <Box width={[1]}>
            {list.map((item, index) => {
              let widthType = "%";
              let widthValue = item.widthIcon
                ? item.widthIcon.split("%").join("") * 1
                : 100;
              if (includes(item.widthIcon, "px")) {
                widthValue = item.widthIcon
                  ? item.widthIcon.split("px").join("") * 1
                  : 100;
                widthType = "px";
              }

              return (
                <Flex
                  key={index}
                  width={[1]}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  align={"center"}
                  style={{
                    paddingBottom: 4,
                    borderBottomWidth: 1,
                    borderBottomColor: "rgba(0,0,0,0.2)",
                    borderBottomStyle: "solid",
                  }}
                >
                  <Box width={[1]}>
                    <Flex width={[1]} flexDirection={"row"}>
                      <Box width={[1]}>
                        <Flex
                          width={[1]}
                          flexDirection={"row"}
                          sx={{
                            "&> *": {
                              p: [2],
                            },
                          }}
                        >
                          <SelectInput
                            id={`type-${index}`}
                            title={"Type"}
                            value={item.type}
                            list={dataType}
                            onChange={(e) =>
                              handleItem("type", e.target.value, index)
                            }
                          />
                        </Flex>
                        {item.type === "icon" && (
                          <Flex
                            width={[1]}
                            flexDirection={"row"}
                            sx={{
                              "&> *": {
                                p: [2],
                              },
                            }}
                          >
                            <Box width={[1]}>
                              <Title title='Upload Icon' />
                              <UploadAvatar
                                type={"icon"}
                                imageUrl={item.icon}
                                onUpdateUrl={(value) =>
                                  handleItem("icon", value, index)
                                }
                                title='Icon'
                                isSquare={true}
                              />
                            </Box>
                            <Box width={[1]}>
                              <PixelPercent
                                name='imagewidth'
                                value={widthType}
                                onChange={(e) =>
                                  handleItem(
                                    "widthIcon",
                                    `${widthValue}${e.target.value}`,
                                    index
                                  )
                                }
                                title='Width Icon'
                              />
                              <Flex
                                flexDirection={"row"}
                                width={[1]}
                                justifyContent={"flex-end"}
                              >
                                <Counter
                                  isDisabled={false}
                                  onSetValue={(value) =>
                                    handleItem(
                                      "widthIcon",
                                      `${value}${widthType}`,
                                      index
                                    )
                                  }
                                  value={widthValue}
                                  interval={1}
                                  maxValue={widthType === "%" ? 100 : 0}
                                />
                              </Flex>
                            </Box>
                          </Flex>
                        )}
                        <Box width={[1]} pb={[2]} pl={[2]}>
                          <Flex
                            width={[1]}
                            flexDirection={"row"}
                            sx={{
                              "&> *": {
                                p: [2],
                              },
                            }}
                          >
                            {item.type === "text" && (
                              <RTInput
                                input={
                                  <input
                                    type='text'
                                    name='title'
                                    value={item.title}
                                    onChange={(value) =>
                                      handleItem("title", value, index)
                                    }
                                  />
                                }
                                source='title'
                                label='Title'
                                style={{ width: 300 }}
                                toolbar={toolbar}
                              />
                              // <TextInput
                              //   style={{ width: 300 }}
                              //   value={item.title}
                              //   onChange={(value) =>
                              //     handleItem("title", value, index)
                              //   }
                              //   name={`title-${index}`}
                              //   id={`title-${index}`}
                              //   title='Title'
                              // />
                            )}
                            {item.type === "text" && (
                              <Box style={{ width: "256px" }}>
                                <SketchColorPicker
                                  title={"Color"}
                                  onUpdateColor={(color) =>
                                    handleItem("color", color, index)
                                  }
                                  color={get(item, "color", "#000000")}
                                />
                              </Box>
                            )}
                            <Box width={[1]}>
                              <DialogHrefContent
                                link={item.link}
                                onSetValue={(v) => onSetItemLinkValue(v, index)}
                                websiteId={props.websiteId}
                              />
                            </Box>
                          </Flex>
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                  <Box width={["60px"]}>
                    <IconButton
                      onClick={() => onDelete(index)}
                      style={{ color: "rgb(0, 188, 212)" }}
                    >
                      <ActionDelete color={"inherit"} />
                    </IconButton>
                  </Box>
                </Flex>
              );
            })}
          </Box>
        </Box>
      </DialogCard>
    </Box>
  );
};
