import { get } from "lodash";

// bgProps: {
//   show: false,
//   content: {
//     url: "",
//     size: "cover",
//     position: "center",
//     repeat: "no-repeat",
//     ratio: 1.2,
//   },
// },
// gradient: {
//   show: false,
//   content: {
//     bg1: "rgb(34,193,195)",
//     bg2: "rgba(253,187,45,1)",
//     type: "linear-gradient",
//     degre: 160,
//     linear: [0, 100],
//   },
// },

export const getStylGradiant = (values) => {
  const isShowGradiant = get(values, "gradient.show", false);
  const dataGradiant = get(values, "gradient.content", {
    bg1: "rgb(34,193,195)",
    bg2: "rgba(253,187,45,1)",
    type: "linear-gradient",
    degre: 160,
    linear: [0, 100],
  });

  return isShowGradiant ? dataGradiant : false;
};

export const getBGProps = (values) => {
  const isShowBg = get(values, "bgProps.show", false);
  const dataGradiant = get(values, "bgProps.content", {
    url: "",
    size: "cover",
    position: "center",
    repeat: "no-repeat",
    ratio: 1.2,
    height: "100%",
  });

  return isShowBg ? dataGradiant : false;
};
