import React from "react";
import { Counter } from "../counter";
import { Flex } from "rebass";
import { Title } from "../text";
export default (props) => {
  //{ span: 0, order: 0 }
  const { onSetValue, state } = props;
  const onSetValueLayout = (k, v) => {
    const newLayout = {
      ...state,
      [k]: v,
    };
    onSetValue(newLayout);
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Title title={"Span"} />
      <Counter
        onSetValue={(value) => onSetValueLayout("span", value)}
        value={state.span}
        interval={1}
        subtitle=''
        minValue={1}
      />
      <Title title={"Order"} />
      <Counter
        onSetValue={(value) => onSetValueLayout("order", value)}
        value={state.order}
        interval={1}
        subtitle=''
        minValue={1}
      />
    </Flex>
  );
};
