export default {
  pricebook: {
    title: "Price Book",
    new_title: "New Pricebook",
    edit_title: "Edit Pricebook",
    fields: {
      name: "Name",
      created_at: "Created On",
      active: "Active",
      site_id: "Site",
      start_at: "Start",
      end_at: "End",
    },
  },
}
