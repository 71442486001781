import React, { Fragment } from "react";
// import { Link } from "react-router-dom"
import { Box, Text, Image, Flex } from "rebass";
import ResponsiveImage from "../Layout/ResponsiveImage";
import Currency from "../../../libs/product/Currency";
import { getPhoto } from "../../../libs/product/utils";
import { HrefContainer } from "../../../libs/hrefUrl";
const defaultImage =
  "https://s3-ap-southeast-1.amazonaws.com/oleh-prod/assets/1/product/For-testing-only-UDO.png";
export const ImagePedro = ({
  styles = {},
  images,
  size = [1],
  screenWidth,
}) => {
  let photo =
    screenWidth <= 702
      ? images.urlMobile && images.urlMobile !== defaultImage
        ? images.urlMobile
        : images.url
      : images.url;
  if (photo === "") {
    photo = images.url;
  }
  return (
    <Box width={size}>
      <Image style={styles} src={photo} />
    </Box>
  );
};

export const ImageCarousel = ({
  styles = {},
  images,
  size = [1],
  screenWidth,
}) => {
  let photo =
    screenWidth <= 702
      ? images.urlMobile
        ? images.urlMobile
        : images.url
      : images.url;
  if (photo === "") {
    photo = images.url;
  }
  return (
    <Box width={size}>
      <Image style={styles} src={photo} m />
    </Box>
  );
};

export const ContentItemList = ({
  item,
  size = [1],
  pContainer = [0, 2],
  screenWidth,
}) => {
  const { imageUrl, link } = item;

  return (
    <Flex
      width={size}
      p={pContainer}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <HrefContainer link={link}>
        <ImagePedro
          size={[1]}
          images={imageUrl}
          test={"contentitem"}
          screenWidth={screenWidth}
        />
      </HrefContainer>
      {/* <Box p={[1]}>
        <LinkHref url={url}>
          <Text
            fontSize={["10px", "12px"]}
            color={"#747374"}
            style={{ lineHeight: "normal", textAlign: "center" }}
            fontWeight={"bold"}
          >
            {item.title}
          </Text>
        </LinkHref>
      </Box> */}
    </Flex>
  );
};

export const ContentItemProduct = ({
  item,
  size = [1],
  pContainer = [0, 2],
}) => {
  const { photos, slug, name } = item;
  // const { url } = href;
  const photo = getPhoto(photos);

  return (
    <Box width={size} p={pContainer}>
      <LinkHref url={`products/${slug}`}>
        <Box width={[1]} style={{ backgroundColor: "#FFFFFF" }}>
          <Image src={photo} alt={name} style={{ padding: 8 }} />
        </Box>
      </LinkHref>
      <Box style={{ backgroundColor: "#FFFFFF" }} pb={[2]}>
        <LinkHref url={`products/${slug}`}>
          <Box width={[1]}>
            <Title
              fontSize={["10px", "12px"]}
              color={"#747374"}
              style={{ lineHeight: "normal", textAlign: "center" }}
              fontWeight={"bold"}
            >
              {item.name}
            </Title>
            <Text
              fontSize={["10px", "12px"]}
              color={"#747374"}
              style={{ lineHeight: "normal", textAlign: "center" }}
              fontWeight={"bold"}
            >
              <Currency value={item.price} currency={"idr"} />
            </Text>
          </Box>
        </LinkHref>
      </Box>
    </Box>
  );
};

export const ContentItemCarousel = ({
  item,
  size = [1],
  pContainer = [0],
  screenWidth,
}) => {
  const { imageUrl, link } = item;

  return (
    <Box width={size} p={pContainer}>
      <HrefContainer link={link}>
        <ResponsiveImage
          src={imageUrl.url}
          srcMobile={imageUrl.urlMobile}
          srcDesktop={imageUrl.url}
        />
      </HrefContainer>
    </Box>
  );
};
export const ContentItemRow = ({ item, size = [1], pContainer = [0] }) => {
  const { imageUrl, link } = item;

  return (
    <div>
      <HrefContainer link={link}>
        <ResponsiveImage
          src={imageUrl.url}
          srcMobile={imageUrl.urlMobile}
          srcDesktop={imageUrl.url}
        />
      </HrefContainer>
    </div>
  );
};

export const ShowAllContent = ({
  url,
  fontSize,
  viewallColor = "#000000",
  title = "View All",
}) => {
  return (
    <Flex flexDirection={"row"} alignItems={"center"}>
      {/* <Text style={{ fontSize: `${fontSize}pt` }} fontWeight={"bold"} pr={[2]}>
        |
      </Text> */}
      <LinkHref url={url}>
        <Text
          style={{
            fontSize: `${fontSize}pt`,
            textTransform: "uppercase",
            color: viewallColor,
          }}
        >
          {title}
        </Text>
      </LinkHref>
    </Flex>
  );
};
const Title = (props) => (
  <Text
    {...props}
    sx={{
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      p: 2,
    }}
  />
);

export const LinkHref = ({ url, children, target }) => {
  if (url && url.includes("http")) {
    return (
      // <Fragment>{children}</Fragment>
      <a href={url} target={target ? target : ""}>
        {children}
      </a>
    );
  }
  return <Fragment>{children}</Fragment>;
  // return <Link to={url}>{children}</Link>
};
