export default {
  transactions: {
    name: "Transaction",
    fields: {
      ref: "Transaction ref",
      description: "Description",
      type: "Type",
      points: "Points",
      order_details: {
        ref: "Order number",
        total: "Amount",
      },
      created_at: "Transaction date",
    },
  },
}
