import React from "react"
import { Flex } from "rebass"
import { FontelloSvg } from "../../../../../components/Icons"
const SocialMediaList = (props) => {
  const { list, size, align } = props
  return (
    <Flex
      width={[1]}
      justifyContent={align}
      flexWrap={"wrap"}
      sx={{
        "&> *": {
          p: [2],
        },
      }}
    >
      {list &&
        list.length > 0 &&
        list.map((item, index) => (
          <FontelloSvg
            key={index}
            name={item.type}
            fill={item.color ? item.color : "#000000"}
            size={`${size}px`}
          />
        ))}
    </Flex>
  )
}

export default SocialMediaList
