import React from "react"
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd"
import ContainerDimensions from "react-container-dimensions"
import { connect } from "../../core"
import { CarouselList } from "./components/carouselAnimation"
import { target } from "./utils"

const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
  widthScreen,
}) => {
  const widthContent = widthScreen ? widthScreen - widthScreen / 4 : "100%"

  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "auto",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        flex: 1,
      }}
    >
      <ContainerDimensions>
        {({ height }) => {
          // console.log(widthContent, height)
          return (
            <CarouselList
              data={layoutState.getItemJS(id).props.values}
              widthContainer={widthContent}
              heightContainer={height}
            />
          )
        }}
      </ContainerDimensions>
    </div>
  )
}

const LayoutContainer = DropTarget(
  "CarouselAnimationView",
  target,
  (conn, monitor) => ({
    connectDropTarget: conn.dropTarget(),
    isOverCurrent: monitor.isOver({ shallow: true }),
  })
)(EditLayout)

export default connect(
  "layoutState",
  "readOnly",
  "widthScreen"
)(LayoutContainer)
