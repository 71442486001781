import React from "react";
import { SketchColorPicker, UploadImage } from "../../../components";
import { RTInput, Counter, BlockCounter } from "../components";
import { PanelCard, ContentDetail } from "../actions";
const toolbar = [
  [{ font: [false, "Rajdhani", "Manrope", "Roboto", "serif", "monospace"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ size: ["small", false, "large", "huge"] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["blockquote", "code-block"],
  ["clean"],
];

export const PanelText = (props) => {
  const { state, onSetValue } = props;
  return (
    <PanelCard title={"Content Text"} id={"content-of-text"}>
      <ContentDetail>
        <RTInput
          input={
            <input
              type='text'
              name='name'
              value={state.valueText}
              onChange={(value) => onSetValue("valueText", value)}
            />
          }
          source='body'
          label='Body'
          toolbar={toolbar}
        />
      </ContentDetail>
      <BlockCounter
        title='Text Padding'
        containerPadding={state.containerPaddingText}
        onUpdatePadding={(containerPadding) =>
          onSetValue("containerPaddingText", containerPadding)
        }
      />
      <ContentDetail>
        <SketchColorPicker
          title={"Background Text"}
          onUpdateColor={(color) => onSetValue("backgroundColorText", color)}
          color={state.backgroundColorText}
        />
      </ContentDetail>
      <ContentDetail>
        <Counter
          isDisabled={true}
          title='Line Height'
          onSetValue={(value) => onSetValue("lineHeight", value)}
          value={state.lineHeight}
          interval={10}
          subtitle='%'
          minValue={100}
        />
      </ContentDetail>
      <ContentDetail>
        <Counter
          isDisabled={false}
          title='Letter Spacing'
          onSetValue={(value) => onSetValue("letterSpacing", value)}
          value={state.letterSpacing}
          interval={1}
          subtitle='px'
          minValue={-5}
        />
      </ContentDetail>
    </PanelCard>
  );
};

export const PanelGeneral = (props) => {
  const { state, onSetValue, type } = props;
  return (
    <PanelCard title={"Content Image"} id={"content-of-image"}>
      <ContentDetail>
        <UploadImage
          type={type}
          imageUrl={state.backgroundImage}
          onUpdateUrl={(value) => onSetValue("backgroundImage", value)}
          title='Image'
        />
      </ContentDetail>

      <BlockCounter
        title='Container Padding'
        containerPadding={state.containerPadding}
        onUpdatePadding={(containerPadding) =>
          onSetValue("containerPadding", containerPadding)
        }
      />
    </PanelCard>
  );
};
