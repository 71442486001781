import React, { useEffect } from "react";
import { get } from "lodash";
import { useQueryWithStore, Loading } from "react-admin";
import { BetterCustomSimpleFormLayout, EditToolbar } from "../../../components";
import { EditFormFields } from "./EditFormFields";
import { useStoreTemp } from "./utils";
export const EditForm = (props) => {
  const { initialValues } = props;
  const { setSettingTemp } = useStoreTemp();
  useEffect(() => {
    setSettingTemp(initialValues);
  }, [initialValues, setSettingTemp]);

  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "categories",
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "id", order: "ASC" },
      filter: { website_id: get(props, "initialValues.website_id") },
    },
  });
  if (loading) {
    return <Loading />;
  }
  return (
    <BetterCustomSimpleFormLayout
      {...props}
      title={"resources.layouts.back"}
      toolbar={<EditToolbar />}
      isShowBack={true}
    >
      <EditFormFields {...props} isCreate={false} categories={data} />
    </BetterCustomSimpleFormLayout>
  );
};
