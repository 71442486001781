import React from "react";
import Slider from "react-slick";
import { Box, Flex, Text } from "rebass";
import { get } from "lodash";
import { FontelloSvg } from "../../../libs/icons";
import { ShowAllContent } from "./Items";
import ContentItemLSV from "./ContentItemLSV";
const defaultSetting = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 200,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  rows: 0,
  slidesPerRow: 0,
  autoplay: false,
  autoplaySpeed: false,
};

let settingsList = (setting, screenWidth, onChange) => {
  if (setting) {
    return {
      dots: setting.dots,
      infinite: setting.infinite,
      speed: setting.speed,
      arrows: setting.arrows,
      slidesToShow:
        screenWidth > 600
          ? setting.slidesToShow
          : setting.slidesToShow > 3
          ? 3
          : setting.slidesToShow,
      slidesToScroll: setting.slidesToScroll,
      autoplay: setting.autoplay,
      autoplaySpeed: setting.autoplaySpeed,
      afterChange: (index) => onChange(index),
    };
  }

  return {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 200,
    slidesToShow:
      screenWidth > 600
        ? setting.slidesToShow
        : setting.slidesToShow > 3
        ? 3
        : setting.slidesToShow,
    slidesToScroll: 1,
    afterChange: (index) => onChange(index),
  };
};

class ListSlideMan extends React.Component {
  state = {
    isIndex: 0,
    isEnd: false,
  };
  onChange = (index) => {
    const { valueText, desktop } = this.props;
    const slidesToShow = get(desktop, "setting.slidesToShow", defaultSetting);
    const addIndex = slidesToShow; //screenWidth > 702 ? 6 : 3;
    const currentIndex = index + addIndex;
    let isEndCurrent = false;
    if (currentIndex >= valueText.length) {
      isEndCurrent = true;
    }
    this.setState({
      isIndex: index,
      isEnd: isEndCurrent,
    });
  };
  next() {
    if (!this.state.isEnd) {
      this.sliderone.slickNext();
    }
  }
  previous() {
    // console.log(this.state.isIndex, this.state.isEnd);
    if (this.state.isIndex > 0) {
      this.sliderone.slickPrev();
      // this.setState({
      //   isEnd: false,
      // });
    }
  }
  render() {
    const {
      screenWidth,
      title,
      desktop,
      valueText,
      colorProps,
      urlViewAll,
    } = this.props;
    const fontSizeHeader = get(desktop, "header.fontSize", 16);
    const showHeader = get(desktop, "header.show", false);
    const showViewAllHeader = get(desktop, "header.showViewAll", false);
    const settings = settingsList(
      get(desktop, "setting", defaultSetting),
      screenWidth,
      (index) => this.onChange(index)
    );

    const colorLeft = this.state.isIndex === 0 ? "#e5e3e6" : "#747374";
    const colorRight = this.state.isEnd ? "#e5e3e6" : "#747374";
    return (
      <Box style={{ width: screenWidth }}>
        {showHeader && (
          <Flex
            width={[1]}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Flex
              width={[1]}
              justify={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text
                style={{ fontSize: `${fontSizeHeader}pt` }}
                fontWeight={"bold"}
              >
                {title}
              </Text>
              {showViewAllHeader && (
                <ShowAllContent
                  url={urlViewAll}
                  viewallColor={get(colorProps, "viewallColor", "#000000")}
                />
              )}
            </Flex>

            <Flex justify={"row"} pl={[2]} alignItems={"center"} pt={[1]}>
              <Box pr={[1]}>
                <FontelloSvg
                  fill={colorLeft}
                  name={"leftSquare"}
                  size={"24px"}
                  onClick={() => this.previous()}
                  style={{ cursor: "pointer" }}
                />
              </Box>
              <Box>
                <FontelloSvg
                  fill={colorRight}
                  name={"rightSquare"}
                  size={"24px"}
                  onClick={() => this.next()}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Flex>
          </Flex>
        )}
        <div style={{ backgroundColor: "transparent" }}>
          <Slider
            ref={(c) => (this.sliderone = c)}
            {...settings}
            style={{ backgroundColor: "tranparent" }}
          >
            {valueText.map((item, index) => {
              return (
                <ContentItemLSV
                  item={item}
                  key={index}
                  size={[1]}
                  screenWidth={screenWidth}
                  colorProps={colorProps}
                  desktop={desktop}
                />
              );
            })}
          </Slider>
        </div>
      </Box>
    );
  }
}

export default ListSlideMan;
