import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/Accordion";
import MuiExpansionPanelSummary from "@material-ui/core/AccordionSummary";
import MuiExpansionPanelDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { Text } from "rebass";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));
const PanelCard = ({ children, title, id }) => {
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Text>{title}</Text>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
const PanelCardView = ({ children, title, id, expanded = false }) => {
  return (
    <ExpansionPanelView expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Text>{title}</Text>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
    </ExpansionPanelView>
  );
};
const ContainerPanel = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

const ExpansionPanel = withStyles({
  root: {
    width: "100%",
    border: "0px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);
const ExpansionPanelView = withStyles({
  root: {
    width: "100%",
    border: "0px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "0px",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .125)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    minHeight: 48,
    "&$expanded": {
      minHeight: 48,
    },
  },
  content: {
    margin: "12px 0px",
    fontSize: "bold",
    "&$expanded": {
      margin: "12px 0px",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    flexDirection: "column",
    overflowY: "auto",
    padding: theme.spacing(1),
  },
}))(MuiExpansionPanelDetails);

export { PanelCard, ContainerPanel, PanelCardView };
