import React from "react";
import { showNotification, useQueryWithStore } from "react-admin";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import { Box } from "rebass";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { EditForm } from "./components";
import { FullLoadingScreen, CardPanel } from "../../components";

const WebsiteEdit = (props) => {
  const { match } = props;
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "websites",
    payload: { id: match.params.id },
  });

  if (loading) {
    return <FullLoadingScreen />;
  }
  const siteId = get(data, "site_id", 0);
  return (
    <CardPanel>
      <Box width={[1]}>
        <EditForm
          {...props}
          sourceApi='websites'
          typePostUrl={"UPDATE"}
          pathUrl='websites'
          initialValues={data}
          customBackUrl={`/sites/${siteId}/websites`}
        />
      </Box>
    </CardPanel>
  );
};

const enhance = compose(withRouter, connect(undefined, { showNotification }));

export default enhance(WebsiteEdit);
