import styled from "styled-components"
export const DivEmpty = styled.div`
  background-color: rgba(222, 242, 250, 0.85);
  outline: 1px dashed #2faade;
  min-height: 100px;
  font-size: 13px;
  color: #2faade;
  text-align: center;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  &:hover {
    outline: 2px solid #7aabc0;
  }
`

export const DivEmptyContent = styled.div`
  background-color: rgba(222, 242, 250, 0.85);
  outline: 1px dashed #2faade;
  min-height: 100px;
  flex: 1;
  font-size: 13px;
  color: #2faade;
  margin: 10px;
  padding: 10px;
  width: 80%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DivTitleDrag = styled.div`
  &::before {
    content: "drag row at here";
    font-size: 11px;
    color: #ffffff;
    background-color: #4cb9ea;
    line-height: 10px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -50px;
    width: 100px;
    text-align: center;
    padding: 5px;
    z-index: 2010;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
`
export const DivTitleDragContent = styled.div`
  &::before {
    content: "drag at here";
    font-size: 11px;
    color: #ffffff;
    background-color: #4cb9ea;
    line-height: 10px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -50px;
    width: 80px;
    text-align: center;
    padding: 5px;
    z-index: 2010;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    padding: 10px;
    justify-content: center;
  }
`

export const DivHover = styled.div`
  z-index: 2010;
  position: relative;
  width: 100%;
  &:hover {
    z-index: 2100;
    outline: 2px solid #7aabc0;
  }
`

export const Image = styled.img`
  opacity: 1;
  display: block;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`
