export default {
  clients: {
    name: "Clients",
    tabs: {
      client_info: "Client Info",
      programmes: "Programmes",
    },
    fields: {
      account: "Account",
      active: "Active",
      name: "Name",
      uen: "UEN",
      address: "Business Address",
      contact_person: "Contact Person",
      email: "Contact Email",
      phone: "Contact Phone",
      payment_terms: "Payment Terms",
      payment_mode: "Payment Mode",
      remarks: "Remarks",
      created_at: "Create On",
    },
  },
}
