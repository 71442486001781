import React from "react";
import {
  DialogIconTemplate,
  TextInput,
  BlockCounter,
  Counter,
  SelectInput,
  SwitchInput,
  Title,
} from "../components";
import { SketchColorPicker } from "../../../components";
import { ContainerPanel, ContentDetail, PanelCard } from "../actions";
import { Flex } from "rebass";
import { dataTypeBorder, typeButton, datalabelPosition } from "./dataList";

export default (props) => {
  const { state, onSetValue } = props;

  const updateBorderContent = (k, v) => {
    const newBorder = {
      ...state.border,
      [k]: v,
    };
    onSetValue("border", newBorder);
  };

  return (
    <ContainerPanel>
      <PanelCard title={"General 1"} id={"flat-general-1"}>
        <ContentDetail>
          <SelectInput
            id={`type-flat-button`}
            title={"Type Button"}
            value={state.typeButton}
            list={typeButton}
            onChange={(e) => onSetValue("typeButton", e.target.value)}
          />
          <TextInput
            value={state.text}
            onChange={(value) => onSetValue("text", value)}
            name='textButton'
            title='Button Text'
            fullWidth={true}
          />
          <SelectInput
            id={`label-position-flat-button`}
            title={"Label Position"}
            value={state.labelPosition}
            list={datalabelPosition}
            onChange={(e) => onSetValue("labelPosition", e.target.value)}
          />
        </ContentDetail>
      </PanelCard>
      <PanelCard title={"General 2"} id={"flat-general-2"}>
        <ContentDetail>
          <Title title={"Show Button"} />
          <Flex
            flexDirection={"column"}
            width={[1]}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
          >
            <SwitchInput
              id={`showDesktop`}
              title='Show on Desktop'
              checked={state.isShowDesktop}
              onChange={(e) => onSetValue("isShowDesktop", e.target.checked)}
            />
            <SwitchInput
              id={`showMobile`}
              title='Show on Mobile'
              checked={state.isShowMobile}
              onChange={(e) => onSetValue("isShowMobile", e.target.checked)}
            />
          </Flex>
          <DialogIconTemplate
            iconItem={state.icon}
            onItemSelected={(icon) => {
              onSetValue("icon", icon);
            }}
          />
        </ContentDetail>
        <BlockCounter
          containerPadding={state.containerPadding}
          onUpdatePadding={(containerPadding) =>
            onSetValue("containerPadding", containerPadding)
          }
          title={"Container Padding"}
        />

        <ContentDetail style={{ paddingBottom: 20 }}>
          <Title title={"Border"} />
          <SketchColorPicker
            title={"Color"}
            onUpdateColor={(color) => updateBorderContent("color", color)}
            color={state.border.color}
          />
          <Counter
            isDisabled={false}
            title='Size'
            onSetValue={(value) => updateBorderContent("size", value)}
            value={state.border.size}
            interval={1}
            subtitle='px'
            minValue={0}
          />
          <SelectInput
            id={`border-button`}
            title={"Type Border"}
            value={state.border.type}
            list={dataTypeBorder}
            onChange={(e) => updateBorderContent("type", e.target.value)}
          />
          <Counter
            isDisabled={false}
            title='Border Radius'
            onSetValue={(value) => updateBorderContent("radius", value)}
            value={state.border.radius}
            interval={1}
            subtitle='px'
            minValue={0}
          />
        </ContentDetail>
      </PanelCard>
    </ContainerPanel>
  );
};
