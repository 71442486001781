export default {
  filters: {
    title: "Filters",
    back: "Back to Filters",
    new_filters: "New Filters",
    edit_filters: "Edit Filters",
    fields: {
      name: "Name",
      first_step: "First Step",
      last_step: "Last Step",
      interval: "Interval Steps",
      type: "Data Type",
      attribute: "Attribute",
      filters: "Filters",
      automatics: "Automatics",
      empty: "",
    },
    error: {
      selected: "Attribute Already Selected",
      not_selected: "Attribute not selected",
    },
    note_text: {
      is_filter:
        "Filter options to be retrieved from an unique list of attributes from the product",
      filter: "Filter options to be made available for this attribute",
      first_step: "First interval",
      last_step: "Last interval",
      interval: "Number of steps between intervals",
    },
  },
}
