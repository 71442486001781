import React from "react";
import { get } from "lodash";
import { Box, Flex, Text } from "rebass";
import { useTranslate, NumberInput, BooleanInput } from "react-admin";
import {
  ColorThemePicker,
  RaTextInput,
  RaSelectArrayChipInput,
} from "../../../components";
import BannerContent from "./BannerContent";
const defaultColor = {
  text: "#626163",
  background: "#EFEFEF",
  primary: "#6B5B37",
  secondary: "#C3C0B6",
  highlight: "#F1E656",
  accent: "#E23098",
  accent1: "#4A90E2",
  accent2: "#3F5498",
  muted: "#F2F1EF",
  error: "#FF0000",
};
// const defaultBanner = {
//   login: {
//     urlImg: "",
//     link: {
//       value: "",
//     },
//   },
// };
const ThemesContent = (props) => {
  const { formData, updateField } = props;
  const translate = useTranslate();
  const colors = get(formData, "theme.colors", defaultColor);
  // const banners = get(formData, "theme.banners", defaultBanner);
  const onSetValue = (key, value) => {
    const myColors = colors;
    myColors[key] = value;
    updateField("theme.colors", myColors);
  };
  // const onSetBannerValue = (key, value) => {
  //   const myBanners = banners;
  //   myBanners[key] = value;
  //   updateField("theme.banners", myBanners);
  // };
  // console.log("formData", formData);
  return (
    <Box width={[1]} px={[2]} style={{ overflowY: "hidden" }}>
      <Box p={[2]}>
        <NumberInput
          source={"theme.header.height"}
          InputLabelProps={{ shrink: true }}
          label={"resources.layouts.fields.header.height"}
        />
        <BooleanInput
          source={"theme.header.isFixedMobile"}
          defaultValue={true}
          label={"resources.layouts.fields.header.isFixedMobile"}
        />
        <RaTextInput
          source={"theme.urlGoogleFont"}
          label={"resources.layouts.fields.urlGoogleFont"}
          fullWidth={true}
          notetext={"resources.layouts.messages.urlGoogleFont"}
        />
        <RaSelectArrayChipInput
          source={"theme.bodyFontFamily"}
          label={"resources.layouts.fields.bodyFontFamily"}
          updateField={updateField}
          fullWidth={true}
          formData={formData}
          notetext={"resources.layouts.messages.bodyFontFamily"}
        />
        {/* urlGoogleFont:'Url Google Font',<Text fontSize={[2]} fontWeight={"bold"}>
          {translate("resources.layouts.fields.header.height")}
        </Text> */}
      </Box>
      <Box p={[2]}>
        <Text fontSize={[2]} fontWeight={"bold"}>
          {translate("resources.layouts.fields.themes_colors")}
        </Text>
      </Box>
      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap='wrap'
        sx={{
          "&>*": {
            p: [2],
          },
        }}
      >
        <Box width={[1 / 4]} p={[1]}>
          <Container>
            <ColorThemePicker
              title={translate("resources.layouts.fields.colors.text")}
              onUpdateColor={(color) => onSetValue("text", color)}
              color={colors.text}
            />
          </Container>
        </Box>
        <Box width={[1 / 4]} p={[1]}>
          <Container>
            <ColorThemePicker
              title={translate("resources.layouts.fields.colors.background")}
              onUpdateColor={(color) => onSetValue("background", color)}
              color={colors.background}
            />
          </Container>
        </Box>
        <Box width={[1 / 4]} p={[1]}>
          <Container>
            <ColorThemePicker
              title={translate("resources.layouts.fields.colors.primary")}
              onUpdateColor={(color) => onSetValue("primary", color)}
              color={colors.primary}
            />
          </Container>
        </Box>
        <Box width={[1 / 4]} p={[1]}>
          <Container>
            <ColorThemePicker
              title={translate("resources.layouts.fields.colors.secondary")}
              onUpdateColor={(color) => onSetValue("secondary", color)}
              color={colors.secondary}
            />
          </Container>
        </Box>
        <Box width={[1 / 4]} p={[1]}>
          <Container>
            <ColorThemePicker
              title={translate("resources.layouts.fields.colors.accent")}
              onUpdateColor={(color) => onSetValue("accent", color)}
              color={colors.accent}
            />
          </Container>
        </Box>
        <Box width={[1 / 4]} p={[1]}>
          <Container>
            <ColorThemePicker
              title={translate("resources.layouts.fields.colors.accent1")}
              onUpdateColor={(color) => onSetValue("accent1", color)}
              color={colors.accent1}
            />
          </Container>
        </Box>
        <Box width={[1 / 4]} p={[1]}>
          <Container>
            <ColorThemePicker
              title={translate("resources.layouts.fields.colors.accent2")}
              onUpdateColor={(color) => onSetValue("accent2", color)}
              color={colors.accent2}
            />
          </Container>
        </Box>
        <Box width={[1 / 4]} p={[1]}>
          <Container>
            <ColorThemePicker
              title={translate("resources.layouts.fields.colors.muted")}
              onUpdateColor={(color) => onSetValue("muted", color)}
              color={colors.muted}
            />
          </Container>
        </Box>
        <Box width={[1 / 4]} p={[1]}>
          <Container>
            <ColorThemePicker
              title={translate("resources.layouts.fields.colors.error")}
              onUpdateColor={(color) => onSetValue("error", color)}
              color={colors.error}
            />
          </Container>
        </Box>
      </Flex>
      <Box py={[3]} px={[2]}>
        <Text fontSize={[2]} fontWeight={"bold"}>
          {translate("resources.layouts.fields.banner.title")}
        </Text>
        <Container>
          <BannerContent
            {...props}
            sourceBanner={"theme.banners.login"}
            title={"resources.layouts.fields.banner.login.title"}
            urlTitle={"resources.layouts.fields.banner.login.url"}
            // onUpdateBanner={(key, value) => onSetBannerValue(key, value)}
          />
        </Container>
      </Box>
      {/* <Box p={[2]} style={{ height: 100 }} /> */}
    </Box>
  );
};

const Container = (props) => (
  <Box
    {...props}
    sx={{
      p: "4px",
      pl: "16px",
      border: " 1px solid rgba(0, 0, 0, 0.2)",
      borderRadius: "8px",
    }}
  />
);

export default ThemesContent;
