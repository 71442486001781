import React, { Component } from "react"
import PropTypes from "prop-types"
import { Flex } from "rebass"
import { UploadImageArray } from "../../../components"
import {
  TextInput,
  Counter,
  Direction,
  Title,
  SwitchInput,
} from "../components"
import {
  FooterAction,
  ContainerPanel,
  PanelCard,
  ContentDetail,
} from "../actions"
import { BoxContent } from "../styled"

class CarouselView extends Component {
  constructor(props) {
    super(props)
    const { imageUrl, altText, auto, interval, direction } = props.data.values
    this.state = {
      heightContent: props.heightContent,
      widthContent: props.widthContent,
      isEditMode: false,
      imageDesktop: imageUrl.url,
      imageMobile: imageUrl.urlMobile,
      altText: altText,
      direction: direction,
      auto: auto,
      interval: interval,
    }
  }

  onChangeDirection = (event, value) => {
    this.setState({ direction: event.target.value })
  }
  onUpdateUrlDesktop = value => {
    this.setState({ imageDesktop: value })
  }
  onUpdateUrlMobile = value => {
    this.setState({ imageMobile: value })
  }
  onUpdateAltText = value => {
    this.setState({ altText: value })
  }
  onToggle = event => {
    this.setState({ auto: event.target.checked })
  }
  onUpdateInterval = value => {
    this.setState({ interval: value })
  }
  onClickCopy = () => {
    let newData = this.props.data
    let imageUrl = {
      url: this.state.imageDesktop,
      urlMobile: this.state.imageMobile,
    }
    newData.values.altText = this.state.altText
    newData.values.imageUrl = imageUrl
    newData.values.auto = this.state.auto
    newData.values.direction = this.state.direction
    newData.values.interval = this.state.interval
    localStorage.setItem("CarouselView", JSON.stringify(newData.values))
  }
  onClickPaste = () => {
    const imageContent = JSON.parse(localStorage.getItem("CarouselView"))
    if (imageContent) {
      const { imageUrl, altText, auto, direction, interval } = imageContent
      this.setState({
        imageDesktop: imageUrl.url,
        imageMobile: imageUrl.urlMobile,
        altText: altText,
        auto,
        direction,
        interval,
      })
    }
  }
  onClickSave = () => {
    let newData = this.props.data
    let imageUrl = {
      url: this.state.imageDesktop,
      urlMobile: this.state.imageMobile,
    }
    newData.values.altText = this.state.altText
    newData.values.imageUrl = imageUrl
    newData.values.auto = this.state.auto
    newData.values.direction = this.state.direction
    newData.values.interval = this.state.interval
    this.props.onUpdateData(newData)
  }

  render() {
    // const {type} = this.props.data.values;

    return (
      <Flex width={[1]} flexDirection={"column"}>
        <BoxContent style={{ height: this.state.heightContent - 120 }}>
          <ContainerPanel>
            <PanelCard
              title="Image Desktop"
              id={"group-image-carousel-view-desktop"}
            >
              <ContentDetail>
                <Title title="Content of Desktop" />
                <UploadImageArray
                  type="desktop"
                  imageUrl={this.state.imageDesktop}
                  onUpdateUrl={value => this.onUpdateUrlDesktop(value)}
                  title="Desktop"
                />
              </ContentDetail>
            </PanelCard>
            <PanelCard
              title="Image Mobile"
              id={"group-image-carousel-view-mobile"}
            >
              <ContentDetail>
                <Title title="Content of Mobile" />
                <UploadImageArray
                  type="mobile"
                  imageUrl={this.state.imageMobile}
                  onUpdateUrl={value => this.onUpdateUrlMobile(value)}
                  title="Mobile"
                />
              </ContentDetail>
            </PanelCard>

            <PanelCard title="General" id={"general-carousel-view"}>
              <ContentDetail>
                <TextInput
                  value={this.state.altText}
                  onChange={value => this.onUpdateAltText(value)}
                  title="Alternatif Text"
                />
              </ContentDetail>
              <ContentDetail>
                <Flex width={[1]} flexDirection={"column"}>
                  <Direction
                    name="direction"
                    title="Direction"
                    value={this.state.direction}
                    onChange={this.onChangeDirection}
                  />
                  <SwitchInput
                    id={`auto-play`}
                    title={"Auto Play"}
                    checked={this.state.auto}
                    onChange={e => this.onToggle(e)}
                  />
                  <Counter
                    isDisabled={false}
                    title="Interval"
                    onSetValue={value => this.onUpdateInterval(value)}
                    value={this.state.interval}
                    interval={1}
                    subtitle="second"
                    minValue={0}
                  />
                </Flex>
              </ContentDetail>
            </PanelCard>
          </ContainerPanel>
        </BoxContent>
        <FooterAction
          onClickSave={this.onClickSave}
          onClickCopy={this.onClickCopy}
          onClickPaste={this.onClickPaste}
          widthContent={this.state.widthContent}
        />
      </Flex>
    )
  }
}

CarouselView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
}

CarouselView.defaultProps = {
  data: {},
}
export default CarouselView
