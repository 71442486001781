import React from "react";
import { Flex, Box, Image, Text } from "rebass";
import SearchBox from "./SearchBox";
import Menu from "./Menu";
import { Badge } from "antd";
import { get } from "lodash";
import { FontelloSvg } from "./Icons";
import { getSetLogoStyle } from "./utils";
const Desktop2 = ({ settings, cart, categories, widthScreen }) => {
  const { items } = cart;
  const colorPrimary = "#CACACA";
  const styleLogo = getSetLogoStyle(
    get(settings, "header.containerLogo"),
    widthScreen
  );
  const indexView = widthScreen > 600 ? 1 : 0;
  const isShowMenu = get(
    settings,
    `header.isShowMenu.show[${indexView}]`,
    true
  );
  return (
    <Box width={[1]} bg={"secondary"}>
      <Flex
        width={[1]}
        sx={{
          height: "84px",
        }}
      >
        <Box width={[1]} bg={"secondary"}>
          <Flex
            width={[1, 1]}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={[2]}
          >
            <Flex width={[1]} flexDirection={"row"} alignItems={"center"}>
              <Image
                sx={{ ...styleLogo, mr: [2] }}
                src={settings.header.image}
                alt='logo'
              />

              {isShowMenu && (
                <Menu
                  isMobile={false}
                  settings={settings}
                  categories={categories}
                  style={{ marginBottom: 0 }}
                />
              )}
            </Flex>
            <Flex style={{ zIndex: 2 }} flexDirection={"row"}>
              <SearchBox width={"300px"} />
              <Flex flexDirection={"column"} alignItems={"center"} px={[0, 3]}>
                <Badge count={items.length + 3} style={{ display: "flex" }}>
                  <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    pb={["1px"]}
                  >
                    <FontelloSvg
                      name={"shoppingCart"}
                      fill={colorPrimary}
                      size={"22px"}
                    />

                    <Text fontSize={[0]} pt={[1]}>
                      Cart
                    </Text>
                  </Flex>
                </Badge>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Desktop2;
