import React from "react";
import {
  Datagrid,
  Edit,
  Create,
  Toolbar,
  SaveButton,
  TopToolbar,
  Show,
} from "react-admin";
import { Flex } from "rebass";
import { withStyles } from "@material-ui/core/styles";
import {
  SimpleOlehForm,
  TabbedOlehForm,
  CustomSimpleOlehForm,
  CustomSimpleOlehFormLayout,
  TabbedCustomForm,
  SimpleFormCustomCreate,
} from "./form";
import { useStyles, useStylesTable, stylesFormContainer } from "./utils";
import { ListButton } from "./buttons";
import { List } from "./ra-list";
import { Title } from "./Text";
import { BOX_SHADOW_COLOR } from "../utils";
import { EditDeleteButton } from "./BetterButton";

const betterRowStyle = (record, index) => ({
  borderBottomWidth: 0,
  borderWidth: 0,
  padding: 0,
});

export const BetterList = (props) => {
  const { classes: classesOverride } = props;
  const classes = useStyles({ classes: classesOverride });
  const classesT = useStylesTable({ classes: classesOverride });
  return (
    <List bulkActionButtons={false} {...props} classes={classes}>
      <Datagrid rowStyle={betterRowStyle} classes={classesT} empty={"false"}>
        {props.children}
      </Datagrid>
    </List>
  );
};

export const BetterSimpleForm = (props) => {
  const { staticContext, hasList, hasCreate, hasEdit, hasShow, ...checkProps } =
    props;
  return (
    <SimpleOlehForm {...checkProps} redirect='list' submitOnEnter={false} />
  );
};
export const BetterCustomSimpleForm = (props) => {
  const { staticContext, hasList, hasCreate, hasEdit, hasShow, ...checkProps } =
    props;
  return <CustomSimpleOlehForm {...checkProps} submitOnEnter={false} />;
};
export const BetterCustomSimpleFormLayout = (props) => {
  const { staticContext, hasList, hasCreate, hasEdit, hasShow, ...checkProps } =
    props;
  return <CustomSimpleOlehFormLayout {...checkProps} submitOnEnter={false} />;
};

export const BetterCustomForm = (props) => {
  const { hasList, hasCreate, hasEdit, hasShow, ...checkProps } = props;
  return (
    <SimpleOlehForm
      {...checkProps}
      submitOnEnter={false}
      isCustomRender={true}
    />
  );
};
export const BetterCreateCustomForm = (props) => {
  const { hasList, hasCreate, hasEdit, hasShow, ...checkProps } = props;
  return (
    <SimpleFormCustomCreate
      {...checkProps}
      submitOnEnter={false}
      isCustomRender={true}
    />
  );
};
export const BetterTabbedForm = (props) => {
  const { hasList, hasCreate, hasEdit, hasShow, permissions, ...checkProps } =
    props;

  return (
    <TabbedOlehForm
      {...checkProps}
      redirect='list'
      submitOnEnter={false}
      permissions={permissions}
    />
  );
};
export const BetterTabbedCustomForm = (props) => {
  const { hasList, hasCreate, hasEdit, hasShow, permissions, ...checkProps } =
    props;

  return (
    <TabbedCustomForm
      {...checkProps}
      redirect='list'
      submitOnEnter={false}
      permissions={permissions}
    />
  );
};
export const BetterCreate = withStyles(stylesFormContainer)((props) => {
  const { classes, permissions } = props;
  return <Create {...props} permissions={permissions} classes={classes} />;
});
export const BetterShow = withStyles(stylesFormContainer)((props) => {
  const { classes } = props;
  return <Show {...props} classes={classes} />;
});

export const BetterEdit = withStyles(stylesFormContainer)((props) => {
  // console.log(props)
  const {
    classes,
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    permissions,
    ...checkProps
  } = props;

  return (
    <Edit
      {...checkProps}
      undoable={false}
      classes={classes}
      permissions={permissions}
    />
  );
});
export const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton redirect={props.redirect} style={{ marginLeft: 16 }} />
  </Toolbar>
);
export const EditWithDeleteToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton
      redirect={props.redirect}
      style={{ marginLeft: 16, marginRight: 16 }}
    />
    <EditDeleteButton redirect={props.redirect} undoable={false} />
  </Toolbar>
);

export const FooterToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton redirect={props.redirect} style={{ marginLeft: 16 }} />
    </Toolbar>
  );
};

export const TopBarAction = ({ basePath }) => {
  return (
    <TopToolbar style={{ paddingTop: 0, paddingBottom: 24 }}>
      <Flex
        flexDirection={"row"}
        justifyContent='space-between'
        alignItems='center'
        width={[1]}
        p={[3]}
        bg={"white"}
        style={{ boxShadow: BOX_SHADOW_COLOR }}
      >
        <Flex />
        <ListButton basepath={basePath} />
      </Flex>
    </TopToolbar>
  );
};
export const TopBarTitleAction = ({ basePath, title }) => {
  return (
    <TopToolbar style={{ paddingTop: 0, paddingBottom: 24 }}>
      <Flex
        flexDirection={"row"}
        justifyContent='space-between'
        alignItems='center'
        width={[1]}
        p={[3]}
        bg={"white"}
        style={{ boxShadow: BOX_SHADOW_COLOR }}
      >
        <Title fontWeight={"bold"} fontSize={[3]}>
          {title}
        </Title>
        <ListButton basepath={basePath} />
      </Flex>
    </TopToolbar>
  );
};

export const TopBarFixedAction = ({ basePath }) => {
  return (
    <TopToolbar
      style={{
        paddingTop: 16,
        position: "fixed",
        zIndex: 2001,
        width: "98%",
        paddingRight: 0,
        paddingBottom: 16,
      }}
    >
      <ListButton basepath={basePath} />
    </TopToolbar>
  );
};
