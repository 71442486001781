import React, { useEffect } from "react"
import { useQueryWithStore } from "react-admin"
import { Flex, Image, Text } from "rebass"
import { Button } from "@material-ui/core"
import { getPhoto } from "./utils"

const ProductCard = ({ product, state, onSetValue, indexProduct }) => {
  const myProduct = state.products
  const params = {
    id: product.id,
    name: product.name,
    photos: getPhoto(product.photos),
    slug: product.slug,
    price: product.price,
    discount: product.discount,
    unit_price: product.unit_price,
    site_id: product.site_id,
  }

  useEffect(() => {
    function handleResize() {
      myProduct[indexProduct] = params
      onSetValue("products", myProduct)
    }
    return () => handleResize()
  }, [onSetValue, params, indexProduct, myProduct])

  return (
    <Flex flexDirection={"column"}>
      <Image src={getPhoto(product.photos)} p={[1]} />
      <Text fontSize={[1, 2]} fontWeight={"bold"} textAlign={"center"} p={[2]}>
        {product.name}
      </Text>

      <Button variant="outlined" color={"primary"}>
        Shop now
      </Button>
    </Flex>
  )
}

const NewProductCard = ({ product, ...rest }) => {
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "products",
    payload: { id: product.id },
  })

  if (loading) {
    return <div>loading...</div>
  }

  return <ProductCard {...rest} product={data} />
}

const ProductCardById = (props) => {
  const { product } = props

  if (product && product.name) {
    return (
      <Flex flexDirection={"column"}>
        <Image src={product.photos} p={[1]} />
        <Text
          fontSize={[1, 2]}
          fontWeight={"bold"}
          textAlign={"center"}
          p={[2]}
        >
          {product.name}
        </Text>

        <Button variant="outlined" color={"primary"}>
          Shop now
        </Button>
      </Flex>
    )
  }

  return <NewProductCard {...props} />
}

export default ProductCardById
