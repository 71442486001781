import React from "react";
import styled from "styled-components";
import { Box } from "rebass";
import ContainerDimensions from "react-container-dimensions";
const Image = styled.img`
  opacity: 1;
  display: block;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`;

export default (props) => {
  const { src, containerMargin } = props;
  /**marginBottom: marginBottom ? marginBottom : 0,
                marginTop: marginTop ? marginTop : 0, */
  return (
    <div style={{ width: "100%" }}>
      <ContainerDimensions>
        {({ width }) => {
          return (
            <Box width={[width]}>
              <Image
                src={src}
                alt={"image"}
                style={{
                  width: width,
                  height: "100%",
                  margin: containerMargin,
                }}
              />
            </Box>
          );
        }}
      </ContainerDimensions>
    </div>
  );
};
