import React, { useState, Fragment } from "react";
import { Flex, Box } from "rebass";
import { get, find } from "lodash";
import { useQuery } from "../../../../../components";
import { ContentDetail } from "../../actions";
import {
  Title,
  TextInput,
  SelectInput,
  SwitchInput,
  dataTypeLink,
  SelectTargetInput,
  SelectInputFields,
} from "../";
import CategoryFields from "./CategoryFields";
import { Drawer, Button } from "antd";
import { getSortList } from "./utils";

export default (props) => {
  const { link, onSetValue, websiteId } = props;
  const [visible, setVisible] = useState(false);
  const categoriesList = useQuery(`/categories`);
  const contentList = useQuery(`/contents?website_id=${websiteId}`);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const onSetItemValue = (k, v) => {
    const params = {
      ...link,
      [k]: v,
    };
    onSetValue(params);
    onClose();
  };
  const onSetItemValueSlug = (v, s) => {
    const params = {
      ...link,
      value: v,
      slug: s,
    };
    onSetValue(params);
    onClose();
  };

  const onSetItemContentValueSlug = (v, list) => {
    const item = find(list, function (o) {
      return o.id * 1 === v * 1;
    });
    const params = {
      ...link,
      value: v,
      slug: get(item, "slug", ""),
    };
    onSetValue(params);
    onClose();
  };

  const onSetType = (v) => {
    const params = {
      ...link,
      type: v,
      value: "",
    };
    onSetValue(params);
  };
  const value = get(link, "value", "");
  const target = get(link, "target", "");
  const typeLink = get(link, "type", "URL");
  const isLink = get(link, "isLink", false);
  const listContent = getSortList(get(contentList, "data", []), "label", "asc");
  const listCategories = getSortList(
    get(categoriesList, "data", []),
    "name",
    "asc"
  );
  return (
    <Fragment>
      <Title title={"Action"} />
      <SwitchInput
        id={`link-text-href-dialog-url`}
        title={"Use link"}
        checked={isLink}
        message={"Please checked when Using 'Https://' or outside url"}
        onChange={(e) => onSetItemValue("isLink", e.target.checked)}
      />
      {isLink && (
        <Box width={[1]}>
          <Flex width={[1]} flexDirection={"row"}>
            <Box pr={[2]}>
              <SelectInput
                id={`type-link-dialog-url`}
                title={"Type"}
                value={typeLink}
                list={dataTypeLink}
                onChange={(e) => onSetType(e.target.value)}
              />
            </Box>
            <Box px={[2]}>
              <SelectTargetInput
                id={`target-link-dialog-url`}
                title={"Target"}
                value={target}
                onChange={(value) => onSetItemValue("target", value)}
              />
            </Box>
          </Flex>
          {typeLink === "Content" && (
            <SelectInputFields
              id={`content-link-dialog-url`}
              title={"Content Url"}
              label={"title"}
              value={value}
              list={listContent}
              onChange={(e) =>
                onSetItemContentValueSlug(e.target.value, listContent)
              }
            />
          )}
          {typeLink === "URL" && (
            <Box px={[1]} pb={[2]}>
              <TextInput
                value={value}
                onChange={(v) => onSetItemValue("value", v)}
                name='Url-dialog'
                title='Url'
                style={{ zIndex: 1301 }}
                fullWidth={true}
              />
            </Box>
          )}
          {typeLink === "Slug" && (
            <Box px={[1]} pb={[2]}>
              <TextInput
                value={value}
                onChange={(v) => onSetItemValue("value", v)}
                name='Slug-dialog'
                title='Slug'
                style={{ zIndex: 1301 }}
                fullWidth={true}
              />
            </Box>
          )}
          {typeLink === "Category" && (
            <Box px={[1]} pb={[2]}>
              <Button type='primary' onClick={showDrawer}>
                Select Category
              </Button>
            </Box>
          )}
        </Box>
      )}

      <Drawer
        title={
          <Flex width={[1]} flexDirection={"row"}>
            Action
          </Flex>
        }
        placement='right'
        closable={false}
        onClose={onClose}
        visible={visible}
        zIndex={1300}
        width={400}
      >
        <ContentDetail>
          <Flex width={[1]} flexDirection={"column"}>
            {listCategories && listCategories.length > 0 && (
              <CategoryFields
                dataSource={listCategories}
                value={value}
                onChange={(v, s) => onSetItemValueSlug(v, s)}
              />
            )}
          </Flex>
        </ContentDetail>
      </Drawer>
    </Fragment>
  );
};
