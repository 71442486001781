export default {
  orders: {
    title: {
      name: "Orders",
      new_order: "New Order",
      edit_order: "Edit Order",
      new_shipment: "New Shipment",
      new_refund: "New Refund",
      shipment: "Shipment",
      new_amendment: "New Amendment",
      edit_amendment: "Edit Amendment",
    },
    labels: {
      order_reference: "Order Reference",
      order_date_time: "Order Date Time",
      items: "Items",
      payment_method: "Payment Method",
      payment_reference: "Payment Reference",
      payment_date: "Payment Date",
      paid_amount: "Paid Amount",
      subtotal: "Subtotal",
      total: "Total",
      shipping: "Shipping",
      tax: "Tax 10%",
      comments: "Comments",
      billing_information: "Billing Information",
      shipping_information: "Shipping Information",
      package: "Package",
      shipment_details: "Shipment Details",
      picked: "Picked",
      shipped: "Shipped",
      logistics_provider: "Logistics Provider",
      details: "Details",
      return_items: "Return Items",
      refund_items: "Refund Items",
      refund: "Refund",
      exchange_items: "Exchange Items",
      packaging: "Packaging",
      shipment: "Shipment",
      request_return_shipment: "Request Return Shipment",
      credit_memo: "Credit Memo",
      create_credit_memo: "Create Credit Memo",
      return: "Return",
      refund_subtotal: "Refund Subtotal",
      return_shipping_fee: "Return Shipping Fee",
      admin_fee: "Admin Fee",
      total_refund: "Total Refund",
      returned_qty: "Returned Qty",
      refunded_qty: "Refunded Qty",
      date: "Date",
      discount: "Discount",
    },
    tabs: {
      order: "Order",
      shipment: "Shipment",
      refund: "Refund",
      amendment: "Amendment",
      timeline: "Timeline",
    },
    fields: {
      name: "Name",
      active: "Active",
      status: "Status",
      reference: "Reference",
      customer: "Customer",
      payment: "Payment",
      amount: "Amount",
      created_at: "Created On",
      ref: "Ref",
      comment: "Comment",
      recipient: "Recipient",
      address_line_1: "Address Line 1",
      address_line_2: "Address Line 2",
      city_town: "City/Town",
      country: "Country",
      state_province: "State/Province",
      postcal_code: "Postal Code",
      mobile_number: "Mobile Number",
      provider: "Provider",
      costs: "Costs",
      weight: "Weight (kg)",
      length: "Length (cm)",
      width: "Width (cm)",
      height: "Height (cm)",
      tracking: "Tracking",
      tracking_number: "Tracking Number",
      cost: "Cost",
      reason: "Reason",
      remarks: "Remarks",
      refund_subtotal: "Refund Subtotal",
      adjustment: "Adjustment",
      refund_shipping: "Refund Shipping",
      admin_fee: "Admin Fee",
      refund_tax: "Refund Tax",
      total_refund: "Total Refund",
      ship_from: "Ship from",
      ship_back_to: "Ship back to",
      type: "Type",
      refund_amount: "Refund Amount",
      refund_method: "Refund Method",
      refund_payment_reference: "Refund Payment Reference",
      refund_payment_date: "Refund Payment Date",
      order_status: "Order Status",
      payment_status: "Payment Status",
      cancellation_reason: "Cancellation Reason",
      email: "Email",
    },
    table: {
      product: "Product",
      ordered: "Ordered",
      shipped: "Shipped",
      picked: "Picked",
    },
    messages: {
      no_package_has_been_created:
        "No package has been created yet. Create new package and start picking item into it.",
      no_returns_record: "No returns record",
      empty_comment: "You must input comment",
      no_record: "No record",
      cancel_content: "Are you sure want to cancel?",
      cancel_title: "Cancelation Order",
      cancel_order_message:
        "Cancellation is not allowed. Order has been shipped.",
      reason_fill_empty: "reason can not be empty",
      cancel_order_success: "Order successfully canceled",
      sending_email: "Sending email",
      set_to_delivered: "Set successful",
      set_to_paid: "Set successful",
    },
  },
};
