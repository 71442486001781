export default {
  sourceGroups: {
    title: "Source Groups",
    new_title: "New Source Group",
    edit_title: "Edit Source Group",
    fields: {
      name: "Name",
      active: "Active",
      sources: "Sources",
    },
  },
}
