import React, { Component } from "react"
import { Box, Flex, Text } from "rebass"
import { filter, isArray } from "lodash"
import styled from "styled-components"
import { IconButton, Dialog, Button } from "@material-ui/core"
import DesktopIcon from "@material-ui/icons/DesktopMacTwoTone"
import MobileIcon from "@material-ui/icons/PhoneIphoneTwoTone"
import { StaticPage } from "../../../editorPreview"
import { DialogTitle, DialogContent } from "../../../components"
import { BoxMobile } from "./ContentLayout"
class ShowContentDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      device: "desktop",
    }
  }

  handleOpenDesktop = () => {
    this.setState({
      open: true,
      device: "desktop",
    })
  }
  handleOpenMobile = () => {
    this.setState({
      open: true,
      device: "mobile",
    })
  }
  handleClose = () => {
    this.setState({
      open: false,
    })
  }
  showDeviceDesktop = () => {
    this.setState({
      device: "desktop",
    })
  }
  showDeviceMobile = () => {
    this.setState({
      device: "mobile",
    })
  }
  render() {
    const { open, device } = this.state
    const {
      localeId,
      content,
      translate,
      heightScreen,
      widthScreen,
      websiteId,
    } = this.props
    let newContent = undefined
    if (open) {
      const localeContent = filter(content, function (o) {
        return o.id === localeId
      })

      newContent =
        localeContent.length > 0 ? localeContent[0].layoutSelected : undefined
    }

    return (
      <div>
        <Flex
          flexDirection={"column"}
          pt={[2]}
          pb={[2]}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <PreviewBox>
            <Button
              onClick={this.handleOpenDesktop}
              color="primary"
              style={{ textTransform: "capitalize" }}
            >
              {translate("resources.buttons.preview")}
            </Button>
          </PreviewBox>
        </Flex>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-show-content-dialog-title"
          open={open}
          fullScreen={true}
          scroll={"paper"}
        >
          <DialogTitle
            id="customized-how-content-dialog-title"
            onClose={this.handleClose}
          >
            <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <Text pr={[4]}>Preview</Text>

              <Flex flexDirection={"row"} alignItems={"center"}>
                <IconButton onClick={this.showDeviceDesktop} color={"primary"}>
                  <DesktopIcon style={{ color: "#0087BA" }} />
                </IconButton>
                <Flex p={[2]} />
                <IconButton onClick={this.showDeviceMobile}>
                  <MobileIcon style={{ color: "#0087BA" }} />
                </IconButton>
              </Flex>
            </Flex>
          </DialogTitle>
          <DialogContent dividers>
            <Box width={[1]} style={{ height: "100%" }}>
              {newContent &&
                !isArray(newContent) &&
                (device === "mobile" ? (
                  <BoxMobile
                    data={newContent}
                    locale={localeId}
                    heightScreen={heightScreen}
                    widthScreen={widthScreen}
                    websiteId={websiteId}
                  />
                ) : (
                  <StaticPage
                    data={newContent}
                    locale={localeId}
                    heightScreen={heightScreen}
                    widthScreen={widthScreen}
                    websiteId={websiteId}
                  />
                ))}
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}
const PreviewBox = styled.div`
  position: absolute;
  left: auto;
  top: -50px;
  right: 0;
  z-index: 2000;
  padding: 0px 6px;
`

export default ShowContentDialog
