import React, { Component } from "react"
import PropTypes from "prop-types"
import { find } from "lodash"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import styled from "styled-components"
import { Image } from "rebass"
import ActionPlay from "@material-ui/icons/Refresh"
import { Transition } from "react-transition-group"
import { IconButton, Button, MenuItem } from "@material-ui/core"
import { transitionsAnimation } from "./transitions"
import { SelectInput } from "../select"

const itemsAnimationDirection = []
for (let i = 0; i < transitionsAnimation.length; i++) {
  const item = transitionsAnimation[i]
  itemsAnimationDirection.push(
    <MenuItem value={item.value} key={i} primaryText={item.text} />
  )
}

class DialogAnimation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      bgItem: props.bgItem,
      direction: "",
      isEmptyTheme: false,
      inView: false,
      animation: { type: "ease both", direction: "moveFromRight" },
    }
  }
  handleOpen = () => {
    this.setState({ open: true })
  }

  handleShow = () => {
    this.setState({ inView: false })
  }

  handleClose = () => {
    this.setState({ open: false })
  }
  onSelectedTemplate = (item, index) => {
    this.setState({ open: false, bgItem: item.bg, isEmptyTheme: false })
    this.props.onItemSelected(item.id)
  }
  handleChange = event => {
    const item = find(transitionsAnimation, function(o) {
      return o.value === event.target.value
    })
    if (item && item.value) {
      const animation = this.state.animation
      this.setState({
        inView: !this.state.inView,
        animation: {
          ...animation,
          direction: event.target.value,
          type: item.animation,
        },
      })
    }
    this.props.onItemSelected(item)
  }
  render() {
    const { animation, inView } = this.state
    const defaultStyle = {
      opacity: 0,
      width: "100%",
    }
    const transitionStyles = {
      entering: {
        opacity: 1,
        animation: `${animation.direction} 1000ms ${animation.type}`,
      },
      entered: {
        opacity: 1,
      },
      exited: {
        opacity: 0,
      },
    }
    return (
      <div>
        <DivBox>
          <Button
            variant="contained"
            color={"primary"}
            onClick={this.handleOpen}
          >
            Show Direction
          </Button>
        </DivBox>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll={"paper"}
        >
          <DialogTitle id="simple-dialog-title">
            Animation Direction
          </DialogTitle>
          <DivBox>
            <DivBoxRow>
              <SelectInput
                id={`animation-selection`}
                title={"Direction"}
                list={transitionsAnimation}
                value={this.state.animation.direction}
                onChange={(e, v) => this.handleChange(e, v)}
              />
              <DivBox1>
                <IconButton onClick={this.handleShow} style={styles.medium}>
                  <ActionPlay />
                </IconButton>
              </DivBox1>
            </DivBoxRow>
            <div>
              <Transition in={!!inView} timeout={1000}>
                {state => {
                  console.log(state)
                  return (
                    <div
                      style={{ ...defaultStyle, ...transitionStyles[state] }}
                    >
                      <Image
                        src="https://s3-ap-southeast-1.amazonaws.com/ola2-cms-staging/assets/1/content/ImageView-image-desktop-1Kb.jpeg"
                        alt="cms editor"
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  )
                }}
              </Transition>
            </div>
          </DivBox>
        </Dialog>
      </div>
    )
  }
}

const styles = {
  mediumIcon: {
    width: 48,
    height: 48,
  },
  medium: {
    width: 96,
    height: 96,
    padding: 24,
  },
}

// const DivItemTemplate = styled.div`
//   padding:8px;
//   width: 150px;
//   &:hover{
//     cursor:pointer;
//     background-color: rgba(0,0,0,0.3);
//   }
// `;

// const DivImageContainer = styled.div`
//   display: inline-flex;
// `;

const DivBox = styled.div`
  padding: 16px 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const DivBox1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
`

const DivBoxRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`

// const Image = styled.img`
//   opacity: 1;
//   display: block;
//   height: auto;
//   backface-visibility: hidden;
// `;

DialogAnimation.propTypes = {
  onItemSelected: PropTypes.func,
  bgItem: PropTypes.string,
}

DialogAnimation.defaultProps = {
  onItemSelected: () => {},
  bgItem: "",
}

export default DialogAnimation
