import React from "react";
import { Flex, Box, Image } from "rebass";
import SearchBox from "./SearchBox";
import Account3 from "./Account3";
import { getSetLogoStyle } from "./utils";
import { get } from "lodash";
const Desktop5 = ({ settings, cart, categories, widthScreen }) => {
  // const { items } = cart;
  // const colorPrimary = "#CACACA";
  const styleLogo = getSetLogoStyle(
    get(settings, "header.containerLogo"),
    widthScreen
  );

  return (
    <Box width={[1]}>
      <Flex width={[1]}>
        <Box width={[1]}>
          <Flex
            width={[1, 1]}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={[2]}
          >
            <Flex width={[1]} flexDirection={"row"} alignItems={"center"}>
              <Image
                sx={{ ...styleLogo, mr: [2] }}
                src={settings.header.image}
                alt='logo'
              />
            </Flex>
            <Flex width={[1]} style={{ zIndex: 2 }} flexDirection={"row"}>
              <SearchBox width={"300px"} />
              <Account3 cart={cart} settings={settings} />
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Desktop5;
