import React, { useState } from "react";
import { Flex, Box, Text } from "rebass";
// import { get } from "lodash";
import { SketchColorPicker } from "../colorpicker";
/**
 * colorProps: {
      color: ["#000000", "#000000"],
      backgroundColor: ["#FFFFFF", "#FFFFFF"],
      hoverColor: ["rgba(255,255,255,0)", "rgba(255,255,255,0)"],
      hoverBgColor: ["rgba(255,255,255,0)", "rgba(255,255,255,0)"],
    },
 */
export default (props) => {
  const { colorProps, onUpdateColor } = props;
  const [state, setState] = useState(colorProps);

  const onChangeValue = (k, v, index) => {
    let myValue = state[k];

    if (state[k]) {
      if (myValue[index]) {
        myValue[index] = v;
      } else {
        if (myValue[index] === "") {
          myValue[index] = v;
        } else {
          if (typeof v === "number") {
            myValue = [1, 1];
            myValue[index] = v;
          } else {
            myValue = ["", ""];
            myValue[index] = v;
          }
        }
      }
    }

    const params = { ...state, [k]: myValue };
    setState(params);
    onUpdateColor("colorProps", params);
  };

  return (
    <Box width={[1]}>
      <Flex width={[1]} flexDirection={"column"}>
        <Text fontWeight={"bold"} pb={[2]}>
          Desktop View
        </Text>
        <SketchColorPicker
          title={"Color"}
          onUpdateColor={(color) => onChangeValue("color", color, 1)}
          color={state.color[1]}
        />
        <SketchColorPicker
          title={"Background"}
          onUpdateColor={(color) => onChangeValue("backgroundColor", color, 1)}
          color={state.backgroundColor[1]}
        />
        <SketchColorPicker
          title={"Hover Color"}
          onUpdateColor={(color) => onChangeValue("hoverColor", color, 1)}
          color={state.hoverColor[1]}
        />
        <SketchColorPicker
          title={"Hover Background"}
          onUpdateColor={(color) => onChangeValue("hoverBgColor", color, 1)}
          color={state.hoverBgColor[1]}
        />
      </Flex>
      <Flex width={[1]} flexDirection={"column"} py={[2]}>
        <Text fontWeight={"bold"} pb={[2]}>
          Mobile View
        </Text>
        <SketchColorPicker
          title={"Color"}
          onUpdateColor={(color) => onChangeValue("color", color, 0)}
          color={state.color[0]}
        />
        <SketchColorPicker
          title={"Background"}
          onUpdateColor={(color) => onChangeValue("backgroundColor", color, 0)}
          color={state.backgroundColor[0]}
        />
        <SketchColorPicker
          title={"Hover Color"}
          onUpdateColor={(color) => onChangeValue("hoverColor", color, 0)}
          color={state.hoverColor[0]}
        />
        <SketchColorPicker
          title={"Hover Background"}
          onUpdateColor={(color) => onChangeValue("hoverBgColor", color, 0)}
          color={state.hoverBgColor[0]}
        />
      </Flex>
    </Box>
  );
};
