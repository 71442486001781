import React, { Component } from "react";
import PropTypes from "prop-types";
import Counter from "./Counter";
import { Title } from "../text";
import { DivRow, Content } from "./styled";
import { SwitchInput } from "../switch";
import { cssToJS, getSize, setValue } from "./utils";

class BlockCounter extends Component {
  constructor(props) {
    super(props);
    const { containerPadding } = props;
    const padding = cssToJS(containerPadding);
    const count = getSize(containerPadding);
    let changeNumber = 0;
    if (!count) {
      changeNumber = containerPadding.split("px").join("") * 1;
    }
    this.state = {
      allSide: changeNumber,
      top: padding.top,
      left: padding.left,
      right: padding.right,
      bottom: padding.bottom,
      isAllSide: count,
    };
  }

  onSetValue = (key, value) => {
    if (this.state.isAllSide) {
      let padding = setValue(
        this.state.top,
        this.state.right,
        this.state.bottom,
        this.state.left
      );
      switch (key) {
        case "top":
          this.setState({ top: value });
          padding = setValue(
            value,
            this.state.right,
            this.state.bottom,
            this.state.left
          );
          break;
        case "bottom":
          this.setState({ bottom: value });
          padding = setValue(
            this.state.top,
            this.state.right,
            value,
            this.state.left
          );
          break;
        case "right":
          this.setState({ right: value });
          padding = setValue(
            this.state.top,
            value,
            this.state.bottom,
            this.state.left
          );
          break;
        case "left":
          this.setState({ left: value });
          padding = setValue(
            this.state.top,
            this.state.right,
            this.state.bottom,
            value
          );
          break;
        default:
          break;
      }
      // console.log(padding)
      this.props.onUpdatePadding(padding);
    } else {
      this.setState({
        allSide: value,
        top: value,
        bottom: value,
        right: value,
        left: value,
      });
      // console.log(value)
      this.props.onUpdatePadding(value + "px");
    }
  };

  onToggle = (e, v) => {
    // console.log(this.state.allSide);
    if (v) {
      let padding = setValue(
        this.state.top,
        this.state.right,
        this.state.bottom,
        this.state.right
      );
      this.props.onUpdatePadding(padding);
    } else {
      this.props.onUpdatePadding(this.state.allSide + "px");
    }
    this.setState({ isAllSide: v });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { containerPadding } = nextProps;
    if (containerPadding !== this.props.containerPadding) {
      const padding = cssToJS(containerPadding);
      const count = getSize(containerPadding);
      let changeNumber = 0;
      if (!count) {
        changeNumber = containerPadding.split("px").join("") * 1;
      }
      this.setState({
        allSide: changeNumber,
        top: padding.top,
        left: padding.left,
        right: padding.right,
        bottom: padding.bottom,
        isAllSide: count,
      });
    }
  }

  render() {
    const { title, idSwitch } = this.props;
    return (
      <Content>
        {title && <Title title={title} />}
        <SwitchInput
          title='More Options'
          id={idSwitch}
          checked={this.state.isAllSide}
          onChange={(e, v) => this.onToggle(e, v)}
        />
        {!this.state.isAllSide && (
          <div>
            <Counter
              title='All Side'
              onSetValue={(value) => this.onSetValue("AllSide", value)}
              value={this.state.allSide}
              subtitle='px'
            />
          </div>
        )}
        {this.state.isAllSide && (
          <div>
            <DivRow>
              <Counter
                title='Top'
                onSetValue={(value) => this.onSetValue("top", value)}
                value={this.state.top}
                subtitle='px'
              />
              <Counter
                title='Right'
                onSetValue={(value) => this.onSetValue("right", value)}
                value={this.state.right}
                subtitle='px'
              />
            </DivRow>
            <DivRow>
              <Counter
                title='Left'
                onSetValue={(value) => this.onSetValue("left", value)}
                value={this.state.left}
                subtitle='px'
              />
              <Counter
                title='Bottom'
                onSetValue={(value) => this.onSetValue("bottom", value)}
                value={this.state.bottom}
                subtitle='px'
              />
            </DivRow>
          </div>
        )}
      </Content>
    );
  }
}

BlockCounter.propTypes = {
  containerPadding: PropTypes.string,
  onUpdatePadding: PropTypes.func.isRequired,
  idSwitch: PropTypes.string,
};

BlockCounter.defaultProps = {
  containerPadding: "20px",
  idSwitch: "moreOptionsblock-counter",
};

export default BlockCounter;
