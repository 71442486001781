import React, { Component } from "react";
import PropTypes from "prop-types";
import DestopIcon from "@material-ui/icons/DesktopMac";
import MobileIcon from "@material-ui/icons/StayPrimaryPortrait";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { CircularProgress, Snackbar, Button } from "@material-ui/core";
import Dropzone from "react-dropzone";
import { shallowEqual } from "recompose";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";
import { UploadClient } from "../../../restClient";
import {
  DivContainer,
  SpanImage,
  Image,
  InsideBox,
  GridImage,
  Content,
  BoxUpload,
} from "./styled";
import {
  URL_UPLOAD,
  ERROR_MESSAGE_FAILED,
  ERROR_MESSAGE_MAX_SIZE,
} from "./constants";

const pinkA200 = pink["A200"];
const red500 = red[500];

class UploadImageArray extends Component {
  constructor(props) {
    super(props);
    let photos = props.imageUrl || [];

    let isShowing = true;
    if (photos.length === 1 && photos[0] instanceof Object) {
      isShowing = false;
    }
    this.state = {
      photos: photos,
      isShow: isShowing,
      isLoading: false,
      openSnackBar: false,
      message: "Please try again",
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let photos = nextProps.imageUrl || [];
    this.setState({ photos: photos });
  }
  onAdd(url) {
    const updatedFiles = [...this.state.photos, url];
    // //console.log(updatedFiles);
    this.setState({ photos: updatedFiles, isLoading: false });
    //console.log(this.state.photos);
    this.props.onUpdateUrl(updatedFiles);
  }
  onRemove = (file) => () => {
    const filteredFiles = this.state.photos.filter(
      (stateFile) => !shallowEqual(stateFile, file)
    );
    this.setState({ photos: filteredFiles });
    this.props.onUpdateUrl(filteredFiles);
  };

  onDrop = (accepted, rejected) => {
    const paramsCaption = "image-carousel-" + this.props.type;

    if (accepted.length > 0 && rejected.length === 0) {
      this.setState({
        isLoading: true,
      });
      let formData = new FormData();
      formData.append("caption", paramsCaption);
      accepted.forEach((file) => {
        formData.append("photos", file, file.name);
      });

      UploadClient(formData, URL_UPLOAD)
        .then((response) => response.json())
        .then((data) => {
          if (data.url) {
            this.onAdd(data.url);
          } else {
            this.setState({
              isLoading: false,
              openSnackBar: true,
              message: ERROR_MESSAGE_FAILED,
            });
          }
        })
        .catch((e) => {
          console.error(e);
          this.setState({
            isLoading: false,
            openSnackBar: true,
            message: ERROR_MESSAGE_FAILED,
          });
        });
    } else {
      this.setState({
        openSnackBar: true,
        message: ERROR_MESSAGE_MAX_SIZE,
      });
    }
  };

  handleRequestClose = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  renderItem() {
    return (
      <GridImage>
        {this.state.photos.map((item, index) => {
          return (
            <div key={index} style={styles.container}>
              <Button
                style={styles.removeButton}
                icon={<RemoveCircle color={pinkA200} />}
                onClick={this.onRemove(item)}
              />
              <Image
                src={item}
                style={styles.image}
                alt='delete'
                title='photos'
              />
            </div>
          );
        })}
      </GridImage>
    );
  }
  render() {
    const { disableClick, maxSize, minSize, multiple, type } = this.props;
    return (
      <Content>
        <Dropzone
          onDrop={this.onDrop}
          accept='image/*'
          disableClick={disableClick}
          maxSize={maxSize}
          minSize={minSize}
          multiple={multiple}
          style={styles.boxImage}
        >
          {({ getRootProps, getInputProps }) => (
            <BoxUpload style={{ width: 150, height: 150 }}>
              <div {...getRootProps()} style={{ display: "flex" }}>
                <input {...getInputProps()} />
                {this.state.isLoading && (
                  <InsideBox>
                    <CircularProgress />
                  </InsideBox>
                )}
                {!this.state.isLoading && (
                  <InsideBox style={{ color: "#173f4f" }}>
                    {type === "mobile" ? (
                      <MobileIcon color={"inherit"} style={{ fontSize: 50 }} />
                    ) : (
                      <DestopIcon color={"inherit"} style={{ fontSize: 50 }} />
                    )}
                    {type === "desktop" ? (
                      <SpanImage>size : 960x400</SpanImage>
                    ) : (
                      <SpanImage>size : 600x600</SpanImage>
                    )}
                  </InsideBox>
                )}
              </div>
            </BoxUpload>
          )}
        </Dropzone>
        <DivContainer>{this.state.isShow && this.renderItem()}</DivContainer>
        <Snackbar
          open={this.state.openSnackBar}
          message={this.state.message}
          autoHideDuration={4000}
          bodyStyle={{ backgroundColor: red500 }}
          onRequestClose={this.handleRequestClose}
        />
      </Content>
    );
  }
}

const styles = {
  container: {
    display: "inline-block",
    position: "relative",
    padding: 5,
  },
  removeButton: {
    position: "absolute",
    top: "0.5rem",
    right: "0.5rem",
    minWidth: "2rem",
    opacity: 1,
  },
  removeButtonHovered: {
    opacity: 1,
  },
  image: {
    maxHeight: "15rem",
    margin: "0.2rem",
    maxWidth: "200px",
  },
  boxImage: {
    width: 120,
    height: 120,
    borderWidth: 2,
    padding: "0 6px 0 6px",
    borderColor: "#666",
    borderStyle: "dashed",
    borderRadius: 5,
  },
};

UploadImageArray.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  disableClick: PropTypes.bool,
  onUpdateUrl: PropTypes.func,
  imageUrl: PropTypes.array,
};

UploadImageArray.defaultProps = {
  type: "default",
  multiple: false,
  maxSize: 1000000,
  title: "Image",
  imageUrl: [],
};

export default UploadImageArray;
