import React from "react";
import { Box, Flex } from "rebass";
import { get, lowerCase } from "lodash";
import ContainerDimensions from "react-container-dimensions";
import { connect } from "../../../core";
import {
  ListSlideMan,
  ListCategories,
  ListProducts,
  ListCollections,
} from "../listside";

const ListSlideView = (props) => {
  const { id, layoutState, widthScreen } = props;
  const {
    desktop,
    mobile,
    urlViewAll,
    title,
    colorProps,
    valueText,
    titleViewAll,
    modeType,
    linkViewAll,
    contentList,
  } = layoutState.getItemJS(id).props.values;
  const myBackground = get(
    colorProps,
    "backgroundColor",
    "rgba(255,255,255,0)"
  );

  const contentList1 = widthScreen > 480 ? desktop : mobile;
  const showViewAllBottom = get(
    contentList1,
    "listProps.showViewAllBottom",
    false
  );
  const tags = get(contentList, "tags", []);
  const products = get(contentList, "products", []);
  const collection = get(contentList, "value", "");
  const showPrice = get(
    contentList,
    `showPrice[${widthScreen > 480 ? 1 : 0}]`,
    true
  );
  return (
    <div
      className='list-slide-view'
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        backgroundColor: myBackground,
        display: "flex",
        flex: 1,
      }}
    >
      <ContainerDimensions>
        {({ width }) => {
          return (
            <Box width={[width]}>
              {lowerCase(modeType) === "list" &&
                valueText &&
                valueText.length > 0 && (
                  <ListSlideMan
                    screenWidth={width}
                    title={title}
                    colorProps={colorProps}
                    urlViewAll={urlViewAll}
                    desktop={contentList1}
                    valueText={valueText}
                  />
                )}
              {lowerCase(modeType) === "category" && tags.length > 0 && (
                <ListCategories
                  screenWidth={width}
                  title={title}
                  colorProps={colorProps}
                  desktop={contentList1}
                  valueText={valueText}
                  titleViewAll={titleViewAll}
                  linkViewAll={linkViewAll}
                  tags={tags}
                  modeType={modeType}
                  showPrice={showPrice}
                />
              )}
              {lowerCase(modeType) === "product" && products.length > 0 && (
                <ListProducts
                  screenWidth={width}
                  title={title}
                  colorProps={colorProps}
                  desktop={contentList1}
                  valueText={valueText}
                  titleViewAll={titleViewAll}
                  linkViewAll={linkViewAll}
                  products={products}
                  modeType={modeType}
                  showPrice={showPrice}
                />
              )}
              {lowerCase(modeType) === "collection" &&
                collection !== "" &&
                collection !== null && (
                  <ListCollections
                    screenWidth={width}
                    title={title}
                    colorProps={colorProps}
                    desktop={contentList1}
                    valueText={valueText}
                    titleViewAll={titleViewAll}
                    linkViewAll={linkViewAll}
                    modeType={modeType}
                    collection={collection}
                    showPrice={showPrice}
                  />
                )}
              {showViewAllBottom && (
                <Flex
                  width={[1]}
                  flexDirection={"rown"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {titleViewAll || ""}
                </Flex>
              )}
            </Box>
          );
        }}
      </ContainerDimensions>
    </div>
    // <div style={{ padding: containerPadding }}>
    //   <Box width={[1]} style={{ backgroundColor: backgroundColor }}>
    //     {width1 > 0 && (
    //       <div style={{ backgroundColor: "transparent" }}>
    //         {modeType === "multiRow" ? (
    //           <ListMultiRow
    //             newMan={valueText}
    //             title={title}
    //             newSetting={setting}
    //             screenWidth={width1}
    //           />
    //         ) : (
    //           <ListNewMan
    //             newMan={valueText}
    //             title={title}
    //             products={products}
    //             newSetting={setting}
    //             screenWidth={width1}
    //           />
    //         )}
    //       </div>
    //     )}
    //   </Box>
    // </div>
  );
};

export default connect("layoutState", "locale", "widthScreen")(ListSlideView);
