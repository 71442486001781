export default {
  reviews: {
    title: "Reviews",
    fields: {
      id: "ID",
      status: "Status",
      product: "Product",
      product_name: "Product Name",
      customer: "Customer",
      created_on: "Created On",
      rating: "Rating",
      subject: "Subject",
      review: "Review",
      website: "Website",
    },
  },
}
