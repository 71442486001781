import React, { Component } from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { FooterAction, ContainerPanel } from "../actions";
import { BoxContent } from "../styled";
// import { defaultHref } from "./utils";
import { PanelAvatar, PanelGeneral } from "./Fields";

class AvatarView extends Component {
  constructor(props) {
    super(props);
    const { containerPadding, align, size, url, link } = props.data.values;
    this.state = {
      heightContent: props.heightContent,
      widthContent: props.widthContent,
      isEditMode: false,
      align: align,
      url: url,
      containerPadding: containerPadding,
      size: size,
      link: link,
      valuesAvatar: props.data.values,
    };
  }
  onClickSave = () => {
    let newData = this.props.data;
    // newData.values = this.state.valuesAvatar
    newData.values.align = this.state.align;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values.size = this.state.size;
    newData.values.url = this.state.url;
    newData.values.link = this.state.link;
    this.props.onUpdateData(newData);
  };
  onUpdateAvatarSize = (value) => {
    this.setState({ size: value });
  };
  onUpdateUrl = (value) => {
    this.setState({ url: value });
  };
  onClickCopy = () => {
    let newData = this.props.data;
    newData.values.align = this.state.align;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values.size = this.state.size;
    newData.values.url = this.state.url;
    newData.values.link = this.state.link;
    localStorage.setItem("AvatarView", JSON.stringify(newData.values));
  };
  onClickPaste = () => {
    const avatarContent = JSON.parse(localStorage.getItem("AvatarView"));
    if (avatarContent) {
      const { containerPadding, align, size, url, link } = avatarContent;
      this.setState({
        align: align,
        url: url,
        containerPadding: containerPadding,
        size: size,
        link: link,
      });
    }
  };
  onSetValue = (k, v) => {
    this.setState({
      ...this.state,
      [k]: v,
    });
  };
  render() {
    return (
      <Flex width={[1]} flexDirection={"column"}>
        <BoxContent style={{ height: this.state.heightContent - 120 }}>
          <ContainerPanel>
            <PanelAvatar
              {...this.props}
              state={this.state}
              onUpdateUrl={this.onUpdateUrl}
              onUpdateAvatarSize={this.onUpdateAvatarSize}
            />
            <PanelGeneral
              state={this.state}
              {...this.props}
              onSetValue={(k, v) => this.onSetValue(k, v)}
            />
          </ContainerPanel>
        </BoxContent>
        <FooterAction
          onClickSave={this.onClickSave}
          onClickCopy={this.onClickCopy}
          onClickPaste={this.onClickPaste}
          widthContent={this.state.widthContent}
        />
      </Flex>
    );
  }
}

AvatarView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

AvatarView.defaultProps = {
  data: {},
};
export default AvatarView;
