export default {
  attributeSet: {
    name: "AttributeSet",
    back: "Back to AttributeSet",
    new_attributeset: "New AttributeSet",
    edit_attributeset: "Edit AttributeSet",
    fields: {
      name: "Name",
      choose_atributes:
        "Choose attributes from the list below and move to the list in the right",
    },
  },
}
