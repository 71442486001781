import React from "react"
import { Create } from "react-admin"
import { BetterCustomSimpleForm, FooterToolbar, DetailAction } from "./"
import FormFields from "./FormFields"
import { getSiteID } from "../../../restClient"
const initValues = {
  site_id: getSiteID(),
  type: "Product",
}

const CreateProductRules = (props) => {
  return (
    <Create
      {...props}
      actions={
        <DetailAction
          title={"resources.promotions.title.new_product"}
          basePath="/promotions"
        />
      }
    >
      <BetterCustomSimpleForm
        {...props}
        toolbar={<FooterToolbar />}
        initialValues={initValues}
        sourceApi="promotions"
        title={"resources.promotions.title.new_product"}
        typePostUrl={"CREATE"}
        pathUrl="promotions"
        customBackUrl="/promotions"
        hideAction={true}
      >
        <FormFields {...props} isCreate={true} />
      </BetterCustomSimpleForm>
    </Create>
  )
}

export default CreateProductRules
