import React, { Fragment } from "react";
import { Flex } from "rebass";

import {
  BlockCounter,
  Counter,
  PixelPercent,
  DialogGradient,
  SwitchInput,
  Title,
} from "../";
import { SketchColorPicker } from "../../../../components";
import { ContentDetail } from "../../actions";

export const ContentBG = (props) => {
  const { state, onChangeType, onSetValue } = props;
  return (
    <Fragment>
      <ContentDetail>
        <SketchColorPicker
          title={"Container Parent Background Color"}
          onUpdateColor={(value) => onSetValue("backgroundColorParent", value)}
          color={state.backgroundColorParent}
        />
      </ContentDetail>
      <ContentDetail>
        <SketchColorPicker
          title={"Background Color"}
          onUpdateColor={(value) => onSetValue("backgroundColor", value)}
          color={state.backgroundColor}
        />
      </ContentDetail>
      <ContentDetail>
        <Title title={"Content Width"} />
        <PixelPercent
          name='type'
          value={state.type}
          onChange={onChangeType}
          title='Type'
        />
        <Flex flexDirection={"row"} width={[1]} justifyContent={"flex-end"}>
          <Counter
            isDisabled={false}
            onSetValue={(value) => onSetValue("contentWidth", value)}
            value={state.contentWidth}
            interval={state.interval}
            maxValue={state.maxValueWidth}
          />
        </Flex>
      </ContentDetail>
      <BlockCounter
        containerPadding={state.padding}
        onUpdatePadding={(value) => onSetValue("padding", value)}
        title={"Content Padding"}
      />
      <ContentDetail>
        <SwitchInput
          id={`use-bg-gradient-body-content`}
          title={"Use Background Gradient"}
          checked={state.isGradient}
          onChange={(e) => onSetValue("isGradient", e.target.checked)}
        />
        {state.isGradient && (
          <DialogGradient
            onSetValue={(gradient) => onSetValue("gradient", gradient)}
            gradient={state.gradient}
          />
        )}
      </ContentDetail>
    </Fragment>
  );
};
