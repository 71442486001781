import React from "react"
// import { Field } from "react-final-form"
import { Box, Text } from "rebass"
import InputLabel from "@material-ui/core/InputLabel"
import { addField, useTranslate } from "react-admin"
import TextMessage from "./utils"

const TextFieldForm = props => {
  const translate = useTranslate()
  const { input, label, notetext, source } = props
  return (
    <Box width={180} pb={[2]}>
      <InputLabel>{label ? translate(label) : source}</InputLabel>
      <Text fontSize={12} pt={[1]}>
        {input.value}
      </Text>
      {notetext && <TextMessage notetext={notetext} />}
    </Box>
  )
}
export default addField(TextFieldForm)
