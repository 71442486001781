import React from "react";
import moment from "moment";
import { useTranslate } from "react-admin";
import { Text, Box } from "rebass";
import { get } from "lodash";
import { Currency } from "../currency";
const TextRecord = ({
  source,
  record = {},
  label,
  color,
  defaultValue = "",
  pb = 3,
  isDate = false,
  formatDate = "DD-MMM-YYYY",
  isCaiptal = false,
  isCurrency = false,
  currencySymbol = "",
}) => {
  const translate = useTranslate();
  const subColor = color ? { color: color } : {};
  const fontCapital = isCaiptal ? { textTransform: "capitalize" } : {};
  if (isDate) {
    return (
      <Box pb={[pb]}>
        <Text fontWeight={"bold"} fontSize={[0]} pb={[1]}>
          {translate(label)}
        </Text>
        <Text style={{ ...subColor }}>
          {get(record, source, defaultValue) !== "" &&
            moment(get(record, source, defaultValue)).format(formatDate)}
        </Text>
      </Box>
    );
  }

  return (
    <Box pb={[pb]}>
      <Text fontWeight={"bold"} fontSize={[0]} pb={[1]}>
        {translate(label)}
      </Text>
      {source === "active" && (
        <Text style={{ textTransform: "capitalize", ...subColor }}>
          {get(record, source, "false")}
        </Text>
      )}
      {source !== "active" && !isCurrency && (
        <Text style={{ ...fontCapital, ...subColor }}>
          {get(record, source, defaultValue)}
        </Text>
      )}
      {source !== "active" && isCurrency && (
        <Text style={{ ...fontCapital, ...subColor }}>
          <Currency
            value={get(record, source, defaultValue) * 1}
            unit={currencySymbol}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </Text>
      )}
    </Box>
  );
};
export default TextRecord;
