import React from "react";
//import PropTypes from 'prop-types';
import styled from "styled-components";
import { DropTarget } from "react-dnd";
import { connect } from "../../core";
import { get } from "lodash";
import Media from "./Media";
import { target } from "./utils";

const Image = styled.img`
  opacity: 1;
  display: block;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`;

// const target = {
//   drop(props, monitor, component) {
//     if (!monitor.didDrop()) {
//       props.layoutState.insertOrMoveItem(
//         props.id,
//         props.children.length,
//         monitor.getItem()
//       )
//     }
//   },
// }
const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
}) => {
  const { imageUrl, align, desktop } = layoutState.getItemJS(id).props.values;
  let srcMobile = "";
  let srcDesktop = "";
  if (imageUrl) {
    srcMobile =
      imageUrl.urlMobile ||
      imageUrl.url ||
      "https://via.placeholder.com/640x400?text=Image";
    srcDesktop =
      imageUrl.url ||
      imageUrl.urlMobile ||
      "https://via.placeholder.com/640x400?text=Image";
  }

  // console.log(layoutState.getItemJS(id).props.values);
  const padding = get(desktop, "padding", "0px");
  const width = get(desktop, "width", "100%");
  const containerMargin = get(desktop, "margin", "0px");

  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "auto",
        display: "flex",
        width: "100%",
        padding: padding,
        flex: 1,
        justifyContent: align,
      }}
    >
      <Media query='(max-width: 768px)'>
        {
          (matches) => {
            return (
              <Image
                src={matches ? srcMobile : srcDesktop}
                alt={"helo"}
                style={{
                  width: width,
                  height: "100%",
                  margin: containerMargin,
                }}
              />
              // <ShowImage
              //   src={matches ? srcMobile : srcDesktop}
              //   containerMargin={containerMargin}
              // />
            );
          }
          // matches ? (
          //   <Image
          //     src={srcMobile}
          //     alt={altText}
          //     style={{
          //       width: width,
          //       height: "100%",
          //       marginBottom: marginBottom ? marginBottom : 0,
          //       marginTop: marginTop ? marginTop : 0,
          //     }}
          //   />
          // ) : (
          //   <Image
          //     src={srcDesktop}
          //     alt={altText}
          //     style={{
          //       width: width,
          //       height: "100%",
          //       marginBottom: marginBottom ? marginBottom : 0,
          //       marginTop: marginTop ? marginTop : 0,
          //     }}
          //   />
          // )
        }
      </Media>
    </div>
  );
};

const LayoutContainer = DropTarget("ImageView", target, (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(EditLayout);

export default connect("layoutState", "readOnly")(LayoutContainer);
