import React from "react"
import { get } from "lodash"
import { DragSource } from "react-dnd"

const source = {
  beginDrag(props, monitor, component) {
    // console.log(props)
    return {
      type: props.type,
      props: {
        values: props.defaultProps.values,
        content: props.defaultProps.content,
      },
      children: [],
      style: {},
      childOf: "Body",
    }
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      // You can check whether the drop was successful
      // or if the drag ended but nobody handled the drop
      return
    }

    const item = monitor.getItem()
    // console.log(item)
    const myItem = props.layoutState.getItemJS(item.id)
    const myChildren = get(myItem, "children", [])
    let myItem1 = props.layoutState
    if (props && props.content) {
      myItem1.insertAllContent(myChildren[0], props.content)
    }
  },
}

const ThemeItem = ({
  type,
  component,
  connectDragSource,
  defaultProps,
  layout,
}) =>
  connectDragSource(
    <div
      style={{ backgroundColor: "#efefef", color: "#eee", cursor: "pointer" }}
    >
      <div
        style={{
          backgroundColor: "transparent",
          color: "#eee",
          padding: 4,
        }}
      >
        {React.createElement(layout, defaultProps)}
      </div>
    </div>
  )

export default DragSource("Body", source, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
}))(ThemeItem)
