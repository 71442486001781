export default {
  shippings: {
    name: "Shippings",
    create_title: "New Shipping",
    edit_title: "Edit Shipping",
    back: "Back to Shippings",
    label: {
      settings: "Settings",
      conditions: "Conditions",
      packaging: "Packaging",
      providers: "Providers",
    },
    fields: {
      dimensions: "Dimension HxLxW (cm)",
      volume: "Volumetric Weight (kg)",
      width: "Width (cm)",
      height: "Height (cm)",
      length: "Length (cm)",
      weight: "Weight (kg)",
      name: "Name",
      active: "Active",
      provider: "Provider",
      sandbox: "Sandbox",
      app_secret: "App secret",
      app_id: "App ID",
      supported_countries: "Supported Countries",
      supported_states: "Supported States or Provinces",
      supported_cities: "Supported Cities",
    },
    note: {
      supported_countries:
        "Put in country code such as SG,PH,ID. Leave it blank to support all countries",
      supported_states:
        "Put in states name separated by comma code such as Central Luzon, Northen Luzon",
      supported_cities: "Put in country code such as SG,PH,ID",
    },
  },
};
