import React, { PureComponent } from "react";

import { connect } from "../utils";
import BaseWrapper from "./BaseWrapper";

class RootWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      component: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.wrap(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.id !== this.props.id ||
      nextProps.addons !== this.props.addons
    ) {
      this.wrap(nextProps);
    }
  }

  wrap = (props) => {
    // console.log(props.layoutState.getItemJS(props.id));
    const type = props.layoutState.getItemJS(props.id).type;

    const { values } = props.layoutState.getItemJS(props.id).props;
    const isRow = values.parent && values.parent === "Body";
    let component = BaseWrapper(props.components[type], type);
    props.addons.forEach((addon) => {
      // console.log(addon.Wrapper);
      if (type === "Body" && addon.Wrapper) {
        component = addon.Wrapper(component, type);
      } else {
        // console.log(props.layoutState.getItemJS(props.id))
        if (isRow && addon.WrapperRow) {
          component = addon.WrapperRow(component, type);
        } else if (!isRow && addon.WrapperContent) {
          component = addon.WrapperContent(component, type);
        }
      }
    });
    this.setState({ component });
  };

  render() {
    const { id, layoutState } = this.props;
    const item = layoutState.getItemJS(id);
    // console.log(item);
    const WrappedComponent = this.state.component;
    return (
      <WrappedComponent pseudoRef={() => {}} id={id}>
        {React.Children.map(item.children, (childId) => (
          <Wrapper key={childId} id={childId} />
        ))}
      </WrappedComponent>
    );
  }
}

const Wrapper = connect("layoutState", "components", "addons")(RootWrapper);

export default Wrapper;
