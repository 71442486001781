import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout13 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const ListSlideView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='ListSlideView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};
ListSlideView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};
/**
 * old defaultValue
 * ListSlideView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "ListSlideView",
    modeType: "List",
    urlViewAll: "",
    title: "",
    titleViewAll: "View All",
    linkViewAll: {
      target: "",
      type: "URL",
      value: "",
      isLink: false,
    },
    contentList: {
      showPrice: [true, true],
      value: "",
      tags: [],
      products: [],
    },
    colorProps: {
      backgroundColor: "rgba(255,255,255,0)",
      color: "rgba(0,0,0,1)",
      viewallColor: "rgba(0,0,0,1)",
      hoverColor: "rgba(0,0,0,1)",
      hoverBackgroundColor: "rgba(255,255,255,0)",
    },
    itemSetting: {
      limit: [100, 100],
      titleFontSize: [12, 12],
      subFontSize: [12, 12],
      titlePadding: ["0px", "0px"],
      containerPadding: ["0px", "0px"],
      subPadding: ["0px", "0px"],
      modeImg: ["contain", "contain"],
    },
    desktop: {
      show: true,
      listProps: {
        showViewAllBottom: false,
      },
      header: {
        show: true,
        fontSize: 14,
        showViewAll: true,
        padding: "0px",
      },
      setting: {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        rows: 0,
        slidesPerRow: 0,
        autoplay: false,
        autoplaySpeed: false,
      },
    },
    valueText: [],
  },

  layout: Layout13,
}; */
ListSlideView.defaultProps = {
  childOf: "Content",
  values: {
    type: "ListSlideView",
    title: "Recommended Products",
    mobile: {
      show: true,
      header: {
        show: true,
        title: "Shop by Sports",
        padding: "16px 10px 10px 10px",
        fontSize: 12,
        showViewAll: true,
      },
      setting: {
        dots: false,
        rows: 0,
        speed: 200,
        arrows: false,
        autoplay: false,
        infinite: false,
        slidesPerRow: 0,
        slidesToShow: 2,
        autoplaySpeed: false,
        adaptiveHeight: true,
        slidesToScroll: 2,
      },
      listProps: {
        padding: "0px 8px 0px 8px",
        fontSize: 12,
        urlViewAll: "",
        titlePadding: "4px 0px 4px 0px",
        showViewAllBottom: false,
      },
      colorProps: {
        color: "rgba(0,0,0,1)",
        hoverColor: "rgba(0,0,0,1)",
        backgroundColor: "rgba(255,255,255,0)",
        hoverBackgroundColor: "rgba(255,255,255,0)",
      },
    },
    parent: "Content",
    desktop: {
      show: true,
      header: {
        show: true,
        padding: "16px 10px 10px 10px",
        fontSize: 14,
        showViewAll: true,
      },
      setting: {
        dots: false,
        rows: 0,
        speed: 200,
        arrows: false,
        autoplay: false,
        infinite: false,
        slidesPerRow: 0,
        slidesToShow: 4,
        autoplaySpeed: false,
        adaptiveHeight: true,
        slidesToScroll: 4,
      },
      listProps: {
        padding: "0px 10px 0px 10px",
        fontSize: 14,
        titlePadding: "10px 0px 10px 0px",
        showViewAllBottom: false,
      },
    },
    modeType: "Collection",
    valueText: [],
    colorProps: {
      color: "rgba(0,0,0,1)",
      hoverColor: "rgba(0,0,0,1)",
      viewallColor: "rgba(0,0,0,1)",
      backgroundColor: "rgba(255, 255, 255, 1)",
      hoverBackgroundColor: "rgba(255, 255, 255, 1)",
    },
    urlViewAll: "",
    contentList: {
      tags: [
        "MW01",
        "FA02",
        "FA01",
        "FA03",
        "NA01",
        "AP05",
        "AP06",
        "AP20",
        "AP07",
        "AP04",
        "AP01",
        "AP03",
        "AP28",
        "AP24",
        "AP02",
        "AP26",
        "FT01",
        "FT02",
        "FT20",
        "FT03",
        "FT04",
        "FT17",
        "AC24",
        "AC02",
        "AC01",
        "AC05",
        "AC03",
        "AC04",
        "AC22",
        "AC06",
        "AC26",
        "AC07",
        "AC08",
        "AC21",
        "SBB",
        "BR01",
      ],
      value: 6,
      showPrice: [true, true],
    },
    itemSetting: {
      limit: [20, 20],
      subPadding: ["0px", "0px"],
      subFontSize: [12, 12],
      titlePadding: ["0px", "0px"],
      titleFontSize: [12, 12],
      containerPadding: ["0px", "0px"],
    },
    linkViewAll: {
      type: "Category",
      value: 174,
      isLink: true,
      target: "",
    },
    titleViewAll: "Show All",
  },

  layout: Layout13,
};

export default ListSlideView;
