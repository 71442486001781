import React from "react"
import PropTypes from "prop-types"
import { Box } from "rebass"
import { Typography } from "@material-ui/core"

export function TabPanel(props) {
  const { children, value, index, name, padding = 2, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`${name}-tabs-list-${index}`}
      aria-labelledby={`${name}-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={padding}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
}

export function a11yProps(name, index) {
  return {
    id: `${name}-width-tab-${index}`,
    "aria-controls": `${name}-tabs-list-${index}`,
  }
}
