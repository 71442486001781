import React from "react"
import { showNotification, useQuery } from "react-admin"
import { withRouter } from "react-router-dom"
import { Box } from "rebass"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { CreateForm } from "./components"
import { FullLoadingScreen, CardPanel } from "../../components"

const CreateFAQ = (props) => {
  const { match } = props
  const { data, loading } = useQuery({
    type: "getOne",
    resource: "websites",
    payload: { id: match.params.website_id },
  })

  if (loading) {
    return <FullLoadingScreen />
  }
  // console.log(data)
  const initialValues = {
    website_id: data.id,
    site_id: data.site_id,
  }
  return (
    <CardPanel>
      <Box width={[1]}>
        <CreateForm
          {...props}
          sourceApi="topics"
          typePostUrl={"CREATE"}
          pathUrl="faq"
          initialValues={initialValues}
          website={data}
        />
      </Box>
    </CardPanel>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(CreateFAQ)
