import React from "react";
import { Tree } from "antd";
import { Box } from "rebass";
import { has, get } from "lodash";
import { listCategories } from "./utils";
export default (props) => {
  const { dataSource, value, onChange } = props;
  const listTree = listCategories(dataSource);
  const onSelect = (selectedKeys, info) => {
    if (has(info, "node.key")) {
      const slug = get(info, "node.category.slug", "");
      onChange(info.node.key, slug);
    }
  };
  return (
    <Box width={[1]}>
      <Tree
        showLine={{ showLeafIcon: false }}
        defaultSelectedKeys={[value]}
        defaultExpandedKeys={[value]}
        onSelect={onSelect}
        treeData={listTree}
        defaultExpandAll={true}
      />
    </Box>
  );
};
