import React from "react"
import { useTranslate } from "react-admin"
import { Flex, Box } from "rebass"
// import { lightBlue } from "@material-ui/core/colors"
import { ListButton } from "../buttons"
import { Title } from "../Text"

export const ActionForm = (props) => {
  const translate = useTranslate()
  const { pt = 3 } = props
  return (
    <Flex
      width={[1]}
      p={[pt]}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box width={[1]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(props.title)}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        <ListButton
          basepath={props.basePath}
          record={props.data}
          color={"primary"}
          // style={{ color: lightBlue[700] }}
        />
      </Flex>
    </Flex>
  )
}
