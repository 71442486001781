import React, { Fragment } from "react";
import { get } from "lodash";
import { Flex } from "rebass";
import { FontelloSvg } from "../../../components/Icons";
import { HrefContainer } from "../hrefUrl";
export default (props) => {
  const { item } = props;
  const { desktop, type, color } = item.content;
  const size = get(desktop, "size", 10);
  const isShow = get(desktop, "show", true);
  const isShowLabel = get(desktop, "showLabel", true);
  const align = get(desktop, "align", "flex-start");
  if (!isShow) {
    return <Fragment />;
  }
  if (isShowLabel) {
    return (
      <HrefContainer link={get(item, "link", { isLink: false })}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={align}
          alignItems={"center"}
          style={{
            padding: desktop.containerPadding,
          }}
        >
          <FontelloSvg
            name={type ? type : ""}
            fill={color ? color : "#000000"}
            size={`${size}px`}
          />
          <span
            style={{
              textTransform: "capitalize",
              fontSize: `${size}px`,
              color: color ? color : "#000000",
              paddingLeft: 8,
            }}
          >
            {type}
          </span>
        </Flex>
      </HrefContainer>
    );
  }

  return (
    <div
      style={{
        padding: desktop.containerPadding,
      }}
    >
      <HrefContainer link={get(item, "link", { isLink: false })}>
        <FontelloSvg
          name={type ? type : ""}
          fill={color ? color : "#000000"}
          size={`${size}px`}
        />
      </HrefContainer>
    </div>
  );
};
