import React from "react"
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd"
import { Avatar } from "@material-ui/core"
import AvatarIcon from "@material-ui/icons/AccountCircle"
import { connect } from "../../core"
import { target } from "./utils"
const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
}) => {
  const { containerPadding, url, size, align } = layoutState.getItemJS(
    id
  ).props.values
  const srcAvatar =
    url || "https://via.placeholder.com/200x200?text=200x200=CMS"
  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        display: "flex",
        padding: containerPadding,
        flex: 1,
        justifyContent: align,
      }}
    >
      <Avatar
        icon={<AvatarIcon />}
        color="#FFFFFF"
        src={srcAvatar}
        style={{ backgroundColor: "#CFD8DC" }}
        size={size}
      />
    </div>
  )
}

const LayoutContainer = DropTarget("AvatarView", target, (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(EditLayout)

export default connect("layoutState", "readOnly")(LayoutContainer)
