import React, { useState, useEffect } from "react"
import { Box } from "rebass"
import { get } from "lodash"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import SwipeableViews from "react-swipeable-views"
import { Divider, Tabs, Tab } from "@material-ui/core"
import { useGetOne, Loading } from "react-admin"
import { RaTextInput, RaTextArea, RTDescriptionProduct } from "./"
import { languages, choicesLanguages } from "./"
import { TabPanel, a11yProps } from "./"

const toolbar = [
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ align: [] }],
]

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}))

let LocaleSiteId = 0

export const LocaleAtributes = ({ formData, site_id, ...rest }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)
  const [localesList, setLocalesList] = useState({
    default_locale: "en",
    locale: languages,
  })
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  const { data, loading } = useGetOne("sites", site_id)

  useEffect(() => {
    let timeout = setTimeout(() => {
      // console.log(LocaleSiteId !== formData.site_id)
      if (data && LocaleSiteId !== site_id) {
        // console.log(localesList)
        let newLanguage = data.locales.map(function (e) {
          let f = choicesLanguages.find((a) => a.id === e)
          return f ? f : undefined
        })
        // setTabId(data.default_locale)
        setLocalesList({
          default_locale: data.default_locale,
          locale: newLanguage,
        })
        LocaleSiteId = site_id
      }
    }, 300)
    return () => clearTimeout(timeout)
  })
  if (loading) {
    return <Loading />
  }
  if (localesList && localesList.locale && localesList.locale.length === 1) {
    const item = localesList.locale[0]
    return (
      <div className={classes.root}>
        <ContentItem locale={item.id} formData={formData} {...rest} />
      </div>
    )
  }
  return (
    <div className={classes.root}>
      <Tabs value={value} indicatorColor="primary" onChange={handleChange}>
        {localesList &&
          localesList.locale &&
          localesList.locale.map((choice, index) => (
            <Tab
              key={choice.id}
              label={choice.name}
              {...a11yProps("localeAttributes", index)}
            />
          ))}
      </Tabs>
      <Divider />
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {localesList &&
          localesList.locale &&
          localesList.locale.map((item, index) => (
            <TabPanel
              key={index}
              value={value}
              index={index}
              dir={theme.direction}
              name={"localeAttributes"}
            >
              <ContentItem
                locale={item.id}
                key={index}
                formData={formData}
                {...rest}
              />
            </TabPanel>
          ))}
      </SwipeableViews>
    </div>
  )
}

const ContentItem = ({ locale, formData, ...rest }) => {
  const isNumber = get(formData, "data_type", "") === "Number"
  const isRichText = get(formData, "data_type", "") === "Rich Text"
  const isText = get(formData, "data_type", "") !== "Rich Text"
  // const onChangeName = (e, v, key) => {
  //   if (key === "en") {
  //     rest.updateField("name", e.target.value)
  //   }
  // }
  const onChangeName = (key, name) => (e) => {
    if (key === "en") {
      rest.updateField(name, e.target.value)
    }
  }
  return (
    <Box width={[1]}>
      <RaTextInput
        source={`locale.${locale}.name`}
        label={"resources.attributes.fields.name"}
        onChange={onChangeName(locale, "name")}
        fullWidth={true}
      />
      {isText && (
        <RaTextArea
          source={`locale.${locale}.description`}
          label={"resources.attributes.fields.description"}
          onChange={onChangeName(locale, "description")}
          style={{ width: 400 }}
          rows={4}
          rowsMax={4}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      {isRichText && (
        <RTDescriptionProduct
          resource={"attributes"}
          source={`locale.${locale}.description`}
          label={"resources.attributes.fields.description"}
          toolbar={toolbar}
        />
      )}
      {isNumber && (
        <RaTextInput
          source={`locale.${locale}.unit`}
          label={"resources.attributes.fields.unit"}
          notetext={"resources.attributes.note.unit"}
        />
      )}
    </Box>
  )
}
