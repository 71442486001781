import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import { UploadImage } from "../../../components";
import {
  BlockCounter,
  Counter,
  Alignment,
  Title,
  SwitchInput,
  HrefUrl,
} from "../components";
import { PanelCard, ContentDetail } from "../actions";

export const PanelImage = (props) => {
  const { state, onSetValue, data } = props;
  const { type } = data.values;

  const onChangeHref = (k, v) => {
    const newHref = {
      ...state.href,
      [k]: v,
    };
    onSetValue("href", newHref);
  };
  const target = get(state, "href.target", "_blank");
  const valueTarget = target === "_blank" ? target : "same";
  return (
    <PanelCard title={"Image"} id={"upload-image"}>
      <ContentDetail>
        <Title title='Upload Image' />
        <UploadImage
          type={type}
          imageUrl={state.imageUrl}
          onUpdateUrl={(value) => onSetValue("imageUrl", value)}
          title='Image'
        />
      </ContentDetail>
      <ContentDetail>
        <Alignment
          value={state.align}
          onChange={(value) => onSetValue("align", value)}
          title='Alignment'
        />
      </ContentDetail>
      <HrefUrl
        url={get(state, "href.url", "")}
        valueTarget={valueTarget}
        onChangeText={(value) => onChangeHref("url", value)}
        onChangeSelect={(e) =>
          onChangeHref(
            "target",
            e.target.value === "_blank" ? e.target.value : ""
          )
        }
      />
    </PanelCard>
  );
};

export const PanelGeneral = (props) => {
  const {
    state,
    onChangeAlign,
    onSetValue,
    onChangeTarget,
    onToggleShowDesktop,
    onToggleShowMobile,
    onUpdateHrefUrl,
  } = props;
  const { target } = state.href;
  const valueTarget = target === "_blank" ? target : "same";

  return (
    <PanelCard title={"General"} id={"general"}>
      <Flex width={[1]} flexDirection={"column"}>
        <BlockCounter
          title='Container Padding'
          containerPadding={state.containerPadding}
          onUpdatePadding={(containerPadding) =>
            onSetValue("containerPadding", containerPadding)
          }
        />
        <ContentDetail>
          <Alignment
            value={state.align}
            onChange={(value) => onChangeAlign(value)}
            title='Alignment'
          />
        </ContentDetail>
        <ContentDetail>
          <Flex
            flexDirection={"row"}
            width={[1]}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Title title='MarginTop' />
            <Counter
              isDisabled={false}
              onSetValue={(value) => onSetValue("marginTop", value)}
              value={state.marginTop}
              interval={1}
            />
          </Flex>
        </ContentDetail>
        <ContentDetail>
          <Flex
            flexDirection={"row"}
            width={[1]}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Title title='MarginBottom' />
            <Counter
              isDisabled={false}
              onSetValue={(value) => onSetValue("marginBottom", value)}
              value={state.marginBottom}
              interval={1}
            />
          </Flex>
        </ContentDetail>
        <ContentDetail>
          <Title title={"Show Image"} />
          <Flex
            flexDirection={"row"}
            width={[1]}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
          >
            <SwitchInput
              id={`showDesktop`}
              title='Show on Desktop'
              checked={state.isShowDesktop}
              onChange={(e) => onToggleShowDesktop(e)}
            />
            <SwitchInput
              id={`showMobile`}
              title='Show on Mobile'
              checked={state.isShowMobile}
              onChange={(e) => onToggleShowMobile(e)}
            />
          </Flex>
        </ContentDetail>
        <HrefUrl
          url={state.href.url}
          valueTarget={valueTarget}
          onChangeText={(value) => onUpdateHrefUrl(value)}
          onChangeSelect={(e) => onChangeTarget(e)}
        />

        {/* <ContentDetail>
                  <Title title={"Animation Direction"} />
                  <SwitchInput
                    title="Show"
                    checked={this.state.animation.show}
                    onChange={e => this.onToggleShowAnimation(e)}
                  />
                  <SelectInput
                    id={`animation-direction`}
                    title={"Direction"}
                    list={transitionsAnimation}
                    value={this.state.animation.direction}
                    onChange={(e, v) => this.handleChange(e, v)}
                  />
                  <DialogAnimation
                    onItemSelected={value => this.onSelectedAnimation(value)}
                  />

                  <Counter
                    isDisabled={false}
                    title="Duration"
                    onSetValue={value => this.onDuration(value)}
                    value={this.state.animation.duration}
                    interval={1}
                    subtitle="ms"
                    minValue={100}
                  />
                </ContentDetail> */}
      </Flex>
    </PanelCard>
  );
};
