import React from "react";
import { Flex } from "rebass";
import { BooleanInput, ReferenceInput, NumberInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import {
  PaperCard,
  RaTextArea,
  RaAutoCompleteInput,
  RaSelectArrayInputOptions,
} from "../../../../components";
import { dataOrderStatus } from "../data";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

const PoliciesFields = (props) => {
  const classes = useStyles();
  const { translate } = props;
  return (
    <Flex width={[1]} flexDirection={"column"} py={[2]}>
      <Flex width={[1]} flexDirection={"column"} p={[2]}>
        <PaperCard
          title={translate("resources.websites.labels.cancelation")}
          showButton={false}
          elevation={3}
          bgHeader={"#eeeeee"}
          propsHeader={{ p: [3] }}
        >
          <Flex width={[1]} flexDirection={"column"} p={[2]}>
            <BooleanInput
              source={"policy.cancellation.allow_cancel"}
              label={"resources.websites.fields.allow_cancel"}
            />
            <RaSelectArrayInputOptions
              className={classes.root}
              source="policy.cancellation.order_status"
              label={translate("resources.websites.fields.order_status")}
              choices={dataOrderStatus}
              fullWidth={true}
              notetext="resources.websites.note.order_status"
            />
            <ReferenceInput
              label="resources.websites.fields.page_id"
              source="page_id"
              reference="contents"
            >
              <RaAutoCompleteInput optionText="title" />
            </ReferenceInput>
            <RaTextArea
              source={`policy.cancellation.message`}
              label={"resources.websites.fields.message"}
              rows="6"
              fullWidth
              multiline
              InputLabelProps={{
                shrink: true,
                disableAnimation: true,
              }}
              style={{ transform: "none" }}
            />
          </Flex>
        </PaperCard>
      </Flex>
      <Flex width={[1]} flexDirection={"column"} p={[2]}>
        <PaperCard
          title={translate("resources.websites.labels.return")}
          showButton={false}
          elevation={3}
          bgHeader={"#eeeeee"}
          propsHeader={{ p: [3] }}
        >
          <Flex width={[1]} flexDirection={"column"} p={[2]}>
            <BooleanInput
              source={"policy.return.allow_return"}
              label={"resources.websites.fields.allow_return"}
            />
            <NumberInput
              source={"policy.return.number_of_delivered"}
              label={"resources.websites.fields.number_of_day_after_delivered"}
              style={{ width: 300 }}
            />
            <ReferenceInput
              label="resources.websites.fields.return_page_id"
              source="page_id"
              reference="contents"
            >
              <RaAutoCompleteInput optionText="title" />
            </ReferenceInput>
            <RaTextArea
              source={`policy.return.message`}
              label={"resources.websites.fields.return_message"}
              rows="6"
              fullWidth
              multiline
              InputLabelProps={{
                shrink: true,
                disableAnimation: true,
              }}
              style={{ transform: "none" }}
            />
          </Flex>
        </PaperCard>
      </Flex>
    </Flex>
  );
};
export default PoliciesFields;
