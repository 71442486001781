import debounce from "lodash/debounce"
import React, { useRef, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import Quill from "quill"
import { useInput, FieldTitle, useNotify } from "ra-core"
import { InputHelperText } from "ra-ui-materialui"
import { FormHelperText, FormControl, InputLabel } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ImageUpload } from "./ImageUpload"

import styles from "./styles"

const useStyles = makeStyles(styles, { name: "RTDescription" })

const RTDescription = ({
  options = {}, // Quill editor options
  record = {},
  toolbar = true,
  fullWidth = true,
  configureQuill,
  helperText,
  label,
  source,
  resource,
  variant,
  margin = "dense",
  ...rest
}) => {
  const notify = useNotify()
  const classes = useStyles()
  const quillInstance = useRef()
  const divRef = useRef()
  const editor = useRef()

  const {
    id,
    isRequired,
    input: { value, onChange },
    meta: { touched, error },
  } = useInput({ source, ...rest })

  const lastValueChange = useRef(value)

  const onTextChange = useCallback(
    debounce(() => {
      const value =
        editor.current.innerHTML === "<p><br></p>"
          ? ""
          : editor.current.innerHTML
      lastValueChange.current = value
      onChange(value)
    }, 500),
    []
  )

  useEffect(() => {
    Quill.register(
      {
        "modules/imageUpload": ImageUpload,
      },
      true
    )
    quillInstance.current = new Quill(divRef.current, {
      modules: {
        toolbar,
        clipboard: { matchVisual: false },
        imageUpload: {
          resource: resource ? resource : "products",
          /**
           * personalize successful callback and call next function
           * to insert new url to the editor
           */
          callbackOK: (serverResponse, next) => {
            // console.log(serverResponse)
            if (serverResponse !== "") {
              const range = quillInstance.current.getSelection()
              const index = range.index + range.length
              quillInstance.current.insertEmbed(index, "image", serverResponse)
            }
            next(serverResponse)
          },
          // personalize failed callback
          callbackKO: serverError => {
            console.error(serverError)
            const message =
              serverError && serverError.body
                ? serverError.body
                : "Please try again"
            notify(`${message}`, "warning")
          },
          // optional
          // add callback when a image have been chosen
          checkBeforeSend: (file, next) => {
            const limit = 3
            const limitFileSize = megabytes => {
              const mb = 1024
              megabytes *= mb
              const isExceeding = file.size / mb > megabytes
              const fileSize = (file.size / mb / mb).toFixed(2)
              return { isExceeding, fileSize }
            }

            if (limitFileSize(limit).isExceeding) {
              // console.error(`File is exceeding limit size.`)
              notify("File is exceeding limit size.", "warning")
              return next(file)
            }
            return next(file)
          },
        },
      },
      theme: "snow",
      ...options,
    })

    if (configureQuill) {
      configureQuill(quillInstance.current)
    }

    quillInstance.current.setContents(
      quillInstance.current.clipboard.convert(value)
    )

    editor.current = divRef.current.querySelector(".ql-editor")
    quillInstance.current.on("text-change", onTextChange)

    return () => {
      quillInstance.current.off("text-change", onTextChange)
      onTextChange.cancel()
      quillInstance.current = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (lastValueChange.current !== value) {
      const selection = quillInstance.current.getSelection()
      quillInstance.current.setContents(
        quillInstance.current.clipboard.convert(value)
      )
      if (selection && quillInstance.current.hasFocus()) {
        quillInstance.current.setSelection(selection)
      }
    }
  }, [value])

  return (
    <FormControl
      error={!!(touched && error)}
      fullWidth={fullWidth}
      className="ra-rich-text-input-description"
      margin={margin}
    >
      {label !== "" && label !== false && (
        <InputLabel shrink htmlFor={id} className={classes.label}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
      )}
      <div data-testid="quill" ref={divRef} className={variant} />
      <FormHelperText
        error={!!error}
        className={!!error ? "ra-rich-text-input-error" : ""}
      >
        <InputHelperText
          error={error}
          helperText={helperText}
          touched={touched}
        />
      </FormHelperText>
    </FormControl>
  )
}

RTDescription.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  source: PropTypes.string,
  toolbar: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.shape({
      container: PropTypes.array,
      handlers: PropTypes.object,
    }),
  ]),
  fullWidth: PropTypes.bool,
  configureQuill: PropTypes.func,
}

export default RTDescription
