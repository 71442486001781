import React from "react"
import { useTranslate, FormDataConsumer } from "react-admin"
import { Field } from "react-final-form"
import { Box, Flex, Text } from "rebass"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { useStyles } from "./utils"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"

const CheckBoxField = ({ name, label }) => {
  //
  // translate("resources.webhooks.fields.created")
  return (
    <Field name={name} type={"checkbox"}>
      {(props) => (
        <Box pr={[2]}>
          <FormControlLabel
            control={
              <Checkbox
                icon={
                  <CheckCircleOutlineIcon fontSize="small" color={"primary"} />
                }
                checkedIcon={
                  <CheckCircleIcon fontSize="small" color={"primary"} />
                }
                checked={props.input.checked}
                onChange={props.input.onChange}
                name={props.input.name}
                color="primary"
              />
            }
            label={<Text fontSize={[0]}>{label}</Text>}
          />
        </Box>
      )}
    </Field>
  )
}

const Events = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="event-fields-content"
                id="event-fields-header"
              >
                <Typography className={classes.heading}>
                  {translate("resources.webhooks.label.events")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Flex width={[1]} flexDirection={"column"}>
                  <Box width={[1]} py={[1]}>
                    <Text fontSize={[0]} fontWeight={"bold"}>
                      {translate("resources.webhooks.label.order")}
                    </Text>
                    <FormGroup row>
                      <CheckBoxField
                        name={"formData.events.order.created"}
                        label={translate("resources.webhooks.fields.created")}
                      />
                      <CheckBoxField
                        name={"formData.events.order.updated"}
                        label={translate("resources.webhooks.fields.updated")}
                      />
                    </FormGroup>
                  </Box>
                  <Box width={[1]} py={[1]}>
                    <Text fontSize={[0]} fontWeight={"bold"}>
                      {translate("resources.webhooks.label.customer")}
                    </Text>
                    <FormGroup row>
                      <CheckBoxField
                        name={"formData.events.customer.created"}
                        label={translate("resources.webhooks.fields.created")}
                      />
                      <CheckBoxField
                        name={"formData.events.customer.updated"}
                        label={translate("resources.webhooks.fields.updated")}
                      />
                    </FormGroup>
                  </Box>
                  <Box width={[1]} py={[1]}>
                    <Text fontSize={[0]} fontWeight={"bold"}>
                      {translate("resources.webhooks.label.product")}
                    </Text>
                    <FormGroup row>
                      <CheckBoxField
                        name={"formData.events.product.created"}
                        label={translate("resources.webhooks.fields.created")}
                      />
                      <CheckBoxField
                        name={"formData.events.product.updated"}
                        label={translate("resources.webhooks.fields.updated")}
                      />
                    </FormGroup>
                  </Box>
                </Flex>
              </AccordionDetails>
            </Accordion>
          )
        }}
      </FormDataConsumer>
    </div>
  )
}
export default Events
