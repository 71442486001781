import React from "react";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import { get } from "lodash";
import { Image, Flex, Box } from "rebass";
import { TextAnimation, defaultSubtitle, defaultTitle } from "./Text";
const defaultContainerTitle = {
  parent: {
    alignItems: ["center", "center"],
    direction: ["column", "row"],
    justifyContent: ["center", "flex-end"],
  },
  title: {
    width: ["100%", "50%"],
    justifyContent: ["center", "center"],
    direction: ["column", "column"],
    alignItems: ["center", "flex-start"],
  },
};
const LeftImageElement = (props) => {
  const { data, id, matches, widthContainer } = props;
  const boxImage = get(data, "position.boxImage", "486px");
  const isAnimation = false; //get(data, "image.isAnimation", false)
  const animationImage = isAnimation
    ? {
        type: get(data, "image.animation.type", "from"),
        ease: get(data, "image.animation.ease", "easeInQuart"),
        opacity: get(data, "image.animation.opacity", 0),
        delay: get(data, "image.animation.delay", 40),
        duration: get(data, "image.animation.duration", 1500),
      }
    : {};

  const useMobile = matches
    ? get(
        data,
        "url_image_mobile",
        get(
          data,
          "url_image",
          "https://via.placeholder.com/400x400?text=400x400=CMS"
        )
      )
    : get(
        data,
        "url_image",
        "https://via.placeholder.com/480x480?text=480x480=CMS"
      );

  const title = get(data, "title", defaultTitle);
  const subtitle = get(data, "subtitle", defaultSubtitle);
  const visibleTitle = get(title, "visible", true);
  const visibleSubtitle = get(subtitle, "visible", true);
  const visibleImage = get(data, "image.visible", true);
  //only for dnd layout using desktop
  const marginTitle = get(data, "position.marginTitleDesktop", "0px");
  const paddingTitle = get(data, "position.paddingTitleDesktop", "0px");
  const marginImage = get(data, "position.marginImageDesktop", "0px");
  const paddingImage = get(data, "position.paddingImageDesktop", "0px");
  const containerParent = get(
    data,
    "containerTitle.parent",
    defaultContainerTitle.parent
  );
  const boxTitle = get(
    data,
    "containerTitle.title",
    defaultContainerTitle.title
  );
  return (
    <Flex
      width={[1]}
      flexDirection={"column"}
      justifyContent={"center"}
      style={{ height: "100%" }}
    >
      <QueueAnim name={`Queue-${data.name}-${id}`}>
        <Flex
          width={[1]}
          flexDirection={containerParent.direction}
          alignItems={containerParent.alignItems}
          justifyContent={containerParent.justifyContent}
        >
          {visibleImage && (
            <Flex width={[1]}>
              <TweenOne
                animation={animationImage}
                name={`TweenOne-image-left-${data.name}-${id}`}
              >
                <Box
                  width={[1, 1, boxImage]}
                  style={{ padding: paddingImage, margin: marginImage }}
                >
                  <Image src={useMobile} />
                </Box>
              </TweenOne>
            </Flex>
          )}
          {(visibleTitle || visibleSubtitle) && (
            <Flex
              width={boxTitle.width}
              flexDirection={boxTitle.direction}
              alignItems={boxTitle.alignItems}
              justifyContent={boxTitle.justifyContent}
              style={{ padding: paddingTitle, margin: marginTitle }}
            >
              {visibleTitle && (
                <TextAnimation data={title} widthContainer={widthContainer} />
              )}
              {visibleSubtitle && (
                <TextAnimation
                  data={subtitle}
                  widthContainer={widthContainer}
                />
              )}
            </Flex>
          )}
        </Flex>
      </QueueAnim>
    </Flex>
  );
};

export default LeftImageElement;
