import React from "react";
import { isEmpty, get } from "lodash";
import { connect } from "../../../core";
import { defaultBorder, ButtonView } from "./utils/FlatButtonUtil";
import { HrefContainer } from "../../../libs/hrefUrl";
const FlatButtonView = (props) => {
  const { id, layoutState, widthScreen } = props;
  const {
    icon,
    text,
    link,
    labelPosition,
    typeButton,
    border,
    desktop,
    mobile,
    isShowDesktop,
    isShowMobile,
  } = layoutState.getItemJS(id).props.values;
  const isBorder = border
    ? `${border.size}px ${border.type} ${border.color}`
    : `${defaultBorder.size}px ${defaultBorder.type} ${defaultBorder.color}`;
  const childIcon = !isEmpty(icon) ? null : null;
  let linkUrl = link.url;
  const borderRadius = get(border, "radius", "0")
    ? `${get(border, "radius", 0)}px`
    : "0px";
  const newChildIcon = childIcon && childIcon !== null ? childIcon : "";
  const viewScreen = widthScreen > 480 ? desktop : mobile;
  const buttonAlign = get(viewScreen, "buttonAlign", "center");
  const containerPadding = get(viewScreen, "containerPadding", "0px");
  const isShow = widthScreen > 480 ? isShowDesktop : isShowMobile;
  const childButton =
    linkUrl !== "" && link.isLink ? (
      <HrefContainer link={link}>
        <ButtonView
          newChildIcon={newChildIcon}
          typeButton={typeButton}
          isBorder={isBorder}
          buttonColors={get(viewScreen, "buttonColors", "transparent")}
          text={text}
          labelPosition={labelPosition}
          fullWidth={get(viewScreen, "fullWidth", false)}
          defaultBorderSize={defaultBorder.size}
          borderRadius={borderRadius}
          buttonPadding={get(viewScreen, "buttonPadding", "0px")}
        />
      </HrefContainer>
    ) : (
      <ButtonView
        newChildIcon={newChildIcon}
        typeButton={typeButton}
        isBorder={isBorder}
        buttonColors={get(viewScreen, "buttonColors", "transparent")}
        text={text}
        labelPosition={labelPosition}
        fullWidth={get(viewScreen, "fullWidth", false)}
        defaultBorderSize={defaultBorder.size}
        borderRadius={borderRadius}
        buttonPadding={get(viewScreen, "buttonPadding", "0px")}
      />
    );
  if (!isShow) {
    return <div />;
  }
  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "auto",
        display: "flex",
        width: "100%",
        justifyContent: buttonAlign,
        padding: containerPadding,
      }}
    >
      {childButton}
    </div>
  );
};

export default connect("layoutState", "widthScreen")(FlatButtonView);
