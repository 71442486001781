import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { find, get } from "lodash"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemText from "@material-ui/core/ListItemText"
import lightBlue from "@material-ui/core/colors/lightBlue"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { useQueryWithStore, useRefresh } from "react-admin"
import CircularProgress from "@material-ui/core/CircularProgress"

import { SITE_ID, getSiteID } from "../../../restClient"

const payload = {
  pagination: { page: 1, perPage: 30 },
  sort: { field: "name", order: "ASC" },
}

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

export default function SelectSiteID(props) {
  const refresh = useRefresh()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const { loading, data } = useQueryWithStore({
    type: "getList",
    resource: "sites",
    payload: payload,
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleGetItem = (item) => {
    // console.log(item)
    if (getSiteID() !== item.id) {
      localStorage.setItem(SITE_ID, item.id)
      refresh()
    }

    handleClose()
  }

  if (loading) {
    return <CircularProgress size={24} />
  }
  if (data === undefined || (data && data.length === 0)) {
    return <div />
  }
  const site = find(data, function (o) {
    return o.id === getSiteID()
  })

  return (
    <div>
      <Button
        aria-controls="site-id-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        style={{
          textTransform: "capitalize",
          color: lightBlue["500"],
          borderRadius: 16,
        }}
      >
        {get(site, "name", "")}
      </Button>
      <StyledMenu
        id="site-id-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <StyledMenuItem onClick={() => handleGetItem(item)} key={index}>
              <ListItemText primary={item.name} />
            </StyledMenuItem>
          ))}
      </StyledMenu>
    </div>
  )
}
