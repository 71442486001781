import React from "react";
import { Flex } from "rebass";
import { Input } from "antd";
const { Search } = Input;

const SearchBox4 = (props) => {
  const { width } = props;
  // console.log(width);
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {/* <Text fontSize={[2]} fontWeight={'bold'}>
        Search
      </Text> */}
      <Search
        placeholder='search'
        onSearch={(value) => console.log(value)}
        style={{
          width: width ? width : "60%",
          borderRadius: 16,
          backgroundColor: "transparent",
        }}
      />
    </Flex>
  );
};
export default SearchBox4;
