/* eslint-disable react-hooks/rules-of-hooks */
import { useGetOne } from "react-admin"
export const choicesLanguages = [
  { id: "en", name: "English" },
  { id: "th", name: "Thai" },
  { id: "id", name: "Bahasa" },
  { id: "zh_CN", name: "简体" },
]

export const labelLanguage = [
  { en: "English" },
  { th: "Thai" },
  { id: "Bahasa" },
  { zh_CN: "简体" },
]
export const labelObject = {
  en: "English",
  th: "Thai",
  id: "Bahasa",
  zh_CN: "简体",
}

export const languages = [{ id: "en", name: "English" }]

export const languagesListSiteId = ({ id }) => {
  const { data, loading, error } = useGetOne("sites", id)
  if (loading) {
    return []
  }
  if (error) {
    return []
  }
  if (data) {
    let newLanguage = data.locales.map(function (e) {
      let f = choicesLanguages.find((a) => a.id === e)
      return f ? f : undefined
    })
    // console.log(newLanguage);
    return { default_locale: data.default_locale, locale: newLanguage }
  }
  return { default_locale: "en", locale: languages }
}
