import React from "react";
import { Flex, Box } from "rebass";
import { get, find } from "lodash";
import { useQuery } from "../../../../../components";
import { ContentDetail } from "../../actions";
import {
  Title,
  TextInput,
  SelectInput,
  SwitchInput,
  dataTypeLink,
  SelectTargetInput,
  SelectInputFields,
} from "../";
import CategoryFields from "./CategoryFields";
import { getSortList } from "./utils";
/**
 * link: {
      target: "",
      type: "URL",
      value: "",
      isLink: false,
    },
 */

export default (props) => {
  const {
    link,
    onSetValue,
    websiteId,
    keyContent = "link",
    title = "Action",
  } = props;
  const categoriesList = useQuery(`/categories`);
  const collectionList = useQuery(`/collections`);
  const contentList = useQuery(`/contents?website_id=${websiteId}`);
  const onSetItemValue = (k, v) => {
    const params = {
      ...link,
      [k]: v,
    };
    onSetValue(keyContent, params);
  };
  const onSetItemValueSlug = (v, s) => {
    const params = {
      ...link,
      value: v,
      slug: s,
    };
    onSetValue(keyContent, params);
  };

  const onSetItemContentValueSlug = (v, list) => {
    const item = find(list, function (o) {
      return o.id * 1 === v * 1;
    });
    const params = {
      ...link,
      value: v,
      slug: get(item, "slug", ""),
    };
    onSetValue(keyContent, params);
  };

  const onSetType = (v) => {
    const params = {
      ...link,
      type: v,
      value: "",
    };
    onSetValue(keyContent, params);
  };
  const value = get(link, "value", "");
  const target = get(link, "target", "");
  const typeLink = get(link, "type", "URL");
  const isLink = get(link, "isLink", false);
  const listContent = getSortList(get(contentList, "data", []), "label", "asc");
  const listCategories = getSortList(
    get(categoriesList, "data", []),
    "name",
    "asc"
  );
  const listCollections = getSortList(
    get(collectionList, "data", []),
    "name",
    "asc"
  );
  // console.log(collectionList);
  return (
    <ContentDetail>
      <Title title={title} />
      <SwitchInput
        id={`link-text-href-url`}
        title={"Use link"}
        checked={isLink}
        message={"Please checked when Using 'Https://' or outside url"}
        onChange={(e) => onSetItemValue("isLink", e.target.checked)}
      />
      {isLink && (
        <Flex width={[1]} flexDirection={"column"}>
          <Box width={[1]}>
            <SelectTargetInput
              id={`target-link-url`}
              title={"Target"}
              value={target}
              onChange={(value) => onSetItemValue("target", value)}
            />
          </Box>
          <Box width={[1]}>
            <SelectInput
              id={`type-link-url`}
              title={"Type"}
              value={typeLink}
              list={dataTypeLink}
              onChange={(e) => onSetType(e.target.value)}
            />
          </Box>
          <Flex width={[1]} flexDirection={"column"}>
            {typeLink === "Content" && (
              <SelectInputFields
                id={`content-link-url`}
                title={"Content Url"}
                label={"title"}
                value={value}
                list={listContent}
                onChange={(e) =>
                  onSetItemContentValueSlug(e.target.value, listContent)
                }
              />
            )}

            {typeLink === "Category" &&
              listCategories &&
              listCategories.length > 0 && (
                <CategoryFields
                  dataSource={listCategories}
                  value={value}
                  onChange={(v, s) => onSetItemValueSlug(v, s)}
                />
              )}
            {typeLink === "Collection" && (
              <SelectInputFields
                id={`collection-link-url`}
                title={"Collection"}
                value={value}
                list={listCollections}
                onChange={(e) =>
                  onSetItemContentValueSlug(e.target.value, listCollections)
                }
              />
            )}
            {typeLink === "URL" && (
              <Box width={[1]} px={[1]}>
                <TextInput
                  value={value}
                  onChange={(value) => onSetItemValue("value", value)}
                  name='Url'
                  title='Url'
                  fullWidth={true}
                />
              </Box>
            )}
            {typeLink === "Slug" && (
              <Box width={[1]} px={[1]}>
                <TextInput
                  value={value}
                  onChange={(value) => onSetItemValue("value", value)}
                  name='SLUG'
                  title='SLUG'
                  fullWidth={true}
                />
              </Box>
            )}
          </Flex>
        </Flex>
      )}
    </ContentDetail>
  );
};
