import { bcx, bcx_secondary_orange } from "../components/app"
import red from "@material-ui/core/colors/red"
export default {
  palette: {
    secondary: {
      light: "#6ec6ff",
      main: "#2196f3",
      dark: "#0069c0",
      contrastText: "#fff",
      primary: bcx,
      secondary: bcx_secondary_orange,
      error: red,
    },
  },
  typography: {
    fontFamily: ["SalesforceSans, sans-serif"].join(","),
    fontSize: 14,
    useNextVariants: true,
    title: {
      fontWeight: 400,
    },
  },
}
