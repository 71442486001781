import React from "react";
import { get } from "lodash";
import { Flex, Text } from "rebass";

const Item = (props) => {
  const { item } = props;
  return (
    <Text px={[2]} py={[1]}>
      {item.title}
    </Text>
  );
};
const List = (props) => {
  const { values } = props;
  const list = get(values, "list", []);
  return (
    <Flex width={[1]} flexDirection={"column"} alignItems={"flex-start"}>
      {list.map((o, index) => {
        return <Item item={o} key={index} />;
      })}
    </Flex>
  );
};

export default List;
