import React, { Fragment } from "react";
import { Flex, Text } from "rebass";
import { IconButton } from "@material-ui/core";
import ActionEdit from "@material-ui/icons/Edit";
import ActionDelete from "@material-ui/icons/Delete";
import { get } from "lodash";
import { ContentDetail } from "../actions";

export default (props) => {
  const { item, onDelete, index, onSelectedItem } = props;

  return (
    <Fragment>
      <Flex width={[1]} flexDirection={"column"} p={[1]}>
        <ContentDetail>
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={[2]}
          >
            <Text>Item # {get(item, "id", index + 1)}</Text>
            <Flex
              flexDirection={"row"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <IconButton
                onClick={() => onSelectedItem(item)}
                style={{ color: "rgb(0, 188, 212)", marginRight: 8 }}
              >
                <ActionEdit color={"inherit"} />
              </IconButton>
              <IconButton
                onClick={() => onDelete()}
                style={{ color: "rgb(0, 188, 212)" }}
              >
                <ActionDelete color={"inherit"} />
              </IconButton>
            </Flex>
          </Flex>
        </ContentDetail>
      </Flex>
    </Fragment>
  );
};
