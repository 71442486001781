import React, { useState, Fragment } from "react";
// import { Link } from 'umi';
import { get, find } from "lodash";
import { Flex, Box, Text } from "rebass";
import MobileListMenu from "./MobileListMenu";
import { Popover, Button } from "antd";
import { MenusList, MenusSubList } from "./MenuDesktop";
import { useWindowSize } from "../../../components/hooks";
import { FontelloSvg } from "../../../components/Icons";
const BOX_SHADOW_COLOR = `rgba(66, 66, 66, 0.2) 0px 3px 3px -2px, rgba(66, 66, 66, 0.14) 0px 3px 4px 0px, rgba(66, 66, 66, 0.12) 0px 1px 8px 0px`;

// const getLink = (item) => {
//   const typeurl = get(item, "content.href.type", "");
//   const valueUrl = get(item, "content.href.value", "");
//   let url = "";
//   if (typeurl === "Content") {
//     url = `/contents/${valueUrl}`;
//   } else if (typeurl === "Category") {
//     url = `/categories/${valueUrl}`;
//   } else if (typeurl === "Url") {
//     url = valueUrl;
//   }
//   return url;
// };

const LabelBox = (props) => {
  const { item, colorText } = props;

  return (
    <Flex
      flexDirection='row'
      alignItems='center'
      justifyContent={"space-between"}
      sx={{
        color: colorText,
        cursor: "pointer",
      }}
    >
      <Text fontSize={[0]} fontWeight={"bold"}>
        {item.title}
      </Text>
      <FontelloSvg name={"angleRight"} fill={colorText} size={"16px"} />
    </Flex>
  );
};

const MenuItem = (props) => {
  const { category } = props;
  return (
    <Flex width={[1]} flexDirection={"row"} flexWrap={"wrap"}>
      {category &&
        category.length > 0 &&
        category.map((itemCategory, indexCategory) => {
          const subMenu = get(itemCategory, "children", []);
          return (
            <Box key={indexCategory} width={[1 / 5]}>
              <Box width={[1]} pb={[0]}>
                {subMenu.length > 0 ? (
                  <LabelSubChildBox item={itemCategory} subMenu={subMenu} />
                ) : (
                  <Box px={[2]} py={[1]}>
                    <LabelChildBox
                      item={itemCategory}
                      isBold={subMenu.length > 0}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
    </Flex>
  );
};

const LabelSubChildBox = (props) => {
  const [visible, setVisible] = useState(false);
  const { item, subMenu } = props;
  const onMouseEnter = () => {
    setVisible(true);
  };
  const onMouseLeave = () => {
    setVisible(false);
  };
  return subMenu.length > 0 ? (
    <Box
      width={[1]}
      py={[1]}
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
      style={{ backgroundColor: visible ? "rgba(0,0,0,0.2)" : "transparent" }}
    >
      <Box px={[2]}>
        <LabelChildBox item={item} isBold={subMenu.length > 0} />
      </Box>
      {visible && (
        <Fragment>
          <Box pt={["5px"]} />
          <Flex
            width={["90%"]}
            flexDirection={"row"}
            flexWrap={"wrap"}
            style={{
              position: "absolute",
              backgroundColor: "#FFFFFF",
            }}
          >
            {subMenu &&
              subMenu.length > 0 &&
              subMenu.map((myItem) => {
                const subMenu1 = get(myItem, "children", []);
                return (
                  <Box width={[1 / 5]} key={myItem.id} p={[1]}>
                    <LabelChildBox item={myItem} isBold={subMenu1.length > 0} />
                    {subMenu1.length > 0 &&
                      subMenu1.map((itemChild) => {
                        return (
                          <Box py={[1]}>
                            <LabelChildBox item={itemChild} />
                          </Box>
                        );
                      })}
                  </Box>
                );
              })}
          </Flex>
        </Fragment>
      )}
    </Box>
  ) : (
    <LabelChildBox item={item} isBold={subMenu.length > 0} />
  );
};

const LabelChildBox = (props) => {
  const { item, isBold } = props;

  return (
    <Flex
      flexDirection='row'
      alignItems='center'
      justifyContent={"space-between"}
      sx={{
        cursor: "pointer",
      }}
    >
      <Text
        fontSize={[0]}
        fontWeight={isBold ? "bold" : "normal"}
        color={"#595959"}
      >
        {item.title}
      </Text>
    </Flex>
  );
};

const MegaMenuItem = (props) => {
  const { categories, widthScreen, colorText, colorPrimary } = props;
  const newList =
    categories &&
    categories.length > 0 &&
    categories.map((item) => {
      return {
        key: item.id,
        label: (
          <LabelBox
            item={item}
            colorText={colorText}
            colorPrimary={colorPrimary}
          />
        ),
        items: <MenuItem item={item} category={item.children} />,
      };
    });
  if (categories && categories.length > 0) {
    return <MenusSubList newList={newList} widthScreen={widthScreen} />;
  }
  return <div />;
};

// const ListMenu = (props) => {
//   // const { show, onClose, history, style } = props
//   const { settings, categories } = props;
//   const screens = useWindowSize();
//   const widthScreen = get(screens, "width", 400) - 14;
//   const list = get(settings, "header.list", []);
//   const titleButton = get(settings, "header.titleButton", "");
//   const newList = list.map((item, index) => {
//     let myLabel = item.title;
//     let myItem = { item: item };
//     if (item.type === "category" && categories) {
//       const category = find(categories, function (o) {
//         return o.category_id === item.category_id;
//       });
//       myLabel = category.title;
//       myItem = { item: item, category: category };
//     }

//     return { key: index, label: myLabel, items: <MenuItem {...myItem} /> };
//   });
//   return (
//     <div
//       className={"menu-container"}
//       style={{ position: "absolute", marginLeft: 60 }}
//     >
//       <Popover
//         placement='bottomLeft'
//         autoAdjustOverflow={false}
//         overlayStyle={{
//           height: "auto",
//           boxShadow: BOX_SHADOW_COLOR,
//         }}
//         color={"#FFFFFF"}
//         title={""}
//         content={
//           <div
//             style={{
//               position: "relative",
//               marginLeft: "-14px",
//               width: widthScreen,
//             }}
//           >
//             <MenusList newList={newList} />
//           </div>
//         }
//         // trigger='click'
//       >
//         <Button type={"text"}>
//           <span style={{ color: "#FFFFFF" }}>{titleButton}</span>
//         </Button>
//       </Popover>
//     </div>
//   );
// };

const ListMenu = (props) => {
  const { settings, categories, colorText, colorPrimary } = props;
  const screens = useWindowSize();
  const widthScreen = get(screens, "width", 400) - 14;
  const titleButton = get(settings, "header.titleButton", "");
  const colorButton = get(settings, "header.color", "");
  const newList = categories.map((item) => {
    if (item.children) {
      const isShow = find(item.children, function (o) {
        return o.children;
      });
      return {
        key: item.id,
        label: (
          <LabelBox
            item={item}
            colorText={colorText}
            colorPrimary={colorPrimary}
          />
        ),
        items: isShow ? (
          <MegaMenuItem
            categories={item.children}
            item={item}
            widthScreen={widthScreen}
            colorText={colorText}
            colorPrimary={colorPrimary}
          />
        ) : (
          <MenuItem item={item} category={item.children} />
        ),
      };
    } else {
      return {
        key: item.id,
        label: (
          <LabelBox
            item={item}
            colorText={colorText}
            colorPrimary={colorPrimary}
          />
        ),
        items: <MenuItem item={item} category={item.children} />,
      };
    }
  });
  return (
    <div
      className={"menu-container"}
      style={{ position: "relative", marginLeft: 0 }}
    >
      <Popover
        placement='bottomLeft'
        autoAdjustOverflow={false}
        overlayStyle={{
          height: "auto",
          boxShadow: BOX_SHADOW_COLOR,
        }}
        color={"#FFFFFF"}
        title={""}
        content={
          <Box
            ml={["-14px", "-70px"]}
            style={{
              position: "relative",
              width: widthScreen,
            }}
          >
            <MenusList newList={newList} widthScreen={widthScreen} />
          </Box>
        }
        // trigger="click"
      >
        <Button type={"text"}>
          <Flex flexDirection={"row"}>
            <FontelloSvg name={"bars"} fill={colorButton} size='22px' />
            <span style={{ color: colorButton, paddingLeft: 4 }}>
              {titleButton}
            </span>
          </Flex>
        </Button>
      </Popover>
    </div>
  );
};

const Menu3 = (props) => {
  const { settings, categories } = props;
  // console.log(categories)
  const [show, setShow] = useState(false);
  if (props.isMobile) {
    return (
      <Flex
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"center"}
      >
        <Box
          onClick={() => setShow(!show)}
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: "24px",
            height: "24px",
            background: "transparent",
            border: "none",
            cursor: "pointer",
            padding: 0,
            zIndex: 2000,
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <div
            className='bar-menu'
            style={{ transform: show ? "rotate(45deg)" : "rotate(0)" }}
          />
          <div
            className='bar-menu'
            style={{
              opacity: show ? 0 : 1,
              transform: show ? "translateX(20px)" : "translateX(0)",
            }}
          />
          <div
            className='bar-menu'
            style={{ transform: show ? "rotate(-45deg)" : "rotate(0)" }}
          />
        </Box>
        <MobileListMenu
          show={show}
          onClose={() => setShow(false)}
          categories={categories}
          settings={settings}
        />
      </Flex>
    );
  }
  return (
    <ListMenu
      show={show}
      onClose={() => setShow(false)}
      settings={settings}
      style={props.style}
      categories={categories}
    />
  );
};

export default Menu3;
