import React, { useState } from "react"
import styled from "styled-components"
import Layout from "./dnd"

const ContentCarouselContainer = (props) => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(true)
  }
  const onMouseOver = () => {
    setHover(false)
  }
  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type="ContentCarousel" />
      {hover && <DivHelp>Content Carousel</DivHelp>}
    </DivHover>
  )
}

export default ContentCarouselContainer

export const DivHover = styled.div`
  z-index: 201;
  position: relative;
  width: 100%;
  outline: 0px solid #00bcd4;
  &:hover {
    z-index: 251;
    outline: 2px solid #ff9900;
    cursor: pointer;
  }
`

export const DivHelp = styled.div`
  font-family: Open Sans, sans-serif;
  position: absolute;
  bottom: -20px;
  left: 50%;
  background-color: #ff9900;
  color: #fff;
  display: block;
  z-index: 251;
  font-size: 12px;
  line-height: 20px;
  padding: 0 10px;
  height: 20px;
`
