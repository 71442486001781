import React, { useState } from "react"
import { keyBy } from "lodash"
import { Box } from "rebass"
import { useQuery, TextField, Pagination, Loading } from "react-admin"
import { ButtonEditView, Datagrid, useStylesTable } from "./"
import { ContentBox } from "./Styled"
export const AttributeSetDataGrid = (props) => {
  const { classes: classesOverride } = props

  const classes = useStylesTable({ classes: classesOverride })

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const { data, total, loading } = useQuery({
    type: "getList",
    resource: "attributeSets",
    payload: {
      pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      filter: {},
    },
  })

  if (loading) {
    return (
      <Box width={[1]} p={[2]}>
        <Loading />
      </Box>
    )
  }

  return (
    <Box width={[1]}>
      {data && data.length > 0 && (
        <ContentBox>
          <Datagrid
            classes={classes}
            data={keyBy(data, "id")}
            ids={data.map(({ id }) => id)}
            currentSort={{ field: "id", order: "ASC" }}
            basePath="/attributeSets" // required only if you set use "rowClick"
            // rowClick="edit"
          >
            <TextField source="name" />
            <ButtonEditView {...props} basePath="/attributeSets" />
          </Datagrid>
          <Pagination
            page={page}
            perPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            setPage={(v) => setPage(v)}
            setPerPage={(v) => setPerPage(v)}
            total={total}
          />
        </ContentBox>
      )}
    </Box>
  )
}
