import React from "react";
import { Flex, Box, Image } from "rebass";

import Menu from "./Menu";
import Account from "./Account";
const MobileView = (props) => {
  const { settings, cart, categories } = props;
  // console.log(props);
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      bg={"secondary"}
      px={["16px"]}
      py={[3]}
    >
      <Box style={{ zIndex: 2 }}>
        <Menu isMobile={true} settings={settings} categories={categories} />
      </Box>
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        style={{ position: "absolute", left: 0, right: 0 }}
      >
        <Image
          sx={{ width: ["48px", "60px"], height: "auto" }}
          src={settings.header.image}
          alt='logo'
        />
      </Flex>
      <Box style={{ zIndex: 2 }}>
        <Account cart={cart} settings={settings} isMobile={true} />
      </Box>
    </Flex>
  );
};

export default MobileView;
