import React, { Component } from "react";
import PropTypes from "prop-types";
import DestopIcon from "@material-ui/icons/DesktopMac";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import MobileIcon from "@material-ui/icons/StayPrimaryPortrait";
import Dropzone from "react-dropzone";
import { CircularProgress, Snackbar, IconButton } from "@material-ui/core";
// import browserImageSize from "browser-image-size"
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";
import { UploadClient } from "../../../restClient";

import {
  DivContainer,
  SpanImage,
  BoxImageViewSmall,
  Image,
  InsideBox,
  BoxUpload,
  Content,
} from "./styled";
import {
  URL_UPLOAD,
  ERROR_MESSAGE_FAILED,
  ERROR_MESSAGE_MAX_SIZE,
} from "./constants";

const pinkA200 = pink["A200"];
const red500 = red[500];

class UploadImageListSlide extends Component {
  constructor(props) {
    super(props);
    const { imageUrl } = props;
    this.state = {
      isEditMode: false,
      imageUrl: imageUrl,
      isLoadingDesktop: false,
      isLoadingMobile: false,
      openSnackBar: false,
      message: "Please try again",
      mobileSize: { width: 0, height: 0 },
      desktopSize: { width: 0, height: 0 },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { imageUrl } = nextProps;
    if (imageUrl !== this.props.imageUrl) {
      this.setState({ imageUrl: imageUrl });
    }
  }
  onDropDesktop = (accepted, rejected) => {
    const paramsCaption = this.props.type + "-image-desktop";
    if (accepted.length > 0 && rejected.length === 0) {
      this.setState({
        isLoadingDesktop: true,
      });

      // caption as brand-product name
      //to getSize Image
      // /let that = this
      // browserImageSize(accepted[0].preview).then(function(size) {
      //   const promise = new Promise(function(resolve, reject) {
      //     if (resolve) {
      //       that.setState({ desktopSize: size })
      //     }
      //     resolve(size)
      //   })
      //   return promise
      // })
      let formData = new FormData();
      formData.append("caption", paramsCaption);
      accepted.forEach((file) => {
        formData.append("photos", file, file.name);
      });
      this.onUploadImageListSlide(formData, "desktop");
    } else {
      this.setState({
        openSnackBar: true,
        message: ERROR_MESSAGE_MAX_SIZE,
      });
    }
  };

  onDropMobile = (accepted, rejected) => {
    const paramsCaption = this.props.type + "-image-mobile";
    if (accepted.length > 0 && rejected.length === 0) {
      this.setState({
        isLoadingMobile: true,
      });

      // let that = this
      // browserImageSize(accepted[0].preview).then(function(size) {
      //   const promise = new Promise(function(resolve, reject) {
      //     if (resolve) {
      //       that.setState({ mobileSize: size })
      //     }
      //     resolve(size)
      //   })
      //   return promise
      // })
      let formData = new FormData();
      // caption as brand-product name
      formData.append("caption", paramsCaption);
      accepted.forEach((file) => {
        formData.append("photos", file, file.name);
      });
      this.onUploadImageListSlide(formData, "mobile");
    } else {
      this.setState({
        openSnackBar: true,
        message: ERROR_MESSAGE_MAX_SIZE,
      });
    }
  };
  onUploadImageListSlide(formData, type) {
    //maxSize : 1 MB check default PropsTypes
    UploadClient(formData, URL_UPLOAD)
      .then((response) => response.json())
      .then((data) => {
        let imageUrl = this.state.imageUrl;
        //console.log(imageUrl);
        if (type === "desktop") {
          imageUrl.url = data.url;
          imageUrl.desktop = this.state.desktopSize;
          this.setState({
            imageUrl: imageUrl,
            isLoadingDesktop: false,
          });
        } else {
          imageUrl.urlMobile = data.url;
          imageUrl.mobile = this.state.mobileSize;
          this.setState({
            imageUrl: imageUrl,
            isLoadingMobile: false,
          });
        }
        this.props.onUpdateUrl(imageUrl);
      })
      .catch((e) => {
        if (type === "desktop") {
          this.setState({
            isLoadingDesktop: false,
            openSnackBar: true,
            message: ERROR_MESSAGE_FAILED,
          });
        } else {
          this.setState({
            isLoadingMobile: false,
            openSnackBar: true,
            message: ERROR_MESSAGE_FAILED,
          });
        }
      });
  }

  handleRequestClose = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  onRemove = (type) => {
    let { imageUrl } = this.state;
    if (type === "mobile") {
      imageUrl.urlMobile = "";
    } else if (type === "desktop") {
      imageUrl.url = "";
    }
    this.setState({
      imageUrl: imageUrl,
    });
  };

  renderItem(url, type, title) {
    const defaultImage =
      type === "mobile"
        ? "https://via.placeholder.com/100x100?text=Image"
        : "https://via.placeholder.com/100x100?text=Image";
    return (
      <div style={styles.gridImage}>
        <div style={styles.container}>
          {url !== "" && (
            <IconButton
              style={{ ...styles.removeButton1, color: pinkA200 }}
              onClick={() => this.onRemove(type)}
            >
              <RemoveCircle color={"inherit"} />
            </IconButton>
          )}
          <Image src={url || defaultImage} alt={title} />
        </div>
      </div>
    );
  }

  render() {
    const { disableClick, maxSize, minSize, multiple, title } = this.props;

    return (
      <Content>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <DivContainer style={{ paddingRight: 8 }}>
            <Dropzone
              onDrop={this.onDropDesktop}
              accept='image/*'
              disableClick={disableClick}
              maxSize={maxSize}
              minSize={minSize}
              multiple={multiple}
              style={styles.boxImage}
            >
              {({ getRootProps, getInputProps }) => (
                <BoxUpload style={{ width: 80, height: 80 }}>
                  <div {...getRootProps()} style={{ display: "flex" }}>
                    <input {...getInputProps()} />
                    {this.state.isLoadingDesktop && (
                      <InsideBox>
                        <CircularProgress />
                      </InsideBox>
                    )}
                    {!this.state.isLoadingDesktop && (
                      <InsideBox style={{ color: "#173f4f" }}>
                        <DestopIcon
                          style={{ fontSize: 24 }}
                          color={"inherit"}
                        />
                        <SpanImage>Drop file here</SpanImage>
                        <SpanImage>or Click here</SpanImage>
                      </InsideBox>
                    )}
                  </div>
                </BoxUpload>
              )}
            </Dropzone>
            <BoxImageViewSmall>
              {this.renderItem(this.state.imageUrl.url, "desktop", title)}
            </BoxImageViewSmall>
          </DivContainer>
          <DivContainer>
            <Dropzone
              onDrop={this.onDropMobile}
              accept='image/*'
              disableClick={disableClick}
              maxSize={maxSize}
              minSize={minSize}
              multiple={multiple}
              style={styles.boxImage}
            >
              {({ getRootProps, getInputProps }) => (
                <BoxUpload style={{ width: 80, height: 80 }}>
                  <div {...getRootProps()} style={{ display: "flex" }}>
                    <input {...getInputProps()} />
                    {this.state.isLoadingMobile && (
                      <InsideBox>
                        <CircularProgress />
                      </InsideBox>
                    )}
                    {!this.state.isLoadingMobile && (
                      <InsideBox style={{ color: "#173f4f" }}>
                        <MobileIcon
                          style={{ fontSize: 24 }}
                          color={"inherit"}
                        />
                        <SpanImage>Drop file here</SpanImage>
                        <SpanImage>or Click here</SpanImage>
                      </InsideBox>
                    )}
                  </div>
                </BoxUpload>
              )}
            </Dropzone>
            <BoxImageViewSmall>
              {this.renderItem(this.state.imageUrl.urlMobile, "mobile", title)}
            </BoxImageViewSmall>
          </DivContainer>
          <Snackbar
            open={this.state.openSnackBar}
            message={this.state.message}
            autoHideDuration={4000}
            bodyStyle={{ backgroundColor: red500 }}
            onRequestClose={this.handleRequestClose}
          />
        </div>
      </Content>
    );
  }
}
const styles = {
  boxImage: {
    width: 80,
    height: 80,
    borderWidth: 2,
    padding: "0 6px 0 6px",
    borderColor: "#666",
    borderStyle: "dashed",
    borderRadius: 5,
  },
  gridImage: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  image: {
    maxHeight: "15rem",
    margin: "0.2rem",
    maxWidth: "100%",
  },
  container: {
    display: "inline-block",
    position: "relative",
  },
  removeButton: {
    position: "absolute",
    top: "0px",
    right: "0px",
    minWidth: "2rem",
    opacity: 1,
  },
  removeButton1: {
    position: "absolute",
    top: "-10px",
    right: "-10px",
    minWidth: "2rem",
    opacity: 1,
  },
};

UploadImageListSlide.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  disableClick: PropTypes.bool,
  onUpdateUrl: PropTypes.func,
  imageUrl: PropTypes.object,
};

UploadImageListSlide.defaultProps = {
  type: "default",
  multiple: false,
  maxSize: 1000000,
  title: "Image",
  imageUrl: {
    url: "https://via.placeholder.com/400x200?text=960x400",
    urlMobile: "https://via.placeholder.com/400x200?text=600x600",
  },
};

export default UploadImageListSlide;
