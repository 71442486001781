import React from "react";
import { get } from "lodash";
const DimensionField = ({ record }) => {
  return (
    <span>
      {get(record, "height", 0)}x{get(record, "length", 0)}x
      {get(record, "width", 0)}
    </span>
  );
};
const VolumeField = ({ record }) => {
  return <span>{get(record, "weight", 0)}</span>;
};

export { DimensionField, VolumeField };
