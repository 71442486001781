import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import { BlockCounter, PixelPercent, Counter, Title } from "../";
export default (props) => {
  const { mobile, onSetValue } = props;
  const onUpdateMobile = (k, v) => {
    const newMobile = {
      ...mobile,
      [k]: v,
    };
    onSetValue("mobile", newMobile);
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Title title={"Content Width"} />
      <PixelPercent
        name='type'
        value={get(mobile, "type", "%")}
        onChange={(e) => onUpdateMobile("type", e.target.value)}
        title='Type'
      />
      <Flex flexDirection={"row"} width={[1]} justifyContent={"flex-end"}>
        <Counter
          isDisabled={false}
          onSetValue={(value) => onUpdateMobile("contentWidth", value)}
          value={get(mobile, "contentWidth", 100)}
          interval={get(mobile, "type", "%") === "%" ? 1 : 20}
          maxValue={get(mobile, "type", "%") === "%" ? 100 : 2000}
        />
      </Flex>
      <BlockCounter
        containerPadding={mobile.padding}
        onUpdatePadding={(value) => onUpdateMobile("padding", value)}
        title={"Content Padding"}
      />
    </Flex>
  );
};
//MobileContentValues
