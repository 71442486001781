import React, { Component } from "react";
import Slider from "react-slick";
import { Box, Flex, Text } from "rebass";

import { ContentItemRow } from "./Items";

class ListMultiRow extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      isIndex: 0,
      isEnd: false
    };
  }
  next() {
    if (!this.state.isEnd) {
      this.sliderone.slickNext();
    }
  }
  previous() {
    if (this.state.isIndex > 0) {
      this.sliderone.slickPrev();
    }
  }
  onChange = index => {
    const { newMan, screenWidth } = this.props;
    const addIndex = screenWidth > 702 ? 6 : 3;
    const currentIndex = index + addIndex;
    let isEndCurrent = false;
    if (currentIndex >= newMan.length) {
      isEndCurrent = true;
    }
    this.setState({
      isIndex: index,
      isEnd: isEndCurrent
    });
  };
  render() {
    const { newMan, screenWidth, newSetting, title } = this.props;

    const settings = {
      infinite: newSetting.infinite,
      centerPadding: "10px",
      className: "center",
      centerMode: true,
      slidesToShow: newSetting.slidesToScroll,
      speed: newSetting.speed,
      rows: newSetting.rows,
      slidesPerRow: newSetting.slidesPerRow
    };

    return (
      <Box
        p={[1]}
        style={{ width: screenWidth, backgroundColor: "tranparent" }}
      >
        <Flex width={[1]} direction={"row"} justify={"space-between"} p={[1]}>
          <Flex width={[1]} justify={"row"} align={"center"}>
            <Text fontSize={[14, 18]} fontWeight={"bold"}>
              {title}
            </Text>
          </Flex>
        </Flex>

        <div style={{ backgroundColor: "transparent" }}>
          <Slider
            ref={c => (this.sliderone = c)}
            {...settings}
            style={{ backgroundColor: "tranparent" }}
          >
            {newMan.map((item, index) => {
              return (
                <Box key={index} p={[1]}>
                  <ContentItemRow
                    item={item}
                    key={index}
                    size={[screenWidth / 3 - 20]}
                    pContainer={[1, 1]}
                  />
                </Box>
              );
            })}
          </Slider>
        </div>
      </Box>
    );
  }
}

export default ListMultiRow;
