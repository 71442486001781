import React from "react"
import {
  BlockCounter,
  SelectInput,
  Title,
  Counter,
  AlignMentContent,
  SliderInput,
} from "../components"
import { Flex } from "rebass"
import { SketchColorPicker } from "../../../components"
import { ContainerPanel, ContentDetail, PanelCard } from "../actions"
import { BoxContent } from "../styled"

const dataLine = [
  { text: "Solid", value: "solid" },
  { text: "Dotted", value: "dotted" },
  { text: "Dashed", value: "dashed" },
]

export const DividerFields = props => {
  const { state, handleValue, onSetValue } = props
  return (
    <BoxContent style={{ height: state.heightContent - 120 }}>
      <ContainerPanel>
        <PanelCard title={"General"} id={"icon-button-general"}>
          <ContentDetail>
            <Title title="Color Button" />
            <SketchColorPicker
              title={"Line Color"}
              onUpdateColor={color => onSetValue("lineColor", color)}
              color={state.lineColor}
            />
          </ContentDetail>
          <ContentDetail>
            <AlignMentContent
              onChange={handleValue("alignValue", "value")}
              value={state.alignValue}
              name="alignment"
              title={"Alignment"}
            />
          </ContentDetail>
          <ContentDetail>
            <SelectInput
              id={`line-type-divider`}
              title={"Line Type"}
              value={state.line}
              list={dataLine}
              onChange={handleValue("line", "value")}
            />
          </ContentDetail>
          <ContentDetail>
            <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Title title={"Line Size"} />
              <Counter
                onSetValue={value => onSetValue("size", value)}
                value={state.size}
                interval={1}
                subtitle="px"
              />
            </Flex>
          </ContentDetail>
          <ContentDetail>
            <SliderInput
              min={0}
              title={`Width ${state.width} %`}
              max={100}
              step={1}
              value={state.width}
              onChange={value => onSetValue("width", value)}
            />
          </ContentDetail>
          <BlockCounter
            containerPadding={state.containerPadding}
            onUpdatePadding={containerPadding =>
              onSetValue("containerPadding", containerPadding)
            }
            title={"Container Padding"}
          />
        </PanelCard>
      </ContainerPanel>
    </BoxContent>
  )
}
