export default {
  customers: {
    name: "Customers",
    fields: {
      name: "Name",
      email: "Email",
      mobile: "Mobile",
      gender: "Gender",
      created_at: "Create On",
    },
  },
};
