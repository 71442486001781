import React from "react"
import { Box, Flex } from "rebass"
const HeaderBox = (props) => (
  <Flex
    width={[1]}
    justifyContent={"flex-end"}
    flexDirection={"row"}
    px={[3]}
    py={[2]}
    bg={"white"}
    {...props}
  />
)

const ContentBox = (props) => (
  <Box width={[1]} p={[3]} bg={"white"} py={[4]} {...props} />
)
const DividerBox = (props) => (
  <Box width={[1]} style={{ height: 24 }} {...props} />
)
export { HeaderBox, ContentBox, DividerBox }
