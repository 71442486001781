import { Layout5 } from "../../TemplateImageSonak";
export const template5A = {
  h5Muj7wk: {
    id: "h5Muj7wk",
    type: "Row4",
    props: {
      values: {
        name: "Row4",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 2,
          height: "100%",
          layout: {
            wrap: false,
            align: "bottom",
            gutter: 10,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "10px 20px 10px 20px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "1080px",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "bottom",
            gutter: 20,
            justify: "center",
            contentAlign: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "10px 10px 10px 10px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 1,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-H6F.jpeg",
                    size: "cover",
                    ratio: 1,
                    layout: {
                      justify: "flex-end",
                    },
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 8,
                  order: 1,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-H6F.jpeg",
                    size: "cover",
                    ratio: 1,
                    height: "280px",
                    layout: {
                      justify: "flex-end",
                    },
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 2,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-V3T.jpeg",
                    size: "cover",
                    ratio: 1,
                    layout: {
                      justify: "flex-end",
                    },
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255, 255, 255, 1)",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 16,
                  order: 2,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-V3T.jpeg",
                    size: "cover",
                    ratio: 1,
                    height: "280px",
                    layout: {
                      justify: "flex-end",
                    },
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    childOf: "Row",
    layout: Layout5,
    children: ["AUXPz630", "yix2tPeb"],
    myContent: [
      {
        id: "JbCeNKU/",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p>SHOP NOW</p>",
            type: "TextView",
            isLink: true,
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "8px 0px 16px 20px",
            },
            isShowMobile: false,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "AUXPz630",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "AUXPz630",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "VKp0uH3n",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p>SHOP NOW</p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "8px 0px 16px 20px",
            },
            isShowMobile: false,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "yix2tPeb",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "yix2tPeb",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "WzwUqgWP",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>FITNESS</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 7,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "10px 0px 10px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 18,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "10px 0px 0px 20px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "yix2tPeb",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "yix2tPeb",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "bDEFnfRP",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>Must-Have</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 7,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "10px 0px 10px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 18,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              containerPadding: "10px 0px 0px 20px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },

        parent: {
          id: "AUXPz630",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "AUXPz630",
          idx: 0,
          index: 1,
        },
      },
    ],
  },
};
