import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

export const useStylesTable = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
  },
  thead: {},
  tbody: {
    height: "inherit",
  },
  headerRow: {
    height: 52,
    backgroundColor: grey[300],
  },
  headerCell: {
    backgroundColor: grey[300],
    color: grey[700],
    padding: "8px 16px",
    "&:last-child": {
      padding: "0 12px",
    },
  },
  checkbox: {},
  row: {},
  clickableRow: {
    cursor: "pointer",
  },
  rowEven: { backgroundColor: grey[100] },
  rowOdd: {},
  rowCell: {
    borderWidth: 0,
    padding: "4px 12px",
    "&:last-child": {
      padding: "0 12px",
    },
  },
  expandHeader: {
    padding: 0,
    width: 48,
  },
  expandIconCell: {
    width: 48,
  },
  expandIcon: {
    transform: "rotate(-90deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expanded: {
    transform: "rotate(0deg)",
  },
}));
export const useStylesTableCard = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
  },
  thead: {},
  tbody: {
    height: "inherit",
  },
  headerRow: {
    height: 52,
    backgroundColor: grey[300],
  },
  headerCell: {
    backgroundColor: grey[300],
    color: grey[700],
    padding: "8px 16px",
    "&:last-child": {
      padding: "0 12px",
    },
  },
  checkbox: {},
  row: {},
  clickableRow: {
    cursor: "pointer",
  },
  rowEven: { backgroundColor: grey[100] },
  rowOdd: {},
  rowCell: {
    borderWidth: 0,
    padding: "4px 12px",
    "&:last-child": {
      padding: "0 12px",
    },
  },
  expandHeader: {
    padding: 0,
    width: 48,
  },
  expandIconCell: {
    width: 48,
  },
  expandIcon: {
    transform: "rotate(-90deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expanded: {
    transform: "rotate(0deg)",
  },
}));

export const stylesFormContainer = createStyles({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  card: {
    flex: "1 1 auto",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
});

export const useStyles = makeStyles((theme) => ({
  root: {},
  main: {
    display: "flex",
  },
  content: {
    marginTop: 0,
    transition: theme.transitions.create("margin-top"),
    position: "relative",
    flex: "1 1 auto",
    boxShadow: "none",
    backgroundColor: "transparent",
    sizeSmall: {
      padding: 0,
    },
  },
  bulkActionsDisplayed: {
    marginTop: -theme.spacing(8),
    transition: theme.transitions.create("margin-top"),
  },
  actions: {
    zIndex: 2,
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
  },
  noResults: { padding: 20 },
}));

export const categories = [{ id: "F & B", name: "F & B" }];
export const typeMerchants = [
  { id: "Merchant Offer", name: "Merchant Offer" },
  { id: "Voucher", name: "Voucher" },
];
