import React from "react";
import { Flex } from "rebass";
import { BetterEditButton, BetterShowButton, BetterChartButton } from "../";
import { MemberButton, ChannelButton } from "./LinkButton";
import HomeIcon from "@material-ui/icons/Home";
import ThemeIcon from "@material-ui/icons/ColorLens";
import HeaderIcon from "@material-ui/icons/LineWeight";
import FooterIcon from "@material-ui/icons/HorizontalSplit";
import Tooltip from "@material-ui/core/Tooltip";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import ButtonDuplicateLayout from "./ButtonDuplicateLayout";
export const ButtonMemberEditView = (props) => {
  return (
    <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
      <MemberButton {...props} />
      <BetterEditButton {...props} />
    </Flex>
  );
};

export const ButtonChannelEditView = (props) => {
  return (
    <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
      <ChannelButton {...props} />
      <BetterEditButton {...props} />
    </Flex>
  );
};

export const ButtonEditView = (props) => {
  return (
    <Flex
      flexDirection={["row"]}
      justifyContent={"flex-end"}
      style={{ height: 33 }}
    >
      <BetterEditButton {...props} />
    </Flex>
  );
};
const MyComponent = React.forwardRef(function MyComponent(props, ref) {
  //  Spread the props to the underlying DOM element.
  // const { urlchild, icon } = props;
  return (
    <div ref={ref}>
      <BetterEditButton {...props} />
    </div>
  );
});

export const ButtonEditLayoutView = (props) => {
  return (
    <Flex
      flexDirection={["row"]}
      justifyContent={"flex-end"}
      // style={{ height: 33 }}
    >
      <Tooltip title={"Header Setting"}>
        <MyComponent {...props} urlchild='header' icon={<HeaderIcon />} />
      </Tooltip>
      <Tooltip title={"HomePage Setting"}>
        <MyComponent {...props} urlchild='page' icon={<HomeIcon />} />
      </Tooltip>
      <Tooltip title={"Footer Setting"}>
        <MyComponent {...props} urlchild='footer' icon={<FooterIcon />} />
      </Tooltip>
      <Tooltip title={"Theme Setting"}>
        <MyComponent {...props} urlchild='theme' icon={<ThemeIcon />} />
      </Tooltip>
      <Tooltip title={"Menu Setting"}>
        <MyComponent {...props} urlchild='menu' icon={<MenuOpenIcon />} />
      </Tooltip>
      <ButtonDuplicateLayout {...props} />
      <BetterEditButton {...props} />
    </Flex>
  );
};

export const ButtonShowEditView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    <BetterShowButton {...props} />
    <BetterEditButton {...props} />
  </Flex>
);

export const ButtonChartEditView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    <BetterChartButton {...props} />
    <BetterEditButton {...props} />
  </Flex>
);
