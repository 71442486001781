import React from "react";
import AppHeader from "./AppHeader";
import { get } from "lodash";
import { Box, Flex } from "rebass";
import { useQueryWithStore } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "./Menu";
import Layout from "./Layout";
import { PROFILE } from "../../restClient";
import { Loading } from "../mui";
import Sidebar from "./Sidebar";
import { grey } from "@material-ui/core/colors";
import { DEFAULT_SIDEBAR, DEFAULT_BG_SIDEBAR } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    // backgroundColor: theme.palette.background.default,
    position: "relative",
    backgroundColor: grey[200],
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
  },
  contentWithSidebar: {
    display: "flex",
    flexGrow: 1,
  },
  content: {
    marginLeft: DEFAULT_SIDEBAR,
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    padding: theme.spacing(0),
    marginTop: "0em",
    paddingTop: 0,
    backgroundColor: grey[200],
  },
}));
//paddingLeft: "2rem",

const MySidebar = (props) => {
  return (
    <Box
      style={{
        // backgroundColor: "#696969",
        height: "100%",
        position: "fixed",
        zIndex: 100,
        overflowY: "auto",
      }}
      className={"menu-side-bar"}
    >
      <Flex flexDirection={"column"} alignItems={"streach"}>
        <Box>
          <Sidebar
            {...props}
            style={{
              overflowY: "scroll",
              height: "100%",
              minHeight: "100vh",
              marginTop: 0,
              paddingTop: 30,
              paddingBottom: 70,
              backgroundColor: DEFAULT_BG_SIDEBAR,
            }}
            className={"menu-side-bar"}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default (props) => {
  const classes = useStyles();
  const profile = localStorage.getItem(PROFILE);
  const newProfile = JSON.parse(profile);
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "sites",
    payload: { id: get(newProfile, "site_id", -1) },
  });
  if (loading) {
    return <Loading />;
  }
  return (
    <Layout
      {...props}
      datauser={data}
      classes={classes}
      appBar={AppHeader}
      sidebar={MySidebar}
      menu={Menu}
      style={{ padding: 0 }}
    />
  );
};
