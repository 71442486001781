import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout25 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const MenuContentView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='MenuContentView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

MenuContentView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};
MenuContentView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "MenuContentView",
    containerPadding: ["0px", "0px"],
    colorProps: {
      color: ["#000000", "#000000"],
      backgroundColor: ["#FFFFFF", "#FFFFFF"],
      hoverColor: ["rgba(255,255,255,0)", "rgba(255,255,255,0)"],
      hoverBgColor: ["rgba(255,255,255,0)", "rgba(255,255,255,0)"],
    },
    model: 0,
  },
  layout: Layout25,
};
export default MenuContentView;
