import React from "react";
import { DropTarget } from "react-dnd";
import { connect } from "../../core";
import { target } from "./utils";
// const target = {
//   drop(props, monitor) {
//     if (!monitor.didDrop()) {
//       props.layoutState.insertOrMoveItem(
//         props.id,
//         props.children.length,
//         monitor.getItem()
//       )
//     }
//   },
// }

let renderText = (
  value,
  containerPadding,
  lineHeight,
  letterSpacing,
  align,
  color
) => {
  const valueDefault =
    value && value.length > 0
      ? `<div><span>${
          value[0].icon &&
          value[0].icon !== "" &&
          value[0].iconPosition === "flex-start"
            ? `<img src="${value[0].icon}" width="16"  />`
            : ""
        } ${value[0].title} ${
          value[0].icon &&
          value[0].icon !== "" &&
          value[0].iconPosition === "flex-end"
            ? `<img src="${value[0].icon}" width="16"  />`
            : ""
        } </span> </div>`
      : `<div>value TextGroup </div>`;
  return (
    <div
      style={{
        padding: containerPadding,
        lineHeight,
        letterSpacing,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flex: 1,
        justifyContent: align,
        color: color,
      }}
      dangerouslySetInnerHTML={{ __html: valueDefault }}
    />
  );
};

const EditLayout = ({ id, connectDropTarget, layoutState }) => {
  const {
    containerPadding,
    valueText,
    lineHeight,
    backgroundColor,
    letterSpacing,
    align,
    color,
  } = layoutState.getItemJS(id).props.values;
  // console.log(layoutState.getItemJS(id).props.values);
  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        backgroundColor,
        display: "flex",
        flex: 1,
        padding: 0,
      }}
    >
      {renderText(
        valueText,
        containerPadding,
        lineHeight,
        letterSpacing,
        align,
        color
      )}
    </div>
  );
};

const LayoutContainer = DropTarget(
  "TextGroupView",
  target,
  (conn, monitor) => ({
    connectDropTarget: conn.dropTarget(),
    isOverCurrent: monitor.isOver({ shallow: true }),
  })
)(EditLayout);

export default connect("layoutState", "readOnly")(LayoutContainer);

// export default LayoutContainer;s
