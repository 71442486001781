import React from "react";
import {
  Counter,
  BlockCounter,
  ListGroup,
  Alignment,
  TextInput,
  Title,
  SelectInput,
} from "../components";
import { Box } from "rebass";
import { SketchColorPicker } from "../../../components";
import { BoxContent } from "../styled";
import { PanelCard, ContentDetail, ContainerPanel } from "../actions";

const list = [
  { id: "list", name: "List" },
  { id: "product", name: "Products" },
  { id: "package", name: "Packages" },
  { id: "appointment", name: "Appointments" },
];

export const GridListFields = (props) => {
  const { state, onSetValue, onUpdateFontSize, data } = props;
  return (
    <BoxContent style={{ height: state.heightContent - 120 }}>
      <ContainerPanel>
        <Box width={[1]} p={[1]}>
          <ContentDetail>
            <Box width={[1]} pb={[2]}>
              <TextInput
                value={state.title}
                onChange={(value) => onSetValue("title", value)}
                name={`text-title`}
                title='Title'
                fullWidth={true}
              />
              <SelectInput
                list={list}
                value={state.modeType}
                id={"modeType"}
                title={"Type"}
                fullWidth={true}
                onChange={(e) => onSetValue("modeType", e.target.value)}
              />
            </Box>
            {state.modeType === "list" && (
              <ListGroup
                data={data}
                list={state.value}
                onChangeText={(value) => onSetValue("value", value)}
              />
            )}
          </ContentDetail>
        </Box>
        <PanelCard
          title={"Background and Color"}
          id={"bg-color-list-slide-view"}
        >
          <ContentDetail>
            <SketchColorPicker
              title={"Color"}
              onUpdateColor={(color) => onSetValue("color", color)}
              color={state.color}
            />
          </ContentDetail>
          <ContentDetail>
            <SketchColorPicker
              title={"Background"}
              onUpdateColor={(color) => onSetValue("backgroundColor", color)}
              color={state.backgroundColor}
            />
          </ContentDetail>
        </PanelCard>
        <PanelCard title={"Content General"} id={"content-list-slide-view"}>
          <ContentDetail>
            <Title title={"Font size Desktop"} />
            <Counter
              onSetValue={(value) => onUpdateFontSize(value, "desktop")}
              value={state.fontSize.desktop}
              interval={1}
              subtitle='pt'
              minValue={5}
            />
          </ContentDetail>
          <ContentDetail>
            <Title title={"Font size Mobile"} />
            <Counter
              onSetValue={(value) => onUpdateFontSize(value, "mobile")}
              value={state.fontSize.mobile}
              interval={1}
              subtitle='pt'
              minValue={5}
            />
          </ContentDetail>
          <BlockCounter
            title={"Container Padding"}
            containerPadding={state.containerPadding}
            onUpdatePadding={(containerPadding) =>
              onSetValue("containerPadding", containerPadding)
            }
          />
          <ContentDetail>
            <Alignment
              value={state.align}
              onChange={(value) => onSetValue("align", value)}
              title='Alignment'
            />
          </ContentDetail>
        </PanelCard>
      </ContainerPanel>
    </BoxContent>
  );
};
