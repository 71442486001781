export default {
  memberVouchers: {
    name: "Member reward",
    fields: {
      created_at: "Redeem date",
      points: "Points",
      expire_at: "Expire date",
      used: "Used",
      used_at: "Use date",
    },
  },
}
