export const defaultListSlideV = {
  modeType: "list",
  urlViewAll: "",
  title: "Shop by Sports",
  colorProps: {
    backgroundColor: "rgba(255,255,255,0)",
    color: "rgba(0,0,0,1)",
    viewallColor: "rgba(0,0,0,1)",
    hoverColor: "rgba(0,0,0,1)",
    hoverBackgroundColor: "rgba(255,255,255,0)",
  },
  desktop: {
    show: true,
    listProps: {
      fontSize: 14,
      titlePadding: "0px",
      padding: "0px",
      showViewAllBottom: false,
    },
    header: {
      show: true,
      fontSize: 14,
      showViewAll: true,
      padding: "0px",
    },
    setting: {
      dots: false,
      infinite: false,
      arrows: false,
      speed: 200,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      rows: 0,
      slidesPerRow: 0,
      autoplay: false,
      autoplaySpeed: false,
    },
  },
  mobile: {
    show: true,
    listProps: {
      fontSize: 14,
      titlePadding: "0px",
      padding: "0px",
      urlViewAll: "",
      showViewAllBottom: false,
    },
    header: {
      show: true,
      title: "Shop by Sports",
      fontSize: 14,
      showViewAll: true,
      padding: "0px",
    },
    colorProps: {
      backgroundColor: "rgba(255,255,255,0)",
      color: "rgba(0,0,0,1)",
      hoverColor: "rgba(0,0,0,1)",
      hoverBackgroundColor: "rgba(255,255,255,0)",
    },
    setting: {
      dots: false,
      infinite: false,
      arrows: false,
      speed: 200,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      rows: 0,
      slidesPerRow: 0,
      autoplay: false,
      autoplaySpeed: false,
    },
  },
  valueText: [],
};

// const defaultListSlideView = {
//   values: {
//     parent: "Content",
//     type: "ListSlideView",
//     containerPadding: "0px",
//     backgroundColor: "rgba(255,255,255,0)",
//     color: "rgba(0,0,0,1)",
//     fontSize: {
//       desktop: 14,
//       mobile: 10,
//     },
//     align: "center",
//     title: "",
//     modeType: "list",
//     showAll: false,
//     urlShowAll: "",
//     valueText: [
//       {
//         imageUrl: {
//           url:
//             "https://s3-ap-southeast-1.amazonaws.com/oleh-prod/assets/1/product/For-testing-only-UDO.png",
//           urlMobile:
//             "https://s3-ap-southeast-1.amazonaws.com/oleh-prod/assets/1/product/For-testing-only-UDO.png",
//         },
//         title: "Loyalty",
//         subtitle: "",
//         href: {
//           url: "",
//           target: "",
//           isLink: false,
//         },
//       },
//     ],
//     products: {
//       isProduct: false,
//       list: [],
//     },
//     setting: {
//       dots: false,
//       infinite: false,
//       arrows: false,
//       speed: 200,
//       slidesToShow: 1,
//       slidesToScroll: 1,
//       adaptiveHeight: true,
//       rows: 0,
//       slidesPerRow: 0,
//       autoplay: false,
//       autoplaySpeed: false,
//     },
//   },
// };
