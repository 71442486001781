import React, { useState } from "react"
import { Text, Box } from "rebass"
// import { get, parseInt } from "lodash"
import { useTranslate } from "react-admin"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Input from "@material-ui/core/Input"
import Autosuggest from "react-autosuggest"
import InputAdornment from "@material-ui/core/InputAdornment"
import CircularProgress from "@material-ui/core/CircularProgress"
import { dataProvider, getSiteID } from "../../../../restClient"
import "./autosuggest.css"

const renderSuggestionsContainer = ({ containerProps, children, query }) => {
  return (
    <div {...containerProps} style={{ overflowY: "scroll", zIndex: 90000 }}>
      {children}
    </div>
  )
}

const renderSuggestion = (suggestion) => (
  <Text fontSize={[0]}>{suggestion.name}</Text>
)

const AutoCompleteProduct = (props) => {
  const translate = useTranslate()
  const {
    value,
    setValue,
    suggestions,
    setSuggestions,
    onSuggestionsClearRequested,
  } = props
  const [loading, setLoading] = useState(false)

  const getSuggestionValue = (suggestion) => {
    props.getItem(suggestion)
    setValue("")
    return ""
  }

  const onChange = (event, { newValue }) => {
    setValue(newValue)
  }
  const onSuggestionsFetchRequested = ({ value }) => {
    if (value.length > 2) {
      setLoading(true)
      dataProvider
        .getList("products", {
          pagination: { page: 1, perPage: 50 },
          sort: { field: "name", order: "ASC" },
          filter: { site_id: getSiteID(), search: value },
        })
        .then((response) => {
          // console.log(response)
          if (response && response.data) {
            setSuggestions(response.data)
          }
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
          console.log(e)
        })
    }
  }
  const inputProps = {
    value,
    onChange: onChange,
  }
  return (
    <Box width={[1]}>
      <Autosuggest
        renderInputComponent={(inputProps) => (
          <FormControl fullWidth>
            <InputLabel htmlFor="auto-complete-product">
              {translate("resources.products.name")}
            </InputLabel>
            <Input
              fullWidth
              id={"auto-complete-product"}
              style={{ width: 400 }}
              {...inputProps}
              endAdornment={
                <InputAdornment position="end">
                  {loading && <CircularProgress size={20} />}
                </InputAdornment>
              }
            />
          </FormControl>
        )}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        inputProps={inputProps}
      />
    </Box>
  )
}

export default AutoCompleteProduct
