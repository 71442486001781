import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { get } from "lodash";
// import { useQueryWithStore } from "react-admin";
import { FooterAction } from "../actions";
import { dataAccordian } from "./dataAccordian";
import FormFields from "./FormFields";

const AccordionListView = (props) => {
  const values = get(props, "data.values", dataAccordian);

  // const { data, total, loading } = useQueryWithStore({
  //   type: "getList",
  //   resource: "collections",
  //   payload: {
  //     pagination: { page: 1, perPage: 1000 },
  //     sort: { field: "id", order: "ASC" },
  //     filter: { site_id: 1 },
  //   },
  // });

  const header = {
    title: values.header.title,
    containerPadding: values.header.containerPadding,
    thumbnail: values.header.thumbnail,
    backgroundColor: values.header.backgroundColor,
    isShowDesktop: values.header.isShowDesktop,
    isShowMobile: values.header.isShowMobile,
  };
  const [state, setState] = useState({
    containerPadding: values.containerPadding,
    align: values.align,
    typeContent: values.typeContent,
    header: header,
    list: values.list,
    collections: values.collections,
    widthContent: props.widthContent,
    heightContent: props.heightContent,
    oldValue: props.data,
  });
  const onClickSave = () => {
    let newData = props.data;
    newData.values.header = state.header;
    newData.values.containerPadding = state.containerPadding;
    newData.values.align = state.align;
    newData.values.typeContent = state.typeContent;
    newData.values.header = state.header;
    newData.values.list = state.list;
    newData.values.collections = state.collections;
    props.onUpdateData(newData);
  };
  const onClickCopy = () => {
    let newData = props.data;
    newData.values.header = state.header;
    newData.values.containerPadding = state.containerPadding;
    newData.values.align = state.align;
    newData.values.typeContent = state.typeContent;
    newData.values.list = state.list;
    newData.values.collections = state.collections;
    localStorage.setItem("AccordionListView", JSON.stringify(newData.values));
  };
  const onClickPaste = () => {
    const textContent = JSON.parse(localStorage.getItem("AccordionListView"));
    if (textContent) {
      const {
        header,
        containerPadding,
        align,
        typeContent,
        list,
        collections,
      } = textContent;

      setState({
        ...state,
        header: header,
        oldValue: props.data,
        containerPadding: containerPadding,
        align: align,
        typeContent: typeContent,
        list: list,
        collections: collections,
      });
    }
  };
  const onSetValue = (k, v) => {
    setState({
      ...state,
      [k]: v,
    });
  };
  const onSetHeaderValue = (k, v) => {
    const header = {
      ...state.header,
      [k]: v,
    };
    setState({
      ...state,
      header: header,
    });
  };
  return (
    <Flex width={[1]} flexDirection={"column"} pb={["80px"]}>
      <FormFields
        state={state}
        onSetValue={onSetValue}
        data={props.data}
        onSetHeaderValue={onSetHeaderValue}
        {...props}
      />
      <FooterAction
        onClickSave={onClickSave}
        onClickCopy={onClickCopy}
        onClickPaste={onClickPaste}
        widthContent={state.widthContent}
      />
    </Flex>
  );
};

AccordionListView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

AccordionListView.defaultProps = {
  data: {},
};

export default AccordionListView;
