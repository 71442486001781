import React from "react";
import { Counter } from "../counter";
import { Flex } from "rebass";
import { get } from "lodash";
import { SwitchInput, Title, SelectInput } from "../";
import { ContentDetail } from "../../actions";
const dataAlign = [
  { value: "top", text: "Top" },
  { value: "middle", text: "Middle" },
  { value: "bottom", text: "Bottom" },
];
const dataJustify = [
  { value: "start", text: "Start" },
  { value: "end", text: "End" },
  { value: "center", text: "Center" },
  { value: "space-around", text: "Space Around" },
  { value: "space-between", text: "Space Between" },
];
const dataJustifyContainer = [
  { value: "flex-start", text: "Start" },
  { value: "flex-end", text: "End" },
  { value: "center", text: "Center" },
];
export default (props) => {
  /**
   * layout: {
      align: "top",
      gutter: 0,
      justify: "start",
      wrap: false,
    },
   */
  const { onSetValue, state, contentId } = props;
  const onSetValueLayout = (k, v) => {
    const newLayout = {
      ...state,
      [k]: v,
    };
    onSetValue(newLayout);
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <ContentDetail>
        <SelectInput
          id={`align${contentId}`}
          title={"Align"}
          value={state.align}
          list={dataAlign}
          onChange={(e) => onSetValueLayout("align", e.target.value)}
        />
      </ContentDetail>
      <ContentDetail>
        <SelectInput
          id={`justify${contentId}`}
          title={"Justify"}
          value={state.justify}
          list={dataJustify}
          onChange={(e) => onSetValueLayout("justify", e.target.value)}
        />
      </ContentDetail>
      <ContentDetail>
        <SelectInput
          id={`align-container${contentId}`}
          title={"Alignment Container"}
          value={get(state, "contentAlign", "center")}
          list={dataJustifyContainer}
          onChange={(e) => onSetValueLayout("contentAlign", e.target.value)}
        />
        <SelectInput
          id={`justify-container${contentId}`}
          title={"Vertical Aligment Container"}
          value={get(state, "contentJustify", "center")}
          list={dataJustifyContainer}
          onChange={(e) => onSetValueLayout("contentJustify", e.target.value)}
        />
      </ContentDetail>
      <Title title={"Gutter"} />
      <Counter
        onSetValue={(value) => onSetValueLayout("gutter", value)}
        value={state.gutter}
        interval={1}
        subtitle=''
        minValue={0}
      />
      <SwitchInput
        id={`${contentId}-bg-layout-wrap`}
        title={"Wrap"}
        checked={state.wrap}
        onChange={(e) => onSetValueLayout("wrap", e.target.checked)}
      />
    </Flex>
  );
};
