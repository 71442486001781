import React from "react"
import PropTypes from "prop-types"
import { Tooltip, IconButton } from "@material-ui/core"
import { useTranslate } from "react-admin"

const ButtonIconOnly = ({
  alignIcon = "left",
  children,
  classes: classesOverride,
  className,
  color,
  disabled,
  label,
  size,
  sizeIcon,
  ...rest
}) => {
  const translate = useTranslate()

  return label && !disabled ? (
    <Tooltip title={translate(label, { _: label })}>
      <IconButton
        aria-label={translate(label, { _: label })}
        className={className}
        color={color}
        {...rest}
      >
        {children}
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton
      className={className}
      color={color}
      disabled={disabled}
      {...rest}
    >
      {children}
    </IconButton>
  )
}

ButtonIconOnly.propTypes = {
  alignIcon: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  sizeIcon: PropTypes.oneOf(["xsmall", "small", "medium", "large"]),
}

ButtonIconOnly.defaultProps = {
  color: "primary",
  size: "small",
  sizeIcon: "small",
}

export default ButtonIconOnly
