import React from "react"
import {
  DialogIconTemplate,
  Alignment,
  BlockCounter,
  SwitchInput,
  SelectInput,
  Title,
  HrefUrl,
  Counter,
} from "../components"
import { Flex } from "rebass"
import { SketchColorPicker } from "../../../components"
import { ContainerPanel, ContentDetail, PanelCard } from "../actions"
import { BoxContent } from "../styled"

const dataSizeFabButton = [
  { text: "Small", value: "small" },
  { text: "Medium", value: "medium" },
  { text: "Large", value: "large" },
]

export const FabFields = props => {
  const {
    state,
    onSetValue,
    handleValue,
    onUpdateColor,
    onUpdateUrl,
    onChangeTarget,
  } = props
  const { target } = state.href
  const valueTarget = target === "_blank" ? target : "same"
  return (
    <BoxContent style={{ height: state.heightContent - 120 }}>
      <ContainerPanel>
        <PanelCard title={"General"} id={"icon-button-general"}>
          <ContentDetail>
            <Title title={"Tooltip"} />
            <SwitchInput
              id={`disable-fab-view`}
              title={"Disabled FAB Button"}
              checked={state.disabled}
              onChange={handleValue("disabled", "checked")}
            />

            <SelectInput
              id={`z-depth-fab-button`}
              title={"Size Fab Button"}
              value={state.size}
              list={dataSizeFabButton}
              onChange={handleValue("size", "value")}
            />
          </ContentDetail>
          <ContentDetail>
            <Alignment
              value={state.buttonAlign}
              onChange={value => onSetValue("buttonAlign", value)}
              title="Button Alignment"
            />
          </ContentDetail>
          <ContentDetail>
            <Title title="Color Button" />
            <SketchColorPicker
              title={"Color"}
              onUpdateColor={color => onUpdateColor(color, "color")}
              color={state.buttonColors.color}
            />
            <SketchColorPicker
              title={"Background"}
              onUpdateColor={color => onUpdateColor(color, "backgroundColor")}
              color={state.buttonColors.backgroundColor}
            />
            <SketchColorPicker
              title={"Hover"}
              onUpdateColor={color => onUpdateColor(color, "hoverColor")}
              color={state.buttonColors.hoverColor}
            />
            <SketchColorPicker
              title={"Disabled"}
              onUpdateColor={color => onUpdateColor(color, "disabledColor")}
              color={state.buttonColors.disabledColor}
            />
          </ContentDetail>

          <HrefUrl
            url={state.href.url}
            valueTarget={valueTarget}
            onChangeText={value => onUpdateUrl(value)}
            onChangeSelect={e => onChangeTarget(e)}
          />

          <ContentDetail>
            <DialogIconTemplate
              iconItem={state.icon}
              onItemSelected={icon => {
                onSetValue("icon", icon)
              }}
              isRequired={true}
            />
          </ContentDetail>
          <BlockCounter
            containerPadding={state.containerPadding}
            onUpdatePadding={containerPadding =>
              onSetValue("containerPadding", containerPadding)
            }
            title={"Container Padding"}
          />
          <ContentDetail>
            <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Title title={"Size Icon"} />
              <Counter
                isDisabled={false}
                onSetValue={value => onSetValue("sizeIcon", value)}
                value={state.sizeIcon}
                interval={1}
                maxValue={100}
              />
            </Flex>
          </ContentDetail>
        </PanelCard>
      </ContainerPanel>
    </BoxContent>
  )
}
