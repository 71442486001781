import React, { useState, useEffect } from "react"
import { keyBy } from "lodash"
import { Box } from "rebass"
import {
  useQueryWithStore,
  Pagination,
  Loading,
  TextField,
  BooleanField,
  DateField,
} from "react-admin"
import TextFieldName from "@material-ui/core/TextField"
import { ButtonEditView, Datagrid } from "./"
import { HeaderBox, ContentBox } from "../../attributes/components/Styled"

const ListProductRules = (props) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [search, setSearch] = useState("")
  const [searchText, setSearchText] = useState("")
  const [isRequest, setIsRequest] = useState(false)
  const { data, total, loading } = useQueryWithStore({
    type: "getList",
    resource: "promotions",
    payload: {
      pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      filter: { q: searchText, type: "Product" },
    },
  })

  const handleChange = (name) => (event) => {
    if (name === "search") {
      setSearch(event.target.value)
    }
    setIsRequest(true)
  }

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (isRequest) {
        setSearchText(search)
        setIsRequest(false)
      }
    }, 500)
    return () => clearTimeout(timeout)
  })

  if (loading) {
    return <Loading />
  }
  return (
    <Box width={[1]}>
      <HeaderBox>
        <TextFieldName
          label="search"
          margin="dense"
          variant="filled"
          value={search}
          onChange={handleChange("search")}
          InputLabelProps={{ shrink: true }}
          style={{ width: 200 }}
        />
      </HeaderBox>
      {data && (
        <ContentBox>
          <Datagrid
            data={keyBy(data, "id")}
            ids={data.map(({ id }) => id)}
            currentSort={{ field: "id", order: "ASC" }}
            basePath="/promotions" // required only if you set use "rowClick"
            // rowClick="edit"
          >
            <TextField
              label={"resources.promotions.fields.name"}
              source="name"
            />
            <BooleanField
              label={"resources.promotions.fields.active"}
              source="active"
            />
            <DateField
              label={"resources.promotions.fields.start"}
              source="start_date"
            />
            <DateField
              label={"resources.promotions.fields.end"}
              source="end_date"
            />
            <ButtonEditView {...props} />
          </Datagrid>
          <Pagination
            page={page}
            perPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            setPage={(v) => setPage(v)}
            setPerPage={(v) => setPerPage(v)}
            total={total ? total : 0}
          />
        </ContentBox>
      )}
    </Box>
  )
}

export default ListProductRules
