import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout12 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const TextGroupView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='TextGroupView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

TextGroupView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};
/**
 * href: {
      url: "",
      target: "",
      isCollection: false,
      collection: 0,
    },
    isLink: false,
 */
TextGroupView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "TextGroupView",
    containerPadding: "0px",
    backgroundColor: "rgba(255,255,255,0)",
    color: "#000000",
    lineHeight: "120%",
    letterSpacing: "1px",
    fontSize: {
      desktop: 14,
      mobile: 10,
    },
    align: "center",
    valueText: [{ title: "textgroup", icon: "", iconPosition: "flex-start" }],
    setting: {
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 3000,
      cssEase: "linear",
      slidesToShow: 1,
      slidesToScroll: 1,
      vertical: true,
      adaptiveHeight: true,
    },
  },

  layout: Layout12,
};

export default TextGroupView;
