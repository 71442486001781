import React from "react"
import { useTranslate, SelectArrayInput } from "react-admin"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import { themeMui } from "../../themes"

const payment_mode = [
  { id: "Wire", name: "resources.payment_mode.list.wire" },
  { id: "Credit Card", name: "resources.payment_mode.list.credit_card" },
]

export const themeRSelectArray = createMuiTheme({
  ...themeMui,
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginTop: 16,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 10,
        minWidth: 280,
        fontWeight: "900",
        "&$shrink": {
          fontSize: 14,
          transform: "translate(0px, 6px) scale(0.75)",
        },
      },
      filled: {
        minWidth: 280,
        fontWeight: "900",
        transform: "translate(0px, 26px) scale(1)",
        "&$shrink": {
          fontSize: 14,
          transform: "translate(0px, 6px) scale(0.75)",
          "&$marginDense": {
            transform: "translate(0px, 6px) scale(0.75)",
          },
        },
        "&$marginDense": {
          fontSize: 10,
          transform: "translate(0px, 26px) scale(1)",
        },
      },
    },
    MuiFilledInput: {
      root: {
        width: 500,
        backgroundColor: "tranparent",
        "&$focused": {
          backgroundColor: "transparent",
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      input: {
        padding: "27px 2px 10px",
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },

    MuiInputBase: {
      root: {
        backgroundColor: "tranparent",
      },
    },
  },
})

const PaymentModeInput = ({ addField, ...rest }) => {
  const translate = useTranslate()

  return (
    <ThemeProvider theme={themeRSelectArray}>
      <SelectArrayInput
        options={{ variant: "filled" }}
        {...rest}
        choices={payment_mode.map(item => ({
          id: item.id,
          name: translate(item.name),
        }))}
      />
    </ThemeProvider>
  )
}

PaymentModeInput.defaultProps = {
  addField: true,
  source: "payment_mode",
  label: "resources.payment_mode.name",
}

export default PaymentModeInput
