export const defaultButton = {
  parent: "Content",
  type: "FlatButtonView",
  text: "FlatButton",
  containerPadding: "0px",
  border: {
    type: "solid",
    size: 0,
    color: "#e61919",
    radius: 0,
  },
  typeButton: "flat",
  labelPosition: "before",
  icon: "",
  href: {
    url: "",
    target: "",
    isCollection: false,
    collection: 0,
  },
  desktop: {
    fullWidth: false,
    fontSize: 14,
    fontWeight: "normal",
    containerPadding: "0px",
    buttonPadding: "8px 12px 8px 12px",
    buttonAlign: "center",
    buttonColors: {
      color: "#FFF",
      backgroundColor: "#e61919",
      hoverColor: "#42d696",
      rippleColor: "#FF9900",
    },
  },
  mobile: {
    fullWidth: false,
    fontSize: 10,
    fontWeight: "normal",
    containerPadding: "0px",
    buttonPadding: "8px 12px 8px 12px",
    buttonAlign: "center",
    buttonColors: {
      color: "#FFF",
      backgroundColor: "#e61919",
      hoverColor: "#42d696",
      rippleColor: "#FF9900",
    },
  },
};
