import React, { Fragment } from "react";
import { Flex } from "rebass";
import { FontelloSvg } from "../../../components/Icons";
const LinkHref = ({ url, children, target }) => {
  if (url && url !== "" && url.includes("http")) {
    return (
      <a href={url} target={target && target !== "same" ? target : ""}>
        {children}
      </a>
    );
  }
  return <Fragment>{children}</Fragment>;
};

const SocialMediaList = (props) => {
  const { list, size, align } = props;
  return (
    <Flex
      width={[1]}
      justifyContent={align}
      flexWrap={"wrap"}
      sx={{
        "&> *": {
          p: [2],
        },
      }}
    >
      {list &&
        list.length > 0 &&
        list.map((item, index) => (
          <LinkHref url={item.url} target={item.url}>
            <FontelloSvg
              key={index}
              name={item.type}
              fill={item.color ? item.color : "#000000"}
              size={`${size}px`}
            />
          </LinkHref>
        ))}
    </Flex>
  );
};

export default SocialMediaList;
