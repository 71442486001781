import React from "react"
import PropTypes from "prop-types"
import onlyUpdateForKeys from "recompose/onlyUpdateForKeys"
import { linkToRecord } from "react-admin"
import ContentCreate from "@material-ui/icons/Create"
import { Link } from "react-router-dom"
import Button from "./Button"

const HeaderEditButton = ({
  basePath = "",
  className,
  classes: classesOverride,
  label = "ra.action.edit",
  icon = <ContentCreate />,
  record = {},
  ...rest
}) => {
  return (
    <Button
      component={Link}
      to={linkToRecord(basePath, record.id)}
      className={className}
      label={label}
      {...rest}
    >
      {icon}
    </Button>
  )
}

HeaderEditButton.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  size: PropTypes.string,
  sizeIcon: PropTypes.string,
  icon: PropTypes.element,
}

const enhance = onlyUpdateForKeys(["basePath", "label", "translate", "record"])
export default enhance(HeaderEditButton)
