import React from "react";
import { Badge } from "antd";
import { get } from "lodash";
import { Flex, Text } from "rebass";
import { FontelloSvg } from "./Icons";

const Account3 = ({ cart, settings, isMobile }) => {
  const { items } = cart;
  const colorPrimary = get(settings, `theme.themes.colors.primary`, "#6B5B37");
  return (
    <Flex
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      pl={[3]}
    >
      {!isMobile && (
        <Flex
          width={["100px"]}
          flexDirection={"row"}
          alignItems={"center"}
          px={[2]}
        >
          <FontelloSvg name={"user_o"} fill={colorPrimary} size='22px' />
          <Text fontSize={[0]} pt={[1]} px={[2]}>
            Account
          </Text>
        </Flex>
      )}
      <Flex flexDirection={"row"}>
        {isMobile && (
          <Flex pr={[1]}>
            <FontelloSvg name={"search"} fill={colorPrimary} size='22px' />
          </Flex>
        )}
        <Flex flexDirection={"column"} alignItems={"center"} px={[0, 3]}>
          <Badge count={items.length + 3} style={{ display: "flex" }}>
            <Flex flexDirection={"column"} alignItems={"center"} pb={["1px"]}>
              <FontelloSvg
                name={"shoppingCart"}
                fill={colorPrimary}
                size={"16px"}
              />
              {!isMobile && (
                <Text fontSize={[0]} pt={[1]}>
                  Cart
                </Text>
              )}
            </Flex>
          </Badge>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Account3;
