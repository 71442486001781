import React, { useState } from "react";
// import { Link } from 'umi';
import { get, find } from "lodash";
import { Flex, Box } from "rebass";
import MobileListMenu from "./MobileListMenu";
import { Popover, Button } from "antd";
import { MenuItem, MenusList } from "./MenuDesktop";
import { useWindowSize } from "../../../components/hooks";
const BOX_SHADOW_COLOR = `rgba(66, 66, 66, 0.2) 0px 3px 3px -2px, rgba(66, 66, 66, 0.14) 0px 3px 4px 0px, rgba(66, 66, 66, 0.12) 0px 1px 8px 0px`;

const ListMenu = (props) => {
  // const { show, onClose, history, style } = props
  const { settings, categories } = props;
  const screens = useWindowSize();
  const widthScreen = get(screens, "width", 400) - 14;
  const list = get(settings, "header.list", []);
  const titleButton = get(settings, "header.titleButton", "");
  const newList = list.map((item, index) => {
    let myLabel = item.title;
    let myItem = { item: item };
    if (item.type === "category" && categories) {
      const category = find(categories, function (o) {
        return o.category_id === item.category_id;
      });
      myLabel = category.title;
      myItem = { item: item, category: category };
    }

    return { key: index, label: myLabel, items: <MenuItem {...myItem} /> };
  });
  return (
    <div
      className={"menu-container"}
      style={{ position: "absolute", marginLeft: 60 }}
    >
      <Popover
        placement='bottomLeft'
        autoAdjustOverflow={false}
        overlayStyle={{
          height: "auto",
          boxShadow: BOX_SHADOW_COLOR,
        }}
        color={"#FFFFFF"}
        title={""}
        content={
          <div
            style={{
              position: "relative",
              marginLeft: "-14px",
              width: widthScreen,
            }}
          >
            <MenusList newList={newList} />
          </div>
        }
        // trigger='click'
      >
        <Button type={"text"}>
          <span style={{ color: "#FFFFFF" }}>{titleButton}</span>
        </Button>
      </Popover>
    </div>
  );
};

const Menu = (props) => {
  const { settings, categories } = props;
  // console.log(categories)
  const [show, setShow] = useState(false);
  if (props.isMobile) {
    return (
      <Flex
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"center"}
      >
        <Box
          onClick={() => setShow(!show)}
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: "24px",
            height: "24px",
            background: "transparent",
            border: "none",
            cursor: "pointer",
            padding: 0,
            zIndex: 2000,
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <div
            className='bar-menu'
            style={{ transform: show ? "rotate(45deg)" : "rotate(0)" }}
          />
          <div
            className='bar-menu'
            style={{
              opacity: show ? 0 : 1,
              transform: show ? "translateX(20px)" : "translateX(0)",
            }}
          />
          <div
            className='bar-menu'
            style={{ transform: show ? "rotate(-45deg)" : "rotate(0)" }}
          />
        </Box>
        <MobileListMenu
          show={show}
          onClose={() => setShow(false)}
          categories={categories}
          settings={settings}
        />
      </Flex>
    );
  }
  return (
    <ListMenu
      show={show}
      onClose={() => setShow(false)}
      settings={settings}
      style={props.style}
      categories={categories}
    />
  );
};
export default Menu;
