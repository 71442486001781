import React from "react"
import { get } from "lodash"
import { showNotification, useQueryWithStore } from "react-admin"
import { withRouter } from "react-router-dom"
import { Box } from "rebass"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { CreateForm } from "./components"
import { FullLoadingScreen, CardPanel } from "../../components"

const SourceCreate = (props) => {
  // const { match } = props
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "sites",
    payload: {
      id: get(props, "match.params.site_id", 0)
        ? get(props, "match.params.site_id", 0) * 1
        : 0,
    },
  })

  if (loading) {
    return <FullLoadingScreen />
  }
  const initialValues = {
    site_id: get(data, "id", 0),
  }
  return (
    <CardPanel>
      <Box width={[1]}>
        <CreateForm
          {...props}
          sourceApi="sources"
          typePostUrl={"CREATE"}
          pathUrl="sources"
          initialValues={initialValues}
        />
      </Box>
    </CardPanel>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(SourceCreate)

// const SourceCreate = (props) => {
//   // const initialValues = {
//   //   site_id: data.id,
//   // }
//   return (
//     <BetterCreate
//       {...props}
//       actions={<ActionForm title={"resources.accounts.name"} />}
//     >
//       <FormFields {...props} toolbar={<FooterToolbar />} />
//     </BetterCreate>
//   )
// }
