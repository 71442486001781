import React from "react";
import { showNotification, useQuery } from "react-admin";
import { withRouter } from "react-router-dom";
import { Box } from "rebass";
import { get } from "lodash";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { FormFields } from "./components";
import { FullLoadingScreen, CardPanel } from "../../components";

const ShippingsCreate = (props) => {
  const { match } = props;
  const { data, loading } = useQuery({
    type: "getOne",
    resource: "websites",
    payload: { id: get(match, "params.website_id", 0) },
  });

  if (loading) {
    return <FullLoadingScreen />;
  }

  const initialValues = {
    website_id: get(data, "id", 0),
  };
  return (
    <CardPanel>
      <Box width={[1]}>
        <FormFields
          {...props}
          title={"resources.shippings.create_title"}
          sourceApi="packagings"
          typePostUrl={"CREATE"}
          pathUrl="shipping"
          initialValues={initialValues}
        />
      </Box>
    </CardPanel>
  );
};

const enhance = compose(withRouter, connect(undefined, { showNotification }));

export default enhance(ShippingsCreate);
