import React from 'react';
import { Button } from 'rebass';

export const defaultBorder = {
  type: 'solid',
  size: 0,
  color: '#e61919',
};

export const ButtonView = ({
  typeButton,
  fullWidth,
  buttonColors,
  isBorder,
  newChildIcon,
  labelPosition,
  text,
  defaultBorderSize,
  borderRadius,
  fontSize,
  buttonPadding,
}) => {
  if (typeButton === 'raised') {
    return (
      <Button
        bg={buttonColors.backgroundColor}
        style={styleCheckFullWidth(fullWidth, buttonColors, isBorder, {
          borderRadius: borderRadius,
          fontSize: fontSize,
          padding: buttonPadding,
        })}
      >
        {labelPosition === 'before'
          ? `${text} ${newChildIcon}`
          : ` ${newChildIcon} ${text}`}
      </Button>
    );
  } else if (typeButton === 'flat') {
    return (
      <Button
        style={styleCheckFullWidth(fullWidth, buttonColors, isBorder, {
          backgroundColor: 'transparent',
          color: buttonColors.color,
          boxShadow: `inset 0 0 0 ${defaultBorderSize}px`,
          borderRadius: borderRadius,
          fontSize: fontSize,
          padding: buttonPadding,
        })}
      >
        {labelPosition === 'before'
          ? `${text} ${newChildIcon}`
          : ` ${newChildIcon} ${text}`}
      </Button>
    );
  } else {
    return <div />;
  }
};

const styleCheckFullWidth = (fullWidth, buttonColors, isBorder, addStyle) => {
  if (fullWidth) {
    return {
      height: 'auto',
      lineHeight: '200%',
      color: buttonColors.color,
      textTransform: 'none',
      border: isBorder,
      width: '100%',
      ...addStyle,
      cursor: 'pointer',
    };
  }
  return {
    height: 'auto',
    lineHeight: '200%',
    color: buttonColors.color,
    textTransform: 'none',
    border: isBorder,
    ...addStyle,
    cursor: 'pointer',
  };
};
