import React, { Component } from "react"
import { Box, Flex, Text } from "rebass"
import { filter, isArray } from "lodash"
import { IconButton } from "@material-ui/core"
import DesktopIcon from "@material-ui/icons/DesktopMacTwoTone"
import MobileIcon from "@material-ui/icons/PhoneIphoneTwoTone"
import CloseIcon from "@material-ui/icons/CloseRounded"
import { HeaderPage } from "../../../editorPreview"
// import { DialogTitle, DialogContent } from "../../../components"
import { BoxMobileHeader } from "./ContentLayout"
// import { Menu } from "antd"
// const { SubMenu } = Menu
class ShowContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      device: "desktop",
      show: false,
    }
  }

  handleOpenDesktop = () => {
    this.setState({
      open: true,
      device: "desktop",
    })
  }
  handleOpenMobile = () => {
    this.setState({
      open: true,
      device: "mobile",
    })
  }
  handleClose = () => {
    this.setState({
      open: false,
    })
  }
  showDeviceDesktop = () => {
    this.setState({
      device: "desktop",
    })
  }
  showDeviceMobile = () => {
    this.setState({
      device: "mobile",
    })
  }

  onClickItem = (e) => {}
  onClose = () => {
    this.setState({ show: false })
  }
  render() {
    const { device } = this.state
    const {
      localeId,
      content,
      heightScreen,
      widthScreen,
      websiteId,
    } = this.props
    let newContent = undefined
    if (this.props.open) {
      const localeContent = filter(content, function (o) {
        return o.id === localeId
      })

      newContent =
        localeContent.length > 0 ? localeContent[0].layoutSelected : undefined
    }
    return (
      <Box width={[1]}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"space-between"}
        >
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Text pr={[4]}>Preview</Text>

            <Flex flexDirection={"row"} alignItems={"center"}>
              <IconButton onClick={this.showDeviceDesktop} color={"primary"}>
                <DesktopIcon style={{ color: "#0087BA" }} />
              </IconButton>
              <Flex p={[2]} />
              <IconButton onClick={this.showDeviceMobile}>
                <MobileIcon style={{ color: "#0087BA" }} />
              </IconButton>
            </Flex>
          </Flex>
          <IconButton
            onClick={() => this.props.onShow(false)}
            color={"primary"}
          >
            <CloseIcon style={{ color: "#0087BA" }} />
          </IconButton>
        </Flex>
        <Box width={[1]} style={{ height: device === "mobile" ? 680 : 400 }}>
          {newContent &&
            !isArray(newContent) &&
            (device === "mobile" ? (
              <BoxMobileHeader
                data={newContent}
                locale={localeId}
                heightScreen={heightScreen}
                widthScreen={widthScreen}
                websiteId={websiteId}
              />
            ) : (
              <HeaderPage
                data={newContent}
                locale={localeId}
                heightScreen={heightScreen}
                widthScreen={widthScreen}
                websiteId={websiteId}
              />
            ))}
        </Box>
      </Box>
    )
  }
}

export default ShowContent
