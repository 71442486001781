import React from "react";
import { get } from "lodash";
import { Flex } from "rebass";
import styled from "styled-components";
import { getTreeFromFlatData } from "../menu/utils";
import { useStoreTemp } from "../../../../../screens/layouts/components/utils";
const MyDivUL = styled.div`
  a {
    color: ${(props) => (props.menuColor ? props.menuColor : "#000000")};
  }
  ul.nav:first-child li > ul:before {
    background-color: ${(props) =>
      props.colorPrimary ? props.colorPrimary : "#000000"};
  }
  ul.nav:first-child li > ul:after {
    background-color: ${(props) =>
      props.colorPrimary ? props.colorPrimary : "#000000"};
  }
  ul.nav:first-child > li:hover > a:not(:last-child):after {
    border-bottom: 15px solid
      ${(props) => (props.colorPrimary ? props.colorPrimary : "#000000")};
  }
`;

export default (props) => {
  const { settingTemp } = useStoreTemp();
  const { colorProps } = props.values;
  const listMenu = get(settingTemp, "menu", []);
  const menus = getTreeFromFlatData({
    flatData: listMenu.map((node) => ({ ...node, title: node.title })),
    getKey: (node) => node.id,
    getParentKey: (node) => node.parent_id,
    rootKey: "root",
  });
  return (
    <Flex width={[1]} justifyContent={"center"} alignItems={"center"}>
      <MyDivUL
        colorPrimary={colorProps.color[1]}
        menuColor={colorProps.color[1]}
        backgroundColor={colorProps.backgroundColor[1]}
      >
        <ul className='nav'>
          {menus.map((il, index) => {
            return (
              <li key={index} style={{ color: colorProps.color[1] }}>
                {il.title}
              </li>
            );
          })}
        </ul>
      </MyDivUL>
    </Flex>
  );
};
