export default {
  products: {
    name: "Products",
    new_title: "New Product",
    new_titles: "New Products",
    edit_title: "Edit Product",
    title_expand: "Restrictions",
    tabs: {
      info: "Info",
      variants: "Variants",
      images: "Images",
      categories: "Categories",
      attributes: "Attributes",
      custom: "Customizations",
      bundles: "Bundles",
    },
    fields: {
      active: "Active",
      id: "ID",
      name: "Name",
      photos: "Photos",
      type: "Type",
      remarks: "Remarks",
      terms: "Terms",
      category: "Category",
      categories: "Categories",
      expiry_days: "Expiry Days",
      points: "Points",
      value: "Value",
      point_need: "Points Needed",
      reward_value: "Reward Value",
      sku: "SKU",
      min_order_value: "Mininum Order Value",
      reward_validity: "Reward Validity (Days)",
      not_in_branches: "Not in Branches",
      cost: "Cost",
      price: "Price",
      margin: "Margin",
      slug: "Slug",
      brand: "Brand",
      sort_order: "Sort Order",
      shipping_weight: "Shipping Weight (kg)",
      discount: "Discount",
      unit_price: "Unit Price",
      description: "Description",
      unit: "Unit",
      value_number: "Value (in number)",
      value_text: "Value (in text)",
      option_title: "Option Title",
      option_page: "Option Content Page",
      option_type: "Option Type",
      option_required: "Required",
      title: "Title",
      addvalue: "Add Value",
      product: "Product",
      code: "Code",
      threshold: "Threshold",
      name_sku: "Name & SKU",
      variant: "Variant",
      options: "Options",
      attribute: "Attribute",
      filters: "Filters",
      attributeSet: "Attribute Set",
      switch: "Yes/No",
      searchable_colors: "Searchable Colors",
      launch_date: "Lauch Date",
      tags: "Tags",
      shipping: {
        weight: "Weight (Kg)",
        height: "Height",
        width: "Width",
        subsidy: "Subsidy",
        length: "Length",
        box: "Box",
        free_shipping: "Free Shipping",
      },
      qty: "Qty",
      line_number: "Line Number",
    },
    label: {
      show_sku: "Show SKU",
      show_categories: "Show Categories",
      buy: "Buy",
      save: "Save",
      buymoresavemore: "Buy more Save more",
    },
    note_text: {
      loading: "Loading...",
      image_variant:
        "Important: Please complete Variant information first before upload product image.",
      slug: "Product url",
      point_need: "Loyalty points needed to redeem this reward",
      reward_value: "Amount to be deducted from subtotal",
      sku: "Restricted to the above SKUs only",
      min_order_value:
        "Miniumn order amount in the shopping cart during checkout",
      reward_validity:
        "Number of days reward is valid after redemption. Put 0 if no expiry",
      not_in_branches: "List of branches not participate for this product",
      check_name_product:
        "Please fill name field on the info tab and complete Variant Information to upload images",
      searchable_colors: "All searchable colors from variants of the product",
    },
    messages: {
      upload_csv_success: "File Uploaded",
      import_product_failed: "Import Failed",
      import_product_failed_500: "Import Failed 500",
    },
  },
};
