import React from "react";
import styled from "styled-components";
import { get } from "lodash";
/**imageUrl: {
    url: "",
    urlMobile: "",
  },
  isShowMobile: true,
  isShowDesktop: true,
  altText: "Image one",
  desktop: {
    width: "100%",
    height: "100%",
    containerMargin: "0px",
    containerPadding: "0px",
    order: 1,
  }, */
const Image = styled.img`
  opacity: 1;
  display: block;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`;
export default (props) => {
  const { item, indexView } = props;
  // console.log(item);
  return (
    <div
      style={{
        border: `${get(props, `border.size[${indexView}]`, 0)}px 
          ${get(props, `border.type[${indexView}]`)}
          ${get(props, `border.color[${indexView}]`)}
         `,
        backgroundColor: get(
          props,
          `border.backgroundColor[${indexView}]`,
          "transparent"
        ),
        padding: get(item, "content.desktop.containerPadding", "0px"),
      }}
    >
      {get(item, "content.imageUrl.url", "") !== "" && (
        <Image
          src={get(item, "content.imageUrl.url", "")}
          alt={"image"}
          style={{
            width: get(item, "desktop.width", "100%"),
            height: get(item, "content.desktop.height", "100%"),
            margin: get(item, "content.desktop.containerMargin", "0px"),
            objectFit: "contain",
          }}
        />
      )}
    </div>
  );
};
