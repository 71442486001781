import { useState, useCallback } from "react";
import { get, has, indexOf } from "lodash";
const propDescription = "description";
const propLocale = "locale";
const compareProductShow = {
  id: "id",
  name: "name",
  slug: "apple-ipad-7-wi-fi-cellular-32gb",
  price: "price",
  margin: "margin",
  photos: "photos",
  discount: "discount",
  product_type: "product_type",
  event: "event",
  advanced_price: "advanced_price",
  unit_price: "unit_price",
};

export function removeProductObject(data) {
  const newProduct = Object.keys(data).reduce((object, key) => {
    if (compareProductShow[key]) {
      object[key] = data[key];
    } else {
      if (key === propLocale) {
        let mylocale = data[propLocale];
        Object.keys(mylocale).map((keyLocale) => {
          let myloc = mylocale[keyLocale];
          const testing = Object.keys(myloc).reduce((obj, key2) => {
            if (key2 !== propDescription) {
              obj[key2] = myloc[key2];
            }
            return obj;
          }, {});
          myloc = testing;
          mylocale[keyLocale] = testing;
          return myloc;
        });
        object[propLocale] = mylocale;
      }
    }
    return object;
  }, {});
  return newProduct;
}

const placeholder = "";

export const getPhoto = function (photos) {
  var src = placeholder;

  if (has(photos, "photo_set")) {
    var photoSet = get(photos, "photo_set");
    var key = Object.keys(photoSet);
    var path = `photo_set.${key[0]}.photos[0]`;
    src = get(photos, path, placeholder);
  } else if (has(photos, "*")) {
    src = get(photos, "*.photos[0]", placeholder);
  }
  return src;
};

export const getPhotos = function (photos, selectedVariant) {
  var defaultPhoto = [placeholder];

  // when product has color variant
  if (has(photos, "photo_set")) {
    var photoSet = get(photos, "photo_set");
    var key = Object.keys(photoSet);
    // get color value from selectedVariant
    var selectedColor = indexOf(key, selectedVariant["color"]);
    var photoIndex = selectedColor > -1 ? selectedColor : 0;

    var path = `photo_set.${key[photoIndex]}.photos`;
    return get(photos, path, defaultPhoto);
  } else if (has(photos, "*")) {
    return get(photos, "*.photos", defaultPhoto);
  }

  return defaultPhoto;
};

export const useClientRect = () => {
  const [rect, setRect] = useState({ width: 0, height: 0 });
  const ref = useCallback((node) => {
    if (node !== null) {
      const { width, height } = node.getBoundingClientRect();
      setRect({ width, height });
    }
  }, []);
  return [rect, ref];
};

export const defaultSetting = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 200,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  rows: 0,
  slidesPerRow: 0,
  autoplay: false,
  autoplaySpeed: false,
};

export const settingsList = (setting, screenWidth, onChange) => {
  if (setting) {
    return {
      dots: setting.dots,
      infinite: setting.infinite,
      speed: setting.speed,
      arrows: setting.arrows,
      slidesToShow:
        screenWidth > 600
          ? setting.slidesToShow
          : setting.slidesToShow > 3
          ? 3
          : setting.slidesToShow,
      slidesToScroll: setting.slidesToScroll,
      autoplay: setting.autoplay,
      autoplaySpeed: setting.autoplaySpeed,
      afterChange: (index) => onChange(index),
    };
  }

  return {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 200,
    slidesToShow:
      screenWidth > 600
        ? setting.slidesToShow
        : setting.slidesToShow > 3
        ? 3
        : setting.slidesToShow,
    slidesToScroll: 1,
    afterChange: (index) => onChange(index),
  };
};
