export default {
  errors: {
    no_have_items: "You don’t have any item in the list yet",
    no_have_product: "No Product",
    please_try_again: "Something error, please try again",
    please_select_type: "Please Select type",
    please_select_packging:
      "Please select packaging or fill height x length x width and weight",
    please_select_ship_back_to: "Please select ship back to",
    please_fill_remarks: "Please fill remarks",
    please_select_reason: "Please select reason",
    please_select_product_picked: "Please add product to pick",
    please_select_status: "Please select Status",
    list_empty: "List Empty",
  },
};
