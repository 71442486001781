import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import { Counter, BlockCounter, Title } from "../components";
import { SketchColorPicker } from "../../../components";
import { ContentDetail } from "../actions";

export default (props) => {
  const { state, onSetValue, title } = props;
  const myLineHeight = get(state, "lineHeight", "120%");
  const myOrder = get(state, "order", 1);
  const myFontSize = get(state, "fontSize", 12);
  const lineHeight = myLineHeight.split("%").join("") * 1;
  const myLetterSpacing = get(state, "letterSpacing", "1px");
  const letterSpacing = myLetterSpacing.split("px").join("") * 1;
  const color = get(state, "color", "#000000");
  const backgroundColor = get(state, "backgroundColor", "transparent");
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      flexWrap={"wrap"}
      sx={{
        "&>div": {
          width: ["320px"],
          p: [2],
          mr: [3],
        },
      }}
    >
      <ContentDetail>
        <Flex
          flexDirection={"row"}
          width={[1]}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Title title='line Height' />
          <Counter
            onSetValue={(value) => onSetValue("lineHeight", `${value}%`)}
            value={lineHeight}
            interval={10}
            subtitle='%'
            minValue={100}
          />
        </Flex>
        <Flex
          flexDirection={"row"}
          width={[1]}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Title title='Letter Spacing' />
          <Counter
            onSetValue={(value) => onSetValue("letterSpacing", `${value}px`)}
            value={letterSpacing}
            interval={1}
            subtitle='px'
            minValue={-5}
          />
        </Flex>
        <Flex
          flexDirection={"row"}
          width={[1]}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Title title='Font Size' />
          <Counter
            onSetValue={(value) => onSetValue("fontSize", value)}
            value={myFontSize}
            interval={1}
            subtitle='pt'
            minValue={5}
          />
        </Flex>
        <Flex
          flexDirection={"row"}
          width={[1]}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Title title='Order' />
          <Counter
            onSetValue={(value) => onSetValue("order", value)}
            value={myOrder}
            interval={1}
            subtitle=''
            minValue={1}
          />
        </Flex>
      </ContentDetail>
      <BlockCounter
        title='Container Padding'
        containerPadding={state.containerPadding}
        onUpdatePadding={(cp) => onSetValue("containerPadding", cp)}
      />

      <ContentDetail>
        <Title title={title} />
        <SketchColorPicker
          title={"Background"}
          onUpdateColor={(color) => onSetValue("backgroundColor", color)}
          color={backgroundColor}
        />
        <SketchColorPicker
          title={"Color"}
          onUpdateColor={(color) => onSetValue("color", color)}
          color={color}
        />
      </ContentDetail>
    </Flex>
  );
};
