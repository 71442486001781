import React from "react";
// import ContainerDimensions from "react-container-dimensions";
//has,
import { get } from "lodash";
import { Row } from "antd";
import { connect } from "../../../core";
import { getStylGradiant, getBGProps } from "../../../libs/gradiant";
// const bgPropertiesDefault = {
//   size: "cover",
//   position: "center center",
//   repeat: "no-repeat",
//   ratio: 1.8,
// };

const Row1 = (props) => {
  const { id, layoutState, children, widthScreen } = props;
  const { values } = layoutState.getItemJS(id).props;
  // console.log(values);
  // console.log(widthScreen);
  const isMobileScreen = widthScreen < 480;
  const contentWidth = get(
    values,
    isMobileScreen ? "mobile.width" : "desktop.width",
    "100%"
  );
  const contentPadding = get(
    values,
    isMobileScreen ? "mobile.padding" : "desktop.padding",
    "0px"
  );
  const contentHeight = get(
    values,
    isMobileScreen ? "mobile.height" : "desktop.height",
    "100%"
  );
  const layoutRowAlign = get(
    values,
    isMobileScreen ? "mobile.layout.align" : "desktop.layout.align",
    "middle"
  );
  const layoutRowGutter = get(
    values,
    isMobileScreen ? "mobile.layout.gutter" : "desktop.layout.gutter",
    0
  );
  const layoutRowAlignContainer = get(
    values,
    isMobileScreen
      ? "mobile.layout.contentAlign"
      : "desktop.layout.contentAlign",
    "center"
  );
  const layoutRowVAlignContainer = get(
    values,
    isMobileScreen
      ? "mobile.layout.contentJustify"
      : "desktop.layout.contentJustify",
    "center"
  );

  const layoutRowjustify = get(
    values,
    isMobileScreen ? "mobile.layout.justify" : "desktop.layout.justify",
    "center"
  );
  const layoutRowWrap = get(
    values,
    isMobileScreen ? "mobile.layout.wrap" : "desktop.layout.wrap",
    false
  );
  const backgroundColor = get(
    values,
    isMobileScreen ? "mobile.backgroundColor" : "desktop.backgroundColor",
    "transparent"
  );
  let important = { backgroundColor: backgroundColor };
  const myGradient = isMobileScreen
    ? getStylGradiant(get(values, "mobile", {}))
    : getStylGradiant(get(values, "desktop", {}));
  if (myGradient) {
    if (myGradient.type) {
      const mydegre =
        myGradient.type === "radial-gradient"
          ? "circle"
          : `${myGradient.degre}deg`;
      const newBg = {
        background: `${myGradient.type}(${mydegre}, ${myGradient.bg1} ${myGradient.linear[0]}%,${myGradient.bg2} ${myGradient.linear[1]}%)`,
      };
      important = { background: myGradient.bg1, height: "100%", ...newBg };
    }
  }
  const myBGProps = isMobileScreen
    ? getBGProps(get(values, "mobile", {}))
    : getBGProps(get(values, "desktop", {}));
  if (myBGProps) {
    const minHeight = widthScreen / myBGProps.ratio;
    important = {
      backgroundImage: `url("${myBGProps.url}")`,
      backgroundSize: myBGProps.size,
      backgroundPosition: myBGProps.position,
      backgroundRepeat: myBGProps.repeat,
      minHeight: minHeight,
    };
  }
  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        padding: contentPadding,
        minHeight: children && children.length > 1 ? null : 30,
        minWidth: children && children.length > 1 ? null : 30,
        display: "flex",
        flex: 1,
        width: "inherit",
        alignItems: layoutRowAlignContainer,
        justifyContent: layoutRowVAlignContainer,
        flexDirection: "column",
        ...important,
      }}
    >
      <Row
        style={{
          width: contentWidth,
          height: contentHeight,
          flexWrap: layoutRowWrap ? "wrap" : "nowrap",
        }}
        align={layoutRowAlign}
        justify={layoutRowjustify}
        gutter={layoutRowGutter}
      >
        {children}
      </Row>
    </div>
  );
};

export default connect("layoutState", "widthScreen")(Row1);
