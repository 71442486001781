import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout2 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const ImageView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='ImageView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

ImageView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};

ImageView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "ImageView",
    desktop: {
      show: true,
      width: "100%",
      height: "100%",
      margin: "0px",
      padding: "0px",
      isFullHeight: false,
    },
    mobile: {
      show: true,
      width: "100%",
      height: "100%",
      margin: "0px",
      padding: "0px",
      isFullHeight: false,
    },
    imageUrl: {
      url: "",
      urlMobile: "",
    },
    align: "center",
    link: {
      target: "",
      type: "URL",
      value: "",
      isLink: false,
    },
  },
  layout: Layout2,
};
export default ImageView;
