import React from "react";
import { Flex, Text, Box } from "rebass";
import AvatarUser from "./AvatarUser";
// import Badge from "@material-ui/core/Badge";
// import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { WithPermissions } from "react-admin";
import { SelectSiteID } from "./site_menu";
import { PROFILE } from "../../restClient";
import { ROLE_USER } from "../../utils";
import Popover from "@material-ui/core/Popover";

const PopHoverUserContent = ({ newProfile }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-content-popover" : undefined;
  return (
    <div>
      <AvatarUser src={newProfile.avatar} onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box width={[1]} p={[2]}>
          <Text fontSize={12}>
            {newProfile.first_name} {newProfile.last_name}
          </Text>
          <Text fontSize={12}>{newProfile.email}</Text>
        </Box>
      </Popover>
    </div>
  );
};

export const UserContent = (props) => {
  const profile = localStorage.getItem(PROFILE);
  if (
    (profile === undefined || profile) === null ||
    profile === "[object Object]"
  ) {
    return <span />;
  }

  const newProfile = profile && profile !== null ? JSON.parse(profile) : {};
  // console.log(profile)
  // console.log(newProfile)
  return (
    <Flex flexDirection={["row"]} alignItems={"center"}>
      <WithPermissions
        render={({ permissions }) =>
          permissions === ROLE_USER.super_admin && <SelectSiteID />
        }
      />

      {/* <LoadingIndicator /> */}
      {/* <AvatarUser src={newProfile.avatar} /> */}
      {/* <Box pl={[2]}>
        <Badge
          color="error"
          badgeContent={3}
          variant="dot"
          overlap="circle"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <NotificationsNoneIcon style={{ color: "#FFFFFF" }} />
        </Badge>
      </Box> */}
      <PopHoverUserContent newProfile={newProfile} />
    </Flex>
  );
};
