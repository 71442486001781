import React, { Fragment, useState } from "react";
import { Flex } from "rebass";
import { replace } from "lodash";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { HeaderTitle } from "../../../../components";

import { RestApiCRUDString } from "../../../../restClient";
import { useQuery } from "../../../../components";
import EditDetailFields from "./EditDetailFields";
import RefundReturnList from "./RefundReturnList";
const EditMainFields = (props) => {
  const {
    record,
    history,
    translate,
    orderId,
    location,
    notify,
    amendmentId,
    dataAmendment,
  } = props;
  const [loading, setLoading] = useState(false);
  //by default source_id 1
  const [state, setState] = useState({
    ...dataAmendment,
  });
  const websiteData = useQuery(`/websites/${record.website_id}`);
  const website = websiteData.data;
  const url = replace(location.pathname, `/${amendmentId}`, "");
  const onSetValue = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };
  const onRequestShipment = () => {
    if (state.remarks === "") {
      notify("resources.errors.please_fill_remarks", "warning", {
        smart_count: 1,
      });
    } else if (state.status === "") {
      notify("resources.errors.please_select_status", "warning", {
        smart_count: 1,
      });
    } else {
      setLoading(true);
      RestApiCRUDString(
        `/orders/${orderId}/amendments/${amendmentId}`,
        "PUT",
        state
      )
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          setLoading(false);
          if (res && res.message && res.message !== "") {
            notify(res.message, "warning", {
              smart_count: 1,
            });
          } else {
            notify("ra.notification.updated", "success", {
              smart_count: 1,
            });
            history.replace(url);
          }
        })
        .catch((e) => {
          //   console.log(e.message);
          notify("resources.errors.please_try_again", "warning", {
            smart_count: 1,
          });
          setLoading(false);
        });
    }
  };
  return (
    <Flex width={[1]} flexDirection={"column"} pb={[2]}>
      <HeaderTitle title={"resources.orders.title.edit_amendment"}>
        <Fragment>
          <Button component={Link} color={"primary"} to={url}>
            {translate("resources.buttons.list")}
          </Button>
        </Fragment>
      </HeaderTitle>
      <Flex width={[1]} flexDirection={"column"} px={[2]}>
        <EditDetailFields
          {...props}
          state={state}
          onSetValue={onSetValue}
          website={website}
        />
        <RefundReturnList
          {...props}
          onRequestShipment={onRequestShipment}
          website={website}
          loading={loading}
          state={state}
        />
      </Flex>
    </Flex>
  );
};

export default EditMainFields;
