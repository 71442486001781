import React from "react"
import styled from "styled-components"
import { Text } from "rebass"
import { grey } from "@material-ui/core/colors"
import CameraAltIcon from "@material-ui/icons/CameraAlt"

export const DivContainer = styled.div`
  display: flex;
  flex: 1 auto;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding: 8px 0;
`
export const SpanImage = styled.span`
  font-size: 10px;
`

export const BoxImageView = styled.div`
  display: flex;
  flex: 1;
  height: auto;
  padding: 0 0px 0 10px;
`
export const BoxImageViewSmall = styled.div`
  width: 80px;
  height: auto;
  padding: 0 10px 0 10px;
`
export const Image = styled.img`
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`
export const InsideBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: inherit;
`

export const GridImage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
`
export const Content = styled.div`
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
`

export const BoxUpload = styled.div`
  width: 90px;
  height: 90px;
  display: flex;
  border: 1px solid ${grey[300]};
  justify-content: center;
  align-ttems: center;
  borderwidth: 0;
  cursor: pointer;
  & > input {
    outline: none;

    *:focus {
      outline: none;
    }
  }
  *:focus {
    outline: none;
  }
`
export const BoxContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: inherit;
  fontsize: 12px;
`

export const BoxInside = () => {
  return (
    <BoxContent>
      <CameraAltIcon style={{ fontSize: 30, color: "#ccc" }} />
      <Text
        fontSize={"0.5rem"}
        p={[2]}
        pt={[1]}
        color={"#008bdb"}
        textAlign={"center"}
      >
        Browser to find or drag image here
      </Text>
      {/* <Text fontSize={[0]} color={"#008bdb"}>
        or Click here
      </Text> */}
    </BoxContent>
  )
}
