import { Box } from "rebass";
import styled from "styled-components";

export const Discount = styled(Box)((props) => ({
  width: props.size || "30px",
  height: props.size || "30px",
  fontSize: props.fontSize || "0.8em",
  borderRadius: "50%",
  textAlign: "center",
  verticalAlign: "middle",
  lineHeight: props.size || "30px",
}));

export const BoxPromotion = styled(Box)((props) => ({
  width: props.size || "30px",
  height: props.size || "30px",
  fontSize: props.fontSize || "0.8em",
  borderRadius: "50%",
  textAlign: "center",
  verticalAlign: "middle",
  lineHeight: props.size || "30px",
}));
