import { get, has } from "lodash"
export const typeListContainer = [
  { value: "product", text: "Product" },
  { value: "category", text: "Category" },
]

export const getPhoto = function (photos) {
  var src = ""

  if (has(photos, "photo_set")) {
    var photoSet = get(photos, "photo_set")
    var key = Object.keys(photoSet)
    var path = `photo_set.${key[0]}.photos[0]`
    src = get(photos, path, "")
  } else if (has(photos, "*")) {
    src = get(photos, "*.photos[0]", "")
  }
  return src
}
