import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { IconButton, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Flex, Box, Text } from "rebass";
import { get } from "lodash";
import { Form, Field } from "react-final-form";
import Button from "@material-ui/core/Button";
import SwipeableViews from "react-swipeable-views";
import {
  useWindowSize,
  TabPanel,
  a11yProps,
  RTDescriptionProduct,
  Loading,
} from "../../../components";
import Divider from "@material-ui/core/Divider";
import { labelObject } from "../../../utils";
import { Tabs, Tab } from "@material-ui/core";
import { withDataProvider } from "react-admin";

const toolbar = [
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ align: [] }],
  [{ color: [] }, { background: [] }],
  ["image"],
];

const CreateQandA = (props) => {
  const {
    toggleDrawer,
    open,
    translate,
    website,
    dataProvider,
    topic_id,
    initialValues,
    status,
    onClose,
  } = props;
  const { width } = useWindowSize();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const mySize = width > 0 ? `${width - 60}px` : 256;
  const isSingle = get(website, "locale.supported_language", []);
  const defaultLocale = get(website, "locale.default_language", "");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const onSubmit = (values) => {
    setLoading(true);
    let newValues = values;
    if (values && values.locale && topic_id && defaultLocale !== "") {
      newValues = {
        ...values,
        question: get(values, `locale.${defaultLocale}.question`, ""),
        answer: get(values, `locale.${defaultLocale}.answer`, ""),
      };
    }
    if (status === "create") {
      dataProvider
        .create("questionAnswers", { data: newValues })
        .then(({ data }) => {
          console.log("success", data);
          setLoading(false);
          onClose();
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
        });
    } else {
      dataProvider
        .update("questionAnswers", {
          id: newValues.id,
          data: newValues,
          previousData: initialValues,
        })
        .then(({ data }) => {
          console.log("success", data);
          setLoading(false);
          onClose();
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
        });
    }
  };
  return (
    <SwipeableDrawer
      anchor={"right"}
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Box width={[mySize]}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={[3]}
          py={[2]}
        >
          <Text fontSize={[3]} fontWeight={"bold"}>
            {status === "create"
              ? translate("resources.faq.new_faq")
              : translate("resources.faq.edit_faq")}
          </Text>
          <Box>
            <IconButton aria-label='close' onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Flex>
        <Divider />
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Flex width={[1]} flexDirection={"column"}>
                {isSingle.length > 1 && (
                  <Box width={[1]} p={2}>
                    <Tabs
                      value={value}
                      indicatorColor='primary'
                      onChange={handleChange}
                    >
                      {isSingle.map((choice, index) => (
                        <Tab
                          key={choice}
                          label={get(labelObject, choice, "")}
                          {...a11yProps("tab-topic-qaa", index)}
                        />
                      ))}
                    </Tabs>
                    <Divider />
                    <SwipeableViews
                      index={value}
                      onChangeIndex={handleChangeIndex}
                    >
                      {isSingle.map((item, index) => (
                        <TabPanel
                          key={index}
                          value={value}
                          index={index}
                          name={`tab-topic-qaa`}
                        >
                          <div key={index}>
                            <Field name={`locale.${item}.question`}>
                              {(props) => (
                                <Box width={[1]} p={[2]}>
                                  <TextField
                                    label={translate(
                                      "resources.faq.fields.question"
                                    )}
                                    name={props.input.name}
                                    value={props.input.value}
                                    onChange={props.input.onChange}
                                    fullWidth={true}
                                  />
                                </Box>
                              )}
                            </Field>
                            <Field name={`locale.${item}.answer`}>
                              {(props) => (
                                <Box width={[1]} p={[2]}>
                                  <RTDescriptionProduct
                                    label={translate(
                                      "resources.faq.fields.answer"
                                    )}
                                    name={props.input.name}
                                    value={props.input.value}
                                    onChange={props.input.onChange}
                                    toolbar={toolbar}
                                  />
                                </Box>
                              )}
                            </Field>
                          </div>
                        </TabPanel>
                      ))}
                    </SwipeableViews>
                  </Box>
                )}
                {isSingle.length === 1 && (
                  <Box width={[1]}>
                    <Field name={`locale.${isSingle[0]}.question`}>
                      {(props) => (
                        <Box width={[1]} p={[2]}>
                          <TextField
                            label={translate("resources.faq.fields.question")}
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            fullWidth={true}
                          />
                        </Box>
                      )}
                    </Field>
                    <Field name={`locale.${isSingle[0]}.answer`}>
                      {(props) => (
                        <Box width={[1]} p={[2]}>
                          <RTDescriptionProduct
                            label={translate("resources.faq.fields.answer")}
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            toolbar={toolbar}
                          />
                        </Box>
                      )}
                    </Field>
                  </Box>
                )}
                {isSingle.length === 0 && (
                  <Box width={[1]}>
                    <Field name={`question`}>
                      {(props) => (
                        <Box width={[1]} p={[2]}>
                          <TextField
                            label={translate("resources.faq.fields.question")}
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            fullWidth={true}
                          />
                        </Box>
                      )}
                    </Field>
                    <Field name={`answer`}>
                      {(props) => (
                        <Box width={[1]} p={[2]}>
                          <RTDescriptionProduct
                            label={translate("resources.faq.fields.answer")}
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            toolbar={toolbar}
                          />
                        </Box>
                      )}
                    </Field>
                  </Box>
                )}
              </Flex>
              <Flex
                style={{ bottom: 0, position: "absolute" }}
                p={[2]}
                width={[1]}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                {loading && (
                  <Box pr={[2]}>
                    <Loading />
                  </Box>
                )}
                <Button type='submit' variant={"contained"} color={"primary"}>
                  {translate("resources.buttons.save")}
                </Button>
              </Flex>
            </form>
          )}
        </Form>
      </Box>
    </SwipeableDrawer>
  );
};

export default withDataProvider(CreateQandA);
