import React from "react";
import { Box } from "rebass";
import styled from "styled-components";
import pink from "@material-ui/core/colors/pink";

const pink500 = pink[500];
const styles = {
  backgroundColor: "#eee",
  padding: 0,
  outline: "2px solid #ccc",
};

const Content = styled.div`
  height: 100%;
`;

const DivContainer = styled.div`
  height: 50px;
  padding: 10px;
  margin: 4px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition-duration: 0.15s;
  transition-property: box-shadow;
  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.35);
  }
`;
export const Layout1 = () => (
  <DivContainer>
    <Box width={[1]} flex='0 1 auto' p={[1, 1, 1, 1]} style={styles}>
      <Content />
    </Box>
  </DivContainer>
);
export const Layout2 = () => (
  <DivContainer>
    <Box width={[1 / 2]} flex='0 1 auto' p={[1, 1, 1, 1]} style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 2]} flex='0 1 auto' p={[1, 1, 1, 1]} style={styles}>
      <Content />
    </Box>
  </DivContainer>
);
export const Layout3 = () => (
  <DivContainer>
    <Box width={[1 / 3]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 3]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 3]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
  </DivContainer>
);
export const Layout4 = () => (
  <DivContainer>
    <Box width={[1 / 4]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[3 / 4]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
  </DivContainer>
);
export const Layout5 = () => (
  <DivContainer>
    <Box width={[3 / 4]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 4]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
  </DivContainer>
);
export const Layout6 = () => (
  <DivContainer>
    <Box width={[1 / 4]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 4]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 4]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 4]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
  </DivContainer>
);

export const Layout7 = () => (
  <DivContainer>
    <Box
      width={[1]}
      flex='0 1 auto'
      p={[1, 1, 1, 1]}
      style={{ ...styles, backgroundColor: pink500 }}
    >
      <Content
        style={{
          color: "#FFFFFF",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        Row Carousel
      </Content>
    </Box>
  </DivContainer>
);

export const Layout8 = () => (
  <DivContainer>
    <Box width={[1 / 5]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 5]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 5]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 5]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 5]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
  </DivContainer>
);

export const Layout9 = () => (
  <DivContainer>
    <Box width={[1 / 6]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 6]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 6]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 6]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 6]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
    <Box width={[1 / 6]} flex='0 1 auto' style={styles}>
      <Content />
    </Box>
  </DivContainer>
);

export const LayoutMultiRow = () => (
  <DivContainer>
    <Box
      width={[1]}
      flex='0 1 auto'
      p={[1, 1, 1, 1]}
      style={{ ...styles, backgroundColor: pink500 }}
    >
      <Content
        style={{
          color: "#FFFFFF",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          // backgroundColor: pink500,
        }}
      >
        Multi Row
      </Content>
    </Box>
  </DivContainer>
);

export const layoutsleft = {
  layout1: Layout1,
  layout2: Layout2,
  layout3: Layout3,
  layout4: Layout4,
  layout5: Layout5,
  layout6: Layout6,
  layout7: Layout7,
  layout8: Layout8,
  layout9: Layout9,
  layoutMultiRow: LayoutMultiRow,
};
