import React, { useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Flex } from "rebass";
import { FooterAction } from "../actions";
import { BoxContent } from "../styled";
import { defaultSubcribe } from "./utils";
import Fields from "./Fields";

const SubcribeView = (props) => {
  const {
    placeholder,
    labelButton,
    urlSubcribe,
    mobile,
    desktop,
  } = props.data.values;
  const [state, setState] = useState({
    placeholder: placeholder,
    labelButton: labelButton,
    urlSubcribe: urlSubcribe,
    mobile: mobile || defaultSubcribe.mobile,
    desktop: desktop || defaultSubcribe.desktop,
  });
  const onSetValue = (k, v) => {
    setState({
      ...state,
      [k]: v,
    });
  };

  const onClickCopy = () => {
    let newData = props.data;
    newData.values.placeholder = state.placeholder;
    newData.values.labelButton = state.labelButton;
    newData.values.urlSubcribe = state.urlSubcribe;
    newData.values.mobile = state.mobile;
    newData.values.desktop = state.desktop;
    localStorage.setItem("SubcribeView", JSON.stringify(newData.values));
  };
  const onClickPaste = () => {
    const subcribeViewF = JSON.parse(localStorage.getItem("SubcribeView"));
    if (subcribeViewF) {
      const {
        placeholder,
        labelButton,
        urlSubcribe,
        mobile,
        desktop,
      } = subcribeViewF;
      setState({
        placeholder: placeholder,
        labelButton: labelButton,
        urlSubcribe: urlSubcribe,
        mobile: mobile,
        desktop: desktop,
      });
    }
  };
  const onClickSave = () => {
    let newData = props.data;
    newData.values.placeholder = state.placeholder;
    newData.values.labelButton = state.labelButton;
    newData.values.urlSubcribe = state.urlSubcribe;
    newData.values.mobile = get(state, "mobile.show", true)
      ? state.mobile
      : { show: false };
    newData.values.desktop = get(state, "desktop.show", true)
      ? state.desktop
      : { show: false };
    props.onUpdateData(newData);
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <BoxContent style={{ height: props.heightContent - 120 }}>
        <Fields state={state} onSetValue={(k, v) => onSetValue(k, v)} />
      </BoxContent>
      <FooterAction
        onClickSave={onClickSave}
        onClickCopy={onClickCopy}
        onClickPaste={onClickPaste}
        widthContent={props.widthContent}
      />
    </Flex>
  );
};

SubcribeView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

SubcribeView.defaultProps = {
  data: {},
};
export default SubcribeView;
