import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import {
  Counter,
  BlockCounter,
  Title,
  SwitchInput,
  Alignment,
} from "../components";
import { ContentDetail } from "../actions";
export default (props) => {
  const { state, onSetValue, title } = props;
  const myShow = get(state, "show", true);
  const myFontSize = get(state, "size", 12);
  const align = get(state, "align", "center");
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      flexWrap={"wrap"}
      sx={{
        "&>div": {
          width: ["320px"],
          p: [2],
          mr: [3],
        },
      }}
    >
      <ContentDetail>
        <SwitchInput
          id={`show${title}`}
          title={`Show on ${title}`}
          checked={myShow}
          onChange={(e) => onSetValue("show", e.target.checked)}
        />
        <BlockCounter
          title='Container Padding'
          containerPadding={state.containerPadding}
          onUpdatePadding={(cp) => onSetValue("containerPadding", cp)}
        />
        <Alignment
          onChange={(value) => onSetValue("align", value)}
          value={align}
          name='align'
          title={"Alignment"}
        />

        <Flex
          flexDirection={"row"}
          width={[1]}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Title title='Size' />
          <Counter
            onSetValue={(value) => onSetValue("size", value)}
            value={myFontSize}
            interval={1}
            subtitle=''
            minValue={5}
          />
        </Flex>
      </ContentDetail>
    </Flex>
  );
};
