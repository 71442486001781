import React from "react";
import { get } from "lodash";
import { Flex, Box, Text } from "rebass";
import styled from "styled-components";
const Image = styled.img`
  opacity: 1;
  display: block;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`;

const ItemText = (props) => {
  const { title } = props;
  const textTitle = get(title, "text", "");
  const titleAlign = get(title, "textAlign[1]", "left");
  const colorTitle = get(title, "color", "#000000");
  const fs = get(title, "fontSize[1]");
  const fontSizeTitle = fs ? { fontSize: `${fs}pt` } : {};
  const cp = get(title, "containerPadding[1]", "0px");
  const ls = get(title, "letterSpacing[1]", "1px");
  const lh = get(title, "lineHeight[1]", "100%");
  return (
    <div
      style={{
        padding: cp,
        lineHeight: lh,
        letterSpacing: ls,
        width: "100%",
        textAlign: titleAlign,
        color: colorTitle,
        ...fontSizeTitle,
      }}
      dangerouslySetInnerHTML={{ __html: textTitle }}
    />
  );
};

export default (props) => {
  const { values } = props;
  const { title, subtitle, icon, layoutRow, isShowDesktop } = values;
  const layoutParams = {
    flexDirection: get(layoutRow, "flexDirection[1]", "row"),
    justifyContent: get(layoutRow, "justifyContent[1]", "flex-start"),
    alignItems: get(layoutRow, "alignItems[1]", "flex-start"),
    flexWrap: get(layoutRow, "flexWrap[1]", "nowrap"),
  };

  const textTitle = get(title, "text", "");
  const textSubtitle = get(subtitle, "text", "");

  if (!isShowDesktop) {
    return (
      <Flex width={[1]} p={[2]}>
        <Text>Please Click here to edit item</Text>
      </Flex>
    );
  }
  return (
    <Flex width={[1]} style={{ ...layoutParams }}>
      {icon !== "" && (
        <Image
          src={icon}
          alt={"image"}
          style={{
            width: "60px",
            height: "auto",
          }}
        />
      )}
      <Box width={[1]}>
        {textTitle !== "" && <ItemText title={title} />}
        {textSubtitle !== "" && <ItemText title={subtitle} />}
      </Box>
    </Flex>
  );
};
