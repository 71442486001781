export default {
  registration: {
    title:
      "Thank you for your interests in BCX Studio. Please fill up the registration form and our team will contact you on the setup",
    field: {
      company_name: "Your Company",
      contact_name: "Your Name",
      mobile_number: "Mobile Number",
    },
  },
}
