// http://mcg.mbitson.com/#!?mcgpalette0=%2326a69a#%2F
export const bcx = {
  "50": "#e0f5fd",
  "100": "#b3e7fa",
  "200": "#80d7f7",
  "300": "#4dc7f4",
  "400": "#26bbf1",
  "500": "#00afef",
  "600": "#00a8ed",
  "700": "#009feb",
  "800": "#0096e8",
  "900": "#0086e4",
  A100: "#ffffff",
  A200: "#d8edff",
  A400: "#a5d5ff",
  A700: "#8bc9ff",
  contrastDefaultColor: "light",
  contrastDarkColors: ["50", "100", "200", "A100", "A200"],
  contrastLightColors: [
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
    "A400",
    "A700",
  ],
}

export const bcx1 = {
  "50": "#e6eaed",
  "100": "#c0cad3",
  "200": "#96a7b6",
  "300": "#6c8498",
  "400": "#4d6982",
  "500": "#2d4f6c",
  "600": "#284864",
  "700": "#223f59",
  "800": "#1c364f",
  "900": "#11263d",
  A100: "#79b5ff",
  A200: "#4699ff",
  A400: "#137cff",
  A700: "#006ff8",
  contrastDefaultColor: "light",
  contrastDarkColors: ["50", "100", "200", "A100", "A200"],
  contrastLightColors: [
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
    "A400",
    "A700",
  ],
}

export const bcx_secondary = {
  "50": "#fbedf4",
  "100": "#f6d3e4",
  "200": "#f0b6d2",
  "300": "#ea98c0",
  "400": "#e682b3",
  "500": "#e16ca5",
  "600": "#dd649d",
  "700": "#d95993",
  "800": "#d54f8a",
  "900": "#cd3d79",
  A100: "#ffffff",
  A200: "#ffe8f1",
  A400: "#ffb5d3",
  A700: "#ff9cc3",
  contrastDefaultColor: "light",
  contrastDarkColors: [
    "50",
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "A100",
    "A200",
    "A400",
    "A700",
  ],
  contrastLightColors: ["800", "900"],
}

export const bcx_secondary_orange = {
  "50": "#fef2e4",
  "100": "#fddfbc",
  "200": "#fbca8f",
  "300": "#f9b562",
  "400": "#f8a540",
  "500": "#f7951e",
  "600": "#f68d1a",
  "700": "#f58216",
  "800": "#f37812",
  "900": "#f1670a",
  A100: "#ffffff",
  A200: "#fff0e7",
  A400: "#ffcfb4",
  A700: "#ffbe9b",
  contrastDefaultColor: "light",
  contrastDarkColors: [
    "50",
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "A100",
    "A200",
    "A400",
    "A700",
  ],
  contrastLightColors: ["800", "900"],
}
