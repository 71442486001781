import React from "react";
import { Form as FormValue, Field } from "react-final-form";
import {
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { get } from "lodash";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Flex, Box } from "rebass";
import UploadField from "./UploadField";
import ContentFields from "./ContentFields";
import CategoryFields from "./CategoryFields";

export default (props) => {
  const {
    translate,
    onCancel,
    initialValues,
    state,
    onSaveRootMenu,
    onSaveSubMenu,
  } = props;
  const onSubmit = (values) => {
    const { iscreate } = state;
    if (iscreate === "sub") {
      // console.log("sub", values);
      const newParams = {
        ...values,
        cols: values.cols * 1,
        mobileCols: values.mobileCols * 1,
      };
      onSaveSubMenu(newParams);
    } else if (iscreate === "root") {
      const newParams = {
        ...values,
        cols: values.cols * 1,
        mobileCols: values.mobileCols * 1,
      };
      onSaveRootMenu(newParams);
    }
  };
  const onDumbSubmit = (v) => {
    console.log(v);
  };
  return (
    <Flex
      style={{ backgroundColor: "#FFFFFF" }}
      width={["600px"]}
      flexDirection={"column"}
    >
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        p={[2]}
      >
        <Typography variant='h6'>
          {translate("resources.layouts.menu.create_menu")}
        </Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </Flex>
      <Box width={[1]} p={[3]}>
        <FormValue
          onSubmit={onDumbSubmit}
          mutators={{
            updateItem: ([field, value], state, utils) => {
              utils.changeValue(state, field, () => value);
            },
          }}
          initialValues={initialValues}
          render={({
            form: {
              mutators: { updateItem },
            },
            values,
          }) => (
            <Box width={[1]} p={[2]}>
              <Box width={[1]} pb={[5]}>
                <UploadField
                  icon={values.icon}
                  updateField={(k, v) => updateItem(k, v)}
                />
                <Field name='href.type'>
                  {(props) => (
                    <FormControl>
                      <InputLabel id='type-menu-select-label' shrink={true}>
                        {translate("resources.layouts.menu.type")}
                      </InputLabel>
                      <Select
                        labelId='type-menu-select-label'
                        id='type-menu-select'
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        style={{ width: 200 }}
                      >
                        <MenuItem value={"Content"}>Content</MenuItem>
                        <MenuItem value={"Category"}>Category</MenuItem>
                        <MenuItem value={"Url"}>URL</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Field>
                {get(values, "href.type", "") &&
                  get(values, "href.type", "") === "Url" && (
                    <Field name={`href.value`}>
                      {(props) => (
                        <Box width={[1]} py={[2]}>
                          <TextField
                            label={translate("resources.layouts.menu.url")}
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Box>
                      )}
                    </Field>
                  )}
                {get(values, "href.type", "") &&
                  get(values, "href.type", "") === "Content" && (
                    <ContentFields
                      {...props}
                      values={values}
                      updateField={(k, v) => updateItem(k, v)}
                    />
                  )}
                {get(values, "href.type", "") &&
                  get(values, "href.type", "") === "Category" && (
                    <CategoryFields
                      {...props}
                      values={values}
                      updateField={(k, v) => updateItem(k, v)}
                    />
                  )}
                {get(values, "href.type", "") &&
                  get(values, "href.type", "") !== "" && (
                    <Field name={`title`}>
                      {(props) => (
                        <Box width={[1]} py={[2]}>
                          <TextField
                            label={translate("resources.layouts.menu.title")}
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Box>
                      )}
                    </Field>
                  )}
                <Field name={`newColumn`}>
                  {(props) => (
                    <Box width={[1]} py={[2]}>
                      <FormControlLabel
                        control={
                          <Switch
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            color='primary'
                          />
                        }
                        label={translate("resources.layouts.menu.newColumn")}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Box>
                  )}
                </Field>
                <Flex width={[1]} flexDirection={"row"} alignItems={"center"}>
                  <Field name={`cols`}>
                    {(props) => (
                      <Box width={[1]} py={[2]} pr={[2]}>
                        <TextField
                          label={translate("resources.layouts.menu.cols")}
                          name={props.input.name}
                          value={props.input.value}
                          type={"number"}
                          onChange={props.input.onChange}
                          style={{ width: 200 }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Box>
                    )}
                  </Field>
                  <Field name={`mobileCols`}>
                    {(props) => (
                      <Box width={[1]} py={[2]}>
                        <TextField
                          label={translate("resources.layouts.menu.mobileCols")}
                          name={props.input.name}
                          value={props.input.value}
                          type={"number"}
                          onChange={props.input.onChange}
                          style={{ width: 200 }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Box>
                    )}
                  </Field>
                </Flex>
              </Box>
              {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              <Flex
                width={[1]}
                flexDirection={"row"}
                py={[3]}
                sx={{
                  backgroundColor: "white",
                  "&>button": { marginRight: [2] },
                  position: "fixed",
                  bottom: "1px",
                }}
              >
                <Button type='button' onClick={() => onCancel()}>
                  {translate("resources.buttons.cancel")}
                </Button>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  type={"button"}
                  onClick={() => onSubmit(values)}
                >
                  {translate("resources.buttons.save")}
                </Button>
              </Flex>
            </Box>
          )}
        />
      </Box>
    </Flex>
  );
};
