import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Media from "./Media"

const Image = styled.img`
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`

const ResponsiveImage = (props) => {
  let srcMobile =
    props.srcMobile ||
    props.srcMobile ||
    props.src ||
    "https://via.placeholder.com/1028x1028?text=CMS"
  let srcDesktop =
    props.srcDesktop ||
    props.srcMobile ||
    props.src ||
    "https://via.placeholder.com/1028x1028?text=CMS"
  return (
    <div>
      <Media query="(max-width: 768px)">
        {(matches) =>
          matches ? (
            <Image src={srcMobile} alt={props.alt} />
          ) : (
            <Image src={srcDesktop} alt={props.alt} />
          )
        }
      </Media>
    </div>
  )
}

ResponsiveImage.propTypes = {
  src: PropTypes.string,
  srcMobile: PropTypes.string,
  srcDesktop: PropTypes.string,
  alt: PropTypes.string,
}
ResponsiveImage.defaultProps = {
  alt: "Image",
}

export default ResponsiveImage
