//Layout17

import { Layout17 } from "../../TemplateImageSonak";
export const template17A = {
  SFaarhxW: {
    id: "SFaarhxW",
    type: "Row6",
    props: {
      values: {
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: true,
            align: "middle",
            gutter: 20,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "8px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: true,
            align: "middle",
            gutter: 20,
            justify: "center",
            contentAlign: "center",
            contentJustify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "8px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                bgProps: {
                  show: false,
                },
                padding: "4px 4px 16px 4px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 1,
                },
                bgProps: {
                  show: false,
                },
                padding: "4px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 2,
                },
                bgProps: {
                  show: false,
                },
                padding: "4px 4px 16px 4px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 2,
                },
                bgProps: {
                  show: false,
                },
                padding: "4px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 3,
                },
                bgProps: {
                  show: false,
                },
                padding: "4px 4px 16px 4px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 3,
                },
                bgProps: {
                  show: false,
                },
                padding: "4px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 4,
                },
                bgProps: {
                  show: false,
                },
                padding: "4px 4px 16px 4px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 4,
                },
                bgProps: {
                  show: false,
                },
                padding: "4px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    layout: Layout17,
    childOf: "Row",
    children: ["ohYIv1RB", "pxj0nHp+", "PYHCBU3v", "ortLbfFS"],
    myContent: [
      {
        id: "FEwIk0ir",
        type: "RowIconText",
        props: {
          values: {
            icon:
              "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/iconrowtext-image-desktop-NM1.jpeg",
            type: "RowIconText",
            title: {
              text: "<p><strong>COMMITMENT</strong></p>",
              color: "rgba(0,0,0,1)",
              fontSize: [12, 14],
              textAlign: ["left", "left"],
              lineHeight: ["100%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["0px", "0px"],
            },
            parent: "Content",
            subtitle: {
              text:
                "<p>We are committed to delivering outstanding services to our customers.</p>",
              color: "rgba(0,0,0,1)",
              fontSize: [10, 10],
              textAlign: ["left", "left"],
              lineHeight: ["120%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["8px 0px 0px 0px", "8px 0px 0px 0px"],
            },
            layoutRow: {
              flexWrap: ["nowrap", "nowrap"],
              alignItems: ["flex-start", "flex-start"],
              flexDirection: ["row", "row"],
              justifyContent: ["flex-start", "flex-start"],
            },
            isShowMobile: true,
            isShowDesktop: true,
            backgroundColor: "rgba(255,255,255,0)",
          },
        },
        parent: {
          id: "pxj0nHp+",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "pxj0nHp+",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "HqGiT4Vv",
        type: "RowIconText",
        props: {
          values: {
            icon:
              "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/iconrowtext-image-desktop-XMH.jpeg",
            type: "RowIconText",
            title: {
              text: "<p><strong>INTEGRITY</strong></p>",
              color: "rgba(0,0,0,1)",
              fontSize: [12, 14],
              textAlign: ["left", "left"],
              lineHeight: ["100%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["0px", "0px"],
            },
            parent: "Content",
            subtitle: {
              text:
                "<p>We upload the highest standards of integrity in all of our actions.</p>",
              color: "rgba(0,0,0,1)",
              fontSize: [10, 10],
              textAlign: ["left", "left"],
              lineHeight: ["120%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["8px 0px 0px 0px", "8px 0px 0px 0px"],
            },
            layoutRow: {
              flexWrap: ["nowrap", "nowrap"],
              alignItems: ["flex-start", "flex-start"],
              flexDirection: ["row", "row"],
              justifyContent: ["flex-start", "flex-start"],
            },
            isShowMobile: true,
            isShowDesktop: true,
            backgroundColor: "rgba(255,255,255,0)",
          },
        },

        parent: {
          id: "ortLbfFS",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "ortLbfFS",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "Uq3e5oQn",
        type: "RowIconText",
        props: {
          values: {
            icon:
              "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/iconrowtext-image-desktop-ATE.jpeg",
            type: "RowIconText",
            title: {
              text: "<p><strong>INSPIRE</strong></p>",
              color: "rgba(0,0,0,1)",
              fontSize: [12, 14],
              textAlign: ["left", "left"],
              lineHeight: ["100%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["0px", "0px"],
            },
            parent: "Content",
            subtitle: {
              text:
                "<p>To inspire customers in taking on sporting challenges with the right gear.</p>",
              color: "rgba(0,0,0,1)",
              fontSize: [10, 10],
              textAlign: ["left", "left"],
              lineHeight: ["120%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["8px 0px 0px 0px", "8px 0px 0px 0px"],
            },
            layoutRow: {
              flexWrap: ["nowrap", "nowrap"],
              alignItems: ["flex-start", "flex-start"],
              flexDirection: ["row", "row"],
              justifyContent: ["flex-start", "flex-start"],
            },
            isShowMobile: true,
            isShowDesktop: true,
            backgroundColor: "rgba(255,255,255,0)",
          },
        },
        parent: {
          id: "ohYIv1RB",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "ohYIv1RB",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "ytsZI0vQ",
        type: "RowIconText",
        props: {
          values: {
            icon:
              "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/iconrowtext-image-desktop-O52.jpeg",
            type: "RowIconText",
            title: {
              text: "<p><strong>QUALITY ASSURANCE</strong></p>",
              color: "rgba(0,0,0,1)",
              fontSize: [12, 14],
              textAlign: ["left", "left"],
              lineHeight: ["100%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["0px", "0px"],
            },
            parent: "Content",
            subtitle: {
              text:
                "<p>We take pride in providing high value products that we stand behind.</p>",
              color: "rgba(0,0,0,1)",
              fontSize: [10, 10],
              textAlign: ["left", "left"],
              lineHeight: ["120%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["8px 0px 0px 0px", "8px 0px 0px 0px"],
            },
            layoutRow: {
              flexWrap: ["nowrap", "nowrap"],
              alignItems: ["flex-start", "flex-start"],
              flexDirection: ["row", "row"],
              justifyContent: ["flex-start", "flex-start"],
            },
            isShowMobile: true,
            isShowDesktop: true,
            backgroundColor: "rgba(255,255,255,0)",
          },
        },
        parent: {
          id: "PYHCBU3v",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "PYHCBU3v",
          idx: 0,
          index: 1,
        },
      },
    ],
  },
};
