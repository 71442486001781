export default {
  layouts: {
    name: "Layouts",
    back: "Back to Layouts",
    title: "Fields",
    subtitle: "Configure which Webpage to use as home page in different dates",
    tabs: {
      header: "Header",
      homepage_content: "Homepage Content",
      footer: "Footer",
      theme: "Theme",
    },
    fields: {
      name: "Name",
      domain: "Domain",
      range_date: "Range Date",
      layout: "Layout",
      active: "Active",
      webpage: "Webpage",
      from: "From",
      to: "To",
      country: "Country",
      default_language: "Default Language",
      supported_language: "Supported Language",
      currency: "Currency",
      currency_symbol: "Currency Symbol",
      thousand_separator: "Thousand Separator",
      decimal_scale: "Decimal Scale",
      decimal_separator: "Decimal Separator",
      date_format: "Date Format",
      time_zone: "Timezone",
      default_layout: "Default Layout",
      start_date: "Start Date",
      end_date: "End Date",
      themes_colors: "Theme Colors",
      urlGoogleFont: "Url Google Font",
      bodyFontFamily: "Body Font-Family",
      colors: {
        text: "Text",
        background: "Background",
        primary: "Primary",
        secondary: "Secondary",
        highlight: "highlight",
        accent: "Accent",
        accent1: "Accent1",
        accent2: "Accent2",
        muted: "Muted",
        error: "Error",
      },
      header: {
        height: "Header Height",
        isFixedMobile: "Fixed Header Mobile",
      },
      banner: {
        title: "Banners Setting",
        login: {
          title: "Login Page",
          url: "Url Banner",
        },
      },
    },
    note: {
      layout: "",
    },
    menu: {
      create_menu: "Create Menu",
      edit_menu: "Edit Menu",
      addRootMenu: "Add Root Menu",
      addSubMenu: "Add Sub Menu",
      icon: "Icon",
      title: "Title",
      url: "Url",
      type: "Type",
      sort_order: "Sort Order",
      content: "Content",
      category: "Category",
      value: "Value",
      cols: "Column",
      newColumn: "New Column",
      mobileCols: "Mobile Column",
    },
    messages: {
      header_message_duplicate: "Duplicate Website Layout",
      content_message_duplicate:
        "To duplicate this website layout, please enter your name layout here.",
      toDate: "Must be after from ",
      remote_item: "Do you want to remove menu?",
      checkYourDate: "Please check yout 'TO' Date",
      urlGoogleFont:
        "example: https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Rajdhani:wght@300;400;500;600;700&display=swap",
      bodyFontFamily: "add font-family, example : Manrope, Rajdhani, etc ",
    },
  },
};
