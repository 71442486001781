import React from "react";
import { get } from "lodash";
import { useQueryWithStore } from "react-admin";
import { connect } from "../../../core";
import { MenuNavBar } from "../../../libs/menu";
const MenuView = (props) => {
  const { id, layoutState, widthScreen, websiteId } = props;

  const { containerPadding } = layoutState.getItemJS(id).props.values;
  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "websiteLayouts",
    payload: {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
      filter: { website_id: get(props, "websiteId", 0) },
    },
  });
  if (loading) {
    return <div />;
  }

  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        width: "100%",
        display: "flex",
        flex: 1,
      }}
    >
      <div style={{ padding: containerPadding, width: "100%" }}>
        <MenuNavBar
          values={layoutState.getItemJS(id).props.values}
          widthScreen={widthScreen}
          websiteId={websiteId}
          listMenu={data}
        />
      </div>
    </div>
  );
};

export default connect("layoutState", "widthScreen", "websiteId")(MenuView);
