import MuiTab from "@material-ui/core/Tab"
import MuiTabs from "@material-ui/core/Tabs"
import { withStyles } from "@material-ui/core/styles"
import styled from "styled-components"
import { pink, blueGrey } from "@material-ui/core/colors"

import MuiExpansionPanel from "@material-ui/core/Accordion"
import MuiExpansionPanelSummary from "@material-ui/core/AccordionSummary"
import MuiExpansionPanelDetails from "@material-ui/core/AccordionDetails"
const pinkA200 = pink["A200"]
const blueGrey600 = blueGrey[600]

const getStylesContent = () => {
  // const width = "300px";
  return {
    sidebarOpen: {
      width: "100%",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      display: "flex",
      transition: "max-height 0.35s ease-in",
      zIndex: 1200,
      paddingTop: 4,
      backgroundColor: "#f5f5f5",
    },
    sidebarOpenRow: {
      width: "100%",
      flexDirection: "column",
      display: "flex",
      transition: "max-height 0.35s ease-in",
      zIndex: 1200,
      backgroundColor: "#f5f5f5",
    },
    sidebarClosed: {
      maxHeight: 0,
      width: 0,
      overFlow: "hidden",
      display: "none",
      transition: "max-height 0.35s ease-out",
      zIndex: 1200,
    },
  }
}

const getHeightStyle = () => {
  return {
    sidebarOpen: {
      maxHeight: 555,
      width: "100%",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      overflowY: "scroll",
      display: "flex",
      transition: " max-height 0.35s ease-in",
      zIndex: 402,
      backgroundColor: "#f5f5f5",
    },
    sidebarClosed: {
      maxHeight: 0,
      width: 0,
      overFlow: "hidden",
      display: "none",
      transition: "max-height 0.35s ease-out",
      zIndex: 402,
    },
  }
}

const DivContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 340px;
  z-index: 402;
`
const ContainerCatalog = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 1999;
  top: 140px;
  padding-bottom: 10px;
  width: inherit;
  position: fixed;
`
const DivButton = styled.div`
  z-index: 402;
  margin-left: 0px;
  height: 48px;
  background-color: ${pinkA200};
`
const DragButton = styled.div`
  z-index: 402;
  margin-left: 0px;
  height: 48px;
  background-color: ${blueGrey600};
`
const BoxTabs = styled.div`
  background-color: #ffffff;
  height: 100%;
`

const DivContainer1 = styled.div`
  display: flex;
  flex-direction: row;
`
const DivButton1 = styled.div`
  z-index: 400;
  margin-left: -48px;
  height: 48px;
  background-color: ${pinkA200};
`

const Tab = withStyles((theme) => ({
  root: {
    padding: 0,
    width: 100,
    minWidth: 72,
    flexDirection: "column",
    backgroundColor: "#f5f5f5",
    fontSize: 11,
  },
  selected: {
    backgroundColor: "#f5f5f5",

    fontSize: 11,
  },
}))(MuiTab)

const Tabs = withStyles((theme) => ({
  root: {
    paddingRight: 0,
    width: "100%",
    backgroundColor: "#f5f5f5",
  },
  fixed: {
    width: "100%",
  },
}))(MuiTabs)
const ExpansionPanel = withStyles({
  root: {
    width: "100%",
    border: "0px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    minHeight: 48,
    "&$expanded": {
      minHeight: 48,
    },
  },
  content: {
    margin: "12px 0px",
    fontSize: "bold",
    "&$expanded": {
      margin: "12px 0px",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    width: "100%",
    overflowY: "auto",
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails)
export {
  getStylesContent,
  getHeightStyle,
  BoxTabs,
  DivButton,
  DivContainer,
  DivButton1,
  DivContainer1,
  ContainerCatalog,
  DragButton,
  Tab,
  Tabs,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
}
