import React, { Fragment } from "react"
import { Flex, Box, Text } from "rebass"
import { get } from "lodash"
import { Field } from "react-final-form"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Delete"
import Paper from "@material-ui/core/Paper"
import { ArrayChipInput } from "../"
import { dataOperator } from "./utils"
import CartFields from "./CartFields"

const CriteriaFields = props => {
  const { items, index, fields, classes, ...rest } = props
  const { formData, translate, updateField } = rest
  const collections = get(formData, `${items}.collections`, [])
  const products = get(formData, `${items}.products`, [])
  const categories = get(formData, `${items}.categories`, [])
  const type = get(formData, `${items}.type`, "")
  return (
    <Paper className={classes.paper} key={index}>
      <Box width={[1]} p={[2]}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box width={[1]}>
            <Field
              key={index}
              label={translate("resources.promotions.fields.collection")}
              name={`${items}.operator`}
            >
              {props => (
                <Select
                  name={props.input.name}
                  value={props.input.value}
                  onChange={props.input.onChange}
                  style={{ width: 256 }}
                >
                  {dataOperator.map((item, indexx) => {
                    return (
                      <MenuItem value={item.id} key={indexx}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}
            </Field>
          </Box>
          <Box>
            <IconButton onClick={() => fields.remove(index)}>
              <CloseIcon style={{ color: "#FF0000" }} />
            </IconButton>
          </Box>
        </Flex>
        <Box width={[1]} pt={[2]}>
          {type === "Product" && (
            <Fragment>
              <Text fontSize={[0]} pb={[2]}>
                {translate("resources.promotions.fields.products")}
              </Text>
              <Field
                label={translate("resources.promotions.fields.products")}
                name={`${items}.products`}
                resource="products"
                component={ArrayChipInput}
                dataOptions={products}
                optionText="name"
                updateField={updateField}
              />
            </Fragment>
          )}
          {type === "Collection" && (
            <Fragment>
              <Text fontSize={[0]} pb={[2]}>
                {translate("resources.promotions.fields.collections")}
              </Text>
              <Field
                label={translate("resources.promotions.fields.collections")}
                name={`${items}.collections`}
                resource="collections"
                component={ArrayChipInput}
                dataOptions={collections}
                optionText="name"
                updateField={updateField}
              />
            </Fragment>
          )}
          {type === "Category" && (
            <Fragment>
              <Text fontSize={[0]} pb={[2]}>
                {translate("resources.promotions.fields.categories")}
              </Text>
              <Field
                label={translate("resources.promotions.fields.categories")}
                name={`${items}.categories`}
                resource="categories"
                component={ArrayChipInput}
                dataOptions={categories}
                optionText="name"
                updateField={updateField}
              />
            </Fragment>
          )}
          {type === "Cart" && <CartFields {...props} />}
        </Box>
      </Box>
    </Paper>
  )
}
export default CriteriaFields
