import React, { Fragment } from "react";
import { Flex, Box, Text } from "rebass";
import {
  FormDataConsumer,
  useTranslate,
  TextInput,
  required,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from "react-admin";
import DateInputFields from "./DateInputFields";
// import { Field } from "react-final-form";
import { get } from "lodash";
import { RaBooleanInput } from "../../../components";
import { ImageStorePromotions } from "../../../components";
import DescriptionTerms from "./DescriptionTerms";
const FormFields = (props) => {
  const translate = useTranslate();
  const onUpdatePhotos = (value) => {
    props.updateField("images", value);
  };
  return (
    <Flex flexDirection={"column"}>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const imagesField = get(formData, "images", []);
          console.log("ImageFields", imagesField);
          const myName = get(formData, "name", "");
          return (
            <Fragment>
              <RaBooleanInput source='active' id={"active"} />
              <Box width={[1]} pb={[2]}>
                <TextInput
                  source='name'
                  label='resources.stores.fields.name'
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  validate={[required()]}
                />
              </Box>
              <Flex>
                <DateInputFields {...props} />
              </Flex>
              <Box>
                <ReferenceArrayInput
                  label='resources.stores.name'
                  reference='stores'
                  source='stores'
                  resource={"stores"}
                  perPage={80}
                  sort={{ field: "name", order: "ASC" }}
                  filterToQuery={(searchText) => ({ search: searchText })}
                  fullWidth={true}
                  filter={{ active: true }}
                >
                  <AutocompleteArrayInput
                    optionText={(record) => {
                      return `${get(record, "name", "")}`;
                    }}
                    optionValue={"id"}
                  />
                </ReferenceArrayInput>
              </Box>
              <Box>
                <Text fontWeight={"bold"} fontSize={[0]}>
                  {translate("resources.promotions.fields.images")}
                </Text>
                <ImageStorePromotions
                  dataImage={{ photos: imagesField }}
                  nameProduct={myName}
                  onUpdatePhotos={(value) => onUpdatePhotos(value)}
                />
              </Box>
              <DescriptionTerms
                {...props}
                translate={translate}
                formData={formData}
              />
            </Fragment>
          );
        }}
      </FormDataConsumer>
    </Flex>
  );
};

export default FormFields;
