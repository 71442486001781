import styled from "styled-components"
import { lightBlue, grey } from "@material-ui/core/colors"
export const DivCointainer = styled.div`
  position: fixed;
  width: 340px;
  height: 90vh;
  zindex: 2001;
  background-color: ${grey[100]};
  overflow-y: scroll;
  padding-bottom: 0px;
  border: 1px solid ${grey[100]};
`

const menuWidth = "500px"
export const MenuWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex: 1;
  z-index: 300;
  height: 100%;
  position: absolute;
  right: 0;
  box-sizing: border-box;
  overflow: hidden;
  max-width: ${menuWidth};
`

export const Menu = styled.div`
  min-width: ${menuWidth};
  order: ${props => props.order};
`

export const DivHeaderActions = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: row;
  justify-content: flex-end;
  background-color: ${lightBlue[700]};
`

export const DivHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
  justify-content: space-betbween;
  background-color: ${lightBlue[700]};
`
