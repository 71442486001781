import React from "react";
import { get } from "lodash";
import { Flex, Box, Text } from "rebass";
import ReactMegaMenu from "react-mega-menu";
const BOX_SHADOW_COLOR = `rgba(66, 66, 66, 0.2) 0px 3px 3px -2px, rgba(66, 66, 66, 0.14) 0px 3px 4px 0px, rgba(66, 66, 66, 0.12) 0px 1px 8px 0px`;
const values = ["LEFT", "RIGHT"];

export const MenusList = (props) => {
  const { newList } = props;
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: `${values[1] !== "LEFT" ? "row" : "row-reverse"}`,
      }}
    >
      <ReactMegaMenu
        styleConfig={{
          menuProps: {
            style: {
              height: "inherit",
              width: "200px",
              padding: "0px",
              backgroundColor: "transparent",
            },
          },
          contentProps: {
            style: {
              width: "100%",
              padding: "2px",
              minHeight: 300,
              height: "inherit",
            },
          },
          menuItemProps: {
            style: {
              padding: "10px",
              width: 200,
            },
          },
          menuItemSelectedProps: {
            style: {
              border: "2px solid rgba(0,0,0,0.4)",
              padding: "10px",
              backgroundColor: "rgba(0,0,0,0.2)",
            },
          },
          containerProps: {
            style: {
              height: "auto",
              width: "100%",
              backgroundColor: "#FFFFFF",
              boxShadow: BOX_SHADOW_COLOR,
            },
          },
        }}
        direction={values[1]}
        data={newList}
      />
    </div>
  );
};

export const MenuItem = (props) => {
  // console.log(props);
  const { item, category } = props;
  if (item.type === "slug") {
    return <span />;
  }
  const categoryChildren = get(category, "children", []);
  return (
    <Flex width={[1]} flexDirection={"row"} flexWrap={"wrap"}>
      {categoryChildren.map((itemCategory, indexCategory) => {
        const subMenu = get(itemCategory, "children", []);
        return (
          <Box key={indexCategory} p={[2]}>
            <Box width={[1]} pb={[1]}>
              <Text fontWeight={"bold"} color={"#595959"}>
                {itemCategory.title}
              </Text>
            </Box>
            {subMenu.map((sb) => {
              return (
                <Text color={"#595959"} py={[1]} key={sb.id}>
                  {get(sb, "category.name", "")}
                </Text>
              );
            })}
          </Box>
        );
      })}
    </Flex>
  );
};
