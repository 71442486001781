import React from "react";
import { Flex, Box, Text } from "rebass";
import { grey } from "@material-ui/core/colors";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { useTranslate } from "react-admin";
const EmptyList = (props) => {
  const {
    message = "resources.errors.no_have_items",
    bg = "transparent",
  } = props;
  const translate = useTranslate();

  return (
    <Box width={[1]} p={[2]}>
      <Flex
        width={[1]}
        justifyContent="center"
        alignItems={"center"}
        flexDirection={"column"}
        bg={bg}
        sx={{
          border: "1px solid #C3c3c3",
          height: 300,
        }}
      >
        <InsertDriveFileOutlinedIcon
          style={{ fontSize: "60pt", color: grey[300] }}
        />

        <Text p={[3]} textAlign={"center"}>
          {translate(message)}
        </Text>
      </Flex>
    </Box>
  );
};

export default EmptyList;
