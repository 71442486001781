import React from "react";
//, { Fragment }
import { Paper as MuiPaper } from "@material-ui/core";
import { Flex, Text } from "rebass";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const Paper = withStyles((theme) => ({
  root: {
    width: "100%",
  },
}))(MuiPaper);

const PaperCard = (props) => {
  const {
    children,
    title = "",
    labelButton = "",
    onClick,
    showButton = false,
    elevation = 1,
    bgHeader = "transparent",
    propsHeader = { p: [3] },
    isCustomButton = false,
    childrenDialog = <div />,
  } = props;
  return (
    <Paper elevation={elevation} component={"div"}>
      <Flex
        {...propsHeader}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          backgroundColor: bgHeader,
        }}
      >
        <Text fontWeight="bold">{title}</Text>
        {showButton && (
          <Flex>
            {isCustomButton ? (
              childrenDialog
            ) : (
              <Button onClick={onClick} color={"primary"}>
                {labelButton}
              </Button>
            )}
          </Flex>
        )}
      </Flex>
      <Flex width={[1]} flexDirection={"column"} p={2}>
        {children}
      </Flex>
    </Paper>
  );
};

export default PaperCard;
