import React from "react"
import CircularSlider from "@fseehawer/react-circular-slider"

const AngleDegre = (props) => {
  const { degre, onSetValue } = props

  return (
    <CircularSlider
      width={100}
      min={0}
      max={360}
      onChange={(v) => onSetValue("degre", v)}
      progressSize={13}
      trackSize={13}
      dataIndex={degre}
      label={""}
      knobPosition="right"
      appendToValue="°"
      valueFontSize="1.3rem"
      verticalOffset={"0.2rem"}
    />
  )
}

export default AngleDegre
