import React, { useState, Fragment } from "react";
import { Flex } from "rebass";
import { useTranslate } from "react-admin";
import { Dialog, Button } from "@material-ui/core";
import { useNotify } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  DialogTitle,
  DialogActions,
  DialogContent,
} from "../../../../components";
import EditFormFields from "./EditFormFields";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { RestApiCRUDString, RestApi } from "../../../../restClient";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EditMemo = (props) => {
  const { record, onRefreshList, show, setShow, recordMemo, setMemo } = props;

  const notify = useNotify();
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState({
    refund: false,
    close: false,
    void: false,
  });
  const [snackBarMessage, setSnackBarMessage] = useState({
    open: false,
    message: "",
    variant: "error",
  });

  const onHandleSnackBar = (k, v) => {
    setSnackBarMessage({
      ...snackBarMessage,
      [k]: v,
    });
  };
  const onSave = () => {
    if (recordMemo.reason === "") {
      onHandleSnackBar("message", translate("resources.errors.reason_empty"));
      onHandleSnackBar("variant", "error");
      onHandleSnackBar("open", true);
    } else if (recordMemo.amount_paid === 0) {
      onHandleSnackBar("message", translate("resources.errors.amount_paid"));
      onHandleSnackBar("variant", "error");
      onHandleSnackBar("open", true);
    } else {
      setLoading(true);
      const params = {
        ...recordMemo,
        total:
          recordMemo.amount_paid -
          (recordMemo.shipping_fee - recordMemo.admin_fee),
      };

      RestApiCRUDString(
        `/orders/${record.id}/creditMemos/${recordMemo.id}`,
        "PUT",
        params
      )
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          setLoading(false);

          if (res) {
            if (res.id) {
              setShow(false);
              onRefreshList();
              notify("ra.notification.updated", "success", {
                smart_count: 1,
              });
            } else {
              //   console.log("create memo", res);
              setSnackBarMessage({
                message: res.message,
                open: true,
                variant: "error",
              });
            }
          } else {
            onHandleSnackBar(
              "message",
              translate("resources.errors.please_try_again")
            );
            onHandleSnackBar("variant", "error");
            onHandleSnackBar("open", true);
          }
        })
        .catch((e) => {
          // console.log(e);
          setLoading(false);
          setShow(false);
          notify("resources.errors.please_try_again", "warning", {
            smart_count: 1,
          });
        });
    }
  };

  const onSubmit = (key) => {
    setLoader({
      refund: key === "refund",
      close: key === "close",
      void: key === "void",
    });
    RestApi(`/orders/${record.id}/creditMemos/${recordMemo.id}/${key}`)
      .then((res) => {
        setLoader({
          refund: false,
          close: false,
          void: false,
        });
        setShow(false);
        onRefreshList();
        notify("ra.notification.updated", "success", {
          smart_count: 1,
        });
      })
      .catch((e) => {
        setLoader({
          refund: false,
          close: false,
          void: false,
        });
        setShow(false);
        notify("resources.errors.please_try_again", "warning", {
          smart_count: 1,
        });

        // console.log(e);
      });
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Dialog
        onClose={() => setShow(false)}
        aria-labelledby={`customized-dialog-title-${recordMemo.id}`}
        open={show}
        scroll={"paper"}
      >
        <DialogTitle
          id={`customized-dialog-title-${recordMemo.id}`}
          onClose={() => setShow(false)}
        >
          {translate("resources.orders.labels.credit_memo")} {recordMemo.id}
        </DialogTitle>
        <DialogContent dividers style={{ minWidth: 600 }}>
          <EditFormFields
            {...props}
            state={recordMemo}
            setState={setMemo}
            translate={translate}
          />
        </DialogContent>
        <DialogActions>
          <Flex width={[1]} flexDirection={"row"}>
            {recordMemo && recordMemo.status === "New" && (
              <Button
                onClick={() => onSave()}
                color={"primary"}
                variant={"contained"}
                style={{ borderRadius: 16, marginRight: 16 }}
              >
                <Flex
                  width={[1]}
                  flexDirection={"row"}
                  justifyContent={"center"}
                >
                  {loading ? (
                    <CircularProgress size={24} color={"secondary"} />
                  ) : (
                    translate("resources.buttons.save")
                  )}
                </Flex>
              </Button>
            )}
            {recordMemo && recordMemo.status === "New" && (
              <Button
                onClick={() => onSubmit("refund")}
                color={"primary"}
                variant={"contained"}
                style={{ borderRadius: 16, marginRight: 16 }}
              >
                <Flex
                  width={[1]}
                  flexDirection={"row"}
                  justifyContent={"center"}
                >
                  {loader.refund ? (
                    <CircularProgress size={24} color={"secondary"} />
                  ) : (
                    translate("resources.buttons.submit_for_refund")
                  )}
                </Flex>
              </Button>
            )}
            {recordMemo && recordMemo.status === "Pending Refund" && (
              <Fragment>
                <Button
                  onClick={() => onSubmit("close")}
                  color={"primary"}
                  variant={"contained"}
                  style={{ borderRadius: 16, marginRight: 16 }}
                >
                  <Flex
                    width={[1]}
                    flexDirection={"row"}
                    justifyContent={"center"}
                  >
                    {loader.close ? (
                      <CircularProgress size={24} color={"secondary"} />
                    ) : (
                      translate("resources.buttons.closed")
                    )}
                  </Flex>
                </Button>
                <Button
                  onClick={() => onSubmit("void")}
                  color={"primary"}
                  variant={"contained"}
                  style={{
                    borderRadius: 16,
                    marginRight: 16,
                    backgroundColor: "#FF9900",
                  }}
                >
                  <Flex
                    width={[1]}
                    flexDirection={"row"}
                    justifyContent={"center"}
                  >
                    {loader.close ? (
                      <CircularProgress size={24} color={"secondary"} />
                    ) : (
                      translate("resources.buttons.void")
                    )}
                  </Flex>
                </Button>
              </Fragment>
            )}
          </Flex>
        </DialogActions>
        <Snackbar
          open={snackBarMessage.open}
          autoHideDuration={6000}
          onClose={() => onHandleSnackBar("open", false)}
        >
          <Alert
            onClose={() => onHandleSnackBar("open", false)}
            severity={snackBarMessage.variant}
          >
            {snackBarMessage.message}
          </Alert>
        </Snackbar>
      </Dialog>
    </Flex>
  );
};

export default EditMemo;
