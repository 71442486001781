import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SelectInputFields = (props) => {
  const classes = useStyles();
  const {
    message,
    onChange,
    value,
    list,
    title,
    id,
    isError = true,
    label = "name",
  } = props;
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`select-label-field-${id}`} shrink={true}>
        {title}
      </InputLabel>
      <Select
        labelId={`select-label-field-${id}`}
        id={`select-field-${id}`}
        value={value}
        onChange={onChange}
        style={{ width: 256 }}
      >
        <MenuItem value={""}>{""}</MenuItem>
        {list.map((item) => (
          <MenuItem value={item.id} key={item.id}>
            {item[label]}
          </MenuItem>
        ))}
      </Select>
      {message && <FormHelperText error={isError}>{message}</FormHelperText>}
    </FormControl>
  );
};
export default SelectInputFields;
