import React from "react"
import PropTypes from "prop-types"
import Layout from "./dnd"

const Body = (props) => {
  return <Layout {...props} type="Body" />
}
Body.propTypes = {
  childOf: PropTypes.string,
}

Body.defaultProps = {
  childOf: "root",
  values: {
    backgroundColor: "#e7e7e7",
    isGradient: false,
    gradient: {
      bg1: "rgb(34,193,195)",
      bg2: "rgba(253,187,45,1)",
      type: "linear-gradient",
      degre: 160,
      linear: [0, 100],
    },
    backgroundImage: {
      url: "",
      urlMobile: "",
      desktop: {
        width: 0,
        height: 0,
      },
      mobile: {
        width: 0,
        height: 0,
      },
    },
    isBgImage: false,
    fullheight: true,
    width: {
      type: "%",
      contentWidth: 100,
    },
    contentWidth: "100%",
  },
}

export default Body
