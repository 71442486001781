export default {
  menus: {
    accounts: "Accounts",
    catalogues: "Catalogues",
    clients: "Clients",
    members: "Members",
    merchants: "Merchants",
    admin: "Admin",
    registrations: "Registrations",
    provisions: "Provisions",
    users: "Users",
    dashboard: "Dashboard",
    programmes: "Programmes",
    orders: "Orders",
    list: "List",
    returns: "Returns",
    products: "Products",
    new_products: "New Products",
    price_book: "Price Book",
    categories: "Categories",
    collections: "Collections",
    attributes: "Attributes",
    marketing: "Marketing",
    promotions: "Promotions",
    banners: "Banners",
    content: "Content",
    pages: "Pages",
    customers: "Customers",
    sites: "Sites",
    settings: "Settings",
    variants: "Variants",
    faq: "FAQ",
    reviews: "Reviews",
    inventory: "Inventory",
    stores: "Stores",
    topSellings: "Top Sellings",
  },
};
