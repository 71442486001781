import React from "react"
import PropTypes from "prop-types"
import shouldUpdate from "recompose/shouldUpdate"
import { makeStyles } from "@material-ui/core"
import ImageEye from "@material-ui/icons/RemoveRedEye"
import { Link } from "react-router-dom"
import { linkToRecord } from "react-admin"

import ButtonIconOnly from "./ButtonIconOnly"

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation()

const useStyles = makeStyles(theme => ({
  root: {
    width: 14,
    height: 14,
  },
  smallIcon: {
    fontSize: 14,
  },
}))

const ShowButton = ({
  basePath = "",
  label = "ra.action.show",
  classes: classesOverride,
  record = {},
  icon = <ImageEye />,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverride })
  return (
    <ButtonIconOnly
      classes={classes}
      component={Link}
      to={`${linkToRecord(basePath, record.id)}/show`}
      label={label}
      onClick={stopPropagation}
      {...rest}
    >
      {React.cloneElement(icon, {
        className: classes[`smallIcon`],
      })}
    </ButtonIconOnly>
  )
}

ShowButton.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  icon: PropTypes.element,
}

const enhance = shouldUpdate(
  (props, nextProps) =>
    props.translate !== nextProps.translate ||
    (props.record &&
      nextProps.record &&
      props.record.id !== nextProps.record.id) ||
    props.basePath !== nextProps.basePath ||
    (props.record == null && nextProps.record != null)
)

export default enhance(ShowButton)
