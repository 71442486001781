export const bgPropertiesDefault = {
  size: "cover",
  position: "center center",
  repeat: "no-repeat",
};

export const defaultItemProps = {
  backgroundColorParent: "rgba(255,255,255,0)",
  width: {
    type: "%",
    contentWidth: 100,
  },
  alignment: {
    vertical: "center",
    horizontal: "center",
  },
};

export const defaultContentCarousel = {
  type: "ContentCarousel",
  props: {
    values: {
      parent: "Row",
      type: "ContentCarousel",
      className: "col-xs-12",
      column: 1,
      backgroundColorParent: "rgba(255,255,255,0)",
      backgroundColor: "rgba(255,255,255,0)",
      backgroundImage: {
        url: "",
        urlMobile: "",
        desktop: {
          width: 0,
          height: 0,
        },
        mobile: {
          width: 0,
          height: 0,
        },
      },
      bgProps: {
        size: "cover",
        position: "center",
        repeat: "no-repeat",
        ratio: 1.2,
      },
      isBackgroundImage: false,
      padding: "0px",
      width: {
        type: "%",
        contentWidth: 100,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
  },
  children: [],
  childOf: "Row",
};

/**layoutRow: {
    flexDirection: ["column", "row"],
    justifyContent: ["flex-start", "flex-start"],
    alignItems: ["flex-start", "flex-start"],
  }, */

export const defaultValueRow1 = {
  name: "Row1",
  parent: "Body",
  type: "Row",
  desktop: {
    column: 1,
    show: true,
    padding: "0px",
    backgroundColor: "rgba(255,255,255,0)",
    width: "100%",
    height: "100%",
    layout: {
      align: "middle",
      gutter: 0,
      justify: "center",
      wrap: false,
      contentAlign: "center",
      contentJustify: "center",
    },
    bgProps: {
      show: false,
      content: {
        url: "",
        size: "cover",
        position: "center",
        repeat: "no-repeat",
        ratio: 1.2,
      },
    },
    gradient: {
      show: false,
      content: {
        bg1: "rgb(34,193,195)",
        bg2: "rgba(253,187,45,1)",
        type: "linear-gradient",
        degre: 160,
        linear: [0, 100],
      },
    },
  },
  mobile: {
    column: 1,
    show: false,
    padding: "0px",
    backgroundColor: "rgba(255,255,255,0)",
    width: "100%",
    height: "100%",
    layout: {
      align: "middle",
      gutter: 0,
      justify: "center",
      wrap: false,
    },
    bgProps: {
      show: false,
      content: {
        url: "",
        size: "cover",
        position: "center",
        repeat: "no-repeat",
        ratio: 1.2,
      },
    },
    gradient: {
      show: false,
      content: {
        bg1: "rgb(34,193,195)",
        bg2: "rgba(253,187,45,1)",
        type: "linear-gradient",
        degre: 160,
        linear: [0, 100],
      },
    },
  },
};
export const defaultValueContent1 = [
  {
    type: "Content",
    props: {
      values: {
        parent: "Row",
        type: "Content",
        className: "col-xs-12",
        column: 1,
        layoutRow: {
          flexDirection: ["column", "column"],
          justifyContent: ["flex-start", "flex-start"],
          alignItems: ["flex-start", "flex-start"],
        },
        desktop: {
          show: true,
          padding: "0px",
          backgroundColor: "rgba(255,255,255,0)",
          width: "100%",
          height: "100%",
          bgProps: {
            show: false,
            content: {
              url: "",
              size: "cover",
              position: "center",
              repeat: "no-repeat",
              ratio: 1.2,
            },
          },
          gradient: {
            show: false,
            content: {
              bg1: "rgb(34,193,195)",
              bg2: "rgba(253,187,45,1)",
              type: "linear-gradient",
              degre: 160,
              linear: [0, 100],
            },
          },
        },
        mobile: {
          show: true,
          padding: "0px",
          backgroundColor: "rgba(255,255,255,0)",
          width: "100%",
          height: "100%",
          bgProps: {
            show: false,
            content: {
              url: "",
              size: "cover",
              position: "center",
              repeat: "no-repeat",
              ratio: 1.2,
            },
          },
          gradient: {
            show: false,
            content: {
              bg1: "rgb(34,193,195)",
              bg2: "rgba(253,187,45,1)",
              type: "linear-gradient",
              degre: 160,
              linear: [0, 100],
            },
          },
        },
      },
    },
    children: [],
    childOf: "Row",
  },
];
