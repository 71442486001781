import { Layout25 } from "../../TemplateImageSonak";
export const template25A = {
  uE2D9FKt: {
    id: "uE2D9FKt",
    type: "Row1",
    props: {
      values: {
        name: "Row1",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "16px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "1080px",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
            contentAlign: "center",
            contentJustify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "16px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255, 255, 255, 1)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    layout: Layout25,
    childOf: "Row",
    children: ["aCa/wSsK"],
    content: {
      "3M/vyiRm": {
        id: "3M/vyiRm",
        type: "ListSlideView",
        props: {
          values: {
            type: "ListSlideView",
            title: "Recommended Products",
            mobile: {
              show: true,
              header: {
                show: true,
                title: "Shop by Sports",
                padding: "16px 10px 10px 10px",
                fontSize: 12,
                showViewAll: true,
              },
              setting: {
                dots: false,
                rows: 0,
                speed: 200,
                arrows: false,
                autoplay: false,
                infinite: false,
                slidesPerRow: 0,
                slidesToShow: 2,
                autoplaySpeed: false,
                adaptiveHeight: true,
                slidesToScroll: 2,
              },
              listProps: {
                padding: "0px 8px 0px 8px",
                fontSize: 12,
                urlViewAll: "",
                titlePadding: "4px 0px 4px 0px",
                showViewAllBottom: false,
              },
              colorProps: {
                color: "rgba(0,0,0,1)",
                hoverColor: "rgba(0,0,0,1)",
                backgroundColor: "rgba(255,255,255,0)",
                hoverBackgroundColor: "rgba(255,255,255,0)",
              },
            },
            parent: "Content",
            desktop: {
              show: true,
              header: {
                show: true,
                padding: "16px 10px 10px 10px",
                fontSize: 14,
                showViewAll: true,
              },
              setting: {
                dots: false,
                rows: 0,
                speed: 200,
                arrows: false,
                autoplay: false,
                infinite: false,
                slidesPerRow: 0,
                slidesToShow: 4,
                autoplaySpeed: false,
                adaptiveHeight: true,
                slidesToScroll: 4,
              },
              listProps: {
                padding: "0px 10px 0px 10px",
                fontSize: 14,
                titlePadding: "10px 0px 10px 0px",
                showViewAllBottom: false,
              },
            },
            modeType: "Collection",
            valueText: [],
            colorProps: {
              color: "rgba(0,0,0,1)",
              hoverColor: "rgba(0,0,0,1)",
              viewallColor: "rgba(0,0,0,1)",
              backgroundColor: "rgba(255, 255, 255, 1)",
              hoverBackgroundColor: "rgba(255, 255, 255, 1)",
            },
            urlViewAll: "",
            contentList: {
              tags: [
                "MW01",
                "FA02",
                "FA01",
                "FA03",
                "NA01",
                "AP05",
                "AP06",
                "AP20",
                "AP07",
                "AP04",
                "AP01",
                "AP03",
                "AP28",
                "AP24",
                "AP02",
                "AP26",
                "FT01",
                "FT02",
                "FT20",
                "FT03",
                "FT04",
                "FT17",
                "AC24",
                "AC02",
                "AC01",
                "AC05",
                "AC03",
                "AC04",
                "AC22",
                "AC06",
                "AC26",
                "AC07",
                "AC08",
                "AC21",
                "SBB",
                "BR01",
              ],
              value: 6,
              showPrice: [true, true],
            },
            itemSetting: {
              limit: [20, 20],
              subPadding: ["0px", "0px"],
              subFontSize: [12, 12],
              titlePadding: ["0px", "0px"],
              titleFontSize: [12, 12],
              containerPadding: ["0px", "0px"],
            },
            linkViewAll: {
              type: "Category",
              value: 174,
              isLink: true,
              target: "",
            },
            titleViewAll: "Show All",
          },
        },
        style: {},
        parent: {
          id: "aCa/wSsK",
          idx: 0,
        },
        childOf: "Content",
        children: [],
      },
    },
  },
};
