import React from "react"
import { Flex } from "rebass"
import { get } from "lodash"
import { SelectInput, Counter } from "../../components"

const pi = [
  { value: "left", text: "Left" },
  { value: "right", text: "Right" },
]

const BoxPosition = (props) => {
  const { data, onPositionValue, onSetItemValue, indexParent } = props
  const positionImage = get(data, "position_image", "left")
  const boxImage = get(data, "position.boxImage", "0px")
  // const marginLeftText = get(data, "position.marginLeftText", "0px")
  // const marginLeftImage = get(data, "position.marginLeftImage", "0px")
  const bi = boxImage.split("px").join("") * 1
  // const mlt = marginLeftText.split("px").join("") * 1
  // const mli = marginLeftImage.split("px").join("") * 1

  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      flexWrap={"wrap"}
      py={[1]}
      className="child-wrap"
    >
      <SelectInput
        id={`type_type_carousel_position_image-${indexParent}`}
        title={"Position Image"}
        value={positionImage}
        list={pi}
        style
        onChange={(event) =>
          onSetItemValue("position_image", event.target.value, indexParent)
        }
      />
      <Counter
        isDisabled={false}
        title="Box Image"
        onSetValue={(value) => onPositionValue("boxImage", value, indexParent)}
        value={bi}
        interval={1}
        subtitle="px"
        minValue={0}
      />

      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        py={[1]}
        className="child-wrap"
      >
        {/* {positionImage === "left" && (
          <Counter
            isDisabled={false}
            title="Margin Left Image"
            onSetValue={(value) =>
              onPositionValue("marginLeftImage", value, indexParent)
            }
            value={mli}
            interval={1}
            subtitle="px"
            minValue={-500}
            style={{ marginRight: 16 }}
          />
        )}
        {positionImage === "right" && (
          <Counter
            isDisabled={false}
            title="Margin Left Text"
            onSetValue={(value) =>
              onPositionValue("marginLeftText", value, indexParent)
            }
            value={mlt}
            interval={1}
            subtitle="px"
            minValue={-500}
          />
        )} */}
      </Flex>
    </Flex>
  )
}

export default BoxPosition
