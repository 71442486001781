import React from "react"
import { useTranslate } from "react-admin"
import { Flex, Box } from "rebass"
import { Title } from "../"
import { ListButton } from "../buttons"
import { BOX_SHADOW_COLOR } from "../../utils"

const DetailAction = (props) => {
  const { children, title, basePath } = props
  const translate = useTranslate()
  return (
    <Box width={[1]}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={children ? "space-between" : "flex-start"}
        p={[3]}
        bg={"white"}
        style={{
          boxShadow: BOX_SHADOW_COLOR,
        }}
      >
        <Box width={[1]}>
          <Title fontWeight={"bold"} fontSize={[3]}>
            {translate(title)}
          </Title>
        </Box>

        <Flex
          width={[1 / 2]}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <ListButton
            basepath={basePath}
            style={{
              textTransform: "capitalize",
            }}
          />
        </Flex>
      </Flex>
      <Box
        width={[1]}
        style={{ height: 24, backgroundColor: "transparent" }}
        mb={[2]}
      />
    </Box>
  )
}

export default DetailAction
