import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text } from "rebass";
import { get } from "lodash";
import { SwitchInput } from "../switch";
import { BlockCounterWithType } from "../counter";

const defaultContainer = {
  isAutoWidth: [false, false],
  isAutoHeight: [false, false],
  width: ["100%", "100%"],
  height: ["100%", "100%"],
};

const ContainerContent = (props) => {
  const { container, keyContent, onUpdate } = props;
  const [state, setState] = useState(container);
  const onChangeValue = (k, v, index) => {
    let myValue = state[k];

    if (state[k]) {
      if (myValue[index]) {
        myValue[index] = v;
      } else {
        if (myValue[index] === "") {
          myValue[index] = v;
        } else {
          if (typeof v === "number") {
            myValue = [1, 1];
            myValue[index] = v;
          } else if (typeof v === "boolean") {
            myValue = [true, true];
            myValue[index] = v;
          } else {
            myValue = ["", ""];
            myValue[index] = v;
          }
        }
      }
    } else {
      if (typeof v === "number") {
        myValue = [1, 1];
        myValue[index] = v;
      } else if (typeof v === "boolean") {
        myValue = [true, true];
        myValue[index] = v;
      } else {
        myValue = ["", ""];
        myValue[index] = v;
      }
    }

    const params = { ...state, [k]: myValue };
    setState(params);
    onUpdate(keyContent, params);
  };

  return (
    <Box width={[1]}>
      <Flex width={[1]} flexDirection={"column"}>
        <Text fontWeight={"bold"} pb={[2]}>
          Desktop View
        </Text>
        <SwitchInput
          id={`is-auto-desktop-width-${keyContent}`}
          title={"Auto Width"}
          checked={get(state, `isAutoWidth[1]`, true)}
          onChange={(e) => onChangeValue("isAutoWidth", e.target.checked, 1)}
        />
        {!state.isAutoWidth[1] && (
          <BlockCounterWithType
            name={`width-desktop-container-content-${keyContent}`}
            value={state.width[1]}
            onSetValue={(v) => onChangeValue("width", v, 1)}
            title={"Width"}
          />
        )}
        <SwitchInput
          id={`is-auto-desktop-height-${keyContent}`}
          title={"Auto Height"}
          checked={get(state, `isAutoHeight[1]`, true)}
          onChange={(e) => onChangeValue("isAutoHeight", e.target.checked, 1)}
        />
        {!state.isAutoHeight[1] && (
          <BlockCounterWithType
            name={`height-desktop-container-content-${keyContent}`}
            value={state.height[1]}
            onSetValue={(v) => onChangeValue("height", v, 1)}
            title={"Height"}
          />
        )}
      </Flex>
      <Flex width={[1]} flexDirection={"column"}>
        <Text fontWeight={"bold"} pb={[2]}>
          Mobile View
        </Text>

        <SwitchInput
          id={`is-auto-mobile-width-${keyContent}`}
          title={"Auto Width"}
          checked={get(state, `isAutoWidth[0]`, true)}
          onChange={(e) => onChangeValue("isAutoWidth", e.target.checked, 0)}
        />
        {!state.isAutoWidth[0] && (
          <BlockCounterWithType
            name={`width-mobile-container-content-${keyContent}`}
            value={state.width[0]}
            onSetValue={(v) => onChangeValue("width", v, 0)}
            title={"Width"}
          />
        )}
        <SwitchInput
          id={`is-auto-mobile-height-${keyContent}`}
          title={"Auto Height"}
          checked={get(state, `isAutoHeight[0]`, true)}
          onChange={(e) => onChangeValue("isAutoHeight", e.target.checked, 0)}
        />
        {!state.isAutoHeight[0] && (
          <BlockCounterWithType
            name={`height-mobile-container-content-${keyContent}`}
            value={state.height[0]}
            onSetValue={(v) => onChangeValue("height", v, 0)}
            title={"Height"}
          />
        )}
      </Flex>
    </Box>
  );
};

ContainerContent.propTypes = {
  container: PropTypes.object,
  title: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  keyContent: PropTypes.string.isRequired,
};

ContainerContent.defaultProps = {
  container: defaultContainer,
  title: "Setting Container",
  keyContent: "container",
};

export default ContainerContent;
