import React, { useState } from "react"
import { Box, Flex } from "rebass"
import { get } from "lodash"
import { IconButton } from "@material-ui/core"
import LanguageIcon from "@material-ui/icons/Language"
import { useTranslate, TextInput } from "react-admin"
import Dialog from "@material-ui/core/Dialog"
import { DialogTitle } from "./FAQFields"
import DialogContent from "@material-ui/core/DialogContent"
import ListQuestionAnswer from "./ListQuestionAnswer"
const EditFormFields = (props) => {
  const translate = useTranslate()
  const [state, setState] = useState({
    open: false,
  })
  const { website, formData } = props

  const onClose = (values) => {
    setState({ open: false })
    if (values.locales) {
      props.updateField("locale", values.locales)
    }
  }

  const supportLanguage = get(website, "locale.supported_language", [])
  return (
    <Box width={[1]}>
      <Flex flexDirection={"row"} alignItems={"center"}>
        <TextInput
          source={`name`}
          label={translate(`resources.faq.fields.topic`)}
          InputLabelProps={{ shrink: true }}
          style={{ backgroundColor: "#FFFFFF" }}
        />
        {supportLanguage.length >= 1 && (
          <IconButton
            aria-label="show"
            color={"primary"}
            onClick={() => setState({ open: true })}
          >
            <LanguageIcon />
          </IconButton>
        )}
      </Flex>
      <Box width={[1]}>
        <ListQuestionAnswer {...props} topic_id={props.formData.id} />
      </Box>
      <Dialog
        open={state.open}
        onClose={() => onClose(props.formData)}
        aria-labelledby="form-topic-field"
        maxWidth={"lg"}
      >
        <DialogTitle
          id="form-topic-field"
          onClose={() => onClose(props.formData)}
        >
          {translate("resources.faq.fields.topic")}
        </DialogTitle>
        <DialogContent style={{ width: 500, minHeight: 200 }}>
          {supportLanguage.map((item, index) => (
            <Box width={[1]} key={index}>
              <TextInput
                defaultValue={get(formData, `locale.${item}.name`, "")}
                source={`locales.${item}.name`}
                label={translate(`resources.faq.locale.${item}`)}
              />
            </Box>
          ))}
        </DialogContent>
      </Dialog>
    </Box>
  )
}
export default EditFormFields
