import React, { Component } from "react"
import PropTypes from "prop-types"
import ContentAdd from "@material-ui/icons/Add"
import { Select, MenuItem, Button } from "@material-ui/core"
import { Box, Flex } from "rebass"
import { FooterAction, ContainerPanel } from "../actions"
import { defaultContentCarousel, bgPropertiesDefault } from "./utils"
import { BoxContent } from "../styled"
import { RowFieldsCarousel } from "./Fields"
import { defaultGradient } from "../components"
class RowCarousel extends Component {
  constructor(props) {
    super(props)
    const {
      backgroundColor,
      padding,
      width,
      backgroundImage,
      isBackgroundImage,
      align,
      bgProps,
      carousel,
      isGradient,
      gradient,
    } = props.data.values
    const bi = backgroundImage ? backgroundImage : { url: "", urlMobile: "" }
    const alignContent = align ? align : "center"
    const isBGI = isBackgroundImage ? isBackgroundImage : false
    const bgProperties = bgProps ? bgProps : bgPropertiesDefault
    const carouselContent = carousel
      ? carousel
      : {
          keyActive: "",
          auto: false,
          direaction: "incremental",
          interval: 3,
        }

    this.state = {
      heightContent: props.heightContent,
      widthContent: props.widthContent,
      content: props.data.content,
      backgroundColor: backgroundColor,
      containerPadding: padding,
      contentWidth: width.contentWidth,
      type: width.type,
      maxValueWidth: width.type === "px" ? 0 : 100,
      interval: width.type === "px" ? 20 : 1,
      backgroundImage: bi,
      align: alignContent,
      isBackgroundImage: isBGI,
      bgProps: bgProperties,
      itemActiveCarousel: carouselContent.keyActive,
      carousel: carouselContent,
      isGradient: isGradient || false,
      gradient: gradient || defaultGradient,
    }
    this.onClickPaste = this.onClickPaste.bind(this)
  }

  handleChangeActiveItemCarousel = (event) => {
    const oldCarousel = this.state.carousel
    let newCarousel = {
      keyActive: event.target.value,
      auto: oldCarousel.auto,
      direaction: oldCarousel.direction,
      interval: oldCarousel.interval,
    }
    this.setState({
      itemActiveCarousel: event.target.value,
      carousel: newCarousel,
    })
  }

  UNSAFE_componentWillMount() {
    const parent = this.props.layoutState.getItemJS(
      this.props.layoutState.selectedItem
    )
    if (parent && parent.children && parent.children.length <= 1) {
      this.setState({
        itemActiveCarousel: parent.children[0],
      })
    }
  }

  onClickAddContent = () => {
    const parent = this.props.layoutState.getItemJS(
      this.props.layoutState.selectedItem
    )
    this.props.layoutState.insertItem(
      this.props.layoutState.selectedItem,
      parent.children.length,
      defaultContentCarousel
    )
  }
  onClickCopy = () => {
    let newData = this.props.data
    let width = {
      type: this.state.type,
      contentWidth: this.state.contentWidth,
    }
    newData.values.backgroundColor = this.state.backgroundColor
    newData.values.padding = this.state.containerPadding
    newData.values.align = this.state.align
    newData.values.backgroundImage = this.state.backgroundImage
    newData.values.isBackgroundImage = this.state.isBackgroundImage
    newData.values.bgProps = this.state.bgProps
    newData.values.carousel = this.state.carousel
    newData.values.width = width
    newData.content = this.state.content
    newData.values.isGradient = this.state.isGradient
    newData.values.gradient = this.state.gradient
    localStorage.setItem("RowCarousel", JSON.stringify(newData))
  }
  onClickPaste = () => {
    const row = JSON.parse(localStorage.getItem("RowCarousel"))
    if (row) {
      const { values, content } = row
      this.setState({
        type: values.width.type,
        contentWidth: values.width.contentWidth,
        backgroundColor: values.backgroundColor,
        containerPadding: values.padding,
        backgroundImage: values.backgroundImage,
        align: values.align,
        isBackgroundImage: values.isBackgroundImage,
        bgProps: values.bgProps,
        content: content,
        carousel: values.carousel,
        isGradient: values.isGradient,
        gradient: values.gradient,
      })
    }
  }
  onClickSave = () => {
    let newData = this.props.data

    let width = {
      type: this.state.type,
      contentWidth: this.state.contentWidth,
    }
    newData.values.backgroundColor = this.state.backgroundColor
    newData.values.padding = this.state.containerPadding
    newData.values.align = this.state.align
    newData.values.backgroundImage = this.state.backgroundImage
    newData.values.isBackgroundImage = this.state.isBackgroundImage
    newData.values.bgProps = this.state.bgProps
    newData.values.width = width
    newData.values.carousel = this.state.carousel
    newData.content = this.state.content
    newData.values.isGradient = this.state.isGradient
    newData.values.gradient = this.state.gradient
    this.props.onUpdateData(newData)
  }

  onUpdatePadding = (padding) => {
    this.setState({ containerPadding: padding })
  }
  onSetValue = (key, value) => {
    this.setState({ ...this.state, [key]: value })
  }

  onChangeType = (event) => {
    let width = 0
    let content = this.state.contentWidth
    let interval = 20
    if (event.target.value === "%") {
      width = 100
      content = 100
      interval = 1
    }
    this.setState({
      type: event.target.value,
      maxValueWidth: width,
      contentWidth: content,
      interval: interval,
    })
  }

  onChangeContentAlign = (value) => {
    this.setState({
      align: value,
    })
  }

  updateBackground = (values) => {
    const {
      backgroundColor,
      backgroundImage,
      isBackgroundImage,
      bgProps,
    } = values
    this.setState({
      backgroundColor,
      backgroundImage,
      isBackgroundImage,
      bgProps,
    })
  }

  onToggle = (e) => {
    let oldCarousel = this.state.carousel
    oldCarousel["auto"] = e.target.checked
    this.setState({ carousel: oldCarousel })
  }
  onUpdateInterval = (value) => {
    let oldCarousel = this.state.carousel
    oldCarousel["interval"] = value
    this.setState({ carousel: oldCarousel })
  }
  onChangeDirection = (event) => {
    let oldCarousel = this.state.carousel
    oldCarousel["direction"] = event.target.value
    this.setState({ carousel: oldCarousel })
  }
  render() {
    const childrenParent = this.props.layoutState.getItemJS(
      this.props.layoutState.selectedItem
    ).children
    return (
      <Flex width={[1]} flexDirection={"column"}>
        <BoxContent style={{ height: this.state.heightContent - 120 }}>
          <ContainerPanel>
            <Box width={[1]} p={[1]}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.onClickAddContent}
                icon={<ContentAdd />}
              >
                Add Item Carousel
              </Button>
            </Box>
            <Box width={[1]} py={[1]} px={[2]}>
              <Select
                label="Edit Content Active"
                value={this.state.itemActiveCarousel}
                onChange={this.handleChangeActiveItemCarousel}
              >
                {childrenParent.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>{`Item Content ${
                      index + 1
                    } `}</MenuItem>
                  )
                })}
              </Select>
            </Box>
            <RowFieldsCarousel
              state={this.state}
              onChangeType={this.onChangeType}
              onSetValue={this.onSetValue}
              onToggle={this.onToggle}
              updateBackground={this.updateBackground}
              carousel={this.state.carousel}
              onUpdateInterval={this.onUpdateInterval}
              onChangeDirection={this.onChangeDirection}
            />
          </ContainerPanel>
        </BoxContent>
        <FooterAction
          onClickSave={this.onClickSave}
          onClickCopy={this.onClickCopy}
          onClickPaste={this.onClickPaste}
          widthContent={this.state.widthContent}
        />
      </Flex>
    )
  }
}

RowCarousel.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
}

RowCarousel.defaultProps = {
  data: {},
}
export default RowCarousel
