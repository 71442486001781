import React from "react"
import { get } from "lodash"
import { Flex } from "rebass"
import { connect } from "../../../core"
import ContainerDimensions from "react-container-dimensions"
import { ProductGroup, ProductGroupCarousel } from "../../../libs/productgroup"

const ProductGroupView = (props) => {
  const { id, layoutState, widthScreen } = props
  const { values } = layoutState.getItemJS(id).props
  const { containerPadding } = values
  /**setting,
    type_list,
    categoryId,
    collectionId,
    showPrice,
    limit, */
  // console.log(props)
  const list = get(values, "products", [])
  if (list.length <= 0) {
    return <div />
  } else if (list.length > 4) {
    return (
      <div
        style={{
          boxSizing: "border-box",
          position: "relative",
          height: "auto",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          padding: containerPadding,
          flex: 1,
        }}
      >
        <ContainerDimensions>
          {({ width, height }) => {
            // console.log(width, widthScreen)
            return (
              <ProductGroupCarousel
                data={values}
                widthContainer={width}
                heightContainer={height}
              />
            )
          }}
        </ContainerDimensions>
      </div>
    )
  }
  return (
    <Flex width={[1]} style={{ padding: containerPadding }}>
      <ProductGroup products={list} widthContainer={widthScreen} />
    </Flex>
  )
}

export default connect("layoutState", "widthScreen")(ProductGroupView)
