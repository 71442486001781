import React from "react";
import { Flex } from "rebass";
import { PanelCard, ContentDetail } from "../actions";
import { TextInput } from "../components";
import SubcribeContent from "./SubcribeContent";
/*
placeholder: "",
    labelBUtton: "",
    urlSubcribe: "",
*/
export default (props) => {
  const { state, onSetValue } = props;

  const onSaveDesktopValue = (k, v) => {
    const params = {
      ...state.desktop,
      [k]: v,
    };
    onSetValue("desktop", params);
  };
  const onSaveMobileValue = (k, v) => {
    const params = {
      ...state.mobile,
      [k]: v,
    };
    onSetValue("mobile", params);
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <PanelCard title={`General Setting`} id={`general-subcribe-setting`}>
        <ContentDetail>
          <TextInput
            value={state.placeholder}
            onChange={(value) => onSetValue("placeholder", value)}
            name='placeholder'
            title='Placeholder input'
            fullWidth={true}
          />
          <TextInput
            value={state.urlSubcribe}
            onChange={(value) => onSetValue("urlSubcribe", value)}
            name='urlSubcribe'
            title='Url Post Subcribe '
            fullWidth={true}
          />
          <TextInput
            value={state.labelButton}
            onChange={(value) => onSetValue("labelButton", value)}
            name='labelButton'
            title='Label Button'
            fullWidth={true}
          />
        </ContentDetail>
      </PanelCard>
      <PanelCard title={`Desktop Setting`} id={`desktop-subcribe-setting`}>
        <SubcribeContent
          state={state.desktop}
          onSetValue={(k, v) => onSaveDesktopValue(k, v)}
          contentId={"desktop-subcribe-content"}
        />
      </PanelCard>
      <PanelCard title={`Mobile Setting`} id={`mobile-subcribe-setting`}>
        <SubcribeContent
          state={state.mobile}
          onSetValue={(k, v) => onSaveMobileValue(k, v)}
          contentId={"mobile-subcribe-content"}
        />
      </PanelCard>
    </Flex>
  );
};
