import React, { useEffect, useState, Fragment } from "react";
import { get } from "lodash";
import { Loading, useTranslate, Show, useDataProvider } from "react-admin";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Divider, Tabs, Tab, Button } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { Flex, Box } from "rebass";
import {
  HeaderTitle,
  TabPanel,
  a11yProps,
  CreateButton,
} from "../../components";
// import { BOX_SHADOW_COLOR } from "../../utils";
import {
  ShipmentFields,
  OrderFields,
  tabs,
  AmendmentFields,
  // TimelineFields,
  checkDefaultIndex,
} from "./components";
import DialogShipmentSendEmail from "./DialogShipmentSendEmail";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tabs: {
    backgroundColor: "#FFFFFF",
  },
}));

const MainShow = (props) => {
  const dataProvider = useDataProvider();
  const history = useHistory();
  const { record } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const theme = useTheme();
  const website_id = get(record, "website_id", 0);
  const [loading, setLoading] = useState(true);
  const [website, setWebsite] = useState({});

  // const match = useRouteMatch();
  const location = useLocation();
  const url = `/orders/${record.id}/show`; //match ? match.url : location.pathname;
  const indexTab = checkDefaultIndex(location.pathname);
  const [value, setValue] = React.useState(indexTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onBackList = () => {
    history.push("/orders");
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  // const onPrintLabelShipment = () => {};

  // console.log(record);
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      dataProvider
        .getOne("websites", { id: website_id })
        .then(({ data }) => {
          // console.log(data);
          if (data) {
            setWebsite(data);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }, 1000);

    return () => clearTimeout(timer);
  }, [website_id, dataProvider]);
  if (loading) {
    return (
      <Flex
        width={[1]}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Loading />
      </Flex>
    );
  }
  return (
    <div className={classes.root}>
      <HeaderTitle
        title={"resources.orders.title.name"}
        iShowWithChildren={false}
      >
        <Fragment>
          {value === 1 && (
            <DialogShipmentSendEmail
              {...props}
              translate={translate}
              dataProvider={dataProvider}
            />
          )}
          <Button color={"primary"} onClick={onBackList}>
            {translate("ra.action.list")}
          </Button>
          {value === 1 && (
            <Fragment>
              {/* <Button color={"primary"} onClick={onPrintLabelShipment}>
                {translate("resources.buttons.print_shipping_label")}
              </Button> */}
              <CreateButton
                basePath={`${url}/shipment`}
                sizeIcon={"xsmall"}
                label={"resources.buttons.create_shipment"}
                style={{ textTransform: "capitalize" }}
                isNotShowIcon={true}
              />
            </Fragment>
          )}
          {value === 2 && (
            <Fragment>
              <CreateButton
                basePath={`${url}/amendment`}
                sizeIcon={"xsmall"}
                label={"resources.buttons.create_amendment"}
                style={{ textTransform: "capitalize" }}
                isNotShowIcon={true}
              />
            </Fragment>
          )}
        </Fragment>
      </HeaderTitle>
      <Tabs
        value={value}
        indicatorColor='primary'
        onChange={handleChange}
        className={classes.tabs}
      >
        {tabs.map((choice, index) => (
          <Tab
            key={choice.id}
            label={translate(choice.name)}
            component={Link}
            to={`${url}/${choice.link}`}
            {...a11yProps("orders", index)}
          />
        ))}
      </Tabs>
      <Divider />
      <Box
        width={[1]}
        style={{ height: 24, backgroundColor: "transparent" }}
        mb={[2]}
      />
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          name={"orders"}
          padding={0}
        >
          <Flex width={[1]} flexDirection={"column"}>
            <OrderFields {...props} translate={translate} website={website} />
          </Flex>
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          dir={theme.direction}
          name={"orders"}
          padding={0}
        >
          <Flex width={[1]} flexDirection={"column"}>
            <ShipmentFields
              {...props}
              translate={translate}
              website={website}
            />
          </Flex>
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          dir={theme.direction}
          name={"orders"}
          padding={0}
        >
          <Flex width={[1]} flexDirection={"column"}>
            <AmendmentFields
              {...props}
              translate={translate}
              website={website}
            />
          </Flex>
        </TabPanel>
        {/* <TabPanel
          value={value}
          index={3}
          dir={theme.direction}
          name={"orders"}
          padding={0}
        >
          <Flex width={[1]} flexDirection={"column"}>
            <TimelineFields
              {...props}
              translate={translate}
              website={website}
            />
          </Flex>
        </TabPanel> */}
      </SwipeableViews>
    </div>
  );
};

const OrderShow = (props) => {
  return (
    <Show {...props} component='div'>
      <MainShow />
    </Show>
  );
};

export default OrderShow;
