import React from "react";
import { showNotification, useQuery } from "react-admin";
import { withRouter } from "react-router-dom";
import { Box } from "rebass";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { EditForm } from "./components";
import { CardPanel } from "../websites/components/CardPanel";
import { FullLoadingScreen } from "../../components";

// const contactUs = {
//   header: {
//     title: "CONTACT US",
//     descriptions:
//       "We appreciate your time and aim to provide the right answers to your enquiries quickly.",
//     items: [
//       "Our Customer Service Agents are available from:",
//       "Monday – Friday: 9am to 6pm",
//       "Saturday: 9am to 4pm",
//       "(Excluding Public Holidays)",
//     ],
//   },
//   contents: [
//     {
//       header: {
//         icon: "phone",
//         title: "CALL US",
//       },
//       phone: {
//         key: "Telephone:",
//         value: "<number>",
//       },
//     },
//     {
//       header: {
//         icon: "whatsapp",
//         title: "WHATSAPP",
//       },
//       phone: {
//         key: "Mobile:",
//         value: "<number>",
//       },
//       description_1:
//         "Calls will not be answered. To speak to a customer service agent, please call us at our landline number.",
//     },
//     {
//       header: {
//         icon: "social_media",
//         title: "SOCIAL MEDIA MESSAGING",
//       },
//       social_media: [
//         {
//           icon: "facebook",
//           url: "",
//         },
//         {
//           icon: "instagram",
//           url: "",
//         },
//         {
//           icon: "twitter",
//           url: "",
//         },
//         { url: "", title: "@PLAYITRIGHTPH" },
//       ],
//       description_1:
//         "Use the private message option or tweet us to contact our support team.",
//     },
//     {
//       header: {
//         icon: "chat",
//         title: "LIVE CHAT",
//       },
//       description_1:
//         "Look out for this icon on the bottom right of your screen.",
//       description_2:
//         "*Available when our customer service agents are not attending to other customers",
//     },
//   ],
// };

const LayoutEditThemePage = (props) => {
  const { match } = props;
  const { data, loading } = useQuery({
    type: "getOne",
    resource: "layouts",
    payload: { id: match.params.id },
  });

  if (loading) {
    return <FullLoadingScreen />;
  }
  // const params = {
  //   ...data,
  //   theme: {
  //     ...data.theme,
  //     contactus: contactUs,
  //   },
  // };
  return (
    <CardPanel>
      <Box width={[1]}>
        <EditForm
          {...props}
          sourceApi='layouts'
          typePostUrl={"UPDATE"}
          pathUrl='layouts'
          initialValues={data}
          showcontent={"theme"}
        />
      </Box>
    </CardPanel>
  );
};

const enhance = compose(withRouter, connect(undefined, { showNotification }));

export default enhance(LayoutEditThemePage);
