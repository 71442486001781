export const defaultImage = {
  values: {
    parent: "Content",
    type: "ImageView",
    desktop: {
      show: true,
      width: "100%",
      height: "100%",
      margin: "0px",
      padding: "0px",
    },
    mobile: {
      show: true,
      width: "100%",
      height: "100%",
      margin: "0px",
      padding: "0px",
    },
    imageUrl: {
      url: "",
      urlMobile: "",
    },
    align: "center",
    href: {
      url: "",
      target: "",
      isCollection: false,
      collection: 0,
    },
  },
};
