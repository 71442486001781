import React from "react"
import { Select, MenuItem } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import { get, has, indexOf } from "lodash"

const propDescription = "description"
const propLocale = "locale"
const compareProductShow = {
  id: "id",
  name: "name",
  slug: "apple-ipad-7-wi-fi-cellular-32gb",
  price: "price",
  margin: "margin",
  photos: "photos",
  discount: "discount",
  product_type: "product_type",
  event: "event",
  advanced_price: "advanced_price",
  unit_price: "unit_price",
}

export function removeProductObject(data) {
  const newProduct = Object.keys(data).reduce((object, key) => {
    if (compareProductShow[key]) {
      object[key] = data[key]
    } else {
      if (key === propLocale) {
        let mylocale = data[propLocale]
        Object.keys(mylocale).map(keyLocale => {
          let myloc = mylocale[keyLocale]
          const testing = Object.keys(myloc).reduce((obj, key2) => {
            if (key2 !== propDescription) {
              obj[key2] = myloc[key2]
            }
            return obj
          }, {})
          myloc = testing
          mylocale[keyLocale] = testing
          return myloc
        })
        object[propLocale] = mylocale
      }
    }
    return object
  }, {})
  return newProduct
}

const placeholder = "https://via.placeholder.com/300x300?text=CMS"

export const getPhoto = function(photos) {
  var src = placeholder

  if (has(photos, "photo_set")) {
    var photoSet = get(photos, "photo_set")
    var key = Object.keys(photoSet)
    var path = `photo_set.${key[0]}.photos[0]`
    src = get(photos, path, placeholder)
  } else if (has(photos, "*")) {
    src = get(photos, "*.photos[0]", placeholder)
  }
  return src
}

export const getPhotos = function(photos, selectedVariant) {
  var defaultPhoto = [placeholder]

  // when product has color variant
  if (has(photos, "photo_set")) {
    var photoSet = get(photos, "photo_set")
    var key = Object.keys(photoSet)
    // get color value from selectedVariant
    var selectedColor = indexOf(key, selectedVariant["color"])
    var photoIndex = selectedColor > -1 ? selectedColor : 0

    var path = `photo_set.${key[photoIndex]}.photos`
    return get(photos, path, defaultPhoto)
  } else if (has(photos, "*")) {
    return get(photos, "*.photos", defaultPhoto)
  }

  return defaultPhoto
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export const SelectInput = props => {
  const classes = useStyles()
  const { message, onChange, value, list, title, id, isError = true } = props
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`select-label-${id}`}>{title}</InputLabel>
      <Select
        labelId={`select-label-${id}`}
        id={`select-${id}`}
        value={value}
        onChange={onChange}
      >
        {list.map(item => (
          <MenuItem value={item.id} key={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {message && <FormHelperText error={isError}>{message}</FormHelperText>}
    </FormControl>
  )
}
