import React, { Fragment } from "react";
import { Flex } from "rebass";
import { get } from "lodash";
//filter
// import CartItem from "./CartItem";
import { Button } from "@material-ui/core";
import { Loading, useNotify } from "react-admin";
import { PaperCard } from "../../../../components";
// import ShipmentDetail from "./ShipmentDetail";
import { RestApiCRUDString, RestApi } from "../../../../restClient";
//v
import { useQuery } from "../../../../components";
import ListProducts from "./ListProducts";
const MainFields = (props) => {
  const { translate, record, website, history } = props;
  const notify = useNotify();
  const currency_symbol = get(website, "locale.currency_symbol", "");
  const list = get(record, "items", []);
  const [loading, setLoading] = React.useState(false);
  const [listQty, setListQty] = React.useState(list);
  const state = {
    packaging_id: 0,
    width: 0,
    height: 0,
    length: 0,
    weight: 0,
    cost: 0,
  };
  // const [state, setState] = React.useState({
  //   packaging_id: 0,
  //   width: 0,
  //   height: 0,
  //   length: 0,
  //   weight: 0,
  //   cost: 0,
  // });

  // console.log(list);
  // const onRequestSubmit = () => {
  //   if (record && record.id) {
  //     setLoading(true);
  //     //for source_id @Ronadi we set to 1 during the request
  //     const params = {
  //       site_id: record.site_id,
  //       order_id: record.id,
  //       source_id: 1,
  //       ...state,
  //     };
  //     RestApiCRUDString(`/orders/${record.id}/shipments`, "POST", params)
  //       .then((response) => {
  //         return response.json();
  //       })
  //       .then((res) => {
  //         if (res && res.id) {
  //           onPostOrderDetail(res);
  //         } else {
  //           setLoading(false);
  //           notify("resources.errors.please_try_again", "warning", {
  //             smart_count: 1,
  //           });
  //         }
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //         setLoading(false);
  //         notify("resources.errors.please_try_again", "warning", {
  //           smart_count: 1,
  //         });
  //       });
  //   }
  // };

  // const onPostOrderDetail = (response) => {
  //   let newParams = [];
  //   //qtyTemp is temp qty from ListProducts Item
  //   listQty.map((io) => {
  //     const orderItem = {
  //       site_id: record.site_id,
  //       order_detail_id: io.id,
  //       qty: get(io, "qtyTemp", 0),
  //     };
  //     newParams.push(orderItem);
  //     return "";
  //   });
  //   RestApiAll(
  //     `orders/${record.id}/shipments/${response.id}`,
  //     "POST",
  //     newParams
  //   )
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         const result = filter(res, function (o) {
  //           return o.message;
  //         });
  //         if (result.length === res.length) {
  //           const message = get(
  //             result[0],
  //             "message",
  //             "resources.errors.please_try_again"
  //           );
  //           notify(message, "warning", {
  //             smart_count: 1,
  //           });
  //           setLoading(false);
  //         } else if (result.length > 0) {
  //           const message = get(
  //             result[0],
  //             "message",
  //             "resources.errors.please_try_again"
  //           );
  //           notify(message, "warning", {
  //             smart_count: 1,
  //           });
  //           setLoading(false);
  //         } else {
  //           onRequestShipment(response.id);
  //         }
  //       } else {
  //         setLoading(false);
  //       }
  //     })
  //     .catch((e) => {
  //       setLoading(false);
  //       notify("resources.errors.please_try_again", "warning", {
  //         smart_count: 1,
  //       });
  //     });
  // };

  // const onRequestShipment = (responseId) => {
  //   if (record && record.id && responseId) {
  //     RestApi(`/orders/${record.id}/shipments/${responseId}/requestShipment`)
  //       .then((res) => {
  //         notify("ra.notification.created", "success", {
  //           smart_count: 1,
  //         });
  //         setLoading(false);
  //         history.replace(`/orders/${record.id}/show/shipment`);
  //       })
  //       .catch((e) => {
  //         setLoading(false);
  //         console.log(e);
  //       });
  //   } else {
  //     setLoading(false);
  //     notify("resources.errors.please_try_again", "warning", {
  //       smart_count: 1,
  //     });
  //   }
  // };

  const onRequestSplitItem = () => {
    if (record && record.id) {
      //
      let newParams = [];
      //for source_id @Ronadi we set to 1 during the request
      const params = {
        site_id: record.site_id,
        order_id: record.id,
        source_id: 1,
        ...state,
      };
      listQty.map((io) => {
        const myQty = get(io, "qtyTemp", 0);
        for (let index = 1; index <= myQty; index++) {
          const orderItem = {
            site_id: record.site_id,
            order_detail_id: io.id,
            qty: 1,
          };
          newParams.push(orderItem);
        }

        return "";
      });

      if (newParams && newParams.length > 0) {
        // let indexCount = 1;
        setLoading(true);
        newParams.map((item, myIndex) => {
          RestApiCRUDString(`/orders/${record.id}/shipments`, "POST", params)
            .then((response) => response.json())
            .then((res) => {
              return RestApiCRUDString(
                `/orders/${record.id}/shipments/${res.id}`,
                "POST",
                item
              );
            })
            .then((response1) => response1.json())
            .then((result) => {
              return RestApi(
                `/orders/${record.id}/shipments/${result.order_shipment_id}/requestShipment`
              );
            })
            .then((response2) => response2.json())
            .then((latestResult) => {
              // indexCount++;
              if (myIndex === newParams.length - 1) {
                setLoading(false);
                notify("ra.notification.created", "success", {
                  smart_count: 1,
                });
                history.replace(`/orders/${record.id}/show/shipment`);
              }
            })
            .catch((e) => {
              if (myIndex === newParams.length - 1) {
                setLoading(false);
              }
            });
          return "";
        });
      } else {
        notify("resources.errors.please_select_product_picked", "warning", {
          smart_count: 1,
        });
      }
    }
  };
  return (
    <Fragment>
      <Flex width={[1]} flexDirection={"column"} py={[3]}>
        <PaperCard
          title={translate("resources.orders.labels.package")}
          showButton={false}
          elevation={3}
          bgHeader={"#eeeeee"}
          propsHeader={{ px: [3], py: [2] }}
        >
          <ListProducts
            list={list}
            translate={translate}
            currency_symbol={currency_symbol}
            listQty={listQty}
            website={website}
            setListQty={setListQty}
          />
          <Flex width={[1]} flexDirection={"column"} py={[2]}>
            <Flex
              width={[1]}
              flexDirection='row'
              sx={{ "&>button": { mr: [2] } }}
            >
              <Button
                variant={"contained"}
                disabled={loading}
                style={{ borderRadius: 24 }}
                color={"primary"}
                // onClick={onRequestSubmit}
                onClick={() => onRequestSplitItem()}
              >
                {translate("resources.buttons.request_shipment")}
              </Button>
            </Flex>
          </Flex>
        </PaperCard>
      </Flex>
    </Fragment>
  );
};
const FormFields = (props) => {
  const { record } = props;
  const websiteId = get(record, "website_id", 0);
  // const [loading, setLoading] = useState(true);
  const { loading, data } = useQuery(`/websites/${websiteId}`);
  if (loading) {
    return (
      <Flex
        width={[1]}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Loading />
      </Flex>
    );
  }
  return <MainFields {...props} website={data} />;
};

export default FormFields;
