import React, { Component } from "react"
import PropTypes from "prop-types"
import { find } from "lodash"
import styled from "styled-components"
import ActionSave from "@material-ui/icons/Save"
import { lightBlue } from "@material-ui/core/colors"
import { IconButton } from "@material-ui/core"
import { Flex } from "rebass"
import { SketchColorPicker, UploadImage } from "../components"
import {
  Counter,
  PixelPercent,
  SwitchInput,
  Title,
  DialogGradient,
  defaultGradient,
} from "../addons/props/components"
import { BoxContent } from "../addons/props/styled"
import { data } from "../addons/props/components/bgTheme"
import { ContentDetail } from "../addons/props/actions"

class BodyItem extends Component {
  constructor(props) {
    super(props)
    const {
      backgroundColor,
      backgroundImage,
      width,
      fullheight,
      isBgImage,
      isGradient,
      gradient,
    } = props.data.values
    let fh = fullheight
    if (fullheight === undefined) {
      fh = true
    }
    this.state = {
      heightContent: props.heightContent,
      expanded: true,
      imageUrl: backgroundImage,
      image: this.checkImage(backgroundImage.url),
      idImage: backgroundImage.url,
      isBgImage: isBgImage || false,
      backgroundColor: backgroundColor,
      contentWidth: width.contentWidth,
      type: width.type,
      maxValueWidth: width.type === "px" ? 0 : 100,
      interval: width.type === "px" ? 50 : 1,
      fullheight: fh,
      isGradient: isGradient || false,
      gradient: gradient || defaultGradient,
    }
  }

  checkImage = (id) => {
    let image = ""
    let result = find(data, function (item) {
      return item.id === id
    })
    if (result) {
      return result.bg
    }
    return image
  }

  onClickSave = () => {
    let newData = this.props.data
    let width = {
      type: this.state.type,
      contentWidth: this.state.contentWidth,
    }

    newData.values.backgroundColor = this.state.backgroundColor
    newData.values.backgroundImage = this.state.imageUrl
    newData.values.isBgImage = this.state.isBgImage
    newData.values.isGradient = this.state.isGradient
    newData.values.gradient = this.state.gradient
    newData.values.width = width
    newData.values.contentWidth = width.contentWidth + width.type
    newData.values.fullheight = this.state.fullheight
    // console.log(this.state.fullheight)
    this.props.onUpdateData(newData)
  }

  // onSetValue = (key, value) => {
  //   this.setState({ contentWidth: value })
  // }
  onItemSelected = (bgSelected) => {
    this.setState({ image: this.checkImage(bgSelected), idImage: bgSelected })
  }
  onChangeType = (event, value) => {
    let width = 0
    let content = this.state.contentWidth
    let interval = 50
    if (value === "%") {
      width = 100
      content = 100
      interval = 1
    }
    this.setState({
      type: value,
      maxValueWidth: width,
      contentWidth: content,
      interval: interval,
    })
  }

  onSetValue = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value,
    })
  }

  render() {
    const { heightContent } = this.props
    return (
      <Flex
        width={[1]}
        flexDirection={"column"}
        pb={[2]}
        style={{ height: heightContent, overflowY: "auto" }}
      >
        <BoxContent style={{ height: this.state.heightContent, padding: 8 }}>
          <ContentDetail>
            <SwitchInput
              id={`full-height`}
              title={"Full Height"}
              checked={this.state.fullheight}
              onChange={(e) => this.onSetValue("fullheight", e.target.checked)}
            />
          </ContentDetail>
          <ContentDetail>
            <SwitchInput
              id={`use-bg-image-body`}
              title={"Use Background Image"}
              checked={this.state.isBgImage}
              onChange={(e) => this.onSetValue("isBgImage", e.target.checked)}
            />
            {this.state.isBgImage && (
              <Flex width={[1]} flexDirection={"column"}>
                <Title title="Upload Background Image" />
                <UploadImage
                  type={"body"}
                  imageUrl={this.state.imageUrl}
                  onUpdateUrl={(value) => this.onSetValue("imageUrl", value)}
                  title="Image"
                />
              </Flex>
            )}
            <SketchColorPicker
              title={"Background"}
              onUpdateColor={(color) =>
                this.onSetValue("backgroundColor", color)
              }
              color={this.state.backgroundColor}
            />
          </ContentDetail>
          <ContentDetail>
            <SwitchInput
              id={`use-bg-gradient-body`}
              title={"Use Background Gradient"}
              checked={this.state.isGradient}
              onChange={(e) => this.onSetValue("isGradient", e.target.checked)}
            />
            {this.state.isGradient && (
              <DialogGradient
                onSetValue={(gradient) => this.onSetValue("gradient", gradient)}
                gradient={this.state.gradient}
              />
            )}
          </ContentDetail>
          <ContentDetail>
            <PixelPercent
              name="type"
              title="Container Width"
              value={this.state.type}
              onChange={this.onChangeType}
            />
            <Flex flexDirection={"row"} width={[1]} justifyContent={"flex-end"}>
              <Counter
                isDisabled={false}
                onSetValue={(value) => this.onSetValue("contentWidth", value)}
                value={this.state.contentWidth}
                interval={this.state.interval}
                maxValue={this.state.maxValueWidth}
              />
            </Flex>
          </ContentDetail>
        </BoxContent>
        <DivHeaderActions>
          <Flex width={[1]}>
            <Flex
              width={["310px"]}
              //this.props.widthContent / 4
              flexDirection={"row"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              style={{ backgroundColor: lightBlue[700] }}
            >
              <IconButton
                onClick={this.onClickSave}
                style={{ color: "inherit" }}
              >
                <ActionSave color={"inherit"} />
              </IconButton>
            </Flex>
          </Flex>
        </DivHeaderActions>
      </Flex>
    )
  }
}

const DivHeaderActions = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  bottom: 0px;
  color: #ffffff;
`

BodyItem.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func,
}

BodyItem.defaultProps = {
  data: {},
}

export default BodyItem
