import React, { Fragment } from "react";
import { Flex, Box, Text } from "rebass";
import {
  FormDataConsumer,
  useTranslate,
  TextInput,
  required,
} from "react-admin";
import { RaBooleanInput } from "../../../components";
import { Field } from "react-final-form";
import { get } from "lodash";
import EditSearchChipInput from "../../products/components/EditSearchChipInput";
const FormFields = (props) => {
  const translate = useTranslate();
  return (
    <Flex flexDirection={"column"}>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <Fragment>
              <RaBooleanInput source='active' id={"active"} />
              <Box width={[1]} pb={[2]}>
                <TextInput
                  source='name'
                  label='resources.stores.fields.name'
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  validate={[required()]}
                />
              </Box>
              <Box width={[1]} pt={[2]}>
                <Text fontWeight={"bold"} fontSize={[0]}>
                  {translate("resources.products.fields.tags")}
                </Text>
                <Field
                  label={""}
                  name={`tags`}
                  component={EditSearchChipInput}
                  dataOptions={get(formData, "tags", [])}
                  updateField={props.updateField}
                />
              </Box>
            </Fragment>
          );
        }}
      </FormDataConsumer>
    </Flex>
  );
};

export default FormFields;
