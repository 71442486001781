export const defaultRowIconText = {
  isShowDesktop: true,
  isShowMobile: true,
  backgroundColor: "rgba(255,255,255,0)",
  icon: "",
  layoutRow: {
    flexDirection: ["column", "row"],
    justifyContent: ["flex-start", "flex-start"],
    alignItems: ["flex-start", "flex-start"],
    flexWrap: ["nowrap", "nowrap"],
  },
  title: {
    text: "Title",
    fontSize: [10, 14],
    lineHeight: ["120%", "120%"],
    letterSpacing: ["1px", "1px"],
    textAlign: ["left", "left"],
    color: "rgba(0,0,0,1)",
    containerPadding: ["0px", "0px"],
  },
  subtitle: {
    text: "subtitle",
    fontSize: [10, 14],
    lineHeight: ["120%", "120%"],
    letterSpacing: ["1px", "1px"],
    textAlign: ["left", "left"],
    color: "rgba(0,0,0,1)",
    containerPadding: ["0px", "0px"],
  },
};

export const toolbar = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ color: [] }, { background: [] }],
  ["blockquote", "code-block"],
  ["clean"],
];
