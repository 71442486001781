import { Layout11 } from "../../TemplateImageSonak";
export const template11A = {
  KG5oH9aQ: {
    id: "KG5oH9aQ",
    type: "Row5",
    props: {
      values: {
        name: "Row5",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: true,
            align: "middle",
            gutter: 0,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "20px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(208, 2, 27, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 20,
            justify: "center",
            contentAlign: "center",
            contentJustify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "20px 20px 20px 50px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(208, 2, 27, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                bgProps: {
                  show: false,
                },
                padding: "16px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                show: null,
                width: "100%",
                height: "100%",
                layout: {
                  span: 16,
                  order: 1,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px 0px 0px 20px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 2,
                },
                bgProps: {
                  show: false,
                },
                padding: "16px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                show: null,
                width: "100%",
                height: "100%",
                layout: {
                  span: 8,
                  order: 2,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    layout: Layout11,
    childOf: "Row",
    children: ["ymng68sL", "pDKVvfqf"],
    myContent: [
      {
        id: "MWTuhHXz",
        type: "SubcribeView",
        props: {
          values: {
            type: "SubcribeView",
            mobile: {
              show: true,
              button: {
                icon: "fas fa-paper-plane",
                padding: "8px",
                fontSize: 10,
                borderRadius: 24,
                iconPosition: "before",
              },
              layout: {
                type: "split",
                align: "center",
                justify: "center",
                direction: "column",
              },
              colorProps: {
                color: "rgba(0,0,0,1)",
                buttonColor: "rgba(255, 255, 255, 1)",
                backgroundColor: "rgba(255, 255, 255, 1)",
                buttonBackgroundColor: "rgba(0, 0, 0, 1)",
              },
              onSetValue: "10px",
              borderRadius: 24,
              inputFontSize: 10,
              containerPadding: "0px",
            },
            parent: "Content",
            desktop: {
              show: true,
              button: {
                icon: "fas fa-paper-plane",
                padding: "4px 16px 4px 16px",
                fontSize: 10,
                borderRadius: 24,
                iconPosition: "before",
              },
              layout: {
                type: "combine",
                align: "center",
                justify: "center",
                direction: "row",
              },
              colorProps: {
                color: "rgba(0,0,0,1)",
                buttonColor: "rgba(255, 255, 255, 1)",
                backgroundColor: "rgba(255, 255, 255, 1)",
                buttonBackgroundColor: "rgba(0, 0, 0, 1)",
              },
              onSetValue: "8px",
              borderRadius: 24,
              inputFontSize: 10,
              containerPadding: "0px",
            },
            labelButton: "SUBSCRIBE",
            placeholder: "Enter E-mail Address",
            urlSubcribe: "",
          },
        },
        parent: {
          id: "pDKVvfqf",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "pDKVvfqf",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "llePlXUG",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text:
              "<p><strong>Get the latest updates about our products.</strong> Subcribe to Our newsletter now! </p>",
            type: "TextView",
            mobile: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 16,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 15,
              textAlign: "left",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "ymng68sL",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "ymng68sL",
          idx: 0,
          index: 1,
        },
      },
    ],
  },
};
