import React, { PureComponent } from "react"

import { connect } from "../utils"
import BaseWrapper from "./BaseWrapper"

class RootWrapper extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      component: null,
    }
  }

  UNSAFE_componentWillMount() {
    this.wrap(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.id !== this.props.id ||
      nextProps.addons !== this.props.addons
    ) {
      this.wrap(nextProps)
    }
  }

  wrap = props => {
    const type = props.layoutState.getItemJS(props.id).type
    const { values } = props.layoutState.getItemJS(props.id).props
    let component = BaseWrapper(props.components["EmptyView"], "EmptyView")
    if (props.components[type]) {
      component = BaseWrapper(props.components[type], type)
    }

    props.addons.forEach(addon => {
      if (type === "Body") {
        if (addon.Wrapper) {
          component = addon.Wrapper(component, type)
        }
      } else {
        // console.log(props.layoutState.getItemJS(props.id));
        if (values.parent !== undefined && values.parent === "Body") {
          if (addon.WrapperRow) {
            component = addon.WrapperRow(component, type)
          }
        } else {
          if (addon.WrapperContent) {
            component = addon.WrapperContent(component, type)
          }
        }
      }
    })
    this.setState({ component })
  }

  render() {
    const { id, layoutState } = this.props
    const item = layoutState.getItemJS(id)
    const WrappedComponent = this.state.component
    return (
      <WrappedComponent pseudoRef={() => {}} id={id}>
        {React.Children.map(item.children, childId => (
          <Wrapper key={childId} id={childId} />
        ))}
      </WrappedComponent>
    )
  }
}

const Wrapper = connect("layoutState", "components", "addons")(RootWrapper)

export default Wrapper
