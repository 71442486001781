import React from "react";
import { Box } from "rebass";
import styled from "styled-components";
import { connect } from "../../../core";

const Container = styled(Box)`
  display: block;
  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Body = (props) => {
  const { id, children, layoutState } = props;
  const { values } = layoutState.getItemJS(id).props;
  const fullheight =
    values.fullheight && values.fullheight !== null ? { height: "100%" } : {};
  const srcDesktop =
    values.backgroundImage.url || values.backgroundImage.urlMobile || "";
  let important = {
    backgroundColor: values.backgroundColor,
  };
  let scrollContainer = {};
  if (values.isBgImage) {
    important = {
      width: "100%",
      backgroundImage: `url("${srcDesktop}")`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      position: "fixed",
      ...fullheight,
    };
    scrollContainer = {
      overflowY: "scroll",
      position: "relative",
    };
  } else {
    if (values.isGradient) {
      if (values.gradient && values.gradient.type) {
        const { gradient } = values;
        const mydegre =
          gradient.type === "radial-gradient"
            ? "circle"
            : `${gradient.degre}deg`;
        const newBg = {
          background: `${gradient.type}(${mydegre}, ${gradient.bg1} ${gradient.linear[0]}%,${gradient.bg2} ${gradient.linear[1]}%)`,
        };
        important = { background: gradient.bg1, ...fullheight, ...newBg };
        scrollContainer = {
          overflowY: "scroll",
          position: "relative",
        };
      }
    }
  }
  return (
    <Container
      style={{
        ...important,
      }}
    >
      <div
        style={{
          ...scrollContainer,
          padding: values.padding,
          width: "100%",
          height: "100%",
        }}
      >
        {children}
      </div>
    </Container>
  );
};

export default connect("layoutState")(Body);
