import React from "react"
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd"
import Fab from "@material-ui/core/Fab"
import FontIcon from "@material-ui/core/Icon"
import { connect } from "../../core"
import { target } from "./utils"

const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
}) => {
  const { values } = layoutState.getItemJS(id).props
  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "auto",
        display: "flex",
        width: "100%",
        justifyContent: values.buttonAlign,
        flex: 1,
        padding: values.containerPadding,
      }}
    >
      <Fab
        size={values.size}
        disabled={values.disabled}
        style={{ backgroundColor: values.buttonColors.backgroundColor }}
      >
        <FontIcon
          className="material-icons"
          style={{
            fill: values.buttonColors.color,
            color: values.buttonColors.color,
            fontSize: values.sizeIcon,
          }}
        >
          {values.icon}
        </FontIcon>
      </Fab>
    </div>
  )
}

/**
 * note : in here for edit not show the target url for button
 * href={values.href.url}
   target={values.href.target}
 */
const LayoutContainer = DropTarget("FAButtonView", target, (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(EditLayout)

export default connect("layoutState", "readOnly")(LayoutContainer)
