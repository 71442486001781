import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box, Text, Image, Flex } from "rebass";
import { getPhoto } from "./utils";
import { Currency } from "../../../components";
function EnhancedTableHead(props) {
  const { product, classes } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell
          colSpan={3}
          component="th"
          scope="row"
          className={classes.cell}
        >
          <Box width={[1]}>
            <Flex width={[1]} flexDirection={"row"} alignItems="center">
              <Image
                ml={[3]}
                src={getPhoto(product.photos)}
                style={{ width: 50, height: "auto" }}
              />
              <Text pl={[3]}>{get(product, "name", "")}</Text>
            </Flex>
          </Box>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 400,
  },
  cell: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
}));

export default function TableProduct(props) {
  const { product, translate, setSelectedProduct, values } = props;
  const variants = get(product, "variant.variants", []);
  const classes = useStyles();

  const [selected, setSelected] = React.useState(values);

  const handleClick = (event, name, row) => {
    // console.log(row);
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setSelectedProduct({
      product: product,
      sku: name,
      qty: 0,
      variant: row.variant,
    });
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            rowCount={variants.length}
            product={product}
            classes={classes}
          />
          <TableBody>
            {variants.map((row, index) => {
              const isItemSelected = isSelected(row.sku);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.sku, row)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.name}
                  selected={isItemSelected}
                >
                  <TableCell className={classes.cell} />
                  <TableCell id={labelId} className={classes.cell}>
                    <Flex
                      width={[1]}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Text px={[3]}>{get(row, "name", "")}</Text>
                      <Flex flexDirection={"row"}>
                        <Text px={[3]} style={{}}>
                          {get(row, "qty", 0)}{" "}
                          {translate("resources.promotions.labels.available")}
                        </Text>
                        <Text px={[3]}>
                          <Currency value={get(row, "price", 0)} unit={"$"} />
                        </Text>
                      </Flex>
                    </Flex>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
