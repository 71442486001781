import React, { useState } from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import { BlockCounter, LayoutContainer } from "../components";
import { BoxContent } from "../styled";
import { FooterAction, PanelCard, ContainerPanel } from "../actions";
import DialogListMenu from "./DialogListMenu";

const defaultLayout = {
  flexDirection: ["column", "row"],
  justifyContent: ["flex-start", "flex-start"],
  alignItems: ["flex-start", "flex-start"],
};

export default (props) => {
  const { containerPadding, list } = props.data.values;
  const [state, setState] = useState({
    containerPadding: containerPadding,
    list: list,
    layoutRow: get(props, "data.values.layoutRow", defaultLayout),
    heightContent: props.heightContent,
    widthContent: props.widthContent,
  });

  const onSetValue = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };
  const onClickSave = () => {
    let newData = props.data;
    newData.values.list = state.list;
    newData.values.containerPadding = state.containerPadding;
    newData.values.layoutRow = state.layoutRow;
    props.onUpdateData(newData);
  };
  const onClickCopy = () => {
    let newData = props.data;
    newData.values.list = state.list;
    newData.values.containerPadding = state.containerPadding;
    newData.values.layoutRow = state.layoutRow;
    localStorage.setItem("MenuGroupView", JSON.stringify(newData.values));
  };
  const onClickPaste = () => {
    const imageContent = JSON.parse(localStorage.getItem("MenuGroupView"));
    if (imageContent) {
      const { containerPadding, list } = imageContent;
      setState({
        containerPadding: containerPadding,
        list: list,
        layoutRow: get(imageContent, "layoutRow", defaultLayout),
      });
    }
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <BoxContent style={{ height: state.heightContent - 120 }}>
        <ContainerPanel>
          <PanelCard title={`Setting Layout`} id={`properties-setting-layout`}>
            <LayoutContainer
              layoutRow={state.layoutRow}
              onUpdateLayoutRow={(k, v) => onSetValue(k, v)}
            />
          </PanelCard>
          <PanelCard title='General' id='menu-content'>
            <BlockCounter
              title='Container Padding'
              containerPadding={state.containerPadding}
              onUpdatePadding={(cp) => onSetValue("containerPadding", cp)}
            />
          </PanelCard>
          <DialogListMenu
            list={state.list}
            onSetValue={onSetValue}
            {...props}
          />
        </ContainerPanel>
      </BoxContent>
      <FooterAction
        onClickSave={onClickSave}
        onClickCopy={onClickCopy}
        onClickPaste={onClickPaste}
        widthContent={state.widthContent}
      />
    </Flex>
  );
};
