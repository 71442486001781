import React from "react";
//, { Component, Fragment }
// import numeral from "numeral"
import NumberFormat from "react-number-format";
const unitCurrency = { cny: "¥", idr: "Rp", myr: "RM", sgd: "$", thb: "฿" };
// const unitFormat = {
//   cny: "0,0.00",
//   idr: "0,0",
//   myr: "0,0.00",
//   sgd: "0,0.00",
//   thb: "0,0",
// }

// class Currency extends Component {
//   render() {
//     const { value, currency = "thb" } = this.props
//     const nValue = numeral(value)
//     const symbolUnit = unitCurrency[currency.toLowerCase()]
//     numeral.defaultFormat(unitFormat[currency.toLowerCase()])
//     const formatted = nValue.format()
//     return <Fragment>{`${symbolUnit}${formatted}`}</Fragment>
//   }
// }

const Currency = ({ value, currency }) => {
  // const prefix = currency || "thb"
  const myCurrency = currency ? currency : "sgd";
  const symbolUnit = unitCurrency[myCurrency.toLowerCase()];
  return (
    <NumberFormat
      displayType='text'
      thousandSeparator={","}
      decimalSeparator={"."}
      prefix={symbolUnit}
      value={value}
    />
  );
};

export default Currency;
