export default {
  sources: {
    title: "Sources",
    new_title: "New Source",
    edit_title: "Edit Source",
    fields: {
      timezone: "Timezone",
      name: "Name",
      active: "Active",
      type: "Type",
      country: "Country",
      capabilities: "Capabilities",
      address1: "Address1",
      address2: "Address2",
      city: "City",
      state: "State",
      postal_code: "Postal Code",
      latitude: "Latitude",
      longtitude: "Longtitude",
      pick_from_source: "Pickup from this source",
      transfer_from_source: "Transfer from this source",
      ship_from_source: "Ship from this source",
      accepts_return: "Accepts return",
      accepts_exchange: "Accepts exchange",
      address: "Address",
    },
  },
}
