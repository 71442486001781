import React from "react";
// import { ReactiveBase, ReactiveList } from "@appbaseio/reactivesearch";
// import ResultProductCard from "./ResultProductCard";
import { Row, Col } from "antd";
// import { get } from "lodash";
// import { elasticSearchServer } from "../../../../../restClient";
// import ItemProduct from "./ItemProduct";
// const elasticSearchServer = "https://api.staging.commerce.bcxstudio.com/search";
// const sizePage = 12;
export default (props) => {
  const { indexValue } = props;
  //typeContent,state,
  // const listValue = get(state, "contentList.tags", []);
  // const myWidth = indexValue === 1 ? widthScreen / 5 : widthScreen / 2;
  // const showSearch = get(state, `showSearch[${indexValue}]`, false);
  // const limit = get(state, `itemSetting.limit[${indexValue}]`, 50);
  return (
    <Row gutter={0}>
      <Col span={indexValue === 1 ? 8 : 12}>Content Product</Col>
    </Row>
    // <ReactiveBase
    //   app='product'
    //   url={elasticSearchServer}
    //   theme={{
    //     typography: {
    //       fontFamily: "Roboto, Helvetica, sans-serif",
    //     },
    //     colors: {
    //       primaryColor: "#008000",
    //       titleColor: "white",
    //     },
    //     component: {
    //       padding: 10,
    //     },
    //   }}
    // >
    //   <ReactiveList
    //     componentId='results'
    //     dataField='name'
    //     size={limit}
    //     className='product-list-reactactive'
    //     showResultStats={false}
    //     infiniteScroll={false}
    //     defaultQuery={() => {
    //       return {
    //         query: {
    //           bool: {
    //             should: [
    //               {
    //                 terms: {
    //                   "tags.keyword": listValue,
    //                 },
    //               },
    //             ],
    //             minimum_should_match: "1",
    //           },
    //         },
    //       };
    //     }}
    //     render={({ data }) => (
    //       <ReactiveList.ResultCardsWrapper style={{ justifyContent: "left" }}>
    //         {/* {data.map((item) => {
    //           return <ResultProductCard item={item} key={item._id} />;
    //         })} */}
    //         <Row gutter={0}>
    //           {data.map((item) => {
    //             return (
    //               <Col
    //                 key={item._id}
    //                 span={indexValue === 1 ? 8 : 12}
    //                 style={{ display: "flex", padding: 8 }}
    //               >
    //                 <ItemProduct
    //                   indexValue={indexValue}
    //                   item={item}
    //                   modeType={typeContent}
    //                   colorProps={get(state, "colorProps", {})}
    //                   itemSetting={get(state, "itemSetting", {})}
    //                   showPrice={get(state, `showPrice[${indexValue}]`, true)}
    //                 />
    //               </Col>
    //             );
    //           })}
    //         </Row>
    //       </ReactiveList.ResultCardsWrapper>
    //     )}
    //     pagination={false}
    //     renderError={(error) => <div />}
    //   />
    // </ReactiveBase>
  );
};
