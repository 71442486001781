import React from "react";
import { required, NumberInput } from "react-admin";
import { Box, Flex } from "rebass";
import {
  BetterCustomSimpleForm,
  RaBooleanInput,
  RaTextInput,
  EditToolbar,
} from "../../../components";
// import Settings from "./Settings";
// import Conditions from "./Conditions";

const FormFields = (props) => {
  const { title } = props;

  return (
    <BetterCustomSimpleForm
      {...props}
      title={title}
      toolbar={<EditToolbar />}
      isShowBack={false}
    >
      <Box width={[1]}>
        <RaTextInput
          source="name"
          label={"resources.shippings.fields.name"}
          validate={required()}
          style={{ width: 320 }}
        />
        <Box width={[1]} pt={[2]}>
          <RaBooleanInput
            source="active"
            label={"resources.shippings.fields.active"}
          />
          <NumberInput
            source="weight"
            label={"resources.shippings.fields.volume"}
            style={{ width: 200 }}
          />
        </Box>
        <Flex
          width={[1]}
          flexDirection={"row"}
          flexWrap={"wrap"}
          sx={{
            "&>div": {
              pr: [3],
            },
          }}
        >
          <NumberInput
            source="height"
            label={"resources.shippings.fields.height"}
          />
          <NumberInput
            source="length"
            label={"resources.shippings.fields.length"}
          />
          <NumberInput
            source="width"
            label={"resources.shippings.fields.width"}
          />
        </Flex>
        {/* <Box width={[1]} pt={[2]}>
          <SelectInput
            source="provider"
            label={"resources.shippings.fields.provider"}
            choices={[
              { id: "Grab", name: "Grab" },
              { id: "Uber", name: "Uber" },
            ]}
            style={{ width: 256 }}
          />
        </Box> */}

        {/* <Box width={[1]} pt={[2]}>
          <Settings {...props} />
        </Box>
        <Box width={[1]} pt={[2]}>
          <Conditions {...props} />
        </Box> */}
      </Box>
    </BetterCustomSimpleForm>
  );
};
export default FormFields;
