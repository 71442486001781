import React from "react";
import { required, SelectInput, ReferenceInput } from "react-admin";
import { Box } from "rebass";
import { get } from "lodash";
import {
  RaBooleanInput,
  RaTextInput,
  RaSelectArrayChipInput,
} from "../../../components";
import NotificationFields from "./NotificationFields";
// import { ScheduleList } from "./ScheduleList"
// import ScheduleWebsite from "./ScheduleWebsite"
import { dataCountry } from "./data";

export const FormFields = (props) => {
  const { isCreate, initialValues, formValues, updateField } = props;
  const siteId = get(initialValues, "site_id", 0)
    ? { site_id: get(initialValues, "site_id", 0) }
    : {};
  return (
    <Box width={[1]} p={[isCreate ? 2 : 3]}>
      <RaTextInput source='name' validate={required()} style={{ width: 256 }} />
      <RaBooleanInput source='active' />
      {/* <SelectArrayInput source='domain' style={{ width: 500 }} /> */}
      <RaSelectArrayChipInput
        label={"resources.websites.fields.domain"}
        source='domain'
        updateField={updateField}
        fullWidth={true}
        record={initialValues}
        formData={formValues}
      />
      <Box width={[1]}>
        <SelectInput
          source='country'
          label='resources.websites.fields.country'
          choices={dataCountry}
          style={{ width: 256 }}
        />
      </Box>
      <RaBooleanInput
        source='auto_detect_country'
        label={"resources.websites.fields.auto_detect_country"}
      />
      {/* <Box width={[1]}>
          <SelectInput
            source="layout"
            choices={dataLayout}
            style={{ width: 256 }}
          />
        </Box> */}
      <ReferenceInput
        label='resources.websites.fields.inventory_source_group'
        source='source_group_id'
        reference='sourceGroups'
        filter={siteId}
      >
        <SelectInput optionText='name' style={{ width: 300 }} />
      </ReferenceInput>
      <NotificationFields {...props} />
    </Box>
  );
};
