export default {
  contents: {
    name: "Contents",
    new_page: "New Page",
    edit_page: "Edit Page",
    fields: {
      title: "Title",
      active: "Active",
      site_id: "Site",
      slug: "Slug",
      tags: "Tags",
      edit_page_design: "Edit Page Design",
      website: "Website",
      start_date: "Start Date",
      end_date: "End Date",
    },
    message: {
      title_reset: "Are you sure want to reset?",
      content_reset: "The Page will be blank if you reset page",
    },
    note_text: {
      slug: "Content Url",
      tags:
        "Tag your page such as Info, FAQ, to help to group and sort the page",
    },
  },
};
