import React, { Component } from "react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";
import styled from "styled-components";
import { Flex, Box } from "rebass";

class SketchColorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      color: props.color,
      result: props.color,
    };
  }
  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    let { rgb } = color;
    let newColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`;
    this.setState({ color: color.rgb, result: newColor });
  };
  handleChangeComplete = (color, event) => {
    let { rgb } = color;
    let newColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`;
    this.props.onUpdateColor(newColor);
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.color !== nextProps.color) {
      this.setState({ color: nextProps.color, result: nextProps.color });
    }
  }

  render() {
    const { title } = this.props;

    return (
      <Flex
        width={[1]}
        flexDirection={["column"]}
        style={{ position: "relative" }}
      >
        <Flex
          width={[1]}
          flexDirection={["row"]}
          justifyContent={"space-between"}
        >
          <Title>{title}</Title>
          <Box p={[2]}>
            <BoxColor onClick={this.handleClick}>
              <ContentColor style={{ backgroundColor: this.state.result }} />
            </BoxColor>
          </Box>
        </Flex>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              color={this.state.color}
              onChange={this.handleChange}
              onChangeComplete={this.handleChangeComplete}
            />
          </div>
        ) : null}
      </Flex>
    );
  }
}

const styles = {
  swatch: {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
  },
  popover: {
    position: "absolute",
    zIndex: 99999,
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  iconButton: {
    width: 48,
    height: 48,
  },
};

const Title = styled.span`
  align-items: center;
  display: flex;
`;
const BoxColor = styled.div`
  background-color: rgb(255, 255, 255);
  display: inline-block;
  cursor: pointer;
  box-shadow: rgb(239, 239, 239) 0px 0px 0px 2px inset !important;
  padding: 2px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  border-image: initial;
`;

const ContentColor = styled.div`
  width: 25px;
  border-radius: 3px;
  height: 25px;
`;

SketchColorPicker.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  onUpdateColor: PropTypes.func.isRequired,
};
//
SketchColorPicker.defaultProps = {
  color: "#4A90E2",
  title: "Background Color ",
};

export default SketchColorPicker;
