import { Flex } from "rebass";
import styled from "styled-components";

export const BoxOverlay = styled.div`
  display: flex;
  justify-content: flex-start;
  top: 20px;
  left: 20px;
  position: absolute;
`;

export const ProductInfo = styled(Flex)`
  flex: 1;
  padding: 0em;
  margin-top: 20px;
  padding-top: 8px;
  min-height: 3rem;
  line-height: 1.3rem;
`;

export const ContainerCard = styled(Flex)`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 0.25rem;
  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
`;

export const BoxOverlayFav = styled.div`
  display: flex;
  justify-content: flex-end;
  top: 20px;
  right: 20px;
  position: absolute;
`;

export const BoxOverlayOutOfStock = styled(Flex)`
  flex: 1;
  width: 100%;
  height: 100%;
  padding-left: 0px;
  padding-right: 0px;
  top: 0;
  position: absolute;
`;
