import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout10 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const ProductListView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='ProductListView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

ProductListView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};

ProductListView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "ProductListView",
    containerPadding: ["0px", "0px"],
    gutter: [0, 0],
    isShow: [true, true],
    showPrice: [true, true],
    productLaunchDate: "",
    showPagination: [true, true],
    showSearch: [true, true],
    columnProduct: [12, 4],
    colorProps: {
      backgroundColor: "rgba(255,255,255,0)",
      color: "rgba(0,0,0,1)",
      viewallColor: "rgba(0,0,0,1)",
      hoverColor: "rgba(0,0,0,1)",
      hoverBackgroundColor: "rgba(255,255,255,0)",
    },
    itemSetting: {
      limit: [100, 100],
      titleFontSize: [12, 12],
      subFontSize: [12, 12],
      titlePadding: ["0px", "0px"],
      containerPadding: ["0px", "0px"],
      subPadding: ["0px", "0px"],
      modeImg: ["contain", "contain"],
    },
    contentList: {
      type: "Category",
      value: "",
      tags: [],
      products: [],
    },
  },
  layout: Layout10,
};
export default ProductListView;
