import React from "react";
import { Button } from "rebass";
import { isEmpty, get } from "lodash";
import FontIcon from "@material-ui/core/Icon";
export const defaultBorder = {
  type: "solid",
  size: 0,
  color: "#e61919",
};

export const ButtonView = ({
  typeButton,
  fullWidth,
  buttonColors,
  isBorder,
  newChildIcon,
  labelPosition,
  text,
  defaultBorderSize,
  borderRadius,
}) => {
  if (typeButton === "raised") {
    const buttonBg = get(buttonColors, "backgroundColor", "transparent");
    return (
      <Button
        bg={buttonBg}
        style={styleCheckFullWidth(fullWidth, buttonColors, isBorder, {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderRadius: borderRadius,
        })}
      >
        {labelPosition === "before" && `${text}`}
        {!isEmpty(newChildIcon) && (
          <FontIcon className='material-icons'> {newChildIcon} </FontIcon>
        )}
        {labelPosition !== "before" && `${text}`}
      </Button>
    );
  } else if (typeButton === "flat") {
    return (
      <Button
        style={styleCheckFullWidth(fullWidth, buttonColors, isBorder, {
          backgroundColor: "transparent",
          color: get(buttonColors, "color", "#000000"),
          boxShadow: `inset 0 0 0 ${defaultBorderSize}px`,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderRadius: borderRadius,
        })}
      >
        {labelPosition === "before" && `${text}`}
        {!isEmpty(newChildIcon) && (
          <FontIcon className='material-icons'> {newChildIcon} </FontIcon>
        )}
        {labelPosition !== "before" && `${text}`}
      </Button>
    );
  } else {
    return <div />;
  }
};

const styleCheckFullWidth = (fullWidth, buttonColors, isBorder, addStyle) => {
  if (fullWidth) {
    return {
      height: "auto",
      lineHeight: "200%",
      color: get(buttonColors, "color", "#000000"),
      textTransform: "none",
      border: isBorder,
      width: "100%",
      ...addStyle,
    };
  }
  return {
    height: "auto",
    lineHeight: "200%",
    color: get(buttonColors, "color", "#000000"),
    textTransform: "none",
    border: isBorder,
    ...addStyle,
  };
};
