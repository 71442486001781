import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import { SelectInput } from "../";
import { ContentDetail } from "../../actions";
import { listDirection, listAlignment } from "./utils";

export default (props) => {
  const { onSetValue, state, contentId } = props;
  const onSetValueLayout = (k, v) => {
    const newLayout = {
      ...state,
      [k]: v,
    };
    onSetValue(newLayout);
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <ContentDetail>
        <SelectInput
          id={`direction${contentId}`}
          title={"Direction"}
          value={get(state, "direction", "column")}
          list={listDirection}
          onChange={(e) => onSetValueLayout("direction", e.target.value)}
        />
      </ContentDetail>
      <ContentDetail>
        <SelectInput
          id={`align${contentId}`}
          title={"AlignItems"}
          value={get(state, "align", "flex-start")}
          list={listAlignment}
          onChange={(e) => onSetValueLayout("align", e.target.value)}
        />
      </ContentDetail>
      <ContentDetail>
        <SelectInput
          id={`justify${contentId}`}
          title={"Justify"}
          value={get(state, "justify", "flex-start")}
          list={listAlignment}
          onChange={(e) => onSetValueLayout("justify", e.target.value)}
        />
      </ContentDetail>
    </Flex>
  );
};
