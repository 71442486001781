import React, { Component } from "react"
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd"
import ContainerDimensions from "react-container-dimensions"
import { connect } from "../../core"
const target = {
  drop(props, monitor, component) {
    if (!monitor.didDrop()) {
      const { values } = monitor.getItem().props
      const hoverType = props.type
      if (values.type !== hoverType) {
        props.layoutState.insertOrMoveItem(
          props.id,
          props.children.length,
          monitor.getItem()
        )
      }
    }
  },
}

const bgPropertiesDefault = {
  size: "cover",
  position: "center center",
  repeat: "no-repeat",
  ratio: 1.2,
}
const renderWithBackgroundImage = (values, children, contentWidth) => {
  const bgProps = values.bgProps ? values.bgProps : bgPropertiesDefault
  const srcMobile =
    values.backgroundImage.urlMobile || values.backgroundImage.url || ""
  const srcDesktop =
    values.backgroundImage.url || values.backgroundImage.urlMobile || ""
  const important = {
    backgroundImage: `url("${srcDesktop}")`,
    backgroundSize: bgProps.size,
    backgroundPosition: bgProps.position,
    backgroundRepeat: bgProps.repeat,
  }
  return (
    <ContainerDimensions>
      {({ width, height }) => {
        const minHeight = width / bgProps.ratio
        if (width < 768) {
          important.backgroundImage = `url("${srcMobile}")`
        }
        return (
          <div
            style={{
              ...important,
              minHeight: minHeight,
              display: "flex",
              width: "inherit",
              maxWidth: contentWidth,
            }}
          >
            <div
              style={{
                width: "inherit",
                display: "flex",
                alignItems: "stretch",
                flexWrap: "wrap",
              }}
            >
              {children}
            </div>
          </div>
        )
      }}
    </ContainerDimensions>
  )
}

const renderWithBackgroundColor = (values, children, contentWidth) => {
  return (
    <ContainerDimensions>
      {({ width, height }) => {
        return (
          <div style={{ maxWidth: contentWidth, width: "inherit" }}>
            <div
              style={{
                width: "inherit",
                display: "flex",
                alignItems: "stretch",
                flexWrap: "wrap",
              }}
            >
              {children}
            </div>
          </div>
        )
      }}
    </ContainerDimensions>
  )
}

class EditLayout extends Component {
  UNSAFE_componentWillMount() {
    const { id, children, layoutState } = this.props
    const { content } = layoutState.getItemJS(id).props

    if (content.length === 1 && children.length === 0) {
      let itemChildren = content[children.length]
      if (itemChildren.id === undefined) {
        layoutState.insertOrMoveItem(id, children.length, itemChildren)
      }
    }
  }

  render() {
    //isOverCurrent,type,
    const { id, children, connectDropTarget, layoutState } = this.props
    const { values } = layoutState.getItemJS(id).props
    const contentWidth = values.width.contentWidth + values.width.type
    const isBackgroundImage = values.isBackgroundImage
      ? renderWithBackgroundImage(values, children, contentWidth)
      : renderWithBackgroundColor(values, children, contentWidth)

    let important = { backgroundColor: values.backgroundColor }
    if (values.isGradient) {
      if (values.gradient && values.gradient.type) {
        const { gradient } = values
        const mydegre =
          gradient.type === "radial-gradient"
            ? "circle"
            : `${gradient.degre}deg`
        const newBg = {
          background: `${gradient.type}(${mydegre}, ${gradient.bg1} ${gradient.linear[0]}%,${gradient.bg2} ${gradient.linear[1]}%)`,
        }
        important = { background: gradient.bg1, height: "100%", ...newBg }
      }
    }
    return connectDropTarget(
      <div
        style={{
          boxSizing: "border-box",
          position: "relative",
          height: "100%",
          padding: values.padding,
          minHeight: children.length > 1 ? null : 30,
          minWidth: children.length > 1 ? null : 30,
          display: "flex",
          flex: 1,
          width: "inherit",
          justifyContent: "center",
          ...important,
        }}
      >
        {children.length > 0 && isBackgroundImage}
      </div>
    )
  }
}

const LayoutContainer = DropTarget("Body", target, (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(EditLayout)

export default connect("layoutState", "readOnly")(LayoutContainer)

// export default LayoutContainer;s
