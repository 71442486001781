import React, { Component } from "react"
import PropTypes from "prop-types"
import { translate } from "react-admin"
import { Box, Text } from "rebass"

import { grey } from "@material-ui/core/colors"
import { inputStyles } from "../../themes"

class PanelView extends Component {
  render() {
    const { translate, children, title, width } = this.props
    const isWidth = width
      ? { width: width, border: `1px solid ${grey[300]}` }
      : { border: `1px solid ${grey[300]}` }
    return (
      <Box pb={[2]}>
        <Text
          py={[2]}
          fontSize={12}
          fontWeight={"bold"}
          style={{ letterSpacing: inputStyles.letterSpacing }}
        >
          {/* {translate("resources.earningRules.title.effective_dates")} */}
          {translate(title)}
        </Text>
        <Box width={[1]} p={[3]} style={isWidth}>
          {children}
        </Box>
      </Box>
    )
  }
}

PanelView.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
}

export default translate(PanelView)
