import React, { useState, Fragment } from "react";
import { Flex, Box, Text } from "rebass";
import { filter } from "lodash";
import DialogEditContent from "./DialogEditContent";
import { IconButton } from "@material-ui/core";
import ActionAdd from "@material-ui/icons/AddCircle";
import { generateRandomKey } from "../../../core/utils";
import { defaultText } from "./utils";
import DialogSelectedItem from "./DialogSelectedItem";
export default (props) => {
  const { list, onSetValue } = props;
  // const [myList, setMyList] = useState(list);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const onAddItem = () => {
    const newItem = {
      typeContent: "text",
      id: generateRandomKey(),
      content: defaultText,
    };
    let newList = list;
    newList.push(newItem);
    // setMyList(newList);
    onSetValue("list", newList);
  };

  const handleItem = (value, id) => {
    const newList = list.map((item) => {
      if (item.id === id) {
        return value;
      } else {
        return item;
      }
    });
    // const newList = filter(list, function (o) {
    //   return o.id === id;
    // });

    // newList.push(value);
    // newList.map((item) => {
    //   if (item.id === value.id) {
    //     return value;
    //   } else {
    //     return item;
    //   }
    // });
    // newList[index] = value;
    onSetValue("list", newList);
    // setMyList(newList);
  };
  const onDelete = (id) => {
    const newList = filter(list, function (o) {
      return o.id !== id;
    });
    // arrayRemove(list, index);
    onSetValue("list", newList);
    // setMyList(newList);
  };
  const onSelectedItem = (item) => {
    if (item && item.id) {
      setSelectedItem(item);
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const onCloseDialog = () => {
    setOpen(false);
    setSelectedItem(null);
  };
  return (
    <Fragment>
      <Flex width={[1]} flexDirection={"column"}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={[1]}
        >
          <Box>
            <Text>List Content</Text>
          </Box>
          <Box>
            <IconButton
              onClick={() => onAddItem()}
              style={{ color: "rgb(0, 188, 212)" }}
            >
              <ActionAdd color={"inherit"} />
            </IconButton>
          </Box>
        </Flex>
        <Box width={[1]}>
          {list &&
            list.length > 0 &&
            list.map((item1, index) => {
              return (
                <DialogEditContent
                  item={item1}
                  key={index}
                  index={index}
                  onDelete={() => onDelete(item1.id)}
                  websiteId={props.websiteId}
                  onSelectedItem={(item) => onSelectedItem(item)}
                />
              );
            })}
        </Box>
      </Flex>
      {open && (
        <DialogSelectedItem
          item={selectedItem}
          onEditItem={(v) => handleItem(v, selectedItem.id)}
          websiteId={props.websiteId}
          onSelectedItem={(v) => onSelectedItem(v)}
          open={open}
          setOpen={(v) => setOpen(v)}
          onCloseDialog={() => onCloseDialog()}
        />
      )}
    </Fragment>
  );
};
