import React from "react"
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd"
import Tooltip from "@material-ui/core/Tooltip"

import IconButton from "@material-ui/core/IconButton"
import FontIcon from "@material-ui/core/Icon"
import { target } from "./utils"
import { connect } from "../../core"

const checkStyles = (padding, color) => {
  let iconS = {
    borderRadius: "50%",
    backgroundColor: color,
    padding: padding,
  }
  // if (size === "large") {
  //   iconS.width = 120
  //   iconS.height = 120
  //   iconS.padding = 30
  // } else if (size === "medium") {
  //   iconS.width = 96
  //   iconS.height = 96
  //   iconS.padding = 24
  // } else if (size === "small") {
  //   iconS.width = 72
  //   iconS.height = 72
  //   iconS.padding = 16
  // }
  return iconS
}

const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
}) => {
  const { values } = layoutState.getItemJS(id).props
  const isDisableTooltip = values.tooltip.length > 0 ? false : true

  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "auto",
        display: "flex",
        width: "100%",
        justifyContent: values.buttonAlign,
        flex: 1,
        padding: values.containerPadding,
      }}
    >
      {isDisableTooltip ? (
        <IconButton
          disabled={values.disabled}
          style={checkStyles(
            values.paddingIcon,
            values.buttonColors.backgroundColor
          )}
        >
          <FontIcon
            className="material-icons"
            fontSize={values.size}
            style={{
              color: values.buttonColors.color,
              fontSize: values.sizeIcon,
            }}
          >
            {values.icon}
          </FontIcon>
        </IconButton>
      ) : (
        <Tooltip title={values.tooltip} placement={values.tooltipPosition}>
          <IconButton
            disabled={values.disabled}
            style={checkStyles(
              values.paddingIcon,
              values.buttonColors.backgroundColor
            )}
          >
            <FontIcon
              className="material-icons"
              fontSize={values.size}
              style={{
                color: values.buttonColors.color,
                fontSize: values.sizeIcon,
              }}
            >
              {values.icon}
            </FontIcon>
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}
//hoverColor={values.buttonColors.hoverColor}
// const styles = {
//   xsmallIcon: {
//     fontSize: 24,
//   },
//   smallIcon: {
//     width: 36,
//     height: 36,
//     fontSize: 36,
//   },
//   mediumIcon: {
//     width: 48,
//     height: 48,
//     fontSize: 48,
//   },
//   largeIcon: {
//     width: 60,
//     height: 60,
//     fontSize: 60,
//   },
// }

/**
 * note : in here for edit not show the target url for button
 * href={values.href.url}
   target={values.href.target}
 */
const LayoutContainer = DropTarget(
  "IconButtonView",
  target,
  (conn, monitor) => ({
    connectDropTarget: conn.dropTarget(),
    isOverCurrent: monitor.isOver({ shallow: true }),
  })
)(EditLayout)

export default connect("layoutState", "readOnly")(LayoutContainer)
