import { Layout18 } from "../../TemplateImageSonak";
export const template15A = {
  "M/z+YjbB": {
    id: "M/z+YjbB",
    type: "Row2",
    props: {
      values: {
        name: "Row2",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: true,
            align: "top",
            gutter: 20,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "20px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "1080px",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 20,
            justify: "center",
            contentAlign: "center",
            contentJustify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 2,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 1,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px 30px 0px 0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-O11.jpeg",
                    size: "cover",
                    ratio: 1.5,
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 2,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-O11.jpeg",
                    size: "cover",
                    ratio: 1.5,
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    parent: {
      id: "root",
      idx: 0,
    },
    layout: Layout18,
    childOf: "Row",
    children: ["7yauca0X", "z8YQGBP/"],
    myContent: [
      {
        id: "w2a5+gKt",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>OUR VISION</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 16,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px 0px 16px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 18,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px 0px 16px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "7yauca0X",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "7yauca0X",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "9Hbvz72d",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text:
              "<p>To be the No1 online retailer of quality sporting equipment and inspire a healthy lifestyle for all .</p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px 0px 20px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px 0px 20px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "7yauca0X",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "+VeSVP5V",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "uq/AhE23",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>OUR MISSION</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 16,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px 0px 16px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 18,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px 0px 16px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "7yauca0X",
          idx: 2,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "7yauca0X",
          idx: 2,
          index: 1,
        },
      },
      {
        id: "JW/y0Cz2",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text:
              "<p>To provide the best quality sporting products across different customer segments and sports categories. regionally</p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px 0px 20px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px 0px 20px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },

        parent: {
          id: "7yauca0X",
          idx: 3,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "7yauca0X",
          idx: 3,
          index: 1,
        },
      },
    ],
  },
};
