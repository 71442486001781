import React from "react";
import { Flex } from "rebass";
import {
  BlockCounter,
  Title,
  Counter,
  Alignment,
  SwitchInput,
} from "../components";
import { get } from "lodash";
import { ContentDetail } from "../actions";
export default (props) => {
  const { state, onSetValue, contentId } = props;
  const show = state && state.show !== null ? get(state, "show", false) : false;
  const size = state && state.size !== null ? get(state, "size", 10) : 10;
  const align =
    state && state.align !== null ? get(state, "align", "center") : "center";
  const containerPadding =
    state && state.containerPadding !== null
      ? get(state, "containerPadding", "0px")
      : "0px";
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <ContentDetail>
        <SwitchInput
          id={`show-social-media-${contentId}`}
          title={"Show on Screen"}
          checked={show}
          onChange={(e) => onSetValue("show", e.target.checked)}
        />
        <Title title='Size' />
        <Counter
          onSetValue={(value) => onSetValue("size", value)}
          value={size}
          interval={1}
          subtitle='px'
          minValue={10}
        />
      </ContentDetail>
      <ContentDetail>
        <Alignment
          onChange={(value) => onSetValue("align", value)}
          value={align}
          name='align'
          title={"Alignment"}
        />
      </ContentDetail>
      <BlockCounter
        title='Container Padding'
        containerPadding={containerPadding}
        onUpdatePadding={(cp) => onSetValue("containerPadding", cp)}
      />
    </Flex>
  );
};
