import React, { useState } from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import { BlockCounter, Counter, Title } from "../components";
import { BoxContent } from "../styled";
import ItemColors from "../components/bgContent/ItemColors";
import { FooterAction, PanelCard, ContainerPanel } from "../actions";
/*
values: {
    containerPadding: ["0px", "0px"],
    colorProps: {
      color: ["#000000", "#000000"],
      backgroundColor: ["#FFFFFF", "#FFFFFF"],
      hoverColor: ["rgba(255,255,255,0)", "rgba(255,255,255,0)"],
      hoverBgColor: ["rgba(255,255,255,0)", "rgba(255,255,255,0)"],
    },
    model: 0,
  },
*/

export default (props) => {
  const { heightContent, data } = props;

  const [state, setState] = useState({
    fontSize: get(data, "values.fontSize", [10, 12]),
    containerPadding: get(data, "values.containerPadding", ["0px", "0px"]),
    colorProps: get(data, "values.colorProps", {
      color: ["#000000", "#000000"],
      backgroundColor: ["#FFFFFF", "#FFFFFF"],
      hoverColor: ["rgba(255,255,255,0)", "rgba(255,255,255,0)"],
      hoverBgColor: ["rgba(255,255,255,0)", "rgba(255,255,255,0)"],
    }),
    model: get(data, "values.model", 0),
  });
  const onSetValueDesktop = (k, v) => {
    let myContent = state[k];
    myContent[1] = v;
    setState({
      ...state,
      [k]: myContent,
    });
  };
  const onSetValueMobile = (k, v) => {
    let myContent = state[k];
    myContent[0] = v;
    setState({
      ...state,
      [k]: myContent,
    });
  };

  const onSetValue = (k, v) => {
    console.log(k, v);
    setState({
      ...state,
      [k]: v,
    });
  };

  const onClickCopy = () => {
    let newData = props.data;
    newData.values.containerPadding = state.containerPadding;
    newData.values.colorProps = state.colorProps;
    newData.values.model = state.model;
    newData.values.fontSize = state.fontSize;
    localStorage.setItem("MenuContentView", JSON.stringify(newData.values));
  };
  const onClickPaste = () => {
    const menuContent = JSON.parse(localStorage.getItem("MenuContentView"));
    if (menuContent) {
      setState({
        ...state,
        containerPadding: menuContent.containerPadding,
        colorProps: menuContent.colorProps,
        model: menuContent.model,
        fontSize: menuContent.fontSize || [12, 10],
      });
    }
  };
  const onClickSave = () => {
    let newData = props.data;
    newData.values.containerPadding = state.containerPadding;
    newData.values.colorProps = state.colorProps;
    newData.values.model = state.model;
    newData.values.fontSize = state.fontSize;
    props.onUpdateData(newData);
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <BoxContent style={{ height: heightContent - 120 }}>
        <ContainerPanel>
          <PanelCard title='Desktop' id='desktop-content'>
            <BlockCounter
              title='Container Padding'
              containerPadding={state.containerPadding[1]}
              onUpdatePadding={(cp) =>
                onSetValueDesktop("containerPadding", cp)
              }
            />
            <Title title={"FontSize"} />
            <Counter
              onSetValue={(value) => onSetValueDesktop("fontSize", value)}
              value={get(state, "fontSize[1]", 12)}
              interval={1}
              subtitle='pt'
              minValue={5}
            />
          </PanelCard>
          <PanelCard title='Mobile' id='mobile-content'>
            <BlockCounter
              title='Container Padding'
              containerPadding={state.containerPadding[0]}
              onUpdatePadding={(cp) => onSetValueMobile("containerPadding", cp)}
            />
            <Title title={"FontSize"} />
            <Counter
              onSetValue={(value) => onSetValueMobile("fontSize", value)}
              value={get(state, "fontSize[0]", 10)}
              interval={1}
              subtitle='pt'
              minValue={5}
            />
          </PanelCard>
        </ContainerPanel>
        <PanelCard title='Color Properties' id='colorProps-content'>
          <ItemColors
            colorProps={state.colorProps}
            onUpdateColor={(k, v) => onSetValue(k, v)}
          />
        </PanelCard>
      </BoxContent>
      <FooterAction
        onClickSave={onClickSave}
        onClickCopy={onClickCopy}
        onClickPaste={onClickPaste}
        widthContent={props.widthContent}
      />
    </Flex>
  );
};
