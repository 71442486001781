import React, { Fragment } from "react";
import { Flex, Box } from "rebass";
import {
  FormDataConsumer,
  useTranslate,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from "react-admin";
import DateInputFields from "./DateInputFields";
// import { Field } from "react-final-form";
import { RaBooleanInput } from "../../../components";
import { get } from "lodash";
import ProductFields from "./ProductFields";
const FormFields = (props) => {
  const translate = useTranslate();
  return (
    <Flex flexDirection={"column"}>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <Fragment>
              <Flex>
                <DateInputFields {...props} />
              </Flex>
              <Box>
                <RaBooleanInput source='active' id={"active"} />
              </Box>
              <Box>
                <ReferenceArrayInput
                  label='resources.stores.name'
                  reference='stores'
                  source='stores'
                  resource={"stores"}
                  perPage={80}
                  sort={{ field: "name", order: "ASC" }}
                  filterToQuery={(searchText) => ({ search: searchText })}
                  fullWidth={true}
                  filter={{ active: true }}
                >
                  <AutocompleteArrayInput
                    optionText={(record) => {
                      return `${get(record, "name", "")}`;
                    }}
                    optionValue={"id"}
                  />
                </ReferenceArrayInput>
              </Box>

              <ProductFields
                {...props}
                translate={translate}
                formData={formData}
              />
            </Fragment>
          );
        }}
      </FormDataConsumer>
    </Flex>
  );
};

export default FormFields;
