import React from "react"
import { Flex } from "rebass"
import { get } from "lodash"
import { ProductGroupCard } from "../../../../components"
import ProductGroupCarousel from "./ProductGroupCarousel"
import { DivEmptyContent } from "../../styled"

const ProductGroup = (props) => {
  const { data, widthContainer } = props
  const products = get(data, "products", [])

  // console.log(widthContainer, data)
  if (products.length === 0) {
    return (
      <DivEmptyContent>
        <span>No content yet. Click here to insert product items</span>
      </DivEmptyContent>
    )
  }
  if (products.length > 4) {
    return <ProductGroupCarousel data={data} widthContainer={widthContainer} />
  }
  return (
    <Flex width={[1]} flexWrap={"wrap"}>
      {products.length > 0 &&
        products.map((item, index) => {
          return (
            <Flex
              key={index}
              p={[2]}
              width={[1 / 2]}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <ProductGroupCard product={item} />
            </Flex>
          )
        })}
    </Flex>
  )
}

export default ProductGroup
