import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "rebass";
import { get, lowerCase } from "lodash";
import {
  FooterAction,
  ContainerPanel,
  PanelCard,
  ContentDetail,
} from "../actions";
import { BoxContent } from "../styled";
import {} from "../components";
import {
  ListGroup,
  TextInput,
  SelectInput,
  HrefContent,
  SelectInputCollection,
  SelectInputProducts,
  Title,
} from "../components";
import { SwitchInputView } from "../components/switch";
// import { defaultDataList } from "./datalist";
// import { ListSlideFields } from "./Fields";
import ColorsContent from "./ColorsContent";
import GeneralContent from "./GeneralContent";
import { defaultListSlideV } from "./utils";
import { CategoryList } from "./product";
import { useQuery } from "../../../../components";
import { getSiteID } from "../../../../restClient";
import ItemSetting from "./ItemSetting";
const defaultItemSetting = {
  limit: [20, 20],
  titleFontSize: [12, 12],
  subFontSize: [12, 12],
  titlePadding: ["0px", "0px"],
  containerPadding: ["0px", "0px"],
  subPadding: ["0px", "0px"],
};

const defaultModeType = [
  { id: "Category", name: "Category" },
  { id: "Collection", name: "Collection" },
  { id: "List", name: "List" },
  { id: "Product", name: "Product" },
];
//

const ListSlideView = (props) => {
  // console.log(props);
  const { categories, collections } = props;
  const { values } = props.data;
  const [state, setState] = useState({
    modeType: get(values, "modeType", defaultListSlideV.modeType),
    desktop: get(values, "desktop", defaultListSlideV.desktop),
    mobile: get(values, "mobile", defaultListSlideV.mobile),
    valueText: get(values, "valueText", []),
    title: get(values, "title", ""),
    titleViewAll: get(values, "titleViewAll", "View All"),
    linkViewAll: get(values, "linkViewAll", {
      target: "",
      type: "URL",
      value: "",
      isLink: false,
    }),
    contentList: get(values, "contentList", {
      showPrice: [true, true],
      value: "",
      tags: [],
      collectionId: null,
    }),
    colorProps: get(values, "colorProps", defaultListSlideV.colorProps),
    itemSetting: get(values, "itemSetting", defaultItemSetting),
  });

  const onSetValue = (k, v) => {
    setState({
      ...state,
      [k]: v,
    });
  };

  const onSetDesktopValue = (k, v) => {
    const newDesktop = {
      ...state.desktop,
      [k]: v,
    };
    setState({
      ...state,
      desktop: newDesktop,
    });
  };
  const onSetMobileValue = (k, v) => {
    const newMobile = {
      ...state.mobile,
      [k]: v,
    };
    setState({
      ...state,
      mobile: newMobile,
    });
  };

  const onClickCopy = () => {
    let newData = props.data;
    newData.values.valueText = state.valueText;
    newData.values.desktop = state.desktop;
    newData.values.mobile = state.mobile;
    newData.values.modeType = state.modeType;
    newData.values.title = state.title;
    newData.values.titleViewAll = state.titleViewAll;
    newData.values.colorProps = state.colorProps;
    newData.values.linkViewAll = state.linkViewAll;
    newData.values.contentList = state.contentList;
    newData.values.itemSetting = state.itemSetting;
    localStorage.setItem("ListSlideView", JSON.stringify(newData.values));
  };
  const onClickPaste = () => {
    const textContent = JSON.parse(localStorage.getItem("ListSlideView"));
    if (textContent) {
      const {
        valueText,
        desktop,
        mobile,
        modeType,
        title,
        colorProps,
        titleViewAll,
        linkViewAll,
        contentList,
        itemSetting,
      } = textContent;
      setState({
        ...state,
        valueText: valueText,
        desktop: desktop,
        mobile: mobile,
        modeType: modeType,
        title: title,
        colorProps: colorProps,
        titleViewAll: titleViewAll,
        linkViewAll: linkViewAll,
        contentList: contentList,
        itemSetting: itemSetting || defaultItemSetting,
      });
    }
  };
  const onClickSave = () => {
    let newData = props.data;
    newData.values.valueText = state.valueText;
    newData.values.desktop = state.desktop;
    newData.values.mobile = state.mobile;
    newData.values.modeType = state.modeType;
    newData.values.title = state.title;
    newData.values.titleViewAll = state.titleViewAll;
    newData.values.colorProps = state.colorProps;
    // newData.values.urlViewAll = state.urlViewAll;
    newData.values.linkViewAll = state.linkViewAll;
    newData.values.contentList = state.contentList;
    newData.values.itemSetting = state.itemSetting;
    props.onUpdateData(newData);
  };

  const onSetItemValue = (k, v, parent) => {
    const newParam = {
      ...state[parent],
      [k]: v,
    };
    onSetValue(parent, newParam);
  };
  const modetype = state.modeType;
  return (
    <Flex width={[1]} flexDirection={"column"} pb={["80px"]}>
      <BoxContent style={{ height: props.heightContent - 120 }}>
        <ContainerPanel>
          <SelectInput
            id={`mode-type`}
            title={"Mode Type"}
            value={state.modeType}
            list={defaultModeType}
            onChange={(e) => onSetValue("modeType", e.target.value)}
          />
          {lowerCase(modetype) === "list" && (
            <ListGroup
              data={props.data}
              list={state.valueText}
              onChangeText={(value) => onSetValue("valueText", value)}
            />
          )}
          {lowerCase(modetype) === "category" && (
            <ContentDetail>
              <CategoryList
                list={categories}
                onSetValue={(k, value) => onSetValue(k, value)}
                contentList={state.contentList}
              />
            </ContentDetail>
          )}
          {lowerCase(modetype) === "product" && (
            <Box width={[1]} px={[1]}>
              <Title title={"List Products"} pb={[1]} />
              <SelectInputProducts
                id={`Content-product-value`}
                title={"Products"}
                onChange={(v) => onSetItemValue("products", v, "contentList")}
                value={get(state, "contentList.products", [])}
              />
            </Box>
          )}
          {lowerCase(modetype) === "collection" && (
            <ContentDetail>
              <SelectInputCollection
                id={`Content-collection-value`}
                title={"Collection"}
                value={get(state, "contentList.value", "")}
                list={collections}
                onChange={(e) =>
                  onSetItemValue("value", e.target.value, "contentList")
                }
              />
            </ContentDetail>
          )}
          <PanelCard title={"General Setting"} id={`setting-lsv`}>
            <ContainerPanel>
              <ContentDetail>
                <SwitchInputView
                  value={state.contentList}
                  onUpdate={(key, value) => onSetValue(key, value)}
                  keyContent={"contentList"}
                  title={"Show Price"}
                  keyValue={"showPrice"}
                />
              </ContentDetail>
              <TextInput
                value={state.title}
                onChange={(value) => onSetValue("title", value)}
                name={`text-title-list-slide`}
                title='Title'
                fullWidth={true}
              />
              <TextInput
                value={state.titleViewAll}
                onChange={(value) => onSetValue("titleViewAll", value)}
                name={`text-titleViewAll-list-slide`}
                title='Title ViewAll'
                fullWidth={true}
              />
              <HrefContent
                link={get(state, "linkViewAll", {})}
                onSetValue={(k, v) => onSetValue(k, v)}
                keyContent={"linkViewAll"}
                websiteId={props.websiteId}
                title={"Link View All"}
              />
            </ContainerPanel>
          </PanelCard>
          <PanelCard title={"Item Setting"} id={`item-setting`}>
            <ItemSetting
              onUpdate={(k, v) => onSetValue(k, v)}
              keyContent={"itemSetting"}
              values={get(state, "itemSetting", defaultItemSetting)}
            />
          </PanelCard>
          <PanelCard title={"Colors Setting"} id={`setting-colors`}>
            <ColorsContent
              state={get(state, "colorProps", {
                backgroundColor: "rgba(255,255,255,0)",
                color: "rgba(0,0,0,1)",
                viewallColor: "rgba(0,0,0,1)",
                hoverColor: "rgba(0,0,0,1)",
                hoverBackgroundColor: "rgba(255,255,255,0)",
              })}
              contentId={"list-slide-color"}
              onSetValue={(v) => onSetValue("colorProps", v)}
            />
          </PanelCard>
          <GeneralContent
            title={"Desktop Setting"}
            state={state.desktop}
            contentId={"desktop-setting"}
            onSetValue={(k, v) => onSetDesktopValue(k, v)}
          />
          <GeneralContent
            title={"Mobile Setting"}
            state={state.mobile}
            contentId={"mobile-setting"}
            onSetValue={(k, v) => onSetMobileValue(k, v)}
          />
        </ContainerPanel>
      </BoxContent>
      <FooterAction
        onClickSave={() => onClickSave()}
        onClickCopy={() => onClickCopy()}
        onClickPaste={() => onClickPaste()}
        widthContent={props.widthContent}
      />
    </Flex>
  );
};

const MainField = (props) => {
  const { websiteId } = props;
  const dataCollections = useQuery(`/collections?site_id=${getSiteID()}`);
  const { data, loading } = useQuery(`/categories?website_id=${websiteId}`);

  if (loading) {
    return <div />;
  }
  if (dataCollections && dataCollections.loading) {
    return <div />;
  }
  return (
    <ListSlideView
      {...props}
      categories={data}
      collections={dataCollections.data}
    />
  );
};

MainField.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

MainField.defaultProps = {
  data: {},
};
export default MainField;
