import React, { Fragment } from "react";
import NoAccessPage from "../NoAccessPage";
import { usePermissions } from "react-admin";
import { ROLE_USER } from "../../utils";
const WrapperPermissions = (props) => {
  const { permissions } = usePermissions();
  const { children, ...rest } = props;
  if (permissions === ROLE_USER.operation) {
    return <NoAccessPage />;
  }
  return <Fragment {...rest}>{children}</Fragment>;
};

export default WrapperPermissions;
