import styled from "styled-components"

export const DivContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`

export const DivHover = styled.div`
  z-index: 201;
  position: relative;
  width: 100%;
  outline: 0px solid #00bcd4;
  &:hover {
    z-index: 251;
    outline: 2px solid #4cb9ea;
    cursor: pointer;
  }
`

export const DivHelp = styled.div`
  font-family: Open Sans, sans-serif;
  position: absolute;
  bottom: -20px;
  right: -1px;
  background-color: #4cb9ea;
  color: #fff;
  display: block;
  z-index: 251;
  font-size: 12px;
  line-height: 20px;
  padding: 0 10px;
  height: 20px;
`
