import React from "react"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import Slider from "@material-ui/core/Slider"

let theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiSlider: {
      thumb: {
        width: "12px",
        height: "40px",
        marginTop: "-19px",
        borderRadius: "6px",
        border: "2px solid #919394",
        /* border-radius: 50%, */
      },
      valueLabel: {
        left: "calc(-50% - 10px)",
      },
      rail: {
        color: "transparent",
      },
      track: {
        color: "transparent",
      },
    },
    PrivateValueLabel: {
      offset: {
        top: "-35px",
      },
      circle: {
        border: "2px solid #919394",
      },
    },
  },
})

function valuetext(value) {
  return `${value}°C`
}

function AirbnbThumbComponent(props, bg1, bg2) {
  const { style, ...rest } = props

  if (props["data-index"] === 0) {
    return <span {...rest} {...bg1} />
  } else if (props["data-index"] === 1) {
    return <span {...rest} {...bg2} />
  }
}

const GradientSlider = (props) => {
  const { gradient, onSetValue } = props
  const { bg1, bg2, type, degre, linear } = gradient
  const mydegre = type === "radial-gradient" ? "circle" : `${degre}deg`
  const newBg = {
    background: `${type}(${mydegre}, ${bg1} ${linear[0]}%,${bg2} ${linear[1]}%)`,
  }

  const handleChange = (event, newValue) => {
    onSetValue("linear", newValue)
  }
  const style1 = {
    style: {
      left: `${linear[0]}%`,
      color: bg1,
    },
  }
  const style2 = {
    style: {
      left: `${linear[1]}%`,
      color: bg2,
    },
  }
  return (
    <ThemeProvider theme={theme}>
      <Slider
        value={linear}
        onChange={handleChange}
        aria-labelledby="range-slider"
        ThumbComponent={(a) => AirbnbThumbComponent(a, style1, style2)}
        getAriaValueText={valuetext}
        valueLabelDisplay="on"
        style={{
          background: bg1,
          ...newBg,
        }}
      />
    </ThemeProvider>
  )
}

export default GradientSlider
