import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout4 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const AvatarView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='AvatarView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

AvatarView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};

AvatarView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "AvatarView",
    url: "",
    containerPadding: "0px",
    size: 100,
    align: "center",
    link: {
      target: "",
      type: "URL",
      value: "",
      isLink: false,
    },
  },
  layout: Layout4,
};
export default AvatarView;
