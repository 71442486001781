import React, { Component } from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import {
  BlockCounter,
  SettingSlide,
  Title,
  SketchColorPicker,
} from "../components";
import {
  FooterAction,
  ContainerPanel,
  PanelCard,
  ContentDetail,
} from "../actions";
import CarouselGroupImage from "./CarouselGroupImage";
import { BoxContent } from "../styled";

class CarouselLinkView extends Component {
  constructor(props) {
    super(props);
    const {
      align,
      valueText,
      backgroundColor,
      color,
      title,
      containerPadding,
      setting,
      fontSize,
    } = props.data.values;
    this.state = {
      heightContent: props.heightContent,
      widthContent: props.widthContent,
      isEditMode: false,
      expanded: true,
      expandedAction: false,
      expandedBackground: false,
      value: valueText,
      backgroundColor: backgroundColor,
      oldValue: props.data,
      containerPadding: containerPadding,
      align: align,
      setting: setting,
      fontSize: fontSize,
      color: color,
      title: title,
    };
  }

  onChangeText = (valueHtml) => {
    this.setState({ value: valueHtml });
  };
  onClickSave = () => {
    let newData = this.props.data;
    newData.values.valueText = this.state.value;
    newData.values.backgroundColor = this.state.backgroundColor;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values.align = this.state.align;
    newData.values.setting = this.state.setting;
    newData.values.fontSize = this.state.fontSize;
    newData.values.color = this.state.color;
    newData.values.title = this.state.title;

    this.props.onUpdateData(newData);
  };
  // onUpdateColor = (color, type) => {
  //   if (type === "background") {
  //     this.setState({ backgroundColor: color })
  //   } else if (type === "color") {
  //     this.setState({ color: color })
  //   }
  // }
  onUpdateColor = (name) => (value) => {
    this.setState({ ...this.state, [name]: value });
  };
  onUpdatePadding = (padding) => {
    this.setState({ containerPadding: padding });
  };
  onClickCopy = () => {
    let newData = this.props.data;
    newData.values.valueText = this.state.value;
    newData.values.backgroundColor = this.state.backgroundColor;
    newData.values.containerPadding = this.state.containerPadding;
    newData.values.align = this.state.align;
    newData.values.setting = this.state.setting;
    newData.values.fontSize = this.state.fontSize;
    newData.values.color = this.state.color;
    newData.values.title = this.state.title;
    localStorage.setItem("CarouselLinkView", JSON.stringify(newData.values));
  };
  onClickPaste = () => {
    const textContent = JSON.parse(localStorage.getItem("CarouselLinkView"));
    if (textContent) {
      const {
        valueText,
        backgroundColor,
        containerPadding,
        align,
        setting,
        fontSize,
        color,
        title,
      } = textContent;

      this.setState({
        value: valueText,
        backgroundColor: backgroundColor,
        oldValue: this.props.data,
        containerPadding: containerPadding,
        align: align,
        setting: setting,
        fontSize: fontSize,
        color: color,
        title: title,
      });
      this.onChangeText(valueText);
    }
  };

  onChangeCarouselGroup = (data) => {
    this.setState({
      value: data,
    });
  };
  onChangeSettingSlide = (data) => {
    this.setState({
      setting: data,
    });
  };

  render() {
    const { setting } = this.state;
    return (
      <Flex width={[1]} flexDirection={"column"}>
        <BoxContent style={{ height: this.state.heightContent - 120 }}>
          <ContainerPanel>
            <PanelCard
              title={"List Carousel"}
              id={"group-image-carousel-link-view"}
            >
              <Title title={"click this button to add Image of carousel"} />
              <CarouselGroupImage
                onChangeText={this.onChangeCarouselGroup}
                data={this.props.data}
                list={this.state.value}
                websiteId={this.props.websiteId}
              />
            </PanelCard>
            <PanelCard title={"General"} id={"general-carousel-link-view"}>
              <Flex width={[1]} flexDirection={"column"}>
                <ContentDetail>
                  <SketchColorPicker
                    title={"Background"}
                    onUpdateColor={this.onUpdateColor("backgroundColor")}
                    color={this.state.backgroundColor}
                  />
                </ContentDetail>
                <BlockCounter
                  title='Container Padding'
                  containerPadding={this.state.containerPadding}
                  onUpdatePadding={(containerPadding) =>
                    this.onUpdatePadding(containerPadding)
                  }
                />
                <ContentDetail>
                  <SettingSlide
                    setting={setting}
                    onChange={(data) => this.onChangeSettingSlide(data)}
                  />
                </ContentDetail>
              </Flex>
            </PanelCard>
          </ContainerPanel>
        </BoxContent>
        <FooterAction
          onClickSave={this.onClickSave}
          onClickCopy={this.onClickCopy}
          onClickPaste={this.onClickPaste}
          widthContent={this.state.widthContent}
        />
      </Flex>
    );
  }
}

CarouselLinkView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

CarouselLinkView.defaultProps = {
  data: {},
};
export default CarouselLinkView;
