import React, { useState, useEffect, cloneElement } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { get } from "lodash";
import { Divider, Tabs, Tab } from "@material-ui/core";
import { Loading, withDataProvider } from "react-admin";
import { languages, choicesLanguages } from "../../utils";
import { a11yProps } from "../mui";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const RaWebsiteLocaleTabs = (props) => {
  const { website_id, children, nameTab, isHideLoading, dataProvider } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [value, setValue] = React.useState(0);
  //   const [data, setData] = useState([]);
  const [localesList, setLocalesList] = useState({
    default_locale: "en",
    locale: languages,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      dataProvider
        .getOne("websites", { id: website_id })
        .then(({ data }) => {
          if (data) {
            const locale = get(data, "locale.supported_language", ["en"]);
            const defaultLanguage = get(data, "locale.default_language", "en");
            let newLanguage = locale.map(function (e) {
              let f = choicesLanguages.find((a) => a.id === e);
              return f ? f : undefined;
            });
            setLocalesList({
              default_locale: defaultLanguage,
              locale: newLanguage,
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }, 1000);

    return () => clearTimeout(timer);
  }, [website_id, dataProvider]);

  if (loading) {
    return isHideLoading ? <div>Loading...</div> : <Loading />;
  }
  if (localesList && localesList.locale && localesList.locale.length === 1) {
    const item = localesList.locale[0];
    return (
      <div className={classes.root}>
        {cloneElement(children, { locale: item.id })}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Tabs value={value} indicatorColor="primary" onChange={handleChange}>
        {localesList &&
          localesList.locale &&
          localesList.locale.map((choice, index) => (
            <Tab
              key={choice.id}
              label={choice.name}
              {...a11yProps(nameTab, index)}
            />
          ))}
      </Tabs>
      <Divider />
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {localesList &&
          localesList.locale &&
          localesList.locale.map((item, index) => (
            <div
              key={index}
              style={{ display: value === index ? "block" : "none" }}
            >
              {cloneElement(children, { locale: item.id })}
            </div>
          ))}
      </SwipeableViews>
    </div>
  );
};

export default withDataProvider(RaWebsiteLocaleTabs);
