import React from "react";
import { get } from "lodash";
import { connect } from "../../../core";
import { SubcribeFields } from "../../../libs/subcribe";

const SubcribeView = (props) => {
  const { id, layoutState, widthScreen } = props;
  const {
    desktop,
    mobile,
    placeholder,
    labelButton,
    urlSubcribe,
  } = layoutState.getItemJS(id).props.values;
  const newcontent = widthScreen > 768 ? desktop : mobile;

  if (get(newcontent, "show", true) === false) {
    return <div />;
  }

  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        display: "flex",
        flex: 1,
        width: "100%",
      }}
    >
      <SubcribeFields
        state={newcontent}
        placeholder={placeholder}
        labelButton={labelButton}
        urlSubcribe={urlSubcribe}
        widthScreen={widthScreen}
      />
    </div>
  );
};

export default connect("layoutState", "widthScreen")(SubcribeView);
