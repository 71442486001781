import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  StaticLayout,
  LayoutState,
  Row1,
  Row2,
  Row3,
  Body,
  Content,
  EmptyView,
  MenuView,
  MenuGroupView,
} from "./layout";

const comps = {
  Row1,
  Row2,
  Row3,
  Body,
  Content,
  EmptyView,
  MenuView,
  MenuGroupView,
};

class HeaderPage extends Component {
  constructor(props) {
    super(props);
    // console.log(props.data)
    this.state = {
      layoutState: props.data
        ? LayoutState.fromRaw(props.data)
        : new LayoutState(),
    };
    // console.log(props.data)
  }

  render() {
    const { heightScreen, widthScreen } = this.props;
    return (
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        <StaticLayout
          layoutState={this.state.layoutState}
          components={comps}
          token={this.props.token}
          locale={this.props.locale}
          heightScreen={heightScreen}
          widthScreen={widthScreen}
          websiteId={this.props.websiteId}
        />
      </div>
    );
  }
}
HeaderPage.propTypes = {
  data: PropTypes.object,
  token: PropTypes.string,
  locale: PropTypes.string,
};

export default HeaderPage;
