import React from "react"
import { Box } from "rebass"
import ContainerDimensions from "react-container-dimensions"
import { connect } from "../../../core"
import { ListCarousel } from "../listside"

const CarouselLinkView = (props) => {
  const { id, layoutState } = props
  const {
    containerPadding,
    valueText,
    backgroundColor,
    align,
    setting,
    title,
  } = layoutState.getItemJS(id).props.values
  //fontSize,
  let newalign = align === "center" ? align : "center"
  if (newalign === "flex-end") {
    newalign = "right"
  } else if (newalign === "flex-start") {
    newalign = "left"
  }
  return (
    <div style={{ width: "100%", padding: containerPadding }}>
      <ContainerDimensions>
        {({ width }) => {
          // console.log(width)
          return (
            <Box width={[width]} style={{ backgroundColor: backgroundColor }}>
              {width > 0 && (
                <div style={{ backgroundColor: "#FFFFFF" }}>
                  <ListCarousel
                    newMan={valueText}
                    title={title}
                    settingsCarousel={setting}
                    screenWidth={width}
                  />
                </div>
              )}
            </Box>
          )
        }}
      </ContainerDimensions>
    </div>
  )
}

export default connect("layoutState")(CarouselLinkView)
