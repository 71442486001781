import React from "react";
import styled from "styled-components";
import { get } from "lodash";
/**imageUrl: {
    url: "",
    urlMobile: "",
  },
  isShowMobile: true,
  isShowDesktop: true,
  altText: "Image one",
  desktop: {
    width: "100%",
    height: "100%",
    containerMargin: "0px",
    containerPadding: "0px",
    order: 1,
  }, */
const Image = styled.img`
  opacity: 1;
  display: block;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`;
export default (props) => {
  const { item, indexView } = props;
  return (
    <div
      style={{
        border: `${get(props, `border.size[${indexView}]`, 0)}px 
          ${get(props, `border.type[${indexView}]`)}
          ${get(props, `border.color[${indexView}]`)}
         `,
        backgroundColor: get(
          props,
          `border.backgroundColor[${indexView}]`,
          "transparent"
        ),
        padding: get(
          item,
          indexView === 1
            ? "content.desktop.containerPadding"
            : "content.mobile.containerPadding",
          "0px"
        ),
      }}
    >
      {get(
        item,
        indexView === 1 ? "content.imageUrl.url" : "content.imageUrl.urlMobile",
        ""
      ) !== "" && (
        <Image
          src={get(
            item,
            indexView === 1
              ? "content.imageUrl.url"
              : "content.imageUrl.urlMobile",
            ""
          )}
          alt={"image"}
          style={{
            width: get(
              item,
              indexView === 1 ? "desktop.width" : "mobile.width",
              "100%"
            ),
            height: get(
              item,
              indexView === 1
                ? "content.desktop.height"
                : "content.mobile.height",
              "100%"
            ),
            margin: get(
              item,
              indexView === 1
                ? "content.desktop.containerMargin"
                : "content.mobile.containerMargin",
              "0px"
            ),
            objectFit: "contain",
          }}
        />
      )}
    </div>
  );
};
