import React, { Fragment } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import SwipeableViews from "react-swipeable-views"
import { Link, useRouteMatch, useLocation } from "react-router-dom"
import { Divider, Tabs, Tab } from "@material-ui/core"
import { TabPanel, a11yProps } from "./"
import { ListCartRules } from "./cart"
import { ListProductRules } from "./product"
import { HeaderTitle, CreateButton } from "../../components"
import { BOX_SHADOW_COLOR } from "../../utils"
const tabs = [
  { id: "product_rules", name: "Product Rules", link: "/promotions" },
  { id: "cart_rules", name: "Cart Rules", link: "/promotions/cart" },
]
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tabs: {
    backgroundColor: "#FFFFFF",
  },
}))

const PromotionsList = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const match = useRouteMatch()
  const location = useLocation()
  const url = match ? match.url : location.pathname
  let indexTab = url === "/promotions/cart" ? 1 : 0
  const [value, setValue] = React.useState(indexTab)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }
  return (
    <div className={classes.root}>
      <HeaderTitle title={"resources.promotions.name"}>
        <Fragment>
          {value === 0 && (
            <CreateButton
              basePath={"/promotions"}
              sizeIcon={"xsmall"}
              style={{ textTransform: "capitalize" }}
            />
          )}
          {value === 1 && (
            <CreateButton
              basePath={"/promotions/cart"}
              sizeIcon={"xsmall"}
              style={{ textTransform: "capitalize" }}
            />
          )}
        </Fragment>
      </HeaderTitle>
      <Tabs
        value={value}
        indicatorColor="primary"
        onChange={handleChange}
        className={classes.tabs}
      >
        {tabs.map((choice, index) => (
          <Tab
            key={choice.id}
            label={choice.name}
            component={Link}
            to={choice.link}
            {...a11yProps("promotions", index)}
          />
        ))}
      </Tabs>
      <Divider />
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ boxShadow: BOX_SHADOW_COLOR }}
      >
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          name={"promotions"}
          padding={0}
        >
          <ListProductRules {...props} />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          dir={theme.direction}
          name={"promotions"}
          padding={0}
        >
          <ListCartRules {...props} />
        </TabPanel>
      </SwipeableViews>
    </div>
  )
}

export default PromotionsList
