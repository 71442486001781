import React from "react";
import { get } from "lodash";
export default (props) => {
  const { item, indexView } = props;
  const { desktop } = item.content;
  const fontSize = get(desktop, "fontSize", 10);
  /*
  backgroundColor: ,
  */
  const parentBackground = get(
    props,
    `border.backgroundColor[${indexView}]`,
    "transparent"
  );
  return (
    <div
      style={{
        padding: desktop.containerPadding,
        backgroundColor: get(desktop, "backgroundColor", parentBackground),
        fontSize: `${fontSize}pt`,
        color: get(desktop, "color", "#000000"),
        letterSpacing: get(desktop, "letterSpacing", "1px"),
        lineHeight: get(desktop, "lineHeight", "100%"),
        border: `${get(props, `border.size[${indexView}]`, 0)}px 
          ${get(props, `border.type[${indexView}]`)}
          ${get(props, `border.color[${indexView}]`)}
         `,
        width: "100%",
      }}
      dangerouslySetInnerHTML={{ __html: get(item, "content.text", "") }}
    />
  );
};
