import React from "react";
import { Counter, SwitchInput, Title, BlockCounter } from "../components";
import { ContentDetail } from "../actions";
import { get } from "lodash";
export default (props) => {
  const { state, onSetValue, contentId } = props;
  const onStateHeaderValue = (k, v) => {
    const params = {
      ...state,
      [k]: v,
    };
    onSetValue(params);
  };
  return (
    <ContentDetail>
      <SwitchInput
        id={`use-show-list-${contentId}`}
        title={"Show on Header List"}
        checked={get(state, "show", false)}
        onChange={(e) => onStateHeaderValue("show", e.target.checked)}
      />
      <SwitchInput
        id={`use-show-list-${contentId}`}
        title={"Show on Header ViewAll Button"}
        checked={get(state, "showViewAll", false)}
        onChange={(e) => onStateHeaderValue("showViewAll", e.target.checked)}
      />
      <Title title={"Font size"} />
      <Counter
        onSetValue={(value) => onStateHeaderValue("fontSize", value)}
        value={state.fontSize}
        interval={1}
        subtitle='pt'
        minValue={5}
      />
      <BlockCounter
        title={"Header Padding"}
        containerPadding={get(state, "padding", "0px")}
        onUpdatePadding={(containerPadding) =>
          onStateHeaderValue("padding", containerPadding)
        }
      />
    </ContentDetail>
  );
};
