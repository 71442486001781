import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout7 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const FlatButtonView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='FlatButtonView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

FlatButtonView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  layout: PropTypes.func,
};

FlatButtonView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "FlatButtonView",
    text: "FlatButton",
    containerPadding: "0px",
    isShowDesktop: true,
    isShowMobile: true,
    border: {
      type: "solid",
      size: 0,
      color: "#e61919",
      radius: 0,
    },
    typeButton: "flat",
    labelPosition: "before",
    icon: "",
    link: {
      target: "",
      type: "URL",
      value: "",
      isLink: false,
    },
    desktop: {
      fullWidth: false,
      fontSize: 14,
      fontWeight: "normal",
      containerPadding: "0px",
      buttonPadding: "8px 12px 8px 12px",
      buttonAlign: "center",
      buttonColors: {
        color: "#FFF",
        backgroundColor: "#e61919",
        hoverColor: "#42d696",
        rippleColor: "#FF9900",
      },
    },
    mobile: {
      fullWidth: false,
      fontSize: 10,
      fontWeight: "normal",
      containerPadding: "0px",
      buttonPadding: "8px 12px 8px 12px",
      buttonAlign: "center",
      buttonColors: {
        color: "#FFF",
        backgroundColor: "#e61919",
        hoverColor: "#42d696",
        rippleColor: "#FF9900",
      },
    },
  },
  layout: Layout7,
};
export default FlatButtonView;
