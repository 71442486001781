import React from "react";
import List from "./List";
const AccordianList = (props) => {
  return (
    <div>
      <List {...props} />
    </div>
  );
};

export default AccordianList;
