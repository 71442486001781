import React from "react"
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd"
import { connect } from "../../core"
import { target } from "./utils"
// const target = {
//   drop(props, monitor, component) {
//     if (!monitor.didDrop()) {
//       props.layoutState.insertOrMoveItem(
//         props.id,
//         props.children.length,
//         monitor.getItem()
//       )
//     }
//   },
// }

const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
}) => {
  const {
    containerPadding,
    width,
    alignValue,
    size,
    line,
    lineColor,
  } = layoutState.getItemJS(id).props.values
  let align = `divinder-${alignValue}`
  let borderValue = `${size} ${line} ${lineColor}`
  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        display: "flex",
        padding: containerPadding,
        flex: 1,
      }}
    >
      <hr className={align} style={{ border: borderValue, width: width }} />
    </div>
  )
}

const LayoutContainer = DropTarget("DividerView", target, (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(EditLayout)

export default connect("layoutState", "readOnly")(LayoutContainer)
