import React from "react"
import { connect } from "../../../core"

const DividerView = (props) => {
  const { id, layoutState } = props
  const {
    containerPadding,
    width,
    alignValue,
    size,
    line,
    lineColor,
  } = layoutState.getItemJS(id).props.values
  let align = `divinder-${alignValue}`
  let borderValue = `${size} ${line} ${lineColor}`
  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        display: "flex",
        padding: containerPadding,
        flex: 1,
      }}
    >
      <hr className={align} style={{ border: borderValue, width: width }} />
    </div>
  )
}

export default connect("layoutState")(DividerView)
