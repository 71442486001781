import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout17 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const MenuView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='MenuView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

MenuView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};
/**
 * item list
 * {
 * title:""
      type: "",
      url: "",
      slug:'',
      target: "",
      collection: 0,
    }
 */
MenuView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "MenuView",
    containerPadding: "0px",
    modelHeader: 3,
    titleButton: "Show Departments",
    color: "#000000",
    logo:
      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-mobile-RCA.png",
    list: [],
  },
  layout: Layout17,
};
export default MenuView;
