export const listDirection = [
  { id: "column", name: "Column" },
  { id: "column-reverse", name: "Column-Reverse" },
  { id: "row", name: "Row" },
  { id: "row-reverse", name: "Row Reverse" },
];
export const listAlignment = [
  { id: "baseline", name: "Baseline" },
  { id: "flex-start", name: "Flex Start" },
  { id: "flex-end", name: "Flex End" },
  { id: "center", name: "Center" },
  { id: "stretch", name: "Stretch" },
  { id: "space-between", name: "Space Between" },
  { id: "space-around", name: "Space Around" },
  { id: "space-evenly", name: "Space Evenly" },
];

export const typeLayoutScribe = [
  { id: "combine", name: "Combine" },
  { id: "split", name: "Split" },
];
