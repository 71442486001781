import React, { Fragment } from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import {
  Title,
  SketchColorPicker,
  SelectInput,
  SwitchInput,
  Alignment,
  Counter,
} from "../../components";
import { PanelCard, ContentDetail } from "../../actions";
import { typeContainer } from "./utils";

const PanelGeneral = (props) => {
  const { state, onUpdateContainer } = props;
  const containerHeight = state.container.height.split("px").join("") * 1;
  const containerHeightMobile = get(state, "container.heightMobile", "480px");
  const showArrow = get(state, "container.arrow", false);
  const isAuto = get(state, "container.auto", false);
  const chM = containerHeightMobile.split("px").join("") * 1;
  const pdtQuery = state.container.paddingTopQuery;
  const paddingTopQuery0 = pdtQuery[0].split("px").join("") * 1;
  const paddingTopQuery1 = pdtQuery[1].split("px").join("") * 1;

  const fullHeight = get(state, "container.fullHeight", false);
  return (
    <Fragment>
      <PanelCard title={"Container"} id={"group-image-carousel-animation-view"}>
        <ContentDetail>
          <SketchColorPicker
            title={"Background Color"}
            onUpdateColor={(color) =>
              onUpdateContainer("backgroundColor", color)
            }
            color={state.container.backgroundColor}
          />
          <SelectInput
            id={`id_type_carousel_list-item`}
            title={"Type"}
            value={state.container.type}
            list={typeContainer}
            onChange={(event) => onUpdateContainer("type", event.target.value)}
          />
        </ContentDetail>
        <ContentDetail>
          <SwitchInput
            id={`carousel-auto-play`}
            title={"Auto Play"}
            checked={isAuto}
            onChange={(e) => onUpdateContainer("auto", e.target.checked)}
          />
        </ContentDetail>
        <ContentDetail>
          <SwitchInput
            id={`carousel-arrow-show`}
            title={"Show Arrows"}
            checked={showArrow}
            onChange={(e) => onUpdateContainer("arrow", e.target.checked)}
          />
        </ContentDetail>
        <ContentDetail>
          <Alignment
            value={state.container.vertical}
            onChange={(value) => onUpdateContainer("vertical", value)}
            title='Vertical'
          />
        </ContentDetail>
        <ContentDetail>
          <Alignment
            value={state.container.horizontal}
            onChange={(value) => onUpdateContainer("horizontal", value)}
            title='Horizontal'
          />
        </ContentDetail>
        <ContentDetail>
          <SwitchInput
            id={`carousel-full-height`}
            title={"Full Height"}
            checked={fullHeight}
            onChange={(e) => onUpdateContainer("fullHeight", e.target.checked)}
          />
          <Flex
            width={[1]}
            pt={[2]}
            flexDirection={"row"}
            alignItems={"center"}
            flexWrap={"wrap"}
          >
            {!fullHeight && (
              <Fragment>
                <Counter
                  title={"Height Desktop"}
                  isDisabled={false}
                  onSetValue={(value) => onUpdateContainer("height", value)}
                  value={containerHeight}
                  interval={1}
                  min={200}
                  subtitle={"px"}
                />
                <Counter
                  title={"Height Mobile"}
                  isDisabled={false}
                  onSetValue={(value) =>
                    onUpdateContainer("heightMobile", value)
                  }
                  value={chM}
                  interval={1}
                  min={200}
                  subtitle={"px"}
                />
              </Fragment>
            )}
            <Counter
              title={"AutoPlaySpeed"}
              isDisabled={false}
              onSetValue={(value) => onUpdateContainer("autoPlaySpeed", value)}
              value={state.container.autoPlaySpeed}
              interval={100}
              min={1000}
            />
          </Flex>
        </ContentDetail>
        <ContentDetail>
          <Title title={"Padding Top Query"} />
          <Flex
            width={[1]}
            pt={[2]}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Counter
              title='Mobile'
              isDisabled={false}
              onSetValue={(value) =>
                onUpdateContainer("paddingTopQuery0", value)
              }
              value={paddingTopQuery0}
              interval={1}
              subtitle='px'
              min={200}
            />
            <Counter
              title='Desktop'
              isDisabled={false}
              onSetValue={(value) =>
                onUpdateContainer("paddingTopQuery1", value)
              }
              value={paddingTopQuery1}
              interval={1}
              subtitle='px'
              min={200}
            />
          </Flex>
        </ContentDetail>
      </PanelCard>
    </Fragment>
  );
};

export default PanelGeneral;
