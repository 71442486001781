import React from "react";
import { get } from "lodash";
import { connect } from "../../../core";
import {
  ProductContents,
  ProductCollectionsContents,
  ProductContentMobile,
} from "../../../libs/product";

const ProductListView = (props) => {
  const { id, layoutState, widthScreen } = props;
  //type, children, isOverCurrent,, readOnly showPrice,
  const { containerPadding, isShow, contentList } = layoutState.getItemJS(
    id
  ).props.values;
  const indexValue = widthScreen > 640 ? 1 : 0;

  if (!isShow[indexValue]) {
    return <div />;
  }
  const typeContent = get(contentList, "type", "");
  const valueContent = get(contentList, "value", "");
  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        display: "flex",
        padding: containerPadding[indexValue],
        flex: 1,
      }}
    >
      {typeContent === "Category" &&
        (widthScreen > 480 ? (
          <ProductContents
            state={layoutState.getItemJS(id).props.values}
            indexValue={indexValue}
            widthScreen={widthScreen}
            typeContent={typeContent}
          />
        ) : (
          <ProductContentMobile
            state={layoutState.getItemJS(id).props.values}
            indexValue={indexValue}
            widthScreen={widthScreen}
            typeContent={typeContent}
          />
        ))}
      {typeContent === "Collection" && (
        <ProductCollectionsContents
          state={layoutState.getItemJS(id).props.values}
          indexValue={indexValue}
          widthScreen={widthScreen}
          valueContent={valueContent}
          typeContent={typeContent}
        />
      )}
    </div>
  );
};

export default connect("layoutState", "widthScreen")(ProductListView);
