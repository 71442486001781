import React, { Component } from "react"
import PropTypes from "prop-types"
import { Flex } from "rebass"
import { ItemBackgroundCarousel } from "../components"
import { FooterAction, ContainerPanel, PanelCard } from "../actions"
import { BoxContent } from "../styled"
import { defaultItemProps } from "./defaultData"

class ContentCarousel extends Component {
  constructor(props) {
    super(props)
    const {
      backgroundColor,
      backgroundImage,
      isBackgroundImage,
      bgProps,
      width,
      alignment,
      padding,
      backgroundColorParent,
    } = props.data.values

    const bi = backgroundImage
      ? backgroundImage
      : defaultItemProps.backgroundImage
    const alignContent = alignment ? alignment : defaultItemProps.alignment
    const bgProperties = bgProps ? bgProps : defaultItemProps.bgProps
    const isBGI = isBackgroundImage ? isBackgroundImage : false
    this.state = {
      heightContent: props.heightContent,
      widthContent: props.widthContent,
      expandedContent: true,
      backgroundColor: backgroundColor,
      backgroundImage: bi,
      backgroundColorParent: backgroundColorParent,
      isBackgroundImage: isBGI,
      align: alignContent,
      bgProps: bgProperties,
      containerPadding: padding,
      width: width,
    }
    this.onClickPaste = this.onClickPaste.bind(this)
  }
  handleExpandChange = expanded => {
    this.setState({ expanded: expanded })
  }
  handleExpandChangeContent = expanded => {
    this.setState({ expandedContent: expanded })
  }
  onClickCopy = () => {
    let newData = this.props.data

    newData.values.backgroundColor = this.state.backgroundColor
    newData.values.backgroundColorParent = this.state.backgroundColorParent
    newData.values.padding = this.state.containerPadding
    newData.values.alignment = this.state.align
    newData.values.backgroundImage = this.state.backgroundImage
    newData.values.isBackgroundImage = this.state.isBackgroundImage
    newData.values.bgProps = this.state.bgProps
    newData.values.width = this.state.width
    localStorage.setItem("ContentCarousel", JSON.stringify(newData))
  }
  onClickPaste = () => {
    const contentCarousel = JSON.parse(localStorage.getItem("ContentCarousel"))
    if (contentCarousel) {
      const { values } = contentCarousel
      this.setState({
        backgroundColor: values.backgroundColor,
        backgroundImage: values.backgroundImage,
        backgroundColorParent: values.backgroundColorParent,
        isBackgroundImage: values.isBackgroundImage,
        align: values.alignment,
        bgProps: values.bgProps,
        containerPadding: values.padding,
        width: values.width,
      })
    }
  }
  onClickSave = () => {
    let newData = this.props.data
    newData.values.backgroundColor = this.state.backgroundColor
    newData.values.backgroundColorParent = this.state.backgroundColorParent
    newData.values.padding = this.state.containerPadding
    newData.values.alignment = this.state.align
    newData.values.backgroundImage = this.state.backgroundImage
    newData.values.isBackgroundImage = this.state.isBackgroundImage
    newData.values.bgProps = this.state.bgProps
    newData.values.width = this.state.width
    this.props.onUpdateData(newData)
  }

  updateBackgroundContent = values => {
    if (values) {
      this.setState({
        backgroundColor: values.backgroundColor,
        backgroundImage: values.backgroundImage,
        backgroundColorParent: values.backgroundColorParent,
        isBackgroundImage: values.isBackgroundImage,
        align: values.alignment,
        bgProps: values.bgProps,
        containerPadding: values.padding,
        width: values.width,
      })
    }
  }

  render() {
    const {
      backgroundColor,
      backgroundImage,
      isBackgroundImage,
      bgProps,
      width,
      align,
      containerPadding,
      backgroundColorParent,
    } = this.state
    return (
      <Flex width={[1]} flexDirection={"column"}>
        <BoxContent style={{ height: this.state.heightContent - 120 }}>
          <ContainerPanel>
            <PanelCard
              title="Properties of Content  1"
              id={"content-row-carousel"}
            >
              <ItemBackgroundCarousel
                backgroundColorParent={backgroundColorParent}
                padding={containerPadding}
                alignment={align}
                width={width}
                backgroundColor={backgroundColor}
                backgroundImage={backgroundImage}
                isBackgroundImage={isBackgroundImage}
                bgProps={bgProps}
                updateBackground={values =>
                  this.updateBackgroundContent(values)
                }
                title={`Content 1`}
              />
            </PanelCard>
          </ContainerPanel>
        </BoxContent>
        <FooterAction
          onClickSave={this.onClickSave}
          onClickCopy={this.onClickCopy}
          onClickPaste={this.onClickPaste}
          widthContent={this.state.widthContent}
        />
      </Flex>
    )
  }
}

ContentCarousel.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
}

ContentCarousel.defaultProps = {
  data: {},
}
export default ContentCarousel
