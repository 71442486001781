import React from "react"
import { TextInput, SelectInput } from "react-admin"
import { createMuiTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/core/styles"
import { inputStyles } from "../../themes"

export const themeRaTA = createMuiTheme({
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginTop: 16,
      },
    },
    MuiFormLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        color: inputStyles.colorLabel,
        transform: "none",
        "&$focused": {
          color: inputStyles.colorLabel,
          backgroundColor: "transparent",
          transform: "none",
        },
      },
    },
    MuiInputLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        color: inputStyles.colorLabel,
        minWidth: 80,
      },

      filled: {
        letterSpacing: inputStyles.letterSpacing,
        minWidth: 80,
        "&$shrink": {
          fontSize: 16,
          "&$marginDense": {
            transform: "translate(0px, 6px) scale(0.75)",
          },
        },
      },
    },
    MuiInput: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        backgroundColor: "transparent",
        minWidth: 80,
      },
    },
    MuiFilledInput: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        backgroundColor: "Transparent",
        "&$focused": {
          backgroundColor: "Transparent",
          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
        },
        "&:hover": {
          backgroundColor: "Transparent",
          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
        },
      },
      inputMarginDense: {
        letterSpacing: inputStyles.letterSpacing,
        paddingBottom: 4,
        padding: "27px 2px 10px",
      },
      input: {
        letterSpacing: inputStyles.letterSpacing,
        padding: "27px 2px 10px",
        paddingBottom: 4,
        fontSize: 14,
      },
      multiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: "27px 2px 10px",
        paddingBottom: 4,
        fontSize: 14,
      },
      underline: {
        borderBottom: `1px solid ${inputStyles.borderBottomColor}`,
        "&$focused": {
          borderBottom: `1px solid ${inputStyles.borderBottomColor}`,
          "&::before": {
            borderBottom: `1px solid ${inputStyles.borderBottomColor}`,
          },
        },
        "&:hover": {
          borderBottom: `1px solid ${inputStyles.borderBottomColor}`,
          "&::before": {
            borderBottom: `1px solid ${inputStyles.borderBottomColor}`,
          },
        },
        "&::before": {
          borderBottom: `1px solid ${inputStyles.borderBottomColor}`,
        },
        // "&::after": {
        //   borderBottom: `1px solid ${inputStyles.borderBottomColor}`,
        // },
      },
    },
  },
})
export const RaSearchInput = (props) => {
  return (
    <ThemeProvider theme={themeRaTA}>
      <TextInput {...props} />
    </ThemeProvider>
  )
}
export const RaSearchSelectInput = (props) => {
  return (
    <ThemeProvider theme={themeRaTA}>
      <SelectInput {...props} />
    </ThemeProvider>
  )
}
