import React from "react";
import { Flex } from "rebass";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { get } from "lodash";
import moment from "moment";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableMedium,
  Currency,
  StyledTableAction,
} from "../../../../components";
import PrintIcon from "@material-ui/icons/Print";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
// import TextField from "@material-ui/core/TextField";
import { useHistory, useLocation } from "react-router-dom";
import { RestApi } from "../../../../restClient";
////resources.buttons.print_shipping_label

const generateFile = (content, fileName) => {
  // console.log("content", content); // here at console if i copy the code and use online tool(https://base64.guru/converter/decode/pdf) it shows the correct pdf
  //{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",}
  const blob = new Blob([content]);
  // console.log(blob);
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

const ListShipment = (props) => {
  const { list, translate, website, record } = props;
  // console.log(website);
  const currency_symbol = get(website, "locale.currency_symbol", "");
  const history = useHistory();
  const location = useLocation();

  const onRowClick = (item) => {
    // console.log(item);
    history.replace(`${location.pathname}/${item.id}`);
  };

  // console.log(record);

  const onPrintLabel = (item) => {
    // console.log(item);
    ///orders/:id/shipments/:shipmentId/print
    RestApi(
      `/orders/${record.id}/shipments/${item.id}/print`,
      "application/pdf"
    )
      .then((res) => {
        return res.blob();
      })
      .then((data) => {
        // console.log(data);
        if (data) {
          const myfilename = `shipments-${record.id}-${
            item.id
          }-${moment().format("DD-MMM-YYYYHH:mm")}.pdf`;
          generateFile(data, myfilename);
        }
      })
      .catch((e) => {});
  };
  // console.log(list);
  return (
    <Flex width={[1]} flexDirection='column' bg={"#FFFFFF"}>
      {/* <Flex width={[1]} flexDirection={"row"} justifyContent='flex-end' p={[2]}>
        <TextField id='standard-basic' label='Search' />
      </Flex> */}
      <Flex width={[1]} flexDirection='column' p={[2]}>
        <TableContainer component={"div"}>
          <Table aria-label='shipment table'>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  {translate("resources.orders.fields.tracking_number")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.orders.fields.status")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.orders.fields.cost")}
                </StyledTableCell>
                <StyledTableMedium>
                  {translate("resources.orders.fields.created_at")}
                </StyledTableMedium>
                <StyledTableAction />
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {list &&
                list !== null &&
                list.length > 0 &&
                list.map((row, index) => (
                  <StyledTableRow
                    key={index}
                    // onClick={() => onRowClick(row)}
                    // style={{ cursor: "pointer" }}
                  >
                    <StyledTableCell component='th' scope='row'>
                      <Flex
                        width={[1]}
                        flexDirection={"row"}
                        alignContent={"center"}
                        sx={{
                          "&>span": {
                            pr: [2],
                          },
                        }}
                      >
                        <span>{row.tracking_number}</span>
                        <Tooltip
                          title={translate(
                            "resources.buttons.print_shipping_label"
                          )}
                        >
                          <PrintIcon
                            color={"primary"}
                            onClick={() => onPrintLabel(row)}
                            style={{ cursor: "pointer" }}
                          />
                        </Tooltip>
                      </Flex>
                    </StyledTableCell>
                    <StyledTableCell component='th' scope='row'>
                      {row.status}
                    </StyledTableCell>
                    <StyledTableCell component='th' scope='row'>
                      <Currency
                        value={get(row, "cost", 0) * 1}
                        unit={currency_symbol}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </StyledTableCell>
                    <StyledTableMedium component='th' scope='row'>
                      {moment(row.created_at).format("DD-MMM-YYYY HH:mm")}
                    </StyledTableMedium>
                    <StyledTableAction>
                      <Tooltip title={"Show"}>
                        <VisibilityIcon
                          color={"primary"}
                          onClick={() => onRowClick(row)}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </StyledTableAction>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Flex>
    </Flex>
  );
};
export default ListShipment;
