import React, { Fragment, useCallback } from "react";
import {
  useTranslate,
  SearchInput,
  Filter,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import Button from "@material-ui/core/Button";
import StoreTopSellingsEdit from "./Edit";
import StoreTopSellingsCreate from "./Create";
import { Route, useHistory } from "react-router-dom";
import { Drawer } from "@material-ui/core";
import { Flex } from "rebass";
import { get } from "lodash";
import { useStyles } from "./styles";
import { BetterList, ButtonEditView, HeaderTitle } from "../../components";
import moment from "moment";
import blue from "@material-ui/core/colors/blue";
const StoreTopSellingFilter = (props) => (
  <Flex
    width={[1]}
    flexDirection={"row"}
    justifyContent={"flex-end"}
    style={{ backgroundColor: "#FFFFFF" }}
  >
    <Filter {...props}>
      <SearchInput source='search' alwaysOn />
    </Filter>
  </Flex>
);

const DateField = ({ record, source }) => {
  const myDate = get(record, source, "");
  if (myDate === null || myDate === "") {
    return <span />;
  }
  return <span>{moment(myDate).format("DD-MMM-YYYY")}</span>;
};

const PeriodField = ({ record }) => {
  const start_date = get(record, "start_date", "");
  const end_date = get(record, "end_date", "");
  return (
    <span>
      {moment(start_date).format("DD-MMM")} to{" "}
      {moment(end_date).format("DD-MMM-YYYY")}
    </span>
  );
};

export const StoreTopSellingsList = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  // const theme = useTheme()
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push("/storeTopSellings");
  }, [history]);

  const openCreate = useCallback(
    (id) => {
      let pathUrl = "/storeTopSellings/create";
      history.push(pathUrl);
    },
    [history]
  );

  return (
    <div className={classes.root}>
      <BetterList
        {...props}
        actions={
          <HeaderTitle title='resources.products.name'>
            <Button
              color='primary'
              onClick={() => openCreate(null)}
              size='small'
              style={{ textTransform: "capitalize" }}
            >
              {translate("resources.buttons.create")}
            </Button>
          </HeaderTitle>
        }
        filters={<StoreTopSellingFilter />}
      >
        <PeriodField
          source='start_date'
          label={"resources.promotions.fields.period"}
        />
        <ReferenceArrayField
          label='resources.stores.name'
          reference='stores'
          source='stores'
        >
          <SingleFieldList>
            <ChipField
              source='name'
              style={{
                backgroundColor: blue[300],
                marginTop: 4,
                marginBottom: 4,
              }}
            />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField
          source='created_at'
          label={"resources.stores.fields.created_at"}
        />
        <ButtonEditView {...props} />
      </BetterList>
      <div className={classes.rootList}>
        <Route path='/storeTopSellings/:id'>
          {({ match }) => {
            const isMatch = !!(
              match &&
              match.url &&
              match.url !== "/storeTopSellings/create"
            );
            if (
              match &&
              match.url &&
              match.url === "/storeTopSellings/create"
            ) {
              return (
                <Fragment>
                  <Drawer
                    variant='persistent'
                    open={
                      match &&
                      match.url &&
                      match.url === "/storeTopSellings/create"
                    }
                    anchor='right'
                    onClose={handleClose}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                  >
                    {match &&
                    match.url &&
                    match.url === "/storeTopSellings/create" ? (
                      <StoreTopSellingsCreate
                        onCancel={handleClose}
                        {...props}
                      />
                    ) : null}
                  </Drawer>
                </Fragment>
              );
            }
            return (
              <Fragment>
                <Drawer
                  variant='persistent'
                  open={isMatch}
                  anchor='right'
                  onClose={handleClose}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  {isMatch &&
                  match &&
                  match.url &&
                  match.url !== "/storeTopSellings/create" ? (
                    <StoreTopSellingsEdit
                      id={match.params.id}
                      onCancel={handleClose}
                      {...props}
                    />
                  ) : null}
                </Drawer>
              </Fragment>
            );
          }}
        </Route>
      </div>
    </div>
  );
};
