import React from "react"
import { showNotification, useQueryWithStore } from "react-admin"
import { withRouter } from "react-router-dom"
import { Box } from "rebass"
import { get } from "lodash"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { FormFields } from "./components"
import { FullLoadingScreen, CardPanel } from "../../components"

const WebhooksCreate = (props) => {
  const { match } = props
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "websites",
    payload: { id: get(match, "params.website_id", 0) },
  })

  if (loading) {
    return <FullLoadingScreen />
  }

  const initialValues = {
    website_id: get(data, "id", 0),
    events: {
      order: {
        created: false,
        updated: false,
      },
      customer: {
        created: false,
        updated: false,
      },
      product: {
        created: false,
        updated: false,
      },
    },
  }
  return (
    <CardPanel>
      <Box width={[1]}>
        <FormFields
          {...props}
          sourceApi="webhooks"
          typePostUrl={"CREATE"}
          pathUrl="webhooks"
          initialValues={initialValues}
        />
      </Box>
    </CardPanel>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(WebhooksCreate)
