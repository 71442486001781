import React, { useState } from "react";
import { get } from "lodash";
//find
import { Button } from "antd";
//Popover
import { FontelloSvg } from "./Icons";
import { Flex } from "rebass";
// import { useWindowSize } from "../../../../../components/hooks";
// import { MenuItem, MenusList } from "./MenuDesktop";
// const BOX_SHADOW_COLOR = `rgba(66, 66, 66, 0.2) 0px 3px 3px -2px, rgba(66, 66, 66, 0.14) 0px 3px 4px 0px, rgba(66, 66, 66, 0.12) 0px 1px 8px 0px`;

const ListMenu = (props) => {
  const { settings } = props;
  //categories
  // const screens = useWindowSize();
  // const widthScreen = get(screens, "width", 400) - 14;
  // const list = get(settings, "header.list", []);
  const titleButton = get(settings, "header.titleButton", "");
  const fontColor = get(settings, "header.color", "");
  // const newList = list.map((item, index) => {
  //   let myLabel = item.title;
  //   let myItem = { item: item };
  //   if (item.type === "category" && categories) {
  //     const category = find(categories, function (o) {
  //       return o.category_id === item.category_id;
  //     });
  //     myLabel = get(category, "title", "");
  //     myItem = { item: item, category: category };
  //   }

  //   return { key: index, label: myLabel, items: <MenuItem {...myItem} /> };
  // });

  return (
    <div
      className={"menu-container"}
      //   style={{ position: "absolute", marginLeft: 120 }}
    >
      <Button type={"text"}>
        <Flex flexDirection={"row"} alignItems={"center"}>
          <FontelloSvg name={"bars"} fill={fontColor} size={"22px"} />{" "}
          <span style={{ color: fontColor, paddingLeft: 8 }}>
            {titleButton}
          </span>
        </Flex>
      </Button>
      {/* <Popover
        placement='bottomLeft'
        autoAdjustOverflow={false}
        overlayStyle={{
          height: "auto",
          boxShadow: BOX_SHADOW_COLOR,
        }}
        color={"#FFFFFF"}
        title={""}
        content={
          <div
            style={{
              position: "relative",
              marginLeft: "-14px",
              width: widthScreen,
            }}
          >
            <MenusList newList={newList} />
          </div>
        }
        // trigger='click'
      >
        <Button type={"text"}>
          <Flex flexDirection={"row"} alignItems={"center"}>
            <FontelloSvg name={"bars"} fill={fontColor} size={"22px"} />{" "}
            <span style={{ color: fontColor, paddingLeft: 8 }}>
              {titleButton}
            </span>
          </Flex>
        </Button>
      </Popover> */}
    </div>
  );
};

const Menu3 = (props) => {
  const [show, setShow] = useState(false);
  return (
    <ListMenu
      show={show}
      onClose={() => setShow(false)}
      categories={props.categories}
      settings={props.settings}
      style={props.style}
    />
  );
};
export default Menu3;
