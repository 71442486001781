import React from "react";
import { DropTarget } from "react-dnd";
import { DivTitleDrag } from "./styled";
const target = {
  drop(props, monitor) {
    // console.log("row", monitor.getItem())
    if (!monitor.didDrop()) {
      const row = monitor.getItem();
      if (row && row.childOf === "Body") {
        props.onDrop(monitor.getItem());
      }
    }
  },
};

const Separator = ({
  direction,
  connectDropTarget,
  isOverCurrent,
  canDrop,
}) => (
  <div
    style={{
      position: "relative",
      zIndex: 2009,
      width: "100%",
      backgroundColor: canDrop ? "#c4c4c4" : "tranparent",
      padding: canDrop ? 0 : 0,
    }}
  >
    {connectDropTarget(
      <div
        style={{
          display: canDrop ? "flex" : "none",
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          width: canDrop ? "100%" : 0,
          height: canDrop ? 30 : 0,
          top: 0,
          left: direction === "vertical" ? 0 : 0,
        }}
      >
        {isOverCurrent ? (
          <div
            style={{
              width: direction === "vertical" ? 4 : "100%",
              height: direction === "vertical" ? "100%" : 4,
              borderRadius: 2,
              marginTop: "-10px",
              backgroundColor: "#4cb9ea",
            }}
          />
        ) : null}
        {isOverCurrent ? <DivTitleDrag /> : null}
      </div>
    )}
  </div>
);
export default DropTarget("Body", target, (connect, monitor) => ({
  isOverCurrent: monitor.isOver({ shallow: true }),
  connectDropTarget: connect.dropTarget(),
  canDrop: monitor.canDrop(),
}))(Separator);
