import React from "react";
import { get } from "lodash";
//ProductCollectionsContents
import { useQuery } from "react-query";
import { RestApi } from "../../../restClient";
import { Row, Col } from "antd";
import ItemProduct from "./ItemProduct";
const cacheTimeMinute = 1000 * 60 * 30;
const ListCollections = (props) => {
  const {
    product,
    indexValue,
    typeContent,
    colorProps,
    itemSetting,
    showPrice,
  } = props;
  const { data, isLoading } = useQuery(
    ["products", product],
    () => RestApi(`/products/${product}`).then((res) => res.json()),
    {
      cacheTime: cacheTimeMinute,
    }
  );
  if (isLoading) {
    return <div />;
  }
  return (
    <ItemProduct
      indexValue={indexValue}
      item={data}
      colorProps={colorProps}
      itemSetting={itemSetting}
      modeType={typeContent}
      showPrice={showPrice}
    />
  );
};

export default (props) => {
  const { valueContent, indexValue, widthScreen, state, typeContent } = props;
  const columnProduct =
    state && state.columnProduct && state.columnProduct[indexValue]
      ? state.columnProduct[indexValue]
      : indexValue === 1
      ? 4
      : 12;
  const myWidth = indexValue === 1 ? widthScreen / 5 : widthScreen / 2;
  const { data, isLoading } = useQuery(
    "collections",
    () => RestApi(`/collections/${valueContent}`).then((res) => res.json()),
    {
      cacheTime: cacheTimeMinute,
    }
  );
  if (isLoading) {
    return <div />;
  }
  if (get(data, "products")) {
    const paramsId = get(data, "products", []);
    const gutter = get(state, "gutter", 0);
    return (
      <Row gutter={gutter}>
        {paramsId.length > 0 &&
          paramsId.map((item, index) => {
            return (
              <Col key={index} span={columnProduct} style={{ display: "flex" }}>
                <ListCollections
                  product={item}
                  myWidth={myWidth}
                  indexValue={indexValue}
                  typeContent={typeContent}
                  colorProps={get(state, "colorProps", {})}
                  itemSetting={get(state, "itemSetting", {})}
                  showPrice={get(state, `showPrice[${indexValue}]`, true)}
                />
              </Col>
            );
          })}
      </Row>
    );
  }
  return <div />;
};
