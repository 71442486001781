import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
// import { Button } from "@material-ui/core";
// import { useNotify } from "react-admin";
import { PaperCard } from "../../../../components";
// import CancelViewFields from "./CancelViewFields";
import { TextRecord } from "../../../../components";
// import { RestApi } from "../../../../restClient";
// import CircularProgress from "@material-ui/core/CircularProgress";

const CancelShipmentDetail = (props) => {
  const { translate, record, website } = props;
  const currency_symbol = get(website, "locale.currency_symbol", "");
  //, match, history
  // const notify = useNotify();
  // const [loading, setLoading] = useState(false);
  // const packageId = get(record, "packaging_id", 0);
  // const isShowing = get(record, "status", "");
  // const shipmentId = get(match, "params.id", 0);
  // const orderId = get(match, "params.order_id", 0);
  // const onPendingShipment = () => {
  //   setLoading(true);
  //   RestApi(`/orders/${orderId}/shipments/${shipmentId}/cancel`)
  //     .then((res) => {
  //       setLoading(false);
  //       notify("resources.shippings.messages.cancel_success", "success", {
  //         smart_count: 1,
  //       });
  //       history.replace(`/orders/${orderId}/show/shipment`);
  //     })
  //     .catch((e) => {
  //       notify("resources.errors.please_try_again", "warning", {
  //         smart_count: 1,
  //       });
  //       setLoading(false);
  //       // console.log(e);
  //     });
  // };
  return (
    <Flex width={[1]} flexDirection={"column"} py={[3]}>
      <PaperCard
        title={translate("resources.orders.labels.shipment_details")}
        showButton={false}
        elevation={3}
        bgHeader={"#eeeeee"}
        propsHeader={{ px: [3], py: [2] }}
      >
        {/* <CancelViewFields {...props} packageId={packageId} /> */}
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems='center'
          p={[3]}
        >
          <TextRecord
            label={"resources.orders.fields.tracking_number"}
            source='tracking_number'
            record={record}
          />
          <TextRecord
            label={"resources.orders.fields.status"}
            source='status'
            record={record}
          />
          <TextRecord
            label={"resources.orders.fields.cost"}
            source='cost'
            isCurrency={true}
            record={record}
            currencySymbol={currency_symbol}
          />
          <TextRecord
            label={"resources.orders.fields.created_at"}
            source='created_at'
            record={record}
            isDate={true}
            formatDate={"DD-MMM-YYYY HH:mm"}
          />
          <Flex />
        </Flex>
        {/* <Flex width={[1]} flexDirection={"column"} px={[3]} py={[2]}>
          <Flex
            width={[1]}
            flexDirection="row"
            pb={[3]}
            sx={{ "&>button": { mr: [2] } }}
          >
            {isShowing === "Pending" && (
              <Button
                variant={"contained"}
                style={{ borderRadius: 24 }}
                color={"primary"}
                onClick={onPendingShipment}
              >
                <Flex
                  width={[1]}
                  flexDirection={"row"}
                  justifyContent={"center"}
                >
                  {loading ? (
                    <CircularProgress size={24} color={"secondary"} />
                  ) : (
                    translate("resources.buttons.cancel_shipment")
                  )}
                </Flex>
              </Button>
            )}
          </Flex>
        </Flex> */}
      </PaperCard>
    </Flex>
  );
};

export default CancelShipmentDetail;
