import { styled } from "./utils";
import RaTextInput from "./RaTextInput";
export { default as RaTextArea } from "./RaTextArea";
export { default as RaReferenceManyField } from "./RaReferenceManyField";
export { default as RaTextInput } from "./RaTextInput";
export { default as RaLongTextInput } from "./RaLongTextInput";
export { default as TextInputField } from "./TextInputField";
export { default as RaSelectInput } from "./RaSelectInput";
export { default as RaBooleanInput } from "./RaBooleanInput";
export { default as RaNumberInput } from "./RaNumberInput";
export { default as RaReferenceArrayInput } from "./RaReferenceArrayInput";
export { default as PanelView } from "./PanelView";
export { default as TextMessage } from "./utils";
export { default as MultiLevelSelectInput } from "./MultiLevelSelectInput";
export { default as RaDateTimeInput } from "./RaDateTimeInput";
export { default as TextFieldForm } from "./TextFieldForm";
export { default as PaymentModeInput } from "./PaymentModeInput";
export { default as RaSelectArrayChipInput } from "./RaSelectArrayChipInput";
export { default as RaReferenceTextField } from "./RaReferenceTextField";
export { default as ReferanceTextForm } from "./ReferanceTextForm";
export { default as RaSelectArrayInputOptions } from "./RaSelectArrayInputOptions";
export { default as RaLocaleTabs } from "./RaLocaleTabs";
export { RaSearchInput, RaSearchSelectInput } from "./RaSearchInput";
export { default as TextRecord } from "./TextRecord";
export { default as RaWebsiteLocaleTabs } from "./RaWebsiteLocaleTabs";
export { RaAutoCompleteInput } from "./RaAutoCompleteInput";
export { default as RaReferenceManyFieldCard } from "./RaReferenceManyFieldCard";
export { default as RaTextField } from "./RaTextField";
export { default as RaCurrencyField } from "./RaCurrencyField";
export { default as ActiveField } from "./ActiveField";
export { default as RaSelectArrayProducts } from "./RaSelectArrayProducts";
export const MyTextInput = styled(RaTextInput)({
  fontSize: "2rem",
  fontWeight: "bold",
});

export { default as RaReferenceField } from "./RaReferenceField";
