import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
// import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const DialogRemoveItem = (props) => {
  const { onSetOpen, state, translate, onRemoveItem } = props;
  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      open={state.openRemove}
      onClose={() => onSetOpen(false)}
      aria-labelledby='form-category-dialog-remove-item-title'
    >
      <DialogTitle id='form-category-dialog-remove-item-title'>
        {translate("resources.layouts.messages.remote_item")}
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => onSetOpen(false)} color='primary'>
          {translate("resources.buttons.no")}
        </Button>
        <Button onClick={() => onRemoveItem()} color='primary'>
          {translate("resources.buttons.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogRemoveItem;
