import { Layout12 } from "../../TemplateImageSonak";

export const template12A = {
  "1ytQxA9H": {
    id: "1ytQxA9H",
    type: "Row1",
    props: {
      values: {
        name: "Row1",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "16px 0px 16px 0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(51, 51, 51, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
            contentAlign: "center",
            contentJustify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "10px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(51, 51, 51, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              parent: "Row",
              desktop: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                padding: "0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    childOf: "Row",
    layout: Layout12,
    children: ["GvssT2Y5"],
    content: {
      "3SOnypVY": {
        id: "3SOnypVY",
        type: "SocialMediaView",
        props: {
          values: {
            list: [
              {
                url: "https;//facebook.com",
                type: "facebook",
                color: "rgba(240, 241, 242, 1)",
                target: "_blank",
              },
              {
                url: "https;//twitter.com",
                type: "twitter",
                color: "rgba(240, 241, 242, 1)",
                target: "_blank",
              },
              {
                url: "https;//instagram.com",
                type: "instagram",
                color: "rgba(240, 241, 242, 1)",
                target: "_blank",
              },
            ],
            type: "SocialMediaView",
            mobile: {
              show: true,
              size: 16,
              align: "center",
              containerPadding: "8px",
            },
            parent: "Content",
            desktop: {
              show: false,
              size: null,
              align: null,
              containerPadding: null,
            },
          },
        },
        parent: {
          id: "GvssT2Y5",
          idx: 0,
        },
        childOf: "Content",
        children: [],
      },

      GKsd539N: {
        id: "GKsd539N",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text:
              "<p>Copyright @ 2021 Sonak Asia Pte Ltd. All rights reserved</p>",
            type: "TextView",
            isLink: false,
            mobile: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 9,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px 24px 0px 24px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 10,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        style: {},
        parent: {
          id: "GvssT2Y5",
          idx: 1,
        },
        childOf: "Content",
        children: [],
      },
    },
  },
};
