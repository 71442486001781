import React from "react";
import { Tree } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { Box } from "rebass";
import { sortBy, filter, transform, get } from "lodash";
const locale = "en";
export default (props) => {
  const { onSetValue, list, value } = props;
  const onSelect = (selectedKeys) => {
    //info
    if (Array.isArray(selectedKeys)) {
      onSetValue(parseInt(selectedKeys[0]));
    }
  };

  const treeDatas = [];
  if (list) {
    treeDatas.push(
      ...transform(
        sortBy(
          filter(list, (o) => o.parent_id === null),
          (o) => o.sort_order
        ),
        (result, o) => {
          result.push({
            title: get(o, "locale." + locale + ".name", o.name),
            key: "" + o.id,
          });
        },
        []
      )
    );
    for (let i = 0; i < treeDatas.length; i++) {
      const children = [];
      children.push(
        ...transform(
          sortBy(
            filter(list, (o) => "" + o.parent_id === treeDatas[i].key),
            (o) => o.sort_order
          ),
          (result, o) => {
            result.push({
              title: get(o, "locale." + locale + ".name", o.name),
              key: "" + o.id,
            });
          },
          []
        )
      );
      for (let j = 0; j < children.length; j++) {
        const grandChildren = [];
        grandChildren.push(
          ...transform(
            sortBy(
              filter(list, (o) => "" + o.parent_id === children[j].key),
              (o) => o.sort_order
            ),
            (result, o) => {
              result.push({
                title: get(o, "locale." + locale + ".name", o.name),
                key: "" + o.id,
              });
            },
            []
          )
        );
        children[j].children = grandChildren;
      }
      treeDatas[i].children = children;
    }
  }
  // console.log(value);
  return (
    <Box width={[1]}>
      {/* <Box pb={1}>
        <Text>Filters</Text>
      </Box> */}
      <Tree
        showLine
        treeData={treeDatas}
        defaultExpandedKeys={[value]}
        switcherIcon={<DownOutlined />}
        onSelect={onSelect}
      />
    </Box>
  );
};
