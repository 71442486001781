import React, { Fragment } from "react"
import { Box } from "rebass"
import { get } from "lodash"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import { RaNumberInput, RaSelectArrayChipInput } from "../"
import { dataAttributes } from "./utils"
const CartFields = props => {
  const { formData, translate, updateField, items, record } = props
  const typeAttribute = get(formData, `${items}.cart.attribute`, "")
  const handleChange = event => {
    updateField(`${items}.cart.attribute`, event.target.value)
  }
  return (
    <Fragment>
      <FormControl>
        <InputLabel id="attribute-cart-select-label">
          {translate("resources.promotions.fields.attribute")}
        </InputLabel>
        <Select
          labelId="attribute-cart-select-label"
          id="attribute-cart-select"
          value={typeAttribute}
          onChange={handleChange}
          style={{ width: 256 }}
        >
          {dataAttributes &&
            dataAttributes.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Box pt={[2]}>
        {typeAttribute === "Subtotal" && (
          <RaNumberInput
            source={`${items}.cart.subtotal`}
            label={"resources.promotions.fields.subtotal"}
          />
        )}
        {typeAttribute === "Total Item Quantity" && (
          <RaNumberInput
            source={`${items}.cart.qty`}
            label={"resources.promotions.fields.qty"}
          />
        )}
        {typeAttribute === "Shipping Weight" && (
          <RaNumberInput
            source={`${items}.cart.weight`}
            label={"resources.promotions.fields.weight"}
          />
        )}
        {typeAttribute === "Payment Method" && (
          <RaSelectArrayChipInput
            source={`${items}.cart.payment_method`}
            label={"resources.promotions.fields.payment_method"}
            updateField={updateField}
            record={record}
            formData={formData}
            fullWidth={true}
          />
        )}
        {typeAttribute === "Shipping Postal Code" && (
          <RaSelectArrayChipInput
            source={`${items}.cart.postal_code`}
            label={"resources.promotions.fields.postal_code"}
            updateField={updateField}
            record={record}
            formData={formData}
            fullWidth={true}
          />
        )}
        {typeAttribute === "Shipping Region" && (
          <RaSelectArrayChipInput
            source={`${items}.cart.shipping_region`}
            label={"resources.promotions.fields.shipping_region"}
            updateField={updateField}
            record={record}
            formData={formData}
            fullWidth={true}
          />
        )}
        {typeAttribute === "Shipping State" && (
          <RaSelectArrayChipInput
            source={`${items}.cart.shipping_state`}
            label={"resources.promotions.fields.shipping_state"}
            updateField={updateField}
            record={record}
            formData={formData}
            fullWidth={true}
          />
        )}
      </Box>
    </Fragment>
  )
}
export default CartFields
