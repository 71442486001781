export default {
  faq: {
    title: "FAQ",
    new_title: "New Topic",
    edit_title: "Edit Topic",
    new_faq: "New Q&A",
    edit_faq: "Edit Q&A",
    tabs: {
      question: "Question",
      last_modified_on: "Last Modified On",
    },
    fields: {
      website: "website",
      topic: "Topic",
      q_and_a: "Q&A",
      created_at: "Created On",
      answer: "Answer",
      last_modified_on: "Last Modified On",
      question: "Question",
    },
    locale: {
      en: "English",
      th: "Thai",
      id: "Bahasa",
      zh_CN: "简体",
    },
  },
}
