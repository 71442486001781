import React, { Component } from "react"
import PropTypes from "prop-types"
import TextField from "@material-ui/core/TextField"
import { addField, FieldTitle } from "react-admin"
import { Box, Flex, Text } from "rebass"
import sanitizeRestProps from "./sanitizeRestProps"
import TextMessage from "./utils"

export class RaNumberInput extends Component {
  handleBlur = event => {
    /**
     * Necessary because of a React bug on <input type="number">
     * @see https://github.com/facebook/react/issues/1425
     */
    const numericValue = isNaN(parseFloat(event.target.value))
      ? null
      : parseFloat(event.target.value)
    this.props.onBlur(numericValue)
    this.props.input.onBlur(numericValue)
  }

  handleFocus = event => {
    this.props.onFocus(event)
    this.props.input.onFocus(event)
  }

  handleChange = event => {
    /**
     * Necessary because of a React bug on <input type="number">
     * @see https://github.com/facebook/react/issues/1425
     */
    const numericValue = isNaN(parseFloat(event.target.value))
      ? null
      : parseFloat(event.target.value)
    this.props.onChange(numericValue)
    this.props.input.onChange(numericValue)
  }

  render() {
    const {
      className,
      input,
      isRequired,
      label,
      meta,
      options,
      source,
      step,
      resource,
      notetext,
      sideMessage,
      ...rest
    } = this.props
    if (typeof meta === "undefined") {
      throw new Error(
        "The RaNumberInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
      )
    }
    const { touched, error } = meta

    return (
      <Box>
        <Flex
          justifyContent={"flex-start"}
          flexDirection={"row"}
          alignItems={"flex-end"}
        >
          <TextField
            type="number"
            variant="filled"
            error={!!(touched && error)}
            helperText={touched && error}
            step={step}
            InputLabelProps={{ shrink: true }}
            label={
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
            }
            className={className}
            {...options}
            {...sanitizeRestProps(rest)}
            {...input}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onChange={this.handleChange}
          />
          {sideMessage && (
            <Text fontSize={12} fontWeight={"bold"} pl={[2]} pb={[2]}>
              {sideMessage}
            </Text>
          )}
        </Flex>
        {notetext && <TextMessage notetext={notetext} />}
      </Box>
    )
  }
}

RaNumberInput.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  notetext: PropTypes.string,
}

RaNumberInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  options: {},
  step: "any",
  textAlign: "right",
}

export const NumberInputWithField = addField(RaNumberInput)
NumberInputWithField.defaultProps = {
  textAlign: "right",
}

export default NumberInputWithField
