import { get, has, indexOf, findIndex } from "lodash"
import placeholder from "../../../assets/images/placeholder.jpg"

export const getPhoto = function (photos) {
  var src = placeholder

  if (has(photos, "photo_set")) {
    var photoSet = get(photos, "photo_set")
    var key = Object.keys(photoSet)
    var path = `photo_set.${key[0]}.photos[0]`
    src = get(photos, path, placeholder)
  } else if (has(photos, "*")) {
    src = get(photos, "*.photos[0]", placeholder)
  }
  return src
}

export const getPhotoCategory = function (photos) {
  var src = ""

  if (has(photos, "photo_set")) {
    var photoSet = get(photos, "photo_set")
    var key = Object.keys(photoSet)
    var path = `photo_set.${key[0]}.photos[0]`
    src = get(photos, path, "")
  } else if (has(photos, "*")) {
    src = get(photos, "*.photos[0]", "")
  }
  return src
}

export const getPhotos = function (photos, selectedVariant) {
  var defaultPhoto = [placeholder]

  // when product has color variant
  if (has(photos, "photo_set")) {
    var photoSet = get(photos, "photo_set")
    var key = Object.keys(photoSet)
    // get color value from selectedVariant
    var selectedColor = indexOf(key, selectedVariant["color"])
    var photoIndex = selectedColor > -1 ? selectedColor : 0

    var path = `photo_set.${key[photoIndex]}.photos`
    return get(photos, path, defaultPhoto)
  } else if (has(photos, "*")) {
    return get(photos, "*.photos", defaultPhoto)
  }

  return defaultPhoto
}

export const getDefaultVariant = function (variant) {
  var selectedVariant = {}
  if (variant && variant.options) {
    variant.options.map((option) => {
      return (selectedVariant[option.name] = get(option, "values[0]"))
    })
  }
  return selectedVariant
}

export const getSKU = function (variants, selectedVariant) {
  // find the sku of the selected variant
  const selectedVariantIndex = findIndex(variants, { variant: selectedVariant })
  // var selectedSKU = variants[0];
  var selectedSKU
  if (selectedVariantIndex > -1) {
    selectedSKU = variants[selectedVariantIndex]
  }
  return selectedSKU
}
