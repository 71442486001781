import React from "react";
import { Box, Flex, Text } from "rebass";
import { get } from "lodash";
import { FontelloSvg } from "../../../libs/icons";
import { ShowAllContent } from "./Items";

export default (props) => {
  const { title, desktop, colorProps, urlViewAll, sliderone, state } = props;
  const colorTitle = get(colorProps, "color", "#000000");
  const fontSizeHeader = get(desktop, "header.fontSize", 16);
  const showHeader = get(desktop, "header.show", false);
  const headerPadding = get(desktop, "header.padding", "0px");
  const showViewAllHeader = get(desktop, "header.showViewAll", false);
  const colorLeft = state.isIndex === 0 ? "#e5e3e6" : "#747374";
  const colorRight = state.isEnd ? "#e5e3e6" : "#747374";
  if (!showHeader) {
    return <div />;
  }
  const next = () => {
    if (!state.isEnd) {
      sliderone.slickNext();
    }
  };
  const previous = () => {
    if (state.isIndex > 0) {
      sliderone.slickPrev();
    }
  };
  return (
    <Flex
      width={[1]}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      style={{ padding: headerPadding }}
    >
      <Flex
        width={[1]}
        justify={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text
          style={{ fontSize: `${fontSizeHeader}pt`, color: colorTitle }}
          fontWeight={"bold"}
        >
          {title}
        </Text>
        {showViewAllHeader && (
          <ShowAllContent
            url={urlViewAll}
            viewallColor={get(colorProps, "viewallColor", "#000000")}
          />
        )}
      </Flex>

      <Flex justify={"row"} pl={[2]} alignItems={"center"} pt={[1]}>
        <Box pr={[1]}>
          <FontelloSvg
            fill={colorLeft}
            name={"leftSquare"}
            size={"24px"}
            onClick={() => previous()}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <Box>
          <FontelloSvg
            fill={colorRight}
            name={"rightSquare"}
            size={"24px"}
            onClick={() => next()}
            style={{ cursor: "pointer" }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
