import React, { Component } from "react"
import PropTypes from "prop-types"
import { find } from "lodash"
import { IconButton, Paper, Switch } from "@material-ui/core"
import ActionSave from "@material-ui/icons/Save"
import { DivHeaderActions } from "../styled"
import { SketchColorPicker } from "../../../components"
import { Counter, PixelPercent, DialogGradient } from "../components"
import DialogTemplate, { data } from "../components/bgTheme"
import { CardCollapse, PaperCard } from "../actions"

class Body extends Component {
  constructor(props) {
    super(props)
    const {
      backgroundColor,
      backgroundImage,
      width,
      fullheight,
    } = props.data.values
    let fh = fullheight
    if (fullheight === undefined) {
      fh = true
    }
    this.state = {
      expanded: true,
      image: this.checkImage(backgroundImage.url),
      idImage: backgroundImage.url,
      backgroundColor: backgroundColor,
      contentWidth: width.contentWidth,
      type: width.type,
      maxValueWidth: width.type === "px" ? 0 : 100,
      interval: width.type === "px" ? 50 : 1,
      fullheight: fh,
    }
  }

  checkImage = (id) => {
    let image = ""
    let result = find(data, function (item) {
      return item.id === id
    })
    if (result) {
      return result.bg
    }
    return image
  }

  handleExpandChange = (expanded) => {
    this.setState({ expanded: expanded })
  }
  onClickSave = () => {
    let newData = this.props.data
    let width = {
      type: this.state.type,
      contentWidth: this.state.contentWidth,
    }
    newData.values.backgroundColor = this.state.backgroundColor
    newData.values.backgroundImage.url = this.state.idImage
    newData.values.width = width
    newData.values.contentWidth = width.contentWidth + width.type
    newData.values.fullheight = this.state.fullheight
    this.props.onUpdateData(newData)
  }
  onUpdateColor = (color) => {
    this.setState({ backgroundColor: color })
  }
  onSetValue = (key, value) => {
    this.setState({ contentWidth: value })
  }
  onItemSelected = (bgSelected) => {
    this.setState({ image: this.checkImage(bgSelected), idImage: bgSelected })
  }
  onChangeType = (event, value) => {
    let width = 0
    let content = this.state.contentWidth
    let interval = 50
    if (value === "%") {
      width = 100
      content = 100
      interval = 1
    }
    this.setState({
      type: value,
      maxValueWidth: width,
      contentWidth: content,
      interval: interval,
    })
  }
  onToggleFullheight = (e) => {
    this.setState({ fullheight: !this.state.fullheight })
  }
  render() {
    return (
      <div style={{ width: "100%" }}>
        <div style={styles.paperCard}>
          <CardCollapse
            title="General"
            subheader="Content Body"
            {...this.props}
          >
            <div>
              <Paper style={{ padding: 10, marginBottom: 10 }}>
                <Switch
                  label="Full Height"
                  style={{ marginTop: 10 }}
                  checked={this.state.fullheight}
                  onChange={this.onToggleFullheight}
                />
              </Paper>
              <Paper style={{ padding: 10 }}>
                <SketchColorPicker
                  title={"Background"}
                  onUpdateColor={(color) => this.onUpdateColor(color)}
                  color={this.state.backgroundColor}
                />
                <DialogGradient
                  onUpdateColor={(color) => this.onUpdateColor(color)}
                  color={this.state.backgroundColor}
                />
              </Paper>
              <PaperCard title={"Background Themes"} {...this.props}>
                <DialogTemplate
                  bgItem={this.state.image}
                  onItemSelected={(item) => {
                    this.onItemSelected(item)
                  }}
                />
              </PaperCard>
              <Paper style={{ padding: 10 }}>
                <PixelPercent
                  name="type"
                  value={this.state.type}
                  onChange={this.onChangeType}
                  title="Container Width"
                />
                <Counter
                  isDisabled={false}
                  onSetValue={(value) => this.onSetValue("Width", value)}
                  value={this.state.contentWidth}
                  interval={this.state.interval}
                  maxValue={this.state.maxValueWidth}
                />
              </Paper>
            </div>
          </CardCollapse>
        </div>
        <DivHeaderActions>
          <IconButton onClick={this.onClickSave}>
            <ActionSave />
          </IconButton>
        </DivHeaderActions>
      </div>
    )
  }
}

const styles = {
  paper: {
    margin: 10,
    padding: 10,
  },
  headerCard: {
    display: "flex",
    textAlign: "left",
  },
  paperCard: {
    margin: 10,
  },
  radioButton: {
    width: 100,
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flex: "1 auto",
    marginBottom: 10,
  },
}

Body.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
}

Body.defaultProps = {
  data: {},
}
export default Body
