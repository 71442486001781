import React from "react";
import { DropTarget } from "react-dnd";
import { Box } from "rebass";
import ContainerDimensions from "react-container-dimensions";
import { connect } from "../../core";
import { List, AccordianList } from "./components";
import { target } from "./utils";

const EditLayout = ({ id, connectDropTarget, layoutState }) => {
  const { containerPadding, align } = layoutState.getItemJS(id).props.values;
  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        display: "flex",
        flex: 1,
      }}
    >
      <div
        style={{
          padding: containerPadding,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flex: 1,
          justifyContent: align,
        }}
      >
        <ContainerDimensions>
          {({ width }) => {
            return (
              <Box width={[width]}>
                {width > 600 ? (
                  <AccordianList
                    screenWidth={width}
                    values={layoutState.getItemJS(id).props.values}
                  />
                ) : (
                  <List
                    screenWidth={width}
                    values={layoutState.getItemJS(id).props.values}
                  />
                )}
              </Box>
            );
          }}
        </ContainerDimensions>
      </div>
    </div>
  );
};

const LayoutContainer = DropTarget(
  "AccordionListView",
  target,
  (conn, monitor) => ({
    connectDropTarget: conn.dropTarget(),
    isOverCurrent: monitor.isOver({ shallow: true }),
  })
)(EditLayout);

export default connect("layoutState", "readOnly")(LayoutContainer);
