import React from "react";
import { connect } from "../../../core";
import ResponsiveImage from "./ResponsiveImage";
import styled from "styled-components";

const renderText = (value) => {
  return <div dangerouslySetInnerHTML={{ __html: value }} />;
};

const ImageTextView = (props) => {
  const { id, layoutState } = props;
  const { containerPadding, backgroundImage, text } = layoutState.getItemJS(
    id
  ).props.values;
  let srcMobile = backgroundImage.urlMobile || backgroundImage.url || "";
  let srcDesktop = backgroundImage.url || backgroundImage.urlMobile || "";
  return (
    <div style={{ padding: containerPadding }}>
      <BoxImage>
        <ResponsiveImage
          srcDesktop={srcDesktop}
          srcMobile={srcMobile}
          alt='Image Template'
        />
        <MiddleView>
          <MiddleText
            style={{
              padding: text.containerPadding,
              lineHeight: text.lineHeight,
              letterSpacing: text.letterSpacing,
              backgroundColor: text.backgroundColor,
            }}
          >
            {renderText(text.value)}
          </MiddleText>
        </MiddleView>
      </BoxImage>
    </div>
  );
};

const BoxImage = styled.div`
  position: relative;
  width: 100%;
`;
const MiddleView = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const MiddleText = styled.div`
  align-items: center;
  justify-content: center;
`;

export default connect("layoutState")(ImageTextView);
