import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 256,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SelectInput = (props) => {
  const classes = useStyles();
  const { message, onChange, value, list, title, id, isError = true } = props;
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`select-label-${id}`}>{title}</InputLabel>
      <Select
        labelId={`select-label-${id}`}
        id={`select-${id}`}
        value={value}
        onChange={onChange}
      >
        {list.map((item) => (
          <MenuItem
            value={item.id ? item.id : item.value}
            key={item.id ? item.id : item.value}
          >
            {item.name ? item.name : item.text}
          </MenuItem>
        ))}
      </Select>
      {message && <FormHelperText error={isError}>{message}</FormHelperText>}
    </FormControl>
  );
};
export default SelectInput;
