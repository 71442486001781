import React, { useState, Fragment } from "react";
import { downloadCSV } from "react-admin";
import { Button } from "@material-ui/core";
import { Flex, Text } from "rebass";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DatePicker } from "antd";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { RestJsonReports } from "../../restClient";
function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD";

const DialogExportOrder = (props) => {
  const { translate } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [startDate, setStartDate] = useState(
    moment().add(-10, "days").toISOString()
  );
  const [endDate, setEndDate] = useState(moment().toISOString());

  const onShowDialog = () => {
    setOpen(true);
  };
  const onChange = (value) => {
    if (value[0]) {
      setStartDate(value[0].toISOString());
    }
    if (value[1]) {
      setEndDate(value[1].toISOString());
    }
  };
  const onSubtmit = () => {
    if (
      startDate !== null &&
      startDate !== "" &&
      endDate !== null &&
      endDate !== ""
    ) {
      setLoading(true);
      const params = `/orders/export?start_date=${moment(startDate)
        .format(dateFormat)
        .toString()}&end_date=${moment(endDate).format(dateFormat).toString()}`;
      RestJsonReports(params)
        .then((response) => response.text())
        .then((data) => {
          if (data && data.includes('{"message":')) {
            const error = JSON.parse(data);
            setMessage(error.message);
            setOpenSnackbar(true);
          } else {
            downloadCSV(
              data,
              `Export-Order-start-date-${startDate}-end-date-${endDate}`
            );
            setOpen(false);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    } else {
      setLoading(false);
      setOpenSnackbar(true);
      setMessage("Please check your start date and end date");
    }
  };
  return (
    <Fragment>
      <Flex
        style={{ minWidth: "100px" }}
        flexDirection={"row"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <Button onClick={onShowDialog} color={"primary"}>
          {translate("resources.buttons.export_order")}
        </Button>
      </Flex>
      <Dialog
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={"sm"}
      >
        <DialogTitle>
          <Flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text>Export Order</Text>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Flex>
        </DialogTitle>
        <DialogContent>
          <Flex
            width={[1]}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            py={[2]}
          >
            <Flex width={[1]} flexDirection={"row"} justifyContent={"center"}>
              <Text width={["150px"]}> Start Date </Text>
              <Text width={["120px"]}> End Date </Text>
            </Flex>
            <Flex
              width={[1]}
              pt={[0]}
              p={[2]}
              flexDirection={"row"}
              justifyContent={"center"}
              mb={[2]}
            >
              <RangePicker
                format='YYYY-MMM-DD'
                onChange={onChange}
                defaultValue={[
                  moment(startDate, dateFormat),
                  moment(endDate, dateFormat),
                ]}
                popupStyle={{ zIndex: 100000 }}
                // onOk={onOk}
              />
            </Flex>
            <Button
              onClick={onSubtmit}
              color={"primary"}
              variant={"contained"}
              style={{ color: "#FFFFFF" }}
              disabled={loading}
            >
              {translate("resources.buttons.export_order")}
            </Button>
          </Flex>

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
          >
            <Alert onClose={() => setOpenSnackbar(false)} severity='error'>
              {message}
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default DialogExportOrder;
