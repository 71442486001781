import React, { useState } from "react";
import { Flex, Box } from "rebass";
import { Title } from "../text";
import { get } from "lodash";
import { SelectInput } from "../select";
import { Counter } from "../counter";
const defaultText = {
  fontSize: [10, 14],
  textAlign: ["center", "center"],
};
const listAlign = [
  { id: "center", name: "Center" },
  { id: "justify", name: "Justify" },
  { id: "left", name: "Left" },
  { id: "right", name: "Right" },
];
export default (props) => {
  const { onSetValue, indexItem } = props;
  const [state, setState] = useState(get(props, "textContent", defaultText));
  const onChangeValue = (k, v, index) => {
    let myValue = state[k];

    if (state[k]) {
      if (myValue[index]) {
        myValue[index] = v;
      } else {
        if (myValue[index] === "") {
          myValue[index] = v;
        } else {
          if (typeof v === "number") {
            myValue = [1, 1];
            myValue[index] = v;
          } else {
            myValue = ["", ""];
            myValue[index] = v;
          }
        }
      }
    }

    const params = { ...state, [k]: myValue };
    setState(params);
    onSetValue(params);
  };
  return (
    <Box width={[1]} py={[1]}>
      <Flex flexDirection={"row"}>
        <Box width={[1]}>
          <Title title={"Desktop FontSize"} />
          <Counter
            onSetValue={(value) => onChangeValue("fontSize", value, 1)}
            value={state.fontSize[1]}
            interval={1}
            subtitle='pt'
            minValue={1}
          />
        </Box>
        <Box width={[1]}>
          <Title title={"Mobile FontSize"} />
          <Counter
            onSetValue={(value) => onChangeValue("fontSize", value, 0)}
            value={state.fontSize[0]}
            interval={1}
            subtitle='pt'
            minValue={1}
          />
        </Box>
      </Flex>
      <Flex flexDirection={"row"} pt={[1]}>
        <Box width={[1]}>
          <SelectInput
            id={`textalign-desktop-${indexItem}`}
            title={"Desktop Text Align"}
            value={state.textAlign[1]}
            list={listAlign}
            onChange={(e) => onChangeValue("textAlign", e.target.value, 1)}
          />
        </Box>
        <Box width={[1]}>
          <SelectInput
            id={`textalign-mobile-${indexItem}`}
            title={"Mobile Text Align"}
            value={state.textAlign[0]}
            list={listAlign}
            onChange={(e) => onChangeValue("textAlign", e.target.value, 0)}
          />
        </Box>
      </Flex>
    </Box>
  );
};
