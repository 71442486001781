import React, { useState } from "react"
import { useQueryWithStore, useTranslate, withDataProvider } from "react-admin"
import { Loading } from "../../../components"
import { Flex, Box } from "rebass"
import { get } from "lodash"
import moment from "moment"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Button from "@material-ui/core/Button"
import { IconButton } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Create"
import {
  StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableMedium,
} from "../../../components"
import CreateQandA from "./CreateQandA"
const payload = {
  pagination: { page: 1, perPage: 500 },
  sort: { field: "question", order: "ASC" },
}
const ListQuestionAnswer = (props) => {
  const { topic_id, dataProvider } = props
  const translate = useTranslate()
  const [state, setState] = useState({
    open: false,
    initialValues: { topic_id: topic_id },
    status: "",
    listUpdate: [],
  })

  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "questionAnswers",
    payload: { ...payload, filter: { topic_id: topic_id } },
  })

  const onEdit = (row) => {
    setState({ ...state, open: true, initialValues: row, status: "edit" })
  }
  const onShow = () => {
    setState({
      ...state,
      open: true,
      initialValues: { topic_id: topic_id },
      status: "create",
    })
  }
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setState({ ...state, open: open, status: "" })
  }

  const onClose = () => {
    setState({
      ...state,
      open: false,
      status: "",
    })
    dataProvider
      .getList("questionAnswers", {
        ...payload,
        filter: { topic_id: topic_id },
      })
      .then(({ data }) => {
        if (data) {
          setState({
            ...state,
            open: false,
            status: "",
            listUpdate: data,
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  if (loading) {
    return <Loading />
  }
  // console.log(props)
  return (
    <Box width={[1]}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"flex-end"}
        py={[2]}
      >
        <Button color="primary" onClick={() => onShow()}>
          {translate("resources.buttons.create_q_and_a")}
        </Button>
      </Flex>
      <TableContainer component={"div"}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {translate("resources.faq.fields.question")}
              </StyledTableCell>
              <StyledTableMedium>
                {translate("resources.faq.fields.last_modified_on")}
              </StyledTableMedium>
              <StyledTableAction align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {state.listUpdate.length === 0
              ? data.map((row, index) => {
                  const lastUpdate = get(row, `updated_at`, "")
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        {get(row, `question`, "")}
                      </StyledTableCell>
                      <StyledTableMedium>
                        {moment(lastUpdate).format("DD-MMM-YYYY")}
                      </StyledTableMedium>
                      <StyledTableAction>
                        <IconButton onClick={() => onEdit(row)}>
                          <EditIcon />
                        </IconButton>
                      </StyledTableAction>
                    </StyledTableRow>
                  )
                })
              : state.listUpdate.map((row, index) => {
                  const lastUpdate = get(row, `updated_at`, "")
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        {get(row, `question`, "")}
                      </StyledTableCell>
                      <StyledTableMedium>
                        {moment(lastUpdate).format("DD-MMM-YYYY")}
                      </StyledTableMedium>
                      <StyledTableAction>
                        <IconButton onClick={() => onEdit(row)}>
                          <EditIcon />
                        </IconButton>
                      </StyledTableAction>
                    </StyledTableRow>
                  )
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateQandA
        translate={translate}
        toggleDrawer={(open) => toggleDrawer(open)}
        open={state.open}
        basePath={props.basePath}
        location={props.location}
        match={props.match}
        website={props.website}
        topic_id={topic_id}
        initialValues={state.initialValues}
        status={state.status}
        onClose={() => onClose()}
      />
    </Box>
  )
}

export default withDataProvider(ListQuestionAnswer)
