import React from "react"
import ContainerDimensions from "react-container-dimensions"
import { connect } from "../../../core"
import { CarouselList } from "../../../libs/carouselAnimation"

const CarouselAnimationView = (props) => {
  const { id, layoutState } = props
  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "auto",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        flex: 1,
      }}
    >
      <ContainerDimensions>
        {({ width, height }) => {
          return (
            <CarouselList
              data={layoutState.getItemJS(id).props.values}
              widthContainer={width}
              heightContainer={height}
            />
          )
        }}
      </ContainerDimensions>
    </div>
  )
}

export default connect("layoutState")(CarouselAnimationView)
