import React from "react";
import { get } from "lodash";
import { connect } from "../../../core";
import { Col } from "antd";
import { getStylGradiant, getBGProps } from "../../../libs/gradiant";
import ContainerDimensions from "react-container-dimensions";
export function cssToJS(str) {
  const lines = str.split("px").filter((line) => line.trim());
  const myKey = ["top", "right", "bottom", "left"];
  const result = {};

  if (lines.length === 1) {
    for (let i = 0; i < myKey.length; i++) {
      const key = myKey[i];
      result[key] = !isNaN(lines[0]) ? lines[0] * 1 : 0;
    }
  } else {
    for (let i = 0; i < myKey.length; i++) {
      const key = myKey[i];
      if (i < lines.length) {
        result[key] = !isNaN(lines[i]) ? lines[i] * 1 : 0;
      } else {
        result[key] = 0;
      }
    }
  }
  return result;
}
// import {
//   renderWithColor,
//   renderWithImage,
//   defaultBackground,
// } from "./utils/contentUtils";

const Content = (props) => {
  const { id, layoutState, children, widthScreen } = props;
  //widthScreen
  // const { values } = layoutState.getItemJS(id).props;
  const { parent } = layoutState.getItemJS(id);
  const parentContent = layoutState.getItemJS(parent.id);
  const column = parentContent.children.indexOf(`${id}`);
  const { content } = parentContent.props;
  // const { alignment } = parentContent.props.values;
  const isMobileScreen = widthScreen < 480;
  const { values } = content[column].props;
  const layoutColSpan = get(
    values,
    isMobileScreen ? "mobile.layout.span" : "desktop.layout.span",
    24
  );
  const layoutColOrder = get(
    values,
    isMobileScreen ? "mobile.layout.order" : "desktop.layout.order",
    1
  );
  const padding = get(
    values,
    isMobileScreen ? "mobile.padding" : "desktop.padding",
    1
  );
  // console.log(values);
  const backgroundColor = get(
    values,
    isMobileScreen ? "mobile.backgroundColor" : "desktop.backgroundColor",
    "transparent"
  );
  let important = { backgroundColor: backgroundColor };
  const myGradient = isMobileScreen
    ? getStylGradiant(get(values, "mobile", {}))
    : getStylGradiant(get(values, "desktop", {}));
  if (myGradient) {
    if (myGradient.type) {
      const mydegre =
        myGradient.type === "radial-gradient"
          ? "circle"
          : `${myGradient.degre}deg`;
      const newBg = {
        background: `${myGradient.type}(${mydegre}, ${myGradient.bg1} ${myGradient.linear[0]}%,${myGradient.bg2} ${myGradient.linear[1]}%)`,
      };
      important = { background: myGradient.bg1, height: "100%", ...newBg };
    }
  }
  let minusHeight = 0;
  const myBGProps = isMobileScreen
    ? getBGProps(get(values, "mobile", {}))
    : getBGProps(get(values, "desktop", {}));

  if (myBGProps) {
    // const minHeight = widthScreen / myBGProps.ratio;
    const getMargin = cssToJS(get(myBGProps, "margin", "0px"));
    // console.log(getMargin);
    minusHeight = isMobileScreen ? getMargin.top + getMargin.bottom : 0;
    important = {
      backgroundImage: `url("${myBGProps.url}")`,
      backgroundSize: myBGProps.size,
      backgroundPosition: myBGProps.position,
      backgroundRepeat: myBGProps.repeat,
      // minHeight: minHeight,
      height: get(myBGProps, "height", "100%"),
      flexDirection: get(myBGProps, "layout.direction", "column"),
      alignItems: get(myBGProps, "layout.align", "flex-start"),
      justifyContent: get(myBGProps, "layout.justify", "flex-start"),
      margin: get(myBGProps, "margin", "0px"),
    };
  }
  // console.log(minusHeight);
  const widthContent = get(
    values,
    isMobileScreen ? "mobile.width" : "desktop.width",
    "100%"
  );
  const height = get(
    values,
    isMobileScreen ? "mobile.height" : "desktop.height",
    "100%"
  );
  // console.log(values);
  return (
    <Col
      span={layoutColSpan}
      order={layoutColOrder}
      style={{ alignSelf: "stretch" }}
    >
      {myBGProps ? (
        <ContainerDimensions>
          {({ width }) => {
            let myMinHeight = {};
            if (myBGProps) {
              const minHeightBG = width / myBGProps.ratio;
              myMinHeight =
                get(myBGProps, "height", "100%") !== "100%"
                  ? {}
                  : { minHeight: minHeightBG - minusHeight };
            }
            return (
              <div
                style={{
                  padding: padding,
                  width: widthContent,
                  display: "flex",
                  height: height,
                  ...important,
                  ...myMinHeight,
                  flexDirection: "column",
                }}
              >
                {children}
              </div>
            );
          }}
        </ContainerDimensions>
      ) : (
        <div
          style={{
            padding: padding,
            ...important,
            width: widthContent,
            height: height,
            flexDirection: "column",
            display: "flex",
          }}
        >
          {children}
        </div>
      )}
    </Col>
  );
};

export default connect("layoutState", "widthScreen")(Content);
