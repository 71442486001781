import React from "react"
import { SketchPicker } from "react-color"
const SelectColors = (props) => {
  const { gradient, onSetValue, indexBG } = props

  const handleColorChange = ({ rgb }) => {
    let newColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
    const key = indexBG === 0 ? "bg1" : "bg2"
    onSetValue(key, newColor)
  }
  const handleChange = (color) => {
    const { rgb } = color
    let newColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
    const key = indexBG === 0 ? "bg1" : "bg2"
    onSetValue(key, newColor)
  }
  return (
    <SketchPicker
      onChange={handleChange}
      color={indexBG === 0 ? gradient.bg1 : gradient.bg2}
      onChangeComplete={handleColorChange}
    />
  )
}

export default SelectColors
