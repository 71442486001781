import React, { Fragment } from "react";
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import { Flex } from "rebass";
export const DivTitle = styled.div`
  font-size: 1em;
  padding-bottom: 10px;
  font-weight: bold;
`;

const styles = {
  paperText: {
    padding: 10,
    marginBottom: 10,
  },
};
export const PaperCard = (props) => {
  const { children, title, elevation = 1 } = props;
  return (
    <Paper style={styles.paperText} elevation={elevation}>
      <DivTitle>{title}</DivTitle>
      <Fragment>{children}</Fragment>
    </Paper>
  );
};

export const PaperCardWithButton = (props) => {
  const { children, title, buttons, elevation = 1 } = props;
  return (
    <Paper style={styles.paperText} elevation={elevation}>
      {buttons ? (
        <Flex flexDirection={"row"} width={[1]} alignItems={"center"}>
          <DivTitle style={{ paddingBottom: 0 }}>{title}</DivTitle>
          {buttons}
        </Flex>
      ) : (
        <DivTitle>{title}</DivTitle>
      )}
      <Fragment>{children}</Fragment>
    </Paper>
  );
};
