import React from "react";
import { EditIcon, ShowIcon, Chart8, TrashIcon, User1Icon } from "./icon_svg";
import {
  EditButton,
  ShowButton,
  ChartButton,
  DeleteButton,
  MemberButton,
  DeleteLabelButton,
} from "./buttons/rabutton";

export const BetterEditButton = (props) => {
  const {
    bashPath,
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    typePostUrl,
    staticContext,
    initialValues,
    customBackUrl,
    showNotification,
    sourceApi,
    pathUrl,
    icon = <EditIcon fontSize={"small"} />,
    ...checkProps
  } = props;
  return (
    <EditButton
      {...checkProps}
      label=''
      style={{ textAlign: "right", fontSize: 14, padding: 7 }}
      icon={icon}
    />
  );
};
export const BetterUserButton = (props) => {
  const { bashPath, hasList, hasCreate, hasEdit, hasShow, ...checkProps } =
    props;
  return (
    <MemberButton
      {...checkProps}
      label=''
      style={{ textAlign: "right", fontSize: 14, padding: 7 }}
      icon={<User1Icon fontSize={"small"} />}
    />
  );
};

export const BetterChartButton = (props) => {
  const { bashPath, hasList, hasCreate, hasEdit, hasShow, ...checkProps } =
    props;
  return (
    <ChartButton
      {...checkProps}
      label=''
      style={{ textAlign: "right", fontSize: 14, padding: 7 }}
      icon={<Chart8 fontSize={"small"} />}
    />
  );
};
export const BetterDeleteButton = (props) => {
  const {
    bashPath,
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    label = "",
    ...checkProps
  } = props;
  return (
    <DeleteButton
      {...checkProps}
      label={label}
      style={{
        textAlign: "right",
        fontSize: 14,
        padding: 7,
      }}
      icon={<TrashIcon fontSize={"small"} />}
    />
  );
};
export const EditDeleteButton = (props) => {
  const { bashPath, hasList, hasCreate, hasEdit, hasShow, ...checkProps } =
    props;
  return (
    <DeleteLabelButton
      {...checkProps}
      style={{
        textAlign: "right",
        fontSize: 14,
        padding: 7,
        color: "#FF0000",
      }}
      icon={<TrashIcon fontSize={"small"} />}
    />
  );
};
export const BetterShowButton = (props) => {
  const { hasList, hasCreate, hasEdit, hasShow, ...checkProps } = props;
  return (
    <ShowButton
      {...checkProps}
      label=''
      style={{ textAlign: "right", fontSize: 14, padding: 7, marginRight: 8 }}
      icon={<ShowIcon fontSize={"small"} />}
    />
  );
};
