import React, { cloneElement, Children } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useReferenceManyFieldController, ListContext } from "react-admin";
import { ContentBoxCard } from "../../utils";
import { Paper as MuiPaper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
import { Box, Flex, Text } from "rebass";
import { CreateButton } from "../buttons";
// import { AddIcon } from "../icon_svg";
import { useStylesTableCard } from "../utils";

const Paper = withStyles((theme) => ({
  root: {
    width: "100%",
  },
}))(MuiPaper);

export const RaReferenceManyFieldCard = (props) => {
  const {
    labelCreate,
    defaultbasePath,
    subheader,
    basePath,
    children,
    page = 1,
    perPage,
    record,
    reference,
    resource,
    sort,
    source,
    target,
    createIcon,
    bgHeader = "#EEEEEE",
    elevation = 3,
    classes: classesOverride,
    showButton = true,
    propsHeader = { p: [2] },
  } = props;

  if (React.Children.count(children) !== 1) {
    throw new Error(
      "<ReferenceManyField> only accepts a single child (like <Datagrid>)"
    );
  }
  //   const [searchText, setSearchText] = useState("");

  let location = useLocation();

  //   const handleChange = (event) => {
  //     setSearchText(event.target.value);
  //   };

  const controllerProps = useReferenceManyFieldController({
    resource,
    reference,
    record,
    target,
    // filter: { q: searchText },
    source,
    basePath: basePath ? basePath : defaultbasePath,
    page,
    perPage,
    sort,
  });
  const classesT = useStylesTableCard({ classes: classesOverride });
  return (
    <Box width={[1]} p={[3]}>
      <Paper elevation={elevation} component={"div"}>
        <Flex
          {...propsHeader}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            backgroundColor: bgHeader,
          }}
        >
          <Text fontWeight="bold">{subheader}</Text>
          {showButton && (
            <CreateButton
              basePath={location.pathname}
              icon={createIcon}
              sizeIcon={"xsmall"}
              isNotShowIcon={!createIcon}
              style={{
                justifyContent: "center",
                textTransform: "none",
                paddingRight: 8,
              }}
              label={labelCreate}
            />
            // <Button onClick={onClick} color={"primary"}>
            //   {labelCreate}
            // </Button>
          )}
        </Flex>
        <ListContext.Provider value={controllerProps}>
          <ReferenceManyFieldView
            {...props}
            {...controllerProps}
            classes={classesT}
          />
        </ListContext.Provider>
        {/* <ReferenceManyFieldView
        {...rest}
        {...{
          currentSort: sort,
          page,
          perPage,
          setPage,
          setPerPage,
          setSort: setSortField,
          ...controllerProps,
        }}
      /> */}
      </Paper>
    </Box>
  );
};

/***{subheader && (
        <Box width={[1]}>
          <Text fontSize={[0]}>{subheader}</Text>
        </Box>
      )}
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box width={[1]}>
          {!notShowSearch && (
            <TextField
              placeholder={"Search"}
              value={searchText}
              onChange={handleChange}
              margin={"dense"}
            />
          )}
        </Box>
        <HeaderBox>
          {isCustomCreateButton ? (
            <CreateButton
              basePath={location.pathname}
              icon={createIcon}
              sizeIcon={"xsmall"}
              isNotShowIcon={!createIcon}
              style={{
                justifyContent: "center",
                textTransform: "none",
                paddingRight: 8,
              }}
              label={labelCreate}
            />
          ) : (
            <CreateButton
              basePath={location.pathname}
              icon={<AddIcon />}
              sizeIcon={"xsmall"}
              style={{
                justifyContent: "center",
                textTransform: "none",
              }}
              label={labelCreate}
            />
          )}
        </HeaderBox>
      </Flex>
       */

RaReferenceManyFieldCard.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.element.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  filter: PropTypes.object,
  label: PropTypes.string,
  perPage: PropTypes.number,
  record: PropTypes.object,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
  labelCreate: PropTypes.string,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  target: PropTypes.string.isRequired,
  isCustomCreateButton: PropTypes.bool,
  subheader: PropTypes.string,
};

RaReferenceManyFieldCard.defaultProps = {
  filter: {},
  perPage: 25,
  sort: { field: "id", order: "DESC" },
  source: "id",
  addLabel: true,
  labelCreate: "ra.action.create",
};

export const ReferenceManyFieldView = ({
  children,
  className,
  currentSort,
  data,
  ids,
  loaded,
  page,
  pagination,
  perPage,
  reference,
  referenceBasePath,
  setPage,
  setPerPage,
  setSort,
  total,
  classes,
}) => (
  <ContentBoxCard>
    {cloneElement(Children.only(children), {
      className,
      resource: reference,
      ids,
      loaded,
      data,
      basePath: referenceBasePath,
      currentSort,
      setSort,
      total,
      classes,
    })}
    {pagination &&
      total !== undefined &&
      cloneElement(pagination, {
        page,
        perPage,
        setPage,
        setPerPage,
        total,
      })}
  </ContentBoxCard>
);

ReferenceManyFieldView.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  data: PropTypes.object,
  ids: PropTypes.array,
  loaded: PropTypes.bool,
  pagination: PropTypes.element,
  reference: PropTypes.string,
  referenceBasePath: PropTypes.string,
  setSort: PropTypes.func,
};

export default RaReferenceManyFieldCard;
