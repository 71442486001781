export const defaultDataList = {
  parent: "Content",
  type: "GridListView",
  containerPadding: "0px",
  backgroundColor: "rgba(255,255,255,0)",
  color: "rgba(0,0,0,1)",
  fontSize: {
    desktop: 14,
    mobile: 10,
  },
  align: "center",
  title: "",
  modeType: "list",
  showAll: false,
  urlShowAll: "",
  valueText: [
    {
      imageUrl: {
        url:
          "https://s3-ap-southeast-1.amazonaws.com/oleh-prod/assets/1/product/For-testing-only-UDO.png",
        urlMobile:
          "https://s3-ap-southeast-1.amazonaws.com/oleh-prod/assets/1/product/For-testing-only-UDO.png",
      },
      title: "Loyalty",
      subtitle: "",
      href: {
        url: "",
        target: "",
        isLink: false,
      },
    },
  ],
  appointment: {
    isAppointment: false,
    id: 0,
    list: [],
  },
  package: {
    isPackage: false,
    id: 0,
    list: [],
  },
  products: {
    isProduct: false,
    id: 0,
    list: [],
  },
};
