import React, { Component } from "react";
import PropTypes from "prop-types";
import Layout from "../Layout";
class Row9 extends Component {
  render() {
    return <Layout {...this.props} type='Row' />;
  }
}

Row9.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};

export default Row9;
