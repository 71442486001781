import React from "react";
import { Menu as Menus } from "antd";
import { get } from "lodash";
import QueueAnim from "rc-queue-anim";
import { Flex } from "rebass";
const { SubMenu } = Menus;

const MobileListMenu = (props) => {
  const { show, onClose, categories } = props;
  const myType = show ? ["left", "right"] : ["right", "left"];
  const myease = show
    ? ["easeInOutQuart", "easeOutQuart"]
    : ["easeOutQuart", "easeInOutQuart"];

  const onClickItem = (e) => {
    // console.log(e);
    // history.replace(e.key);
    onClose();
  };

  // const list = get(settings, "header.list", [])
  return (
    <QueueAnim key='demo-min-burger-menu' type={myType} ease={myease}>
      {show && (
        <div
          key={"a"}
          style={{
            width: 256,
            position: "absolute",
            marginTop: 27,
            zIndex: 2,
            marginLeft: -16,
            overflowY: "scroll",
          }}
        >
          <Menus
            style={{ width: 256 }}
            mode='inline'
            theme='dark'
            onClick={onClickItem}
            onClose={onClose}
          >
            {categories &&
              categories.length > 0 &&
              categories.map((menuParent) => {
                const subMenu = get(menuParent, "children", []);
                if (subMenu.length > 0) {
                  return (
                    <SubMenu
                      key={`${menuParent.id}`}
                      title={
                        <Flex width={[1]} style={{ textAlign: "left" }}>
                          {menuParent.title}
                        </Flex>
                      }
                    >
                      <Menus.Item
                        key={`${menuParent.id}:parent`}
                        data={menuParent}
                      >
                        <Flex width={[1]} style={{ textAlign: "left" }}>
                          Show All
                        </Flex>
                      </Menus.Item>
                      {subMenu.map((menuSub1) => {
                        const subMenu1 = get(menuSub1, "children", []);
                        if (subMenu1.length > 0) {
                          return (
                            <SubMenu
                              key={`${menuSub1.id}`}
                              title={
                                <Flex width={[1]} style={{ textAlign: "left" }}>
                                  {menuSub1.title}
                                </Flex>
                              }
                            >
                              <Menus.Item
                                key={`${menuSub1.id}:category1`}
                                data={menuSub1}
                              >
                                <Flex width={[1]} style={{ textAlign: "left" }}>
                                  Show All
                                </Flex>
                              </Menus.Item>
                              {subMenu1.map((menuSub2) => {
                                const subMenu2 = get(menuSub2, "children", []);
                                if (subMenu2.length > 0) {
                                  return (
                                    <SubMenu
                                      key={`${menuSub2.id}`}
                                      title={
                                        <Flex
                                          width={[1]}
                                          style={{ textAlign: "left" }}
                                        >
                                          {menuSub2.title}
                                        </Flex>
                                      }
                                    >
                                      <Menus.Item
                                        key={`${menuSub2.id}:category2`}
                                        data={menuSub2}
                                      >
                                        <Flex
                                          width={[1]}
                                          style={{ textAlign: "left" }}
                                        >
                                          Show All
                                        </Flex>
                                      </Menus.Item>
                                      {subMenu2.map((menuSub3) => {
                                        const subMenu3 = get(
                                          menuSub3,
                                          "children",
                                          []
                                        );
                                        if (subMenu3.length > 0) {
                                          return (
                                            <SubMenu
                                              key={`${menuSub3.id}`}
                                              title={
                                                <Flex
                                                  width={[1]}
                                                  style={{ textAlign: "left" }}
                                                >
                                                  {menuSub3.title}
                                                </Flex>
                                              }
                                            >
                                              <Menus.Item
                                                key={`${menuSub3.id}:category3`}
                                                data={menuSub3}
                                              >
                                                <Flex
                                                  width={[1]}
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Show All
                                                </Flex>
                                              </Menus.Item>
                                              {subMenu3.map((menuSub4) => {
                                                const subMenu4 = get(
                                                  menuSub4,
                                                  "children",
                                                  []
                                                );
                                                if (subMenu4.length > 0) {
                                                  return (
                                                    <SubMenu
                                                      key={`${menuSub4.id}`}
                                                      title={
                                                        <Flex
                                                          width={[1]}
                                                          style={{
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          {menuSub4.title}
                                                        </Flex>
                                                      }
                                                    >
                                                      <Menus.Item
                                                        key={`${menuSub4.id}:category3`}
                                                        data={menuSub4}
                                                      >
                                                        <Flex
                                                          width={[1]}
                                                          style={{
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          Show All
                                                        </Flex>
                                                      </Menus.Item>
                                                      {subMenu4.map(
                                                        (menuSub5) => {
                                                          return (
                                                            <Menus.Item
                                                              key={`${menuSub5.id}`}
                                                              data={menuSub5}
                                                            >
                                                              <Flex
                                                                width={[1]}
                                                                style={{
                                                                  textAlign:
                                                                    "left",
                                                                }}
                                                              >
                                                                {menuSub5.title}
                                                              </Flex>
                                                            </Menus.Item>
                                                          );
                                                        }
                                                      )}
                                                    </SubMenu>
                                                  );
                                                }
                                                return (
                                                  <Menus.Item
                                                    key={`${menuSub4.id}`}
                                                    data={menuSub4}
                                                  >
                                                    <Flex
                                                      width={[1]}
                                                      style={{
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      {menuSub4.title}
                                                    </Flex>
                                                  </Menus.Item>
                                                );
                                              })}
                                            </SubMenu>
                                          );
                                        }
                                        return (
                                          <Menus.Item
                                            key={`${menuSub3.id}`}
                                            data={menuSub3}
                                          >
                                            <Flex
                                              width={[1]}
                                              style={{ textAlign: "left" }}
                                            >
                                              {menuSub3.title}
                                            </Flex>
                                          </Menus.Item>
                                        );
                                      })}
                                    </SubMenu>
                                  );
                                }
                                return (
                                  <Menus.Item
                                    key={`${menuSub2.id}`}
                                    data={menuSub2}
                                  >
                                    <Flex
                                      width={[1]}
                                      style={{ textAlign: "left" }}
                                    >
                                      {menuSub2.title}
                                    </Flex>
                                  </Menus.Item>
                                );
                              })}
                            </SubMenu>
                          );
                        }
                        return (
                          <Menus.Item key={`${menuSub1.id}`} data={menuSub1}>
                            <Flex width={[1]} style={{ textAlign: "left" }}>
                              {menuSub1.title}
                            </Flex>
                          </Menus.Item>
                        );
                      })}
                    </SubMenu>
                  );
                }
                return (
                  <Menus.Item key={`${menuParent.id}`} data={menuParent}>
                    <Flex width={[1]} style={{ textAlign: "left" }}>
                      {menuParent.title}
                    </Flex>
                  </Menus.Item>
                );
              })}
          </Menus>
        </div>
      )}
    </QueueAnim>
  );
};

export default MobileListMenu;
