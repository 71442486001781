import React from "react";
import PropTypes from "prop-types";
import { uniqBy } from "lodash";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useTranslate } from "react-admin";
import { useQuery } from "react-query";
import { getProductsList } from "../../restClient";
const cacheTimeMinute = 1000 * 60 * 30;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
    chip: {
      textTransform: "capitalize",
    },
  },
}));

const ProductChipInput = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { dataOptions, resource, optionText, source, label } = props;
  const { isLoading, data } = useQuery("products", () => getProductsList(), {
    cacheTime: cacheTimeMinute,
  });

  // const dataList = data ? data : [];

  // const valueOptions = dataList.filter(function (item) {
  //   for (let key in dataOptions) {
  //     if (item.id === undefined || item.id === dataOptions[key]) return true;
  //   }
  //   return false;
  // });

  //   const newData = union(valueOptions, dataList);
  //   const listData = map(newData, (item) => {
  //     return item;
  //   });
  const onChangeChip = (e, v) => {
    // const valueOptions1 = map(v, (item) => {
    //   return item;
    // });
    // console.log(v, valueOptions1);
    props.updateField(source, v);
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        disableClearable
        loading={isLoading}
        loadingText={"Loading..."}
        id={`${resource}-${source}`}
        options={uniqBy(data, "id")}
        value={dataOptions}
        onChange={onChangeChip}
        filterSelectedOptions={true}
        getOptionLabel={(option) => option[optionText]}
        style={{ width: "100%" }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            console.log(option);
            return (
              <Chip
                label={option[optionText]}
                {...getTagProps({ index })}
                color='primary'
                style={{ color: "#FFFFFF" }}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            variant='standard'
            label={translate(label)}
            placeholder=''
          />
        )}
      />
    </div>
  );
};

ProductChipInput.propTypes = {
  updateField: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
  dataOptions: PropTypes.array,
  optionText: PropTypes.string.isRequired,
};

ProductChipInput.defaultProps = {
  optionText: "name",
};
export default ProductChipInput;
