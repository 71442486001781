import React from "react";
// import ContainerDimensions from "react-container-dimensions";
import { get } from "lodash";
import { Box } from "rebass";
import { connect } from "../../../core";
import { HrefContainer } from "../../../libs/hrefUrl";

const renderText = (
  value,
  containerPadding,
  lineHeight,
  letterSpacing,
  link
) => {
  if (link && link.value !== "") {
    return (
      <HrefContainer link={link}>
        <div
          style={{
            padding: containerPadding,
            lineHeight,
            letterSpacing,
            width: "100%",
          }}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      </HrefContainer>
    );
  }
  return (
    <div
      style={{
        padding: containerPadding,
        lineHeight,
        letterSpacing,
        width: "100%",
      }}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};
const renderTextShow = (
  value,
  containerPadding,
  lineHeight,
  letterSpacing,
  color,
  textAlign,
  fontSize,
  backgroundColor,
  hoverBackgroundColor,
  colorHover,
  link
) => {
  const myfontSize =
    fontSize && fontSize > 0 ? { fontSize: `${fontSize}pt` } : {};

  if (link && link.value !== "") {
    return (
      <HrefContainer link={link}>
        <Box
          sx={{
            padding: containerPadding,
            lineHeight,
            letterSpacing,
            width: "100%",
            color: color,
            textAlign: textAlign,
            ...myfontSize,
            backgroundColor: backgroundColor,
            "&:hover": {
              backgroundColor: hoverBackgroundColor,
              color: colorHover,
            },
          }}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      </HrefContainer>
    );
  }
  return (
    <div
      style={{
        padding: containerPadding,
        lineHeight,
        letterSpacing,
        width: "100%",
        color: color,
        textAlign: textAlign,
        ...myfontSize,
      }}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};

const TextView = (props) => {
  const { id, layoutState, widthScreen } = props;
  const {
    containerPadding,
    text,
    backgroundColor,
    lineHeight,
    letterSpacing,
    desktop,
    mobile,
    link,
    isShowDesktop,
    isShowMobile,
  } = layoutState.getItemJS(id).props.values;
  const isShowing = widthScreen > 480 ? isShowDesktop : isShowMobile;
  const newcontent = widthScreen > 768 ? desktop : mobile;
  if (!isShowing) {
    return <div />;
  }
  if (newcontent) {
    const hoverBackgroundColor = get(
      desktop,
      "hoverBackgroundColor",
      get(newcontent, "backgroundColor", "transparent")
    );
    const colorHover = get(
      desktop,
      "hoverColor",
      get(newcontent, "color", "transparent")
    );
    const isFlex = get(newcontent, "isFullHeight") ? { flex: 1 } : {};
    return (
      <div
        style={{
          boxSizing: "border-box",
          position: "relative",
          display: "flex",
          width: "100%",
          backgroundColor: get(newcontent, "backgroundColor", "transparent"),
          ...isFlex,
        }}
      >
        {renderTextShow(
          text,
          newcontent.containerPadding,
          get(newcontent, "lineHeight", "100%"),
          get(newcontent, "letterSpacing", "1px"),
          get(newcontent, "color", "transparent"),
          get(newcontent, "textAlign", "left"),
          get(newcontent, "fontSize", "12"),
          get(newcontent, "backgroundColor", "transparent"),
          hoverBackgroundColor,
          colorHover,
          link
        )}
      </div>
    );
  }
  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        display: "flex",
        flex: 1,
        width: "100%",
        backgroundColor: backgroundColor,
      }}
    >
      {renderText(text, containerPadding, lineHeight, letterSpacing, link)}
    </div>
  );
};

export default connect("layoutState", "widthScreen")(TextView);
