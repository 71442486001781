import React, { Component } from "react"
import PropTypes from "prop-types"
import { Flex, Box } from "rebass"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"
import Pagination from "./Pagination"
import styled from "styled-components"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const Image = styled.img`
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`

class CarouselList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
    }
  }
  handleChangeIndex = index => {
    this.setState({
      index,
    })
  }

  render() {
    const { photos, alt, auto, direction, interval } = this.props
    const { index } = this.state
    let autoPlay = false
    if (photos.length > 1) {
      autoPlay = auto
    }
    const intervalswipe = interval * 1000
    return (
      <Box>
        <Flex flexWrap={"wrap"} justifyContent={"center"}>
          <Box width={[1]} style={styles.imageContainer}>
            <AutoPlaySwipeableViews
              index={index}
              onChangeIndex={this.handleChangeIndex}
              interval={intervalswipe}
              direction={direction}
              autoplay={autoPlay}
            >
              {photos &&
                photos.map((photo, index) => (
                  <Image src={photo} key={`imageDiv${index}`} alt={alt} />
                ))}
            </AutoPlaySwipeableViews>
            <div style={styles.pagination}>
              <Pagination
                dots={photos.length}
                index={index}
                onChangeIndex={this.handleChangeIndex}
              />
            </div>
          </Box>
        </Flex>
      </Box>
    )
  }
}
const styles = {
  pagination: {
    position: "absolute",
    width: "100%",
    marginTop: "-24px",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
}

CarouselList.propTypes = {
  photos: PropTypes.array,
  alt: PropTypes.string,
  interval: PropTypes.number,
  direction: PropTypes.string,
  auto: PropTypes.bool,
}

CarouselList.defaultProps = {
  photos: [],
  alt: "Image",
  auto: false,
  direction: "incremental",
  interval: 3,
}

export default CarouselList
