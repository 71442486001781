export default {
  collections: {
    name: "Collections",
    new_page: "New Collection",
    edit_page: "Edit Collection",
    fields: {
      name: "name",
      site_id: "Site",
      active: "Active",
      collections: "Collections",
      website: "Website",
      banner: "Banner",
      description: "Description",
      find_product: "Find Product",
    },
    message: {
      title_reset: "Are you sure want to reset?",
      content_reset: "The Page will be blank if you reset page",
    },
  },
};
