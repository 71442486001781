import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 256,
    zIndex: 3000,
  },
  menuPaper: {
    zIndex: 3000,
    paper: {
      zIndex: 3000,
    },
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SelectTargetInput = (props) => {
  const classes = useStyles();
  const { message, onChange, value, title, id, isError = true } = props;
  const onChangeTarget = (e) => {
    if (e.target.value === "same") {
      onChange("");
    } else {
      onChange(e.target.value);
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`select-target-label-${id}`}>{title}</InputLabel>
      <Select
        labelId={`select-target-label-${id}`}
        id={`select-target-${id}`}
        value={value === "" ? "same" : value}
        onChange={(e) => onChangeTarget(e)}
        className={classes.menuPaper}
      >
        <MenuItem value={"same"}>Same Page</MenuItem>
        <MenuItem value={"_blank"}>New Page</MenuItem>
      </Select>
      {message && <FormHelperText error={isError}>{message}</FormHelperText>}
    </FormControl>
  );
};
export default SelectTargetInput;
