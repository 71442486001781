import React from "react";
import { showNotification, useQuery } from "react-admin";
import { withRouter } from "react-router-dom";
import { Box } from "rebass";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { CreateForm } from "./components";
import { FullLoadingScreen, CardPanel } from "../../components";

const LayoutCreate = (props) => {
  const { match } = props;
  const { data, loading } = useQuery({
    type: "getOne",
    resource: "websites",
    payload: { id: match.params.website_id },
  });

  if (loading) {
    return <FullLoadingScreen />;
  }
  const initialValues = {
    website_id: data.id,
    site_id: data.site_id,
    theme: {
      colors: {
        text: "#626163",
        background: "#EFEFEF",
        primary: "#6B5B37",
        secondary: "#C3C0B6",
        highlight: "#F1E656",
        accent: "#E23098",
        accent1: "#4A90E2",
        accent2: "#3F5498",
        muted: "#F2F1EF",
        error: "#FF0000",
      },
    },
  };
  return (
    <CardPanel>
      <Box width={[1]}>
        <CreateForm
          {...props}
          sourceApi="layouts"
          typePostUrl={"CREATE"}
          pathUrl="layouts"
          websites={data}
          initialValues={initialValues}
        />
      </Box>
    </CardPanel>
  );
};

const enhance = compose(withRouter, connect(undefined, { showNotification }));

export default enhance(LayoutCreate);
