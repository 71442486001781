import { Layout4 } from "../../TemplateImageSonak";
export const template4A = {
  CbNAWarh: {
    id: "CbNAWarh",
    type: "Row1",
    props: {
      values: {
        name: "Row1",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 20,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "36px 24px 10px 24px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 20,
            justify: "center",
            contentAlign: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "30px 0px 10px 0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              parent: "Row",
              desktop: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                padding: "0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    layout: Layout4,
    childOf: "Row",
    children: ["zllH+rmO"],
    content: {
      "GG3nt/E1": {
        id: "GG3nt/E1",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>Your Gear, Your Rules</strong></p>",
            type: "TextView",
            isLink: false,
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 16,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 24,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "zllH+rmO",
          idx: 0,
        },
        childOf: "Content",
        children: [],
      },
    },
  },
};
