import styled from "styled-components"

export const DivContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`

export const DivHover = styled.div`
  z-index: 2011;
  position: relative;
  width: 100%;
  &:hover {
    outline: 2px solid #7aabc0;
  }
`

export const DivHelp = styled.div`
  font-family: Open Sans, sans-serif;
  position: absolute;
  bottom: 0px;
  left: -1px;
  background-color: #7aabc0;
  color: #fff;
  display: block;
  z-index: 4011;
  font-size: 12px;
  line-height: 20px;
  padding: 0 10px;
  height: 20px;
`
