import React from "react";
import { connect } from "../../../core";
import Body from "./Body";
import Row from "./Row";
import Content from "./Content";
import TextView from "./TextView";
import Avatar from "./AvatarView";
import ImageView from "./ImageView";
import ImageTextView from "./ImageTextView";
import DividerView from "./DividerView";
import FlatButtonView from "./FlatButtonView";
import IconButtonView from "./IconButtonView";
import ProductListView from "./ProductListView";
import YoutubeView from "./YoutubeView";
import EmptyView from "./EmptyView";
import FAButtonView from "./FAButtonView";
import TextGroupView from "./TextGroupView";
import ListSlideView from "./ListSlideView";
import CarouselLinkView from "./CarouselLinkView";
import CarouselAnimationView from "./CarouselAnimationView";
import ProductGroupView from "./ProductGroupView";
import MenuView from "./MenuView";
import MenuGroupView from "./MenuGroupView";
import MultiContentGroupView from "./MultiContentGroupView";
import SubcribeView from "./SubcribeView";
import SocialMediaView from "./SocialMediaView";
import RowIconText from "./RowIconText";
const LayoutHandler = ({ readOnly, ...props }) => {
  const component = dataComponent({ ...props });
  if (component[props.type]) {
    return component[props.type];
  } else {
    return <div />;
  }
};
const dataComponent = ({ ...props }) => {
  // console.log(props);
  return {
    Body: <Body {...props} />,
    Row: <Row {...props} />,
    Content: <Content {...props} />,
    TextView: <TextView {...props} />,
    DividerView: <DividerView {...props} />,
    AvatarView: <Avatar {...props} />,
    ImageView: <ImageView {...props} />,
    ImageTextView: <ImageTextView {...props} />,
    FlatButtonView: <FlatButtonView {...props} />,
    FAButtonView: <FAButtonView {...props} />,
    IconButtonView: <IconButtonView {...props} />,
    ProductListView: <ProductListView {...props} />,
    YoutubeView: <YoutubeView {...props} />,
    TextGroupView: <TextGroupView {...props} />,
    EmptyView: <EmptyView {...props} />,
    ListSlideView: <ListSlideView {...props} />,
    CarouselLinkView: <CarouselLinkView {...props} />,
    CarouselAnimationView: <CarouselAnimationView {...props} />,
    ProductGroupView: <ProductGroupView {...props} />,
    MenuView: <MenuView {...props} />,
    MenuGroupView: <MenuGroupView {...props} />,
    MultiContentGroupView: <MultiContentGroupView {...props} />,
    SubcribeView: <SubcribeView {...props} />,
    SocialMediaView: <SocialMediaView {...props} />,
    RowIconText: <RowIconText {...props} />,
  };
};

export default connect("readOnly")(LayoutHandler);
