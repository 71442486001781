import { includes } from "lodash";
export {
  choicesLanguages,
  languages,
  languagesListSiteId,
  labelObject,
} from "./localeConfig";
export {
  DEFAULT_SIDEBAR,
  DEFAULT_BG_SIDEBAR,
  HOVER_BG_MENU,
  ACTIVE_BG_MENU,
  BOX_SHADOW_COLOR,
  HeaderBox,
  ContentBox,
  DividerBox,
  ContentBoxCard,
} from "./constants";
export const ROLE_USER = {
  super_admin: "Super Admin",
  loyalty_program_admin: "Loyalty Program Admin",
  loyalty_program_user: "Loyalty Program User",
  merchant: "Merchant",
  account_admin: "Account Admin",
  client_admin: "Client Admin",
  operation: "Operation",
  branch_staff: "Branch Staff",
};

const escapeRegex = /([[\].#*$><+~=|^:(),"'`\s])/g;
let classCounter = 0;
export const generateClassName = (rule, styleSheet) => {
  classCounter += 1;

  if (process.env.NODE_ENV === "production") {
    return `c${classCounter}`;
  }

  if (styleSheet && styleSheet.options.classNamePrefix) {
    let prefix = styleSheet.options.classNamePrefix;
    // Sanitize the string as will be used to prefix the generated class name.
    prefix = prefix.replace(escapeRegex, "-");

    if (prefix.match(/^Mui/)) {
      return `${prefix}-${rule.key}`;
    }

    return `${prefix}-${rule.key}-${classCounter}`;
  }

  return `${rule.key}-${classCounter}`;
};

export const getUrlEndPoint = () => {
  const isStaging = includes(process.env.REACT_APP_SERVER, "staging");
  if (isStaging) {
    return "https://staging.playitright.com/products";
  }

  return "https://playitright.com/products";
};
