import React, { useRef, useState, Fragment } from "react";
import { Box } from "rebass";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { useTranslate } from "react-admin";
import { get } from "lodash";
import HeaderEditorLayout from "../HeaderEditorLayout";
import ShowContent from "./ShowContent";
import DialogShowContent from "./DialogShowContent";

const HeaderContent = (props) => {
  const { formData, widthScreen, heightScreen, categories } = props;
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const myref = useRef("myHeader");
  const [layout, setLayout] = useState(formData.header);
  const [layoutContent, setLayoutContent] = useState([
    {
      id: "en",
      data: formData.header,
      layoutSelected: formData.header,
    },
  ]);
  const websiteId = get(formData, "website_id", 0);
  const siteId = get(formData, "site_id", 0);

  const onChangeData = (data, dataRaw, localeId) => {
    setLayout(data);
    const params = [
      {
        id: localeId,
        data: data,
        layoutSelected: dataRaw,
      },
    ];
    setLayoutContent(params);
    props.updateField(`header`, dataRaw);
  };
  const onResetPage = (ref, locale) => {};
  const onShow = (value) => {
    if (!value) {
      setLayout(layoutContent[0].layoutSelected);
    }
    setOpen(value);
  };
  return (
    <Fragment>
      {!open && (
        <Box width={[1]} pl={[1]}>
          <HeaderEditorLayout
            ref={myref}
            myref={myref}
            onChangeData={(data, dataRaw) => onChangeData(data, dataRaw, "en")}
            data={layout}
            statusForm={"edit"}
            isShow={true}
            locale={"en"}
            onResetPage={() => onResetPage(myref, "en")}
            widthScreen={widthScreen - 250}
            heightScreen={heightScreen}
            websiteId={websiteId}
            siteId={siteId}
            categories={categories}
            settingHeader={formData}
          />
        </Box>
      )}
      {!open && (
        <PreviewBox>
          <Button
            onClick={() => onShow(true)}
            color='primary'
            style={{ textTransform: "capitalize" }}
          >
            {translate("resources.buttons.preview")}
          </Button>
        </PreviewBox>
      )}
      {open && (
        <DialogShowContent open={open} setOpen={setOpen}>
          <ShowContent
            localeId={"en"}
            content={layoutContent}
            translate={translate}
            widthScreen={widthScreen}
            heightScreen={heightScreen}
            websiteId={websiteId}
            onShow={onShow}
            open={open}
          />
        </DialogShowContent>
      )}
    </Fragment>
  );
};

const PreviewBox = styled.div`
  position: fixed;
  left: auto;
  top: 6px;
  right: 0px;
  z-index: 2000;
  padding: 0px 6px;
`;

export default HeaderContent;
