import React from "react";
import { Box, Flex } from "rebass";
import { get } from "lodash";
import {
  SelectInput,
  SwitchInput,
  Counter,
  TextInput,
  SketchColorPicker,
  BlockCounter,
} from "../../components";

import { fontFamily, textTransform, fontWeight } from "./utils";
import BoxTextAnimation from "./BoxTextAnimation";

const BoxText = (props) => {
  const { data, onUpdateValue, type } = props;
  const value = get(data, "value", "");
  const sizeValue = get(data, "size", "48pt");
  const sizeMediumValue = get(data, "sizeMedium", "30pt");
  const sizeSmallValue = get(data, "sizeSmall", "20pt");
  const size = sizeValue.split("pt").join("") * 1;
  const sizeMedium = sizeMediumValue.split("pt").join("") * 1;
  const sizeSmall = sizeSmallValue.split("pt").join("") * 1;

  const styleFont = {
    fontFamily: get(data, "family", "Rajdhani"),
    textAlign: get(data, "align", "center"),
    color: get(data, "color", "#ed747f"),
    textTransform: get(data, "textTransform", "normal"),
    fontWeight: get(data, "weight", "normal"),
    lineHeight: get(data, "lineHeight", "100%"),
    letterSpacing: get(data, "letterSpacing", 0),
  };
  const lineHeight = styleFont.lineHeight.split("%").join("") * 1;
  const letterSpacing = styleFont.letterSpacing.split("px").join("") * 1;

  const paddingMobile = get(data, "paddingMobile", "0px");
  const paddingDesktop = get(data, "paddingDesktop", "0px");
  const onChangeText = (value) => {
    onUpdateValue("value", value, props.index);
  };
  return (
    <Box width={[1]}>
      <TextInput
        value={value}
        onChange={onChangeText}
        name={`text-${type}`}
        title='Text'
        style={{ width: 420 }}
      />
      <Box width={"256px"}>
        <SwitchInput
          id={`carousel-item-use-${type}-visible-${props.index}`}
          title={"Show"}
          checked={data.visible}
          style={{ width: 100 }}
          onChange={(e) =>
            onUpdateValue("visible", e.target.checked, props.index)
          }
        />
        <SketchColorPicker
          title={"Color"}
          onUpdateColor={(color) => onUpdateValue("color", color, props.index)}
          color={styleFont.color}
        />
      </Box>
      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        py={[1]}
        className='child-wrap'
        alignItems={"center"}
      >
        <SwitchInput
          id={`carousel-item-${type}-use-italic-${props.index}`}
          title={"Italic"}
          checked={data.italic}
          style={{ width: 100 }}
          onChange={(e) =>
            onUpdateValue("italic", e.target.checked, props.index)
          }
        />
        <SelectInput
          id={`font-family_carousel-${type}_position_image-${props.index}`}
          title={"Font-Family"}
          value={styleFont.fontFamily}
          list={fontFamily}
          onChange={(event) =>
            onUpdateValue("family", event.target.value, props.index)
          }
        />

        <SelectInput
          id={`text-transform_carousel-${type}_position_image-${props.index}`}
          title={"Text Transform"}
          value={styleFont.textTransform}
          list={textTransform}
          onChange={(event) =>
            onUpdateValue("textTransform", event.target.value, props.index)
          }
        />
        <SelectInput
          id={`font-weight_carousel-${type}_position_image-${props.index}`}
          title={"Font-Weight"}
          value={styleFont.fontWeight}
          list={fontWeight}
          onChange={(event) =>
            onUpdateValue("weight", event.target.value, props.index)
          }
        />
      </Flex>
      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        py={[1]}
        className='child-wrap'
      >
        <Counter
          isDisabled={false}
          title='lineHeight'
          onSetValue={(value) =>
            onUpdateValue("lineHeight", `${value}%`, props.index)
          }
          value={lineHeight}
          interval={5}
          subtitle='%'
          minValue={100}
        />
        <Counter
          isDisabled={false}
          title='letterSpacing'
          onSetValue={(value) =>
            onUpdateValue("letterSpacing", `${value}`, props.index)
          }
          value={letterSpacing}
          interval={1}
          subtitle=''
          minValue={0}
        />
      </Flex>
      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        py={[1]}
        className='child-wrap'
        alignItems={"center"}
      >
        <Counter
          isDisabled={false}
          title='Font Size Desktop'
          onSetValue={(value) => onUpdateValue("size", `${value}pt`)}
          value={size}
          interval={1}
          subtitle='pt'
          minValue={5}
        />
        <Counter
          isDisabled={false}
          title='Font Size Tablet'
          onSetValue={(value) => onUpdateValue("sizeMedium", `${value}pt`)}
          value={sizeMedium}
          interval={1}
          subtitle='pt'
          minValue={5}
          style={{ marginRight: 16 }}
        />
        <Counter
          isDisabled={false}
          title='Font Size Mobile'
          onSetValue={(value) => onUpdateValue("sizeSmall", `${value}pt`)}
          value={sizeSmall}
          interval={1}
          subtitle='pt'
          minValue={5}
        />
      </Flex>

      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        className={"item-list"}
      >
        <BlockCounter
          idSwitch={`padding-image-${type}-mobile-${props.index}`}
          containerPadding={paddingMobile}
          onUpdatePadding={(v) =>
            onUpdateValue("paddingMobile", v, props.index)
          }
          title={"Padding Mobile"}
        />
        <BlockCounter
          idSwitch={`padding-image-${type}-desktop-${props.index}`}
          containerPadding={paddingDesktop}
          onUpdatePadding={(v) =>
            onUpdateValue("paddingDesktop", v, props.index)
          }
          title={"Padding Desktop"}
        />
      </Flex>

      <BoxTextAnimation {...props} />
    </Box>
  );
};
export default BoxText;
