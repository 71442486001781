import React from "react";
import PropTypes from "prop-types";
import Layout from "../Layout";

const RowIconText = (props) => {
  return <Layout {...props} type='RowIconText' />;
};

RowIconText.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};

export default RowIconText;
