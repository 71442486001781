import React from "react";
import { Flex } from "rebass";
import { Input } from "antd";
const { Search } = Input;

const SearchBox = (props) => {
  const { width, styleBox } = props;
  // console.log(width);
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {/* <Text fontSize={[2]} fontWeight={'bold'}>
        Search
      </Text> */}
      <Search
        placeholder='input search text'
        onSearch={(value) => console.log(value)}
        style={{ width: width ? width : "60%", ...styleBox }}
      />
    </Flex>
  );
};
export default SearchBox;
