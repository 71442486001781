import { Layout20 } from "../../TemplateImageSonak";
export const template18A = {
  "BY+d+u0R": {
    id: "BY+d+u0R",
    type: "Row1",
    props: {
      values: {
        name: "Row1",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
          },
          bgProps: {
            show: true,
            content: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-6YP.jpeg",
              size: "cover",
              ratio: 2,
              repeat: "no-repeat",
              position: "top center",
            },
          },
          padding: "0px",
          gradient: {
            show: false,
          },
          backgroundColor: "transparent",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "middle",
            gutter: 0,
            justify: "center",
            contentAlign: "center",
            contentJustify: "center",
          },
          bgProps: {
            show: true,
            content: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-6YP.jpeg",
              size: "cover",
              ratio: 3,
              repeat: "no-repeat",
              position: "top center",
            },
          },
          padding: "0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255,255,255,0)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              parent: "Row",
              desktop: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                padding: "0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    childOf: "Row",
    layout: Layout20,
    children: ["hsufiyp4"],
    content: {
      QxqFo8Gj: {
        id: "QxqFo8Gj",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>Campaign</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 24,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(255, 255, 255, 1)",
              fontSize: 40,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "hsufiyp4",
          idx: 0,
        },
        childOf: "Content",
        children: [],
      },
    },
  },
};
