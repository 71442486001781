import React from "react";
import { Box } from "rebass";
import { DropTarget } from "react-dnd";
import { connect } from "../../core";
import { target } from "./utils";

const EditLayout = ({ id, connectDropTarget, layoutState }) => {
  const {
    containerPadding,
    backgroundColor,
    align,
    title,
  } = layoutState.getItemJS(id).props.values;
  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        backgroundColor,
        display: "flex",
        flex: 1,
      }}
    >
      <div
        style={{
          padding: containerPadding,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flex: 1,
          justifyContent: align,
        }}
      >
        <Box width={[1]}>{title}-GridViewList</Box>
        {/* <ContainerDimensions>
          {({ width }) => {
            return (
              <Box width={[width]} style={{ backgroundColor: backgroundColor }}>
                {width > 0 && (
                  <div style={{ backgroundColor: "transparent" }}>
                    {setting.modeType === "multiRow" ? (
                      <ListMultiRow
                        newMan={valueText}
                        title={title}
                        newSetting={setting}
                        screenWidth={width > 1280 ? 1280 : width}
                      />
                    ) : (
                      <ListNewMan
                        newMan={valueText}
                        title={title}
                        products={products}
                        newSetting={setting}
                        screenWidth={width > 1280 ? 1280 : width}
                      />
                    )}
                  </div>
                )}
              </Box>
            )
          }}
        </ContainerDimensions> */}
      </div>
    </div>
  );
};

const LayoutContainer = DropTarget("GridListView", target, (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(EditLayout);

export default connect("layoutState", "readOnly")(LayoutContainer);

// export default LayoutContainer;s
