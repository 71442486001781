import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { DropTarget } from "react-dnd";
import { connect } from "../../core";
import { get } from "lodash";
import { DivEmptyContent } from "./styled";
import { Flex } from "rebass";
import { target } from "./utils";
import { ProductContents, ProductCollectionsContents } from "./components";

class EditLayout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      productsContent: [],
      isLoading: false,
      isLoadingFirst: true,
    };
  }

  componentWillUnmount() {
    this.unmounting = true;
  }

  renderEmptyList() {
    return (
      <div style={{ flex: 1, display: "flex" }}>
        <DivEmptyContent>Click here to add collection product</DivEmptyContent>
      </div>
    );
  }

  render() {
    const { id, connectDropTarget, layoutState, widthScreen } = this.props;
    //type, children, isOverCurrent,, readOnly showPrice,
    const { containerPadding, contentList } = layoutState.getItemJS(
      id
    ).props.values;
    const isEmpty = get(contentList, "value", "");
    const indexValue = widthScreen > 640 ? 1 : 0;
    const typeContent = get(contentList, "type", "");
    const valueContent = get(contentList, "value", "");
    return connectDropTarget(
      <div
        style={{
          boxSizing: "border-box",
          position: "relative",
          height: "100%",
          display: "flex",
          padding: containerPadding[indexValue],
          flex: 1,
        }}
      >
        {isEmpty === "" ? (
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            p={[2]}
          >
            Click Desktop Icon to Show or Click here to edit{" "}
          </Flex>
        ) : (
          // this.renderEmptyList()
          <Fragment>
            {typeContent === "Category" && (
              <ProductContents
                state={layoutState.getItemJS(id).props.values}
                indexValue={indexValue}
                widthScreen={widthScreen}
                typeContent={typeContent}
              />
            )}
            {typeContent === "Collection" && (
              <ProductCollectionsContents
                state={layoutState.getItemJS(id).props.values}
                indexValue={indexValue}
                widthScreen={widthScreen}
                valueContent={valueContent}
                typeContent={typeContent}
              />
            )}
          </Fragment>
        )}
        {/* {this.state.productsContent && this.state.productsContent.length > 0
          ? this.renderList(showPrice)
          : this.renderEmptyList()} */}
      </div>
    );
  }
}

EditLayout.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  isOverCurrent: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  children: PropTypes.array,
  layoutState: PropTypes.object,
  id: PropTypes.string,
};

const LayoutContainer = DropTarget(
  "ProductListView",
  target,
  (conn, monitor) => ({
    connectDropTarget: conn.dropTarget(),
    isOverCurrent: monitor.isOver({ shallow: true }),
  })
)(EditLayout);

export default connect(
  "layoutState",
  "widthScreen",
  "readOnly"
)(LayoutContainer);
