import accounts from "./accounts";
import attributes from "./attributes";
import attributeSet from "./attributeSet";
import buttons from "./buttons";
import branches from "./branches";
import catalogues from "./catalogues";
import categories from "./categories";
import channels from "./channels";
import contents from "./contents";
import clients from "./clients";
import dashboard from "./dashboard";
import earningRules from "./earningRules";
import filters from "./filters";
import login from "./login";
import levels from "./levels";
import loyaltyPrograms from "./loyaltyPrograms";
import menus from "./menus";
import members from "./members";
import merchants from "./merchants";
import memberVouchers from "./memberVouchers";
import notification from "./notification";
import orders from "./orders";
import provisions from "./provisions";
import programmes from "./programmes";
import payment_mode from "./payment_mode";
import pricebook from "./pricebook";
import products from "./products";
import promotions from "./promotions";
import rewards from "./rewards";
import register from "./register";
import registration from "./registration";
import returns from "./returns";
import users from "./users";
import transactions from "./transactions";
import setup from "./setup";
import settings from "./settings";
import sites from "./sites";
import shippings from "./shippings";
import websites from "./websites";
import variants from "./variants";
import webhooks from "./webhooks";
import payments from "./payments";
import layouts from "./layouts";
import sources from "./sources";
import sourceGroups from "./sourceGroups";
import faq from "./faq";
import reviews from "./reviews";
import errors from "./errors";
import collections from "./collections";
import inventories from "./inventories";
import customers from "./customers";
import stores from "./stores";
import topsellings from "./topsellings";
export default {
  ...accounts,
  ...attributes,
  ...attributeSet,
  ...branches,
  ...buttons,
  ...catalogues,
  ...channels,
  ...categories,
  ...collections,
  ...contents,
  ...clients,
  ...customers,
  ...dashboard,
  ...earningRules,
  ...errors,
  ...filters,
  ...faq,
  ...levels,
  ...inventories,
  ...login,
  ...loyaltyPrograms,
  ...members,
  ...memberVouchers,
  ...menus,
  ...merchants,
  ...notification,
  ...orders,
  ...programmes,
  ...provisions,
  ...payment_mode,
  ...pricebook,
  ...products,
  ...promotions,
  ...rewards,
  ...register,
  ...registration,
  ...returns,
  ...reviews,
  ...transactions,
  ...users,
  ...setup,
  ...settings,
  ...sites,
  ...shippings,
  ...websites,
  ...variants,
  ...webhooks,
  ...payments,
  ...layouts,
  ...sources,
  ...sourceGroups,
  ...stores,
  ...topsellings,
};
