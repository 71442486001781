import React, { useState } from "react";
import { Box, Flex } from "rebass";
import blue from "@material-ui/core/colors/blue";
import { useTranslate } from "react-admin";
import ChipInput from "material-ui-chip-input";
import { get, split, join } from "lodash";
import Chip from "@material-ui/core/Chip";
import { PanelView } from "../../../components";
const defaultNotification = {
  order: "",
  return_refund: "",
};

const defaultChipRenderer = (
  {
    text,
    isFocused,
    isDisabled,
    isReadOnly,
    handleClick,
    handleDelete,
    className,
  },
  key
) => (
  <Chip
    key={key}
    className={className}
    style={{
      pointerEvents: isDisabled || isReadOnly ? "none" : undefined,
      backgroundColor: isFocused ? blue[300] : undefined,
      textTransform: "none",
    }}
    onClick={handleClick}
    onDelete={handleDelete}
    label={<span style={{ textTransform: "none" }}>{text}</span>}
  />
);
export default (props) => {
  //   console.log(props);
  const {
    record,
    updateField,
    basePath,
    customBackUrl,
    initialValues,
    pathUrl,
    typePostUrl,
    sourceApi,
    ...rest
  } = props;
  const translate = useTranslate();
  const mynotification =
    get(initialValues, "notification", defaultNotification) !== null
      ? get(initialValues, "notification", defaultNotification)
      : defaultNotification;

  const defaultOrder =
    mynotification.order === "" ? [] : split(mynotification.order, ",");
  const defaultReturnRefund =
    mynotification.return_refund === ""
      ? []
      : split(mynotification.return_refund, ",");

  const [state, setState] = useState({
    notification: {
      order: defaultOrder,
      return_refund: defaultReturnRefund,
    },
  });
  const onChange = (k, v) => {
    setState({
      ...state,
      [k]: v,
    });
  };
  const onChangeNotification = (k, v) => {
    const newNotifications = {
      ...state.notification,
      [k]: v,
    };
    onChange("notification", newNotifications);
    let newNotification = {
      order: join(state.notification.order, ","),
      return_refund: join(state.notification.return_refund, ","),
    };
    if (k === "order") {
      newNotification.order = join(v, ",");
    } else if (k === "return_refund") {
      newNotification.return_refund = join(v, ",");
    }
    updateField("notification", newNotification);
  };
  const handleAddChip = (key, valueChip) => {
    const values = [...state.notification[key], valueChip];
    onChangeNotification(key, values);
  };
  const handleDeleteChip = (key, valueChip) => {
    const values = state.notification[key].filter((v) => v !== valueChip);
    onChangeNotification(key, values);
  };
  let valueOrder =
    typeof state.notification.order === "string" ||
    state.notification.order instanceof String
      ? split(state.notification.order, ",")
      : state.notification.order;

  let valueReturnRefund =
    typeof state.notification.return_refund === "string" ||
    state.notification.return_refund instanceof String
      ? split(state.notification.return_refund, ",")
      : state.notification.return_refund;
  return (
    <Box width={[1]} py={[3]}>
      <PanelView
        title={"resources.websites.labels.notification"}
        id={"notifications-notify"}
        {...rest}
      >
        <Flex width={[1]} flexDirection={"column"}>
          <Box>
            <ChipInput
              label={translate("resources.websites.fields.order")}
              value={valueOrder}
              onAdd={(chip) => handleAddChip("order", chip)}
              onDelete={(chip, index) => handleDeleteChip("order", chip, index)}
              InputLabelProps={{ shrink: true }}
              fullWidth={true}
              chipRenderer={defaultChipRenderer}
            />
          </Box>
          <Box>
            <ChipInput
              label={translate("resources.websites.fields.return_refund")}
              value={valueReturnRefund}
              onAdd={(chip) => handleAddChip("return_refund", chip)}
              onDelete={(chip, index) =>
                handleDeleteChip("return_refund", chip, index)
              }
              InputLabelProps={{ shrink: true }}
              fullWidth={true}
              chipRenderer={defaultChipRenderer}
            />
          </Box>
        </Flex>
      </PanelView>
    </Box>
  );
};
