import React from "react";
import { Flex, Text, Box } from "rebass";
import { FontelloSvg } from "../../../components/Icons";
import { useTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const IconButton = ({ size, icon, color, onClick }) => (
  <Flex justifyContent="center" onClick={onClick} style={{ cursor: "pointer" }}>
    <FontelloSvg
      name={icon || "userCircle"}
      fill={color || grey[300]}
      size={size || "24px"}
    />
  </Flex>
);

const isDefaultColor = (qty, min, color) => {
  return qty <= min ? grey[300] : color;
};
const isDefaultColorMax = (qty, max, color) => {
  return qty >= max ? grey[300] : color;
};

export const Quantity = ({ min = 1, max = 50, qty = 1, size, onQtyChange }) => {
  const theme = useTheme();
  const accentColor = theme.palette.primary[500];
  const iconSize = size === "small" ? "1.4rem" : "2rem";
  const fontSize = size === "small" ? "1rem" : "1.6rem";
  function onAddQty(qty) {
    if (qty < max) {
      const newQty = qty + 1;
      onQtyChange(newQty);
    }
  }

  function onMinQty(qty) {
    if (qty > min) {
      const newQty = qty - 1;
      onQtyChange(newQty);
    }
  }

  return (
    <Flex justifyContent="center" alignItems="center">
      <IconButton
        icon="minusCircle"
        color={isDefaultColor(qty, min, accentColor)}
        size={iconSize}
        onClick={() => onMinQty(qty)}
      />
      <Box mx={3}>
        <Text fontSize={fontSize} color={accentColor}>
          {qty}
        </Text>
      </Box>
      <IconButton
        icon="plusCircle"
        // color={accentColor}
        color={isDefaultColorMax(qty, max, accentColor)}
        size={iconSize}
        onClick={() => onAddQty(qty)}
      />
    </Flex>
  );
};
