import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  StaticLayout,
  LayoutState,
  Row1,
  Row2,
  Row3,
  Row4,
  Row5,
  Row6,
  Row7,
  Row9,
  Row8,
  MultiRow,
  Body,
  Content,
  FlatButtonView,
  FAButtonView,
  IconButtonView,
  TextView,
  ImageView,
  ImageTextView,
  AvatarView,
  DividerView,
  ProductListView,
  YoutubeView,
  TextGroupView,
  ListSlideView,
  EmptyView,
  CarouselLinkView,
  CarouselAnimationView,
  ProductGroupView,
  MultiContentGroupView,
  SubcribeView,
  SocialMediaView,
  MenuView,
  MenuGroupView,
  RowIconText,
} from "./layout";

const comps = {
  Row1,
  Row2,
  Row3,
  Row4,
  Row5,
  Row6,
  Row7,
  Row9,
  Row8,
  MultiRow,
  Body,
  Content,
  TextView,
  ImageView,
  ImageTextView,
  AvatarView,
  DividerView,
  FlatButtonView,
  FAButtonView,
  IconButtonView,
  ProductListView,
  YoutubeView,
  TextGroupView,
  ListSlideView,
  EmptyView,
  CarouselLinkView,
  CarouselAnimationView,
  ProductGroupView,
  MultiContentGroupView,
  SubcribeView,
  SocialMediaView,
  MenuView,
  MenuGroupView,
  RowIconText,
};

class StaticPage extends Component {
  constructor(props) {
    super(props);
    // console.log(props.data)
    this.state = {
      layoutState: props.data
        ? LayoutState.fromRaw(props.data)
        : new LayoutState(),
    };
    // console.log(props.data)
  }

  render() {
    const { heightScreen, widthScreen, websiteId, settings } = this.props;
    return (
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        <StaticLayout
          layoutState={this.state.layoutState}
          components={comps}
          token={this.props.token}
          locale={this.props.locale}
          heightScreen={heightScreen}
          widthScreen={widthScreen}
          websiteId={websiteId}
          settings={settings}
        />
      </div>
    );
  }
}
StaticPage.propTypes = {
  data: PropTypes.object,
  token: PropTypes.string,
  locale: PropTypes.string,
};

export default StaticPage;
