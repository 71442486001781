import React, { Component } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import Quill from "quill";
import "./richtextinput.css";

class RTInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: true,
    };
  }
  componentDidMount() {
    const { input, toolbar } = this.props;
    let value = "";
    if (input !== undefined && input.props.value !== undefined) {
      value = input.props.value;
    }
    var Font = Quill.import("formats/font");
    Font.whitelist = ["Manrope", "Roboto", "Rajdhani"];
    Quill.register(Font, true);
    this.quill = new Quill(this.divRef, {
      modules: { toolbar },
      theme: "snow",
    });

    this.quill.pasteHTML(value);
    //this.quill.clipboard.dangerouslyPasteHTML(value);

    this.editor = this.divRef.querySelector(".ql-editor");
    this.quill.on("text-change", debounce(this.onTextChange, 500));
  }

  componentWillUnmount() {
    this.quill.off("text-change", this.onTextChange);
    this.quill = null;
  }

  onTextChange = () => {
    //console.log(this.editor.innerHTML);
    if (this.props.input.props.onChange) {
      this.props.input.props.onChange(this.editor.innerHTML);
    }
  };

  updateDivRef = (ref) => {
    this.divRef = ref;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.input.value !== this.props.input.value) {
      this.quill.pasteHTML(nextProps.input.value);
    }
  }
  render() {
    return (
      <div>
        <div className='aor-rich-text-input'>
          <div ref={this.updateDivRef} style={this.props.style} />
        </div>
      </div>
    );
  }
}
RTInput.propTypes = {
  addField: PropTypes.bool.isRequired,
  addLabel: PropTypes.bool.isRequired,
  input: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.object,
  source: PropTypes.string,
  toolbar: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  style: PropTypes.object,
};

RTInput.defaultProps = {
  addField: true,
  addLabel: true,
  options: {},
  record: {},
  style: {},
  toolbar: true,
};

export default RTInput;
