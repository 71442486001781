import React from "react"
import { get } from "lodash"
import Slider from "react-slick"
import ProductGroupCard from "./ProductGroupCard"
import "./slick.css"

const ProductGroupCarousel = (props) => {
  const { data, widthContainer } = props

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    centerPadding: "20px",
    slidesToShow: 1,
    rows: 2,
    slidesPerRow: 2,
    autoplay: false,
    autoplaySpeed: false,
  }
  const products = get(data, "products", [])
  // const setting = get(data, "setting", settings)
  // console.log(setting)

  return (
    <div
      style={{
        backgroundColor: "transparent",
        width: widthContainer,
      }}
    >
      <Slider {...settings}>
        {products.map((product, index) => {
          return (
            <div key={index}>
              <div>
                <ProductGroupCard
                  product={product}
                  widthContainer={widthContainer}
                />
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}
export default ProductGroupCarousel
