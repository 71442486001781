import { has, get } from "lodash";
export const getPhoto = function (photos) {
  var src = "";

  if (has(photos, "photo_set")) {
    var photoSet = get(photos, "photo_set");
    var key = Object.keys(photoSet);
    var path = `photo_set.${key[0]}.photos[0]`;
    src = get(photos, path, "");
  } else if (has(photos, "*")) {
    src = get(photos, "*.photos[0]", "");
  }
  return src;
};

export const dataStatus = [
  { id: "New", name: "New" },
  { id: "Processing", name: "Processing" },
  { id: "Delivered", name: "Delivered" },
  { id: "Cancelled", name: "Cancelled" },
];

export const dataTypeAmendment = [
  { id: "Return", name: "Return" },
  { id: "Refund", name: "Refund" },
];
//{ id: "Exchange", name: "Exchange" },

export const dataReasonAmendment = [
  { id: "Damaged", name: "Damaged" },
  { id: "Faulty item", name: "Faulty item" },
  { id: "Wrong size", name: "Wrong size" },
  { id: "Wrong color", name: "Wrong color" },
  { id: "Quality", name: "Quality" },
  { id: "Others", name: "Others" },
];
export const dataReasonAmendmentRefund = [
  { id: "Missing Item", name: "Missing Item" },
  { id: "Lost Shipment", name: "Lost Shipment" },
  { id: "Cancel Order", name: "Cancel Order" },
];

export const tabs = [
  { id: "order", name: "resources.orders.tabs.order", link: "" },
  {
    id: "shipment",
    name: "resources.orders.tabs.shipment",
    link: "shipment",
  },
  {
    id: "amendment",
    name: "resources.orders.tabs.amendment",
    link: "amendment",
  },
  {
    id: "timeline",
    name: "resources.orders.tabs.timeline",
    link: "timeline",
  },
];

export const checkDefaultIndex = (pathname) => {
  let index = 0;
  if (pathname.includes("shipment")) {
    index = 1;
  } else if (pathname.includes("amendment")) {
    index = 2;
  } else if (pathname.includes("timeline")) {
    index = 3;
  }

  return index;
};

export const dummyShipment = [
  {
    id: 1,
    provider: "Lalamove",
    status: "Picked",
    reference: "A12345",
    cost: 100.5,
    tracking: "T123456789",
    created_at: "2020-09-24T08:53:01.495676Z",
    packaging: "Size A",
    height: 20,
    width: 20,
    length: 20,
    weight: 0.5,
  },
  {
    id: 2,
    provider: "Lalamove",
    status: "Requested",
    reference: "LA012345",
    cost: 5,
    tracking: "A1234567890",
    created_at: "2020-09-23T12:53:01.495676Z",
    packaging: "Size A",
    height: 20,
    width: 20,
    length: 20,
    weight: 0.5,
  },
];

export const dummyAmendment = [
  {
    id: 1,
    type: "Refund",
    provider: "Lalamove",
    status: "Completed",
    shipment_status: "Delivered",
    reference: "A12345",
    cost: 100.5,
    tracking: "T123456789",
    created_at: "2020-09-24T08:53:01.495676Z",
    packaging: "Size A",
    height: 20,
    width: 20,
    length: 20,
    weight: 0.5,
    item_cancel: 1,
    remarks: "",
    reasons: "Wrong color",
    refund_amount: 2000.5,
    items: [
      {
        id: 8,
        site_id: 1,
        cart_id: 19,
        product_id: 962,
        name: "GEL-KAYANO 5 OG",
        photo:
          "https://storage.sg.content-cdn.io/cdn-cgi/image/1024,1024,quality=75,format=auto/in-resources/dce48adf-57c2-4c14-91cb-2a23e2680e87/Images/ProductImages/Source/1021A163_020_0020013136_RT_Large.jpg",
        sku: "1021A163.020.9H",
        variant: {
          WIDTH: "STANDARD (D)",
          COLORS: "POLAR SHADE/SMOKE BLUE",
          "SIZE(US)": "9H",
        },
        bundles: null,
        customizations: null,
        qty: 1,
        price: "132.30000000000001136868377216160297393798828125",
        discount_price: "132.30000000000001136868377216160297393798828125",
        tier_price: "132.3000000000000",
        subtotal: "132.3000000000000",
        next_discount: null,
        created_at: "2020-09-04T10:38:33.651503Z",
        updated_at: "2020-09-06T08:54:08.517685142Z",
      },
      {
        id: 10,
        site_id: 1,
        cart_id: 19,
        product_id: 968,
        name: "GEL-BND",
        photo:
          "https://storage.sg.content-cdn.io/cdn-cgi/image/1024,1024,quality=75,format=auto/in-resources/dce48adf-57c2-4c14-91cb-2a23e2680e87/Images/ProductImages/Source/1021A313_400_0020019843_RT_Large.jpg",
        sku: "1021A313.400.9",
        variant: {
          COLORS: "ELECTRIC BLUE/ELECTRIC BLUE",
          "SIZE(US)": "9",
        },
        bundles: null,
        customizations: null,
        qty: 1,
        price: "139",
        discount_price: "139",
        tier_price: "139",
        subtotal: "139",
        next_discount: null,
        created_at: "2020-09-05T19:06:51.567758Z",
        updated_at: "2020-09-06T08:54:08.52296888Z",
      },
    ],
  },
  {
    id: 2,
    type: "Exchange",
    provider: "Lalamove",
    status: "Completed",
    shipment_status: "Delivered",
    reference: "LA012345",
    cost: 5,
    tracking: "A1234567890",
    created_at: "2020-09-23T12:53:01.495676Z",
    packaging: "Size A",
    height: 20,
    width: 20,
    length: 20,
    weight: 0.5,
    item_cancel: 1,
    remarks: "",
    reasons: "Wrong size",
    refund_amount: 1000.5,
    items: [
      {
        id: 8,
        site_id: 1,
        cart_id: 19,
        product_id: 962,
        name: "GEL-KAYANO 5 OG",
        photo:
          "https://storage.sg.content-cdn.io/cdn-cgi/image/1024,1024,quality=75,format=auto/in-resources/dce48adf-57c2-4c14-91cb-2a23e2680e87/Images/ProductImages/Source/1021A163_020_0020013136_RT_Large.jpg",
        sku: "1021A163.020.9H",
        variant: {
          WIDTH: "STANDARD (D)",
          COLORS: "POLAR SHADE/SMOKE BLUE",
          "SIZE(US)": "9H",
        },
        bundles: null,
        customizations: null,
        qty: 1,
        price: "132.30000000000001136868377216160297393798828125",
        discount_price: "132.30000000000001136868377216160297393798828125",
        tier_price: "132.3000000000000",
        subtotal: "132.3000000000000",
        next_discount: null,
        created_at: "2020-09-04T10:38:33.651503Z",
        updated_at: "2020-09-06T08:54:08.517685142Z",
      },
      {
        id: 10,
        site_id: 1,
        cart_id: 19,
        product_id: 968,
        name: "GEL-BND",
        photo:
          "https://storage.sg.content-cdn.io/cdn-cgi/image/1024,1024,quality=75,format=auto/in-resources/dce48adf-57c2-4c14-91cb-2a23e2680e87/Images/ProductImages/Source/1021A313_400_0020019843_RT_Large.jpg",
        sku: "1021A313.400.9",
        variant: {
          COLORS: "ELECTRIC BLUE/ELECTRIC BLUE",
          "SIZE(US)": "9",
        },
        bundles: null,
        customizations: null,
        qty: 1,
        price: "139",
        discount_price: "139",
        tier_price: "139",
        subtotal: "139",
        next_discount: null,
        created_at: "2020-09-05T19:06:51.567758Z",
        updated_at: "2020-09-06T08:54:08.52296888Z",
      },
    ],
  },
  {
    id: 3,
    type: "Return",
    provider: "Lalamove",
    status: "Completed",
    shipment_status: "Delivered",
    reference: "LA012345",
    cost: 5,
    tracking: "A1234567890",
    created_at: "2020-09-23T12:53:01.495676Z",
    packaging: "Size A",
    height: 20,
    width: 20,
    length: 20,
    weight: 0.5,
    item_cancel: 1,
    remarks: "",
    reasons: "Wrong color",
    refund_amount: 500.5,
    items: [
      {
        id: 8,
        site_id: 1,
        cart_id: 19,
        product_id: 962,
        name: "GEL-KAYANO 5 OG",
        photo:
          "https://storage.sg.content-cdn.io/cdn-cgi/image/1024,1024,quality=75,format=auto/in-resources/dce48adf-57c2-4c14-91cb-2a23e2680e87/Images/ProductImages/Source/1021A163_020_0020013136_RT_Large.jpg",
        sku: "1021A163.020.9H",
        variant: {
          WIDTH: "STANDARD (D)",
          COLORS: "POLAR SHADE/SMOKE BLUE",
          "SIZE(US)": "9H",
        },
        bundles: null,
        customizations: null,
        qty: 1,
        price: "132.30000000000001136868377216160297393798828125",
        discount_price: "132.30000000000001136868377216160297393798828125",
        tier_price: "132.3000000000000",
        subtotal: "132.3000000000000",
        next_discount: null,
        created_at: "2020-09-04T10:38:33.651503Z",
        updated_at: "2020-09-06T08:54:08.517685142Z",
      },
      {
        id: 10,
        site_id: 1,
        cart_id: 19,
        product_id: 968,
        name: "GEL-BND",
        photo:
          "https://storage.sg.content-cdn.io/cdn-cgi/image/1024,1024,quality=75,format=auto/in-resources/dce48adf-57c2-4c14-91cb-2a23e2680e87/Images/ProductImages/Source/1021A313_400_0020019843_RT_Large.jpg",
        sku: "1021A313.400.9",
        variant: {
          COLORS: "ELECTRIC BLUE/ELECTRIC BLUE",
          "SIZE(US)": "9",
        },
        bundles: null,
        customizations: null,
        qty: 1,
        price: "139",
        discount_price: "139",
        tier_price: "139",
        subtotal: "139",
        next_discount: null,
        created_at: "2020-09-05T19:06:51.567758Z",
        updated_at: "2020-09-06T08:54:08.52296888Z",
      },
    ],
  },
];

export const dummyOrder = {
  id: 0,
  site_id: 1,
  customer_id: 2,
  customer_info: {
    id: 1,
    zip: "11730",
    city: "Jakarta Selatan",
    name: "Ronadi",
    phone: "+6287888790908",
    address: "Jalan Jendral Sudirman Kav 12",
    country: "Indonesia",
    remarks: "",
    site_id: 1,
    address2: "",
    province: "DKI Jakarta",
    created_at: "2020-09-06T06:53:01.495676Z",
    updated_at: "2020-09-06T06:53:01.495676Z",
    customer_id: 2,
  },
  note: null,
  issued_at: "2020-09-06T08:54:08.544053Z",
  status: "pending payment",
  assigned_to: null,
  ref: "20200906-BYFK",
  items: [
    {
      id: 8,
      site_id: 1,
      cart_id: 19,
      product_id: 962,
      name: "GEL-KAYANO 5 OG",
      photo:
        "https://storage.sg.content-cdn.io/cdn-cgi/image/1024,1024,quality=75,format=auto/in-resources/dce48adf-57c2-4c14-91cb-2a23e2680e87/Images/ProductImages/Source/1021A163_020_0020013136_RT_Large.jpg",
      sku: "1021A163.020.9H",
      variant: {
        WIDTH: "STANDARD (D)",
        COLORS: "POLAR SHADE/SMOKE BLUE",
        "SIZE(US)": "9H",
      },
      bundles: null,
      customizations: null,
      qty: 1,
      price: "132.30000000000001136868377216160297393798828125",
      discount_price: "132.30000000000001136868377216160297393798828125",
      tier_price: "132.3000000000000",
      subtotal: "132.3000000000000",
      next_discount: null,
      created_at: "2020-09-04T10:38:33.651503Z",
      updated_at: "2020-09-06T08:54:08.517685142Z",
    },
    {
      id: 10,
      site_id: 1,
      cart_id: 19,
      product_id: 968,
      name: "GEL-BND",
      photo:
        "https://storage.sg.content-cdn.io/cdn-cgi/image/1024,1024,quality=75,format=auto/in-resources/dce48adf-57c2-4c14-91cb-2a23e2680e87/Images/ProductImages/Source/1021A313_400_0020019843_RT_Large.jpg",
      sku: "1021A313.400.9",
      variant: {
        COLORS: "ELECTRIC BLUE/ELECTRIC BLUE",
        "SIZE(US)": "9",
      },
      bundles: null,
      customizations: null,
      qty: 1,
      price: "139",
      discount_price: "139",
      tier_price: "139",
      subtotal: "139",
      next_discount: null,
      created_at: "2020-09-05T19:06:51.567758Z",
      updated_at: "2020-09-06T08:54:08.52296888Z",
    },
  ],
  customs: {},
  amounts: {
    total: 271.3,
    coupon: 0,
    custom: 0,
    subtotal: 271.3,
    shipping: 0,
    packaging: 0,
    subsidy: 0,
    total_items: 0,
    interest: 0,
    fee: 0,
    cashback: 0,
  },
  payment_code: null,
  payment_info: null,
  tracking: null,
  tags: null,
  reason: null,
  photos: null,
  created_at: "2020-09-06T08:54:08.544892Z",
  updated_at: "2020-09-06T08:54:08.556356Z",
};
