import React, { Component } from "react";
import PropTypes from "prop-types";
import { Box } from "rebass";
import { get } from "lodash";
import {
  SwitchInput,
  defaultGradient,
  Alignment,
  SelectInput,
  Title,
} from "../";
import { ContentDetail } from "../../actions";
import { BackgroundImage } from "./dataList";
import { ContentBG } from "./Fields";
import MobileContentValues from "./MobileContentValues";
const dataDirection = [
  { text: "Column", value: "column" },
  { text: "Row", value: "row" },
];

const defaultMobile = {
  padding: "0px",
  type: "%",
  contentWidth: 100,
};

class ItemBackgroundContent extends Component {
  constructor(props) {
    super(props);
    const { defaultItemProps, itemContent, indexContent } = props;
    const {
      backgroundColor,
      backgroundImage,
      isBackgroundImage,
      bgProps,
      width,
      alignment,
      padding,
      backgroundColorParent,
      isGradient,
      gradient,
      mobile,
    } = itemContent.props.values;
    const widthContentParent = width ? width : defaultItemProps.width;
    const alignContent = alignment ? alignment : defaultItemProps.alignment;
    const bgColorParent = backgroundColorParent
      ? backgroundColorParent
      : defaultItemProps.backgroundColorParent;

    // const typeBackgroundContent = typeBackground
    //   ? typeBackground
    //   : defaultItemProps.typeBackground
    // const backgroundCarouselParent = backgroundCarousel
    //   ? backgroundCarousel
    //   : defaultItemProps.backgroundCarousel

    this.state = {
      indexContent: indexContent,
      backgroundColorParent: bgColorParent,
      backgroundColor: backgroundColor,
      backgroundImage: backgroundImage,
      isBackgroundImage: isBackgroundImage,
      bgProps: bgProps,
      padding: padding,
      type: widthContentParent.type || "%",
      contentWidth: widthContentParent.contentWidth || 100,
      maxValueWidth: widthContentParent.type === "px" ? 0 : 100,
      interval: widthContentParent.type === "px" ? 20 : 1,
      valign: alignContent.vertical,
      align: alignContent.horizontal,
      isGradient: isGradient || false,
      gradient: gradient || defaultGradient,
      isMobileView: get(itemContent, "props.values.isMobileView", true),
      isDesktopView: get(itemContent, "props.values.isDesktopView", true),
      flexDirection: get(itemContent, "props.values.flexDirection", "column"),
      alignItems: get(itemContent, "props.values.alignItems", "flex-start"),
      justifyContent: get(
        itemContent,
        "props.values.justifyContent",
        "flex-start"
      ),
      mobile: mobile || defaultMobile,
    };
  }
  onUpdateUrl = (value) => {
    this.setState({ backgroundImage: value });

    this.updateContent("backgroundImage", value);
  };
  onToggle = (e) => {
    this.setState({ isBackgroundImage: e.target.checked });
    // this.updateContent("isBackgroundImage", e.target.checked)
  };
  onChangeBackgroundImageProperties = (event, value, key) => {
    let { bgProps } = this.state;
    bgProps[key] = event.target.value;
    this.setState({
      bgProps: bgProps,
    });
    this.updateContent("bgProps", bgProps);
  };

  onSetValue = (key, value) => {
    this.setState({ [key]: value });
    this.updateContent(key, value);
  };

  onChangeType = (event) => {
    let width = 0;
    let content = this.state.contentWidth;
    let interval = 20;
    if (event.target.value === "%") {
      width = 100;
      content = 100;
      interval = 1;
    }
    this.setState({
      type: event.target.value,
      maxValueWidth: width,
      contentWidth: content,
      interval: interval,
    });
    this.updateContent("type", event.target.value);
  };

  updateContent = (key, value) => {
    const {
      backgroundColor,
      backgroundImage,
      isBackgroundImage,
      bgProps,
      padding,
      type,
      contentWidth,
      valign,
      align,
      backgroundColorParent,
      isGradient,
      gradient,
      mobile,
    } = this.state;
    const values = {
      backgroundColorParent:
        key === "backgroundColorParent" ? value : backgroundColorParent,
      backgroundColor: key === "backgroundColor" ? value : backgroundColor,
      backgroundImage: key === "backgroundImage" ? value : backgroundImage,
      isBackgroundImage:
        key === "isBackgroundImage" ? value : isBackgroundImage,
      bgProps: key === "bgProps" ? value : bgProps,
      padding: key === "padding" ? value : padding,
      width: {
        type: key === "type" ? value : type,
        contentWidth: key === "contentWidth" ? value : contentWidth,
      },
      alignment: {
        vertical: key === "valign" ? value : valign,
        horizontal: key === "align" ? value : align,
      },
      isGradient: key === "isGradient" ? value : isGradient,
      gradient: key === "gradient" ? value : gradient,
      isMobileView: key === "isMobileView" ? value : this.state.isMobileView,
      isDesktopView: key === "isDesktopView" ? value : this.state.isDesktopView,
      flexDirection: key === "flexDirection" ? value : this.state.flexDirection,
      alignItems: key === "alignItems" ? value : this.state.alignItems,
      justifyContent:
        key === "justifyContent" ? value : this.state.justifyContent,
      mobile: mobile,
    };
    this.props.updateBackground(values);
  };

  render() {
    return (
      <Box width={[1]}>
        <ContentDetail>
          <Title title={"Mobile Settings"} />
          <MobileContentValues
            mobile={this.state.mobile}
            onSetValue={(k, v) => this.onSetValue(k, v)}
          />
        </ContentDetail>
        <ContentDetail>
          <SwitchInput
            id={`view-mobile-content`}
            title={"Visible on Mobile Screen"}
            checked={this.state.isMobileView}
            onChange={(e) => this.onSetValue("isMobileView", e.target.checked)}
          />
          <SwitchInput
            id={`view-desktop-content`}
            title={"Visible on Desktop Screen"}
            checked={this.state.isDesktopView}
            onChange={(e) => this.onSetValue("isDesktopView", e.target.checked)}
          />
          <SelectInput
            id={`flex-Direction`}
            title={"Direction"}
            value={this.state.flexDirection}
            list={dataDirection}
            onChange={(e) => this.onSetValue("flexDirection", e.target.value)}
          />
          <Alignment
            value={this.state.justifyContent}
            onChange={(value) => this.onSetValue("justifyContent", value)}
            title='JustifyContent'
          />
          <Alignment
            value={this.state.alignItems}
            onChange={(value) => this.onSetValue("alignItems", value)}
            title='AlignItems'
          />
        </ContentDetail>
        <ContentDetail>
          <Title title={"Content Position"} />
          <Alignment
            value={this.state.valign}
            onChange={(value) => this.onSetValue("valign", value)}
            title='Content Vertical Alignment'
          />
          <Alignment
            value={this.state.align}
            onChange={(value) => this.onSetValue("align", value)}
            title='Content Horizontal Alignment'
          />
        </ContentDetail>
        <ContentDetail>
          <SwitchInput
            id={`bg-image-content${this.state.indexContent}`}
            title={"Using background image"}
            checked={this.state.isBackgroundImage}
            onChange={(e) => this.onToggle(e)}
          />
          {this.state.isBackgroundImage && (
            <BackgroundImage
              contentId={"itembackgroundContent"}
              state={this.state}
              onChangeBackgroundImageProperties={
                this.onChangeBackgroundImageProperties
              }
              onSetValue={this.onSetValue}
            />
          )}
        </ContentDetail>
        <ContentBG
          state={this.state}
          onChangeType={this.onChangeType}
          onSetValue={this.onSetValue}
        />
      </Box>
    );
  }
}

ItemBackgroundContent.propTypes = {
  updateBackground: PropTypes.func,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.object,
  bgProps: PropTypes.object,
  isBackgroundImage: PropTypes.bool,
  title: PropTypes.string,
  typeBackground: PropTypes.number,
  backgroundCarousel: PropTypes.object,
};

ItemBackgroundContent.defaultProps = {
  title: "Background Row",
  backgroundImage: { url: "", urlMobile: "" },
  backgroundColor: "#FFFFFF",
  isBackgroundImage: false,
  bgProps: {
    size: "cover",
    position: "center",
    repeat: "no-repeat",
    ratio: 1.2,
  },
  padding: "0px",
  width: { type: "%", contentWidth: 100 },
  alignment: {
    vertical: "center",
    horizontal: "center",
  },
};

export default ItemBackgroundContent;
