/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import BannerAnim from "rc-banner-anim";
// import "rc-banner-anim/assets/index.css"
import RightImageElement from "./RightImageElement";
import LeftImageElement from "./LeftImageElement";

const { Element } = BannerAnim;
const BgElement = Element.BgElement;

const CarouselList = (props) => {
  const { data, widthContainer } = props;
  const paddingTop = get(data, "container.paddingTopQuery", ["76px", "123px"]);
  const bgColor = get(data, "container.backgroundColor", "#650101");
  const typeBanner = get(data, "container.type", "across");
  const isAuto = false; //const isAuto = get(data, "container.auto", false)
  const heightContainer = get(data, "container.height", "570px");
  const list = get(data, "list", []);
  const autoPlaySpeed = get(data, "container.autoPlaySpeed", 5000);
  const vertical = get(data, "container.vertical", "center");
  const horizontal = get(data, "container.horizontal", "center");

  const containerHeightMobile = get(data, "container.heightMobile", "480px");
  const chM = containerHeightMobile.split("px").join("") * 1;
  const currentHeight = widthContainer < 480 ? chM : heightContainer;
  const fullHeight = get(data, "container.fullHeight", false);
  // console.log(data);
  return (
    <Flex
      width={[1]}
      flexDirection='column'
      pt={paddingTop}
      style={{ backgroundColor: bgColor }}
    >
      <BannerAnim
        type={typeBanner}
        autoPlay={isAuto}
        autoPlaySpeed={autoPlaySpeed}
        style={{
          height: fullHeight ? "100vh" : currentHeight,
          display: "flex",
          flexDirection: "column",
          alignItems: horizontal,
          justifyContent: vertical,
        }}
      >
        {list.map((item, index) => {
          const isBgImage = get(item, "bg.isBgImage", false);
          const useMobile =
            widthContainer <= 832
              ? get(
                  item,
                  "url_bg_mobile",
                  get(item, "url_bg", "https://via.placeholder.com/1000x300")
                )
              : get(item, "url_bg", "https://via.placeholder.com/480x480");
          const bgImage = isBgImage
            ? {
                backgroundImage: `url(${useMobile})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }
            : {};
          // console.log(bgImage);
          if (item.position_image === "left") {
            return (
              <Element key={index} prefixCls='banner-user-elem'>
                <BgElement key='bg' className='bg' style={bgImage} />
                <LeftImageElement
                  data={item}
                  key={index}
                  id={index}
                  matches={widthContainer <= 832}
                  widthContainer={widthContainer}
                />
              </Element>
            );
          }
          return (
            <Element key={index} prefixCls='banner-user-elem'>
              <BgElement key='bg' className='bg' style={bgImage} />
              <RightImageElement
                data={item}
                key={index}
                id={index}
                matches={widthContainer <= 832}
                widthContainer={widthContainer}
              />
            </Element>
          );
        })}
      </BannerAnim>
    </Flex>
  );
};

export default CarouselList;
