import React, { Component } from "react"
import PropTypes from "prop-types"
import { find } from "lodash"
import { Switch, Dialog, Button } from "@material-ui/core"
import styled from "styled-components"
import { Image } from "rebass"
import bg1 from "./bg/bg1.jpg"
import bg2 from "./bg/bg2.jpg"
import bg3 from "./bg/bg3.jpg"
import bg4 from "./bg/bg4.jpg"
import bg5 from "./bg/bg5.jpg"
import bg6 from "./bg/bg6.jpg"
import bg7 from "./bg/bg7.jpg"
import bg8 from "./bg/bg8.jpg"
import bg9 from "./bg/bg9.jpg"
import bg10 from "./bg/bg10.jpg"
import bg11 from "./bg/bg11.jpg"

export const data = [
  { id: "0", bg: bg1 },
  { id: "1", bg: bg2 },
  { id: "2", bg: bg3 },
  { id: "3", bg: bg4 },
  { id: "4", bg: bg5 },
  { id: "5", bg: bg6 },
  { id: "6", bg: bg7 },
  { id: "7", bg: bg8 },
  { id: "8", bg: bg9 },
  { id: "9", bg: bg10 },
  { id: "10", bg: bg11 },
]

class DialogTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      bgItem: props.bgItem,
      isEmptyTheme: false,
    }
  }
  checkImage = id => {
    let image = ""
    let result = find(data, function(item) {
      return item.id === id
    })
    if (result) {
      return result.bg
    }

    return image
  }
  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }
  onSelectedTemplate = (item, index) => {
    this.setState({ open: false, bgItem: item.bg, isEmptyTheme: false })
    this.props.onItemSelected(item.id)
  }
  onToggle = (e, v) => {
    if (v) {
      this.setState({ isEmptyTheme: v, bgItem: "" })
      this.props.onItemSelected("")
    } else {
      this.setState({ isEmptyTheme: v })
      this.props.onItemSelected(this.state.bgItem)
    }
  }
  render() {
    return (
      <div>
        <div>
          <Switch
            label="Remove Themes"
            style={{ marginBottom: 10 }}
            toggled={this.state.isEmptyTheme}
            onToggle={(e, v) => this.onToggle(e, v)}
          />
        </div>
        <DivBox>
          <Button
            label="Add Content"
            onClick={this.handleOpen}
            style={{ height: 40 }}
          />
          {this.state.bgItem && (
            <ImageBox>
              {" "}
              <Image
                src={this.state.bgItem}
                style={{ height: "auto" }}
                alt={this.state.bgItem}
              />
            </ImageBox>
          )}
        </DivBox>
        <Dialog
          title="Background Template"
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          autoScrollBodyContent={true}
        >
          <DivImageContainer>
            {data.map((item, index) => {
              return (
                <DivItemTemplate
                  key={index}
                  onClick={() => this.onSelectedTemplate(item, index)}
                >
                  <Image
                    src={data[index].bg}
                    style={{ width: 150, height: 150 }}
                    alt={data[index].bg}
                  />
                </DivItemTemplate>
              )
            })}
          </DivImageContainer>
        </Dialog>
      </div>
    )
  }
}

const DivItemTemplate = styled.div`
  padding: 8px;
  width: 150px;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
  }
`

const DivImageContainer = styled.div`
  display: inline-flex;
`

const DivBox = styled.div`
  padding: 16px 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 10px;
`

DialogTemplate.propTypes = {
  onItemSelected: PropTypes.func,
  bgItem: PropTypes.string,
}

DialogTemplate.defaultProps = {
  onItemSelected: () => {},
  bgItem: "",
}

export default DialogTemplate
