import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import hoistNonReactStatic from "hoist-non-react-statics";

import { connect } from "../../core";

const HOC = (WrappedComponent, displayName) => {
  class SelectWrapper extends Component {
    // componentDidMount() {
    //   if (this.node && this.node !== null) {
    //     this.node.addEventListener("click", this.handleClick);
    //     // console.log(this.node)
    //   }
    // }

    // componentWillUnmount() {
    //   if (this.node && this.node !== null) {
    //     this.node.removeEventListener("click", this.handleClick);
    //   }
    // }

    handleClick = (e) => {
      e.stopPropagation();
      // note : because root already can edit on the catalog
      if (this.props.id !== "root") {
        this.props.layoutState.setSelectedItem(this.props.id);
      }
    };

    render() {
      const { layoutState, pseudoRef, ...props } = this.props;
      return (
        <WrappedComponent
          {...props}
          pseudoRef={(instance) => {
            this.node = findDOMNode(instance);
            if (this.node && this.node !== null) {
              this.node.addEventListener("click", this.handleClick);
            }

            pseudoRef(instance);
          }}
        />
      );
    }
  }

  SelectWrapper.displayName = `SelectWrapper(${displayName})`;
  hoistNonReactStatic(SelectWrapper, WrappedComponent);

  return connect("layoutState")(SelectWrapper);
};

export default HOC;
