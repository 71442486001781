import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout24 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const RowIconText = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='RowIconText' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

RowIconText.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};

RowIconText.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "RowIconText",
    isShowDesktop: true,
    isShowMobile: true,
    backgroundColor: "rgba(255,255,255,0)",
    icon: "",
    layoutRow: {
      flexDirection: ["column", "row"],
      justifyContent: ["flex-start", "flex-start"],
      alignItems: ["flex-start", "flex-start"],
      flexWrap: ["nowrap", "nowrap"],
    },
    title: {
      text: "Title",
      fontSize: [10, 14],
      lineHeight: ["120%", "120%"],
      letterSpacing: ["1px", "1px"],
      textAlign: ["left", "left"],
      color: "rgba(0,0,0,1)",
      containerPadding: ["0px", "0px"],
    },
    subtitle: {
      text: "subtitle",
      fontSize: [10, 14],
      lineHeight: ["120%", "120%"],
      letterSpacing: ["1px", "1px"],
      textAlign: ["left", "left"],
      color: "rgba(0,0,0,1)",
      containerPadding: ["0px", "0px"],
    },
  },
  layout: Layout24,
};
export default RowIconText;
