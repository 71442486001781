import React from "react";
import { get, lowerCase, find, map, mapKeys, filter, uniq } from "lodash";
import { Flex, Box, Text } from "rebass";

const listColor = [
  { id: " blue", value: "#3f51b5" },
  { id: " green", value: "#008000" },
  { id: " red", value: "#FF0000" },
  { id: " orange", value: "#FF9900" },
  { id: " black", value: "#000000" },
  { id: " white", value: "#FFFFFF" },
  { id: " grey", value: "#808080" },
  { id: " silver", value: "#c0c0c0" },
  { id: " pink", value: "#ffc0cb" },
  { id: " purple", value: "#800080" },
  { id: " lavender", value: "#800080" },
  { id: " peacoat", value: "#2b2e43" },
];

export const getListColor = (product, typeContent) => {
  const colors = get(
    product,
    typeContent === "Category" ? "variants.options" : "variant.options",
    []
  );
  const photos = get(product, "photos.photo_set");
  // console.log(product);
  const variants = find(colors, function (o) {
    return (
      lowerCase(o.name) === "color" ||
      lowerCase(o.name) === "colors" ||
      lowerCase(o.name) === "warna"
    );
  });

  const list = get(variants, "values", []);
  const newItems = map(list, (i) => {
    let newList = [];
    mapKeys(photos, function (value, key) {
      // console.log(key, value);
      if (value && value.code !== "") {
        if (i === key) {
          // newList = value.code;
          newList.push(value.code);
        }
      } else {
        const mycolor = find(listColor, function (o) {
          return lowerCase(key).includes(o.id);
        });

        if (mycolor && mycolor.id) {
          // newList = mycolor.value;
          newList.push(mycolor.value);
        }
      }

      return value.code;
    });
    return newList;
  });

  const merged = [].concat.apply([], newItems);

  const myItems = uniq(merged);
  return filter(myItems, function (o) {
    return o !== "";
  });
};

const ProductColor = (props) => {
  const { product, typeContent } = props;
  const list = getListColor(product, typeContent);
  if (list && list.length === 0) {
    return <span />;
  }
  return (
    <Box width={[1]}>
      <Text
        fontWeight={"bold"}
        color={"grey"}
        fontSize={["8pt"]}
        style={{ textTransform: "uppercase" }}
      >
        Available COLORS
      </Text>
      <Flex
        flexDirection={"row"}
        py={[1]}
        sx={{ "&>div": { mr: [1] } }}
        justifyContent={"flex-start"}
      >
        {list.map((item, index) => {
          return (
            <Box
              key={index}
              width={["24px"]}
              sx={{
                border: "1px solid #484847",
                backgroundColor: item,
                height: ["24px"],
                borderRadius: "50%",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            />
          );
        })}
      </Flex>
    </Box>
  );
};

export default ProductColor;
