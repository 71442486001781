import React, { Component } from "react"
import PropTypes from "prop-types"
import { Box } from "rebass"
import { addField, FieldTitle, ResettableTextField } from "react-admin"
import sanitizeRestProps from "./sanitizeRestProps"
import TextMessage from "./utils"

export class RaTextInput extends Component {
  handleBlur = eventOrValue => {
    this.props.onBlur(eventOrValue)
    this.props.input.onBlur(eventOrValue)
  }

  handleFocus = event => {
    this.props.onFocus(event)
    this.props.input.onFocus(event)
  }

  handleChange = eventOrValue => {
    this.props.onChange(eventOrValue)
    this.props.input.onChange(eventOrValue)
  }

  render() {
    const {
      className,
      input,
      isRequired,
      label,
      meta,
      options,
      resource,
      source,
      type,
      notetext,
      updateField,
      ...rest
    } = this.props
    if (typeof meta === "undefined") {
      throw new Error(
        "The RaTextInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
      )
    }
    const { touched, error } = meta

    let newError = error
    if (error === "ra.validation.required") {
      newError = "Required"
    }

    return (
      <Box>
        <ResettableTextField
          variant="filled"
          type={type}
          label={
            label === false ? (
              label
            ) : (
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
                style={{ fontWeight: "900" }}
              />
            )
          }
          error={!!(touched && error)}
          helperText={touched && newError}
          className={className}
          {...options}
          {...sanitizeRestProps(rest)}
          {...input}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onChange={this.handleChange}
        />

        {notetext && <TextMessage notetext={notetext} />}
      </Box>
    )
  }
}

RaTextInput.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  meta: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  type: PropTypes.string,
  notetext: PropTypes.string,
}

RaTextInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  options: {},
  type: "text",
}

export default addField(RaTextInput)
