import { createMuiTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import teal from "@material-ui/core/colors/teal";
import { grey } from "@material-ui/core/colors";
import { bcx, bcx_secondary_orange } from "../components/app";
import { inputStyles } from "./customInput";
import { DEFAULT_SIDEBAR } from "../utils";
//bcx_secondary,

export const themeMui = createMuiTheme({
  palette: {
    primary: bcx,
    secondary: bcx_secondary_orange,
    error: red,
    background: {
      default: "#EFEFEF",
    },
  },
  typography: {
    fontFamily: ["SalesforceSans, sans-serif"].join(","),
    fontSize: 14,
    useNextVariants: true,
  },
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginTop: 8,
      },
    },
    MuiTableSortLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        color: inputStyles.colorLabel,
        fontSize: 14,
        fontWeight: "900",
      },
    },
    MuiFormLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        color: inputStyles.colorLabel,
        fontWeight: "900",
      },
    },

    MuiInputLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        fontSize: 16,
        minWidth: 100,
        fontWeight: "900",
        "&$shrink": {
          color: inputStyles.colorLabel,
          fontSize: 16,
          transform: "translate(0px, 6px) scale(0.75)",
        },
        "&$focused": {
          color: inputStyles.colorLabel,
        },
        "&$error": {
          color: red[500],
        },
      },
      filled: {
        letterSpacing: inputStyles.letterSpacing,
        minWidth: 100,
        fontWeight: "900",
        transform: "translate(0px, 26px) scale(1)",
        "&$shrink": {
          fontSize: 16,
          transform: "translate(0px, 6px) scale(0.75)",
          "&$marginDense": {
            transform: "translate(0px, 6px) scale(0.75)",
          },
        },
        "&$marginDense": {
          fontSize: 14,
          transform: "translate(0px, 26px) scale(1)",
        },
      },
    },
    MuiSelect: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
      },
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiInput: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
      },
      underline: {
        borderBottom: `1px solid ${grey[300]}`,
        "&::before": {
          borderBottom: `1px solid ${inputStyles.borderBottomColor}`,
        },
        "&:after": {
          borderBottomWidth: 1,
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `1px solid ${grey[500]}`,
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderBottom: `1px solid ${grey[500]}`,
          },
        },
      },
    },
    MuiExpansionPanelSummary: {
      content: {
        margin: 0,
        marginTop: 12,
        "&$expanded": {
          margin: 0,
          marginTop: 12,
        },
      },
    },
    MuiStepIcon: {
      root: {
        color: "#FF9900",
      },
    },
    MuiSwitch: {
      root: {},
      colorPrimary: {
        "&$checked": {
          color: teal[500],
          "& + $track": {
            backgroundColor: teal[200],
            opacity: 1,
            border: "none",
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        backgroundColor: "Transparent",
        "&$focused": {
          backgroundColor: "Transparent",
        },
        "&:hover": {
          backgroundColor: "Transparent",
        },
      },
      inputMarginDense: {
        letterSpacing: 1,
        paddingTop: 23,
        paddingBottom: 2,
      },
      inputMultiline: {
        letterSpacing: inputStyles.letterSpacing,
        paddingTop: 23,
        paddingBottom: 2,
      },
      input: {
        letterSpacing: inputStyles.letterSpacing,
        padding: "6px 2px 10px",
        paddingBottom: 2,
        fontSize: 14,
      },
      multiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: "27px 2px 10px",
        fontSize: 14,
      },
      // underline: {
      //   borderBottom: `1px solid ${grey[300]}`,
      //   "&::before": {
      //     borderBottom: `1px solid ${inputStyles.borderBottomColor}`,
      //   },
      //   "&:after": {
      //     borderBottomWidth: 1,
      //   },
      //   "&:hover:not($disabled):not($focused):not($error):before": {
      //     borderBottom: `1px solid ${grey[500]}`,
      //     // Reset on touch devices, it doesn't add specificity
      //     "@media (hover: none)": {
      //       borderBottom: `1px solid ${grey[500]}`,
      //     },
      //   },
      // },
    },
    WAMuiChipInput: {
      chipContainer: {
        marginTop: 0,
        "&$labeled&$standard": {
          marginTop: 22,
        },
      },
      underline: {
        borderBottom: `1px solid ${grey[300]}`,
        "&::before": {
          borderBottom: `1px solid ${inputStyles.borderBottomColor}`,
        },
        "&::after": {
          borderBottomWidth: 1,
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `1px solid ${grey[500]}`,
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderBottom: `1px solid ${grey[500]}`,
          },
        },
      },
    },
    MuiFilledInput: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        backgroundColor: "transparent",
        "&$focused": {
          backgroundColor: "transparent",
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&$disabled": {
          backgroundColor: "transparent",
        },
      },
      inputMarginDense: {
        letterSpacing: inputStyles.letterSpacing,
        paddingBottom: 4,
        padding: "27px 2px 10px",
      },
      input: {
        letterSpacing: inputStyles.letterSpacing,
        padding: "27px 2px 10px",
        paddingBottom: 4,
        fontSize: 14,
      },
      multiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: "27px 2px 10px",
        paddingBottom: 4,
        fontSize: 14,
      },

      underline: {
        borderBottom: `1px solid ${grey[300]}`,
        "&::before": {
          borderBottom: `1px solid ${inputStyles.borderBottomColor}`,
        },
        "&:after": {
          borderBottomWidth: 1,
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `1px solid ${grey[500]}`,
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderBottom: `1px solid ${grey[500]}`,
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: 8,
        paddingTop: 4,
        paddingBottom: 4,
        borderBottomWidth: 0,
      },
      head: {
        fontWeight: "bold",
        padding: 8,
      },
    },
    MuiToolbar: {
      regular: {
        paddingRight: 0,
        paddingLeft: 0,
      },
      gutters: {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    MuiTab: {
      wrapper: {
        textTransform: "none",
        fontWeight: "bold",
      },
      textColorPrimary: {
        minWidth: 100,
      },
    },
    Layout: {
      content: {
        padding: 0,
      },
    },
    RaFormInput: {
      input: {
        width: "100%",
      },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        paddingLeft: 16,
      },
    },
    PrivateTabIndicator: {
      vertical: {
        width: 0,
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "0.85rem",
      },
    },
    RaCreate: {
      card: {
        boxShadow:
          "rgba(66, 66, 66, 0.2) 0px 3px 3px -2px, rgba(66, 66, 66, 0.14) 0px 3px 4px 0px, rgba(66, 66, 66, 0.12) 0px 1px 8px 0px",
      },
      noActions: {
        backgroundColor: "#FF9900",
        marginTop: 0,
        "&@media screen and (min-width: 600px)": {
          marginTop: 0,
        },
      },
    },
    RaEdit: {
      card: {
        // boxShadow: "none",
        boxShadow:
          "rgba(66, 66, 66, 0.2) 0px 3px 3px -2px, rgba(66, 66, 66, 0.14) 0px 3px 4px 0px, rgba(66, 66, 66, 0.12) 0px 1px 8px 0px",
      },
      noActions: {
        marginTop: 0,
        "&@media screen and (min-width: 600px)": {
          marginTop: 0,
        },
      },
    },
    MuiChip: {
      label: {
        textTransform: "capitalize",
      },
      deleteIconColorPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      root: {},
      label: {
        textTransform: "capitalize",
      },
    },
    RaButton: {
      label: {
        textTransform: "capitalize",
      },
    },

    MuiTablePagination: {
      toolbar: {
        backgroundColor: grey[300],
      },
    },
    RaShow: {
      noActions: {
        marginTop: 0,
      },
    },

    // RaListToolbar: {
    //   toolbar: {
    //     display: "flex",
    //     width: "100%",
    //     flexDirection: "column-reverse",
    //   },
    // },
    // RaFilterForm: {
    //   form: {
    //     flexDirection: "row",
    //     justifyContent: "flex-end",
    //     marginTop: "0px",
    //   },
    // },
    // RaFilterFormInput: {
    //   body: {
    //     display: "flex",
    //     flexDirection: "row",
    //     alignItems: "center",
    //     justifyContent: "flex-end",
    //   },
    // },
    RaSideBar: {
      drawerPaper: {
        width: DEFAULT_SIDEBAR,
        backgroundColor: "#424242",
      },
    },
  },
  sidebar: {
    width: DEFAULT_SIDEBAR,
    closedWidth: 0,
    backgroundColor: "#424242",
  },
});
