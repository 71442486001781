import React from "react";
import { useTranslate } from "react-admin";
import { Flex, Box } from "rebass";
import { Title } from "../";
import { BOX_SHADOW_COLOR } from "../../utils";

const HeaderTitle = ({
  title,
  children,
  isShowButton,
  iShowWithChildren = true,
}) => {
  const translate = useTranslate();
  return (
    <Box width={[1]}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={children ? "space-between" : "flex-start"}
        p={[3]}
        bg={"white"}
        style={{
          boxShadow: BOX_SHADOW_COLOR,
        }}
      >
        <Box>
          <Title fontWeight={"bold"} fontSize={[3]}>
            {translate(title)}
          </Title>
        </Box>
        {children && (
          <Flex
            flexDirection={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            {children}
          </Flex>
        )}
      </Flex>
      {children && iShowWithChildren && (
        <Box
          width={[1]}
          style={{ height: 24, backgroundColor: "transparent" }}
          mb={[2]}
        />
      )}
      {isShowButton && (
        <Box
          width={[1]}
          style={{ height: 24, backgroundColor: "transparent" }}
          mb={[2]}
        />
      )}
    </Box>
  );
};

export default HeaderTitle;
