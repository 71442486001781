import React from "react"
import { Switch } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import FormHelperText from "@material-ui/core/FormHelperText"

const SwitchInput = props => {
  const { message, onChange, checked, title, id, isError = true } = props
  return (
    <FormGroup>
      <FormControlLabel
        htmlFor={`Switch-${id}`}
        control={
          <Switch
            id={`Switch-${id}`}
            checked={checked}
            color="primary"
            onChange={onChange}
          />
        }
        label={title}
      />
      {message && <FormHelperText error={isError}>{message}</FormHelperText>}
    </FormGroup>
  )
}
export default SwitchInput
