import React from "react";
//, { useEffect, useState }
import { Flex } from "rebass";
import { get } from "lodash";
import LinearProgress from "@material-ui/core/LinearProgress";
// import CancelItemView from "./CancelItemView";
import { PaperCard } from "../../../../components";
// import { RestApi } from "../../../../restClient";
import { useQuery } from "../../../../components";
import OrderItems from "./OrderItems";
const ListOrderItems = (props) => {
  const { translate, website, order, match } = props;
  const shipmentId = get(match, "params.id", 0);
  const orderId = get(match, "params.order_id", 0);
  const itemOrders = get(order, "items", []);
  // const [loading, setLoading] = useState(true);
  // const [list, setList] = useState([]);
  const currency_symbol = get(website, "locale.currency_symbol", "");
  const { loading, data } = useQuery(
    `/orders/${orderId}/shipments/${shipmentId}`
  );
  // useEffect(() => {
  //   setLoading(true);
  //   const timer = setTimeout(() => {
  //     RestApi(`/orders/${orderId}/shipments/${shipmentId}`)
  //       .then((response) => {
  //         return response.json();
  //       })
  //       .then((res) => {
  //         // console.log(res);
  //         if (res) {
  //           // console.log(res);
  //           setList(res);
  //         }
  //         setLoading(false);
  //       })
  //       .catch((e) => {
  //         setLoading(false);
  //         // console.log(e);
  //       });
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // }, [orderId, shipmentId]);
  return (
    <Flex width={[1]} flexDirection={"column"} py={[3]}>
      <PaperCard
        title={translate("resources.orders.labels.package")}
        showButton={false}
        elevation={3}
        bgHeader={"#eeeeee"}
        propsHeader={{ px: [3], py: [2] }}
      >
        <Flex width={[1]} flexDirection={"column"}>
          {loading ? (
            <Flex width={[1]} p={[3]}>
              <LinearProgress style={{ width: "100%" }} />
            </Flex>
          ) : (
            data &&
            data !== null &&
            data.length > 0 &&
            data.map((item, index) => {
              return (
                <OrderItems
                  key={index}
                  item={item}
                  translate={translate}
                  itemOrders={itemOrders}
                  currency_symbol={currency_symbol}
                />
                // <CancelItemView
                //   item={item}
                //   key={index}
                //   translate={translate}
                //   itemOrders={itemOrders}
                //   currency_symbol={currency_symbol}
                // />
              );
            })
          )}
        </Flex>
      </PaperCard>
    </Flex>
  );
};

export default ListOrderItems;
