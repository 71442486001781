import React from "react";
import { ImageField } from "../../../../components";
export default (props) => {
  const { updateField, icon } = props;
  return (
    <div>
      <ImageField
        photo={icon}
        type='icon-menu'
        labelImage={"Icon"}
        updateField={(k, v) => updateField(k, v)}
        source={"icon"}
        toValue={"icon"}
      />
    </div>
  );
};
