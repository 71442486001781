import React, { Component } from "react";
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd";
import { get } from "lodash";
// import ContainerDimensions from "react-container-dimensions";
import { connect } from "../../core";
import { Row } from "antd";
import { getStylGradiant, getBGProps } from "./components/gradiant";
// const defaultRow = {
//   column: 1,
//   show: true,
//   padding: "0px",
//   backgroundColor: "rgba(255,255,255,0)",
//   width: "100%",
//   height: "100%",
//   layout: {
//     align: "top",
//     gutter: 0,
//     justify: "start",
//     wrap: false,
//   },
// };

const target = {
  drop(props, monitor, component) {
    if (!monitor.didDrop()) {
      const { values } = monitor.getItem().props;
      const hoverType = props.type;
      if (values.type !== hoverType) {
        props.layoutState.insertOrMoveItem(
          props.id,
          props.children.length,
          monitor.getItem()
        );
      }
    }
  },
};

class EditLayout extends Component {
  UNSAFE_componentWillMount() {
    const { id, children, layoutState } = this.props;

    const { content } = layoutState.getItemJS(id).props;

    if (children && children.length < content.length) {
      for (let index = 0; index < content.length; index++) {
        let itemChildren = content[index];
        layoutState.insertOrMoveItem(id, index, itemChildren);
      }
    }
  }
  render() {
    const {
      id,
      children,
      connectDropTarget,
      layoutState,
      widthScreen,
    } = this.props;
    const { values } = layoutState.getItemJS(id).props;
    const contentWidth = "100%"; //get(values, "desktop.width", "100%");
    const contentHeight = get(values, "desktop.height", "100%");
    const contentPadding = get(values, "desktop.padding", "0px");
    const layoutRowAlign = get(values, "desktop.layout.align", "middle");
    const layoutRowGutter = get(values, "desktop.layout.gutter", 0);
    const layoutRowjustify = get(values, "desktop.layout.justify", "center");
    const layoutRowWrap = get(values, "desktop.layout.wrap", false);
    const layoutRowAlignContainer = get(
      values,
      "desktop.layout.contentAlign",
      "center"
    );
    const layoutRowVAlignContainer = get(
      values,
      "desktop.layout.contentAlign",
      "center"
    );
    const backgroundColor = get(
      values,
      "desktop.backgroundColor",
      "transparent"
    );
    let important = { backgroundColor: backgroundColor };
    const myGradient = getStylGradiant(get(values, "desktop", {}));
    if (myGradient) {
      if (myGradient.type) {
        const mydegre =
          myGradient.type === "radial-gradient"
            ? "circle"
            : `${myGradient.degre}deg`;
        const newBg = {
          background: `${myGradient.type}(${mydegre}, ${myGradient.bg1} ${myGradient.linear[0]}%,${myGradient.bg2} ${myGradient.linear[1]}%)`,
        };
        important = { background: myGradient.bg1, height: "100%", ...newBg };
      }
    }
    const myBGProps = getBGProps(get(values, "desktop", {}));
    if (myBGProps) {
      const minHeight = widthScreen / myBGProps.ratio;
      important = {
        backgroundImage: `url("${myBGProps.url}")`,
        backgroundSize: myBGProps.size,
        backgroundPosition: myBGProps.position,
        backgroundRepeat: myBGProps.repeat,
        minHeight: minHeight,
      };
    }
    //...important,
    return connectDropTarget(
      <div
        style={{
          boxSizing: "border-box",
          position: "relative",
          height: "100%",
          padding: contentPadding,
          minHeight: children && children.length > 1 ? null : 30,
          minWidth: children && children.length > 1 ? null : 30,
          display: "flex",
          flex: 1,
          width: "inherit",
          alignItems: layoutRowAlignContainer,
          justifyContent: layoutRowVAlignContainer,
          flexDirection: "column",
          ...important,
        }}
      >
        <Row
          style={{
            width: contentWidth,
            height: contentHeight,
            flexWrap: layoutRowWrap ? "wrap" : "nowrap",
          }}
          align={layoutRowAlign}
          justify={layoutRowjustify}
          gutter={layoutRowGutter}
        >
          {children}
        </Row>
      </div>
    );
  }
}

const LayoutContainer = DropTarget("Body", target, (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(EditLayout);

export default connect(
  "layoutState",
  "readOnly",
  "widthScreen"
)(LayoutContainer);

// export default LayoutContainer;s
