import React from "react";
import { Box } from "rebass";
import { get } from "lodash";
import { RTInput, DialogHrefContent, Title } from "../components";
import TextContentFields from "./TextContentFields";
import { ContentDetail } from "../actions";

const toolbar = [
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ align: [] }],
  ["blockquote", "code-block"],
  ["clean"],
];

export default (props) => {
  const { item, onSetItemValue } = props;
  const onSetValue = (k, v) => {
    const newItem = {
      ...item,
      [k]: v,
    };
    onSetItemValue(newItem);
  };

  const onSetValueDesktop = (k, v) => {
    const newDesktop = {
      ...item.desktop,
      [k]: v,
    };
    onSetValue("desktop", newDesktop);
  };

  const onSetValueMobile = (k, v) => {
    const newMobile = {
      ...item.mobile,
      [k]: v,
    };
    onSetValue("mobile", newMobile);
  };

  return (
    <Box width={[1]}>
      <Box width={[1]} py={[2]}>
        <Title title={"Desktop Settings"} />
        <TextContentFields
          state={item.desktop}
          title={"Desktop"}
          onSetValue={(k, v) => onSetValueDesktop(k, v)}
        />
      </Box>
      <Box width={[1]} py={[2]}>
        <Title title={"Mobile Settings"} />
        <TextContentFields
          state={item.mobile}
          title={"Mobile"}
          onSetValue={(k, v) => onSetValueMobile(k, v)}
        />
      </Box>
      <ContentDetail>
        <DialogHrefContent
          link={item.link}
          onSetValue={(v) => onSetValue("link", v)}
          websiteId={props.websiteId}
        />
      </ContentDetail>
      <ContentDetail style={{ minHeight: 100 }}>
        <Title title={"Desktop Text"} />
        <RTInput
          input={
            <input
              type='text'
              name='name'
              value={item.text}
              onChange={(value) => onSetValue("text", value)}
            />
          }
          source='body'
          label='Body'
          toolbar={toolbar}
        />
      </ContentDetail>
      <ContentDetail style={{ minHeight: 100 }}>
        <Title title={"Mobile Text"} />
        <RTInput
          input={
            <input
              type='text'
              name='name'
              value={get(item, "mobile.text", item.text)}
              onChange={(value) => onSetValueMobile("text", value)}
            />
          }
          source='body'
          label='Body'
          toolbar={toolbar}
        />
      </ContentDetail>
    </Box>
  );
};
