import React from "react";
import { Image } from "rebass";
import styled from "styled-components";
const DivContainer = styled.div`
  padding: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition-duration: 0.15s;
  transition-property: box-shadow;
  &:hover {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.35);
  }
`;

const myimage = require("../../assets/images/template13.jpg");

export const LayoutContent = (myIndex) => (
  <DivContainer>
    <Image src={myimage} />
  </DivContainer>
);
const Layout1 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template1.png")} />
  </DivContainer>
);
const Layout2 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template2.png")} />
  </DivContainer>
);
const Layout3 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template3.png")} />
  </DivContainer>
);
const Layout4 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template4.png")} />
  </DivContainer>
);
const Layout5 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template5.png")} />
  </DivContainer>
);
const Layout6 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template6.png")} />
  </DivContainer>
);
const Layout7 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template7.png")} />
  </DivContainer>
);
const Layout8 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template8.png")} />
  </DivContainer>
);
const Layout9 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template9.png")} />
  </DivContainer>
);
const Layout10 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template10.png")} />
  </DivContainer>
);

const Layout11 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template11.png")} />
  </DivContainer>
);
const Layout12 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template12.png")} />
  </DivContainer>
);
const Layout13 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template13.png")} />
  </DivContainer>
);
// const Layout13 = () => (
//   <DivContainer>
//     <Image src={myimage} />
//   </DivContainer>
// );

const Layout14 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template14.png")} />
  </DivContainer>
);
const Layout15 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template15.png")} />
  </DivContainer>
);

const Layout16 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template16.png")} />
  </DivContainer>
);
const Layout17 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template17.png")} />
  </DivContainer>
);
const Layout18 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template18.png")} />
  </DivContainer>
);

const Layout19 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template19.png")} />
  </DivContainer>
);

const Layout20 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template20.png")} />
  </DivContainer>
);
const Layout21 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template21.png")} />
  </DivContainer>
);

const Layout22 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template22.png")} />
  </DivContainer>
);
const Layout23 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template23.png")} />
  </DivContainer>
);
const Layout24 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template24.png")} />
  </DivContainer>
);
const Layout25 = () => (
  <DivContainer>
    <Image src={require("../../assets/sonak/template25.png")} />
  </DivContainer>
);

export {
  Layout1,
  Layout2,
  Layout3,
  Layout4,
  Layout5,
  Layout6,
  Layout7,
  Layout8,
  Layout9,
  Layout10,
  Layout11,
  Layout12,
  Layout13,
  DivContainer,
  Layout14,
  Layout15,
  Layout18,
  Layout16,
  Layout17,
  Layout19,
  Layout20,
  Layout21,
  Layout22,
  Layout23,
  Layout24,
  Layout25,
};
