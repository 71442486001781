import React from "react";
import { RowIconTextContent } from "../../../libs/rowicontext";
import { connect } from "../../../core";
const RowIconText = (props) => {
  const { id, layoutState, widthScreen } = props;
  const {
    backgroundColor,
    isShowDesktop,
    isShowMobile,
  } = layoutState.getItemJS(id).props.values;

  const isShowing = widthScreen > 480 ? isShowDesktop : isShowMobile;
  if (!isShowing) {
    return <div />;
  }
  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        backgroundColor: backgroundColor,
        display: "flex",
        flex: 1,
        padding: 0,
      }}
    >
      <RowIconTextContent
        values={layoutState.getItemJS(id).props.values}
        isDesktop={widthScreen > 480}
      />
    </div>
  );
};
export default connect("layoutState", "widthScreen")(RowIconText);
