import React, { useState } from "react"
import { Flex } from "rebass"
import { get, uniqBy } from "lodash"
import { dataProvider } from "../../../../restClient"
import { ProductCardById } from "../../../components"

import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const CategoriesList = (props) => {
  const { state, onSetValue, categories } = props
  const classes = useStyles()
  const categoryId =
    get(state, "categoryId", "") === 0 ? "" : get(state, "categoryId", "")
  const [value, setValue] = useState(categoryId)
  const oldProduct = categoryId !== "" ? state.products : []
  const [list, setList] = useState(oldProduct)

  const handleChange = (event) => {
    if (event.target.value !== value) {
      setValue(event.target.value)
      getItem(event.target.value)
    }
  }

  const getItem = (category) => {
    // console.log(category)
    if (category) {
      onSetValue("categoryId", category)
      // setValue(category.id)
      dataProvider
        .getMany("productCategories", {
          ids: category.id,
        })
        .then((response) => {
          if (response && response.data) {
            const mydata = uniqBy(response.data, function (e) {
              return e.product_id
            })
            const myProduct = mydata.map((item) => {
              return { id: item.product_id }
            })
            onSetValue("products", myProduct)
            setList(myProduct)
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }

  return (
    <Flex width={[1]} flexDirection="column">
      <FormControl className={classes.formControl}>
        <InputLabel id="list-categories-select-label">Category</InputLabel>
        <Select
          labelId="list-categories-select-label"
          id="list-categories-select"
          value={value}
          onChange={handleChange}
          style={{ width: 256 }}
        >
          {categories &&
            categories.length > 0 &&
            categories.map((category, indexCat) => (
              <MenuItem value={category.id} key={indexCat}>
                {category.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Flex width={[1]}>
        {list &&
          list.length > 0 &&
          list.map((item, index) => {
            return (
              <Flex
                key={index}
                p={[2]}
                width={["200px"]}
                flexDirection={"column"}
                justifyContent={"space-between"}
              >
                <ProductCardById
                  product={item}
                  {...props}
                  indexProduct={index}
                />
              </Flex>
            )
          })}
      </Flex>
    </Flex>
  )
}

export default CategoriesList
