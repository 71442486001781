import styled from "styled-components"

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
`
export const Content = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 4px;
`
