import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { uniqBy } from "lodash";
import ChipInput from "../chipinput/ChipInput";
import { useQuery } from "react-query";
import { getProductsList } from "../../../../../restClient";
const cacheTimeMinute = 1000 * 60 * 30;
// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 100,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));

const getChoicesForValues = (values, choices = []) => {
  if (!values || !Array.isArray(values)) {
    throw Error("Value of SelectArrayChipInput should be an array");
  }
  return values
    .map(
      (value) => choices.find((c) => c.id === value) || { value, text: value }
    )
    .map(formatChoice);
};

const formatChoices = (choices) => choices.map(formatChoice);
const formatChoice = (choice) => {
  return {
    value: choice.id,
    text: choice.name,
  };
};

const SelectInputProducts = (props) => {
  const { onChange, value } = props;
  //list, title, id, isError = true message,
  // const [state, setState] = useState({
  //   values: value,
  // });
  const { isLoading, data } = useQuery("products", () => getProductsList(), {
    cacheTime: cacheTimeMinute,
  });

  const onChangeList = (event, value) => {
    const filterValue = uniqBy(value, function (e) {
      return e.value;
    });

    // setState({
    //   values: filterValue,
    // });
    const newCollections = filterValue.map((o) => o.value);
    onChange(newCollections);
  };

  if (isLoading) {
    return <div style={{ padding: 16 }}>Loading...</div>;
  }
  const listProducts = getChoicesForValues(value, data);
  //   console.log(value);
  return (
    <ChipInput
      id='products-list-slide-view-content'
      title={"Products"}
      placeholder='Products'
      dataSource={formatChoices(data)}
      onChange={onChangeList}
      value={listProducts}
    />
  );
};
export default SelectInputProducts;
