import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { slice } from "lodash";
import styled from "styled-components";
import { Dialog, Button } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import { icon } from "./dataFontAwesomeIcon";

import DialogActions from "@material-ui/core/DialogActions";
const DialogIconFATemplate = (props) => {
  const [state, setState] = useState({
    open: false,
    iconItem: props.iconItem,
    isEmptyTheme: false,
    page: 1,
    perPage: 100,
    total: icon.length,
    dataIcon: slice(icon, [0], [100]),
  });

  const handleOpen = () => {
    setState({ ...state, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const onIconSelected = (item) => {
    setState({
      ...state,
      open: false,
      iconItem: item,
      isEmptyTheme: false,
    });
    props.onItemSelected(item);
  };

  const onLoadMore = () => {
    const { page, perPage, total, dataIcon } = state;
    const newPage = page + 1;
    const offsetEnd = Math.min(newPage * perPage, total);
    const offsetBegin = Math.min((newPage - 1) * perPage + 1, offsetEnd);
    const lastPage = Math.ceil(total / perPage) || 1;
    if (newPage <= lastPage) {
      const addIconArray = slice(icon, [offsetBegin], [offsetEnd]);
      setState({
        ...state,
        page: newPage,
        dataIcon: update(dataIcon, { $push: addIconArray }),
      });
    }
  };
  const lastPage = Math.ceil(state.total / state.perPage) || 1;
  return (
    <Fragment>
      <DivBox>
        <Button
          variant='contained'
          color='primary'
          onClick={() => handleOpen()}
        >
          Select Icon
        </Button>
        {state.iconItem && (
          <div style={{ paddingLeft: 16, paddingTop: 8 }}>
            <div
              className={`${state.iconItem}`}
              style={{ fontSize: 24, color: "#1f7dde" }}
            />
          </div>
        )}
      </DivBox>
      <Dialog
        title='Select Icon'
        open={state.open}
        onClose={() => handleClose()}
        scroll={"paper"}
      >
        <DialogContent dividers={true}>
          <DivImageContainer>
            <ul style={{ margin: 0 }}>
              {state.dataIcon.map((item, index) => {
                return (
                  <li
                    className={`${item}`}
                    key={index}
                    style={{ padding: 10, fontSize: 24, color: "#1f7dde" }}
                    onClick={() => onIconSelected(item)}
                  />
                  //   <IconButton
                  //
                  //     style={{ padding: 10 }}
                  //     onClick={() => onIconSelected(item)}
                  //   >

                  //   </IconButton>
                );
              })}
            </ul>
          </DivImageContainer>
        </DialogContent>
        <DialogActions>
          {state.page < lastPage && (
            <BoxButton>
              <Button
                variant='contained'
                color='primary'
                fullWidth={true}
                onClick={() => onLoadMore()}
              >
                Load More
              </Button>
            </BoxButton>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const DivImageContainer = styled.div`
  display: inline-flex;
`;

const DivBox = styled.div`
  padding: 16px 0px;
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const BoxButton = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;

DialogIconFATemplate.propTypes = {
  onItemSelected: PropTypes.func,
  iconItem: PropTypes.string,
  isRequired: PropTypes.bool,
};

DialogIconFATemplate.defaultProps = {
  onItemSelected: () => {},
  iconItem: "",
  isRequired: false,
};

export default DialogIconFATemplate;
