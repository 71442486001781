import React from "react";
import NumberFormat from "react-number-format";

const Currency = ({
  value,
  unit,
  thousandSeparator = ",",
  decimalSeparator = ".",
  decimalScale = 0,
  fixedDecimalScale = false,
}) => {
  const prefix = unit || "";
  return (
    <NumberFormat
      displayType='text'
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      prefix={prefix}
      value={value}
      fixedDecimalScale={fixedDecimalScale}
      decimalScale={decimalScale}
    />
  );
};

export default Currency;
