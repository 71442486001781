export default {
  variants: {
    title: "Variants",
    new_title: "New Variant",
    edit_title: "Edit Variant",
    fields: {
      name: "Name",
      code: "Code",
    },
    note_text: {
      code: "This is used for system internally. Don’t use spaces",
    },
  },
}
