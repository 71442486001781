import { withStyles } from "@material-ui/core/styles"
import MuiExpansionPanel from "@material-ui/core/Accordion"
import MuiExpansionPanelSummary from "@material-ui/core/AccordionSummary"
import MuiExpansionPanelDetails from "@material-ui/core/AccordionDetails"
const ExpansionPanel = withStyles({
  root: {
    border: "0px solid rgba(0, 0, 0, .125)",
    marginBottom: 16,
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "0px",
      marginBottom: 16,
    },
  },
  expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    minHeight: 48,
    "&$expanded": {
      minHeight: 48,
    },
  },
  content: {
    margin: "0px",

    fontSize: "bold",
    "&$expanded": {
      margin: "0px",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    overflowY: "auto",
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails)

export { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary }
