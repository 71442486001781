import React, { useCallback } from "react"
import PropTypes from "prop-types"
import {
  makeStyles,
  Select,
  MenuItem,
  Input,
  FilledInput,
  FormHelperText,
  FormControl,
  Chip,
} from "@material-ui/core"
import classnames from "classnames"
import { FieldTitle, useInput, useChoices, InputHelperText } from "react-admin"
import blue from "@material-ui/core/colors/blue"
import { Flex } from "rebass"
import TextMessage from "./utils"

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  basePath,
  choices,
  classNamInputWithOptionsPropse,
  componenInputWithOptionsPropst,
  crudGetMInputWithOptionsPropsatching,
  crudGetOInputWithOptionsPropsne,
  defaultValue,
  filter,
  filterToQuery,
  formClassName,
  initializeForm,
  input,
  isRequired,
  label,
  limitChoicesToValue,
  loaded,
  locale,
  meta,
  onChange,
  options,
  optionValue,
  optionText,
  perPage,
  record,
  reference,
  resource,
  setFilter,
  setPagination,
  setSort,
  sort,
  source,
  textAlign,
  translate,
  translateChoice,
  validation,
  ...rest
}) => rest

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      width: "100%",
      marginTop: 0,
      "& .MuiFilledInput-inputMarginDense": {
        paddingTop: 0,
      },
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: theme.spacing(1 / 4),
      color: "#FFFFFF",
      backgroundColor: blue[500],
    },
    title: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "stretch",
      minWidth: 80,
      paddingRight: 16,
      paddingBottom: 0,
    },
    full: {
      width: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    notfull: {
      paddingRight: 8,
    },
    select: {
      height: "auto",
      overflow: "auto",
      paddingTop: 0,
    },
  }),
  { name: "RaRaSelectArrayInputOptions" }
)

const RaSelectArrayInputOptions = ({
  choices = [],
  classes: classesOverride,
  className,
  format,
  helperText,
  label,
  margin = "dense",
  onBlur,
  onChange,
  onFocus,
  options,
  optionText,
  optionValue,
  parse,
  resource,
  source,
  translateChoice,
  validate,
  variant = "filled",
  notetext,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverride })
  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  })

  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  })

  const renderMenuItemOption = useCallback((choice) => getChoiceText(choice), [
    getChoiceText,
  ])

  const renderMenuItem = useCallback(
    (choice) => {
      // console.log(choice)
      return choice ? (
        <MenuItem key={getChoiceValue(choice)} value={getChoiceValue(choice)}>
          {renderMenuItemOption(choice)}
        </MenuItem>
      ) : null
    },
    [getChoiceValue, renderMenuItemOption]
  )

  return (
    <Flex flexDirection={["column"]}>
      <FormControl
        margin={margin}
        className={classnames(classes.root, className)}
        error={touched && !!error}
        variant={variant}
        {...sanitizeRestProps(rest)}
      >
        <div className={classes.title}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </div>
        <div
          className={rest && rest.fullWidth ? classes.full : classes.notfull}
        >
          <Select
            autoWidth
            multiple
            input={
              variant === "standard" ? (
                <Input id={id} />
              ) : (
                <FilledInput id={id} />
              )
            }
            error={!!(touched && error)}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected
                  .map((item) =>
                    choices.find((choice) => getChoiceValue(choice) === item)
                  )
                  .map((item) => (
                    <Chip
                      key={getChoiceValue(item)}
                      label={renderMenuItemOption(item)}
                      className={classes.chip}
                    />
                  ))}
              </div>
            )}
            data-testid="selectArray"
            variant={variant}
            {...input}
            value={input.value || []}
            {...options}
          >
            {choices.map(renderMenuItem)}
          </Select>
          {notetext && <TextMessage notetext={notetext} />}
          {(touched && error) || helperText ? (
            <FormHelperText error={touched && !!error}>
              <InputHelperText
                touched={touched}
                error={error}
                helperText={helperText}
              />
            </FormHelperText>
          ) : null}
        </div>
      </FormControl>
    </Flex>
  )
}

RaSelectArrayInputOptions.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  optionValue: PropTypes.string.isRequired,
  resource: PropTypes.string,
  source: PropTypes.string,
  translateChoice: PropTypes.bool,
  notetext: PropTypes.string,
  fullWidth: PropTypes.bool,
}

RaSelectArrayInputOptions.defaultProps = {
  options: {},
  optionText: "name",
  optionValue: "id",
  translateChoice: true,
  fullWidth: false,
}

export default RaSelectArrayInputOptions
