import React from "react";
import { FontelloSvg } from "../../../libs/icons";
import { connect } from "../../../core";
import { HrefContainer } from "../../../libs/hrefUrl";
const IconButtonView = (props) => {
  const { id, layoutState } = props;
  /**tooltipPosition,
    tooltip, */
  const {
    buttonColors,
    icon,
    size,
    link,
    buttonAlign,
    disabled,
    containerPadding,
  } = layoutState.getItemJS(id).props.values;
  // console.log(href.url)
  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "auto",
        display: "flex",
        width: "100%",
        justifyContent: buttonAlign,
        flex: 1,
        padding: containerPadding,
      }}
    >
      {!disabled ? (
        <HrefContainer link={link}>
          <FontelloSvg
            fill={buttonColors.color}
            name={icon}
            size={checkSize(size)}
          />
        </HrefContainer>
      ) : (
        <FontelloSvg
          fill={buttonColors.color}
          name={icon}
          size={checkSize(size)}
        />
      )}
    </div>
  );
};

const checkSize = (size) => {
  if (size === "large") {
    return "128px";
  } else if (size === "medium") {
    return "64px";
  } else if (size === "small") {
    return "48px";
  }
  return "24px";
};

export default connect("layoutState")(IconButtonView);
