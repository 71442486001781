import React, { useState } from "react"
import PropTypes from "prop-types"
import Layout from "../dnd"
import { Layout5 } from "../ContentBox"
import { DivHover, DivHelp } from "./styled"

const DividerView = props => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(true)
  }
  const onMouseOver = () => {
    setHover(false)
  }

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type="DividerView" />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  )
}

DividerView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
}
DividerView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "DividerView",
    containerPadding: "0px",
    line: "solid",
    lineColor: "rgba(255,255,255,1)",
    alignValue: "center",
    width: "100%",
    size: "1px",
  },
  layout: Layout5,
}
export default DividerView
