import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout14 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const CarouselLinkView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='CarouselLinkView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

CarouselLinkView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};

CarouselLinkView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "CarouselLinkView",
    containerPadding: "0px",
    backgroundColor: "rgba(255,255,255,0)",
    color: "rgba(0,0,0,1)",
    fontSize: {
      desktop: 14,
      mobile: 10,
    },
    align: "center",
    title: "",
    showAll: false,
    valueText: [
      {
        imageUrl: {
          url:
            "https://s3-ap-southeast-1.amazonaws.com/oleh-prod/assets/1/product/For-testing-only-UDO.png",
          urlMobile:
            "https://s3-ap-southeast-1.amazonaws.com/oleh-prod/assets/1/product/For-testing-only-UDO.png",
        },
        link: {
          target: "",
          type: "URL",
          value: "",
          isLink: false,
        },
      },
    ],
    setting: {
      dots: false,
      infinite: false,
      arrows: false,
      speed: 200,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      rows: 0,
      slidesPerRow: 0,
    },
  },

  layout: Layout14,
};

export default CarouselLinkView;
