import React from "react";
// import ContainerDimensions from "react-container-dimensions";
import { connect } from "../../../core";
import { MultiContentGroup } from "../../../libs/multicontentgroup";

const MultiContentGroupView = (props) => {
  const { id, layoutState, widthScreen } = props;
  const { containerPadding, mobile } = layoutState.getItemJS(id).props.values;
  let containerPadding1 = mobile.containerPadding;
  if (widthScreen > 768) {
    containerPadding1 = containerPadding;
  }
  // console.log(layoutState.getItemJS(id).props.values);
  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        width: "100%",
        display: "flex",
      }}
    >
      <div style={{ padding: containerPadding1, width: "100%" }}>
        <MultiContentGroup
          values={layoutState.getItemJS(id).props.values}
          widthScreen={widthScreen}
        />
      </div>
    </div>
  );
};
export default connect("layoutState", "widthScreen")(MultiContentGroupView);
