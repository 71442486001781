import React, { useRef } from "react";
import Slider from "react-slick";
import { Box, Flex, Text } from "rebass";
import { get } from "lodash";

import { ShowAllContent } from "./Items";
import ContentItemLSV from "./ContentItemLSV";
const defaultSetting = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 200,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  rows: 0,
  slidesPerRow: 0,
  autoplay: false,
  autoplaySpeed: false,
};

let settingsList = (setting, screenWidth, onChange) => {
  if (setting) {
    return {
      dots: setting.dots,
      infinite: setting.infinite,
      speed: setting.speed,
      arrows: setting.arrows,
      slidesToShow:
        screenWidth > 600
          ? setting.slidesToShow
          : setting.slidesToShow > 3
          ? 3
          : setting.slidesToShow,
      slidesToScroll: setting.slidesToScroll,
      autoplay: setting.autoplay,
      autoplaySpeed: setting.autoplaySpeed,
      afterChange: (index) => onChange(index),
    };
  }

  return {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 200,
    slidesToShow:
      screenWidth > 600
        ? setting.slidesToShow
        : setting.slidesToShow > 3
        ? 3
        : setting.slidesToShow,
    slidesToScroll: 1,
    afterChange: (index) => onChange(index),
  };
};

export default (props) => {
  const refContainer = useRef(null);
  // const [state, setState] = useState({ isIndex: 0, isEnd: false });
  const {
    screenWidth,
    title,
    desktop,
    valueText,
    colorProps,
    titleViewAll,
  } = props;
  const fontSizeHeader = get(desktop, "header.fontSize", 16);
  const showHeader = get(desktop, "header.show", false);
  const headerPadding = get(desktop, "header.padding", "0px");
  const showViewAllHeader = get(desktop, "header.showViewAll", false);
  const settings = settingsList(
    get(desktop, "setting", defaultSetting),
    screenWidth,
    (index) => onChange(index)
  );
  const onChange = (index) => {
    // const { valueText, screenWidth } = props;
    // const addIndex = screenWidth > 702 ? 6 : 3;
    // const currentIndex = index + addIndex;
    // let isEndCurrent = false;
    // if (currentIndex >= valueText.length) {
    //   isEndCurrent = true;
    // }
    // setState({
    //   isIndex: index,
    //   isEnd: isEndCurrent,
    // });
  };
  return (
    <Box style={{ width: screenWidth }}>
      {showHeader && (
        <Flex
          width={[1]}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexDirection={"row"}
          style={{ padding: headerPadding }}
        >
          <Text style={{ fontSize: `${fontSizeHeader}pt` }} fontWeight={"bold"}>
            {title}
          </Text>
          {showViewAllHeader && (
            <ShowAllContent
              url={""}
              title={titleViewAll}
              viewallColor={get(colorProps, "viewallColor", "#000000")}
            />
          )}
        </Flex>
      )}
      <div style={{ backgroundColor: "transparent" }}>
        <Slider
          ref={refContainer}
          {...settings}
          style={{ backgroundColor: "tranparent" }}
        >
          {valueText.map((item, index) => {
            return (
              <ContentItemLSV
                item={item}
                key={index}
                size={[1]}
                screenWidth={screenWidth}
                colorProps={colorProps}
                desktop={desktop}
              />
            );
          })}
        </Slider>
      </div>
    </Box>
  );
};
