import React from "react";
import { get } from "lodash";
import { Flex, Text, Box } from "rebass";
import {
  Title,
  RTInput,
  SelectInput,
  Counter,
  SketchColorPicker,
  BlockCounter,
} from "../components";
import { toolbar } from "./utils";
import { ContentDetail } from "../actions";
/**
 * subtitle: {
    text: "subtitle",
    fontSize: [10, 14],
    textAlign: ["left", "left"],
    color: "rgba(0,0,0,1)",
    lineHeight: ["120%", "120%"],
    letterSpacing: ["1px", "1px"],
    containerPadding: ["0px", "0px"],
  },
 */

const defaultAlignText = [
  { id: "left", name: "Left" },
  { id: "center", name: "Center" },
  { id: "right", name: "Right" },
];

export default (props) => {
  const { value, onSetValue } = props;

  const myLineHeightD = get(value, "lineHeight[1]", "100%");
  const myLineHeightM = get(value, "lineHeight[0]", "100%");
  const lineHeightD = myLineHeightD.split("%").join("") * 1;
  const lineHeightM = myLineHeightM.split("%").join("") * 1;
  const myLetterSpacingD = get(value, "letterSpacing[1]", "1px");
  const letterSpacingD = myLetterSpacingD.split("px").join("") * 1;
  const myLetterSpacingM = get(value, "letterSpacing[0]", "1px");
  const letterSpacingM = myLetterSpacingM.split("px").join("") * 1;

  const onChangeValue = (k, v, index) => {
    let myValue = value[k];

    if (value[k]) {
      if (myValue[index]) {
        myValue[index] = v;
      } else {
        if (myValue[index] === "") {
          myValue[index] = v;
        } else {
          if (typeof v === "number") {
            myValue = [1, 1];
            myValue[index] = v;
          } else {
            myValue = ["", ""];
            myValue[index] = v;
          }
        }
      }
    }
    onSetValue(k, myValue);
  };
  return (
    <Box width={[1]}>
      <ContentDetail>
        <RTInput
          input={
            <input
              type='text'
              name='name-title'
              value={value.text}
              onChange={(value) => onSetValue("text", value)}
            />
          }
          source='body'
          label='Title'
          toolbar={toolbar}
        />
      </ContentDetail>
      <ContentDetail>
        <SketchColorPicker
          title={"Color"}
          onUpdateColor={(color) => onSetValue("color", color)}
          color={value.color}
        />
      </ContentDetail>
      <Box width={[1]}>
        <Text fontWeight={"bold"} py={[2]}>
          Desktop Properties
        </Text>
        <ContentDetail>
          <SelectInput
            id={`dekstop-text-align`}
            title={"Text Align"}
            value={value.textAlign[1]}
            list={defaultAlignText}
            onChange={(e) => onChangeValue("textAlign", e.target.value, 1)}
          />
          <Title title={"Font Size"} />
          <Counter
            onSetValue={(value) => onChangeValue("fontSize", value, 1)}
            value={get(value, "fontSize[1]", 1)}
            interval={1}
            subtitle='pt'
            minValue={1}
          />
          <Flex
            flexDirection={"row"}
            width={[1]}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Title title='Line Height' />
            <Counter
              onSetValue={(value) =>
                onChangeValue("lineHeight", `${value}%`, 1)
              }
              value={lineHeightD}
              interval={10}
              subtitle='%'
              minValue={100}
            />
          </Flex>
          <Flex
            flexDirection={"row"}
            width={[1]}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Title title='Letter Spacing' />
            <Counter
              onSetValue={(value) =>
                onChangeValue("letterSpacing", `${value}px`, 1)
              }
              value={letterSpacingD}
              interval={1}
              subtitle='px'
              minValue={-5}
            />
          </Flex>
        </ContentDetail>
        <BlockCounter
          title='Container Padding'
          containerPadding={value.containerPadding[1]}
          onUpdatePadding={(cp) => onChangeValue("containerPadding", cp, 1)}
        />
      </Box>
      <Box width={[1]}>
        <Text fontWeight={"bold"} py={[2]}>
          Mobile Properties
        </Text>
        <ContentDetail>
          <SelectInput
            id={`mobile-text-align`}
            title={"Text Align"}
            value={value.textAlign[0]}
            list={defaultAlignText}
            onChange={(e) => onChangeValue("textAlign", e.target.value, 0)}
          />
          <Title title={"Font Size"} />
          <Counter
            onSetValue={(value) => onChangeValue("fontSize", value, 0)}
            value={get(value, "fontSize[0]", 10)}
            interval={1}
            subtitle='pt'
            minValue={5}
          />
          <Flex
            flexDirection={"row"}
            width={[1]}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Title title='Line Height' />
            <Counter
              onSetValue={(value) =>
                onChangeValue("lineHeight", `${value}%`, 0)
              }
              value={lineHeightM}
              interval={10}
              subtitle='%'
              minValue={100}
            />
          </Flex>
          <Flex
            flexDirection={"row"}
            width={[1]}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Title title='Letter Spacing' />
            <Counter
              onSetValue={(value) =>
                onChangeValue("letterSpacing", `${value}px`, 0)
              }
              value={letterSpacingM}
              interval={1}
              subtitle='px'
              minValue={-5}
            />
          </Flex>
        </ContentDetail>
        <BlockCounter
          title='Container Padding'
          containerPadding={value.containerPadding[0]}
          onUpdatePadding={(cp) => onChangeValue("containerPadding", cp, 0)}
        />
      </Box>
    </Box>
  );
};
