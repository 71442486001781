import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import { UploadAvatar } from "../../../components";
import {
  BlockCounter,
  Counter,
  Alignment,
  Title,
  HrefContent,
} from "../components";
import { PanelCard, ContentDetail } from "../actions";
export const PanelAvatar = (props) => {
  const { data, state, onUpdateUrl, onUpdateAvatarSize } = props;
  const { type } = data.values;
  return (
    <PanelCard title={"Avatar"} id={"upload-avatar"}>
      <ContentDetail>
        <Title title='Upload Avatar' />
        <UploadAvatar
          type={type}
          imageUrl={state.url}
          onUpdateUrl={(value) => onUpdateUrl(value)}
          title='Avatar'
        />
      </ContentDetail>
      <ContentDetail>
        <Flex
          flexDirection={"row"}
          width={[1]}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Title title='Avatar Size' />
          <Counter
            onSetValue={(value) => onUpdateAvatarSize(value)}
            value={state.size}
            interval={1}
            subtitle='px'
          />
        </Flex>
      </ContentDetail>
    </PanelCard>
  );
};
export const PanelGeneral = (props) => {
  const { state, onSetValue } = props;

  return (
    <PanelCard title={"General"} id={"general-avatar"}>
      <Flex width={[1]} flexDirection={"column"}>
        <ContentDetail>
          <BlockCounter
            containerPadding={state.containerPadding}
            onUpdatePadding={(containerPadding) =>
              onSetValue("containerPadding", containerPadding)
            }
          />
        </ContentDetail>
        <ContentDetail>
          <Alignment
            value={state.align}
            onChange={(value) => onSetValue("align", value)}
            title='Alignment'
          />
        </ContentDetail>
      </Flex>
      <HrefContent
        link={get(state, "link", {})}
        onSetValue={(k, v) => onSetValue(k, v)}
        websiteId={props.websiteId}
      />
    </PanelCard>
  );
};
