import { Layout2 } from "../../TemplateImageSonak";
export const template2A = {
  "fFY+SIcL": {
    id: "fFY+SIcL",
    type: "Row3",
    props: {
      values: {
        name: "Row3",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: true,
            align: "middle",
            gutter: 16,
            justify: "center",
            contentAlign: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "16px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "1080px",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "top",
            gutter: 50,
            justify: "center",
            contentAlign: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "16px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              parent: "Row",
              desktop: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 8,
                  order: 1,
                },
                padding: "16px 0px 16px 0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
              mobile: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                padding: "16px 0px 16px 0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              parent: "Row",
              desktop: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 8,
                  order: 2,
                },
                padding: "16px 0px 16px 0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
              mobile: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 2,
                },
                padding: "16px 0px 16px 0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              parent: "Row",
              desktop: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 8,
                  order: 3,
                },
                padding: "16px 0px 16px 0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
              mobile: {
                show: true,
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 3,
                },
                padding: "16px 0px 16px 0px",
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },

    childOf: "Row",
    layout: Layout2,
    children: ["1PGf16wS", "JQL1dzJs", "ZEt9rDot"],
    myContent: [
      {
        id: "17CG5Mol",
        type: "ImageView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            type: "ImageView",
            align: "center",
            mobile: {
              show: true,
              width: "80px",
              height: "80px",
              margin: "0px",
              padding: "0px",
            },
            parent: "Content",
            desktop: {
              show: true,
              width: "80px",
              height: "80px",
              margin: "0px",
              padding: "0px",
            },
            imageUrl: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-desktop-I3X.png",
              mobile: {
                width: 0,
                height: 0,
              },
              desktop: {
                width: 0,
                height: 0,
              },
              urlMobile:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-mobile-PJL.png",
            },
          },
        },

        parent: {
          id: "1PGf16wS",
          idx: 0,
        },
        myParent: {
          id: "1PGf16wS",
          idx: 0,
          index: 1,
        },
        childOf: "Content",
        children: [],
      },
      {
        id: "0z8xi7Vx",
        type: "ImageView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            type: "ImageView",
            align: "center",
            mobile: {
              show: true,
              width: "80px",
              height: "80px",
              margin: "0px",
              padding: "0px",
            },
            parent: "Content",
            desktop: {
              show: true,
              width: "80px",
              height: "80px",
              margin: "0px",
              padding: "0px",
            },
            imageUrl: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-desktop-NTJ.png",
              mobile: {
                width: 0,
                height: 0,
              },
              desktop: {
                width: 0,
                height: 0,
              },
              urlMobile:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-mobile-9HH.png",
            },
          },
        },
        parent: {
          id: "ZEt9rDot",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "ZEt9rDot",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "MKyGswv3",
        type: "ImageView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            type: "ImageView",
            align: "center",
            mobile: {
              show: true,
              width: "80px",
              height: "80px",
              margin: "0px",
              padding: "0px",
            },
            parent: "Content",
            desktop: {
              show: true,
              width: "80px",
              height: "80px",
              margin: "0px",
              padding: "0px",
            },
            imageUrl: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-desktop-JCJ.png",
              mobile: {
                width: 0,
                height: 0,
              },
              desktop: {
                width: 0,
                height: 0,
              },
              urlMobile:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-mobile-65I.png",
            },
          },
        },

        parent: {
          id: "JQL1dzJs",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "JQL1dzJs",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "Oe0xL173",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text:
              '<p class="ql-align-center">When you shop on PlayitRight, you\'re shopping the real deal. Everything is guaranteed genuine and 100% authentic.</p>',
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 8,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 0px 0px",
            },
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "16px 0px 0px 0px",
            },
            parent: "Content",
            isShowMobile: true,
            isShowDesktop: true,
          },
        },

        parent: {
          id: "JQL1dzJs",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "JQL1dzJs",
          idx: 2,
          index: 1,
        },
      },
      {
        id: "V5LLMyO9",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text:
              '<p class="ql-align-center"><strong>MONEY-BACK GUARANTEE</strong></p>',
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 0px 0px",
            },
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 12,
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 0px 0px",
            },
            parent: "Content",
            isShowMobile: true,
            isShowDesktop: true,
          },
        },

        parent: {
          id: "ZEt9rDot",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "ZEt9rDot",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "gQjoZn9m",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text:
              '<p class="ql-align-center"><strong>AUTHENTICITY</strong></p>',
            type: "TextView",
            isLink: false,
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 0px 0px",
            },
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 12,
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 0px 0px",
            },
            parent: "Content",
            isShowMobile: true,
            isShowDesktop: true,
          },
        },

        parent: {
          id: "JQL1dzJs",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "JQL1dzJs",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "iUYRhRG2",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text:
              '<p class="ql-align-center">Guaranteed by manufacturers of each brand by their own waranties.</p>',
            type: "TextView",
            isLink: true,
            parent: "Content",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 8,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 10px 0px",
            },
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "16px 0px 0px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },

        parent: {
          id: "1PGf16wS",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "1PGf16wS",
          idx: 2,
          index: 1,
        },
      },
      {
        id: "tCoHMB2f",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text:
              '<p class="ql-align-center">PlayitRight gives you complete confidence in what you buy with the shop it. Don\'t Sweat it Money-back Guarantee.</p>',
            type: "TextView",
            isLink: true,
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 8,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 10px 0px",
            },
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "16px 0px 0px 0px",
            },
            parent: "Content",
            isShowMobile: true,
            isShowDesktop: true,
          },
        },

        parent: {
          id: "ZEt9rDot",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "ZEt9rDot",
          idx: 2,
          index: 1,
        },
      },
      {
        id: "wDnBF2JU",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text:
              '<p class="ql-align-center"><strong>QUALITY TESTED</strong></p>',
            type: "TextView",
            isLink: false,
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "8px 0px 0px 0px",
            },
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 12,
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 0px 0px",
            },
            parent: "Content",
            isShowMobile: true,
            isShowDesktop: true,
          },
        },

        parent: {
          id: "1PGf16wS",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "1PGf16wS",
          idx: 1,
          index: 1,
        },
      },
    ],
  },
};
