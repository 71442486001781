import React from "react";
//, { useRef }
// import { elasticSearchServer } from "../../../../../restClient";
// import Slider from "react-slick";
import { Box, Flex, Text } from "rebass";
import { get } from "lodash";
// import { ReactiveBase, ReactiveList } from "@appbaseio/reactivesearch";
import { ShowAllContent } from "./Items";
// import ItemCategory from "./ItemCategory";
// import { defaultSetting, settingsList } from "./utils";

export default (props) => {
  // const refContainer = useRef(null);
  // const [state, setState] = useState({ isIndex: 0, isEnd: false });
  const {
    screenWidth,
    title,
    desktop,
    colorProps,
    titleViewAll,
    // tags,
    // modeType,
  } = props;
  const fontSizeHeader = get(desktop, "header.fontSize", 16);
  const showHeader = get(desktop, "header.show", false);
  const headerPadding = get(desktop, "header.padding", "0px");
  const showViewAllHeader = get(desktop, "header.showViewAll", false);
  // const settings = settingsList(
  //   get(desktop, "setting", defaultSetting),
  //   screenWidth,
  //   (index) => onChange(index)
  // );
  // const onChange = (index) => {
  // const { valueText, screenWidth } = props;
  // const addIndex = screenWidth > 702 ? 6 : 3;
  // const currentIndex = index + addIndex;
  // let isEndCurrent = false;
  // if (currentIndex >= valueText.length) {
  //   isEndCurrent = true;
  // }
  // setState({
  //   isIndex: index,
  //   isEnd: isEndCurrent,
  // });
  // };
  return (
    <Box style={{ width: screenWidth }}>
      {showHeader && (
        <Flex
          width={[1]}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexDirection={"row"}
          style={{ padding: headerPadding }}
        >
          <Text style={{ fontSize: `${fontSizeHeader}pt` }} fontWeight={"bold"}>
            {title}
          </Text>
          {showViewAllHeader && (
            <ShowAllContent
              url={""}
              title={titleViewAll}
              viewallColor={get(colorProps, "viewallColor", "#000000")}
            />
          )}
        </Flex>
      )}
      <div style={{ backgroundColor: "transparent" }}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"center"}
          p={[2]}
        >
          <Text textAlign={"center"}>Items Content</Text>
        </Flex>

        {/* <ReactiveBase app='product' url={elasticSearchServer}>
          <ReactiveList
            componentId='results'
            dataField='name'
            infiniteScroll={false}
            className='product-list-slide-categories-reactactive'
            showResultStats={false}
            defaultQuery={() => {
              return {
                query: {
                  bool: {
                    should: [
                      {
                        terms: {
                          "tags.keyword": tags,
                        },
                      },
                    ],
                    minimum_should_match: "1",
                  },
                },
              };
            }}
            render={({ data }) => (
              <Slider
                ref={refContainer}
                {...settings}
                style={{ backgroundColor: "transparent" }}
              >
                {data &&
                  data.map((item, index) => {
                    return (
                      <ItemCategory
                        item={item}
                        key={index}
                        size={[1]}
                        screenWidth={screenWidth}
                        colorProps={colorProps}
                        desktop={desktop}
                        modeType={modeType}
                      />
                    );
                  })}
              </Slider>
            )}
            pagination={false}
            renderError={(error) => <div />}
          />
        </ReactiveBase> */}
        {/*  */}
      </div>
    </Box>
  );
};
