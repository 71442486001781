import React from "react";
import { Counter, SwitchInput, Title, BlockCounter } from "../components";
import { ContentDetail } from "../actions";
import { get } from "lodash";
export default (props) => {
  const { state, onSetValue, contentId } = props;
  const onStatePropsValue = (k, v) => {
    const params = {
      ...state,
      [k]: v,
    };
    onSetValue(params);
  };
  return (
    <ContentDetail>
      <BlockCounter
        title={"Item Content Padding"}
        containerPadding={state.padding}
        onUpdatePadding={(containerPadding) =>
          onStatePropsValue("padding", containerPadding)
        }
      />
      <BlockCounter
        title={"Item Title Padding"}
        containerPadding={state.titlePadding}
        onUpdatePadding={(containerPadding) =>
          onStatePropsValue("titlePadding", containerPadding)
        }
      />
      <Title title={"Title Font size"} />
      <Counter
        onSetValue={(value) => onStatePropsValue("fontSize", value)}
        value={state.fontSize}
        interval={1}
        subtitle='pt'
        minValue={5}
      />
      <SwitchInput
        id={`use-show-list-ShowViewAllBottom-${contentId}`}
        title={"Show ViewAll Bottom"}
        checked={get(state, "showViewAllBottom", false)}
        onChange={(e) =>
          onStatePropsValue("showViewAllBottom", e.target.checked)
        }
      />
    </ContentDetail>
  );
};
