import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
const RaTextField = ({ record, source }) => {
  return (
    <Flex
      flexDirection={"row"}
      flexWrap={"wrap"}
      alignItems={"center"}
      sx={{ minHeight: 44 }}
    >
      {get(record, source, "")}
    </Flex>
  );
};

export default RaTextField;
