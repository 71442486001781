import React, { Component } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { slice, find } from "lodash";
import styled from "styled-components";
import { Dialog, Button, IconButton } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import { icon } from "./dataIcon";
import FontIcon from "@material-ui/core/Icon";

import DialogActions from "@material-ui/core/DialogActions";
import { SwitchInput } from "../switch";

import "./index.css";

const data = [];

class DialogIconTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      iconItem: props.iconItem,
      isEmptyTheme: false,
      page: 1,
      perPage: 100,
      total: icon.length,
      dataIcon: slice(icon, [0], [100]),
    };
    this.onLoadMore = this.onLoadMore.bind(this);
  }

  checkImage = (id) => {
    let image = "";
    let result = find(data, function (item) {
      return item.id === id;
    });
    if (result) {
      return result.bg;
    }

    return image;
  };
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  onIconSelected = (item) => {
    this.setState({ open: false, iconItem: item.icon, isEmptyTheme: false });
    this.props.onItemSelected(item.icon);
  };
  onToggle = (e, v) => {
    if (e.target.checked) {
      this.setState({ isEmptyTheme: e.target.checked, iconItem: "" });
      this.props.onItemSelected("");
    } else {
      this.setState({ isEmptyTheme: e.target.checked });
      this.props.onItemSelected(this.state.iconItem);
    }
  };
  onLoadMore = () => {
    const { page, perPage, total, dataIcon } = this.state;
    const newPage = page + 1;
    const offsetEnd = Math.min(newPage * perPage, total);
    const offsetBegin = Math.min((newPage - 1) * perPage + 1, offsetEnd);
    const lastPage = Math.ceil(total / perPage) || 1;
    if (newPage <= lastPage) {
      const addIconArray = slice(icon, [offsetBegin], [offsetEnd]);
      this.setState({
        page: newPage,
        dataIcon: update(dataIcon, { $push: addIconArray }),
      });
    }
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { iconItem } = nextProps;
    if (nextProps !== this.props.nextProps) {
      this.setState({ iconItem: iconItem });
    }
  }
  render() {
    const { page, perPage, total, isRequired } = this.state;
    const lastPage = Math.ceil(total / perPage) || 1;
    return (
      <div>
        <div>
          {isRequired && (
            <SwitchInput
              id={`remove-icon`}
              title={"Remove Icon"}
              checked={this.state.isEmptyTheme}
              onToggle={(e) => this.onToggle(e)}
            />
          )}
        </div>
        <DivBox>
          <Button variant='contained' color='primary' onClick={this.handleOpen}>
            Select Icon
          </Button>
          {this.state.iconItem && (
            <div style={{ paddingLeft: 16, paddingTop: 8 }}>
              <FontIcon className='material-icons'>
                {this.state.iconItem}
              </FontIcon>
            </div>
          )}
        </DivBox>

        <Dialog
          title='Select Icon'
          open={this.state.open}
          onClose={this.handleClose}
          scroll={"paper"}
        >
          <DialogContent dividers={true}>
            <DivImageContainer>
              <div>
                {this.state.dataIcon.map((item, index) => {
                  return (
                    <IconButton
                      key={index}
                      style={{ padding: 10 }}
                      onClick={() => this.onIconSelected(item)}
                    >
                      <FontIcon className='material-icons'>
                        {item.icon}
                      </FontIcon>
                    </IconButton>
                  );
                })}
              </div>
            </DivImageContainer>
          </DialogContent>
          <DialogActions>
            {page < lastPage && (
              <BoxButton>
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth={true}
                  onClick={this.onLoadMore}
                >
                  Load More
                </Button>
              </BoxButton>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const DivImageContainer = styled.div`
  display: inline-flex;
`;

const DivBox = styled.div`
  padding: 16px 0px;
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const BoxButton = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;

DialogIconTemplate.propTypes = {
  onItemSelected: PropTypes.func,
  iconItem: PropTypes.string,
  isRequired: PropTypes.bool,
};

DialogIconTemplate.defaultProps = {
  onItemSelected: () => {},
  iconItem: "",
  isRequired: false,
};

export default DialogIconTemplate;
