import React, { Fragment } from "react";
import {
  ReferenceInput,
  BooleanInput,
  TextInput,
  SelectInput,
  required,
  FormDataConsumer,
} from "react-admin";
import { Flex } from "rebass";
import {
  BetterSimpleForm,
  BetterEdit,
  BetterCreate,
  EditToolbar,
  ActionForm,
} from "../../components";
import { ROLE_USER } from "../../utils";
import { roles } from "./utils";
import { get } from "lodash";
/**
  RaBooleanInput,
  NoAccessPage, */

const Form = ({ isEdit, ...props }) => {
  const { permissions } = props;
  // if (permissions === ROLE_USER.super_admin) {
  //   //isEdit &&
  //   return <NoAccessPage />
  // }

  return (
    <BetterSimpleForm {...props} style={{ backgroundColor: "#FFFFFF" }}>
      <Flex width={[1]} px={[3]} py={[2]} flexDirection={"column"}>
        <BooleanInput
          source='status'
          parse={(v) => (v ? 1 : 0)}
          format={(v) => v === 1}
        />
        {/* {props.permissions === ROLE_USER.super_admin && (
        <ReferenceInput
          source='loyalty_program_id'
          reference='loyaltyPrograms'
          style={{ width: 300 }}
        >
          <SelectInput optionText='name' />
        </ReferenceInput>
      )} */}

        <TextInput source='first_name' style={{ width: 256 }} />
        <TextInput source='last_name' style={{ width: 256 }} />
        <TextInput source='nickname' style={{ width: 256 }} />
        <TextInput
          source='email'
          type='email'
          validate={required()}
          style={{ width: 256 }}
        />
        <TextInput source='mobile' style={{ width: 256 }} />
        {props.permissions === ROLE_USER.super_admin && (
          <SelectInput
            source='role'
            choices={roles(permissions)}
            validate={required()}
            style={{ width: 256 }}
          />
        )}

        {/* <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.role === ROLE_USER.merchant && (
            <ReferenceInput
              source='custom.merchant_id'
              reference='merchants'
              label='resources.users.fields.custom.merchant'
              filter={{ loyalty_program_id: formData.loyalty_program_id }}
            >
              <SelectInput optionText='name' />
            </ReferenceInput>
          )
        }
      </FormDataConsumer> */}

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            props.permissions === ROLE_USER.account_admin &&
            formData.role !== ROLE_USER.account_admin &&
            formData.role !== ROLE_USER.super_admin && (
              <SelectInput
                source='role'
                choices={roles(permissions)}
                validate={required()}
                style={{ width: 256 }}
              />
            )
          }
        </FormDataConsumer>
        {/* <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            formData.role === ROLE_USER.client_admin && (
              <ReferenceInput
                source='client_id'
                reference='clients'
                style={{ width: 256 }}
              >
                <SelectInput optionText='name' />
              </ReferenceInput>
            )
          );
        }}
      </FormDataConsumer> */}

        <FormDataConsumer>
          {({ formData }) => {
            console.log("formData", formData);

            return (
              <Fragment>
                {ROLE_USER.branch_staff === get(formData, "role") && (
                  <ReferenceInput
                    source='custom1.store_id'
                    reference='stores'
                    label='resources.users.fields.stores'
                    filter={{ site_id: formData.site_id }}
                    perPage={0}
                    style={{ width: 256 }}
                  >
                    <SelectInput optionText='name' />
                  </ReferenceInput>
                )}
                {ROLE_USER.branch_staff !== get(formData, "role") && (
                  <ReferenceInput
                    source='website_id'
                    reference='websites'
                    label='resources.users.fields.website'
                    filter={{ site_id: formData.site_id }}
                    style={{ width: 256 }}
                    perPage={0}
                  >
                    <SelectInput optionText='name' />
                  </ReferenceInput>
                )}
              </Fragment>
            );
          }}
        </FormDataConsumer>
      </Flex>
    </BetterSimpleForm>
  );
};
export const create = (props) => (
  <BetterCreate
    {...props}
    actions={<ActionForm title={"resources.users.name"} pt={3} />}
  >
    <Form {...props} toolbar={<EditToolbar />} />
  </BetterCreate>
);
export const edit = (props) => (
  <BetterEdit
    {...props}
    actions={<ActionForm title={"resources.users.name"} pt={3} />}
  >
    <Form toolbar={<EditToolbar />} {...props} isEdit={true} />
  </BetterEdit>
);
