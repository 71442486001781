import React from "react";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import { get } from "lodash";
import { Image, Flex, Box } from "rebass";
import { TextAnimation, defaultSubtitle, defaultTitle } from "./Text";

const defaultContainerTitle = {
  parent: {
    alignItems: ["center", "center"],
    direction: ["column", "row"],
    justifyContent: ["center", "flex-end"],
  },
  title: {
    width: ["100%", "100%"],
    justifyContent: ["center", "center"],
    direction: ["column", "column"],
    alignItems: ["center", "center"],
  },
};

const RightImageElement = (props) => {
  const { data, id, matches, widthContainer } = props;
  const boxImage = get(data, "position.boxImage", "486px");
  // const marginLeftText = get(data, "position.marginLeftText", "-100px")
  const isAnimation = get(data, "image.isAnimation", false);
  const animationImage = isAnimation
    ? {
        type: get(data, "image.animation.type", "from"),
        ease: get(data, "image.animation.ease", "easeInQuart"),
        opacity: get(data, "image.animation.opacity", 0),
        delay: get(data, "image.animation.delay", 40),
        duration: get(data, "image.animation.duration", 1500),
      }
    : {};

  const mobileImage = get(
    data,
    "url_image_mobile",
    get(
      data,
      "url_image",
      "https://via.placeholder.com/400x400?text=400x400=CMS"
    )
  );

  const useMobile = matches
    ? mobileImage !== ""
      ? mobileImage
      : get(
          data,
          "url_image",
          "https://via.placeholder.com/400x400?text=400x400=CMS"
        )
    : get(
        data,
        "url_image",
        "https://via.placeholder.com/480x480?text=480x480=CMS"
      );

  const title = get(data, "title", defaultTitle);
  const subtitle = get(data, "subtitle", defaultSubtitle);
  const imageSize = widthContainer > 480 ? boxImage : widthContainer;
  // const direction = widthContainer > 480 ? "row" : "column-reverse";
  // const ml = widthContainer > 480 ? marginLeftText : "0px"
  const visibleTitle = get(title, "visible", true);
  const visibleSubtitle = get(subtitle, "visible", true);
  const marginTitle =
    widthContainer > 480
      ? get(data, "position.marginTitleDesktop", "0px")
      : get(data, "position.marginTitleMobile", "0px");
  const paddingTitle =
    widthContainer > 480
      ? get(data, "position.paddingTitleDesktop", "0px")
      : get(data, "position.paddingTitleMobile", "0px");
  const marginImage =
    widthContainer > 480
      ? get(data, "position.marginImageDesktop", "0px")
      : get(data, "position.marginImageMobile", "0px");
  const paddingImage =
    widthContainer > 480
      ? get(data, "position.paddingImageDesktop", "0px")
      : get(data, "position.paddingImageMobile", "0px");
  const containerParent = get(
    data,
    "containerTitle.parent",
    defaultContainerTitle.parent
  );
  const boxTitle = get(
    data,
    "containerTitle.title",
    defaultContainerTitle.title
  );
  return (
    <Flex
      width={[1]}
      flexDirection={"column"}
      justifyContent={"center"}
      style={{ height: "100%" }}
    >
      <QueueAnim name={`Queue-${data.name}-${id}`}>
        <Flex width={[1]} alignItems={"center"}>
          <Flex
            width={[1]}
            flexDirection={
              widthContainer > 480
                ? containerParent.direction[1]
                : containerParent.direction[0]
            }
            alignItems={
              widthContainer > 480
                ? containerParent.alignItems[1]
                : containerParent.alignItems[0]
            }
            justifyContent={
              widthContainer > 480
                ? containerParent.justifyContent[1]
                : containerParent.justifyContent[0]
            }
          >
            <Flex
              width={
                widthContainer > 480 ? boxTitle.width[1] : boxTitle.width[0]
              }
              flexDirection={
                widthContainer > 480
                  ? boxTitle.direction[1]
                  : boxTitle.direction[0]
              }
              alignItems={
                widthContainer > 480
                  ? boxTitle.alignItems[1]
                  : boxTitle.alignItems[0]
              }
              justifyContent={
                widthContainer > 480
                  ? boxTitle.justifyContent[1]
                  : boxTitle.justifyContent[0]
              }
              style={{ padding: paddingTitle, margin: marginTitle, zIndex: 9 }}
            >
              {visibleTitle && (
                <TextAnimation data={title} widthContainer={widthContainer} />
              )}
              {visibleSubtitle && (
                <TextAnimation
                  data={subtitle}
                  widthContainer={widthContainer}
                />
              )}
            </Flex>
            <TweenOne
              animation={animationImage}
              name={`TweenOne-image-right-${data.name}-${id}`}
            >
              <Box
                width={imageSize}
                // pt={widthContainer > 480 ? [0] : [3]}
                style={{ padding: paddingImage, margin: marginImage }}
              >
                <Image src={useMobile} />
              </Box>
              {/* <Image src={useMobile} style={{ width: imageSize }} /> */}
            </TweenOne>
          </Flex>
        </Flex>
      </QueueAnim>
    </Flex>
  );
};

export default RightImageElement;
