import React from "react"
import { Flex, Box, Text } from "rebass"
const listBG = [
  {
    value: {
      bg1: "rgb(34,193,195)",
      bg2: "rgba(253,187,45,1)",
      type: "linear-gradient",
      degre: 160,
      linear: [0, 100],
    },
    bg: {
      background:
        "linear-gradient(160deg, rgb(34,193,195) 0%, rgba(253,187,45,1) 100%)",
    },
  },
  {
    value: {
      bg1: " #FC466B",
      bg2: "#3F5EFB",
      type: "linear-gradient",
      degre: 90,
      linear: [0, 100],
    },
    bg: {
      background: "linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%)",
    },
  },
  {
    value: {
      bg1: " #FDBB2D",
      bg2: "#22C1C3",
      type: "linear-gradient",
      degre: 90,
      linear: [0, 100],
    },
    bg: {
      background: "linear-gradient(90deg, #FDBB2D 0%, #22C1C3 100%)",
    },
  },
  {
    value: {
      bg1: " #1CB5E0",
      bg2: "#000851",
      type: "linear-gradient",
      degre: 90,
      linear: [0, 100],
    },
    bg: {
      background: "linear-gradient(90deg, #1CB5E0 0%, #000851 100%)",
    },
  },
  {
    value: {
      bg1: " #d53369",
      bg2: "#daae51",
      type: "linear-gradient",
      degre: 90,
      linear: [0, 100],
    },
    bg: {
      background: "linear-gradient(90deg, #d53369 0%, #daae51 100%)",
    },
  },
  {
    value: {
      bg1: " #fcff9e",
      bg2: "#c67700",
      type: "linear-gradient",
      degre: 90,
      linear: [0, 100],
    },
    bg: {
      background: "linear-gradient(90deg, #fcff9e 0%, #c67700 100%)",
    },
  },
  {
    value: {
      bg1: " #00d2ff",
      bg2: "#3a47d5",
      type: "linear-gradient",
      degre: 90,
      linear: [0, 100],
    },
    bg: {
      background: "linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%)",
    },
  },
  {
    value: {
      bg1: " #0700b8",
      bg2: "#00ff88",
      type: "linear-gradient",
      degre: 90,
      linear: [0, 100],
    },
    bg: {
      background: "linear-gradient(90deg, #0700b8 0%, #00ff88 100%)",
    },
  },
  {
    value: {
      bg1: " #00C9FF",
      bg2: "#92FE9D",
      type: "linear-gradient",
      degre: 90,
      linear: [0, 100],
    },
    bg: {
      background: "linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%)",
    },
  },
  {
    value: {
      bg1: " #efd5ff",
      bg2: "#515ada",
      type: "linear-gradient",
      degre: 90,
      linear: [0, 100],
    },
    bg: {
      background: "linear-gradient(90deg, #efd5ff 0%, #515ada 100%)",
    },
  },
  {
    value: {
      bg1: " #e3ffe7",
      bg2: "#d9e7ff",
      type: "linear-gradient",
      degre: 90,
      linear: [0, 100],
    },
    bg: {
      background: "linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%)",
    },
  },
]

const BoxListColor = (props) => {
  const { setGradient } = props

  const handleChange = (color) => {
    console.log(color)
    setGradient(color.value)
  }
  return (
    <Box pl={[3]} ml={[4]}>
      <Text textAlign={"left"} pb={[3]} px={[2]}>
        List Gradient
      </Text>
      <Flex flexDirection={"row"} flexWrap={"wrap"} py={[2]}>
        {listBG.map((item, index) => (
          <Box
            key={index}
            width={["60px"]}
            p={[2]}
            mx={[2]}
            mb={[3]}
            style={{
              ...item.bg,
              height: 60,
              cursor: "pointer",
              border: "2px solid #C4c4c4",
            }}
            onClick={() => handleChange(item)}
          />
        ))}
      </Flex>
    </Box>
  )
}

export default BoxListColor
