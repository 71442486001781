import React from "react"
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd"
import YouTube from "react-youtube"
import { connect } from "../../core"

import { target } from "./utils"
// const target = {
//   drop(props, monitor, component) {
//     if (!monitor.didDrop()) {
//       props.layoutState.insertOrMoveItem(
//         props.id,
//         props.children.length,
//         monitor.getItem()
//       )
//     }
//   },
// }

const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
}) => {
  const { containerPadding, alignValue, videoId } = layoutState.getItemJS(
    id
  ).props.values
  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        display: "flex",
        padding: containerPadding,
        flex: 1,
        justifyContent: alignValue,
      }}
    >
      <YouTube videoId={videoId} />
    </div>
  )
}

const LayoutContainer = DropTarget("YoutubeView", target, (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(EditLayout)

export default connect("layoutState", "readOnly")(LayoutContainer)
