import React, { Fragment, useState } from "react";
import { Flex, Box, Text } from "rebass";
import { get, map } from "lodash";
import { IconButton, Dialog, Button } from "@material-ui/core";
import { DialogTitle, DialogActions, DialogContent } from "./styles";
import ActionAdd from "@material-ui/icons/AddCircle";
import ActionDelete from "@material-ui/icons/Delete";
import TextInput from "../TextInput";
import TextAttributes from "./TextAttributes";
import { UploadImageListSlide } from "../../../../components";
import { DialogHrefContent } from "../hrefurl";
// const customContentStyle = {
//   width: "100%",
//   maxWidth: "none",
//   padding: 20,
// }

const arrayRemove = (arr, value) => {
  arr.splice(value, 1);
  return arr;
};

const ListGroup = (props) => {
  const myList = get(props, "list", []);
  const checkText = map(myList, function (o) {
    const newText = o.text
      ? o.text
      : { fontSize: [10, 14], textAlign: ["center", "center"] };
    return { ...o, text: newText };
  });
  const [state, setState] = useState({
    list: checkText,
    open: false,
  });
  const handleOpen = () => {
    setState({ ...state, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleSave = () => {
    setState({ ...state, open: false });
    props.onChangeText(state.list);
  };
  const onAddText = () => {
    const newList = state.list;
    newList.push({
      imageUrl: {
        url: "",
        urlMobile: "",
      },
      title: "Bcx Studio",
      link: {
        target: "",
        type: "URL",
        value: "",
        isLink: false,
      },
      text: {
        fontSize: [10, 14],
        textAlign: ["center", "center"],
      },
    });
    setState({
      ...state,
      list: newList,
    });
  };
  const onDelete = (index) => {
    const newList = arrayRemove(state.list, index);
    setState({
      ...state,
      list: newList,
    });
  };

  const onUpdateText = (value, index) => {
    const { list } = state;
    list[index].title = value;
    setState({
      ...state,
      list: list,
    });
  };
  // const onUpdateItemHref = (key, value, index) => {
  //   const { list } = state;
  //   const newItem = {
  //     ...list[index].href,
  //     [key]: value,
  //   };
  //   list[index].href = newItem;
  //   setState({
  //     ...state,
  //     list: list,
  //   });
  // };
  const onUpdateUrl = (value, index) => {
    const { list } = state;
    list[index].imageUrl = value;
    setState({
      ...state,
      list: list,
    });
  };

  const onSetItemLinkValue = (value, index) => {
    const { list } = state;
    list[index].link = value;
    setState({
      ...state,
      list: list,
    });
  };
  const onSetItemTextValue = (value, index) => {
    const { list } = state;
    list[index].text = value;
    console.log(list);
    setState({
      ...state,
      list: list,
    });
  };
  return (
    <Fragment>
      <Box p={[2]}>
        <Button
          variant='outlined'
          onClick={() => handleOpen()}
          color={"primary"}
        >
          Content of List
        </Button>
      </Box>
      <Dialog
        onClose={() => handleClose()}
        aria-labelledby='customized-dialog-title'
        open={state.open}
        fullScreen={true}
        scroll={"paper"}
      >
        <DialogTitle id='customized-dialog-title' onClose={() => handleClose()}>
          Content of List
        </DialogTitle>
        <DialogContent dividers>
          <Box width={[1]}>
            <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Box width={[1]} p={[2]}>
                <Text fontSize={[2]} fontWeight={"bold"}>
                  Items
                </Text>
              </Box>
              <Box>
                <IconButton
                  onClick={() => onAddText()}
                  style={{ color: "rgb(0, 188, 212)" }}
                >
                  <ActionAdd color={"inherit"} />
                </IconButton>
              </Box>
            </Flex>
            <Box width={[1]}>
              {state.list.length > 0 &&
                state.list.map((item, index) => {
                  // const valueTarget =
                  //   get(item, "href.target", "same") === "_blank"
                  //     ? "_blank"
                  //     : "same";
                  return (
                    <Flex
                      key={index}
                      width={[1]}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      align={"center"}
                      style={{
                        paddingBottom: 4,
                        borderBottomWidth: 1,
                        borderBottomColor: "rgba(0,0,0,0.2)",
                        borderBottomStyle: "solid",
                      }}
                    >
                      <Box width={[1]}>
                        <Flex width={[1]} flexDirection={"row"}>
                          <UploadImageListSlide
                            type={"list-slide"}
                            imageUrl={item.imageUrl}
                            onUpdateUrl={(value) => onUpdateUrl(value, index)}
                            title='Image'
                          />
                          <Box width={[1]}>
                            <TextInput
                              value={item.title}
                              onChange={(value) => onUpdateText(value, index)}
                              name={`text-${index}`}
                              title='Title'
                              fullWidth={true}
                            />
                            <Flex width={[1]} flexDirection={"row"}>
                              <Box width={[1]}>
                                <TextAttributes
                                  textContent={item.text}
                                  indexItem={index}
                                  onSetValue={(v) =>
                                    onSetItemTextValue(v, index)
                                  }
                                />
                              </Box>
                              <Box width={[1]}>
                                <DialogHrefContent
                                  link={item.link}
                                  onSetValue={(v) =>
                                    onSetItemLinkValue(v, index)
                                  }
                                  websiteId={props.websiteId}
                                />
                              </Box>
                            </Flex>
                            {/* <TextInput
                              value={item.href.url}
                              onChange={(value) =>
                                onUpdateItemHref("url", value, index)
                              }
                              name={`url-${index}`}
                              title='Url'
                              fullWidth={true}
                            />
                            <SelectInput
                              id={`target-${index}`}
                              title={"Target"}
                              value={valueTarget}
                              list={dataHref}
                              onChange={(e) =>
                                onUpdateItemHref(
                                  "target",
                                  e.target.value,
                                  index
                                )
                              }
                            /> */}
                          </Box>
                        </Flex>
                      </Box>
                      <Box>
                        {state.list.length > 1 && (
                          <IconButton
                            onClick={() => onDelete(index)}
                            style={{ color: "rgb(0, 188, 212)" }}
                          >
                            <ActionDelete color={"inherit"} />
                          </IconButton>
                        )}
                      </Box>
                    </Flex>
                  );
                })}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color='primary'>
            Cancel
          </Button>
          <Button onClick={() => handleSave()} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ListGroup;
