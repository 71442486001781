import React from "react";
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd";
import { target } from "./utils";
import { get } from "lodash";
//import Separator from './Separator';
import { connect } from "../../core";

// const target = {
//   drop(props, monitor, component) {
//     if (!monitor.didDrop()) {
//       props.layoutState.insertOrMoveItem(props.id, props.children.length, monitor.getItem());
//     }
//   }
// };

let renderText = (
  value,
  containerPadding,
  lineHeight,
  letterSpacing,
  color,
  fs,
  textAlign
) => {
  const fontSize = fs ? { fontSize: `${fs}pt` } : {};
  return (
    <div
      style={{
        padding: containerPadding,
        lineHeight,
        letterSpacing,
        width: "100%",
        textAlign: textAlign,
        color: color,
        ...fontSize,
      }}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};

const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
}) => {
  /*isShowDesktop: true,
  isShowMobile: true, */
  const {
    containerPadding,
    text,
    lineHeight,
    backgroundColor,
    letterSpacing,
    desktop,
  } = layoutState.getItemJS(id).props.values;
  const mylineHeight = get(desktop, "lineHeight", lineHeight);
  const cp = get(desktop, "containerPadding", containerPadding);
  const ls = get(desktop, "letterSpacing", letterSpacing);
  const fs = get(desktop, "fontSize");
  const textAlign = get(desktop, "textAlign", "left");
  const color = get(desktop, "color", "#000000");
  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        backgroundColor: get(desktop, "backgroundColor", backgroundColor),
        display: "flex",
        flex: 1,
        padding: 0,
      }}
    >
      {renderText(text, cp, mylineHeight, ls, color, fs, textAlign)}
    </div>
  );
};

const LayoutContainer = DropTarget("TextView", target, (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(EditLayout);

export default connect("layoutState", "readOnly")(LayoutContainer);

// export default LayoutContainer;s
