import React from "react";
import { Flex } from "rebass";
import { BlockCounter, BlockCounterWithType, SwitchInput } from "../components";
/**
 * desktop: {
      show: true,
      width: "100%",
      height: "100%",
      margin: "0px",
      padding: "0px",
    },
 */

export default (props) => {
  const { state, onSetValue } = props;

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <SwitchInput
        id={`showMobile`}
        title='Show on Mobile'
        checked={state.show}
        onChange={(e) => onSetValue("show", e.target.checked)}
      />
      <BlockCounterWithType
        name='width-image-mobile-content'
        value={state.width}
        onSetValue={(v) => onSetValue("width", v)}
        title={"Width"}
      />
      <BlockCounterWithType
        name='height-image-mobile-content'
        value={state.height}
        onSetValue={(v) => onSetValue("height", v)}
        title={"Height"}
      />
      <BlockCounter
        title='Container Padding'
        containerPadding={state.padding}
        onUpdatePadding={(padding) => onSetValue("padding", padding)}
      />
      <BlockCounter
        title='Container Margin'
        containerPadding={state.margin}
        onUpdatePadding={(margin) => onSetValue("margin", margin)}
      />
      <SwitchInput
        id={`isFullHeightMobile`}
        title='Is Full Height (Flex)'
        checked={state.isFullHeight}
        onChange={(e) => onSetValue("isFullHeight", e.target.checked)}
      />
    </Flex>
  );
};
