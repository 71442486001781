import polyglotI18nProvider from "ra-i18n-polyglot"
import { resolveBrowserLocale } from "react-admin"
// import { LOCALE } from "../restClient"
import customEnglishMessages from "./en"
import customIndonesianMessages from "./id"
// const messages={
//   en: { ...englishMessages, ...customEnglishMessages }
// }
// export default locale => (messages["en"]);

const messages = {
  id: customIndonesianMessages,
  en: customEnglishMessages,
}

export default polyglotI18nProvider((locale) => {
  // console.log(locale)
  // const myLocale = localStorage.getItem(LOCALE)
  // console.log(locale, myLocale)
  // const isGetLocale = myLocale !== null ? myLocale : locale
  // console.log(isGetLocale)
  return messages.en
  //return messages[isGetLocale] ? messages[isGetLocale] : messages.en
}, resolveBrowserLocale())

// export default polyglotI18nProvider((locale) => {
//   console.log(locale, resolveBrowserLocale())
//   if (locale === "id") {
//     return import("./id.js").then((messages) => messages.default)
//   }
//   // if (locale !== "id") {
//   //   return import("./en").then((messages) => messages.default)
//   // }

//   // Always fallback on english
//   return customEnglishMessages
// }, "en")
