import React from "react"
import PropTypes from "prop-types"
import shouldUpdate from "recompose/shouldUpdate"
import { makeStyles } from "@material-ui/core"
import ContentCreate from "@material-ui/icons/Create"
import { Link, useLocation } from "react-router-dom"
import { linkToRecord } from "react-admin"
import { get } from "lodash"

import ButtonIconOnly from "./ButtonIconOnly"

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation()

const useStyles = makeStyles(theme => ({
  root: {
    width: 14,
    height: 14,
  },
  smallIcon: {
    fontSize: 14,
  },
}))

const MemberButton = ({
  basePath = "",
  label = "resources.buttons.chart",
  classes: classesOverride,
  record = {},
  isCustomLink = false,
  taburl = "",
  icon = <ContentCreate />,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverride })

  let urlLink = linkToRecord(basePath, record.id)
  const myLocation = useLocation()
  const urlPathName = get(myLocation, "pathname", "")

  if (isCustomLink && urlPathName && urlPathName !== "" && taburl !== "") {
    urlLink = urlPathName.includes(taburl)
      ? `${urlPathName}/${record.id}/members`
      : `${urlPathName}/${taburl}/${record.id}/members`
    // console.log(urlLink)
  }

  return (
    <ButtonIconOnly
      classes={classes}
      component={Link}
      to={urlLink}
      label={label}
      onClick={stopPropagation}
      {...rest}
    >
      {React.cloneElement(icon, {
        className: classes[`smallIcon`],
      })}
    </ButtonIconOnly>
  )
}

MemberButton.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  icon: PropTypes.element,
}

const enhance = shouldUpdate(
  (props, nextProps) =>
    props.translate !== nextProps.translate ||
    (props.record &&
      nextProps.record &&
      props.record.id !== nextProps.record.id) ||
    props.basePath !== nextProps.basePath ||
    (props.record == null && nextProps.record != null)
)

export default enhance(MemberButton)
