import jsonServerRestClient from "ra-data-json-server";
import { get } from "lodash";
import { fetchUtils, cacheDataProviderProxy } from "react-admin";

// export const API_DEBUG = "https://api.loyalty.olehshop.com/admin"
// export const API_DEBUG = "https://api.admin.staging.playitright.com"; //"https://admin-api.staging.commerce.bcxstudio.com"; //
export const API_DEBUG = "/api"; //"https://admin-api.staging.commerce.bcxstudio.com"; //
export const API_SERVER = API_DEBUG; //process.env.REACT_APP_SERVER || API_DEBUG
// console.log(API_SERVER)

export const elasticSearchServer =
  process.env.REACT_APP_SERVER_ELASTIC_SEARCH ||
  "https://api.staging.playitright.com/search";
const API_URL = API_SERVER.replace("/admin", "");
const API_SERVER_PRODUCTLIST = API_SERVER.replace("/admin", "");

const keyLocale = "_commerce_admin_bcx";
export const TOKEN = `token${keyLocale}`;
export const PROFILE = `profile${keyLocale}`;
export const ROLE = `role${keyLocale}`;
export const NOAUTHPAGE = `no_auth_page${keyLocale}`;
export const SITE_ID = `site_id${keyLocale}`;

export const CLIENT_URL = process.env.REACT_APP_CLIENT;

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: "application/json",
    });
  }
  const token = localStorage.getItem(TOKEN);
  options.headers.set("Authorization", `Bearer ${token}`);

  //can change url in here

  const resource = url.replace(API_SERVER, "");
  // console.log(resource)
  // console.log(url)
  if (resource.includes("programmes")) {
    url = url.replace("programmes", "loyaltyPrograms");
  } else if (resource.includes("catalogues")) {
    url = url.replace("catalogues", "catalogs");
  } else if (resource.includes("register")) {
    url = url.replace("register", "herokuProvisions");
    if (url.includes("herokuProvisions?")) {
      url = url + `&status=${encodeURIComponent("Pending review")}`;
    }
  } else if (resource.includes("provisions")) {
    url = url.replace("provisions", "herokuProvisions");
    if (url.includes("herokuProvisions?")) {
      url = url + `&status=${encodeURIComponent("Approved")}`;
    }
  } else if (resource.includes("layouts")) {
    url = url.replace("layouts", "websiteLayouts");
  } else if (resource.includes("reviews")) {
    url = url.replace("reviews", "productReviews");
  }

  return fetchUtils.fetchJson(url, options);
};
const newDataProvider = jsonServerRestClient(API_SERVER, httpClient);
//,5 * 60 * 1000
export const dataProvider = cacheDataProviderProxy(newDataProvider);

export const UploadClient = (formData, path) => {
  var myHeaders = new Headers();
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);
  return fetch(API_SERVER + path, {
    method: "POST",
    body: formData,
    headers: myHeaders,
  });
};
export const RestApiCollectionMarketers = (paramsId) => {
  let myHeaders = new Headers({ "Content-Type": "application/json" });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);
  const url = `${API_URL}/marketers/collections/${paramsId}`;
  // console.log(url);
  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  });
};

export const RestApiProductList = (urlParams) => {
  let myHeaders = new Headers({ "Content-Type": "application/json" });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);
  const url = API_SERVER_PRODUCTLIST + urlParams;
  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  });
};

export const RestApi = (urlParams, contentType = "application/json") => {
  let myHeaders = new Headers({ "Content-Type": contentType });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);
  const url = API_SERVER + urlParams;
  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  });
};

export const RestApiReward = (urlParams, method = "GET", params) => {
  let options = {};
  if (!options.headers) {
    options.headers = new Headers({ "Content-Type": "application/json" });
  }
  const token = localStorage.getItem(TOKEN);
  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
  }

  options.method = method;
  if (params) {
    options.body = JSON.stringify(params);
  }
  const url = `${API_SERVER}/${urlParams}`;
  return fetch(url, options);
};

export const loginClient = (params) => {
  const url = API_SERVER + "/sessions";
  return fetch(url, {
    method: "POST",
    body: params,
    headers: new Headers({ "Content-Type": "application/json" }),
  });
};
export const loginClientJWT = (params) => {
  const url = API_SERVER + "/../mc/sessions/token";
  return fetch(url, {
    method: "POST",
    body: params,
    headers: new Headers({ "Content-Type": "application/json" }),
  });
};

export const updateLogin = (response) => {
  localStorage.setItem(TOKEN, response.token);
  localStorage.setItem(PROFILE, JSON.stringify(response.profile));
  localStorage.setItem(ROLE, response.profile.role);
};

export const RestApiCollections = () => {
  let myHeaders = new Headers({ "Content-Type": "application/json" });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);
  const url = API_URL + "/dashboard/collections";
  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  });
};

export const RestApiContent = (params) => {
  let myHeaders = new Headers({ "Content-Type": "application/json" });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);
  const url = `${API_SERVER}/contents?${params}`;
  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  });
};
export const RestApiCollection = (params) => {
  let myHeaders = new Headers({ "Content-Type": "application/json" });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);
  const url = `${API_SERVER}/collections?${params}`;
  return fetch(url, {
    method: "GET",

    headers: myHeaders,
  });
};

export const RestApiCRUD = (urlParams, method, params) => {
  let myHeaders = new Headers({ "Content-Type": "application/json" });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);
  const url = `${API_SERVER}${urlParams}`;
  if (params) {
    return fetch(url, {
      method: method,
      body: params,
      headers: myHeaders,
    });
  }
  return fetch(url, {
    method: method,
    headers: myHeaders,
  });
};
export const RestApiCRUDString = (urlParams, method, params) => {
  let myHeaders = new Headers({ "Content-Type": "application/json" });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);
  const url = `${API_SERVER}${urlParams}`;
  return fetch(url, {
    method: method,
    body: JSON.stringify(params),
    headers: myHeaders,
  });
};

export const getSiteID = () => {
  const siteId = localStorage.getItem(SITE_ID);
  const profile = localStorage.getItem(PROFILE);
  if (siteId) {
    return Number(siteId);
  } else {
    return Number(get(profile, "site_id", 0));
  }
};

export const RestApiAll = (url, method, params) => {
  const apiRequest = [];
  params.map((item) => {
    // const url = `products?${item}`; //API_SERVER + "" + params
    var myHeaders = new Headers({
      "Content-Type": "application/json",
    });
    const token = localStorage.getItem(TOKEN);
    myHeaders.append("Authorization", `Bearer ${token}`);

    const options = {
      method: method,
      body: item,
      headers: myHeaders,
    };
    apiRequest.push(request(url, options));

    return null;
  });
  return Promise.all(apiRequest).then((values) => values);
};
export const getProductsList = () => {
  const url = `${API_SERVER}/products`; //API_SERVER + "" + params
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  }).then((response) => response.json());
};
export const getProductById = (params) => {
  const getParamsId = get(params, "queryKey[1]");
  const url = `${API_SERVER}/products/${getParamsId}`; //API_SERVER + "" + params
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  }).then((response) => response.json());
};
export const getData = (params) => {
  const url = `${API_SERVER}/${params}`; //API_SERVER + "" + params
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  }).then((response) => response.json());
};
export const RestApiProductAll = (params) => {
  const apiRequest = [];
  params.map((item) => {
    const url = `/products/${item}`; //API_SERVER + "" + params
    var myHeaders = new Headers({
      "Content-Type": "application/json",
    });
    const token = localStorage.getItem(TOKEN);
    myHeaders.append("Authorization", `Bearer ${token}`);

    const options = {
      method: "GET",
      body: item,
      headers: myHeaders,
    };
    apiRequest.push(request(url, options));

    return null;
  });
  return Promise.all(apiRequest).then((values) => values);
};

function request(url, options) {
  if (!options) {
    options = {};
  }
  if (!options.headers) {
    options.headers = new Headers({ "Content-Type": "application/json" });
  } else {
    options.headers.set("Content-Type", "application/json");
  }
  if (options.body) {
    options.body = JSON.stringify(options.body);
  }

  return fetch(`${API_SERVER}/${url}`, options).then((response) =>
    response.json()
  );
}

export const getCategories = (params) => {
  const url = `${API_SERVER}/categories${params}`; //API_SERVER + "" + params
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  }).then((response) => response.json());
};

export const getWebsites = () => {
  //websites
  const url = `${API_SERVER}/websites`; //API_SERVER + "" + params
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  }).then((response) => response.json());
};

export const getContents = (params) => {
  const url = `${API_SERVER}/contents${params}`; //API_SERVER + "" + params
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  }).then((response) => response.json());
};

export const RestJsonReports = (urlParams) => {
  let options = {};
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem(TOKEN);
  options.headers.set("Authorization", `Bearer ${token}`);
  options.method = "GET";
  const url = API_SERVER + urlParams;
  //"/reports/orders";
  return fetch(url, options);
};
