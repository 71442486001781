import React, { useState } from "react"
import PropTypes from "prop-types"
import Layout from "../dnd"
import { Layout6 } from "../ContentBox"
import { DivHover, DivHelp } from "./styled"

const CarouselView = props => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(true)
  }
  const onMouseOver = () => {
    setHover(false)
  }

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type="CarouselView" />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  )
}

CarouselView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  layout: PropTypes.func,
}

CarouselView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "CarouselView",
    altText: "Carousel",
    auto: false,
    direaction: "incremental",
    interval: 3,
    imageUrl: {
      url: [],
      urlMobile: [],
    },
  },
  layout: Layout6,
}
export default CarouselView
