export default {
  payments: {
    name: "Payments",
    create_title: "New Payment",
    edit_title: "Edit Payment",
    back: "Back to Payments",
    label: {
      settings: "Settings",
      conditions: "Conditions",
    },
    fields: {
      name: "Name",
      active: "Active",
      payment_gateway: "Payment Gateway",
      sandbox: "Sandbox",
      app_secret: "App secret",
      app_id: "App ID",
      supported_countries: "Supported Countries",
      minimum_subtotal: "Minimum Subtotal",
      maximum_subtotal: "Maximum Subtotal",
    },
    note: {
      supported_countries:
        "Put in country code such as SG,PH,ID. Leave it blank to support all countries",
      minimum_subtotal:
        "Minimum amount needed to use this payment method. Put 0 for no restriction.",
      maximum_subtotal:
        "Maximum amount can be processed with this payment method. Put 0 for no restriction.",
    },
  },
}
