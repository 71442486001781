import React from "react";
import styled from "styled-components";
import { Flex, Text } from "rebass";
import { Currency } from "../../../../components";

const Price = styled(Text)`
  font-size: ${(props) => (props.striked ? "1em" : "1em")};
  display: ${(props) => (props.hide ? "none" : "block")};
  color: ${(props) => (props.striked ? "#FF0000" : "#616161")};
`;
////   text-decoration: ${(props) => (props.striked ? "line-through" : "")};
const DiscountPrices = ({ unit, myPrice, discountPrice, qty }) => {
  return (
    <Flex
      width={[1]}
      flexDirection='row'
      justifyContent='flex-end'
      alignItems={"flex-start"}
    >
      <Flex
        flexDirection={"row"}
        alignItems={"center"}
        sx={{
          "&>div": {
            pr: [2],
          },
        }}
      >
        <Price striked={myPrice > discountPrice} pr='0.8em'>
          <Currency
            value={discountPrice}
            unit={unit}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </Price>
        <Text py={1}>
          x <span>{qty}</span> =
        </Text>
        <Price striked={myPrice > discountPrice} pr='0.8em'>
          <Currency
            value={discountPrice * qty}
            unit={unit}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </Price>
        <Flex width={"15px"} />
      </Flex>
    </Flex>
  );
};

export default DiscountPrices;
