import React, { Fragment } from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import {
  Counter,
  BlockCounter,
  Title,
  SelectInput,
  SwitchInput,
} from "../components";
import { SketchColorPicker } from "../../../components";
import { ContentDetail } from "../actions";

/**
 * desktop: {
    backgroundColor: "rgba(255,255,255,0)",
    color: "rgba(0,0,0,1)",
    lineHeight: "120%",
    letterSpacing: "1px",
    fontSize: 10,
    containerPadding:'0px'
  },
 */

const dataTextAlign = [
  { value: "left", text: "Left" },
  { value: "center", text: "Center" },
  { value: "right", text: "Right" },
  { value: "justify", text: "Justify" },
];

export default (props) => {
  const { title, state, onSetValue, contentId } = props;
  const myLineHeight = get(state, "lineHeight", "120%");
  const lineHeight = myLineHeight.split("%").join("") * 1;
  const myLetterSpacing = get(state, "letterSpacing", "1px");
  const letterSpacing = myLetterSpacing.split("px").join("") * 1;
  const color = get(state, "color", "#000000");
  const backgroundColor = get(state, "backgroundColor", "transparent");
  const colorHover = get(state, "hoverColor", "transparent");
  const hoverBackgroundColor = get(
    state,
    "hoverBackgroundColor",
    "transparent"
  );
  const textAlign = get(state, "textAlign", "left");
  return (
    <Fragment>
      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        sx={{
          "&>div": {
            width: ["320px"],
            p: [2],
            mr: [3],
          },
        }}
      >
        <ContentDetail>
          <Title title={title} />
          <SketchColorPicker
            title={"Background"}
            onUpdateColor={(color) => onSetValue("backgroundColor", color)}
            color={backgroundColor}
          />
          <SketchColorPicker
            title={"Color"}
            onUpdateColor={(color) => onSetValue("color", color)}
            color={color}
          />
          <SketchColorPicker
            title={"Hover Color"}
            onUpdateColor={(color) => onSetValue("hoverColor", color)}
            color={colorHover}
          />
          <SketchColorPicker
            title={"Hover Background"}
            onUpdateColor={(color) => onSetValue("hoverBackgroundColor", color)}
            color={hoverBackgroundColor}
          />
        </ContentDetail>
        <ContentDetail>
          <Flex
            flexDirection={"row"}
            width={[1]}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Title title='line Height' />
            <Counter
              onSetValue={(value) => onSetValue("lineHeight", `${value}%`)}
              value={lineHeight}
              interval={10}
              subtitle='%'
              minValue={100}
            />
          </Flex>
          <Flex
            flexDirection={"row"}
            width={[1]}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Title title='Letter Spacing' />
            <Counter
              onSetValue={(value) => onSetValue("letterSpacing", `${value}px`)}
              value={letterSpacing}
              interval={1}
              subtitle='px'
              minValue={-5}
            />
          </Flex>
          <Title title={`Font size ${title}`} />
          <Counter
            onSetValue={(value) => onSetValue("fontSize", value)}
            value={state.fontSize}
            interval={1}
            subtitle='pt'
            minValue={5}
          />
          <SelectInput
            id={`text-align-${contentId}`}
            title={"Text Align"}
            value={textAlign}
            list={dataTextAlign}
            onChange={(e) => onSetValue("textAlign", e.target.value)}
          />
          <SwitchInput
            id={`isFullHeight-${contentId}`}
            title='Is Full Height (Flex)'
            checked={state.isFullHeight}
            onChange={(e) => onSetValue("isFullHeight", e.target.checked)}
          />
        </ContentDetail>
        <BlockCounter
          title='Container Padding'
          containerPadding={state.containerPadding}
          onUpdatePadding={(cp) => onSetValue("containerPadding", cp)}
        />
      </Flex>
    </Fragment>
  );
};
