import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"

import PeopleIcon from "@material-ui/icons/People"
import { lightBlue } from "@material-ui/core/colors"

const styles = {
  orangeAvatar: {
    margin: 8,
    width: 32,
    height: 32,
    color: "#fff",
    backgroundColor: lightBlue[500],
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#FFFFFF",
    cursor: "pointer",
  },
}

function AvatarUser(props) {
  const { classes, src, onClick } = props
  if (src) {
    return (
      <Avatar className={classes.orangeAvatar} src={src} onClick={onClick} />
    )
  }
  return (
    <Avatar className={classes.orangeAvatar} size={"small"}>
      <PeopleIcon />
    </Avatar>
  )
}

AvatarUser.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AvatarUser)
