export default {
  buttons: {
    chart: "Chart",
    create_websites: "Create New Website",
    add_new_schedule: "Add New Schedule",
    add_schedule: "Add Schedule",
    edit_schedule: "Edit Schedule",
    add_option: "Add option",
    add_variant: "Add Variant",
    add_product: "Add Product",
    add_comment: "Add Comment",
    add: "Add",
    edit: "Edit",
    create: "Create",
    cancel: "Cancel",
    save: "Save",
    generate: "Generate",
    view: "View",
    back: "Back",
    preview: "Preview",
    download_cvs: "Download CSV",
    reset_page: "Reset Page",
    no: "No",
    yes: "Yes",
    remove: "Remove",
    create_attribute_sets: "Create New Attribute Sets",
    create_topic: "Create Topic",
    create_q_and_a: "Create Q&A",
    done: "Done",
    export: "Export",
    cancel_order: "Cancel Order",
    print_packing_slip: "Print Packing Slip",
    print_invoice: "Print Invoice",
    print_shipping_label: "Print Shipping Label",
    create_shipment: "Create Shipment",
    print_creadit_memo: "Print Credit Memo",
    print_return_slip: "Print return Slip",
    create_refund: "Create Refund",
    show_order: "Show Order",
    get_cost: "Get Cost",
    request_shipment: "Request Shipment",
    list: "List",
    cancel_shipment: "Cancel Shipment",
    create_amendment: "Create Amendment",
    add_packaging: "Add Packaging",
    add_provider: "Add Provider",
    create_memo: "Create Credit Memo",
    submit_for_refund: "Submit For Refund",
    closed: "Closed",
    void: "Void",
    upload_csv: "Upload Inventory CSV",
    import_product_csv: "Import Product CSV",
    export_to_csv: "Export to CSV",
    send_email: "Send Email",
    send: "Send",
    title_shipment_send_email: "Send Shipment Email",
    export_order: "Export Order",
    set_to_delivered: "Set to Delivered",
    set_to_paid: "Set to Paid",
  },
};
