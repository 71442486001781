import React from "react"
import { required } from "react-admin"
import { Box } from "rebass"
import {
  BetterCustomSimpleForm,
  RaBooleanInput,
  RaTextInput,
  RaTextArea,
  EditToolbar,
} from "../../../components"
import Settings from "./Settings"
import Events from "./Events"

const FormFields = (props) => {
  return (
    <BetterCustomSimpleForm
      {...props}
      title={"resources.webhooks.create_title"}
      toolbar={<EditToolbar />}
      isShowBack={false}
    >
      <Box width={[1]}>
        <RaTextInput
          source="name"
          label={"resources.webhooks.fields.name"}
          validate={required()}
          style={{ width: 320 }}
        />
        <Box width={[1]} pt={[2]}>
          <RaBooleanInput
            source="active"
            label={"resources.webhooks.fields.active"}
          />
        </Box>
        <RaTextArea
          source="description"
          label={"resources.webhooks.fields.description"}
          rows="6"
          fullWidth
          multiline
          InputLabelProps={{
            shrink: true,
            disableAnimation: true,
          }}
          style={{ transform: "none" }}
        />
        <Box width={[1]} pt={[2]}>
          <Settings {...props} />
        </Box>
        <Box width={[1]} pt={[2]}>
          <Events {...props} />
        </Box>
      </Box>
    </BetterCustomSimpleForm>
  )
}
export default FormFields
