import React, { Component } from "react";
import { DivContainer } from "./styled";
import { Body } from "./body";
import { RowCarousel } from "./rows";
import { ContentCarousel } from "./carousel";
import { dataPropreties } from "./dataPropreties";

class PropsPlugin extends Component {
  constructor(props) {
    super(props);
    let item = props.layoutState.getSelectedItem();
    // console.log(item)
    this.state = {
      item: item.props,
      type: item.type,
      itemId: item.id,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.layoutState.selectedItem &&
      nextProps.layoutState.selectedItem !== this.props.layoutState.selectedItem
    ) {
      let item = nextProps.layoutState.getSelectedItem();
      this.setState({ item: item.props, type: item.type, itemId: item.id });
    }
  }

  onUpdateData = (data) => {
    try {
      this.props.layoutState.updateItem(this.props.layoutState.selectedItem)(
        ["props"],
        () => data
      );
      // this.props.onClose()
    } catch (err) {
      console.log(err.message);
    }
  };
  onUpdateDataCarousel = (data) => {
    let newData = data;
    // console.log(data);
    let dataContent = [];
    for (let index = 0; index < data.content.length; index++) {
      const element = data.content[index];
      const newItem = {
        childOf: element.childOf,
        children: element.children,
        props: element.props,
        parent: element.parent,
        id: element.id,
        type: element.type,
      };
      dataContent.push(newItem);
    }
    newData["content"] = dataContent;
    // console.log(newData);
    try {
      this.props.layoutState.updateItem(this.props.layoutState.selectedItem)(
        ["props"],
        () => newData
      );
      this.props.onClose();
    } catch (err) {
      console.log(err.message);
    }
  };
  render() {
    if (this.state.type === "Body") {
      return (
        <DivContainer>
          <Body
            data={this.state.item}
            {...this.props}
            onUpdateData={(data) => this.onUpdateData(data)}
          />
        </DivContainer>
      );
    }
    if (this.state.type === "Row7") {
      return (
        <DivContainer>
          <RowCarousel
            data={this.state.item}
            {...this.props}
            onUpdateData={(data) => this.onUpdateDataCarousel(data)}
          />
        </DivContainer>
      );
    } else if (this.state.type === "ContentCarousel") {
      return (
        <DivContainer>
          <ContentCarousel
            data={this.state.item}
            {...this.props}
            onUpdateData={(data) => this.onUpdateData(data)}
          />
        </DivContainer>
      );
    } else {
      let elementComponent = <div>Please Close and select again</div>;
      const item = dataPropreties(
        this.state.item,
        this.state.itemId,
        this.onUpdateData,
        this.props
      );
      if (item[this.state.item.values.type]) {
        elementComponent = item[this.state.item.values.type];
      }

      return <DivContainer>{elementComponent}</DivContainer>;
    }
  }
}

PropsPlugin.Title = "Props";

export default {
  Title: "Props",
  Interface: PropsPlugin,
};
