import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout20 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const AccordionListView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='AccordionListView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

AccordionListView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};
AccordionListView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "AccordionListView",
    containerPadding: "0px",
    align: "flex-start",
    typeContent: "custom",
    header: {
      title: "",
      containerPadding: "0px",
      thumbnail: "",
      backgroundColor: "transparent",
      isShowDesktop: false,
      isShowMobile: true,
    },
    list: [
      {
        title: "",
        link: {
          target: "",
          type: "URL",
          value: "",
          isLink: false,
        },
      },
    ],
    collections: [],
  },
  layout: Layout20,
};
export default AccordionListView;
