import React, { Fragment } from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import {
  Alignment,
  BlockCounter,
  Counter,
  SwitchInput,
  Title,
  SelectInput,
} from "../components";
import { ContentDetail } from "../actions";
import { SketchColorPicker } from "../../../components";

export default (props) => {
  const { title, state, onSetValue, typeButton } = props;
  const onUpdateColor = (k, v) => {
    const newButtonColors = {
      ...state.buttonColors,
      [k]: v,
    };
    onSetValue("buttonColors", newButtonColors);
  };
  return (
    <Fragment>
      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        sx={{
          "&>div": {
            width: ["320px"],
            p: [2],
            mr: [3],
          },
        }}
      >
        <ContentDetail>
          <SketchColorPicker
            title={"Button Color"}
            onUpdateColor={(color) => onUpdateColor("color", color)}
            color={get(state, "buttonColors.color", "#000000")}
          />
          <SketchColorPicker
            title={"Button Background"}
            onUpdateColor={(color) => onUpdateColor("backgroundColor", color)}
            color={get(state, "buttonColors.backgroundColor", "#FFFFFF")}
          />
        </ContentDetail>
        {typeButton !== "raised" && (
          <ContentDetail>
            <SketchColorPicker
              title={"Button Hover"}
              onUpdateColor={(color) => onUpdateColor("hoverColor", color)}
              color={get(state, "buttonColors.hoverColor", "#FFFFFF")}
            />
            <SketchColorPicker
              title={"Button Ripple"}
              onUpdateColor={(color) => onUpdateColor("rippleColor", color)}
              color={get(state, "buttonColors.rippleColor", "#FFFFFF")}
            />
          </ContentDetail>
        )}
        <ContentDetail>
          <Alignment
            value={state.buttonAlign}
            onChange={(value) => onSetValue("buttonAlign", value)}
            title='Button Align'
          />
          <Title title={`Font size ${title}`} />
          <Counter
            onSetValue={(value) => onSetValue("fontSize", value)}
            value={state.fontSize}
            interval={1}
            subtitle='pt'
            minValue={5}
          />
          <SelectInput
            id={`type-flat-button`}
            title={"Type Button"}
            value={get(state, "fontWeight", "normal")}
            list={[
              { id: "normal", text: "Normal" },
              { id: "bold", text: "Bold" },
              { id: "100", text: "100" },
              { id: "200", text: "200" },
              { id: "300", text: "300" },
            ]}
            onChange={(e) => onSetValue("fontWeight", e.target.value)}
          />

          <SwitchInput
            id={`full-width-${title}`}
            title={"Full Width"}
            checked={state.fullWidth}
            onChange={(e) => onSetValue("fullWidth", e.target.checked)}
          />
        </ContentDetail>
        <BlockCounter
          containerPadding={state.containerPadding}
          onUpdatePadding={(containerPadding) =>
            onSetValue("containerPadding", containerPadding)
          }
          title={"Container Padding"}
        />
        <BlockCounter
          containerPadding={state.buttonPadding}
          onUpdatePadding={(bp) => onSetValue("buttonPadding", bp)}
          title={"Button Padding"}
        />
      </Flex>
    </Fragment>
  );
};
//ButtonContentFields
