import styled from "styled-components";
import { lightBlue } from "@material-ui/core/colors";

export const DivContainer = styled.div`
  display: flex;
  width: 320px;
  position: fixed;
  flex: 1;
  flex-direction: row;
  background-color: #ffffff;
  justify-content: center;
  padding-bottom: 50px;
  padding-top: 15px;
  z-index: 988997;
  top: 140px;
`;

export const DivHeaderActions = styled.div`
  display: flex;
  flex: 1 auto;
  flex-direction: row;
  justify-content: flex-end;
  background-color: ${lightBlue[700]};
  color: #ffffff;
`;
export const DivFooterActions = styled.div`
  display: flex;
  flex: 1;
  bottom: 0px;
  flex-direction: row;
  justify-content: flex-end;
  background-color: ${lightBlue[700]};
  position: fixed;
  color: #ffffff;
  z-index: 1000;
`;
export const DivTitle = styled.div`
  font-size: 1em;
  padding-bottom: 10px;
  font-weight: bold;
`;

export const DivContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const BoxContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow-y: auto;
  flex-direction: column;
  padding-bottom: 80px;
`;
export const DivBody = styled.div`
  width: 100%;
  border: 1px solid ${lightBlue[900]};
`;
