import React, { Fragment, useState, useEffect } from "react";
import { Flex, Text, Box } from "rebass";
import { IconButton } from "@material-ui/core";
import ActionEdit from "@material-ui/icons/Edit";
import { DialogCardView, Title, RTInput } from "../components";
import { ContentDetail } from "../actions";
import { defaultText, toolbar } from "./utils";
import TextContentFields from "./TextContentFields";
export default (props) => {
  const { item, onSetValue } = props;
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(item);

  useEffect(() => {
    setState(item);
  }, [item]);
  const handleSave = () => {
    setOpen(false);
    onSetValue(state);
  };

  const onSetValueDesktop = (k, v) => {
    const newDesktop = {
      ...item.desktop,
      [k]: v,
    };
    onSetValue("desktop", newDesktop);
  };

  const onSetValueMobile = (k, v) => {
    const newMobile = {
      ...item.mobile,
      [k]: v,
    };
    onSetValue("mobile", newMobile);
  };
  return (
    <Fragment>
      <Flex width={[1]} flexDirection={"column"} p={[1]}>
        <ContentDetail>
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={[1]}
          >
            <Text>Text Setting</Text>
            <IconButton
              onClick={() => setOpen(true)}
              style={{ color: "rgb(0, 188, 212)", marginRight: 8 }}
            >
              <ActionEdit color={"inherit"} />
            </IconButton>
          </Flex>
        </ContentDetail>
      </Flex>
      {open && (
        <DialogCardView
          title={`Text Setting`}
          onSave={() => handleSave()}
          open={open}
          setOpen={(v) => setOpen(v)}
          id={`item-text-view`}
          hiddenAction={false}
        >
          <Box width={[1]}>
            <ContentDetail>
              <RTInput
                input={
                  <input
                    type='text'
                    name='name'
                    value={item.text}
                    onChange={(value) => onSetValue("text", value)}
                  />
                }
                source='body'
                label='Body'
                toolbar={toolbar}
              />
            </ContentDetail>
            <Box width={[1]} py={[2]}>
              <Title title={"Desktop Settings"} />
              <TextContentFields
                onSetValue={(k, v) => onSetValueDesktop(k, v)}
                title={"Desktop"}
                state={state.desktop || defaultText.desktop}
              />
            </Box>
            <Box width={[1]} py={[2]}>
              <Title title={"Mobile Settings"} />
              <TextContentFields
                onSetValue={(k, v) => onSetValueMobile(k, v)}
                title={"Mobile"}
                state={state.mobile || defaultText.mobile}
              />
            </Box>
          </Box>
        </DialogCardView>
      )}
    </Fragment>
  );
};
