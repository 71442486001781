import React from "react";
import PropTypes from "prop-types";
import Layout from "../Layout";

const SocialMediaView = (props) => {
  return <Layout {...props} type='SocialMediaView' />;
};

SocialMediaView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};

export default SocialMediaView;
