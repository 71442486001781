import React, { Fragment, useState } from "react";
import { Button } from "@material-ui/core";
import { RestApi } from "../../restClient";
import { useQuery } from "react-query";
import { get, uniq, map } from "lodash";
import moment from "moment";
import { Flex } from "rebass";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogTitle } from "./components/DialogFields";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useNotify } from "react-admin";
function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    minWidth: 256,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

//DialogContent,
const cacheTimeMinute = 1000 * 60 * 5;
const DialogShipmentSendEmail = (props) => {
  const { translate, record } = props;
  const notify = useNotify();
  const classes = useStyles();
  const orderId = get(record, "id", 0);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectDate, setSelectDate] = useState("");
  const { data, isLoading } = useQuery(
    ["orders_shipment", orderId],
    () => RestApi(`/orders/${orderId}/shipments`).then((res) => res.json()),
    {
      cacheTime: cacheTimeMinute,
    }
  );
  const handleChange = (event) => {
    setSelectDate(event.target.value);
  };
  const onSendEmail = () => {
    if (selectDate !== "") {
      setLoading(true);
      RestApi(`/orders/${orderId}/shipments/notify?date=${selectDate}`)
        .then((res) => res.json())
        .then((response) => {
          //   console.log(response);
          setLoading(false);
          if (response && response.message) {
            setMessage(response.message);
            setOpenMessage(true);
          } else {
            setOpen(false);
            setSelectDate("");
            notify("resources.orders.messages.sending_email");
          }
        })
        .catch((error) => {
          setLoading(false);
          setMessage(error.message);
          setOpenMessage(true);
        });
    } else {
      setMessage("Please Select Date");
      setOpenMessage(true);
    }
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (isLoading) {
    return <Fragment />;
  }

  const dataDate = map(data, function (o) {
    return moment(o.created_at).format("YYYY-MM-DD");
  });

  const list = uniq(dataDate);
  return (
    <Fragment>
      <Button color={"primary"} onClick={() => setOpen(true)}>
        {translate("resources.buttons.send_email")}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-shipment-send-email-title'
        open={open}
        scroll={"paper"}
      >
        <DialogTitle
          id='customized-dialog-shipment-send-email-title'
          onClose={handleClose}
        >
          {translate("resources.buttons.title_shipment_send_email")}
        </DialogTitle>
        <DialogContent dividers>
          <Flex
            width={["400px"]}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <FormControl className={classes.formControl}>
              <InputLabel id='date-shipment-select-label'>
                {translate("resources.orders.labels.date")}
              </InputLabel>
              <Select
                labelId='date-shipment-select-label'
                id='date-shipment-select'
                value={selectDate}
                onChange={handleChange}
              >
                {list &&
                  list.length > 0 &&
                  list.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {moment(item).format("DD-MMM-YYYY")}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button
              color={"primary"}
              onClick={() => onSendEmail()}
              variant='contained'
              disabled={loading}
              style={{ color: "#FFFFFF" }}
            >
              {translate("resources.buttons.send")}
            </Button>
            {loading && (
              <Flex p={[2]}>
                <CircularProgress color={"primary"} />
              </Flex>
            )}
          </Flex>
          <Snackbar
            open={openMessage}
            autoHideDuration={6000}
            onClose={handleCloseMessage}
          >
            <Alert onClose={handleCloseMessage} severity='error'>
              {message}
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default DialogShipmentSendEmail;
