import React from "react"
import { SvgIcon } from "@material-ui/core"

export const ListIcon = (props) => {
  return (
    <SvgIcon {...props} width="33" height="31" viewBox="0 0 33 31">
      <path
        d="M262.5,1340h30a1.5,1.5,0,0,0,0-3h-30A1.5,1.5,0,0,0,262.5,1340Zm0,7h19a1.5,1.5,0,0,0,0-3h-19A1.5,1.5,0,0,0,262.5,1347Zm0,7h28a1.5,1.5,0,0,0,0-3h-28A1.5,1.5,0,0,0,262.5,1354Zm0,7h25a1.5,1.5,0,0,0,0-3h-25A1.5,1.5,0,0,0,262.5,1361Zm30,4h-30a1.5,1.5,0,0,0,0,3h30A1.5,1.5,0,0,0,292.5,1365Z"
        transform="translate(-261 -1337)"
      ></path>
    </SvgIcon>
  )
}
export const TrashIcon = (props) => {
  return (
    <SvgIcon {...props} width="39" height="43.94" viewBox="0 0 39 43.94">
      <path
        d="M910,1426h-8a9.946,9.946,0,0,0-19,0h-8c-1.25.01-2,.24-2,2a1.1,1.1,0,0,0,1,1h2v31c0,1.68.843,2.94,2.5,2.94h27c1.657,0,3.5-1.26,3.5-2.94v-31s0.337-.01,1,0c1.083,0,1.975-.02,2-1.95C912.009,1426.35,910.828,1426,910,1426Zm-17.5-4.01A7.139,7.139,0,0,1,899,1426H886A7.139,7.139,0,0,1,892.5,1421.99ZM906,1460h-4v-17a2.173,2.173,0,0,0-1.739-2.01c-0.864,0-1.261,1.16-1.261,2.01v17h-5v-23.48a1.5,1.5,0,1,0-3,0V1460h-5v-17c0-.85-0.419-2.01-1.28-2.01A2.158,2.158,0,0,0,883,1443v17h-4v-31h27v31Z"
        transform="translate(-873 -1419)"
      ></path>
    </SvgIcon>
  )
}

export const ShowIcon = (props) => {
  return (
    <SvgIcon {...props} width="43.719" height="32" viewBox="0 0 43.719 32">
      <path
        d="M826.389,1439.43c-0.36-.59-8.964-14.43-21.416-14.43s-21.057,13.84-21.417,14.43a3.022,3.022,0,0,0,0,3.15c0.36,0.58,8.962,14.38,21.4,14.42h0.056c12.43,0,21.013-13.84,21.372-14.43A3.013,3.013,0,0,0,826.389,1439.43ZM805.019,1454h-0.046c-10.929-.04-18.878-13-18.878-13s7.949-13,18.878-13,18.878,13,18.878,13S815.924,1454,805.019,1454Zm-0.046-21a8,8,0,1,0,7.948,8A7.984,7.984,0,0,0,804.973,1433Zm0,13a5,5,0,1,1,4.968-5A4.985,4.985,0,0,1,804.973,1446Z"
        transform="translate(-783.125 -1425)"
      ></path>
    </SvgIcon>
  )
}

export const EditIcon = (props) => {
  return (
    <SvgIcon {...props} width="34.75" height="36" viewBox="0 0 34.75 36">
      <path
        d="M647.8,1692.88l-4.956-5a2.953,2.953,0,0,0-4.206,0l-23.791,24c-0.9.91-.871,3.12-0.871,3.12v8l7.931-1a5.432,5.432,0,0,0,2.973-1l22.92-23.88A3.016,3.016,0,0,0,647.8,1692.88ZM621.908,1719l-4.957-5,23.791-24,4.956,5Z"
        transform="translate(-613.938 -1687)"
      ></path>
    </SvgIcon>
  )
}
export const DeleteIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="30.718"
      height="30.84"
      viewBox="0 0 30.718 30.84"
    >
      <path
        d="M894.788,1176.57l12.583-12.89a1.5,1.5,0,0,0-.022-2.11,1.478,1.478,0,0,0-2.1.02l-12.561,12.87-13.081-12.89a1.479,1.479,0,0,0-2.1.02,1.5,1.5,0,0,0,.02,2.11l13.062,12.87-13.062,12.87a1.5,1.5,0,0,0-.02,2.11,1.477,1.477,0,0,0,2.1.02l13.081-12.89,12.561,12.87a1.477,1.477,0,0,0,1.062.45,1.459,1.459,0,0,0,1.04-.43,1.5,1.5,0,0,0,.022-2.11Z"
        transform="translate(-877.094 -1161.16)"
      ></path>
    </SvgIcon>
  )
}
export const CloseIcon = (props) => {
  return (
    <SvgIcon {...props} width="39" height="43.94" viewBox="0 0 39 43.94">
      <path
        d="M910,1426h-8a9.946,9.946,0,0,0-19,0h-8c-1.25.01-2,.24-2,2a1.1,1.1,0,0,0,1,1h2v31c0,1.68.843,2.94,2.5,2.94h27c1.657,0,3.5-1.26,3.5-2.94v-31s0.337-.01,1,0c1.083,0,1.975-.02,2-1.95C912.009,1426.35,910.828,1426,910,1426Zm-17.5-4.01A7.139,7.139,0,0,1,899,1426H886A7.139,7.139,0,0,1,892.5,1421.99ZM906,1460h-4v-17a2.173,2.173,0,0,0-1.739-2.01c-0.864,0-1.261,1.16-1.261,2.01v17h-5v-23.48a1.5,1.5,0,1,0-3,0V1460h-5v-17c0-.85-0.419-2.01-1.28-2.01A2.158,2.158,0,0,0,883,1443v17h-4v-31h27v31Z"
        transform="translate(-873 -1419)"
      ></path>
    </SvgIcon>
  )
}
export const AddIcon = (props) => {
  return (
    <SvgIcon {...props} width="39" height="39" viewBox="0 0 39 39">
      <path
        d="M453.5,1158a1.5,1.5,0,0,1,1.5,1.5v36a1.5,1.5,0,0,1-3,0v-36A1.5,1.5,0,0,1,453.5,1158Zm-18,18h36a1.5,1.5,0,0,1,0,3h-36A1.5,1.5,0,0,1,435.5,1176Z"
        transform="translate(-434 -1158)"
      ></path>
    </SvgIcon>
  )
}
export const CheckIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="28.719"
      height="18.85"
      viewBox="0 0 28.719 18.85"
    >
      <path
        d="M467.393,1606.55a1.468,1.468,0,0,0-2.1,0l-14.8,14.83-8.856-8.88a1.489,1.489,0,1,0-2.1,2.11l9.906,9.93a1.474,1.474,0,0,0,2.1,0l15.85-15.89A1.483,1.483,0,0,0,467.393,1606.55Z"
        transform="translate(-439.125 -1606.12)"
      ></path>
    </SvgIcon>
  )
}

export const BadgeIcon = (props) => {
  return (
    <SvgIcon {...props} width="45px" height="45px" viewBox="0 0 45 45">
      <path d="M44.29,0.84 C43.7043649,0.255093244 42.7556351,0.255093244 42.17,0.84 L39.5,3.51 C39.2491943,3.44007441 38.9903526,3.40309702 38.73,3.4 L23.56,3.4 C22.7646186,3.40070021 22.0020694,3.71723005 21.44,4.28 L1.52,24.19 C0.350186488,25.3612702 0.350186488,27.2587298 1.52,28.43 L16.69,43.6 C17.8612702,44.7698135 19.7587298,44.7698135 20.93,43.6 L40.84,23.69 C41.3991042,23.1262971 41.7119547,22.3639488 41.71,21.57 L41.71,6.4 C41.706903,6.13964738 41.6699256,5.88080568 41.6,5.63 L44.27,2.96 C44.8604683,2.37989847 44.8694188,1.4311382 44.29,0.84 L44.29,0.84 Z M38.72,21.57 L18.81,41.48 L3.64,26.31 L23.56,6.4 L36.61,6.4 L32.03,10.98 C31.3248061,10.6043129 30.5390064,10.4052894 29.74,10.4 C26.9785763,10.4 24.74,12.6385763 24.74,15.4 C24.74,18.1614237 26.9785763,20.4 29.74,20.4 C32.5014237,20.4 34.74,18.1614237 34.74,15.4 C34.7316324,14.6001576 34.529165,13.8143094 34.15,13.11 L38.73,8.53 L38.72,21.57 Z M31.72,15.4 C31.72,16.5045695 30.8245695,17.4 29.72,17.4 C28.6154305,17.4 27.72,16.5045695 27.72,15.4 C27.72,14.2954305 28.6154305,13.4 29.72,13.4 C30.2521635,13.3973391 30.7634441,13.6068776 31.1406825,13.9822391 C31.5179208,14.3576007 31.7300067,14.8678299 31.73,15.4 L31.72,15.4 Z"></path>
    </SvgIcon>
  )
}

export const BagIcon = (props) => {
  return (
    <SvgIcon {...props} width="38" height="45.12" viewBox="0 0 38 45.12">
      <path
        d="M382,2310h-6c-0.754-4.66-5.289-9.13-10-9.13s-9.246,4.47-10,9.13h-6a2.8,2.8,0,0,0-3,2.77v30.2a3.017,3.017,0,0,0,3,3.03h32a3.017,3.017,0,0,0,3-3.03v-30.2A2.8,2.8,0,0,0,382,2310Zm-16-6.1c3.013,0,6.25,3.12,7,6.1H359C359.75,2307.02,362.987,2303.9,366,2303.9Zm16,39.07H350V2313h6v2.52a1.5,1.5,0,1,0,3,0V2313h14v2.52a1.5,1.5,0,1,0,3,0V2313h6v29.97Z"
        transform="translate(-347 -2300.88)"
      ></path>
    </SvgIcon>
  )
}
export const CaseIcon = (props) => {
  return (
    <SvgIcon {...props} width="42" height="37" viewBox="0 0 42 37">
      <path
        d="M912,1868h-9v-3a3,3,0,0,0-3-3H888a3,3,0,0,0-3,3v3h-9a3,3,0,0,0-3,3v25a3,3,0,0,0,3,3h36a3,3,0,0,0,3-3v-25A3,3,0,0,0,912,1868Zm-24-3h12v3H888v-3Zm24,6v7.51a1.459,1.459,0,0,0-.671.15,62.181,62.181,0,0,1-12.538,4.26A3,3,0,0,0,896,1881h-4a3,3,0,0,0-2.821,2.02,55.656,55.656,0,0,1-12.633-4.36,1.385,1.385,0,0,0-.546-0.15V1871h36Zm-16,13v3h-4v-3h4Zm-20,12v-14.21a59.332,59.332,0,0,0,13,4.28V1887a3,3,0,0,0,3,3h4a3,3,0,0,0,3-3v-1.08a52.585,52.585,0,0,0,13-4.37V1896H876Z"
        transform="translate(-873 -1862)"
      ></path>
    </SvgIcon>
  )
}
export const Chart8 = (props) => {
  return (
    <SvgIcon {...props} width="43" height="38" viewBox="0 0 43 38">
      <path
        d="M824.5,2693h-40a1.5,1.5,0,0,1,0-3h40A1.5,1.5,0,0,1,824.5,2693ZM794,2674v11h-4v-11h4m0-3h-4a3,3,0,0,0-3,3v11a3,3,0,0,0,3,3h4a3,3,0,0,0,3-3v-11a3,3,0,0,0-3-3h0Zm12-4v18h-4v-18h4m0-3h-4a3,3,0,0,0-3,3v18a3,3,0,0,0,3,3h4a3,3,0,0,0,3-3v-18a3,3,0,0,0-3-3h0Zm12-6v27h-4v-27h4m0-3h-4a3,3,0,0,0-3,3v27a3,3,0,0,0,3,3h4a3,3,0,0,0,3-3v-27a3,3,0,0,0-3-3h0Z"
        transform="translate(-783 -2655)"
      ></path>
    </SvgIcon>
  )
}
export const DocumentIcon = (props) => {
  return (
    <SvgIcon {...props} width="38" height="42" viewBox="0 0 38 42">
      <path
        d="M644,1332H621a2.99,2.99,0,0,0-2.539,1.42l-8.042,8.04A3,3,0,0,0,609,1344v27a3,3,0,0,0,3,3h32a3,3,0,0,0,3-3v-36A3,3,0,0,0,644,1332Zm-26,6.12V1341h-2.879ZM644,1371H612v-26.9c0.019-.02.043-0.02,0.06-0.04l0.061-.06H621v-8.88l0.061-.06c0.017-.02.023-0.04,0.039-0.06H644v36Zm-6-16H618a1.5,1.5,0,0,0,0,3h20A1.5,1.5,0,0,0,638,1355Zm-3,7H618a1.5,1.5,0,0,0,0,3h17A1.5,1.5,0,0,0,635,1362Zm-4-11h6a3,3,0,0,0,3-3v-6a3,3,0,0,0-3-3h-6a3,3,0,0,0-3,3v6A3,3,0,0,0,631,1351Zm0-9h6v6h-6v-6Z"
        transform="translate(-609 -1332)"
      ></path>
    </SvgIcon>
  )
}
export const FilterIcon = (props) => {
  return (
    <SvgIcon {...props} width="42" height="39" viewBox="0 0 42 39">
      <path
        d="M696.5,1340h2.713a5.494,5.494,0,0,0,10.574,0H735.5a1.5,1.5,0,0,0,0-3H709.787a5.494,5.494,0,0,0-10.574,0H696.5A1.5,1.5,0,0,0,696.5,1340Zm8-4a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,704.5,1336Zm31,15H721.118a5.492,5.492,0,0,0-10.236,0H696.5a1.5,1.5,0,0,0,0,3h14.1a5.495,5.495,0,0,0,10.808,0h14.1A1.5,1.5,0,0,0,735.5,1351Zm-19.5,4.5a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,716,1355.5Zm19.5,9.5h-2.713a5.494,5.494,0,0,0-10.574,0H696.5a1.5,1.5,0,0,0,0,3h25.713a5.494,5.494,0,0,0,10.574,0H735.5A1.5,1.5,0,0,0,735.5,1365Zm-8,4a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,727.5,1369Z"
        transform="translate(-695 -1333)"
      ></path>
    </SvgIcon>
  )
}

export const SettingsIcon = (props) => {
  return (
    <SvgIcon {...props} width="44" height="44" viewBox="0 0 44 44">
      <path
        d="M453,1433a8,8,0,1,0,8,8A8.01,8.01,0,0,0,453,1433Zm0,13a5,5,0,1,1,5-5A5,5,0,0,1,453,1446Zm19-11h-0.975a18.787,18.787,0,0,0-1.034-2.51l0.687-.68a3.011,3.011,0,0,0,0-4.25l-4.243-4.24a3,3,0,0,0-4.243,0l-0.687.69a18.722,18.722,0,0,0-2.5-1.04V1422a3,3,0,0,0-3-3h-6a3,3,0,0,0-3,3v0.97a18.79,18.79,0,0,0-2.506,1.04l-0.686-.69a3,3,0,0,0-4.243,0l-4.243,4.24a3.011,3.011,0,0,0,0,4.25l0.687,0.68a18.787,18.787,0,0,0-1.034,2.51H434a3,3,0,0,0-3,3v6a3,3,0,0,0,3,3h0.975a18.787,18.787,0,0,0,1.034,2.51l-0.687.68a3.011,3.011,0,0,0,0,4.25l4.243,4.24a3,3,0,0,0,4.243,0l0.686-.69a18.79,18.79,0,0,0,2.506,1.04V1460a3,3,0,0,0,3,3h6a3,3,0,0,0,3-3v-0.97a18.722,18.722,0,0,0,2.5-1.04l0.687,0.69a3,3,0,0,0,4.243,0l4.243-4.24a3.011,3.011,0,0,0,0-4.25l-0.687-.68a18.787,18.787,0,0,0,1.034-2.51H472a3,3,0,0,0,3-3v-6A3,3,0,0,0,472,1435Zm0,9h-3.291a15.857,15.857,0,0,1-2.475,5.99l2.322,2.32-4.242,4.25-2.323-2.33a15.949,15.949,0,0,1-5.991,2.48V1460h-6v-3.29a15.949,15.949,0,0,1-5.991-2.48l-2.323,2.33-4.242-4.25,2.322-2.32a15.857,15.857,0,0,1-2.475-5.99H434v-6h3.291a15.857,15.857,0,0,1,2.475-5.99l-2.322-2.32,4.242-4.25,2.323,2.33a15.949,15.949,0,0,1,5.991-2.48V1422h6v3.29a15.949,15.949,0,0,1,5.991,2.48l2.323-2.33,4.242,4.25-2.322,2.32a15.857,15.857,0,0,1,2.475,5.99H472v6Z"
        transform="translate(-431 -1419)"
      ></path>
    </SvgIcon>
  )
}

export const StarIcon = (props) => {
  return (
    <SvgIcon {...props} width="42.532" height="42" viewBox="0 0 42.532 42">
      <path
        d="M629.937,1158l5.938,13H648.74l-8.907,9,1.966,14-11.862-8.17-11.89,8.17,2.983-14-8.906-9H624l5.938-13m0-3a2.97,2.97,0,0,0-2.7,1.74L622.1,1168h-9.976a2.969,2.969,0,0,0-2.743,1.85,3.023,3.023,0,0,0,.644,3.27l7.763,7.85-2.643,12.4a3.018,3.018,0,0,0,1.244,3.12,2.95,2.95,0,0,0,1.658.51,2.913,2.913,0,0,0,1.67-.52l10.218-7.02,10.191,7.02a2.923,2.923,0,0,0,1.673.52,2.962,2.962,0,0,0,1.569-.45,3.011,3.011,0,0,0,1.37-2.97l-1.757-12.52,7.858-7.94a3.026,3.026,0,0,0,.644-3.27,2.969,2.969,0,0,0-2.743-1.85H637.774l-5.141-11.26a2.97,2.97,0,0,0-2.7-1.74h0Z"
        transform="translate(-609.156 -1155)"
      ></path>
    </SvgIcon>
  )
}

export const UserIcon = (props) => {
  return (
    <SvgIcon {...props} width="41" height="38" viewBox="0 0 41 38">
      <path
        d="M190.5,1182c5.514,0,9.5-5.38,9.5-12s-3.986-12-9.5-12-9.5,5.38-9.5,12S184.986,1182,190.5,1182Zm0-21c3.866,0,6.5,4.03,6.5,9s-2.634,9-6.5,9-6.5-4.03-6.5-9S186.634,1161,190.5,1161Zm9.149,20.01a1.5,1.5,0,1,0-.3,2.98,9.753,9.753,0,0,1,8.576,9.01H173.074a9.826,9.826,0,0,1,8.579-9.01,1.5,1.5,0,0,0-.3-2.98c-0.114.01-11.351,1.27-11.351,13.49a1.5,1.5,0,0,0,1.5,1.5h38a1.5,1.5,0,0,0,1.5-1.5C211,1182.28,199.763,1181.02,199.649,1181.01Z"
        transform="translate(-170 -1158)"
      ></path>
    </SvgIcon>
  )
}

export const User1Icon = (props) => {
  return (
    <SvgIcon {...props} width="44" height="44" viewBox="0 0 44 44">
      <path
        d="M190,1243a22,22,0,1,0,22,22A22.025,22.025,0,0,0,190,1243Zm-19,22a19,19,0,1,1,33.934,11.72,19.225,19.225,0,0,0-9.488-7.39,9.513,9.513,0,0,0,2.554-6.58c0-4.96-3.589-9-8-9s-8,4.04-8,9a9.469,9.469,0,0,0,2.755,6.78,17.812,17.812,0,0,0-9.3,7.69A18.947,18.947,0,0,1,171,1265Zm19.5,3.5c-0.549,0-1.094.04-1.636,0.09-2.213-.61-3.864-2.99-3.864-5.84,0-3.31,2.239-6,5-6s5,2.69,5,6a5.935,5.935,0,0,1-3.677,5.79C191.049,1268.52,190.777,1268.5,190.5,1268.5Zm-12.816,10.95a14.628,14.628,0,0,1,11.032-7.82,7.77,7.77,0,0,0,1.284.12,6.978,6.978,0,0,0,1.655-.19c4.848,0.46,8.991,3.79,11.18,7.42A18.93,18.93,0,0,1,177.684,1279.45Z"
        transform="translate(-168 -1243)"
      ></path>
    </SvgIcon>
  )
}

export const ViewIcon = (props) => {
  return (
    <SvgIcon {...props} width="39" height="39" viewBox="0 0 39 39">
      <path
        d="M359,1334H348a3,3,0,0,0-3,3v11a3,3,0,0,0,3,3h11a3,3,0,0,0,3-3v-11A3,3,0,0,0,359,1334Zm0,14H348v-11h11v11Zm22-14H370a3,3,0,0,0-3,3v11a3,3,0,0,0,3,3h11a3,3,0,0,0,3-3v-11A3,3,0,0,0,381,1334Zm0,14H370v-11h11v11Zm-22,8H348a3,3,0,0,0-3,3v11a3,3,0,0,0,3,3h11a3,3,0,0,0,3-3v-11A3,3,0,0,0,359,1356Zm0,14H348v-11h11v11Zm22-14H370a3,3,0,0,0-3,3v11a3,3,0,0,0,3,3h11a3,3,0,0,0,3-3v-11A3,3,0,0,0,381,1356Zm0,14H370v-11h11v11Z"
        transform="translate(-345 -1334)"
      ></path>
    </SvgIcon>
  )
}

export const TurnOffIcon = (props) => {
  return (
    <SvgIcon {...props} width="37.969" height="40" viewBox="0 0 37.969 40">
      <path
        d="M810.994,1597.88v3.33a15.993,15.993,0,1,1-13.992,0v-3.33a19.312,19.312,0,0,0-11.764,20.92A19.015,19.015,0,1,0,810.994,1597.88Zm-7-3.39-1,2.4v16.47c0,1.05.465,1.9,1.5,1.9s1.5-.85,1.5-1.9v-16.47c0-1.05-.465-1.9-1.5-1.9S803,1595.84,803,1596.89Z"
        transform="translate(-785.031 -1595)"
      ></path>
    </SvgIcon>
  )
}

export const ThIcon = (props) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M9 2h-5c-1.103 0-2 .896-2 2v5c0 1.104.897 2 2 2h5c1.103 0 2-.896 2-2v-5c0-1.104-.897-2-2-2zm0 7h-5v-5h5v5zM20 2h-5c-1.104 0-2 .896-2 2v5c0 1.104.896 2 2 2h5c1.104 0 2-.896 2-2v-5c0-1.104-.896-2-2-2zm0 7h-5v-5h5v5zM9 13h-5c-1.103 0-2 .896-2 2v5c0 1.104.897 2 2 2h5c1.103 0 2-.896 2-2v-5c0-1.104-.897-2-2-2zm0 7h-5v-5h5v5zM20 13h-5c-1.104 0-2 .896-2 2v5c0 1.104.896 2 2 2h5c1.104 0 2-.896 2-2v-5c0-1.104-.896-2-2-2zm0 7h-5v-5h5v5z"></path>
    </SvgIcon>
  )
}
export const BackIcon = (props) => {
  return (
    <SvgIcon {...props} width="256" height="512" viewBox="0 0 256 512">
      <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
    </SvgIcon>
  )
}
