import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { connect } from "../../../core";
import { get } from "lodash";
// import Media from "./Media";
import { HrefContainer } from "../../../libs/hrefUrl";

// const renderImage = (
//   srcImage,
//   altText,
//   width,
//   isHaveLink,
//   href,
//   marginBottom,
//   marginTop,
//   height,
//   isShowing,
//   containerPadding,
//   align
// ) => {
//   const newHeight = height ? height : "100%";
//   // console.log(isShowing);

//   if (isShowing === false) {
//     return <div />;
//   }
//   if (isHaveLink) {
//     return (
//       <div
//         ref='imageView'
//         style={{
//           boxSizing: "border-box",
//           position: "relative",
//           height: "auto",
//           display: "flex",
//           width: "100%",
//           padding: containerPadding,
//           flex: 1,
//           justifyContent: align,
//         }}
//       >
//         <LinkHref url={href.url} target={href.target}>
//           <ShowImage src={srcImage} />
//           {/* <Image
//             src={srcImage}
//             alt={altText}
//             style={{
//               width: width,
//               height: newHeight,
//               marginBottom: marginBottom ? marginBottom : 0,
//               marginTop: marginTop ? marginTop : 0,
//             }}
//           /> */}
//         </LinkHref>
//       </div>
//     );
//   }

//   return (
//     <div
//       ref='imageView'
//       style={{
//         boxSizing: "border-box",
//         position: "relative",
//         height: "auto",
//         display: "flex",
//         width: "100%",
//         padding: containerPadding,
//         flex: 1,
//         justifyContent: align,
//       }}
//     >
//       <ShowImage src={srcImage} />
//       {/* <Image
//         src={srcImage}
//         alt={altText}
//         style={{
//           width: width,
//           height: newHeight,
//           marginBottom: marginBottom ? marginBottom : 0,
//           marginTop: marginTop ? marginTop : 0,
//         }}
//       /> */}
//     </div>
//   );
// };

class ImageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthElement: 0,
    };
  }
  componentDidMount() {
    const { id, layoutState } = this.props;
    const { animation } = layoutState.getItemJS(id).props.values;
    if (animation && animation.show) {
      if (this.refs.imageView) {
        const elementWidth = ReactDOM.findDOMNode(
          this.refs.imageView
        ).getBoundingClientRect().width;
        this.setState({
          widthElement: elementWidth,
        });
      }
    }
  }

  render() {
    const { id, layoutState, widthScreen } = this.props;
    const { imageUrl, align, link, desktop, mobile } = layoutState.getItemJS(
      id
    ).props.values;
    let srcMobile = imageUrl.urlMobile || imageUrl.url || "";
    let srcDesktop = imageUrl.url || imageUrl.urlMobile || "";

    const srcImage = widthScreen > 480 ? srcDesktop : srcMobile;
    const content = widthScreen > 480 ? desktop : mobile;
    const isShow =
      widthScreen > 480
        ? get(content, "show", false)
        : get(mobile, "show", false);
    if (!isShow) {
      return <div />;
    }

    const padding = get(content, "padding", "0px");
    const width = get(content, "width", "100%");
    const height = get(content, "height", "100%");
    const containerMargin = get(content, "margin", "0px");
    const isFlex = get(content, "isFullHeight") ? { flex: 1 } : {};
    return (
      <div
        style={{
          boxSizing: "border-box",
          position: "relative",
          height: "auto",
          display: "flex",
          width: "100%",
          padding: padding,
          justifyContent: align,
          ...isFlex,
        }}
      >
        <HrefContainer link={link}>
          <Image
            src={srcImage}
            alt={"image"}
            style={{
              width: width,
              height: height,
              margin: containerMargin,
            }}
          />
        </HrefContainer>
      </div>
    );
  }
}
const Image = styled.img`
  opacity: 1;
  display: block;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`;

export default connect("layoutState", "widthScreen")(ImageView);
