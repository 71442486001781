import React, { useState, useEffect } from "react"
import { keyBy } from "lodash"
import { Box } from "rebass"
import { get } from "lodash"
import { useQuery, Pagination, Loading } from "react-admin"
import TextField from "@material-ui/core/TextField"
import { ButtonEditView, Datagrid, useStylesTable } from "./"
import { HeaderBox, ContentBox } from "./Styled"
const LocaleName = ({ record = {} }) => {
  const newLocaleName = get(record, `locale`, {})
  const labelLocale = Object.keys(newLocaleName)[0]
  const name = get(record, `locale.${labelLocale}.name`, "")

  return <span>{name}</span>
}
LocaleName.defaultProps = { label: "Name" }

const ItemField = ({ source, record = {} }) => <span>{record[source]}</span>

export const AttributesDataGrid = (props) => {
  const { classes: classesOverride } = props
  const classes = useStylesTable({ classes: classesOverride })
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [search, setSearch] = useState("")
  const [searchText, setSearchText] = useState("")
  const [isRequest, setIsRequest] = useState(false)
  const { data, total, loading } = useQuery({
    type: "getList",
    resource: "attributes",
    payload: {
      pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      filter: { q: searchText },
    },
  })

  const handleChange = (name) => (event) => {
    if (name === "search") {
      setSearch(event.target.value)
    }
    setIsRequest(true)
  }

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (isRequest) {
        setSearchText(search)
        setIsRequest(false)
      }
    }, 500)
    return () => clearTimeout(timeout)
  })

  if (loading) {
    return <Loading />
  }
  return (
    <Box width={[1]}>
      <HeaderBox>
        <TextField
          label="search"
          margin="dense"
          variant="filled"
          value={search}
          onChange={handleChange("search")}
          InputLabelProps={{ shrink: true }}
          style={{ width: 200 }}
        />
      </HeaderBox>
      {data && (
        <ContentBox>
          <Datagrid
            {...props}
            classes={classes}
            data={keyBy(data, "id")}
            ids={data.map(({ id }) => id)}
            currentSort={{ field: "id", order: "ASC" }}
            basePath="/attributes" // required only if you set use "rowClick"
            // rowClick="edit"
          >
            <LocaleName label={"resources.attributes.fields.name"} />
            <ItemField
              label={"resources.attributes.fields.code"}
              source="code"
            />
            <ItemField
              label={"resources.attributes.fields.type"}
              source="data_type"
            />
            <ButtonEditView {...props} />
          </Datagrid>
          <Pagination
            page={page}
            perPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            setPage={(v) => setPage(v)}
            setPerPage={(v) => setPerPage(v)}
            total={total}
          />
        </ContentBox>
      )}
    </Box>
  )
}
