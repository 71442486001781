import React, { useState, useEffect } from "react";
import { EmptyList, PaperCard } from "../../../components";
import { Flex } from "rebass";
import { get } from "lodash";
import { Loading, withDataProvider } from "react-admin";
import { ListShipment } from "./shipment_item";
import { RestApi } from "../../../restClient";

const ShipmentFields = (props) => {
  const { record, dataProvider } = props;
  const orderId = get(record, "id", 0);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [list, setList] = useState([]);

  const onChangeText = (e) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      RestApi(`/orders/${orderId}/shipments?search=${searchText}`)
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          // console.log(res);
          if (res) {
            setList(res);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }, 1000);

    return () => clearTimeout(timer);
  }, [orderId, searchText, dataProvider]);
  if (loading) {
    return <Loading />;
  }
  if (list && list.length === 0) {
    return (
      <EmptyList
        bg={"#FFFFFF"}
        message={"resources.orders.messages.no_package_has_been_created"}
      />
    );
  }
  return (
    <Flex width={[1]} flexDirection={"column"} py={[2]}>
      <PaperCard
        title={""}
        showButton={false}
        elevation={3}
        bgHeader={"#eeeeee"}
        propsHeader={{ px: [3] }}
      >
        <ListShipment
          {...props}
          list={list}
          searchText={searchText}
          onChangeText={onChangeText}
        />
      </PaperCard>
    </Flex>
  );
};

export default withDataProvider(ShipmentFields);
