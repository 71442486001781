import React from "react";
// import { Badge } from "antd";
import { Flex } from "rebass";
import { FontelloSvg } from "./Icons";

const Account4 = ({ isMobile, values }) => {
  //   const { items } = cart;cart,
  const { color } = values;
  //   const colorPrimary = get(settings, `theme.themes.colors.primary`, "#6B5B37");
  return (
    <Flex
      flexDirection={"row"}
      justifyContent={"flex-end"}
      alignItems={"center"}
    >
      {!isMobile && (
        <Flex flexDirection={"row"} alignItems={"center"} px={[2]}>
          <FontelloSvg name={"user_o"} fill={color} size='22px' />
        </Flex>
      )}
      <Flex flexDirection={"row"}>
        {isMobile && (
          <Flex pr={[1]}>
            <FontelloSvg name={"search"} fill={color} size='22px' />
          </Flex>
        )}
        <Flex flexDirection={"column"} alignItems={"center"} px={[0, 3]}>
          {/* <Badge count={items.length + 3} style={{ display: "flex" }}> */}
          <Flex flexDirection={"column"} alignItems={"center"} pb={["1px"]}>
            <FontelloSvg name={"shoppingCart"} fill={color} size={"16px"} />
          </Flex>
          {/* </Badge> */}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Account4;
