export default {
  stores: {
    name: "Stores",
    new_title: "New Store",
    edit_title: "Edit Store",
    fields: {
      name: "Name",
      type: "Type",
      created_at: "Create On",
      tags: "Tags",
    },
  },
};
