import React from "react";
import { Flex } from "rebass";
import CircularProgress from "@material-ui/core/CircularProgress";

export const Loading = () => (
  <Flex width={[1]} p={[1]} justifyContent={["center"]} alignItems={["center"]}>
    <CircularProgress />
  </Flex>
);

export const FullLoadingScreen = ({ color = "secondary" }) => (
  <Flex
    width={[1]}
    p={[1]}
    flexDirection={"column"}
    justifyContent={["center"]}
    alignItems={["center"]}
    style={{ height: "100vh" }}
  >
    <CircularProgress color={color} />
  </Flex>
);
