import React, { useState, useEffect } from "react";
import { keyBy, get } from "lodash";
import {
  useQueryWithStore,
  Pagination,
  Loading,
  TextField,
  BooleanField,
} from "react-admin";
import { Paper as MuiPaper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Datagrid, ButtonEditView, CreateButton } from "../../../../components";
import { ContentBox } from "../../../attributes/components/Styled";
import { Box, Flex, Text } from "rebass";
import { DimensionField, VolumeField } from "../PackagingFields";

const Paper = withStyles((theme) => ({
  root: {
    width: "100%",
  },
}))(MuiPaper);

const ListPackagings = (props) => {
  const {
    labelCreate,
    subheader,
    createIcon,
    bgHeader = "#EEEEEE",
    elevation = 3,
    showButton = true,
    propsHeader = { p: [2] },
    location,
    match,
  } = props;
  const [page, setPage] = useState(1);
  const websiteId = get(match, "params.id", 0);
  const [perPage, setPerPage] = useState(25);
  const [isRequest, setIsRequest] = useState(false);
  const { data, total, loading } = useQueryWithStore({
    type: "getList",
    resource: "packagings",
    payload: {
      pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      filter: { website_id: websiteId },
    },
  });

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (isRequest) {
        setIsRequest(false);
      }
    }, 500);
    return () => clearTimeout(timeout);
  });

  if (loading) {
    return <Loading />;
  }
  return (
    <Box width={[1]} p={[3]}>
      <Paper elevation={elevation} component={"div"}>
        <Flex
          {...propsHeader}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            backgroundColor: bgHeader,
          }}
        >
          <Text fontWeight="bold">{subheader}</Text>
          {showButton && (
            <CreateButton
              basePath={location.pathname}
              icon={createIcon}
              sizeIcon={"xsmall"}
              isNotShowIcon={!createIcon}
              style={{
                justifyContent: "center",
                textTransform: "none",
                paddingRight: 8,
              }}
              label={labelCreate}
            />
          )}
        </Flex>
        {data && (
          <ContentBox py={[2]}>
            <Datagrid
              data={keyBy(data, "id")}
              ids={data.map(({ id }) => id)}
              currentSort={{ field: "id", order: "ASC" }}
              basePath="/websites" // required only if you set use "rowClick"
              // rowClick="edit"
            >
              <TextField
                label={"resources.promotions.fields.name"}
                source="name"
              />

              <DimensionField
                source={"width"}
                label={"resources.shippings.fields.dimensions"}
              />
              <VolumeField
                source={"weight"}
                label={"resources.shippings.fields.volume"}
              />
              <BooleanField source={"active"} />

              <ButtonEditView
                basePath={props.basePath}
                isCustomLink={true}
                taburl="shipping"
              />
            </Datagrid>
            <Pagination
              page={page}
              perPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              setPage={(v) => setPage(v)}
              setPerPage={(v) => setPerPage(v)}
              total={total ? total : 0}
            />
          </ContentBox>
        )}
      </Paper>
    </Box>
  );
};

export default ListPackagings;
