import React, { Fragment } from "react";
import { get } from "lodash";
import { Flex } from "rebass";
import { FontelloSvg } from "../../../../../components/Icons";
export default (props) => {
  const { item, indexView } = props;
  const { desktop, type, color } = item.content;
  const size = get(desktop, "size", 10);
  const isShow = get(desktop, "show", true);
  const isShowLabel = get(desktop, "showLabel", true);
  const align = get(desktop, "align", "flex-start");
  if (!isShow) {
    return <Fragment />;
  }
  if (isShowLabel) {
    return (
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={align}
        alignItems={"center"}
        style={{
          padding: desktop.containerPadding,
          border: `${get(props, `border.size[${indexView}]`, 0)}px 
          ${get(props, `border.type[${indexView}]`)}
          ${get(props, `border.color[${indexView}]`)}
         `,
        }}
      >
        <FontelloSvg
          name={type ? type : ""}
          fill={color ? color : "#000000"}
          size={`${size}px`}
        />
        <span
          style={{
            textTransform: "capitalize",
            fontSize: `${size}px`,
            color: color ? color : "#000000",
            paddingLeft: 8,
          }}
        >
          {type}
        </span>
      </Flex>
    );
  }

  return (
    <div
      style={{
        padding: desktop.containerPadding,
        border: `${get(props, `border.size[${indexView}]`, 0)}px 
          ${get(props, `border.type[${indexView}]`)}
          ${get(props, `border.color[${indexView}]`)}
         `,
        backgroundColor: get(
          props,
          `border.backgroundColor[${indexView}]`,
          "transparent"
        ),
      }}
    >
      <FontelloSvg
        name={type ? type : ""}
        fill={color ? color : "#000000"}
        size={`${size}px`}
      />
    </div>
  );
};
