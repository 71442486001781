import React, { cloneElement, Children, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useReferenceManyFieldController, ListContext } from "react-admin";
import { HeaderBox, ContentBox } from "../../utils";
/**
  useSortState,
  usePaginationState, */

// import { lightBlue } from "@material-ui/core/colors"
import TextField from "@material-ui/core/TextField";

import { Box, Flex, Text } from "rebass";
import { CreateButton } from "../buttons";
import { AddIcon } from "../icon_svg";
import { useStylesTable } from "../utils";
export const RaReferenceManyField = (props) => {
  const {
    labelCreate,
    defaultbasePath,
    notShowSearch,
    subheader,
    isCustomCreateButton,
    basePath,
    children,
    page = 1,
    perPage,
    record,
    reference,
    resource,
    sort,
    source,
    target,
    createIcon,
    filter,
    classes: classesOverride,
  } = props;
  if (React.Children.count(children) !== 1) {
    throw new Error(
      "<ReferenceManyField> only accepts a single child (like <Datagrid>)"
    );
  }
  const [searchText, setSearchText] = useState("");

  let location = useLocation();

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const controllerProps = useReferenceManyFieldController({
    resource,
    reference,
    record,
    target,
    filter: { ...filter, q: searchText },
    source,
    basePath: basePath ? basePath : defaultbasePath,
    page,
    perPage,
    sort,
  });
  const classesT = useStylesTable({ classes: classesOverride });
  return (
    <Box width={[1]}>
      {subheader && (
        <Box width={[1]}>
          <Text fontSize={[0]}>{subheader}</Text>
        </Box>
      )}
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box width={[1]}>
          {!notShowSearch && (
            <TextField
              placeholder={"Search"}
              value={searchText}
              onChange={handleChange}
              margin={"dense"}
            />
          )}
        </Box>
        <HeaderBox>
          {isCustomCreateButton ? (
            <CreateButton
              basePath={location.pathname}
              icon={createIcon}
              sizeIcon={"xsmall"}
              isNotShowIcon={!createIcon}
              style={{
                justifyContent: "center",
                textTransform: "none",
                paddingRight: 8,
              }}
              label={labelCreate}
            />
          ) : (
            <CreateButton
              basePath={location.pathname}
              icon={<AddIcon />}
              sizeIcon={"xsmall"}
              style={{
                justifyContent: "center",
                textTransform: "none",
              }}
              label={labelCreate}
            />
          )}
        </HeaderBox>
      </Flex>
      <ListContext.Provider value={controllerProps}>
        <ReferenceManyFieldView
          {...props}
          {...controllerProps}
          classes={classesT}
        />
      </ListContext.Provider>
      {/* <ReferenceManyFieldView
        {...rest}
        {...{
          currentSort: sort,
          page,
          perPage,
          setPage,
          setPerPage,
          setSort: setSortField,
          ...controllerProps,
        }}
      /> */}
    </Box>
  );
};

RaReferenceManyField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.element.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  filter: PropTypes.object,
  label: PropTypes.string,
  perPage: PropTypes.number,
  record: PropTypes.object,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
  labelCreate: PropTypes.string,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  target: PropTypes.string.isRequired,
  isCustomCreateButton: PropTypes.bool,
  subheader: PropTypes.string,
};

RaReferenceManyField.defaultProps = {
  filter: {},
  perPage: 25,
  sort: { field: "id", order: "DESC" },
  source: "id",
  addLabel: true,
  labelCreate: "ra.action.create",
};

export const ReferenceManyFieldView = ({
  children,
  className,
  currentSort,
  data,
  ids,
  loaded,
  page,
  pagination,
  perPage,
  reference,
  referenceBasePath,
  setPage,
  setPerPage,
  setSort,
  total,
  classes,
}) => {
  return (
    <ContentBox py={[0]}>
      {cloneElement(Children.only(children), {
        className,
        resource: reference,
        ids,
        loaded,
        data,
        basePath: referenceBasePath,
        currentSort,
        setSort,
        total,
        classes,
      })}
      {pagination &&
        total !== undefined &&
        cloneElement(pagination, {
          page,
          perPage,
          setPage,
          setPerPage,
          total,
        })}
    </ContentBox>
  );
};

ReferenceManyFieldView.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  data: PropTypes.object,
  ids: PropTypes.array,
  loaded: PropTypes.bool,
  pagination: PropTypes.element,
  reference: PropTypes.string,
  referenceBasePath: PropTypes.string,
  setSort: PropTypes.func,
};

export default RaReferenceManyField;
