import React from "react";
import { Flex, Box } from "rebass";
import { get } from "lodash";
import { withDataProvider } from "react-admin";
import { PaperCard } from "./promotions/PaperCard";
import { RaTextArea, RaWebsiteLocaleTabs } from "../../../components";

const LocaleDescriptions = (props) => {
  const { locale } = props;

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <RaTextArea
        source={`locale.${locale}.description`}
        label={"resources.promotions.fields.description"}
        rows="6"
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
      <RaTextArea
        source={`locale.${locale}.terms`}
        label={"resources.promotions.fields.terms"}
        rows="6"
        fullWidth
        multiline
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        style={{ transform: "none" }}
      />
    </Flex>
  );
};

const DescriptionTerms = (props) => {
  const { translate, formData } = props;
  const website_id = get(formData, "website_id", "0");
  if (website_id === "0") {
    return <div />;
  }
  return (
    <Box width={[1]} py={[2]}>
      <PaperCard
        title={translate("resources.promotions.labels.description_terms")}
        elevation={3}
      >
        <RaWebsiteLocaleTabs
          website_id={website_id}
          nameTab="description_terms-info"
        >
          <LocaleDescriptions {...props} />
        </RaWebsiteLocaleTabs>
      </PaperCard>
    </Box>
  );
};

export default withDataProvider(DescriptionTerms);
