import React from "react";
import { DropTarget } from "react-dnd";
import { target } from "./utils";
import { connect } from "../../core";
import { MultiContentGroupView } from "./components";
const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
  websiteId,
  categories,
}) => {
  const { containerPadding } = layoutState.getItemJS(id).props.values;
  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        display: "flex",
        flex: 1,
        padding: 0,
        width: "100%",
      }}
    >
      <div style={{ padding: containerPadding, width: "100%" }}>
        <MultiContentGroupView
          values={layoutState.getItemJS(id).props.values}
          websiteId={websiteId}
          categories={categories}
        />
      </div>
    </div>
  );
};

const LayoutContainer = DropTarget(
  "MultiContentGroupView",
  target,
  (conn, monitor) => ({
    connectDropTarget: conn.dropTarget(),
    isOverCurrent: monitor.isOver({ shallow: true }),
  })
)(EditLayout);

export default connect(
  "layoutState",
  "readOnly",
  "websiteId",
  "categories"
)(LayoutContainer);

//DnDMultiContentGroupView
