import React, { Fragment } from "react"
import { Box, Flex } from "rebass"
import { get } from "lodash"

import { SelectInput, SwitchInput, Counter } from "../../components"
import { modeTexty, typeTexty, easeAnimation } from "./utils"

const BoxTextAnimation = (props) => {
  const { data, onUpdateValue, type } = props
  const isAnimation = get(data, "isAnimation", false)
  const isEase = get(data, "isEase", false)
  const ease = get(data, "ease", "")
  const typeAnimation = get(data, "type", "top")
  const mode = get(data, "mode", "smooth")
  const delay = get(data, "delay", 200)
  const duration = get(data, "duration", 1500)
  return (
    <Box width={[1]}>
      <Flex
        width={[1]}
        direction={"row"}
        wrap={"wrap"}
        alignItems={"center"}
        py={[1]}
        className="child-wrap"
      >
        <SwitchInput
          id={`carousel-item-${type}-use-animation-${props.index}`}
          title={"Use Animation"}
          checked={isAnimation}
          style={{ width: 200 }}
          onChange={(e) =>
            onUpdateValue("isAnimation", e.target.checked, props.index)
          }
        />
        {isAnimation && (
          <Fragment>
            <SelectInput
              id={`type-animation-${type}_carousel_position_image-${props.index}`}
              title={"Type Animation"}
              value={typeAnimation}
              list={typeTexty}
              onChange={(event) =>
                onUpdateValue("type", event.target.value, props.index)
              }
            />
            <SelectInput
              id={`mode-animation-${type}_carousel_position_image-${props.index}`}
              title={"Mode Animation"}
              value={mode}
              list={modeTexty}
              style={{ maxHeight: 200 }}
              onChange={(event) =>
                onUpdateValue("mode", event.target.value, props.index)
              }
            />
          </Fragment>
        )}
      </Flex>
      {isAnimation && (
        <Box width={[1]}>
          <Flex
            width={[1]}
            flexDirection={"row"}
            flexWrap={"wrap"}
            alignItems={"center"}
            py={[1]}
            className="child-wrap"
          >
            <Counter
              isDisabled={false}
              title="Delay"
              onSetValue={(value) => onUpdateValue("delay", value, props.index)}
              value={delay}
              interval={1}
              subtitle=""
              minValue={0}
            />
            <Counter
              isDisabled={false}
              title="Duration"
              onSetValue={(value) =>
                onUpdateValue("duration", value, props.index)
              }
              value={duration}
              interval={1}
              subtitle=""
              minValue={100}
            />
          </Flex>
          <Flex
            width={[1]}
            flexDirection={"row"}
            flexWrap={"wrap"}
            className={"child-wrap"}
            alignItems={"center"}
          >
            <SwitchInput
              id={`carousel-item-${type}-use-ease-${props.index}`}
              title={"Use Ease"}
              checked={isEase}
              style={{ width: 200 }}
              onChange={(e) =>
                onUpdateValue("isEase", e.target.checked, props.index)
              }
            />
            {isEase && (
              <SelectInput
                id={`Ease_carousel-${type}_position_image-${props.index}`}
                title={"Ease"}
                value={ease}
                list={easeAnimation}
                style={{ maxHeight: 200 }}
                onChange={(event) =>
                  onUpdateValue("ease", event.target.value, props.index)
                }
              />
            )}
          </Flex>
        </Box>
      )}
    </Box>
  )
}
export default BoxTextAnimation
