import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text } from "rebass";
import { get } from "lodash";
import { SelectInput } from "../select";
import DialogInfoLayout from "./DialogInfoLayout";

const listDirection = [
  { id: "column", name: "Column" },
  { id: "column-reverse", name: "Column-Reverse" },
  { id: "row", name: "Row" },
  { id: "row-reverse", name: "Row Reverse" },
];
const listAlignment = [
  { id: "baseline", name: "Baseline" },
  { id: "flex-start", name: "Flex Start" },
  { id: "flex-end", name: "Flex End" },
  { id: "center", name: "Center" },
  { id: "stretch", name: "Stretch" },
  { id: "space-between", name: "Space Between" },
  { id: "space-around", name: "Space Around" },
  { id: "space-evenly", name: "Space Evenly" },
];
const defaultWrap = [
  { id: "wrap", name: "Wrap" },
  { id: "nowrap", name: "No Wrap" },
];

const LayoutRowComponent = (props) => {
  const { layoutRow, onUpdateLayoutRow } = props;
  const [state, setState] = useState(layoutRow);
  // useEffect(() => {
  //   handleChange(layoutRow);
  // }, [layoutRow]);
  // const handleChange = (v) => {
  //   setState({
  //     ...state,
  //     ...v,
  //   });
  // };

  const onChangeValue = (k, v, index) => {
    let myValue = state[k];

    if (state[k]) {
      if (myValue[index]) {
        myValue[index] = v;
      } else {
        if (myValue[index] === "") {
          myValue[index] = v;
        } else {
          if (typeof v === "number") {
            myValue = [1, 1];
            myValue[index] = v;
          } else {
            myValue = ["", ""];
            myValue[index] = v;
          }
        }
      }
    }

    const params = { ...state, [k]: myValue };
    setState(params);
    onUpdateLayoutRow("layoutRow", params);
  };

  return (
    <Box width={[1]}>
      <Flex width={[1]} flexDirection={"column"}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontWeight={"bold"} pb={[2]}>
            Desktop View
          </Text>
          <DialogInfoLayout {...props} />
        </Flex>
        <SelectInput
          id={`direction`}
          title={"Direction"}
          value={state.flexDirection[1]}
          list={listDirection}
          onChange={(e) => onChangeValue("flexDirection", e.target.value, 1)}
        />
        <SelectInput
          id={`justifyContent`}
          title={"Jusity Content"}
          value={state.justifyContent[1]}
          list={listAlignment}
          onChange={(e) => onChangeValue("justifyContent", e.target.value, 1)}
        />
        <SelectInput
          id={`alignItems`}
          title={"Align Items"}
          value={state.alignItems[1]}
          list={listAlignment}
          onChange={(e) => onChangeValue("alignItems", e.target.value, 1)}
        />
        <SelectInput
          id={`wrap`}
          title={"Wrap"}
          value={get(state, "flexWrap[1]", "nowrap")}
          list={defaultWrap}
          onChange={(e) => onChangeValue("flexWrap", e.target.value, 1)}
        />
      </Flex>
      <Flex width={[1]} flexDirection={"column"} py={[2]}>
        <Text fontWeight={"bold"} pb={[2]}>
          Mobile View
        </Text>
        <SelectInput
          id={`direction-mobile`}
          title={"Direction"}
          value={state.flexDirection[0]}
          list={listDirection}
          onChange={(e) => onChangeValue("flexDirection", e.target.value, 0)}
        />
        <SelectInput
          id={`justifyContent-mobile`}
          title={"Jusity Content"}
          value={state.justifyContent[0]}
          list={listAlignment}
          onChange={(e) => onChangeValue("justifyContent", e.target.value, 0)}
        />
        <SelectInput
          id={`alignItems-mobile`}
          title={"Align Items"}
          value={state.alignItems[0]}
          list={listAlignment}
          onChange={(e) => onChangeValue("alignItems", e.target.value, 0)}
        />
        <SelectInput
          id={`wrap-mobile`}
          title={"Wrap"}
          value={get(state, "flexWrap[0]", "nowrap")}
          list={defaultWrap}
          onChange={(e) => onChangeValue("flexWrap", e.target.value, 0)}
        />
      </Flex>
    </Box>
  );
};

LayoutRowComponent.propTypes = {
  layoutRow: PropTypes.object,
  title: PropTypes.string,
  onUpdateLayoutRow: PropTypes.func.isRequired,
};

LayoutRowComponent.defaultProps = {
  layoutRow: {
    flexDirection: ["column", "row"],
    justifyContent: ["flex-start", "flex-start"],
    alignItems: ["flex-start", "flex-start"],
    flexWrap: ["nowrap", "nowrap"],
  },
  title: "Setting Layout",
};

export default LayoutRowComponent;
