import React from "react"
import PropTypes from "prop-types"
import TextField from "@material-ui/core/TextField"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import { useInput, FieldTitle, InputHelperText } from "react-admin"
import { Box } from "rebass"
import { red } from "@material-ui/core/colors"
import { bcx, bcx_secondary_orange } from "../app"
import sanitizeRestProps from "./sanitizeRestProps"
import TextMessage from "./utils"

const themeRaDateTIme = createMuiTheme({
  palette: {
    primary: bcx,
    secondary: bcx_secondary_orange,
    error: red,
  },
  typography: {
    fontFamily: ["SalesforceSans, sans-serif"].join(","),
    fontSize: 12,
    useNextVariants: true,
  },
  overrides: {
    // Style sheet name ⚛️
    MuiFormControl: {
      marginDense: {
        marginTop: 16,
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: "Transparent",
        "&$focused": {
          backgroundColor: "Transparent",
        },
        "&:hover": {
          backgroundColor: "Transparent",
        },
      },
      inputMarginDense: {
        paddingTop: 2,
        paddingBottom: 8,
      },
      inputMultiline: {
        paddingTop: 2,
        paddingBottom: 2,
      },
      input: {
        padding: "27px 2px 2px",
        fontSize: 12,
      },
      multiline: {
        padding: "27px 2px 2px",
        fontSize: 12,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "Transparent",
        "&$focused": {
          backgroundColor: "Transparent",
        },
        "&:hover": {
          backgroundColor: "Transparent",
        },
      },
      inputMarginDense: {
        paddingTop: 2,
        paddingBottom: 4,
        padding: "27px 2px 2px",
      },
      input: {
        padding: "27px 2px 2px",
        fontSize: 12,
      },
      multiline: {
        padding: "27px 2px 2px",
        fontSize: 12,
      },
    },
  },
})

const leftPad = (nb = 2) => value => ("0".repeat(nb) + value).slice(-nb)
const leftPad4 = leftPad(4)
const leftPad2 = leftPad(2)
const convertDateToString = value => {
  if (!(value instanceof Date) || isNaN(value.getDate())) return ""
  const yyyy = leftPad4(value.getFullYear())
  const MM = leftPad2(value.getMonth() + 1)
  const dd = leftPad2(value.getDate())
  const hh = leftPad2(value.getHours())
  const mm = leftPad2(value.getMinutes())
  return `${yyyy}-${MM}-${dd}T${hh}:${mm}`
}

// yyyy-MM-ddThh:mm
const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/

const format = value => {
  // null, undefined and empty string values should not go through convertDateToString
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (value == null || value === "") {
    return ""
  }

  if (value instanceof Date) {
    return convertDateToString(value)
  }
  // valid dates should not be converted
  if (dateTimeRegex.test(value)) {
    return value
  }

  return convertDateToString(new Date(value))
}

const parse = value => new Date(value)

export const RaDateTimeInput = ({
  label,
  helperText,
  margin = "dense",
  onBlur,
  onChange,
  onFocus,
  options,
  source,
  resource,
  validate,
  variant = "filled",
  notetext,
  ...rest
}) => {
  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: "datetime-local",
    validate,
    ...rest,
  })

  return (
    <Box>
      <ThemeProvider theme={themeRaDateTIme}>
        <TextField
          id={id}
          {...input}
          variant={variant}
          margin={margin}
          error={!!(touched && error)}
          helperText={
            (touched && error) || helperText ? (
              <InputHelperText
                touched={touched}
                error={error}
                helperText={helperText}
              />
            ) : null
          }
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          InputLabelProps={{
            shrink: false,
          }}
          {...options}
          {...sanitizeRestProps(rest)}
        />
      </ThemeProvider>
      {notetext && <TextMessage notetext={notetext} />}
    </Box>
  )
}

RaDateTimeInput.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  notetext: PropTypes.string,
}

RaDateTimeInput.defaultProps = {
  options: {},
}

export default RaDateTimeInput
