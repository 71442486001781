import React, { useState } from "react";
import { Box } from "rebass";
import { get } from "lodash";
import HomepageEditorLayout from "../HomepageEditorLayout";

const HomepageContent = (props) => {
  const { formData, widthScreen, heightScreen, categories } = props;
  // const translate = useTranslate()
  // const myHomePage = "myHomePage";
  // const myref = useRef(myHomePage);
  const [layout, setLayout] = useState(formData.homepage_content);
  const websiteId = get(formData, "website_id", 0);
  const siteId = get(formData, "site_id", 0);
  const onChangeData = (data, dataRaw, localeId) => {
    setLayout(data);
    props.updateField(`homepage_content`, dataRaw);
  };
  const onResetPage = (ref, locale) => {
    //myref, "en"
  };

  return (
    <Box width={[1]} pl={[1]}>
      <HomepageEditorLayout
        // ref={myref}
        // myref={myref}
        onChangeData={(data, dataRaw) => onChangeData(data, dataRaw, "en")}
        data={layout}
        statusForm={"edit"}
        isShow={true}
        locale={"en"}
        onResetPage={() => onResetPage()}
        widthScreen={widthScreen - 250}
        heightScreen={heightScreen}
        websiteId={websiteId}
        siteId={siteId}
        categories={categories}
      />
    </Box>
  );
};

export default HomepageContent;
