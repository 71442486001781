//
import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../dnd";
import { Layout21 } from "../ContentBox";
import { DivHover, DivHelp } from "./styled";

const MenuGroupView = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };
  const onMouseOver = () => {
    setHover(false);
  };

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type='MenuGroupView' />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  );
};

MenuGroupView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
};
/**
 * item list
 * {
 * title:""
      type: "",
      url: "",
      slug:'',
      target: "",
      collection: 0,
    }
    {
        title: "",
        icon: "",
        widthIcon: "100px",
        type: "text",
        url: "",
        target: "same",
        color: "#000000",
      },
 */
MenuGroupView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "MenuGroupView",
    containerPadding: "0px",
    layoutRow: {
      flexDirection: ["column", "row"],
      justifyContent: ["flex-start", "flex-start"],
      alignItems: ["flex-start", "flex-start"],
      flexWrap: ["nowrap", "nowrap"],
    },
    list: [],
  },
  layout: Layout21,
};
export default MenuGroupView;
