export default {
  webhooks: {
    name: "Webhooks",
    create_title: "New Webhook",
    edit_title: "Edit Webhook",
    back: "Back to Webhooks",
    label: {
      settings: "Settings",
      events: "Events",
      order: "Order",
      customer: "Customer",
      product: "Product",
    },
    fields: {
      name: "Name",
      active: "Active",
      description: "Description",
      created: "Created",
      updated: "Updated",
      app_secret: "App secret",
      url: "Url",
    },
    note: {
      language: "Supported languages for the site",
      subheader:
        "Website shares the same products, pricing, categories, collections but with unique webpage design",
    },
  },
}
