export default {
  programmes: {
    name: "Programmes",
    tabs: {
      programme: "Programme",
      earning_rules: "Earning Rules",
      levels: "Levels",
    },
    fields: {
      active: "Active",
      account: "Account",
      name: "Name",
      loyalty_program_name: "Loyalty Program Name",
      currency_code: "Currency Code",
      points_singular: "Point in Singular",
      points_plural: "Point in Plural",
      points_expiry: "Point Expiry (in Days)",
      catalogues: "Catalogues",
      remarks: "Remarks",
      created_at: "Create On",
    },
    info: {},
    message: {
      loyalty_program_name: "How your customer remember your loyalty program",
      currency_code: "For example, SGD, THB, IDR, MYR",
      points_singular: "For example, point, dollar, $",
      points_plural: "For example, points, dollars, $",
      points_expiry: "Put 0 for no expiry",
      catalogues: "Which catalogue to include for this program",
    },
  },
}
