import React from "react";
import { Flex, Text } from "rebass";
import { get } from "lodash";
import {
  BlockCounter,
  ItemBgProps,
  SketchColorPicker,
  LayoutContent,
  BlockCounterWithType,
  SwitchInput,
  DialogGradient,
} from "../../components";
import { ContentDetail } from "../../actions";
/*
padding: get(mobile, "padding", "0px"),
      backgroundColor: get(mobile, "backgroundColor", "transparent"),
      width: get(mobile, "width", "100%"),
      height: get(mobile, "height", "100%"),
      layout: get(mobile, "layout", { span: 0, order: 0 }),
      bgProps: get(mobile, "bgProps.show", false)
        ? get(mobile, "bgProps", defaultItemContent.mobile.bgProps)
        : defaultItemContent.mobile.bgProps,
      gradient: get(mobile, "gradient.show", false)
        ? get(mobile, "gradient", defaultItemContent.mobile.gradient)
        : defaultItemContent.mobile.gradient,
*/
export default (props) => {
  const { state, onSetValue } = props;

  const onSetItemGradient = (k, v) => {
    const newGradient = {
      ...state.gradient,
      [k]: v,
    };
    onSetValue("gradient", newGradient);
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Flex width={[1]} flexDirection={"row"} bg={"#0288d1"}>
        <Text fontSize={[0]} fontWeight={"bold"} color={"white"} p={[2]}>
          Mobile Content
        </Text>
      </Flex>

      <SketchColorPicker
        title={"Background Color"}
        onUpdateColor={(color) => onSetValue("backgroundColor", color)}
        color={state.backgroundColor}
      />
      <BlockCounter
        containerPadding={state.padding}
        onUpdatePadding={(value) => onSetValue("padding", value)}
        title={"Padding"}
      />

      <BlockCounterWithType
        name='width-content'
        value={state.width}
        onSetValue={(v) => onSetValue("width", v)}
        title={"Width"}
      />
      <BlockCounterWithType
        name='max-width-content'
        value={state.maxWidth}
        onSetValue={(v) => onSetValue("maxWidth", v)}
        title={"MaxWidth"}
      />
      <BlockCounterWithType
        name='height-content'
        value={state.height}
        onSetValue={(v) => onSetValue("height", v)}
        title={"Height"}
      />
      <ContentDetail>
        <LayoutContent
          state={state.layout}
          onSetValue={(v) => onSetValue("layout", v)}
        />
      </ContentDetail>
      <ContentDetail>
        <ItemBgProps
          state={state.bgProps}
          onSetValue={(v) => onSetValue("bgProps", v)}
          contentId={"bg-props-mobile"}
        />
      </ContentDetail>
      <ContentDetail>
        <SwitchInput
          id={`use-bg-gradient-body-content-mobile`}
          title={"Use Background Gradient"}
          checked={get(state, "gradient.show", false)}
          onChange={(e) => onSetItemGradient("show", e.target.checked)}
        />
        {get(state, "gradient.show", false) && (
          <DialogGradient
            onSetValue={(gradient) => onSetItemGradient("content", gradient)}
            gradient={get(state, "gradient.content", {
              bg1: "rgb(34,193,195)",
              bg2: "rgba(253,187,45,1)",
              type: "linear-gradient",
              degre: 160,
              linear: [0, 100],
            })}
          />
        )}
      </ContentDetail>
    </Flex>
  );
};
