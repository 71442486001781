import { Record, fromJS } from "immutable";
import crypto from "crypto";
import { get, findIndex } from "lodash";

export const deepRemove = (map, id) => {
  const children = map.getIn(["items", id, "children"]);
  if (children)
    children.forEach((c) => {
      map = deepRemove(map, c);
    });
  return map.deleteIn(["items", id]);
};

const propsValue = {
  values: {
    backgroundColor: "#ececec",
    backgroundImage: {
      url: "",
    },
    width: {
      type: "%",
      contentWidth: 100,
    },
    contentWidth: "100%",
  },
};

const defaultItems = fromJS({
  root: {
    id: "root",
    type: "Body",
    props: propsValue,
    children: [],
  },
});

class LayoutState extends Record({ items: defaultItems, selectedItem: null }) {
  onChange(listener) {
    this.listener = listener;
  }

  getItemJS(id) {
    const item = this.items.get(id);
    return item && item.toJS();
  }

  generateRandomKey() {
    let mykey = crypto.randomBytes(6).toString("base64");
    // console.log(this.items.has(mykey));
    // console.log("cryto", mykey)
    // let key
    // while (key === undefined || this.items.has(key) || !isNaN(Number(key))) {
    //   key = Math.floor(Math.random() * Math.pow(2, 24)).toString(32)
    // }

    return mykey;
  }

  insertOrMoveItem(parentId, idx, item) {
    return item.id
      ? this.moveItem(parentId, idx, item)
      : this.insertItem(parentId, idx, item);
  }

  insertItem(parentId, idx, item) {
    item.id = this.generateRandomKey();
    item.parent = { id: parentId, idx: idx };
    let nextState = this.setIn(["items", item.id], fromJS(item)).updateIn(
      ["items", parentId, "children"],
      (c) => {
        return c && c.splice(idx, 0, item.id);
      }
    );
    this.listener(nextState);
    return item;
  }

  insertTemplate(parentId, idx, item) {
    return item.id
      ? this.moveItem(parentId, idx, item)
      : this.insertTemplateContent(parentId, idx, item);
  }

  insertTemplateContent(parentId, idx, item) {
    // console.log(parentId, item)
    item.id = this.generateRandomKey();
    item.parent = { id: parentId, idx: idx };
    let nextState = this.setIn(["items", item.id], fromJS(item)).updateIn(
      ["items", parentId, "children"],
      (c) => {
        return c && c.splice(idx, 0, item.id);
      }
    );
    this.listener(nextState);

    return nextState;
  }

  insertAllContent(parentId, item1) {
    let newItem = {};
    let myChildren = [];
    let nextState = this.get("items");
    Object.keys(item1).map((key) => {
      let value = item1[key];
      let { parent } = value;

      if (value && value.type !== "Row1") {
        value.id = this.generateRandomKey();
        value.parent = { id: parentId, idx: parent.idx };
        newItem[value.id] = value;
        nextState = nextState.updateIn(["items", parentId, "children"], (c) => {
          return myChildren.splice(parent.idx, 0, value.id);
        });
      }
      return "";
    });

    let myChildrenResult = this.getItemJS(parentId);
    newItem[parentId] = myChildrenResult;
    newItem[parentId].children = myChildren;
    // console.log(myChildren)
    nextState = this.mergeIn(["items"], fromJS(newItem));
    this.listener(nextState);
    return nextState;
  }
  insertAllMultiContent(contentItems, oldDefaultChildren, listChildren) {
    let newItem = {};
    let nextState = this.get("items");
    let myChildren1 = listChildren.map(function (item) {
      return [];
    });

    if (contentItems && contentItems.length > 0) {
      contentItems.map((value) => {
        let { myParent } = value;
        if (value && value.type !== "Row2") {
          const parentValue = findIndex(oldDefaultChildren, function (o) {
            return o === get(myParent, "id", 0);
          });

          const newParentValue = parentValue === -1 ? 0 : parentValue;
          value.id = this.generateRandomKey();
          value.parent = {
            id: listChildren[newParentValue],
            idx: myParent.idx,
          };
          newItem[value.id] = value;
          nextState = nextState.updateIn(
            ["items", listChildren[newParentValue], "children"],
            (c) => {
              return myChildren1[newParentValue]
                ? myChildren1[newParentValue].splice(myParent.idx, 0, value.id)
                : null;
            }
          );
          let myChildrenResult = this.getItemJS(listChildren[newParentValue]);

          newItem[listChildren[newParentValue]] = myChildrenResult;
          newItem[listChildren[newParentValue]].children =
            myChildren1[newParentValue];
        }
        return "";
      });
    }

    nextState = this.mergeIn(["items"], fromJS(newItem));
    this.listener(nextState);
    return nextState;
  }

  // insertItemChild(parentId, idx , item){
  //   // console.log(item);
  //   // console.log(idx);
  //   //console.log(parentId);
  //   item.id = this.generateRandomKey();
  //   item.parent = { id: parentId, idx: idx };
  //   //console.log(item);
  //   let nextState = this
  //     .setIn(['items', item.id], fromJS(item))
  //     .updateIn(['items', parentId, 'children'], c => c.splice(idx, 0, item.id));
  //   this.listener(nextState);
  //   // console.log(nextState);
  //   // if(item.type === 'Row'){
  //   //   this.insertItemChild(item.id,0);
  //   // }
  //   return item;
  // }

  moveItem(parentId, idx, item) {
    if (parentId === item.parent.id && idx > item.parent.idx) {
      idx--;
    }
    let getData = this.getItemJS(parentId);
    //can't add same content
    if (getData.type === item.type) {
      return;
    }

    let nextState = this.updateIn(["items", item.parent.id, "children"], (c) =>
      c.filter((id) => id !== item.id)
    )
      .updateIn(["items", parentId, "children"], (c) => {
        return c && c.splice(idx, 0, item.id);
      })
      .setIn(["items", item.id, "parent"], fromJS({ id: parentId, idx }));
    this.listener(nextState);
    return item;
  }

  removeItem(id) {
    if (id === "root") return;
    const item = this.getItemJS(id);
    let parent = item.parent.id;
    let nextState = this.updateIn(["items", parent, "children"], (c) =>
      c.filter((cId) => cId !== id)
    );
    this.listener(deepRemove(nextState, id));
    return item;
  }

  updateItem(id) {
    return (path, func) => {
      this.listener(this.updateIn(["items", id, ...path], func));
    };
  }

  setSelectedItem(id) {
    if (this.selectedItem === id) return;
    this.listener(this.set("selectedItem", id));
  }

  getSelectedItem() {
    const item = this.items.get(this.selectedItem);
    return item && item.toJS();
  }

  getAncestors(id) {
    let result = [this.getItemJS(id)];
    while (result[0].parent && result.length < 4) {
      result.unshift(this.getItemJS(result[0].parent.id));
    }
    return result;
  }

  toRaw() {
    return this.items.toJS();
  }

  setItems(raw) {
    let layoutState = new LayoutState();
    let nextState = layoutState.set("items", fromJS(raw));
    this.listener(nextState);
  }
}

LayoutState.fromRaw = (raw) => {
  let layoutState = new LayoutState();
  return layoutState.set("items", fromJS(raw));
};

export default LayoutState;
