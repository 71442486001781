export default {
  branches: {
    name: "Branches",
    fields: {
      active: "Active",
      name: "Name",
      uen: "UEN",
      type: "Type",
      address: "Business Address",
      contact_person: "Contact Person",
      email: "Contact Email",
      phone: "Contact Phone",
      validation_code: "Validation Code",
      website: "Website",
      remarks: "Remarks",
      created_at: "Create On",
    },
  },
}
