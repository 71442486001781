import React, { useState } from "react"
import PropTypes from "prop-types"
import Layout from "../dnd"
import { Layout16 } from "../ContentBox"
import { DivHover, DivHelp } from "./styled"

const ProductGroupView = (props) => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(true)
  }
  const onMouseOver = () => {
    setHover(false)
  }

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type="ProductGroupView" />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  )
}

ProductGroupView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
}

ProductGroupView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "ProductGroupView",
    containerPadding: "0px",
    products: [],
    type_list: "product",
    categoryId: 0,
    collectionId: 0,
    showPrice: false,
    limit: 4,
    setting: {
      dots: false,
      infinite: false,
      arrows: true,
      speed: 500,
      centerPadding: "20px",
      slidesToShow: 1,
      rows: 2,
      slidesPerRow: 2,
      autoplay: false,
      autoplaySpeed: false,
    },
  },
  layout: Layout16,
}
export default ProductGroupView
