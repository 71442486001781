import { Layout13 } from "../../TemplateImageSonak";
export const template13A = {
  "5mcZedzv": {
    id: "5mcZedzv",
    type: "Row9",
    props: {
      values: {
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: true,
            align: "middle",
            gutter: 8,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "1080px",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "top",
            gutter: 10,
            justify: "center",
            contentAlign: "center",
            contentJustify: "flex-start",
          },
          bgProps: {
            show: false,
          },
          padding: "0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 5,
                  order: 1,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 2,
                },
                bgProps: {
                  show: false,
                },
                padding: "8px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 4,
                  order: 2,
                },
                bgProps: {
                  show: false,
                },
                padding: "8px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 3,
                },
                bgProps: {
                  show: false,
                },
                padding: "8px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 4,
                  order: 3,
                },
                bgProps: {
                  show: false,
                },
                padding: "8px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 4,
                },
                bgProps: {
                  show: false,
                },
                padding: "8px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 4,
                  order: 4,
                },
                bgProps: {
                  show: false,
                },
                padding: "8px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 5,
                },
                bgProps: {
                  show: false,
                },
                padding: "8px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 4,
                  order: 5,
                },
                bgProps: {
                  show: false,
                },
                padding: "8px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 0,
                  order: 6,
                },
                bgProps: {
                  show: false,
                },
                padding: "8px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 4,
                  order: 6,
                },
                bgProps: {
                  show: false,
                },
                padding: "8px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    layout: Layout13,
    childOf: "Row",
    children: [
      "VdKJ7tnl",
      "NWfOXBaJ",
      "VRVj5O+4",
      "RKp/taKu",
      "1uzzM7YV",
      "Y1JsZty0",
    ],
    myContent: [
      {
        id: "/Aim9oWs",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>Resources</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 10px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 12,
              textAlign: "left",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 10px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "RKp/taKu",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "RKp/taKu",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "1dZ5MUh3",
        type: "MultiContentGroupView",
        props: {
          values: {
            list: [
              {
                id: "dp2o7",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>Size Guides</p>",
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
              {
                id: "80dru",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>Returns and Exchanges</p>",
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 3,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
              {
                id: "7nqe0",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>Shipping Information</p>",
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 2,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
              {
                id: "i4r9",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>Terms of Use</p>",
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 4,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
              {
                id: "641n0",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>Privacy Policy</p>",
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 5,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
              {
                id: "emllc",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>FAQ</p>",
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 6,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
            ],
            type: "MultiContentGroupView",
            mobile: {
              containerPadding: "0px",
            },
            parent: "Content",
            layoutRow: {
              wrap: ["wrap", "nowrap"],
              column: [2, 1],
              alignItems: ["flex-start", "flex-start"],
              flexDirection: ["row", "column"],
              justifyContent: ["flex-start", "flex-start"],
            },
            containerPadding: "0px",
          },
        },
        parent: {
          id: "RKp/taKu",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "RKp/taKu",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "8+LgxUnK",
        type: "MultiContentGroupView",
        props: {
          values: {
            list: [
              {
                id: "dp2o7",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>Company Profile</p>",
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
              {
                id: "80dru",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>Mission Statement</p>",
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 3,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
              {
                id: "7nqe0",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>Why Choose Us</p>",
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 2,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
              {
                id: "i4r9",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>Quality Assurance</p>",
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 4,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
            ],
            type: "MultiContentGroupView",
            mobile: {
              containerPadding: "0px",
            },
            parent: "Content",
            layoutRow: {
              wrap: ["wrap", "nowrap"],
              column: [2, 1],
              alignItems: ["flex-start", "flex-start"],
              flexDirection: ["row", "column"],
              justifyContent: ["flex-start", "flex-start"],
            },
            containerPadding: "0px",
          },
        },
        parent: {
          id: "NWfOXBaJ",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "NWfOXBaJ",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "T0YN++E0",
        type: "MultiContentGroupView",
        props: {
          values: {
            list: [
              {
                id: "dp2o7",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>My Account</p>",
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
              {
                id: "80dru",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>Loyalty Rewards</p>",
                  isLink: true,
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 3,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
              {
                id: "7nqe0",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>Contact Us</p>",
                  isLink: true,
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 2,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
              {
                id: "i4r9",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>Track Your Order</p>",
                  isLink: true,
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 4,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
              {
                id: "641n0",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>E-gift Cards</p>",
                  isLink: false,
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 5,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
            ],
            type: "MultiContentGroupView",
            mobile: {
              containerPadding: "0px",
            },
            parent: "Content",
            layoutRow: {
              wrap: ["wrap", "nowrap"],
              column: [2, 1],
              alignItems: ["flex-start", "flex-start"],
              flexDirection: ["row", "column"],
              justifyContent: ["flex-start", "flex-start"],
            },
            containerPadding: "0px",
          },
        },
        parent: {
          id: "VRVj5O+4",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "VRVj5O+4",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "TfbuZxWq",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>Highlights</strong></p>",
            type: "TextView",
            isLink: false,
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 10px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 12,
              textAlign: "left",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 10px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "1uzzM7YV",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "1uzzM7YV",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "YDfos2bK",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>Services</strong></p>",
            type: "TextView",
            isLink: false,
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 10px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 12,
              textAlign: "left",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 10px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "VRVj5O+4",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "VRVj5O+4",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "frk3I/WU",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>Follow Us</strong></p>",
            type: "TextView",
            isLink: false,
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 10px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 12,
              textAlign: "left",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 10px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "Y1JsZty0",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "Y1JsZty0",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "qvc5dn/g",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>About Us</strong></p>",
            type: "TextView",
            isLink: false,
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 10,
              textAlign: "left",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 10px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 12,
              textAlign: "left",
              lineHeight: "100%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "10px 0px 10px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },
        parent: {
          id: "NWfOXBaJ",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "NWfOXBaJ",
          idx: 0,
          index: 1,
        },
      },
      {
        id: "sTJKio+q",
        type: "MultiContentGroupView",
        props: {
          values: {
            list: [
              {
                id: "bkcuf",
                content: {
                  link: {
                    type: "URL",
                    value: "https://facebook.com",
                    isLink: false,
                    target: "",
                  },
                  type: "facebook",
                  color: "rgba(74, 74, 74, 1)",
                  isLink: true,
                  mobile: {
                    show: true,
                    size: 12,
                    align: "flex-start",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    show: true,
                    size: 12,
                    align: "flex-start",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  showLabel: true,
                },
                typeContent: "social",
              },
              {
                id: "dhf1l",
                content: {
                  link: {
                    type: "URL",
                    value: "https://twitter.com",
                    isLink: false,
                    target: "",
                  },
                  type: "twitter",
                  color: "rgba(74, 74, 74, 1)",
                  isLink: true,
                  mobile: {
                    show: true,
                    size: 12,
                    align: "flex-start",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    show: true,
                    size: 12,
                    align: "flex-start",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  showLabel: true,
                },
                typeContent: "social",
              },
              {
                id: "82gmd",
                content: {
                  link: {
                    type: "URL",
                    value: "https://instagram.com",
                    isLink: false,
                    target: "",
                  },
                  type: "instagram",
                  color: "rgba(0, 0, 0, 1)",
                  isLink: true,
                  mobile: {
                    show: true,
                    size: 12,
                    align: "flex-start",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    show: true,
                    size: 12,
                    align: "flex-start",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  showLabel: true,
                },
                typeContent: "social",
              },
            ],
            type: "MultiContentGroupView",
            mobile: {
              containerPadding: "0px",
            },
            parent: "Content",
            layoutRow: {
              column: [1, 1],
              flexWrap: ["nowrap", "nowrap"],
              alignItems: ["flex-start", "flex-start"],
              flexDirection: ["column", "column"],
              justifyContent: ["flex-start", "flex-start"],
            },
            containerPadding: "0px",
          },
        },
        parent: {
          id: "Y1JsZty0",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "Y1JsZty0",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "uvplEv+8",
        type: "MultiContentGroupView",
        props: {
          values: {
            list: [
              {
                id: "dp2o7",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>Latest Topics</p>",
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 9,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
              {
                id: "80dru",
                content: {
                  link: {
                    type: "URL",
                    value: "",
                    isLink: false,
                    target: "",
                  },
                  text: "<p>Tips &amp; Tricks</p>",
                  isLink: true,
                  mobile: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 3,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                  desktop: {
                    color: "rgba(74, 74, 74, 1)",
                    order: 1,
                    fontSize: 8,
                    lineHeight: "100%",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(255,255,255,0)",
                    containerPadding: "8px 0px 8px 0px",
                  },
                },
                typeContent: "text",
              },
            ],
            type: "MultiContentGroupView",
            mobile: {
              containerPadding: "0px",
            },
            parent: "Content",
            layoutRow: {
              wrap: ["wrap", "nowrap"],
              column: [2, 1],
              alignItems: ["flex-start", "flex-start"],
              flexDirection: ["row", "column"],
              justifyContent: ["flex-start", "flex-start"],
            },
            containerPadding: "0px",
          },
        },
        parent: {
          id: "1uzzM7YV",
          idx: 1,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "1uzzM7YV",
          idx: 1,
          index: 1,
        },
      },
      {
        id: "vNeaghNe",
        type: "ImageView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            type: "ImageView",
            align: "center",
            mobile: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            parent: "Content",
            desktop: {
              show: true,
              width: "100%",
              height: "100%",
              margin: "0px",
              padding: "0px",
            },
            imageUrl: {
              url:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-desktop-SZJ.png",
              mobile: {
                width: 0,
                height: 0,
              },
              desktop: {
                width: 0,
                height: 0,
              },
              urlMobile:
                "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ImageView-image-mobile-IZT.png",
            },
          },
        },
        parent: {
          id: "VdKJ7tnl",
          idx: 0,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "VdKJ7tnl",
          idx: 1,
          index: 1,
        },
      },
    ],
  },
};
