import React, { Component } from "react"
import Slider from "react-slick"
import { Box, Flex, Text } from "rebass"
// import { Icon } from "antd";
import { ContentItemList, ContentItemProduct, ShowAllContent } from "./Items"

let settingsList = (setting, screenWidth, onChange) => {
  if (setting) {
    return {
      dots: setting.dots,
      infinite: setting.infinite,
      speed: setting.speed,
      arrows: setting.arrows,
      slidesToShow:
        screenWidth > 600
          ? setting.slidesToShow
          : setting.slidesToShow > 3
          ? 3
          : setting.slidesToShow,
      slidesToScroll: setting.slidesToScroll,
      autoplay: setting.autoplay,
      autoplaySpeed: setting.autoplaySpeed,
      afterChange: (index) => onChange(index),
    }
  }

  return {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 200,
    slidesToShow:
      screenWidth > 600
        ? setting.slidesToShow
        : setting.slidesToShow > 3
        ? 3
        : setting.slidesToShow,
    slidesToScroll: 1,
    afterChange: (index) => onChange(index),
  }
}

class ListNewMan extends Component {
  constructor(props) {
    super(props)
    // this.next = this.next.bind(this)
    // this.previous = this.previous.bind(this)
    this.state = {
      isIndex: 0,
      isEnd: false,
    }
  }
  // next() {
  //   if (!this.state.isEnd) {
  //     this.sliderone.slickNext()
  //   }
  // }
  // previous() {
  //   if (this.state.isIndex > 0) {
  //     this.sliderone.slickPrev()
  //   }
  // }
  onChange = (index) => {
    const { newMan, screenWidth } = this.props
    const addIndex = screenWidth > 702 ? 6 : 3
    const currentIndex = index + addIndex
    let isEndCurrent = false
    if (currentIndex >= newMan.length) {
      isEndCurrent = true
    }
    this.setState({
      isIndex: index,
      isEnd: isEndCurrent,
    })
  }
  render() {
    const { newMan, screenWidth, newSetting, title, products } = this.props
    const { modeType, urlShowAll, showAll } = newSetting

    const settings = settingsList(newSetting, screenWidth, (index) =>
      this.onChange(index)
    )

    // const colorLeft = this.state.isIndex === 0 ? "#e5e3e6" : "#747374"
    // const colorRight = this.state.isEnd ? "#e5e3e6" : "#747374"
    const listData = products && products.isProduct ? products.list : newMan
    return (
      <Box
        p={[1]}
        style={{ width: screenWidth, backgroundColor: "tranparent" }}
      >
        <Flex
          width={[1]}
          direction={"row"}
          justifyContent={"space-between"}
          p={[1]}
        >
          <Flex
            width={[1]}
            justifyContent={"flex-start"}
            alignItems={"center"}
            flexDirection={"row"}
          >
            <Text fontSize={[14, 18]} fontWeight={"bold"}>
              {title}
            </Text>
            {showAll && <ShowAllContent url={urlShowAll} />}
          </Flex>

          {/* <Flex justify={"row"}>
            <Box pr={[1]}>
              <Icon
                type="left-square"
                size={"lg"}
                onClick={this.previous}
                theme="filled"
                style={{ fontSize: "24px", color: colorLeft }}
              />
            </Box>
            <Box>
              <Icon
                type="right-square"
                size={"lg"}
                theme="filled"
                onClick={this.next}
                style={{ fontSize: "24px", color: colorRight }}
              />
            </Box>
          </Flex> */}
        </Flex>
        <div style={{ backgroundColor: "transparent" }}>
          <Slider
            ref={(c) => (this.sliderone = c)}
            {...settings}
            style={{ backgroundColor: "tranparent" }}
          >
            {listData.map((item, index) => {
              return modeType === "product" ? (
                <ContentItemProduct
                  item={item}
                  key={index}
                  size={[1]}
                  screenWidth={screenWidth}
                  pContainer={[1, 1]}
                />
              ) : (
                <ContentItemList
                  item={item}
                  key={index}
                  size={[1]}
                  screenWidth={screenWidth}
                  pContainer={[1, 1]}
                />
              )
            })}
          </Slider>
        </div>
      </Box>
    )
  }
}

export default ListNewMan
