import React, { Fragment } from "react";
import { Flex, Box } from "rebass";
import { get } from "lodash";
import { Button } from "@material-ui/core";
import { PaperCard } from "../../../../components";
import ViewItem from "./ViewItem";
const RefundReturnList = (props) => {
  const {
    translate,
    onRequestShipment,
    loading,
    dataAmendment,
    // record,
    website,
    listItem,
  } = props;
  //   let titleList = "resources.orders.labels.return_items";
  //   if (dataAmendment.type === "Refund") {
  //     titleList = "resources.orders.labels.items";
  //   }
  //   const list = get(record, "items", []);
  const currency_symbol = get(website, "locale.currency_symbol", "");
  return (
    <Fragment>
      <Box width={[1]} pb={[3]}>
        <PaperCard
          title={translate("resources.orders.labels.items")}
          showButton={false}
          elevation={3}
          bgHeader={"#eeeeee"}
          propsHeader={{ px: [3], py: [2] }}
        >
          <Flex width={[1]} flexDirection={"column"}>
            {listItem.length > 0 &&
              listItem.map((item, index) => {
                return (
                  <ViewItem
                    item={item}
                    key={index}
                    translate={translate}
                    website={website}
                    typeAmendment={dataAmendment.type}
                    currency_symbol={currency_symbol}
                  />
                );
              })}
          </Flex>
        </PaperCard>
      </Box>
      <Button
        variant={"contained"}
        disabled={loading}
        style={{ borderRadius: 24, width: 100 }}
        color={"primary"}
        onClick={onRequestShipment}
      >
        {translate("resources.buttons.save")}
      </Button>
    </Fragment>
  );
};

export default RefundReturnList;
