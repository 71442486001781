import React from "react";
import { SwitchInput, Title, HrefContent } from "../components";
import { get } from "lodash";

import { ContainerPanel, ContentDetail, PanelCard } from "../actions";
import { BoxContent } from "../styled";
/**isShowDesktop: true,
  isShowMobile: true,
  text:
    "<div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>",
  href: {
    url: "",
    target: "",
    isCollection: false,
    collection: 0,
  },
  isLink: false, */

export default (props) => {
  const { state, onSetValue, heightContent, websiteId } = props;
  return (
    <BoxContent style={{ height: heightContent - 120 }}>
      <ContainerPanel>
        <PanelCard title={"General"} id={"text-view-general-1"}>
          <ContentDetail>
            <Title title={"Show Button"} />
            <SwitchInput
              id={`showDesktop`}
              title='Show on Desktop'
              checked={state.isShowDesktop}
              onChange={(e) => onSetValue("isShowDesktop", e.target.checked)}
            />
            <SwitchInput
              id={`showMobile`}
              title='Show on Mobile'
              checked={state.isShowMobile}
              onChange={(e) => onSetValue("isShowMobile", e.target.checked)}
            />
          </ContentDetail>
          <HrefContent
            link={get(state, "link", {})}
            onSetValue={(k, v) => onSetValue(k, v)}
            websiteId={websiteId}
          />
          {/* <SwitchInput
            id={`link-text-url`}
            title={"Use link"}
            checked={get(state, "isLink", false)}
            onChange={(e) => onSetValue("isLink", e.target.checked)}
          />
          {get(state, "isLink", false) && (
            <HrefUrl
              url={get(state, "href.url", "")}
              valueTarget={valueTarget}
              onChangeText={(value) => onUpdateHref("url", value)}
              onChangeSelect={(e) =>
                onUpdateHref(
                  "target",
                  e.target.value === "_blank" ? e.target.value : ""
                )
              }
            />
          )} */}
        </PanelCard>
      </ContainerPanel>
    </BoxContent>
  );
};
