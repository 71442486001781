import React, { Component } from "react"
import PropTypes from "prop-types"
import { uniqBy } from "lodash"
import ChipInput from "./chipinput/ChipInput"
import { Box } from "rebass"
import { dataProvider } from "../../../../restClient"
import { Title } from "./text"

class ProductListInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      values: [],
      dataItems: [],
      productsContent: [],
    }
    this.getCollections()
  }

  getCollections = () => {
    let newValue = []

    if (Array.isArray(this.props.values)) {
      newValue = this.props.values
    }
    dataProvider
      .getList("collections", {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then(response => {
        if (response && response.data) {
          this.setState({
            dataItems: response.data,
            values: this.getChoicesForValues(newValue, response.data),
          })
        }
      })
  }

  // getProductList = () => {
  //   let newValue = []
  //   if (Array.isArray(this.props.values)) {
  //     newValue = this.props.values
  //   }
  //   let params = newValue.join()
  //   const url = "/products?collections=" + params
  //   RestApiProductList(url)
  //     .then(response => {
  //       return response.json()
  //     })
  //     .then(items => {
  //       if (Array.isArray(items.products)) {
  //         this.setState({ productsContent: items })
  //       }
  //     })
  //     .catch(e => {
  //       console.error(e)
  //     })
  // }

  handleAdd = newValue => {
    const values = [...this.state.values, newValue]
    this.setState({ values })
    let newCollections = values.map(o => o.value)
    this.props.onChangeList(newCollections)
  }

  handleDelete = newValue => {
    const values = this.state.values.filter(v => v.value !== newValue)
    this.setState({ values })
    let newCollections = values.map(o => o.value)
    this.props.onChangeList(newCollections)
  }

  getChoicesForValues = (values, choices = []) => {
    if (!values || !Array.isArray(values)) {
      throw Error("Value of SelectArrayChipInput should be an array")
    }
    return values
      .map(value => choices.find(c => c.id === value) || { value, text: value })
      .map(this.formatChoice)
  }
  formatChoices = choices => choices.map(this.formatChoice)
  formatChoice = choice => {
    return {
      value: choice.id,
      text: choice.name,
    }
  }
  onChangeList = (event, value) => {
    const filterValue = uniqBy(value, function(e) {
      return e.value
    })

    this.setState({
      values: filterValue,
    })
    const newCollections = filterValue.map(o => o.value)
    this.props.onChangeList(newCollections)
  }

  render() {
    const { dataItems } = this.state
    return (
      <div>
        {dataItems && dataItems.length > 0 ? (
          <ChipInput
            id="collections"
            title={"Collection Products"}
            placeholder="Products"
            dataSource={this.formatChoices(this.state.dataItems)}
            onChange={this.onChangeList}
            value={this.state.values}
          />
        ) : (
          <Box width={[1]} p={[2]}>
            <Title title={"Data Collections Empty"} />
          </Box>
        )}
      </div>
    )
  }
}

ProductListInput.propTypes = {
  values: PropTypes.array,
  onChangeList: PropTypes.func.isRequired,
  typePage: PropTypes.string,
  marketerId: PropTypes.string,
}

ProductListInput.defaultProps = {
  values: [],
  typePage: "",
  marketerId: "0",
}

export default ProductListInput
