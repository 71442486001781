import React from "react"
import { get } from "lodash"
import { DragSource } from "react-dnd"

const source = {
  beginDrag(props, monitor, component) {
    // console.log(props.content)
    return {
      type: props.type,
      props: {
        values: props.defaultProps.values,
        content: props.defaultProps.content,
      },
      children: [],
      style: {},
      childOf: "Body",
    }
  },
  endDrag(props, monitor, component) {
    // console.log(props)
    // console.log("did not", props.content)
    if (!monitor.didDrop()) {
      // You can check whether the drop was successful
      // or if the drag ended but nobody handled the drop
      return
    }
    const myContent = get(props, "myContent", [])
    const oldDefaultChildren = get(props, "children", [])
    const item = monitor.getItem()
    const myItem = item && item.id ? props.layoutState.getItemJS(item.id) : {}
    const myChildren = get(myItem, "children", [])
    props.layoutState.insertAllMultiContent(
      myContent,
      oldDefaultChildren,
      myChildren
    )

    // const mydata = []
    // Object.keys(props.content).map((key) => {
    //   let value = props.content[key]
    //   if (value && value.childOf === "Row" && value.type !== "MultiRow") {
    //     mydata.push(value)
    //   }
    //   return value
    // })
    // let nextItem = null
    // console.log("item", item)
    // console.log("myItem", myItem)
    // props.layoutState.insertAllMultiContent(
    //   myContent,
    //   oldDefaultChildren,
    //   myChildren
    // )
    // Object.keys(props.content).map((key) => {
    //   let value = props.content[key]
    //   if (value) {
    //     if (
    //       value.type !== "MultiRow" &&
    //       value.type !== "Row2" &&
    //       value.type !== "Row6" &&
    //       value.type !== "Content"
    //     ) {
    //       let { id, children, parent, ...rest } = value
    //       const parentValue = findIndex(oldDefaultChildren, function (o) {
    //         return o === get(parent, "id", 0)
    //       })
    //       const newParentValue = parentValue === -1 ? 0 : parentValue
    //       // console.log(myChildren[newParentValue], parent)
    //       if (nextItem === null) {
    //         nextItem = props.layoutState.insertTemplate(
    //           myChildren[newParentValue],
    //           get(parent, "idx", 0),
    //           rest
    //         )
    //       } else {
    //         //   console.log(nextItem)
    //         nextItem = nextItem.insertTemplate(
    //           myChildren[newParentValue],
    //           get(parent, "idx", 0),
    //           rest
    //         )
    //       }
    //       // return ""
    //     }
    //   }
    //   return ""
    // })
  },
}

const ThemeMulti = ({
  type,
  component,
  connectDragSource,
  defaultProps,
  layout,
}) =>
  connectDragSource(
    <div
      style={{
        backgroundColor: "#efefef",
        color: "#eee",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          backgroundColor: "transparent",
          color: "#eee",
          padding: 4,
        }}
      >
        {React.createElement(layout, defaultProps)}
      </div>
    </div>
  )

export default DragSource("Body", source, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
}))(ThemeMulti)
