import React from "react"
import { Box } from "rebass"
import { FormDataConsumer } from "react-admin"
import { BetterCustomSimpleForm, EditToolbar } from "../../../components"
import CreateFormFields from "./CreateFormFields"

const NewTopic = (props) => {
  return (
    <Box>
      <FormDataConsumer>
        {({ formData }) => {
          return <CreateFormFields {...props} formData={formData} />
        }}
      </FormDataConsumer>
    </Box>
  )
}

export const CreateForm = (props) => {
  return (
    <BetterCustomSimpleForm
      {...props}
      title={"resources.faq.new_title"}
      labelBack={"resources.faq.new_title"}
      toolbar={<EditToolbar />}
      isShowBack={false}
    >
      <NewTopic {...props} isCreate={true} />
    </BetterCustomSimpleForm>
  )
}
