import React, { useState } from "react";
import PropTypes from "prop-types";
// import { has } from "lodash";
import { Flex } from "rebass";
import {
  FooterAction,
  PanelCard,
  ContainerPanel,
  ContentDetail,
} from "../actions";
import RowIconTextFields from "./RowIconTextFields";
import { SketchColorPicker, LayoutRowComponent, Title } from "../components";
import { UploadAvatar } from "../../../components";
import { defaultRowIconText } from "./utils";
import { BoxContent } from "../styled";
const RowIconText = (props) => {
  const {
    backgroundColor,
    title,
    icon,
    subtitle,
    layoutRow,
    isShowDesktop,
    isShowMobile,
  } = props.data.values;

  const [state, setState] = useState({
    layoutRow: layoutRow || defaultRowIconText.layoutRow,
    backgroundColor: backgroundColor || "transparent",
    title: title || defaultRowIconText.title,
    icon: icon || "",
    subtitle: subtitle || defaultRowIconText.subtitle,
    isShowDesktop: isShowDesktop,
    isShowMobile: isShowMobile,
  });

  const onSetValue = (k, v) => {
    setState({
      ...state,
      [k]: v,
    });
  };
  const onSetTitleValue = (k, v) => {
    const params = {
      ...state.title,
      [k]: v,
    };
    onSetValue("title", params);
  };
  const onSetSubTitleValue = (k, v) => {
    const params = {
      ...state.subtitle,
      [k]: v,
    };
    onSetValue("subtitle", params);
  };

  const onClickCopy = () => {
    let newData = props.data;
    newData.values.icon = state.icon;
    newData.values.backgroundColor = state.backgroundColor;
    newData.values.layoutRow = state.layoutRow;
    newData.values.title = state.title;
    newData.values.subtitle = state.subtitle;
    newData.values.isShowDesktop = state.isShowDesktop;
    newData.values.isShowMobile = state.isShowMobile;
    localStorage.setItem("RowIconText", JSON.stringify(newData.values));
  };
  const onClickPaste = () => {
    const rowIconTextF = JSON.parse(localStorage.getItem("RowIconText"));
    if (rowIconTextF) {
      const {
        backgroundColor,
        title,
        icon,
        subtitle,
        layoutRow,
        isShowDesktop,
        isShowMobile,
      } = rowIconTextF;
      setState({
        backgroundColor: backgroundColor,
        title: title,
        icon: icon,
        subtitle: subtitle,
        layoutRow: layoutRow,
        isShowDesktop: isShowDesktop,
        isShowMobile: isShowMobile,
      });
    }
  };
  const onClickSave = () => {
    let newData = props.data;
    newData.values.isShowDesktop = state.isShowDesktop;
    newData.values.isShowMobile = state.isShowMobile;
    newData.values.backgroundColor = state.backgroundColor;
    newData.values.subtitle = state.subtitle;
    newData.values.title = state.title;
    newData.values.icon = state.icon;
    newData.values.layoutRow = state.layoutRow;
    props.onUpdateData(newData);
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <BoxContent style={{ height: props.heightContent - 120 }}>
        <ContainerPanel>
          <ContentDetail>
            <Title title='Upload Icon' />
            <UploadAvatar
              type={"iconrowtext"}
              imageUrl={state.icon}
              onUpdateUrl={(value) => onSetValue("icon", value)}
              title='Image'
            />
            <SketchColorPicker
              title={"Background Color"}
              onUpdateColor={(color) => onSetValue("backgroundColor", color)}
              color={state.backgroundColor}
            />
          </ContentDetail>
          <PanelCard title={`Setting Layout`} id={`properties-setting-layout`}>
            <LayoutRowComponent
              layoutRow={state.layoutRow}
              onUpdateLayoutRow={(k, v) => onSetValue(k, v)}
            />
          </PanelCard>
          <PanelCard title={`Title Layout`} id={`properties-Title-layout`}>
            <RowIconTextFields
              value={state.title}
              onSetValue={(k, v) => onSetTitleValue(k, v)}
            />
          </PanelCard>
          <PanelCard
            title={`Subtitle Layout`}
            id={`properties-Subtitle-layout`}
          >
            <RowIconTextFields
              value={state.subtitle}
              onSetValue={(k, v) => onSetSubTitleValue(k, v)}
            />
          </PanelCard>
        </ContainerPanel>
      </BoxContent>
      <FooterAction
        onClickSave={onClickSave}
        onClickCopy={onClickCopy}
        onClickPaste={onClickPaste}
        widthContent={props.widthContent}
      />
    </Flex>
  );
};

RowIconText.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

RowIconText.defaultProps = {
  data: {},
};
export default RowIconText;
