import React from "react";
import NumberFormat from "react-number-format";

const Currency = ({ value, unit }) => {
  const prefix = unit || "Rp";
  return (
    <NumberFormat
      displayType='text'
      thousandSeparator={","}
      decimalSeparator={"."}
      prefix={prefix}
      value={value}
    />
  );
};

export default Currency;
