import React, { Fragment } from "react";
import { Flex } from "rebass";
import {
  BlockCounter,
  Counter,
  BackgroundContent,
  PixelPercent,
  SwitchInput,
  Direction,
  DialogGradient,
} from "../components";
import { PanelCard, ContentDetail } from "../actions";
import MobileValueFields from "./MobileValueFields";
export const RowFields = (props) => {
  const {
    state,
    onChangeType,
    onSetValue,
    updateBackground,
    onChangeTypeHeight,
  } = props;
  const {
    backgroundColor,
    backgroundImage,
    isBackgroundImage,
    bgProps,
  } = state;
  return (
    <Fragment>
      <PanelCard title='Mobile Settings' id={"properties-row-mobile"}>
        <MobileValueFields {...props} />
      </PanelCard>
      <PanelCard title='Desktop Settings' id={"properties-row"}>
        <Flex flexDirection={"column"}>
          <BlockCounter
            containerPadding={state.containerPadding}
            onUpdatePadding={(value) => onSetValue("containerPadding", value)}
            title={"Content Padding"}
          />
          <ContentDetail>
            <PixelPercent
              name='type'
              value={state.type}
              onChange={onChangeType}
              title='Container Width'
            />

            <Counter
              isDisabled={false}
              onSetValue={(value) => onSetValue("contentWidth", value)}
              value={state.contentWidth}
              interval={state.interval}
              maxValue={state.maxValueWidth}
            />
            <PixelPercent
              name='typeHeight'
              value={state.typeHeight}
              onChange={onChangeTypeHeight}
              title='Container Height'
            />
            <Counter
              isDisabled={false}
              onSetValue={(value) => onSetValue("contentHeight", value)}
              value={state.contentHeight}
              interval={state.intervalHeight}
              maxValue={state.maxValueHeight}
            />
          </ContentDetail>
          {/* <ContentDetail>
            <SwitchInput
              id={`bg-show-desktop`}
              title={"Is bg show on Desktop"}
              checked={state.isDesktopHeight}
              onChange={(e, v) => onToggle(e, v, "desktop")}
            />
            <SwitchInput
              id={`bg-show-mobile`}
              title={"Is bg show on mobile"}
              checked={state.isMobileHeight}
              onChange={(e, v) => onToggle(e, v, "mobile")}
            />
          </ContentDetail> */}
          <ContentDetail>
            <SwitchInput
              id={`use-bg-gradient-body`}
              title={"Use Background Gradient"}
              checked={state.isGradient}
              onChange={(e) => onSetValue("isGradient", e.target.checked)}
            />
            {state.isGradient && (
              <DialogGradient
                onSetValue={(gradient) => onSetValue("gradient", gradient)}
                gradient={state.gradient}
              />
            )}
          </ContentDetail>
          <BackgroundContent
            backgroundColor={backgroundColor}
            backgroundImage={backgroundImage}
            isBackgroundImage={isBackgroundImage}
            bgProps={bgProps}
            updateBackground={updateBackground}
          />
        </Flex>
      </PanelCard>
    </Fragment>
  );
};

export const RowFieldsCarousel = (props) => {
  const {
    state,
    onChangeType,
    onSetValue,
    onToggle,
    updateBackground,
    onUpdateInterval,
    onChangeDirection,
    carousel,
  } = props;
  const {
    backgroundColor,
    backgroundImage,
    isBackgroundImage,
    bgProps,
  } = state;
  return (
    <PanelCard title='Properties of Row' id={"properties-row1"}>
      <Flex flexDirection={"column"}>
        <BlockCounter
          containerPadding={state.containerPadding}
          onUpdatePadding={(value) => onSetValue("containerPadding", value)}
          title={"Content Padding"}
        />
        <ContentDetail>
          <PixelPercent
            name='type'
            value={state.type}
            onChange={onChangeType}
            title='Container Width'
          />

          <Counter
            isDisabled={false}
            onSetValue={(value) => onSetValue("contentWidth", value)}
            value={state.contentWidth}
            interval={state.interval}
            maxValue={state.maxValueWidth}
          />
        </ContentDetail>
        <ContentDetail>
          <Direction
            name='direction'
            title='Properties'
            value={carousel.direction}
            onChange={onChangeDirection}
          />
        </ContentDetail>
        <ContentDetail>
          <SwitchInput
            id={`autoplay-row-carousel`}
            title={"Auto Play"}
            checked={carousel.auto}
            onChange={(e) => onToggle(e)}
          />
          <Counter
            isDisabled={false}
            title='Interval'
            onSetValue={(value) => onUpdateInterval(value)}
            value={carousel.interval}
            interval={1}
            subtitle='second'
            minValue={0}
          />
        </ContentDetail>
        <ContentDetail>
          <SwitchInput
            id={`use-bg-gradient-body`}
            title={"Use Background Gradient"}
            checked={state.isGradient}
            onChange={(e) => onSetValue("isGradient", e.target.checked)}
          />
          {state.isGradient && (
            <DialogGradient
              onSetValue={(gradient) => onSetValue("gradient", gradient)}
              gradient={state.gradient}
            />
          )}
        </ContentDetail>
        <BackgroundContent
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage}
          isBackgroundImage={isBackgroundImage}
          bgProps={bgProps}
          updateBackground={updateBackground}
        />
      </Flex>
    </PanelCard>
  );
};
