import React from "react";
//import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd";
import { connect } from "../../core";
import { target } from "./utils";
import { get } from "lodash";
import { Flex } from "rebass";
import { SocialMediaList } from "./components/socialmedia";
const EditLayout = ({
  id,
  children,
  isOverCurrent,
  connectDropTarget,
  type,
  layoutState,
  readOnly,
}) => {
  const { desktop, list } = layoutState.getItemJS(id).props.values;
  //size, align, list

  const containerPadding = get(desktop, "containerPadding", "0px");
  const size = get(desktop, "size", 16);
  const align = get(desktop, "align", "center");
  return connectDropTarget(
    <div
      style={{
        boxSizing: "border-box",
        position: "relative",
        height: "100%",
        display: "flex",
        padding: containerPadding,
        flex: 1,
      }}
    >
      {get(desktop, "show", false) ? (
        <SocialMediaList list={list} size={size} align={align} />
      ) : (
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"center"}
          style={{ textAlign: "center" }}
        >
          Click Here to Edit
        </Flex>
      )}
    </div>
  );
};

const LayoutContainer = DropTarget(
  "SocialMediaView",
  target,
  (conn, monitor) => ({
    connectDropTarget: conn.dropTarget(),
    isOverCurrent: monitor.isOver({ shallow: true }),
  })
)(EditLayout);

export default connect("layoutState", "readOnly")(LayoutContainer);
