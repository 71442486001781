import React from "react"
import { Flex } from "rebass"
import { useTranslate, FormDataConsumer } from "react-admin"
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  PromotionFields,
  CriteriaList,
} from "../components"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ActionList from "./ActionList"
import CouponSettings from "./CouponSettings"

const FormFields = props => {
  const translate = useTranslate()
  return (
    <Flex>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <Flex width={[1]} flexDirection={"column"}>
            <PromotionFields
              formData={formData}
              translate={translate}
              {...props}
            />
            <Flex width={1} flexDirection={"column"} mt={[2]}>
              <CriteriaList
                {...props}
                translate={translate}
                formData={formData}
              />
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="action-content"
                  id="action-header"
                >
                  <Typography>
                    {translate("resources.promotions.panel.action")}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ActionList
                    {...props}
                    translate={translate}
                    formData={formData}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="coupon-settings-content"
                  id="coupon-settings-header"
                >
                  <Typography>
                    {translate("resources.promotions.panel.coupon_settings")}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <CouponSettings
                    {...props}
                    translate={translate}
                    formData={formData}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Flex>
          </Flex>
        )}
      </FormDataConsumer>
    </Flex>
  )
}

export default FormFields
