import React from "react"

import { Flex, Image, Text } from "rebass"
import { Button } from "@material-ui/core"
const ProductGroupCard = (props) => {
  const { product, widthContainer } = props
  const fontSize = widthContainer <= 480 ? "10px" : 2
  const fontWeight = widthContainer <= 480 ? "normal" : "bold"
  return (
    <Flex
      px={[2]}
      py={[1]}
      width={[1]}
      flexDirection={"column"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Image src={product.photos} p={[1]} />
      <Text
        fontSize={["10px", fontSize]}
        fontWeight={["normal", fontWeight]}
        textAlign={"center"}
        p={[3]}
      >
        {product.name}
      </Text>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"center"}>
        <Button variant="outlined" color={"primary"}>
          Shop now
        </Button>
      </Flex>
    </Flex>
  )
}

export default ProductGroupCard
