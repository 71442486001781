import React from "react";
import { Flex, Text } from "rebass";
import { get, without } from "lodash";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageEye from "@material-ui/icons/RemoveRedEye";
import { red, blue } from "@material-ui/core/colors";
import { getUrlEndPoint } from "../../../utils";

const ProductItemFields = (props) => {
  const { record, onDeleteProduct } = props;
  const endpointWeb = getUrlEndPoint();

  return (
    <Flex
      width={[1]}
      flexDirection='row'
      justifyContent={"flex-start"}
      alignItems={"center"}
      py={[1]}
      style={{ borderBottom: `1px solid #EEEEEE` }}
    >
      <Flex
        flexDirection='row'
        justifyContent={"flex-start"}
        alignItems={"center"}
        width={[1]}
      >
        <Text pl={[2]}>{record}</Text>
      </Flex>
      <Flex
        px={[2]}
        flexDirection='row'
        justifyContent={"flex-end"}
        alignItems={"center"}
        sx={{
          "&>*": {
            px: [2],
          },
        }}
      >
        <div style={{ paddingTop: 8 }}>
          <a
            href={`${endpointWeb}/${record}`}
            target={"_blank"}
            rel='noopener noreferrer'
          >
            <ImageEye style={{ color: blue[900] }} />
          </a>
        </div>
        <IconButton onClick={() => onDeleteProduct()}>
          <DeleteIcon style={{ color: red[900] }} />
        </IconButton>
      </Flex>
    </Flex>
  );
};

const TableProductsSKU = (props) => {
  const { formData, onDeleteProduct } = props;
  const products = get(formData, `products`, []);
  const list = without(products, undefined);
  return (
    <Flex width={[1]} flexDirection={"column"}>
      {list.length > 0 && (
        <Flex
          width={[1]}
          flexDirection='row'
          justifyContent={"flex-start"}
          alignItems={"center"}
          py={[1]}
          style={{ borderBottom: `1px solid #EEEEEE` }}
        >
          <Flex
            flexDirection='row'
            justifyContent={"flex-start"}
            alignItems={"center"}
            width={[1]}
          >
            <Text pl={[2]} style={{ fontWeight: "bold" }}>
              {/* ID */}
              SLUG
            </Text>
          </Flex>
          <Flex
            px={[2]}
            flexDirection='row'
            justifyContent={"flex-end"}
            alignItems={"center"}
            sx={{
              "&>*": {
                px: [2],
              },
            }}
          >
            <div />
          </Flex>
        </Flex>
      )}
      {list.length > 0 &&
        list.map((item) => {
          return (
            <ProductItemFields
              key={item}
              record={item}
              onDeleteProduct={() => onDeleteProduct(item)}
            />
          );
        })}
    </Flex>
  );
};

export default TableProductsSKU;
