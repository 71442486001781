import React from "react";
import { Box } from "rebass";
import { useTranslate } from "react-admin";
import { ImageOlaSingleInput } from "../../../components";
/**
 * 
 banner: {
        title: "Banners Setting",
        login: {
          title: "Login Page",
          url: "Url Banner",
        },
      },
 */
const BannerContent = (props) => {
  const { title, urlTitle, formData, sourceBanner, ...rest } = props;
  const translate = useTranslate();
  return (
    <Box width={[1]}>
      <ImageOlaSingleInput
        {...rest}
        source={`${sourceBanner}.urlImg`}
        toValue={"products"}
        labelImage={translate(title)}
        label={title}
        accept='image/*'
        record={formData}
        basePath={"/adbanner"}
      />
      {/* <Text>{urlTitle}</Text> */}
    </Box>
  );
};

export default BannerContent;
