import React, { useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Flex, Box } from "rebass";
import { BoxContent } from "../styled";
import { PanelImage } from "./Fields";
import { FooterAction, ContainerPanel, PanelCard } from "../actions";
import { defaultImage } from "./utils";
import ImageDesktopFields from "./ImageDesktopFields";
import ImageMobileFields from "./ImageMobileFields";
import { HrefContent } from "../components";
const ImageView = (props) => {
  const { desktop, mobile, imageUrl, align, href, link } = props.data.values;
  const [state, setState] = useState({
    desktop: {
      show: get(desktop, "show", true),
      width: get(desktop, "width", "100%"),
      height: get(desktop, "height", "100%"),
      margin: get(desktop, "margin", "0px"),
      padding: get(desktop, "padding", "0px"),
      isFullHeight: get(desktop, "isFullHeight", false),
    },
    mobile: {
      show: get(mobile, "show", true),
      width: get(mobile, "width", "100%"),
      height: get(mobile, "height", "100%"),
      margin: get(mobile, "margin", "0px"),
      padding: get(mobile, "padding", "0px"),
      isFullHeight: get(mobile, "isFullHeight", false),
    },
    imageUrl: imageUrl || defaultImage.imageUrl,
    href: href || defaultImage.href,
    align: align || defaultImage.align,
    link: link || {
      target: get(href, "target", "") === "same" ? "" : get(href, "target", ""),
      type: "URL",
      value: get(href, "url", ""),
      isLink: false,
    },
  });

  const onClickSave = () => {
    let newData = props.data;
    newData.values.align = state.align;
    newData.values.link = state.link;
    newData.values.imageUrl = state.imageUrl;

    if (state.desktop.show) {
      newData.values.desktop = state.desktop;
    }
    if (state.mobile.show) {
      newData.values.mobile = state.mobile;
    }

    props.onUpdateData(newData);
  };
  const onClickCopy = () => {
    let newData = props.data;
    newData.values.align = state.align;
    newData.values.link = state.link;
    newData.values.imageUrl = state.imageUrl;
    if (state.desktop.show) {
      newData.values.desktop = state.desktop;
    }
    if (state.mobile.show) {
      newData.values.mobile = state.mobile;
    }
    localStorage.setItem("ImageView", JSON.stringify(newData.values));
  };
  const onClickPaste = () => {
    const imageContent = JSON.parse(localStorage.getItem("ImageView"));
    if (imageContent && imageContent !== null) {
      const { desktop, mobile, imageUrl, align, link } = imageContent;
      setState({
        ...state,
        desktop: {
          show: get(desktop, "show", true),
          width: get(desktop, "width", "100%"),
          height: get(desktop, "height", "100%"),
          margin: get(desktop, "margin", "0px"),
          padding: get(desktop, "padding", "0px"),
        },
        mobile: {
          show: get(mobile, "show", true),
          width: get(mobile, "width", "100%"),
          height: get(mobile, "height", "100%"),
          margin: get(mobile, "margin", "0px"),
          padding: get(mobile, "padding", "0px"),
        },
        imageUrl: imageUrl || defaultImage.imageUrl,
        link: link,
        align: align || defaultImage.align,
      });
    }
  };

  const onSetValue = (k, v) => {
    setState({
      ...state,
      [k]: v,
    });
  };
  const onSetDesktopValue = (k, v) => {
    const newDesktop = {
      ...state.desktop,
      [k]: v,
    };
    onSetValue("desktop", newDesktop);
  };

  const onSetMobileValue = (k, v) => {
    const newMobile = {
      ...state.mobile,
      [k]: v,
    };
    onSetValue("mobile", newMobile);
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <BoxContent style={{ height: props.heightContent - 120 }}>
        <ContainerPanel>
          <PanelCard title={`Desktop Setting`} id={`desktop-imageview-setting`}>
            <ImageDesktopFields
              state={state.desktop}
              onSetValue={(k, v) => onSetDesktopValue(k, v)}
            />
          </PanelCard>
          <PanelCard title={`Mobile Setting`} id={`mobile-imageview-setting`}>
            <ImageMobileFields
              state={state.mobile}
              onSetValue={(k, v) => onSetMobileValue(k, v)}
            />
          </PanelCard>
          <PanelImage
            state={state}
            onSetValue={(k, v) => onSetValue(k, v)}
            data={props.data}
          />
          <Box width={[1]} py={[2]}>
            <HrefContent
              link={get(state, "link", {})}
              onSetValue={(k, v) => onSetValue(k, v)}
              websiteId={props.websiteId}
            />
          </Box>
        </ContainerPanel>
      </BoxContent>
      <FooterAction
        onClickSave={() => onClickSave()}
        onClickCopy={() => onClickCopy()}
        onClickPaste={() => onClickPaste()}
        widthContent={props.widthContent}
      />
    </Flex>
  );
};

ImageView.propTypes = {
  data: PropTypes.object,
  onUpdateData: PropTypes.func.isRequired,
};

ImageView.defaultProps = {
  data: {},
};
export default ImageView;
