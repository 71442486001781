export const defaultItemProps = {
  backgroundColorParent: "rgba(255,255,255,0)",
  backgroundColor: "rgba(255,255,255,0)",
  backgroundImage: {
    url: "",
    urlMobile: "",
    desktop: {
      width: 0,
      height: 0,
    },
    mobile: {
      width: 0,
      height: 0,
    },
  },
  bgProps: {
    size: "cover",
    position: "center",
    repeat: "no-repeat",
    ratio: 1.2,
  },
  isBackgroundImage: false,
  padding: "0px",
  width: {
    type: "%",
    contentWidth: 100,
  },
  alignment: {
    vertical: "center",
    horizontal: "center",
  },
}
