import React, { useState } from "react"
import PropTypes from "prop-types"
import Layout from "../dnd"
import { Layout3 } from "../ContentBox"
import { DivHover, DivHelp } from "./styled"

const ImageTextView = props => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(true)
  }
  const onMouseOver = () => {
    setHover(false)
  }

  return (
    <DivHover onMouseLeave={() => onMouseOver()} onMouseEnter={() => onHover()}>
      <Layout {...props} type="ImageTextView" />
      {hover && <DivHelp>Content</DivHelp>}
    </DivHover>
  )
}

ImageTextView.propTypes = {
  childOf: PropTypes.string,
  values: PropTypes.object,
  cell: PropTypes.array,
  layout: PropTypes.func,
}
/*

*/
ImageTextView.defaultProps = {
  childOf: "Content",
  values: {
    parent: "Content",
    type: "ImageTextView",
    containerPadding: "0px",
    backgroundColor: "rgba(255,255,255,0)",
    text: {
      containerPadding: "0px",
      backgroundColor: "rgba(255,255,255,0)",
      lineHeight: "120%",
      letterSpacing: "1px",
      value:
        "<div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>",
    },
    backgroundImage: {
      url: "",
      urlMobile: "",
      desktop: {
        width: 0,
        height: 0,
      },
      mobile: {
        width: 0,
        height: 0,
      },
    },
  },
  layout: Layout3,
}
export default ImageTextView
