import React from "react"
import { Flex } from "rebass"
import { ContentDetail } from "../../actions"
import { Title, TextInput, SelectInput, dataHref } from "../"
const HrefUrl = props => {
  const { onChangeText, onChangeSelect, valueTarget, url } = props
  return (
    <ContentDetail>
      <Title title={"Action"} />
      <Flex width={[1]}>
        <TextInput
          value={url}
          onChange={value => onChangeText(value)}
          name="HrefUrl"
          title="Url"
          fullWidth={true}
        />
      </Flex>

      <SelectInput
        id={`target`}
        title={"Target"}
        value={valueTarget}
        list={dataHref}
        onChange={e => onChangeSelect(e)}
      />
    </ContentDetail>
  )
}

export default HrefUrl
