import React from "react";
import { Flex } from "rebass";
const NoAccessPage = (props) => {
  return (
    <Flex
      width={[1]}
      justifyContent={"center"}
      alignItems={"center"}
      style={{ height: "85vh" }}
    >
      {/* <Image src={require("../assets/images/e-buy.png")} width={320} /> */}
    </Flex>
  );
};
export default NoAccessPage;
