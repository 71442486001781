import React from "react";
import { showNotification, useQuery } from "react-admin";
import { withRouter } from "react-router-dom";
import { Box } from "rebass";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { EditForm } from "./components";
import { CardPanel } from "../websites/components/CardPanel";
import { FullLoadingScreen } from "../../components";

const LayoutEditHomePage = (props) => {
  const { match } = props;
  const { data, loading } = useQuery({
    type: "getOne",
    resource: "layouts",
    payload: { id: match.params.id },
  });

  if (loading) {
    return <FullLoadingScreen />;
  }

  return (
    <CardPanel>
      <Box width={[1]}>
        <EditForm
          {...props}
          sourceApi='layouts'
          typePostUrl={"UPDATE"}
          pathUrl='layouts'
          initialValues={data}
          showcontent={"home"}
        />
      </Box>
    </CardPanel>
  );
};

const enhance = compose(withRouter, connect(undefined, { showNotification }));

export default enhance(LayoutEditHomePage);
