import { Layout14 } from "../../TemplateImageSonak";
export const template14A = {
  "h9YAP2b+": {
    id: "h9YAP2b+",
    type: "Row2",
    props: {
      values: {
        name: "Row2",
        type: "Row",
        mobile: {
          show: true,
          width: "100%",
          column: 1,
          height: "100%",
          layout: {
            wrap: true,
            align: "middle",
            gutter: 20,
            justify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "20px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        parent: "Body",
        desktop: {
          show: true,
          width: "1080px",
          column: 1,
          height: "100%",
          layout: {
            wrap: false,
            align: "top",
            gutter: 20,
            justify: "center",
            contentAlign: "center",
            contentJustify: "center",
          },
          bgProps: {
            show: false,
          },
          padding: "0px",
          gradient: {
            show: false,
          },
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      },
      content: [
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 1,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-I3D.jpeg",
                    size: "cover",
                    ratio: 1.5,
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 1,
                },
                bgProps: {
                  show: true,
                  content: {
                    url:
                      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/ease-image-desktop-I3D.jpeg",
                    size: "cover",
                    ratio: 1.5,
                    repeat: "no-repeat",
                    position: "center",
                  },
                },
                padding: "0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
        {
          type: "Content",
          props: {
            values: {
              type: "Content",
              mobile: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 24,
                  order: 2,
                },
                bgProps: {
                  show: false,
                },
                padding: "20px 0px 20px 0px",
                gradient: {
                  show: false,
                },
                backgroundColor: "transparent",
              },
              parent: "Row",
              desktop: {
                width: "100%",
                height: "100%",
                layout: {
                  span: 12,
                  order: 2,
                },
                bgProps: {
                  show: false,
                },
                padding: "0px 0px 0px 50px",
                gradient: {
                  show: false,
                },
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
          childOf: "Row",
          children: [],
        },
      ],
    },
    layout: Layout14,
    childOf: "Row",
    parent: {
      id: "root",
      idx: 0,
    },
    children: ["yUEz4j7t", "+VeSVP5V"],
    myContent: [
      {
        id: "sHmRNhXP",
        type: "TextView",
        props: {
          values: {
            link: {
              type: "URL",
              value: "",
              isLink: false,
              target: "",
            },
            text: "<p><strong>OUR VALUES</strong></p>",
            type: "TextView",
            mobile: {
              color: "rgba(0,0,0,1)",
              fontSize: 16,
              textAlign: "center",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px 0px 16px 0px",
            },
            parent: "Content",
            desktop: {
              color: "rgba(0,0,0,1)",
              fontSize: 18,
              textAlign: "left",
              lineHeight: "120%",
              letterSpacing: "1px",
              backgroundColor: "rgba(255,255,255,0)",
              containerPadding: "0px 0px 20px 0px",
            },
            isShowMobile: true,
            isShowDesktop: true,
          },
        },

        parent: {
          id: "+VeSVP5V",
          idx: 4,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "+VeSVP5V",
          idx: 4,
          index: 1,
        },
      },
      {
        id: "Uq3e5oQn",
        type: "RowIconText",
        props: {
          values: {
            icon:
              "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/iconrowtext-image-desktop-ATE.jpeg",
            type: "RowIconText",
            title: {
              text: "<p><strong>INSPIRE</strong></p>",
              color: "rgba(0,0,0,1)",
              fontSize: [12, 14],
              textAlign: ["left", "left"],
              lineHeight: ["100%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["0px", "0px"],
            },
            parent: "Content",
            subtitle: {
              text:
                "<p>To inspire customers in taking on sporting challenges with the right gear.</p>",
              color: "rgba(0,0,0,1)",
              fontSize: [10, 10],
              textAlign: ["left", "left"],
              lineHeight: ["120%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["8px 0px 16px 0px", "8px 0px 16px 0px"],
            },
            layoutRow: {
              flexWrap: ["nowrap", "nowrap"],
              alignItems: ["flex-start", "flex-start"],
              flexDirection: ["row", "row"],
              justifyContent: ["flex-start", "flex-start"],
            },
            isShowMobile: true,
            isShowDesktop: true,
            backgroundColor: "rgba(255,255,255,0)",
          },
        },

        parent: {
          id: "+VeSVP5V",
          idx: 4,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "+VeSVP5V",
          idx: 4,
          index: 1,
        },
      },
      {
        id: "FEwIk0ir",
        type: "RowIconText",
        props: {
          values: {
            icon:
              "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/iconrowtext-image-desktop-NM1.jpeg",
            type: "RowIconText",
            title: {
              text: "<p><strong>COMMITMENT</strong></p>",
              color: "rgba(0,0,0,1)",
              fontSize: [12, 14],
              textAlign: ["left", "left"],
              lineHeight: ["100%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["0px", "0px"],
            },
            parent: "Content",
            subtitle: {
              text:
                "<p>We are committed to delivering outstanding services to our customers.</p>",
              color: "rgba(0,0,0,1)",
              fontSize: [10, 10],
              textAlign: ["left", "left"],
              lineHeight: ["120%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["8px 0px 16px 0px", "8px 0px 16px 0px"],
            },
            layoutRow: {
              flexWrap: ["nowrap", "nowrap"],
              alignItems: ["flex-start", "flex-start"],
              flexDirection: ["row", "row"],
              justifyContent: ["flex-start", "flex-start"],
            },
            isShowMobile: true,
            isShowDesktop: true,
            backgroundColor: "rgba(255,255,255,0)",
          },
        },
        parent: {
          id: "+VeSVP5V",
          idx: 4,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "+VeSVP5V",
          idx: 4,
          index: 1,
        },
      },
      {
        id: "ytsZI0vQ",
        type: "RowIconText",
        props: {
          values: {
            icon:
              "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/iconrowtext-image-desktop-O52.jpeg",
            type: "RowIconText",
            title: {
              text: "<p><strong>QUALITY ASSURANCE</strong></p>",
              color: "rgba(0,0,0,1)",
              fontSize: [12, 14],
              textAlign: ["left", "left"],
              lineHeight: ["100%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["0px", "0px"],
            },
            parent: "Content",
            subtitle: {
              text:
                "<p>We take pride in providing high value products that we stand behind.</p>",
              color: "rgba(0,0,0,1)",
              fontSize: [10, 10],
              textAlign: ["left", "left"],
              lineHeight: ["120%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["8px 0px 16px 0px", "8px 0px 16px 0px"],
            },
            layoutRow: {
              flexWrap: ["nowrap", "nowrap"],
              alignItems: ["flex-start", "flex-start"],
              flexDirection: ["row", "row"],
              justifyContent: ["flex-start", "flex-start"],
            },
            isShowMobile: true,
            isShowDesktop: true,
            backgroundColor: "rgba(255,255,255,0)",
          },
        },

        parent: {
          id: "+VeSVP5V",
          idx: 4,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "+VeSVP5V",
          idx: 4,
          index: 1,
        },
      },
      {
        id: "HqGiT4Vv",
        type: "RowIconText",
        props: {
          values: {
            icon:
              "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/1/content/iconrowtext-image-desktop-XMH.jpeg",
            type: "RowIconText",
            title: {
              text: "<p><strong>INTEGRITY</strong></p>",
              color: "rgba(0,0,0,1)",
              fontSize: [12, 14],
              textAlign: ["left", "left"],
              lineHeight: ["100%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["0px", "0px"],
            },
            parent: "Content",
            subtitle: {
              text:
                "<p>We upload the highest standards of integrity in all of our actions.</p>",
              color: "rgba(0,0,0,1)",
              fontSize: [10, 10],
              textAlign: ["left", "left"],
              lineHeight: ["120%", "100%"],
              letterSpacing: ["1px", "1px"],
              containerPadding: ["8px 0px 16px 0px", "8px 0px 0px 0px"],
            },
            layoutRow: {
              flexWrap: ["nowrap", "nowrap"],
              alignItems: ["flex-start", "flex-start"],
              flexDirection: ["row", "row"],
              justifyContent: ["flex-start", "flex-start"],
            },
            isShowMobile: true,
            isShowDesktop: true,
            backgroundColor: "rgba(255,255,255,0)",
          },
        },
        parent: {
          id: "+VeSVP5V",
          idx: 4,
        },
        childOf: "Content",
        children: [],
        myParent: {
          id: "+VeSVP5V",
          idx: 4,
          index: 1,
        },
      },
    ],
  },
};
