import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
// import { useDispatch } from "react-redux"
// import classNames from "classnames"
import { Flex, Image, Box } from "rebass";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import IconButton from "@material-ui/core/IconButton"
// import MenuIcon from "@material-ui/icons/Menu"
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { UserMenu } from "react-admin";
//toggleSidebar
// import HideOnScroll from "./HideOnScroll"
import { UserContent } from "./UserContent";
import { DEFAULT_SIDEBAR, DEFAULT_BG_SIDEBAR } from "../../utils";

const useStyles = makeStyles(
  (theme) => ({
    toolbar: {
      paddingRight: 0,
      height: 72,
      backgroundColor: "#0087BA",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    menuButton: {
      marginLeft: "0.5em",
      marginRight: "0.5em",
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(["transform"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: "rotate(0deg)",
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(["transform"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: "rotate(180deg)",
    },
    title: {
      flex: 1,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  }),
  { name: "RaAppBar" }
);

const AppBar = (props) => {
  const {
    children,
    classes: classesOverride,
    className,
    logo,
    logout,
    open,
    title,
    userMenu,
    width,
    ...rest
  } = props;
  const classes = useStyles({
    classes: classesOverride,
  });
  // const dispatch = useDispatch()
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const datauser = get(props, "datauser", undefined);
  return (
    <div>
      <MuiAppBar
        className={className}
        color='primary'
        {...rest}
        style={{
          boxShadow: "none",
          backgroundColor: "#0087BA",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          width={[`${DEFAULT_SIDEBAR}px`]}
          style={{ backgroundColor: DEFAULT_BG_SIDEBAR, position: "fixed" }}
        >
          <Flex
            flexDirection={"row"}
            width={[1]}
            justifyContent={"center"}
            alignItems={"center"}
            py={[3]}
          >
            <Image
              src={
                datauser && datauser.logo
                  ? datauser.logo
                  : require("../../assets/images/logoclienteling.png")
              }
              style={{ width: "auto", height: 80 }}
            />
          </Flex>
        </Box>
        <Toolbar
          disableGutters
          variant={isXSmall ? "regular" : "dense"}
          className={classes.toolbar}
        >
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => dispatch(toggleSidebar())}
              className={classNames(classes.menuButton)}
            >
              <MenuIcon
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton> */}

            <Flex
              flexDirection={"row"}
              pr={[2]}
              alignItems={"center"}
              style={{ position: "fixed", zIndex: 2001, right: 0 }}
            >
              <UserContent {...props} />
            </Flex>
          </Flex>
        </Toolbar>
      </MuiAppBar>
    </div>
  );
};

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.element,
};

AppBar.defaultProps = {
  userMenu: <UserMenu />,
};

export default AppBar;
