import React, { Fragment, useState } from "react";
import { IconButton, Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Typography from "@material-ui/core/Typography";
import { Box, Text } from "rebass";
import alignItemImage from "./align-items.svg";
import flexDirectionImage from "./flex-direction.svg";
import justifyContentImage from "./justify-content.svg";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default (props) => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <IconButton onClick={() => setOpen(true)} color={"primary"}>
        <InfoIcon />
      </IconButton>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby={`info-layout-dialog-title`}
        open={open}
        fullScreen={true}
        scroll={"paper"}
      >
        <DialogTitle
          id={`info-layout-dialog-title`}
          onClose={() => setOpen(false)}
        >
          Info FlexBox Layout
        </DialogTitle>
        <DialogContent dividers>
          <Box width={[1]}>
            <Box width={[1]}>
              <Box width={[1]}>
                <Text width={[1]} pb={[2]}>
                  Flex Direction
                </Text>
                <Text width={[1]} pb={[2]}>
                  row (default): left to right in ltr; right to left in rtl
                </Text>
                <Text width={[1]} pb={[2]}>
                  row-reverse: right to left in ltr; left to right in rtl
                </Text>
                <Text width={[1]} pb={[2]}>
                  column:same as row but top to bottom
                </Text>
                <Text width={[1]} pb={[2]}>
                  column-reverse: same as row-reverse but bottom to top
                </Text>
              </Box>
              <img
                src={flexDirectionImage}
                alt='flex-direction'
                style={{ width: 500 }}
              />
            </Box>
            <Box width={[1]}>
              <img
                src={justifyContentImage}
                alt='justify-content'
                style={{ width: 500 }}
              />
            </Box>
            <Box width={[1]}>
              <img
                src={alignItemImage}
                alt='align-items'
                style={{ width: 500 }}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
