import React from "react"
import { Box, Flex } from "rebass"
import bottomphone from "../../../editorPreview/img/phone-bottom-mode.png"
import topphone from "../../../editorPreview/img/phone-top-mode.png"
import contentphone from "../../../editorPreview/img/phone-content-mode.png"
import { HeaderPage, StaticPage } from "../../../editorPreview"

export const BoxMobile = (props) => {
  const { data, locale, heightScreen } = props
  //widthScreen
  return (
    <Flex
      className={"mobile-view"}
      width={[1]}
      flexDirection={"column"}
      sx={{
        display: "flex",
        flex: 1,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#dedede",
      }}
    >
      <Box
        sx={{
          width: 448,
          backgroundImage: `url(${topphone})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          justifyContent: "center",
          height: 72,
          zIndex: 2000,
        }}
      />
      <Box
        sx={{
          width: 448,
          maxHeight: 480,
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${contentphone})`,
            backgroundRepeat: "repeat-y",
            backgroundSize: "contain",
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            width={320}
            sx={{
              margin: "0 auto",
              height: 480,
              maxHeight: 480,
              overflowY: "scroll",
            }}
          >
            <StaticPage
              data={data}
              locale={locale}
              heightScreen={heightScreen}
              widthScreen={350}
            />
          </Box>
        </Box>
      </Box>
      <Box
        width={448}
        sx={{
          backgroundImage: `url(${bottomphone})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          height: 127,
          justifyContent: "center",
          backgroundColor: "#dedede",
          zIndex: 2000,
        }}
      />
    </Flex>
  )
}

export const BoxMobileHeader = (props) => {
  const { data, locale, heightScreen } = props
  //widthScreen
  return (
    <Flex
      className={"mobile-view"}
      width={[1]}
      flexDirection={"column"}
      sx={{
        display: "flex",
        flex: 1,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#dedede",
      }}
    >
      <Box
        sx={{
          width: 448,
          backgroundImage: `url(${topphone})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          justifyContent: "center",
          height: 72,
          zIndex: 2000,
        }}
      />
      <Box
        sx={{
          width: 448,
          maxHeight: 480,
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${contentphone})`,
            backgroundRepeat: "repeat-y",
            backgroundSize: "contain",
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            width={320}
            sx={{
              margin: "0 auto",
              height: 480,
              maxHeight: 480,
              overflowY: "scroll",
            }}
          >
            <HeaderPage
              data={data}
              locale={locale}
              heightScreen={heightScreen}
              widthScreen={350}
            />
          </Box>
        </Box>
      </Box>
      <Box
        width={448}
        sx={{
          backgroundImage: `url(${bottomphone})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          height: 127,
          justifyContent: "center",
          backgroundColor: "#dedede",
          zIndex: 2000,
        }}
      />
    </Flex>
  )
}
